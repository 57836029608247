import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';

import Typography from 'components/Typography';

import { WHITE } from 'common/constants/colors';
import { selectShowObjectConfidence, selectShowIdentityConfidence } from 'settings/redux/selectors';
import { truncatePercentage } from 'common/helpers/mathUtils';

import { serveObjectIcon } from 'common/constants/objects';

import { getAttributesFromDetection } from './utils';

const limitText = text => (text?.length > 15 ? `${text.slice(0, 15)}...` : text);

const useStyles = createUseStyles(theme => ({
  main: {
    alignItems: 'center',
    color: WHITE,
    textShadow: '1px 1px 5px rgb(0 0 0 / 80%)',
    display: 'flex',
    minHeight: theme.spacing(2),
    position: 'absolute',
    zIndex: 99,
    padding: 0,
  },
  icon: {
    fontSize: 10,
    marginRight: theme.spacing(0.5),
    position: 'relative',
    borderRadius: '50%',
    minHeight: theme.spacing(2),
    minWidth: theme.spacing(2),
  },
  actualIcon: {
    minHeight: theme.spacing(1.75),
    minWidth: theme.spacing(1.75),
    fontSize: 'inherit',
  },
}));

const DetectionMeta = ({ detection, showText = false }) => {
  const classes = useStyles();

  const showObjectConfidence = useSelector(selectShowObjectConfidence);
  const showIdentityConfidence = useSelector(selectShowIdentityConfidence);
  const { _type: typeId } = detection.detection;

  const { isObj, name, color } = getAttributesFromDetection(detection.detection);
  const confidence = truncatePercentage(detection.detection.getConfidence());
  const showConfidence = (isObj && showObjectConfidence) || (!isObj && showIdentityConfidence);

  // Custom objects are not an identity and also do not have a pre-defined object / type name
  const displayName = limitText(name);
  const Icon = Number.isInteger(typeId) && typeId !== 0 ? serveObjectIcon(typeId) : null;
  const isProvisionalName =
    !isEmpty(detection.detection._person) && !detection.detection._person.provisional;

  const topOfDetection = detection.boundingBox.top;
  const heightOfDetection = detection.boundingBox.height;

  const { left } = detection.boundingBox;
  const top = 5 + topOfDetection + heightOfDetection;
  const dataX = left + detection.boundingBox.width / 2;
  const dataY = top - 5 - detection.boundingBox.height / 2;
  const iconStyle = {};
  if (color) {
    iconStyle.backgroundColor = color;
  }

  return (
    <div
      className={classes.main}
      style={{ left, top }}
      data-test-position={`${dataX},${dataY}`}
      data-testid="DetectionMeta"
    >
      {Icon && (
        <div className={classes.icon} style={iconStyle}>
          <Icon className={classes.actualIcon} />
        </div>
      )}

      {showText && (
        <Typography variant="body2">
          {isProvisionalName && limitText(detection.detection._person.name)}
          {displayName}
          {showConfidence && ` ${confidence}`}
        </Typography>
      )}
    </div>
  );
};

DetectionMeta.propTypes = {
  detection: PropTypes.shape(),
  showText: PropTypes.bool,
};

export default DetectionMeta;
