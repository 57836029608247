import React from 'react';
import DateTime from 'dateTime';
import injectSheet from 'react-jss';
import Paper from '@material-ui/core/Paper';

import Button from 'common/components/base/Button';
import { SECONDARY } from 'common/constants/colors';
import { mergeDateWithTime } from './lib/utils';
import CustomDateTimePicker from './lib/CustomDateTimePicker';

const styles = {
  main: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    padding: 8,
  },
  button: {
    textDecoration: 'none',
    color: SECONDARY,
    fontWeight: 500,
  },
  timePicker: {
    minHeight: 254,
  },
};

class DateTimePicker extends React.Component {
  state = {
    internalDate: this.props.date
      ? DateTime.fromJSDateWithTZ(this.props.date, { timeZone: this.props.date?.zoneName })
      : DateTime.fromJSDateWithTZ(),
  };

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date && this.props.date) {
      this.onDateUpdated();
    }
  }

  handleTimeChange = time =>
    this.setState(state => ({
      internalDate: mergeDateWithTime(state.internalDate, time),
    }));

  handleDateChange = date =>
    this.setState(state => ({
      internalDate: mergeDateWithTime(date, state.internalDate),
    }));

  onChange = () => {
    const { returnLuxonDate } = this.props;
    this.props.onClose();
    const newValue = returnLuxonDate ? this.state.internalDate : this.state.internalDate.valueOf();
    this.props.onChange(newValue);
  };

  onClear = () => {
    this.props.onChange(null);
    this.props.onClose();
  };

  onDateUpdated = () =>
    this.setState({ internalDate: DateTime.convertToLuxonDate(this.props.date) });

  render() {
    const { classes } = this.props;
    return (
      <Paper data-testid="DateTimePicker" className={classes.main}>
        <CustomDateTimePicker
          bindRef={node => (this.picker = node)}
          showTabs={false}
          date={this.state.internalDate}
          onTimeChange={this.handleTimeChange}
          onYearChange={this.handleDateChange}
          onDateChange={this.handleDateChange}
          disableFuture
        />
        <div className={classes.buttons}>
          <Button theme="transparent" className={classes.button} onClick={this.onClear}>
            CLEAR
          </Button>

          <div style={{ display: 'flex' }}>
            <Button theme="transparent" className={classes.button} onClick={this.props.onClose}>
              CANCEL
            </Button>
            <Button theme="transparent" className={classes.button} onClick={this.onChange}>
              OK
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

export default injectSheet(styles)(DateTimePicker);
