import { flow } from 'lodash';

export const numberOrNull = val => (val === '' || val == null ? null : Number(val));

export const fixLng = ({ lng = null, ...rest }) => ({
  lng: numberOrNull(lng),
  ...rest,
});

export const fixLat = ({ lat = null, ...rest }) => ({
  lat: numberOrNull(lat),
  ...rest,
});

export const fixCoords = ({ coords, ...rest }) => ({
  ...(coords ? { coords: flow([fixLat, fixLng])(coords) } : undefined),
  ...rest,
});

export const fixLocation = ({ location, ...rest }) => ({
  ...(location ? { location: fixCoords(location) } : null),
  ...rest,
});

export const transformCameraParams = fixLocation;
export const transformDatasourceParams = fixLocation;
