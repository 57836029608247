import axios from 'axios.js';
import { ANALYTICS_BACKEND, HEALTH } from 'common/constants/urls';

export function getMirageHealth() {
  return axios.get(`${ANALYTICS_BACKEND}${HEALTH}/`).then(({ data }) => data);
}

export function pingMirageBackend() {
  const current = new Date().getTime();
  return axios
    .get(`${ANALYTICS_BACKEND}/ping/`)
    .then(() => ({
      responseTime: new Date().getTime() - current,
    }))
    .catch(() => ({
      responseTime: null,
    }));
}
