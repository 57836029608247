import PropTypes from 'prop-types';
import { styled } from 'react-jss';
import { useSelector } from 'react-redux';
import { MapContainer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { selectIsTileServerFormFailure } from 'settings/redux/selectors';
import {
  INTERNET_IS_INACTIVE_MESSAGE,
  LOCATION_NOT_AVAILABLE_FOR_PAGE_MESSAGE,
} from 'common/helpers/networkUtils';

import MapViewEmpty from './MapViewEmpty';
import MapImage from './MapImage';
import MapLegend from './MapLegend';
import MapMarkers from './MapMarkers';
import MapTileLayer from './MapTileLayer';
import MapFeatureDrawGeofence from './MapFeatureDrawGeofence';

export { default as MiniMap } from './MiniMap';

const StyledWrapper = styled('div')({
  position: 'relative',
  height: '100%',
  width: '100%',
});

const StyledMapContainer = styled(MapContainer)({
  height: '100%',
  position: 'relative',
  width: '100%',
  zIndex: 1,
});

const StyledMapLegend = styled(MapLegend)({
  position: 'absolute',
  zIndex: 1000, // higher than map tiles (400)
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',
});

const santaClara = ['37.3708853', '-122.0025719'];

const Map = ({
  center = santaClara,
  children = null,
  disableMapControls = false,
  unavailable = false,
  zoom = 8,
  omitFailure = false,
  ...rest
}) => {
  const isFailure = useSelector(selectIsTileServerFormFailure);

  if (unavailable) {
    return (
      <StyledWrapper data-testid="Map-wrapper">
        <MapViewEmpty message={LOCATION_NOT_AVAILABLE_FOR_PAGE_MESSAGE} />
      </StyledWrapper>
    );
  }

  if (!omitFailure && isFailure) {
    return (
      <StyledWrapper data-testid="Map-wrapper">
        <MapViewEmpty message={INTERNET_IS_INACTIVE_MESSAGE} />
      </StyledWrapper>
    );
  }

  let options = {};

  if (disableMapControls) {
    options = {
      boxZoom: false,
      doubleClickZoom: false,
      dragging: false,
      keyboard: false,
      scrollWheelZoom: false,
      touchZoom: false,
      zoomControl: false,
    };
  }

  return (
    <StyledWrapper data-testid="Map-wrapper">
      <StyledMapContainer
        id="leaflet-map"
        center={center}
        zoom={zoom}
        maxZoom={18}
        {...options}
        {...rest}
      >
        {children}
      </StyledMapContainer>
    </StyledWrapper>
  );
};

Map.ImageLayer = MapImage;
Map.Legend = StyledMapLegend;
Map.Markers = MapMarkers;
Map.TileLayer = MapTileLayer;
Map.FeatureDrawGeofence = MapFeatureDrawGeofence;

Map.propTypes = {
  center: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  unavailable: PropTypes.bool,
  zoom: PropTypes.number,
};

export default Map;
