import { createSelector } from 'reselect';
import { get, isEmpty } from 'lodash';

import { selectIsProcOnline } from 'app/header/mirageHealth/redux/selectors';
import { selectCurrentUsername } from 'auth/redux/selectors';

import getProcessingJobsInfo from 'library/common/globalProcessingPanel/getProcessingJobsInfo';

export const selectProcessingJobs = state => state?.library?.main?.processingJobs;
export const selectUploadingJobs = state => get(state, 'library.main.uploadingJobs');
const selectUploadingJobList = createSelector(selectUploadingJobs, Object.values);
const selectUploadingImages = state => get(state, 'library.main.uploadingImages');
const selectUploadingImagesList = createSelector(selectUploadingImages, Object.values);
export const selectCurrentLibraryKey = state => get(state, 'library.main.currentLibraryKey');

const selectUploadImages = createSelector(selectUploadingImagesList, uploadingImages =>
  uploadingImages.filter(folder => folder.parentFolderKey)
);

const selectUploadJobs = createSelector(selectUploadingJobList, uploadingJobs =>
  uploadingJobs.filter(folder => folder.parentFolderKey)
);

const selectCurrentlyUploadingJobs = createSelector(
  selectUploadImages,
  selectUploadJobs,
  (images, jobs) => (jobs || []).concat(images)
);

const areJobsInProgress = jobs => Object.values(jobs).filter(job => job.parentFolderKey).length > 0;

export const selectAreJobsInProgress = createSelector(
  selectProcessingJobs,
  selectUploadingJobList,
  selectUploadingImagesList,
  (processingJobs, uploadingJobs, uploadingImages) =>
    (!isEmpty(processingJobs) && processingJobs.batches.length > 0) ||
    areJobsInProgress(uploadingJobs) ||
    areJobsInProgress(uploadingImages)
);

export const selectJobs = createSelector(
  selectCurrentlyUploadingJobs,
  selectProcessingJobs,
  selectCurrentUsername,
  selectIsProcOnline,
  getProcessingJobsInfo
);

export const selectOpenFolders = state => get(state, 'library.main.openedFolders');

export const selectLibraryFolderIsOpen = createSelector(
  selectOpenFolders,
  (_, folderId) => folderId,
  (openFolders, folderId) => openFolders[folderId]
);

export const selectFolderMoveInfo = state => state.library.main.folderMoveInfo;
