import React from 'react';
import { Link, useParams, Route, Switch, useRouteMatch } from 'react-router-dom';
import Typography from 'components/Typography';
import { MissionProvider, useMission } from './MissionContext';
import SightingsForIdentityPackage from './SightingsForIdentityPackage';
import Person from './Person';

const Dashboard = () => {
  const { path, url } = useRouteMatch();
  const { persons } = useMission();
  return (
    <Switch>
      <Route exact path={path}>
        <Typography variant="h3">Mission Dashboard</Typography>
        {persons?.map(id => (
          <Link key={id} to={`${url}/${id}`}>
            <Person id={id} />
          </Link>
        ))}
      </Route>
      <Route path={`${path}/:personId`}>
        <SightingsForIdentityPackage />
      </Route>
    </Switch>
  );
};

const MissionDashboard = () => {
  const { libraryId, depth } = useParams();

  return (
    <MissionProvider id={libraryId} depth={depth}>
      <Dashboard />
    </MissionProvider>
  );
};

export default MissionDashboard;
