import { useState, useEffect, useRef } from 'react';
import { noop } from 'lodash';

import Dialog from '@material-ui/core/Dialog';
import useSharedStyles from './useSharedStyles';
import IdentityThumbnail from './IdentityThumbnail';
import FormHeader from './FormHeader';
import FormFooter from './FormFooter';
import EditIdentityNameConfirm from './EditIdentityNameConfirm';
import IdentityForm from './IdentityForm';
import FormBody from './FormBody';
import { getDefaultName, getUrl } from './utils';

const AddIdentity = ({
  open = false,
  identity = {},
  isProcessing = false,
  processImages,
  removeImage,
  imagesToAdd = [],
  errorMessage,
  onError,
  addImages,
  isLoading = false,
  onClose,
  handleSubmit,
  fromPlan = false,
  toggleWebcam,
  isEditingExistingIdentity = false,
}) => {
  const classes = useSharedStyles();
  const imagesListEl = useRef();
  const imageEl = useRef();
  const title = isEditingExistingIdentity ? 'Edit Identity' : 'Add a New Identity';
  const [isEditNameConfirmOpen, setIsEditNameConfirmOpen] = useState(false);
  const [onEditNameConfirm, setOnEditNameConfirm] = useState(noop);
  const [hasActiveSearchRequests, setHasActiveSearchRequests] = useState(false);
  const [addingImages, setAddingImages] = useState(false);
  const [buttonLabels, setButtonLabels] = useState({ close: 'Cancel' });

  useEffect(() => {
    if (isEditingExistingIdentity && imagesToAdd.length) {
      return setButtonLabels({ submit: 'Update Identity', close: 'Cancel' });
    }

    if (imagesToAdd.length) {
      return setButtonLabels({ submit: 'Process', close: 'Cancel' });
    }

    if (fromPlan) {
      return setButtonLabels({ submit: 'Add to Plan', close: 'Cancel' });
    }

    setButtonLabels({ submit: 'Save', close: 'Cancel' });
  }, [imagesToAdd.length, isEditingExistingIdentity, fromPlan]);

  useEffect(() => {
    const shouldScrollToLastImage = addingImages || imagesToAdd.length || isProcessing;
    if (imagesListEl.current && imageEl.current && shouldScrollToLastImage) {
      const timer = setTimeout(() => {
        imagesListEl.current.scrollLeft += imageEl.current.getBoundingClientRect().right;
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [imagesToAdd.length, isProcessing, addingImages]);

  const onAddImages = (...args) => {
    addImages(...args);
    setAddingImages(true);
  };

  const onSubmit = ({ name, tags }) => {
    const _identity = {
      ...identity,
      name: name || identity.name,
      tags: tags || identity.tags,
    };
    if (imagesToAdd.length) {
      return processImages(_identity);
    }
    handleSubmit(_identity);
  };

  const handleNameEditChange = ({ name, tags }) => {
    if (identity.name !== name && hasActiveSearchRequests) {
      setOnEditNameConfirm(() => () => onSubmit({ name, tags }));
      setIsEditNameConfirmOpen(true);
    } else {
      onSubmit({ name, tags });
    }
  };

  const onCloseEditNameConfirm = () => {
    setOnEditNameConfirm(noop);
    setIsEditNameConfirmOpen(false);
  };

  const renderEditNameConfirmDialog = () => (
    <EditIdentityNameConfirm
      handleClose={onCloseEditNameConfirm}
      onConfirm={onEditNameConfirm}
      open={isEditNameConfirmOpen}
      identity={identity}
      setHasActiveSearchRequests={setHasActiveSearchRequests}
    />
  );

  const renderImage = ({ thumbnailUrl, canRemove = false, highlight = false } = {}) => (
    <IdentityThumbnail
      key={thumbnailUrl}
      thumbnailUrl={thumbnailUrl}
      onDelete={removeImage}
      isProcessing={isProcessing}
      isLoading={isLoading}
      canRemove={canRemove}
      highlight={highlight}
      ref={imageEl}
    />
  );

  const renderImages = (images = [], { canRemove = false, highlight = false } = {}) =>
    images.map(image => renderImage({ thumbnailUrl: getUrl(image), canRemove, highlight }));

  return (
    <Dialog
      open={open}
      onClick={e => e.stopPropagation()}
      onClose={onClose}
      classes={{ paper: classes.main }}
      fullScreen
      data-testid="UploadIdentity"
    >
      <FormHeader title={title} onClose={onClose} identity={identity} />
      <div className={classes.body}>
        <IdentityForm
          defaultName={getDefaultName(imagesToAdd[0])}
          identity={identity}
          errorMessage={errorMessage}
          onSubmit={handleNameEditChange}
          isLoading={isLoading || isProcessing}
          onError={onError}
        />
        <FormBody
          isProcessing={isProcessing}
          addImages={onAddImages}
          imagesToAdd={imagesToAdd}
          objectType={identity.objectType}
          processing={identity?.processing}
          isLoading={isLoading}
          toggleWebcam={toggleWebcam}
          ref={imagesListEl}
        >
          {!!identity?.images?.length &&
            renderImages(identity.images, { canRemove: identity.images.length > 1 })}
          {!!identity?.detections?.length && renderImages(identity.detections, { canRemove: true })}
          {imagesToAdd?.length > 1 && renderImages(imagesToAdd.slice(1), { canRemove: true })}
          {!!imagesToAdd?.length &&
            renderImages([imagesToAdd[0]], { canRemove: true, highlight: true })}
          {isProcessing && renderImage({ thumbnailUrl: '/analyze-animation.gif', highlight: true })}
        </FormBody>
      </div>
      <FormFooter
        onClose={() => onClose(identity)}
        disabled={isProcessing}
        form="identity-form"
        labels={buttonLabels}
        isLoading={isLoading}
      />
      {renderEditNameConfirmDialog()}
    </Dialog>
  );
};

export default AddIdentity;
