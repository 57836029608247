import * as C from './constants';
import { createKey, createRequestId, parseIndexer } from './utils';

const OPEN_STATE = 1;

function onOpen() {
  while (this.pendingSend.length) {
    const toSend = this.pendingSend.pop();
    this.send(toSend);
  }
}

function onSend(payload) {
  const readyPayload = JSON.stringify(payload);

  if (this.readyState !== OPEN_STATE) {
    this.pendingSend.push(readyPayload);
  } else {
    this.send(readyPayload);
  }
}

function onMessage(message) {
  const data = JSON.parse(message.data);

  const { type, model } = data;

  // Call all listeners for type
  this.listeners[type]?.forEach(listener => {
    if (typeof listener === 'function') {
      listener(data);
    }
  });

  // Call all listeners for type + model
  if (model) {
    const key = createKey(type, model);
    this.listeners[key]?.forEach(listener => {
      if (typeof listener === 'function') {
        listener(data);
      }
    });
  }
}

function subscribe(indexer, listener) {
  const { key, type, model } = parseIndexer(indexer);

  const isSubscribed = this.listeners[key]?.length;

  // No need to subscribe more than once
  if (!isSubscribed) {
    const payload = { request_id: createRequestId(key), action: C.SUBSCRIBE, type, model };

    this.onSend(payload);
  }

  this.addListener(key, listener);
}

function unsubscribe(indexer, listener) {
  const { key, type, model } = parseIndexer(indexer);

  this.removeListener(key, listener);

  // Only unsubscribe if all listeners are removed
  if (!this.listeners[key]?.length) {
    this.onSend({ request_id: createRequestId(key), action: C.UNSUBSCRIBE, type, model });
  }
}

function addListener(key, listener) {
  this.listeners[key] = this.listeners[key] || [];
  this.listeners[key].push(listener);
}

function removeListener(key, listener) {
  const listenersForType = this.listeners[key];
  this.listeners[key] = listenersForType?.filter(_listener => _listener !== listener);
}

function connectSocket(externalEventListeners) {
  const socket = new WebSocket(`wss://${window.location.host}/ws/observer/`);

  socket.pendingSend = [];
  socket.listeners = {};

  socket.onopen = () => {
    onOpen.call(socket);
    externalEventListeners?.onopen?.();
  };
  socket.onclose = externalEventListeners?.onclose;
  socket.onmessage = onMessage;
  socket.onSend = onSend;

  socket.subscribe = subscribe;
  socket.unsubscribe = unsubscribe;

  socket.addListener = addListener;
  socket.removeListener = removeListener;

  return socket;
}

function connect(externalEventListeners) {
  const currentListeners = this.socket?.listeners;
  const socket = connectSocket(externalEventListeners);

  // If the socket connection has been reopened, re-subscribe to any listeners that
  // existed on the previous connection
  if (currentListeners) {
    Object.keys(currentListeners).forEach(key => {
      currentListeners[key].forEach(listener => {
        socket.subscribe(key, listener);
      });
    });
  }

  this.socket = socket;
}

function get() {
  return this.socket;
}

function MirageSocket() {
  this.connect = connect;
  this.get = get;
}

const socket = new MirageSocket();

export default socket;
