import Box from '@material-ui/core/Box';

import MirageUI from './MirageUI';
import GoogleMapsApi from './GoogleMapsApi';
import ErrorBoundary from './ErrorBoundary';
import AppProviders from './AppProviders';
import SocketConnection from './SocketConnection';

const App = () => (
  <AppProviders>
    <ErrorBoundary>
      <Box />
      <MirageUI />
      <GoogleMapsApi />
      <SocketConnection />
    </ErrorBoundary>
  </AppProviders>
);

export default App;
