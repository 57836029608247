import { Component } from 'react';
import injectSheet from 'react-jss';

import { ERROR, BORDER_COLOR, TEXT, CLICKABLE_ICON_LIGHT } from 'common/constants/colors';
import emitter, { DISABLE_VIDEO_SHORTCUTS, ENABLE_VIDEO_SHORTCUTS } from 'common/constants/emitter';
import { PLAY_PAUSE } from 'common/constants/shortcuts';
import { TEST_SCENE_PLAYER_SAVE_AS_VIDEO_CHECKBOX } from '__testSetup__/constants';

import DashVideo from 'common/components/videoPlayer/DashVideo';
import Checkbox from 'common/components/base/Checkbox';
import Button from 'common/components/base/Button';
import Info from 'common/components/base/Info';
import Tooltip from 'common/components/base/Tooltip';
import VideoProgressBar from 'common/components/videoPlayer/controls/VideoProgressBar';
import BaseVideoControls from 'common/components/videoPlayer/BaseVideoControls';
import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';

import { getInputStartValue, getInputEndValue } from './utils';

const styles = {
  main: {
    flex: 2,
    height: 'inherit',
    padding: '20px 20px 20px 0',
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 336,
    background: 'black',
    position: 'relative', // for loading overlay
    overflow: 'hidden',

    '& video': {
      minHeight: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      height: 'auto',
      width: '100%',
      objectFit: 'contain',
    },

    '& img': {
      height: '100%',
    },
  },
  videoOptions: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: props => (!props.saveAsVideo ? 36 : 0),
    paddingTop: 8,

    '& p': {
      marginRight: 12,
      fontSize: 11,
    },
  },
  errorMessage: {
    position: 'relative',
    top: 8,
    fontWeight: 'lighter',
    fontSize: 10,
    color: ERROR,
  },
  controlBarWrapper: {
    position: 'relative',
    height: 36,
  },
  progressBar: {
    height: 36,
    marginLeft: 40,
  },
  videoControl: {
    position: 'absolute',
    left: 0,
    height: 36,
  },
  targetButton: {
    marginLeft: 8,
    fill: CLICKABLE_ICON_LIGHT,
  },
  timeInput: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    top: 8,
    margin: '0 6px',

    '& input': {
      width: 64,
      height: 32,
      boxShadow: 'none',
      borderRadius: 2,
      border: `solid 1px ${BORDER_COLOR}`,
      padding: '0 12px',
      boxSizing: 'border-box',
      fontSize: 11,
      marginBottom: 4,
    },

    '& span': {
      fontWeight: 'lighter',
      fontSize: 10,
    },
  },
  tooltip: {
    maxWidth: 140,
    textAlign: 'center',
  },
};

const emptyStyles = {
  width: '100%',
  height: '100%',
  background: TEXT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 300,
  fontSize: 12,
  color: 'white',
  flexGrow: 1,
};

class ScenePlayer extends Component {
  state = {
    isPlaying: false,
    isLoading: false,
    isSeeking: false,
  };

  componentDidMount() {
    this.setVideoToTime(this.props.targetTime);
    emitter.emit(DISABLE_VIDEO_SHORTCUTS);
    document.addEventListener('keyup', this.handleVideoShortCuts);
  }

  componentDidUpdate(prevProps) {
    if (!this.video) return;

    if (!this.props.saveAsVideo && prevProps.saveAsVideo !== this.props.saveAsVideo) {
      this.setVideoToTime(this.props.targetTime);
      this.pauseVideo();
    } else if (this.props.saveAsVideo && prevProps.saveAsVideo !== this.props.saveAsVideo) {
      this.playVideo();
    }
  }

  componentWillUnmount() {
    emitter.emit(ENABLE_VIDEO_SHORTCUTS);
    document.removeEventListener('keyup', this.handleVideoShortCuts);
  }

  progress = null;

  video = null;

  handleVideoShortCuts = e => {
    if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') {
      return;
    }
    const value = e.keyCode;

    if (value === PLAY_PAUSE) this.togglePlay();
  };

  togglePlay = () => {
    if (!this.props.saveAsVideo) return;

    if (this.video.isPaused()) {
      this.playVideo();
    } else {
      this.pauseVideo();
    }
  };

  onLoadStart = () => {
    this.video.setTime(this.props.targetTime / 1000);
    this.setState({ isLoading: true });
  };

  onLoadFinish = () => {
    this.setState({ isLoading: false });
  };

  onSeekStart = () => this.setState({ isSeeking: true });

  onSeekFinish = () => this.setState({ isSeeking: false });

  updateProgress = time => {
    if (time * 1000 > this.props.endTime || time * 1000 < this.props.startTime) {
      this.setVideoToTime(this.props.startTime);
    }
  };

  updatePlayingState = () => {
    if (!this.video) return;

    if (this.video.isPaused()) {
      this.setState({ isPlaying: false });
    } else {
      this.setState({ isPlaying: true });
    }
  };

  playVideo = () => {
    this.video.play();
    this.updatePlayingState();
  };

  pauseVideo = () => {
    this.video.pause();
    this.updatePlayingState();
  };

  sync = () => {
    if (!this.video || !this.props.saveAsVideo) return;

    const seconds = this.video.getTime();
    this.updateProgress(seconds);
    this.updateVideoProgressBar(seconds);
  };

  updateVideoProgressBar = seconds => {
    this.progress.setTime(seconds);
  };

  setVideoToTime = time => {
    if (this.video) this.video.setTime(time / 1000);
  };

  setRef = node => {
    this.video = node;
    if (this.props.bindRef) this.props.bindRef(node);
  };

  getVideoDuration = () => {
    const duration = this.video ? this.video.getDuration() : 0;

    return Number.isNaN(duration) ? 0 : duration;
  };

  handleBackToTarget = () => {
    if (!this.props.saveAsVideo || !this.video) return;

    this.setVideoToTime(this.props.targetTime);
    this.sync();
    this.pauseVideo();
  };

  renderControls = () => (
    <div className={this.props.classes.controlBarWrapper}>
      <div className={this.props.classes.videoControl}>
        <BaseVideoControls isPlaying={this.state.isPlaying} onClick={this.togglePlay} />
      </div>
      <div className={this.props.classes.progressBar}>
        <VideoProgressBar
          getVideoDuration={this.getVideoDuration}
          bindRef={node => (this.progress = node)}
          disabled
        />
      </div>
    </div>
  );

  renderEmptyVideo = () => (
    <div style={emptyStyles}>
      {this.props.imageUrl ? (
        <img alt="preview" src={this.props.imageUrl} />
      ) : (
        !this.props.isLoading && 'Scene preview cannot be created without associated Data Source'
      )}
    </div>
  );

  renderErrorMessage = () => {
    const { errorMessage, classes } = this.props;
    if (!errorMessage) return null;
    return (
      <div className={classes.errorMessage}>
        <span>{errorMessage}</span>
      </div>
    );
  };

  render() {
    const {
      classes,
      endTime,
      isLoading,
      saveAsVideo,
      sourceId,
      transform,
      archivedSceneUrl,
      showControls,
      startTime,
      targetTime,
    } = this.props;

    const duration = this.getVideoDuration();
    const start = getInputStartValue(startTime, targetTime);
    const end = getInputEndValue(endTime, targetTime, duration);
    const removedDSTooltip = 'Original video file is no longer available';

    return (
      <div className={classes.main}>
        <div
          data-testid="ScenePlayer-VideoContainer"
          id="ScenePlayer-VideoContainer"
          className={classes.videoContainer}
          style={showControls ? { cursor: 'pointer' } : {}}
        >
          {(isLoading || this.state.isLoading || this.state.isSeeking) && (
            <LoadingOverlay backgroundColor="transparent" />
          )}
          {sourceId ? (
            <DashVideo
              ref={this.setRef}
              src={`api/ds/${sourceId}/manifest.mpd`}
              onClick={this.togglePlay}
              onPlay={this.updatePlayingState}
              onPause={this.updatePlayingState}
              onProgress={this.sync}
              onLoadStart={this.onLoadStart}
              onLoadFinish={this.onLoadFinish}
              onSeekStart={this.onSeekStart}
              onSeekFinish={this.onSeekFinish}
              style={{ transform }}
            />
          ) : archivedSceneUrl ? (
            <video controls>
              <source src={archivedSceneUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            this.renderEmptyVideo()
          )}
        </div>
        {showControls ? this.renderControls() : null}
        <div className={classes.videoOptions}>
          <Checkbox
            size="small"
            disabled={!this.props.sourceId}
            checked={saveAsVideo}
            onChange={this.props.onToggleSaveAsVideo}
            label="Save as a video clip"
            {...TEST_SCENE_PLAYER_SAVE_AS_VIDEO_CHECKBOX}
          />
          <div className={classes.timeInput}>
            <input
              disabled={!showControls}
              value={start}
              onChange={this.props.onChangeStartTime}
              type="number"
              placeholder="0"
              data-testid="ScenePlayer-secBefore"
            />
            <span>Sec. Before</span>
          </div>
          —
          <div className={classes.timeInput}>
            <input
              disabled={!showControls}
              value={end}
              onChange={this.props.onChangeEndTime}
              type="number"
              placeholder="0"
              data-testid="ScenePlayer-secAfter"
            />
            <span>Sec. After</span>
          </div>
          {!this.props.sourceId && <Info title={removedDSTooltip} />}
          <Tooltip
            title="Jump to the “Captured Scene” position in the video player preview"
            classes={{ tooltip: classes.tooltip }}
          >
            <div>
              <Button
                className={classes.targetButton}
                onClick={this.handleBackToTarget}
                disabled={!showControls}
                size="small"
                variant="outlined"
                data-testid="ScenePlayer-ResetButton"
              >
                RESET
              </Button>
            </div>
          </Tooltip>
        </div>
        {saveAsVideo && this.renderErrorMessage()}
      </div>
    );
  }
}

export default injectSheet(styles)(ScenePlayer);
