import store from 'redux/store';
import { PLAN_ANALYSIS_ROUTE, PLAN_MONITOR_ROUTE, PLAN_ROUTE } from 'common/constants/urls';

import {
  addToSearchQuery,
  addToSearchQueryMonitor,
  removeFromQueryMonitor,
  removeFromQuery,
} from 'plan/redux/actions';
import { selectSearchQuery, selectMonitorSearchQuery } from 'plan/redux/selectors';

export const generateQueryActions = route => {
  const queryActions = {};
  if (!route) return queryActions;

  queryActions.inPlan = route.includes(PLAN_ROUTE);
  const { dispatch } = store;
  if (route.includes(PLAN_ANALYSIS_ROUTE)) {
    queryActions.selectSearchQuery = selectSearchQuery;
    queryActions.onAddToPlan = (...args) => dispatch(addToSearchQuery(...args));
    queryActions.onRemoveFromPlan = (...args) => dispatch(removeFromQuery(...args));
  } else if (route.includes(PLAN_MONITOR_ROUTE)) {
    queryActions.selectSearchQuery = selectMonitorSearchQuery;
    queryActions.onAddToPlan = (...args) => dispatch(addToSearchQueryMonitor(...args));
    queryActions.onRemoveFromPlan = (...args) => dispatch(removeFromQueryMonitor(...args));
  }

  return queryActions;
};
