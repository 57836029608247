import { useEffect } from 'react';

import { DEFAULT_ZOOM } from 'common/constants/panAndZoom';
import { ZONE_STYLES } from 'common/components/videoPlayer/constants';

import { getRectangleBounds } from './utils';
import { useZones } from './ZonesContext';

const { pointSize, defaultColor } = ZONE_STYLES;

const Rectangle = ({ data, dimension, mouseOffset, onComplete, zoom = DEFAULT_ZOOM }) => {
  const { active, cancel, complete } = useZones();

  useEffect(() => {
    if (data[active]?.length === 2) {
      const bounds = getRectangleBounds({
        dimension,
        topLeft: data[active][0],
        bottomRight: data[active][1],
      });

      onComplete({
        bounds,
        onCancel: () => {
          cancel();
        },
      });

      complete();
    }
  }, [active, cancel, complete, data, dimension, onComplete]);

  return data.map((points, zoneIndex) => {
    if (points.length === 0) {
      return null;
    }

    // If two coordinates draw rectangle, ignore 3rd or further value
    const rectCords = points.slice(0, 2);

    // If one coordinate draw based on mouse position
    if (rectCords.length === 1) {
      if (!mouseOffset) {
        return null;
      }

      rectCords[1] = mouseOffset;
    }

    const strokeWidth = (pointSize * 100) / zoom;

    // This is to keep the drawn rectangle tracking with the mouse
    if (mouseOffset && data.length === 1) {
      const _dimensions = Math.max(
        Math.abs(mouseOffset[0] - rectCords[0][0]),
        Math.abs(mouseOffset[1] - rectCords[0][1])
      );

      return (
        <rect
          x={Math.min(rectCords[0][0], mouseOffset[0])}
          y={Math.min(rectCords[0][1], mouseOffset[1])}
          width={_dimensions}
          height={_dimensions}
          stroke={defaultColor}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeWidth}
          fill="none"
          style={{ pointerEvents: 'none' }}
          key={[zoneIndex, rectCords[0][0], mouseOffset[1]].join(',')}
        />
      );
    }

    const left = Math.min(rectCords[0][0], rectCords[1][0]);
    const top = Math.min(rectCords[0][1], rectCords[1][1]);
    const right = Math.max(rectCords[0][0], rectCords[1][0]);
    const bottom = Math.max(rectCords[0][1], rectCords[1][1]);
    const edgeLength = Math.max(right - left, bottom - bottom);

    return (
      <rect
        x={left}
        y={top}
        width={edgeLength}
        height={edgeLength}
        stroke={defaultColor}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeWidth}
        fill="none"
        key={[zoneIndex, left, top, right, bottom].join(',')}
      />
    );
  });
};

export default Rectangle;
