import React, { useState, createContext, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as AuthActions from 'auth/redux/actions';
import * as SettingsSelectors from 'settings/redux/selectors';

const LoginContext = createContext();

const names = {
  USERNAME: 'username',
  PASSWORD: 'password',
};

export const LoginProvider = props => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasConsent, setHasConsent] = useState(false);
  const [isConsentBannerOpen, setIsConsentBannerOpen] = useState(true);

  const requireConsent = useSelector(SettingsSelectors.selectConsentBannerContent);
  const isLoginDisabled = Boolean(!hasConsent && requireConsent);

  return (
    <LoginContext.Provider
      value={{
        isLoading,
        error,
        hasConsent,
        setHasConsent,
        isConsentBannerOpen,
        setIsConsentBannerOpen,
        isLoginDisabled,
        toggleConsentBannerOpen: () => setIsConsentBannerOpen(prev => !prev),
        names,
        onSubmit: e => {
          const username = e.target.elements[names.USERNAME].value;
          const password = e.target.elements[names.PASSWORD].value;
          setIsLoading(true);

          dispatch(AuthActions.signIn({ username, password })).catch(err => {
            if (err.response?.status >= 500) {
              setError('A Server Error has Occurred');
            } else {
              setError('Incorrect Username or Password');
            }

            setIsLoading(false);
          });
        },
      }}
      {...props}
    />
  );
};

export const useLogin = () => useContext(LoginContext);
