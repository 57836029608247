import { LIGHT_ACCENT, LIGHT_BLUE, LIGHTEST_BLUE, WHITE } from 'common/constants/colors';
import { getTextColor } from './utils';

export function getTheadTrProps() {
  return {
    style: {
      backgroundColor: LIGHT_ACCENT,
    },
  };
}

export function getTheadProps() {
  return {
    style: {
      backgroundColor: LIGHT_BLUE,
    },
  };
}

export function getTableProps() {
  return {
    style: {
      overflow: 'hidden',
    },
  };
}

export function getTrProps(state, rowInfo) {
  if (rowInfo) {
    return {
      style: {
        color: getTextColor(rowInfo.original.status),
        background: rowInfo.index % 2 === 0 ? LIGHTEST_BLUE : 'rgb(246, 249, 254, 0.5)',
        height: 58,
        display: 'flex',
        alignItems: 'center',
        verticalAlign: 'middle',
      },
    };
  }
  return {};
}

export function getTrPropsForJobGroup(state, rowInfo) {
  if (rowInfo) {
    return {
      style: {
        color: getTextColor(rowInfo.original.status),
        display: 'flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        background: WHITE,
        height: 45,
      },
    };
  }
  return {};
}
