import { combineReducers } from 'redux';

import { FOLDER, OBJECT, GEOFENCE, GEOIMAGE, GEODATASET } from 'common/constants/app';

import geodataset from './geodataset/reducer';
import geoimage from './geoimage/reducer';
import geofence from './geofence/reducer';
import object from './object/reducer';
import folder from './folder/reducer';

const defaults = {
  isLoadingGeoData: true,
};

function main(state = defaults, action) {
  switch (action.type) {
    case 'LIBRARY_GEOSPATIAL/SET_LOADING_GEODATA': {
      return {
        ...state,
        isLoadingGeoData: action.payload,
      };
    }
    default:
      return state;
  }
}

export default combineReducers({
  main,
  [GEOFENCE]: geofence,
  [GEOIMAGE]: geoimage,
  [GEODATASET]: geodataset,
  [OBJECT]: object,
  [FOLDER]: folder,
});
