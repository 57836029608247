import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import injectSheet from 'react-jss';
import Dialog from '@material-ui/core/Dialog';
import isEmpty from 'lodash/isEmpty';

import { renderAlert, renderError } from 'app/redux/actions';
import { getSceneStillImageURL } from 'common/api/sceneApi';
import { getExportZip } from 'common/api/datasourceApi';
import { extractMirageFormError } from 'common/helpers/apiErrorUtils';
import { defaultFetchReports } from 'common/redux/models/actions';
import { ANALYTICS_BACKEND } from 'common/constants/urls';
import { IMAGE } from 'common/constants/app';
import { getLocationString } from 'common/helpers/locationUtils';
import {
  formatImageDate,
  parseToShorthandDate,
  parseDurationToHHMMSS,
} from 'common/helpers/dateUtils';
import { downloadBlob, downloadUrl, COMMON_MIME_TYPES } from 'common/helpers/fileUtils';
import { isSceneSavedAsVideo } from 'common/helpers/sceneUtils';
import { exceedsLimitErrorMessage, isLengthValid } from 'common/components/popups/utils';
import { calculateNewTransformValue } from 'common/components/panAndZoom/PanAndZoomUtils';
import * as reportConstants from 'reports/constants';
import { BORDER_COLOR, PRIMARY_GREY, LIGHT_BORDER } from 'common/constants/colors';

import Button from 'common/components/base/Button';
import Icon from 'common/components/base/Icon';
import LocationInput from 'common/components/base/LocationInput';
import TextInput from 'common/components/base/TextInput';
import Typography from 'components/Typography';
import ReportAutoSuggest from 'common/components/generalComponents/autosuggest/ReportAutoSuggest';

import ScenePlayer from './ScenePlayer';
import { buildScene } from './utils';

const styles = {
  content: {
    width: 750,
    borderRadius: 4,
    backgroundColor: 'white',
    boxShadow: `0 4px 12px 0 rgba(0, 0, 0, 0.3)`,
  },
  header: {
    display: 'flex',
    height: 64,
    borderBottom: `solid 0.5px ${BORDER_COLOR}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: 12,
  },
  sceneMetaData: {
    flex: 1,
    padding: 20,
    marginBottom: 24,
    maxWidth: '40%',
  },
  cancel: {
    width: 16,
    height: '100%',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },

    '& > img': {
      width: 12,
      height: 12,
    },
  },
  subtext: {
    color: PRIMARY_GREY,
    fontSize: 10,
    padding: '5px 3px',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 456,
    height: 336,
    background: 'black',
    margin: '12px 12px 12px 0px',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,
    padding: 20,
    height: 46,
    borderTop: `solid 1px ${LIGHT_BORDER}`,

    '& > button': {
      marginLeft: 6,
      minWidth: 72,
    },
  },
  input: {
    width: '100%',
    marginTop: 0,
  },
  sceneImageStyle: {
    minHeight: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
    objectFit: 'contain',
  },
};

const PaperProps = {
  style: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const SAVE_EDIT_SCENE_FORM = 'SaveEditSceneForm';
const DEFAULT_STATE = {
  id: null,
  targetTime: 0,
  startTime: 0,
  endTime: 0,
  summary: '',
  description: '',
  imageUrl: '',
  location: {},
  query: '',
  source: null,
  tags: '',
  saveAsVideo: false,
  isSaving: false,
  report: {
    id: null,
    name: '',
  },
  errorMessage: '',
  transform: '',
};

const FORM_FIELDS = {
  report: {
    name: true,
    width: reportConstants.REPORT_NAME_MAX_LENGTH,
    required: true,
  },
  summary: { width: reportConstants.SCENE_TITLE_MAX_LENGTH, required: true },
  description: {
    width: reportConstants.SCENE_NOTES_MAX_LENGTH,
    required: false,
  },
};

/**
  @prop scene {object} should be the scene model we are currently viewing
  @prop source {object} should be the datasoure used when creating the scene
  @prop onClose {func} called when component is about to close
  @prop open {bool} determines whether this component is open or not
*/

function buildSceneState(scene = {}, reportModels) {
  const report = reportModels[scene.report];
  return {
    id: scene.id || null,
    targetTime: scene.targetTime || 0,
    startTime: scene.startTime || 0,
    endTime: scene.endTime || 0,
    summary: scene.summary || '',
    description: scene.description || '',
    imageUrl: scene.imageUrl || '',
    location: scene.location || {},
    query: scene.query || '',
    saveAsVideo: isSceneSavedAsVideo(scene),
    source: scene.source || null,
    tags: scene.tags || '',
    report: {
      id: report ? report.id : null,
      name: report ? report.name : '',
    },
    transform: scene.transform || '',
  };
}
class SaveEditScene extends Component {
  static defaultProps = {
    scene: {},
    source: {},
  };

  state = DEFAULT_STATE;

  componentDidMount() {
    this.props.dispatchDefaultFetchReports();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scene !== prevProps.scene) {
      this.onSceneChanged();
    } else if (!this.props.open && prevProps.open) {
      this.onClose();
    }
  }

  bindRef = node => (this.video = node);

  isDatasourceAvailable = () => this.props.scene.source || this.props.scene.imageSource;

  isDatasourceAnImage = () => {
    const { scene } = this.props;

    return Boolean(
      scene.imageSource || (scene.archivedSource && scene.archivedSource.itemType === IMAGE)
    );
  };

  handleClose = () => this.props.onClose();

  handleExport = () => {
    if (this.isDatasourceAnImage()) return this.onExportFrameImage();
    if (this.state.saveAsVideo && this.state.endTime - this.state.startTime >= 1000) {
      return this.onExportClip();
    }
    return this.onExportFrameDatasource();
  };

  onExportFrameImage() {
    const { id, title } = this.props.source;

    return getSceneStillImageURL({ source: id }).then(thumbUrl => {
      downloadUrl(thumbUrl, `${title}.jpg`);
    });
  }

  onExportFrameDatasource = () => {
    const { source, targetTime, report, summary, description, location } = this.state;
    const { startEpochMs, title } = this.props.source;

    const timestamp = Math.round(startEpochMs + targetTime);

    const relativeTargetTime = parseDurationToHHMMSS(targetTime, {
      showHours: true,
    }).replace(/:/g, '');

    const body = {
      clip: false,
      timestamp,
      summary,
      description,
      location,
      reportName: report.name,
    };

    return getExportZip(source, body)
      .then(data => {
        const blob = new Blob([data], {
          type: COMMON_MIME_TYPES.zip,
        });
        downloadBlob(blob, `${title}_${relativeTargetTime}.zip`);
      })
      .catch(() => renderAlert('Unable to export selected video frame.'));
  };

  onExportClip = () => {
    const {
      source,
      targetTime,
      startTime,
      endTime,
      report,
      summary,
      description,
      location,
    } = this.state;
    const { startEpochMs, title } = this.props.source;

    const timestamp = Math.round(startEpochMs + targetTime);
    const startTimestamp = Math.round(startEpochMs + startTime);
    const endTimestamp = Math.round(startEpochMs + endTime);

    const relativeStartTime = parseDurationToHHMMSS(startTime, {
      showHours: true,
    }).replace(/:/g, '');
    const relativeEndTime = parseDurationToHHMMSS(endTime, {
      showHours: true,
    }).replace(/:/g, '');

    const body = {
      clip: true,
      timestamp,
      start: startTimestamp,
      end: endTimestamp,
      summary,
      description,
      location,
      reportName: report.name,
    };

    return getExportZip(source, body)
      .then(data => {
        const blob = new Blob([data], {
          type: COMMON_MIME_TYPES.zip,
        });
        downloadBlob(blob, `${title}_${relativeStartTime}_${relativeEndTime}.zip`);
      })
      .catch(this.onExportClipError);
  };

  onExportClipError = err => {
    const message =
      err.response.status === 400
        ? 'Video export on clips longer than 60 seconds not yet supported.'
        : 'Unable to export selected video clip.';
    renderAlert(message);
  };

  handleLocationChange = location => this.setState({ location });

  setSelectedReport = (e, { id, name }) => {
    e.preventDefault();
    this.setState({
      report: { name, id },
    });
  };

  onChangeReportName = (e, { newValue }) => {
    const report = Object.values(this.props.reportModels).find(p => p.name === newValue);
    if (report) {
      this.setState({ report });
    } else {
      this.setState({ report: { id: null, name: newValue } });
    }
  };

  onClose = () => this.setState(DEFAULT_STATE);

  onSceneChanged = () => {
    const scene = buildSceneState(this.props.scene, this.props.reportModels);
    this.setState(scene);
  };

  onToggleSaveAsVideo = () => this.setState(state => ({ saveAsVideo: !state.saveAsVideo }));

  onUpdate = prop => e => this.setState({ [prop]: e.currentTarget.value });

  onChangeStartTime = e => {
    const seconds = Number(e.target.value);
    const offset = this.state.targetTime - seconds * 1000;
    let start = offset;
    if (offset < 0 || seconds < 0) start = 0;
    this.setState({ startTime: start });
    this.checkError(start, this.state.endTime);
  };

  onChangeEndTime = e => {
    const seconds = Number(e.target.value);
    const offset = this.state.targetTime + seconds * 1000;
    const duration = this.getDuration();
    let end = offset;
    if (offset > duration * 1000 || seconds < 0) end = duration * 1000;
    this.setState({ endTime: end });
    this.checkError(this.state.startTime, end);
  };

  checkError = (start, end) => {
    const startRounded = Math.ceil(Math.round(start) / 1000);
    const endRounded = Math.ceil((end - ((this.getDuration() * 1000) % 1000)) / 1000);
    if (endRounded - startRounded > 60) {
      this.setState({
        errorMessage: 'Video export on clips longer than 60 seconds not yet supported.',
      });
    } else {
      this.setState({ errorMessage: '' });
    }
  };

  onSaveScene = e => {
    e.preventDefault();
    this.setState({ isSaving: true });
    const scene = buildScene(this.props.scene, this.state, this.getTransformValue());
    const { report } = this.state;

    return this.props
      .onSaveScene({ scene, report })
      .then(this.handleClose)
      .catch(this.handleError)
      .finally(() => this.setState({ isSaving: false }));
  };

  getImage = () => {
    const { image, scene } = this.props;
    return (
      image || // For create
      (this.isDatasourceAvailable() && scene.datasource) || // For edit, not deleted
      scene.archivedSource || // For edit, deleted
      {}
    );
  };

  getDuration = () => (this.video ? this.video.getDuration() : null);

  getNewSceneTime = () => {
    const { source } = this.props;
    const dsDate = source.date || source.internalDate;

    return dsDate ? new Date(dsDate).getTime() + this.state.targetTime : null;
  };

  handleError = err => {
    const { error, message } = extractMirageFormError(err);
    renderError({ message: error || message, severity: 'error' });
  };

  renderSubtext = ({ label, value, ...rest }) => (
    <div className={this.props.classes.subtext} {...rest}>
      {value ? `${label}: ${value}` : label}
    </div>
  );

  getTransformValue = () => {
    const panAndZoomEl = document.getElementById('VideoPlayer-pan-and-zoom');
    const scenePlayer = document.getElementById('ScenePlayer-VideoContainer');

    let newTransform = '';
    if (panAndZoomEl && scenePlayer) {
      newTransform = calculateNewTransformValue(panAndZoomEl, scenePlayer);
    }

    return this.props?.scene?.transform || newTransform || panAndZoomEl?.style?.transform;
  };

  // We can potentially add in an override, i.e. this.props.renderScenePlayer(), which overrides the
  // default scene player behavior, but I think this is okay for now
  renderSource = () => {
    const { isLoading, scene, classes } = this.props;

    if (scene.imageSource) {
      const imageURL = `${ANALYTICS_BACKEND}/images/${scene.imageSource}/file`;

      return (
        <div className={classes.imageWrapper}>
          <img src={imageURL} alt="screen capture" className={classes.sceneImageStyle} />
        </div>
      );
    }

    const showControls = isEmpty(scene.archivedSource) && this.state.saveAsVideo;

    return (
      <ScenePlayer
        bindRef={this.bindRef}
        targetTime={this.state.targetTime}
        startTime={this.state.startTime}
        endTime={this.state.endTime}
        sourceId={scene.source}
        imageUrl={scene?.imageUrl}
        archivedSceneUrl={scene?.archivedSceneUrl}
        isLoading={isLoading}
        showControls={showControls}
        saveAsVideo={this.state.saveAsVideo}
        transform={this.getTransformValue()}
        onChangeStartTime={this.onChangeStartTime}
        onChangeEndTime={this.onChangeEndTime}
        onToggleSaveAsVideo={this.onToggleSaveAsVideo}
        errorMessage={this.state.errorMessage}
      />
    );
  };

  renderBody = () => {
    const {
      classes,
      source,
      scene: { sceneTime = this.getNewSceneTime(), utcOffsetSec, datasource },
    } = this.props;
    const location = getLocationString(this.state.location);
    const isImage = this.isDatasourceAnImage();
    const { displayTimezoneName } = datasource || source;
    const formattedDate =
      !!sceneTime &&
      (isImage // MIR-8565: Image dates require special formatting
        ? formatImageDate(sceneTime, utcOffsetSec, {
            showDayOfWeek: true,
            showSeconds: true,
            displayTimezoneName,
          })
        : parseToShorthandDate(sceneTime, {
            showDayOfWeek: true,
            showSeconds: true,
            showTimeZone: true,
            displayTimezoneName,
          }));

    const isSceneNotesLengthValid = isLengthValid(
      reportConstants.SCENE_NOTES_MAX_LENGTH,
      this.state.description.length
    );

    const isSceneTitleLengthValid = isLengthValid(
      reportConstants.SCENE_TITLE_MAX_LENGTH,
      this.state.summary.length
    );

    return (
      <div style={{ display: 'flex', margin: '10px' }}>
        <form
          id={SAVE_EDIT_SCENE_FORM}
          className={classes.sceneMetaData}
          onSubmit={this.onSaveScene}
        >
          <ReportAutoSuggest
            renderInput={this.renderReportTextInput}
            value={this.state.report.name}
            onChange={this.onChangeReportName}
            onSuggestionSelected={this.setSelectedReport}
            required
          />
          <TextInput
            required
            className={classes.input}
            startAdornment={
              <Icon size="small" iconName="edit" theme="grey" style={{ marginRight: 4 }} />
            }
            value={this.state.summary}
            onChange={this.onUpdate('summary')}
            label={reportConstants.SCENE_TITLE_LABEL}
            maxLength={reportConstants.SCENE_TITLE_MAX_LENGTH}
            error={!isSceneTitleLengthValid}
            helperText={
              isSceneTitleLengthValid
                ? ''
                : exceedsLimitErrorMessage(
                    reportConstants.SCENE_TITLE_ERROR_LABEL,
                    reportConstants.SCENE_TITLE_MAX_LENGTH
                  )
            }
            data-testid="SaveEditScene-sceneTitle"
          />
          <TextInput
            multiline
            rows={10}
            type="text"
            className={classes.input}
            value={this.state.description}
            maxLength={reportConstants.SCENE_NOTES_MAX_LENGTH}
            error={!isSceneNotesLengthValid}
            helperText={
              isSceneNotesLengthValid
                ? ''
                : exceedsLimitErrorMessage(
                    reportConstants.SCENE_NOTES_ERROR_LABEL,
                    reportConstants.SCENE_NOTES_MAX_LENGTH
                  )
            }
            onChange={this.onUpdate('description')}
            label={reportConstants.SCENE_NOTES_LABEL}
            InputLabelProps={{ shrink: true }}
            data-testid="SaveEditScene-sceneNotes"
          />
          <LocationInput
            fullWidth
            placeholder="Enter Location"
            startAdornment={
              <Icon size="small" iconName="room" theme="grey" style={{ marginRight: 4 }} />
            }
            value={location}
            className={classes.input}
            onPlacesChanged={this.handleLocationChange}
            data-testid="SaveEditScene-Location"
          />
          {this.renderSubtext({
            label:
              'Locations selected from dropdown menu and Lat/Longs entered in DDS format will appear on the report map.',
          })}
          {location &&
            this.renderSubtext({
              label: 'Data source location',
              value: location,
            })}
          {formattedDate &&
            this.renderSubtext({
              label: 'Scene date',
              value: formattedDate,
              'data-testid': 'SaveEditScene-sceneDate',
            })}
        </form>
        {this.renderSource()}
      </div>
    );
  };

  renderHeader = () => (
    <header className={this.props.classes.header}>
      <div style={{ width: 16 }} />
      <Typography variant="h3">Scene Details</Typography>
      <div
        onClick={this.handleClose}
        className={this.props.classes.cancel}
        role="button"
        tabIndex="0"
      >
        <Icon iconName="close" />
      </div>
    </header>
  );

  renderReportTextInput = inputProps => {
    const isReportNameLengthValid = isLengthValid(
      reportConstants.REPORT_NAME_MAX_LENGTH,
      inputProps.value.length
    );

    return (
      <TextInput
        startAdornment={
          <Icon size="small" iconName="list_alt" theme="grey" style={{ marginRight: 4 }} />
        }
        className={this.props.classes.input}
        label={reportConstants.REPORT_NAME_LABEL}
        required
        inputProps={{ ...inputProps }}
        error={!isReportNameLengthValid}
        helperText={
          isReportNameLengthValid
            ? ''
            : exceedsLimitErrorMessage(
                reportConstants.REPORT_NAME_ERROR_LABEL,
                reportConstants.REPORT_NAME_MAX_LENGTH
              )
        }
        data-testid="SaveEditScene-reportName"
      />
    );
  };

  renderFooter = () => (
    <footer className={this.props.classes.footer}>
      <Button
        variant="outlined"
        onClick={this.handleClose}
        data-testid="SaveEditScene-CancelButton"
      >
        CANCEL
      </Button>
      <Button
        disabled={!this.isDatasourceAvailable()}
        onClick={this.handleExport}
        variant="outlined"
        data-testid="SaveEditScene-ExportButton"
      >
        EXPORT
      </Button>
      <Button
        disabled={this.checkFormError()}
        type="submit"
        form={SAVE_EDIT_SCENE_FORM}
        data-testid="SaveEditScene-SaveButton"
      >
        SAVE
      </Button>
    </footer>
  );

  checkIfFieldisDisabled = item => {
    const value = FORM_FIELDS[item].name ? this.state[item].name : this.state[item];

    return value.length > FORM_FIELDS[item].width || (!value.length && FORM_FIELDS[item].required);
  };

  checkFormError = () => {
    const { isSaving } = this.state;

    Object.keys(FORM_FIELDS).filter(item => this.checkIfFieldisDisabled(item, FORM_FIELDS));

    return Object.keys(FORM_FIELDS).filter(item => this.checkIfFieldisDisabled(item)).length
      ? true
      : isSaving;
  };

  render() {
    const { classes, open } = this.props;
    return (
      <Dialog
        open={open}
        onMouseDown={e => {
          e.stopPropagation();
          this.handleClose();
        }}
        onClose={this.handleClose}
        PaperProps={PaperProps}
        fullScreen
      >
        <div className={classes.content} onMouseDown={e => e.stopPropagation()} role="none">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = {
  dispatchDefaultFetchReports: defaultFetchReports,
};

const mapStateToProps = ({ common }) => ({
  reportModels: common.models.reports,
});

const withProps = connect(mapStateToProps, mapDispatchToProps)(SaveEditScene);

export default injectSheet(styles)(withRouter(withProps));
