export function getCoordsFromDrawing(drawing) {
  if (!drawing) {
    return null;
  }

  if (drawing.type === 'rectangle') {
    const bounds = drawing.overlay.getBounds();

    const topLeftLng = bounds.b.f;
    const bottomRightLat = bounds.f.f;
    const bottomRightLng = bounds.b.b;
    const topLeftLat = bounds.f.b;

    return [
      // top_left
      {
        latitude: topLeftLat,
        longitude: topLeftLng,
      },
      // top_right
      {
        latitude: bottomRightLat,
        longitude: topLeftLng,
      },
      // bottom_right
      {
        latitude: bottomRightLat,
        longitude: bottomRightLng,
      },
      // bottom_left
      {
        latitude: topLeftLat,
        longitude: bottomRightLng,
      },
    ];
  }
  if (drawing.type === 'polygon') {
    return drawing.overlay.getPath().b.map(coord => ({
      latitude: coord.lat(),
      longitude: coord.lng(),
    }));
  }
  throw Error(`Not expecting drawing of type: ${drawing.type}`);
}
