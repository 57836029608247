import { batchActions } from 'redux-batched-actions';
import { cloneDeep, isEqual } from 'lodash';

import * as DeprecatedFolderApi from 'common/api/folder/v1';
import {
  getProcessingData,
  cancelJob,
  reprioritizeJob,
  cancelJobGroup,
  resumeJob,
} from 'common/api/vidprocApi';
import { endDataSourceUpload } from 'common/api/datasourceApi';
import { extractErrorMessage } from 'common/helpers/apiErrorUtils';
import { renderErrorMessage } from 'app/redux/actions';
import { selectIsGeoActive } from 'settings/redux/selectors';
import * as CommonModelSelectors from 'common/redux/models/selectors';
import * as CommonModelActions from 'common/redux/models/actions';
import * as LibrarySelectors from 'library/redux/selectors';
import * as ProcessingPanelSelectors from 'library/common/globalProcessingPanel/redux/selectors';
import { JOB_FILTER_PARAM } from 'library/common/globalProcessingPanel/constants';
import { FILE_UPLOAD_CHUNK_SIZE } from 'library/redux/constants';

import { hasBatchContentsChanged, processGeoJobs } from './utils';

export const toggleOpenedFolder = ({ key, isOpened }) =>
  function toggleOpenedFolderThunk(dispatch, getState) {
    const folderModels = CommonModelSelectors.selectFolderV2Models(getState());

    if (isOpened) {
      const subfolders = folderModels[key]?.folders;
      const subFoldersToFetch = subfolders?.filter(id => !folderModels[id]);

      if (subFoldersToFetch?.length) {
        dispatch(CommonModelActions.fetchFoldersV2({ ids: subFoldersToFetch.join(',') }));
      }
    }

    dispatch({
      type: 'LIBRARY/TOGGLE_OPEN_FOLDER',
      isOpened,
      key,
    });
  };

export const setCurrentLibraryKey = id =>
  function setCurrentLibraryKeyThunk(dispatch, getState) {
    const state = getState();

    const currentLibraryKey = CommonModelSelectors.selectFolderIdOfCurrentLibrary(state, id);

    return dispatch({
      type: 'LIBRARY/SET_CURRENT_LIBRARY_KEY',
      currentLibraryKey,
    });
  };

export const setFolderMoveInfo = moveInfo => ({
  type: 'LIBRARY/SET_FOLDER_MOVE_INFO',
  moveInfo,
});

const setProcessingJobs = jobs => ({
  type: 'LIBRARY/SET_PROCESSING_JOBS',
  payload: jobs,
});

const setUploadingJobs = payload => ({
  type: 'LIBRARY/SET_UPLOADING_JOBS',
  payload,
});

const setUploadingImages = payload => ({
  type: 'LIBRARY/SET_UPLOADING_IMAGES',
  payload,
});

/* ========================= THUNK ACTION CREATORS ========================== */

export function fetchProcessingData() {
  return function fetchProcessingDataThunk(dispatch, getState) {
    const isGeoActive = selectIsGeoActive(getState());
    const params = ProcessingPanelSelectors.getPaginatedParameters(getState());

    if (isGeoActive && params[JOB_FILTER_PARAM]) delete params[JOB_FILTER_PARAM];
    return getProcessingData(params).then(_newProcessingData => {
      const state = getState();
      const prevProcessingData = LibrarySelectors.selectProcessingJobs(state);
      const oldUploadingJobs = LibrarySelectors.selectUploadingJobs(state);
      const uploadingJobs = cloneDeep(oldUploadingJobs);

      const actions = [];

      if (!isEqual(oldUploadingJobs, uploadingJobs)) {
        actions.push(setUploadingJobs(uploadingJobs));
      }

      const newProcessingData = isGeoActive
        ? processGeoJobs(_newProcessingData)
        : _newProcessingData;
      if (hasBatchContentsChanged(prevProcessingData, newProcessingData)) {
        actions.push(setProcessingJobs(newProcessingData));
      }

      if (actions.length) {
        dispatch(batchActions(actions));
      }
    });
  };
}

export const removeUploadingVideo = key => (dispatch, getState) => {
  const payload = { ...getState().library.main.uploadingVideos };
  delete payload[key];
  return dispatch(setUploadingJobs(payload));
};

export const removeUploadingJobGroup = ({ id }) => (dispatch, getState) => {
  const payload = { ...getState().library.main.uploadingImages };
  delete payload[id];

  return dispatch(setUploadingImages(payload));
};

export const cancelUploadingJobGroup = ({ id }) => dispatch => {
  dispatch(removeUploadingJobGroup({ id }));
  endDataSourceUpload(id, { state: 'failed' });
};

export const updateUploadingImages = normalizeUploadImageInfo => (dispatch, getState) => {
  const uploadingImages = {
    ...getState().library.main.uploadingImages,
    [normalizeUploadImageInfo.txID]: normalizeUploadImageInfo,
  };

  return dispatch(setUploadingImages(uploadingImages));
};

export const setUploadImageProgress = (
  txID,
  totalCount,
  index,
  fileProgress,
  { loaded, total }
) => (dispatch, getState) => {
  fileProgress[index] = (loaded * 100) / total;
  const totalPercent = fileProgress
    ? Object.values(fileProgress).reduce((sum, num) => sum + num, 0)
    : 0;
  const percent = Math.ceil(totalCount / FILE_UPLOAD_CHUNK_SIZE);
  const percentProgress = parseInt(Math.round(totalPercent / percent), 10);
  const payload = { ...getState().library.main.uploadingImages };

  payload[txID] = {
    ...payload[txID],
    percentProgress,
  };

  return dispatch(setUploadingImages(payload));
};

export const cancelProcessingJob = ({ id }) => dispatch =>
  cancelJob(id).then(() => dispatch(fetchProcessingData()));

export const restartProcessingJob = ({ id }) => dispatch =>
  resumeJob(id).then(() => dispatch(fetchProcessingData()));

export const cancelProcessingJobGroup = jobs => dispatch =>
  cancelJobGroup(jobs).then(() => dispatch(fetchProcessingData()));

export const reprioritizeProcessingJob = body => dispatch =>
  reprioritizeJob(body).then(() => dispatch(fetchProcessingData()));

export function removeFolderItem(folderItemId, folderId) {
  return async function removeFolderItemThunk(dispatch) {
    try {
      await DeprecatedFolderApi.deleteFolderItem(folderItemId);

      await dispatch(CommonModelActions.fetchFoldersV2({ ids: folderId }));
    } catch (err) {
      const message = extractErrorMessage(err);

      renderErrorMessage(message);
    }
  };
}
