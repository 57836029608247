import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { renderError } from 'app/redux/actions';
import { getUsersQuery } from 'common/api/userApi';
import CircularLoader from 'common/components/base/CircularLoader';
import TextInput from 'common/components/base/TextInput';
import UserNameWithIcon from 'common/components/generalComponents/UserNameWithIcon';
import { getConsistentUserColor } from 'common/redux/models/getRandomUserColor';

const useUsers = (searchString, maxResultsShown) => {
  const [state, setState] = useState({ isLoading: false, searchString: null, users: [] });

  useEffect(() => {
    const fetcher = async () => {
      setState({ ...state, isLoading: true, searchString });

      let fetchedUsers = [];
      try {
        const res = await getUsersQuery({
          page: 1,
          page_size: maxResultsShown,
          search: searchString,
          sort_by: 'username',
        });
        fetchedUsers = res.data;
      } catch (e) {
        renderError({ code: 'Users', message: 'Unable to fetch', severity: 'error' });
      }
      setState({ isLoading: false, searchString, users: fetchedUsers });
    };

    if (!state.isLoading && searchString !== state.searchString) {
      fetcher();
    }
  }, [state, searchString, maxResultsShown]);

  return state;
};

const renderOptionDefault = option => (
  <UserNameWithIcon name={option.username} color={getConsistentUserColor(option)} />
);

const UserSelect = ({
  disabledUserIds,
  filteredUserIds,
  maxResultsShown,
  onChange,
  placeholder,
  renderOption,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchString, setSearchString] = useState('');
  const { isLoading, users } = useUsers(searchString, maxResultsShown);
  const filteredUsers = users.filter(u => !filteredUserIds.includes(u.id));

  const onInputChange = debounce((__event, value) => setSearchString(value), 250, {
    maxWait: 1000,
  });

  const onSelectionMade = (__event, value) => onChange(value);

  const isOptionDisabled = option => disabledUserIds.includes(option.id);

  const isOptionSelected = (option, val) => option.id === val.id;

  const renderInput = params => (
    <TextInput
      {...params}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading ? <CircularLoader size={16} variant="indeterminate" /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      label={open || isFocused || searchString ? '' : placeholder}
      placeholder={placeholder}
    />
  );

  return (
    <Autocomplete
      blurOnSelect
      clearOnBlur
      getOptionDisabled={isOptionDisabled}
      getOptionLabel={option => option.username}
      getOptionSelected={isOptionSelected}
      id="userSelect"
      loading={isLoading}
      noOptionsText="No users match this search criteria"
      onBlur={() => setIsFocused(false)}
      onChange={onSelectionMade}
      onClose={() => setOpen(false)}
      onFocus={() => setIsFocused(true)}
      onInputChange={onInputChange}
      onOpen={() => setOpen(true)}
      open={open}
      options={filteredUsers}
      renderInput={renderInput}
      renderOption={renderOption}
      selectOnFocus
      {...rest}
    />
  );
};

UserSelect.propTypes = {
  disabledUserIds: PropTypes.arrayOf(PropTypes.number),
  filteredUserIds: PropTypes.arrayOf(PropTypes.number),
  maxResultsShown: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  renderOption: PropTypes.func,
};

UserSelect.defaultProps = {
  disabledUserIds: [],
  filteredUserIds: [],
  maxResultsShown: 10,
  placeholder: 'Select User to Add...',
  renderOption: renderOptionDefault,
};

export default UserSelect;
