import noop from 'lodash/noop';
import { createUseStyles } from 'react-jss';

import Typography from 'components/Typography';

import { LIGHT_BLUE, REGENT_GREY, DEFAULT_BACKGROUND } from 'common/constants/colors';
import InspectTypesSelect from 'common/components/generalComponents/inputs/InspectTypesSelect';

const useStyles = createUseStyles(theme => ({
  inspectOverlay: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    padding: theme.spacing(0, 1.5),
    border: `1px solid ${LIGHT_BLUE}`,
    background: LIGHT_BLUE,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 10, // Needs to be above kinetic seek overlay
  },
  inspectTypes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  inspectTypesSelect: {
    margin: theme.spacing(0, 0.5),
    height: 'unset',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    background: DEFAULT_BACKGROUND,
  },
  select: {
    color: REGENT_GREY,
    fontSize: 10,
    padding: 0,
    height: 'unset',
  },
}));

const InspectOverlay = ({
  confidenceThreshold,
  hideInspectOverlay = false,
  inspecting,
  inspectModeTypes,
  onInspectTypesSelection = noop,
}) => {
  if (hideInspectOverlay || !inspecting) return null;

  const classes = useStyles();

  return (
    <div className={classes.inspectOverlay}>
      <Typography variant="subtitle2">Inspect Mode</Typography>
      <div className={classes.inspectTypes}>
        <Typography variant="subtitle2">Showing additional</Typography>
        <InspectTypesSelect
          className={classes.inspectTypesSelect}
          classes={{ select: classes.select }}
          inspectModeTypes={inspectModeTypes}
          onSelection={onInspectTypesSelection}
        />
        <Typography variant="subtitle2">
          detections above {confidenceThreshold}% confidence.
        </Typography>
      </div>
    </div>
  );
};

export default InspectOverlay;
