import React from 'react';
import { withStyles } from 'tss-react-local';

import { PRIMARY } from 'common/constants/colors';

import DateTimeRangePickerInput from 'common/components/pickers/DateTimeRangePickerInput';
import DateTimeRangePickerChip from 'common/components/pickers/DateTimeRangePickerChip';
import DateTimePickerInput from 'common/components/pickers/DateTimePickerInput';
import TimeRangePicker from 'common/components/pickers/TimeRangePickerChip';
import NewPicker from './NewPicker';

import { Example } from '../helpers';

const styles = {
  main: {
    width: 300,
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 3,
  },
  header: {
    display: 'flex',
    background: PRIMARY,
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    padding: '0 8px 5px 8px',
  },
  picker: {
    '& > div': {
      boxShadow: 'none',
      border: 0,
    },
  },
  date: {
    fontWeight: 300,
  },
};

const FIVE_MINUTES = 5 * 60 * 1000;

class PickersExample extends React.Component {
  state = {
    startTime: null,
    endTime: null,

    startDate: null,
    endDate: null,
    dateTime: null,
  };

  onDateRangeChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  onTimeRangeChange = ({ startTime, endTime }) => {
    this.setState({ startTime, endTime });
  };

  onDateTimeChange = dateTime => {
    this.setState({ dateTime });
  };

  render() {
    return (
      <div>
        <Example label="Time Picker">
          <TimeRangePicker
            startTime={this.state.startTime}
            endTime={this.state.endTime}
            onChange={this.onTimeRangeChange}
          />
          <TimeRangePicker
            disabled
            startTime={this.state.startTime}
            endTime={this.state.endTime}
            onChange={this.onTimeRangeChange}
          />
        </Example>
        <Example label="Date Time Range">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DateTimeRangePickerInput
              className={this.props.classes.input}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.onDateRangeChange}
              showSeconds={false}
            />
            <DateTimeRangePickerChip
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.onDateRangeChange}
              minTimespan={FIVE_MINUTES}
            />
            <DateTimeRangePickerChip
              disabled
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.onDateRangeChange}
            />
          </div>
        </Example>
        <Example label="Date Time Picker">
          <DateTimePickerInput
            className={this.props.classes.input}
            date={this.state.dateTime}
            onChange={this.onDateTimeChange}
          />
        </Example>

        <Example label="Current Picker">
          <DateTimeRangePickerInput
            dateRange={[this.state.startTime, this.state.endTime]}
            showSeconds={false}
          />
        </Example>

        <Example label="New Picker">
          <br />
          <NewPicker />
        </Example>
      </div>
    );
  }
}

export default withStyles(PickersExample, styles);
