import { isFinite } from 'lodash';

export const DEFAULT_TOOLTIP_STRINGS = {
  add: 'Click to draw next point',
  complete: 'Click to complete zone',
  delete: 'Click to delete point',
  start: 'Click to start drawing zone',
};

export const getTooltipText = ({
  zones = [],
  areZonesComplete,
  hoveringPoint,
  tooltipStrings = DEFAULT_TOOLTIP_STRINGS,
}) => {
  if (!hoveringPoint) return '';
  const { zoneIndex, pointIndex, inactivePoint } = hoveringPoint;
  if (inactivePoint) return '';

  const zonesLength = zones.length;
  const inProgress = zonesLength > 0 && !areZonesComplete;
  const isStarting = zonesLength === 0 || areZonesComplete;
  const isDeletePoint = zonesLength > 0 && isFinite(zoneIndex) && isFinite(pointIndex);
  const isCompletePoint =
    zoneIndex === zonesLength - 1 && pointIndex === 0 && zones[zoneIndex].length > 1;

  if (isCompletePoint) return tooltipStrings.complete;
  if (isDeletePoint) return tooltipStrings.delete;
  if (inProgress) return tooltipStrings.add;
  if (isStarting) return tooltipStrings.start;
  return '';
};

export const getRectangleBounds = ({ topLeft, bottomRight, dimension }) => {
  const edgeLength = Math.max(
    Math.abs(bottomRight[0] - topLeft[0]),
    Math.abs(bottomRight[1] - topLeft[1])
  );

  return {
    x: Math.min(topLeft[0], bottomRight[0]) / dimension.width,
    y: Math.min(topLeft[1], bottomRight[1]) / dimension.height,
    w: edgeLength / dimension.width,
    h: edgeLength / dimension.height,
  };
};
