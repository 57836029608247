import { divIcon } from 'leaflet';

import { DS_TYPE_IMAGE } from 'common/constants/app';
import { MAP_PIN_IMAGE, MAP_PIN_VIDEO } from 'common/constants/colors';

import './pinStyles.css';

// This icon takes a dynamic argument to render numbers/labels over the pin image.
export const reportPinIcon = ({ isSelected, type, children }) =>
  divIcon({
    className: 'report-pin',
    iconAnchor: isSelected ? [18, 36] : [15, 30],
    iconSize: isSelected ? [36, 36] : [30, 30],
    html: `<p style="font-size:${
      isSelected ? '14px' : '12px'
    };position:absolute;top:2px;left:50%;transform:translateX(-50%);color:white;z-index:300;">${children}</p><svg viewBox="0 0 50 72" style="width:100%;height:100%;"><path fill=${
      type === DS_TYPE_IMAGE ? MAP_PIN_IMAGE : MAP_PIN_VIDEO
    } d="M25,0 C11.2142857,0 0,11.304 0,25.2 C0,44.1 25,72 25,72 C25,72 50,44.1 50,25.2 C50,11.304 38.7857143,0 25,0 Z"></path></svg>`,
  });
