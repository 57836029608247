const defaults = {
  allData: [],
  isEditModeEnabled: false,
  zoneBeingEdited: {},
};

export default function cameraReducer(state = defaults, action) {
  switch (action.type) {
    case 'LIBRARY/CAMERA/SET_ALL_DATA': {
      return {
        ...state,
        allData: action.payload,
      };
    }
    case 'LIBRARY/CAMERA/TOGGLE_EDIT_MODE': {
      return {
        ...state,
        isEditModeEnabled: !state.isEditModeEnabled,
        zoneBeingEdited: action.payload,
      };
    }
    default:
      return state;
  }
}
