import React, { Suspense, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Redirect, Route, Switch } from 'react-router-dom';
import { Cookies } from 'react-cookie';

import { extractMirageFormError } from 'common/helpers/apiErrorUtils';
import { setAuthError, checkAuthError } from 'common/helpers/authUtils';
import { postOpenIDAuth } from 'common/api/authApi';
import { lazyWithPreload, hasValidBrowser } from 'common/helpers/networkUtils';
import * as myQs from 'common/helpers/queryString';
import { fetchCurrentUser, fetchOpenIdConfig, setIsOIDCSession } from 'auth/redux/actions';
import { selectShowAbout, selectCanAccessExamples, selectDefaultRoute } from 'app/redux/selectors';
import { initSettings, fetchCustomerSettings } from 'settings/redux/actions';
import { selectIsAuthenticated, selectHasPercipientEmail } from 'auth/redux/selectors';
import { updateMirageHealth } from 'app/header/mirageHealth/redux/actions';

import '__testSetup__/utils';

import {
  ANALYSIS_ROUTE,
  GEO_ALERTING_ROUTE,
  LIBRARY_FMV_ROUTE,
  LIBRARY_GEO_ROUTE,
  LIVE_MONITOR_ROUTE,
  PLAN_GEOSPATIAL_ROUTE,
  PLAN_ANALYSIS_ROUTE,
  PLAN_MONITOR_ROUTE,
  SETTINGS_ROUTE,
  REPORTS_ROUTE,
  INCREMENTAL_TRAINING_ROUTE,
  MINT_ROUTE,
  ANALYSIS_SIFT_ROUTE,
} from 'common/constants/urls';

import Delay from 'common/components/base/Delay';
import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import Examples from '__examples__/Examples';

import About from 'common/components/popups/about/About';
import Auth from 'auth/Auth';

import BrowserErrorPage from './errorpage/BrowserErrorPage';
import SideBar from './nav/SideBar';
import AppPopups from './AppPopups';
import AppRoot, { AppBody, AppContent } from './AppRoot';
import { ClassificationHeader, ClassificationFooter } from './ClassificationBanner';
import useAuthenticationIdleTimer from './hooks/useAuthenticationIdleTimer';
import useOIDCSessionRefreshPolling from './hooks/useOIDCSessionRefreshPolling';
import useGlobalSignoutListener from './hooks/useGlobalSignoutListener';

import IdentityPackage from '../identityPackage/IdentityPackage';
import MissionDashboard from '../identityPackage/MissionDashboard';

/**
  The AuthRouter is loaded with the initial bundle so that it can download
  and render as soon as possible. Once the App/Auth page renders and the user
  authenticates the remaining files are fetched
*/
const AnalysisRouter = lazyWithPreload(() => import('analysis/AnalysisRouter'));

const PrintScene = lazyWithPreload(() => import('reports/printScene/PrintScene'));

const SettingsRouter = lazyWithPreload(() => import('settings/SettingsRouter'));
const LiveMonitoringRouter = lazyWithPreload(() => import('liveSearch/LiveMonitoringRouter'));
const PlanAnalysisRouter = lazyWithPreload(() => import('plan/PlanAnalysisRouter'));
const PlanMonitorRouter = lazyWithPreload(() => import('plan/PlanMonitorRouter'));
const PlanIdentifyRouter = lazyWithPreload(() => import('plan/identify/PlanIdentifyRouter'));
const PlanGeospatialRouter = lazyWithPreload(() => import('plan/geospatial/PlanGeospatialRouter'));
const LibraryFmvRouter = lazyWithPreload(() => import('library/fmv/LibraryFmvRouter'));
const LibraryGeoRouter = lazyWithPreload(() => import('library/LibraryGeoRouter'));
const ReportsRouter = lazyWithPreload(() => import('reports/ReportsRouter'));
const IncrementalTrainingRouter = lazyWithPreload(() =>
  import('incrementalTraining/IncrementalTrainingRouter')
);
const MintRouter = lazyWithPreload(() => import('mint/MintRouter'));
const GeoAlertingRouter = lazyWithPreload(() => import('geoAlerting/GeoAlertingRouter'));

function preloadSiteFiles() {
  AnalysisRouter.preload();
  PrintScene.preload();
  SettingsRouter.preload();
  LibraryFmvRouter.preload();
  LibraryGeoRouter.preload();
  LiveMonitoringRouter.preload();
  PlanAnalysisRouter.preload();
  PlanMonitorRouter.preload();
  PlanIdentifyRouter.preload();
  PlanGeospatialRouter.preload();
  ReportsRouter.preload();
  IncrementalTrainingRouter.preload();
  MintRouter.preload();
  GeoAlertingRouter.preload();
}

const Fallback = (
  <Delay>
    <LoadingOverlay description="Loading Mirage" />
  </Delay>
);

const handleError = err => {
  const { message } = extractMirageFormError(err);
  const isValidMessage = message && typeof message === 'string';

  setAuthError(isValidMessage ? message : 'An unknown error occurred');
};

const MirageUI = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [initializing, setInitializing] = useState(true);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const hasPercipientEmail = useSelector(selectHasPercipientEmail);
  const showAbout = useSelector(selectShowAbout);
  const canAccessExamples = useSelector(selectCanAccessExamples);
  const defaultRoute = useSelector(selectDefaultRoute);

  // We need to use `window.location` currently as Google's redirect + React-Router's
  // HashRouter create a malformed URL that React-Router can't correctly parse the
  // location.search.
  const {
    query: { redirect: redirectRoute, code },
  } = myQs.parseUrl(window.location.href);

  useAuthenticationIdleTimer();
  useOIDCSessionRefreshPolling();
  useGlobalSignoutListener();

  // Init login should only fire on app startup as a way to check the current
  // authentication status. fetchCurrentUser() is called separately on sign in
  // after fetching a valid token.
  const initLogin = useCallback(() => {
    const requests = [dispatch(initSettings()), dispatch(fetchCurrentUser())];

    return Promise.all(requests).finally(() => {
      // Clear query params manually on login
      // (Same reasoning as above for using window.location)
      if (window.location.search) window.history.replaceState({}, '', `/${window.location.hash}`);
      setInitializing(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) preloadSiteFiles();
  }, [isAuthenticated]);

  useEffect(() => {
    // isOIDCSession comes out of localStorage as a string
    const isOIDCSession = localStorage.getItem('isOIDCSession');
    dispatch(setIsOIDCSession(isOIDCSession === 'true'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCustomerSettings());
    dispatch(fetchOpenIdConfig()).finally(() => {
      if (code) {
        postOpenIDAuth({ authorizationCode: code })
          .then(headers => {
            localStorage.setItem('isOIDCSession', true);
            dispatch(setIsOIDCSession(true));

            if (headers.location) {
              window.location.assign(headers.location);
              return;
            }
            initLogin();
          })
          .catch(handleError);
      } else {
        initLogin();
      }
    });

    checkAuthError();
  }, [code, dispatch, initLogin]);

  useEffect(() => {
    const cookies = new Cookies();

    // If user has old auth token in mirage-auth cookie delete it on app startup
    const legacyCookie = cookies.get('mirage-auth');
    if (legacyCookie) cookies.remove('mirage-auth');
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(updateMirageHealth());
      const intervalId = setInterval(() => dispatch(updateMirageHealth()), 30000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dispatch, isAuthenticated]);

  // Wait for initSettings response to avoid flicker of regular auth to auth with consent
  if (initializing) return null;

  if (isAuthenticated && !hasPercipientEmail && !hasValidBrowser()) {
    return <BrowserErrorPage />;
  }

  return (
    <AppRoot>
      <ClassificationHeader />
      {showAbout && <About />}

      <AppBody>
        <Suspense fallback={Fallback}>
          {!isAuthenticated ? (
            <Switch>
              <Route path="/auth" component={Auth} />
              <Route path="/auth/admin" component={Auth} />

              {/*
                  If the user is unauthenticated and attempts to go to any URL other than /auth routes
                  we redirect them to auth with the attempted destination encoded in the URL
                */}

              <Redirect exact from="/" to="/auth" />
              <Redirect
                to={{
                  pathname: '/auth',
                  search: myQs.stringifySearch({
                    redirect: encodeURIComponent(location.pathname + location.search),
                  }),
                }}
              />
            </Switch>
          ) : (
            <>
              <SideBar />

              <AppContent>
                <AppPopups />

                <Switch>
                  <Route
                    path="/examples"
                    component={Examples}
                    isAuthenticated={canAccessExamples}
                  />

                  {!!redirectRoute && <Redirect to={redirectRoute} />}

                  <Route path={PLAN_ANALYSIS_ROUTE} component={PlanAnalysisRouter} />
                  <Route path={PLAN_MONITOR_ROUTE} component={PlanMonitorRouter} />
                  <Route path="/plan/identify" component={PlanIdentifyRouter} />
                  <Route path={PLAN_GEOSPATIAL_ROUTE} component={PlanGeospatialRouter} />
                  <Route path={GEO_ALERTING_ROUTE} component={GeoAlertingRouter} />
                  <Route path={ANALYSIS_ROUTE} component={AnalysisRouter} />
                  <Route path={ANALYSIS_SIFT_ROUTE} component={AnalysisRouter} />
                  <Route path={REPORTS_ROUTE} component={ReportsRouter} />
                  <Route path={LIBRARY_GEO_ROUTE} component={LibraryGeoRouter} />
                  <Route path={LIBRARY_FMV_ROUTE} component={LibraryFmvRouter} />
                  <Route path={LIVE_MONITOR_ROUTE} component={LiveMonitoringRouter} />
                  <Route path={INCREMENTAL_TRAINING_ROUTE} component={IncrementalTrainingRouter} />
                  <Route path={MINT_ROUTE} component={MintRouter} />
                  <Route path={SETTINGS_ROUTE} component={SettingsRouter} />
                  <Route exact path="/print-scene" component={PrintScene} />
                  <Route path="/identity-package/:personId/:tabName?" component={IdentityPackage} />
                  <Route path="/mission/:libraryId/:depth" component={MissionDashboard} />

                  {defaultRoute && <Redirect to={defaultRoute} />}
                </Switch>
              </AppContent>
            </>
          )}
        </Suspense>
      </AppBody>

      {isAuthenticated && <ClassificationFooter />}
    </AppRoot>
  );
};

export default MirageUI;
