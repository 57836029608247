import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react-local';

import { BORDER_COLOR } from 'common/constants/colors';

const useStyle = makeStyles()({
  content: {
    height: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: 'white',
    justifyContent: 'center',
  },
  itemWrapper: {
    width: '40%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  message: {
    height: 16,
    lineHeight: '16px',
    width: '100%',
    textAlign: 'center',
    borderTop: `1px ${BORDER_COLOR} solid`,
  },
  wrapper: {
    width: 320,
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px ${BORDER_COLOR} solid`,
  },
});

const MapLegend = ({ className, items, message, ...rest }) => {
  const { classes, cx } = useStyle();

  return items ? (
    <div className={cx(classes.wrapper, className)} {...rest}>
      <div className={classes.content}>
        {items.map(({ icon, text }) => (
          <div className={classes.itemWrapper} key={text}>
            {icon}

            {text}
          </div>
        ))}
      </div>

      {message && <div className={classes.message}>{message}</div>}
    </div>
  ) : null;
};

MapLegend.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.string,
    })
  ).isRequired,
  message: PropTypes.string,
};

export default MapLegend;
