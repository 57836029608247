import React from 'react';

import JumpBack5sIcon from 'common/icons/jump-back-5-s';
import JumpBack90sIcon from 'common/icons/jump-back-90';
import JumpForward5sIcon from 'common/icons/jump-forward-5-s';
import JumpForward90sIcon from 'common/icons/jump-forward-90';
import PlaySpeed2xIcon from 'common/icons/playspeed-2-x';
import PlaySpeed4xIcon from 'common/icons/playspeed-4-x';

import emitter, { SHORTCUT_ANIMATION, VIDEOPLAYER_SEEK } from 'common/constants/emitter';
import {
  PLAY_SPEED_1X,
  PLAY_SPEED_2X,
  PLAY_SPEED_4X,
  SKIP_BACKWARD_1,
  SKIP_FORWARD_1,
  SKIP_BACKWARD_2,
  SKIP_FORWARD_2,
} from 'common/constants/shortcuts';

import SidebarBase from './SidebarBase';

const BASE_RATE = 1;

class VideoSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.onRate2x = this.createSetRate(2, PLAY_SPEED_2X);
    this.onRate4x = this.createSetRate(4, PLAY_SPEED_4X);
    this.onJump5s = this.createOnSeek(5, SKIP_FORWARD_1);
    this.onJump90s = this.createOnSeek(90, SKIP_FORWARD_2);
    this.onRewind5s = this.createOnSeek(-5, SKIP_BACKWARD_1);
    this.onRewind90s = this.createOnSeek(-90, SKIP_BACKWARD_2);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.inspecting && this.props.inspecting) {
      this.setRate(BASE_RATE);
    }
  }

  createOnSeek = (seconds, animation) => () => {
    const duration = this.props.video.getDuration();
    const frameLength = this.props.video.getFrameLength();
    let seekToTime = this.props.video.getTime() + seconds;

    // Seeking to anywhere less than 3 frames from the end of the video
    // causes the video player not to update to the appropriate frame
    if (seekToTime > duration - frameLength * 3) {
      seekToTime = duration - frameLength * 3;
    }
    if (seekToTime < 0) seekToTime = 0;

    emitter.emit(SHORTCUT_ANIMATION, animation);
    emitter.emit(VIDEOPLAYER_SEEK, seekToTime);
    this.props.seekTo(seekToTime);
  };

  createSetRate = (rate, animation) => () => {
    if (this.props.playbackRate === BASE_RATE && this.props.video.isPaused()) {
      this.props.play();
    }

    if (this.props.playbackRate === rate) {
      this.setRate(BASE_RATE);
      emitter.emit(SHORTCUT_ANIMATION, PLAY_SPEED_1X);
    } else {
      this.setRate(rate);
      emitter.emit(SHORTCUT_ANIMATION, animation);
    }
  };

  getOptions = () => {
    if (!this.props.video) return [];

    const { playbackRate } = this.props;

    return [
      {
        Icon: PlaySpeed2xIcon,
        isSelected: playbackRate === 2,
        onClick: this.onRate2x,
        tooltip: '2x play speed',
      },
      {
        Icon: PlaySpeed4xIcon,
        isSelected: playbackRate === 4,
        onClick: this.onRate4x,
        tooltip: '4x play speed',
      },
      {
        disabled: this.isJumpDisabled(5),
        Icon: JumpForward5sIcon,
        onClick: this.onJump5s,
        tooltip: 'Jump forward 5 sec. (Right arrow)',
      },
      {
        disabled: this.isJumpDisabled(-5),
        Icon: JumpBack5sIcon,
        onClick: this.onRewind5s,
        tooltip: 'Jump back 5 sec. (Left arrow)',
      },
      {
        disabled: this.isJumpDisabled(90),
        Icon: JumpForward90sIcon,
        onClick: this.onJump90s,
        tooltip: 'Jump forward 90 sec. (Shift + Right arrow)',
      },
      {
        disabled: this.isJumpDisabled(-90),
        Icon: JumpBack90sIcon,
        onClick: this.onRewind90s,
        tooltip: 'Jump back 90 sec. (Shift + Left arrow)',
      },
    ];
  };

  isJumpDisabled = seconds => {
    const duration = this.props.video.getDuration();
    const seekToTime = this.props.video.getTime() + seconds;

    return seekToTime > duration || seekToTime < 0;
  };

  setRate = rate => {
    this.props.setPlaybackRate(rate);
  };

  render() {
    return <SidebarBase options={this.getOptions()} {...this.props} />;
  }
}

export default VideoSidebar;
