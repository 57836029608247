import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import IdentityPackageLinkedPerson from './IdentityPackageLinkedPerson';
import { useIdentityPackage } from './IdentityPackageContext';
import IdentityLinkDialog from './IdentityLinkDialog';

const ExistingNetwork = () => {
  const { identityLinkIds } = useIdentityPackage();
  const { path } = useRouteMatch();

  return (
    <>
      <Grid container spacing={3}>
        {identityLinkIds.map(id => (
          <Grid item xs={3} key={id}>
            <IdentityPackageLinkedPerson linkId={id} />
          </Grid>
        ))}
      </Grid>
      <Route path={`${path}/link/:linkId`}>
        <IdentityLinkDialog closeUrl={path} />
      </Route>
    </>
  );
};

export default ExistingNetwork;
