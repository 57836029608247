import React from 'react';
import injectSheet from 'react-jss';
import Fade from '@material-ui/core/Fade';

import { OFF_BLACK } from 'common/constants/colors';
import { getHexadecimalOpacity } from 'common/helpers/colorHelpers';

const styles = {
  main: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: 10,
  },
  wrapper: {
    borderRadius: 3,
    padding: 6,
    background: getHexadecimalOpacity(OFF_BLACK, 0.8),
    position: 'absolute',
    fontSize: 10,
  },
  span: {
    color: 'white',
    display: 'block',
    whiteSpace: 'nowrap',
  },
};

const NOOP = () => {};

class ProgressBarOverlay extends React.Component {
  static defaultProps = {
    bindAbsoluteTimeRef: NOOP,
    bindRelativeTimeRef: NOOP,
    bindRef: NOOP,
  };

  _bindAbsoluteTimeRef = node => {
    this.props.bindAbsoluteTimeRef(node);
  };

  _bindRelativeTimeRef = node => {
    this.props.bindRelativeTimeRef(node);
  };

  render() {
    const { classes } = this.props;
    return (
      <Fade in={this.props.open}>
        <div className={classes.main} ref={this.props.bindRef}>
          <div className={classes.wrapper}>
            <span className={classes.span} ref={this._bindAbsoluteTimeRef} />
            <span className={classes.span} ref={this._bindRelativeTimeRef} />
          </div>
        </div>
      </Fade>
    );
  }
}

export default injectSheet(styles)(ProgressBarOverlay);
