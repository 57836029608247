import React from 'react';
import injectSheet from 'react-jss';
import NOOP from 'lodash/noop';
import isFinite from 'lodash/isFinite';
import throttle from 'lodash/throttle';

import { parseToShorthandDate, parseDurationToHHMMSS } from 'common/helpers/dateUtils';
import { PRIMARY_GREY, SECONDARY } from 'common/constants/colors';

import ProgressBarOverlay from './ProgressBarOverlay';
import { VIDEO_PROGRESS_BAR_MARGIN } from '../constants';

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    height: 36,
    flexGrow: 1,
  },
  input: {
    '-webkit-appearance': 'none',
    margin: `0 ${VIDEO_PROGRESS_BAR_MARGIN}px`,
    cursor: 'pointer',
    width: '100%',
    outline: 0,
    background: 'transparent',

    '&:disabled': {
      cursor: 'not-allowed',
    },

    '&::-webkit-slider-runnable-track': {
      background: PRIMARY_GREY,
      height: '4px',
      borderRadius: '2px',
    },

    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      position: 'relative',
      top: '-5px',
      height: '13px',
      width: '13px',
      borderRadius: '50%',
      background: SECONDARY,
      border: `1px solid white`,
    },

    '&:disabled::-webkit-slider-thumb': {
      background: 'rgb(165,165,165)',
    },
  },
};

const LIVE_RANGE_END = 1.03;

function buildRatio({ currentTarget, pageX }) {
  const { x, width } = currentTarget.getBoundingClientRect();

  return (pageX - x) / width;
}

class VideoProgressBar extends React.Component {
  static defaultProps = {
    offset: 0,
    onStartTimeChanged: NOOP,
  };

  constructor(props) {
    super(props);

    /* setTime is called by VideoPlayer onProgress event */
    this.setTime = throttle(this._updateValues, 100);
  }

  state = { showOverlay: false };

  componentDidMount() {
    /* JSS removes access to ref in layer above */
    this.props.bindRef(this);
    this.setTime(0);
  }

  injectGoToLiveText = () => {
    this.absoluteTime.innerHTML = 'Go to LIVE';
    this.relativeTime.innerHTML = '';
  };

  injectTimestamp = ({ currentTarget, pageX }, ratio) => {
    const { offset } = this.props;
    const { y } = currentTarget.getBoundingClientRect();
    const duration = this._getDuration();
    const relativeTime = parseDurationToHHMMSS(Math.round(duration * ratio * 1000));
    const absoluteTime = offset
      ? parseToShorthandDate(offset + duration * ratio * 1000, {
          showSeconds: true,
        })
      : '';

    if (this.absoluteTime && this.relativeTime && this.overlay) {
      this.overlay.style.left = `${pageX}px`;
      this.overlay.style.top = `${y - 16}px`;

      this.absoluteTime.innerHTML = absoluteTime;
      this.relativeTime.innerHTML = absoluteTime ? `+ ${relativeTime}` : relativeTime;
    }
  };

  shouldGoToLive = ratio =>
    this.props.isCameraRecording && !this.props.isLive && ratio > 1 && ratio <= LIVE_RANGE_END;

  _onMouseDown = () => {
    this._isMouseDown = true;
    this._wasPausedOnMouseDown = !this.props.isPlaying;
    this.props.pause();
  };

  _onMouseUp = e => {
    this._isMouseDown = false;

    // Seeking with the progress bar takes the user out of the LIVE view
    if (this.props.isLive) {
      this.props.toggleIsLive(false);
    }

    if (this.shouldGoToLive(buildRatio(e))) {
      this.props.toggleIsLive(true);
      // showOverlay must be set to false explicitly because the go-to-live
      // range is outside the timeline bounds so _onMouseLeave will not be
      // triggered
      this.setState({ showOverlay: false });
      return;
    }

    const value = Number(e.target.value);
    const duration = this._getDuration();
    const seconds = value === 1 ? duration - 0.01 : value * duration;

    this.props.seekTo(seconds);

    if (!this._wasPausedOnMouseDown) {
      /* Give video player a cycle to breathe */
      setTimeout(this.props.play);
    }

    this.props.onStartTimeChanged(seconds);
  };

  _onMouseMove = e => {
    const ratio = buildRatio(e);
    const isInBounds = ratio > 0 && ratio <= 1;
    const showGoToLive = this.shouldGoToLive(ratio);

    if (!isInBounds && !showGoToLive) {
      if (this.state.showOverlay) {
        this.setState({ showOverlay: false });
      }
      return;
    }

    if (!this.state.showOverlay) {
      this.setState({ showOverlay: true });
    }

    if (showGoToLive) {
      this.injectGoToLiveText();
      return;
    }

    this.injectTimestamp(e, ratio);
  };

  _onMouseLeave = () => {
    if (this.state.showOverlay) {
      this.setState({ showOverlay: false });
    }
  };

  _updateValues = seconds => {
    const { startTime } = this.props;
    if (!this._isMouseDown && isFinite(seconds)) {
      let value = 0;
      if (seconds > 0) {
        value = isFinite(startTime)
          ? (seconds - startTime) / this._getDuration()
          : seconds / this._getDuration();
      }

      if (this.rangeInput && isFinite(value)) {
        this.rangeInput.value = this.props.isNearLiveEdge ? 1 : value;
      }
    }
  };

  _getDuration = () => {
    const duration = this.props.getVideoDuration();
    return isFinite(duration) ? duration : 0;
  };

  render() {
    const { classes, disabled } = this.props;

    return (
      <div className={classes.wrapper}>
        <ProgressBarOverlay
          open={this.state.showOverlay}
          bindRef={node => (this.overlay = node)}
          bindAbsoluteTimeRef={node => (this.absoluteTime = node)}
          bindRelativeTimeRef={node => (this.relativeTime = node)}
        />
        <input
          name="video-progress-bar"
          type="range"
          step="any"
          min="0"
          max="1"
          ref={node => (this.rangeInput = node)}
          disabled={disabled}
          className={classes.input}
          // Input elements can be controlled by arrow keys but we have our
          // own logic in VideoControls.jsx, so disable default behavior
          onKeyDown={e => e.preventDefault()}
          onMouseMove={this._onMouseMove}
          onMouseLeave={this._onMouseLeave}
          onMouseUp={this._onMouseUp}
          onMouseDown={this._onMouseDown}
        />
      </div>
    );
  }
}

export default injectSheet(styles)(VideoProgressBar);
