import { useSelector } from 'react-redux';
import injectSheet from 'react-jss';

import Tooltip from 'common/components/base/Tooltip';
import LiveIcon from 'common/icons/ic-live-icon-filled';

import { DARK_GREY, REGENT_GREY, OFF_BLACK, TEXT, CYAN_BLUE } from 'common/constants/colors';
import { ICON_TO_TEXT } from 'library/redux/camera/constants';
import { selectIsLiveNGEnabled } from 'settings/redux/selectors';

import VideoControls from './VideoControls';
import VideoProgressBar from './VideoProgressBar';

import { VIDEO_CONTROL_WIDTH } from '../constants';

export const VIDEO_PLAYER_BOTTOM = 50;

const styles = {
  videoBottom: {
    display: 'flex',
    minHeight: VIDEO_PLAYER_BOTTOM,
    background: OFF_BLACK,
    borderRight: `1px solid ${OFF_BLACK}`,
  },
  videoControlWrapper: {
    background: OFF_BLACK,
    width: VIDEO_CONTROL_WIDTH,
  },
  videoProgressWrapper: {
    position: 'relative',
    bottom: 1,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    minWidth: 0,
  },
  liveIcon: {
    display: 'flex',
    alignItems: 'center',
    width: 48,

    '& > div': {
      display: 'flex',
    },
  },
  durationOverlay: {
    position: 'absolute',
    top: 8,
    fontSize: 10,
    color: 'white',
    fontWeight: 500,
    right: 20,
  },
};

const VideoControlsAndProgressBar = props => {
  const isLiveNGEnabled = useSelector(selectIsLiveNGEnabled);

  const renderLiveIcon = () => {
    if (!props.shouldRenderLiveIcon) return null;

    if (props.isCameraRecording) {
      const liveColorScheme = isLiveNGEnabled
        ? { borderColor: CYAN_BLUE, backgroundColor: CYAN_BLUE }
        : {};
      const liveIconStyle = props.isNearLiveEdge
        ? liveColorScheme
        : {
            backgroundColor: 'white',
            borderColor: REGENT_GREY,
            color: REGENT_GREY,
          };

      return (
        <div className={props.classes.liveIcon}>
          <Tooltip showTooltip={!props.isNearLiveEdge} title="Go to LIVE view">
            <div
              onClick={props.onClickLiveIcon}
              role="button"
              style={{ cursor: 'pointer' }}
              tabIndex="0"
              data-testid="VideoControls-Live"
            >
              <LiveIcon style={liveIconStyle} />
            </div>
          </Tooltip>
        </div>
      );
    }

    const liveIconTooltip =
      props.cameraDisplayName != null
        ? `The camera ${props.cameraDisplayName} is not recording. The camera status is ${
            ICON_TO_TEXT[props.cameraStatus]
          }`
        : `No Live Streams are being analyzed.`;

    return (
      <div className={props.classes.liveIcon}>
        <Tooltip title={liveIconTooltip}>
          <div style={{ cursor: 'not-allowed' }}>
            <LiveIcon
              style={{
                backgroundColor: DARK_GREY,
                borderColor: DARK_GREY,
                color: TEXT,
              }}
            />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={props.classes.videoBottom}>
      <div className={props.classes.videoControlWrapper}>
        <VideoControls
          disabled={props.areControlsDisabled}
          frameRate={props.frameRate}
          hasInitialized={props.hasInitialized}
          inspecting={props.inspecting}
          isCameraRecording={props.isCameraRecording}
          isLive={props.isLive}
          isNearLiveEdge={props.isNearLiveEdge}
          isPlaying={props.isPlaying}
          isPlayDisabled={props.isPlayDisabled}
          replay={props.replay}
          seekTo={props.seekTo}
          segments={props.segments}
          startEpochMs={props.startEpochMs}
          togglePlay={props.togglePlay}
          toggleIsLive={props.toggleIsLive}
          video={props.video}
        />
      </div>
      <div className={props.classes.videoProgressWrapper}>
        <div
          className={props.classes.durationOverlay}
          ref={props.setDurationOverlayRef}
          data-testid="VideoDuration"
        />
        <VideoProgressBar
          bindRef={props.bindProgressBarRef}
          disabled={props.isProgressBarDisabled}
          getVideoDuration={props.getVideoDuration}
          isCameraRecording={props.isCameraRecording}
          isLive={props.isLive}
          isNearLiveEdge={props.isNearLiveEdge}
          isPlaying={props.isPlaying}
          offset={props.startDate}
          onStartTimeChanged={props.onStartTimeChanged}
          pause={props.pause}
          play={props.play}
          seekTo={props.seekTo}
          toggleIsLive={props.toggleIsLive}
        />
      </div>
      {renderLiveIcon()}
    </div>
  );
};

export default injectSheet(styles)(VideoControlsAndProgressBar);
