import { STATUS } from 'common/constants/api';

import { USER_PROFILE } from './constants';
import { getDefaultGCS, normalizeSettingsResponse } from './utils';

/* Should be temporarily until we start saving user specific settings and features */
let activeFeatures = {};
try {
  const storedFeatures = JSON.parse(localStorage.getItem('activeFeatures'));
  if (storedFeatures) activeFeatures = storedFeatures;
} catch (err) {
  console.error(err);
}

const defaults = {
  activeFeatures,
  activeModules: {},
  configSettings: {},
  customerSettings: {},
  globalSettings: {},
  globalSettingsFetchStatus: STATUS.IDLE,
  globalSettingsFetchError: null,
  globalSettingsUpdateStatus: STATUS.IDLE,
  globalSettingsUpdateError: null,
  tileServerConnectionStatus: STATUS.IDLE,
  tileServerConnectionError: null,
  geoSettings: {},
  useCache: true,
  confidenceThreshold: 5,
  showIdentityConfidence: localStorage.getItem('showIdentityConfidence') === 'true',
  showConfidenceSlider: true,
  showObjectConfidence: localStorage.getItem('showObjectConfidence') === 'true',

  /* Need to refactor settings reducer into separate reducers */
  cameras: [],
  liveQueries: [],
  selectedCameras: {},

  /* Geographic coordinate system */
  gcs: getDefaultGCS(),
};

export default function settingsReducer(state = defaults, action) {
  switch (action.type) {
    case 'SETTINGS/VERIFY_TILE_SERVER': {
      return {
        ...state,
        tileServerConnectionStatus: STATUS.PENDING,
        tileServerConnectionError: defaults.tileServerConnectionError,
      };
    }
    case 'SETTINGS/VERIFY_TILE_SERVER_SUCCESS': {
      return {
        ...state,
        tileServerConnectionStatus: STATUS.SUCCESS,
      };
    }
    case 'SETTINGS/VERIFY_TILE_SERVER_FAILURE': {
      return {
        ...state,
        tileServerConnectionStatus: STATUS.FAILURE,
        tileServerConnectionError: action.payload,
      };
    }
    case 'SETTINGS/VERIFY_SERVER_STATUSES': {
      return {
        ...state,
        tileServerStatuses: action.payload,
      };
    }
    case 'SETTINGS/SET_ACTIVE_MODULES': {
      return {
        ...state,
        activeModules: action.payload,
      };
    }

    case 'SETTINGS/SET_CONFIG_SETTINGS': {
      return {
        ...state,
        config: action.payload,
      };
    }

    case 'SETTINGS/SET_CUSTOMER_SETTINGS': {
      return {
        ...state,
        customerSettings: action.payload,
      };
    }

    case 'SETTINGS/SET_GCS': {
      localStorage.setItem('gcs', action.payload);

      return {
        ...state,
        gcs: action.payload,
      };
    }

    case 'SETTINGS/SET_CAMERAS': {
      return {
        ...state,
        cameras: action.payload,
      };
    }

    case 'SETTINGS/SET_SELECTED_CAMERAS': {
      return {
        ...state,

        /* Currently only allow only camera to be selected as request, will need to be changed in the future */
        selectedCameras: {
          ...action.payload,
        },
      };
    }

    case 'SETTINGS/SET_ACTIVE_FEATURES': {
      localStorage.setItem('activeFeatures', JSON.stringify(action.payload));

      return {
        ...state,
        activeFeatures: action.payload,
      };
    }

    case 'SETTINGS/TOGGLE_SHOW_CONFIDENCE': {
      return {
        ...state,
        showConfidence: !state.showConfidence,
      };
    }

    case 'SETTINGS/SET_CONFIDENCE_THRESHOLD': {
      return {
        ...state,
        confidenceThreshold: action.value,
      };
    }

    case 'SETTINGS/TOGGLE_SHOW_CONFIDENCE_SLIDER': {
      const hasConfidenceSlider = !state.showConfidenceSlider;
      localStorage.setItem('showConfidenceSlider', hasConfidenceSlider);
      return {
        ...state,
        showConfidenceSlider: hasConfidenceSlider,
      };
    }

    case 'SETTINGS/TOGGLE_SHOW_OBJECT_CONFIDENCE': {
      const hasObjectConfidence = !state.showObjectConfidence;
      localStorage.setItem('showObjectConfidence', hasObjectConfidence);
      return {
        ...state,
        showObjectConfidence: hasObjectConfidence,
      };
    }

    case 'SETTINGS/TOGGLE_SHOW_IDENTITY_CONFIDENCE': {
      const hasIdentityConfidence = !state.showIdentityConfidence;
      localStorage.setItem('showIdentityConfidence', hasIdentityConfidence);
      return {
        ...state,
        showIdentityConfidence: hasIdentityConfidence,
      };
    }

    case 'SETTINGS/SET_LIVE_QUERIES': {
      return {
        ...state,
        liveQueries: action.payload,
      };
    }
    case 'SETTINGS/UPDATE_GLOBAL_SETTINGS': {
      return {
        ...state,
        globalSettingsUpdateStatus: STATUS.PENDING,
        globalSettingsUpdateError: defaults.globalSettingsUpdateError,
      };
    }
    case 'SETTINGS/UPDATE_GLOBAL_SETTINGS_SUCCESS': {
      return {
        ...state,
        globalSettingsUpdateStatus: STATUS.SUCCESS,
      };
    }
    case 'SETTINGS/UPDATE_GLOBAL_SETTINGS_FAILURE': {
      return {
        ...state,
        globalSettingsUpdateStatus: STATUS.FAILURE,
        globalSettingsUpdateError: action.payload,
      };
    }
    case 'SETTINGS/FETCH_GLOBAL_SETTINGS': {
      return {
        ...state,
        globalSettingsFetchStatus: STATUS.PENDING,
        globalSettingsFetchError: defaults.globalSettingsFetchError,
      };
    }

    case 'SETTINGS/FETCH_GLOBAL_SETTINGS_SUCCESS': {
      return {
        ...state,
        globalSettings: normalizeSettingsResponse(action.payload),
        globalSettingsFetchStatus: STATUS.SUCCESS,
      };
    }
    case 'SETTINGS/FETCH_GLOBAL_SETTINGS_FAILURE': {
      return {
        ...state,
        globalSettingsFetchStatus: STATUS.FAILURE,
        globalSettingsFetchError: action.payload,
      };
    }

    case 'SETTINGS/SET_GEO_SETTINGS': {
      return {
        ...state,
        geoSettings: action.payload,
      };
    }

    case 'SETTINGS/SET_USER_PROFILE': {
      return {
        ...state,
        [USER_PROFILE]: action.payload,
      };
    }

    default:
      return state;
  }
}
