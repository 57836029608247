export const CURRENT_USER_PARAM = 'me';

export const NAME = 'name';

export const DSFOLDERS_PARAMS = {
  isDeepSearchEnabled: 'deep_search',
  itemType: 'item_type[eq]',
  page: 'page',
  pageSize: 'page_size',
  startTime: 'internal_ts[gte]',
  endTime: 'internal_ts[lt]',
  title: 'title[like]',
  sort: 'sort_by',
  getSort: (field, direction) => {
    if (field === NAME) field = 'title'; // The BE refers to the NAME field as 'title'
    return `${direction.toLowerCase()}(${field})`;
  },
};

export const dateRangeParams = ([start, end]) => {
  const params = {};
  if (start && end) {
    params[DSFOLDERS_PARAMS.startTime] = Math.round(start / 1000);
    params[DSFOLDERS_PARAMS.endTime] = Math.round(end / 1000);
  }
  return params;
};
