import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getArchivedSourceName, calcSceneIndex } from 'common/helpers/sceneUtils';
import { getCoordsFromFormattedAddress } from 'reports/utils';
import {
  selectDatasourceModels,
  selectImageModels,
  selectReportModels,
  selectSceneModels,
} from 'common/redux/models/selectors';
import { reportPinIcon } from 'common/components/map/mapIcons/common';

export const selectIsLoadingPage = state => state.report.isLoadingPage;

const selectReportIds = state => get(state, 'report.reports');

export const selectSceneIds = state => get(state, 'report.scenes');

export const selectReportsPagination = state => get(state, 'report.pagination.reports');

export const selectScenesPagination = state => get(state, 'report.pagination.scenes');

export const selectSelectedScenes = state => state.report.selectedScenes;

export const selectSelectedReportKey = state => get(state, 'report.selectedReportKey');

export const selectReportSort = state => state.report.reportSort;

export const selectSceneSort = state => state.report.sceneSort;

export const selectSceneSortDirection = state => state.report.sceneSortDirection;

export const selectSelectedReport = createSelector(
  selectSelectedReportKey,
  selectReportModels,
  (selectedReportKey, reportModels) => reportModels[selectedReportKey]
);

export const selectSelectedView = state => state.report.selectedView;

const selectSceneDecorator = createSelector(
  selectDatasourceModels,
  selectImageModels,
  selectReportModels,
  selectScenesPagination,
  (dsModels, imageModels, reportModels, scenesPagination) => (scene, idx) => {
    const datasource = dsModels[scene.source] || imageModels[scene.imageSource] || {};

    scene.reportName = reportModels[scene.report]?.name || '';
    scene.sourceName = datasource.title || getArchivedSourceName(scene);
    scene.itemType = datasource.itemType;

    scene.datasource = datasource;
    scene.index = idx + 1 + scenesPagination.offset;

    return scene;
  }
);

export const selectReports = createSelector(
  selectReportIds,
  selectReportModels,
  (reportIds, reportModels) => reportIds.map(id => reportModels[id])
);

export const selectScenes = createSelector(
  selectSceneIds,
  selectSceneModels,
  selectSceneDecorator,
  (sceneIds, sceneModels, sceneDecorator) =>
    sceneIds.reduce((acc, id, index) => {
      if (sceneModels[id]) {
        acc.push(sceneDecorator({ ...sceneModels[id] }, index));
      }

      return acc;
    }, [])
);

export const selectScenesBySelectedReportId = createSelector(
  [selectScenes, selectSelectedReportKey],
  (scenes, selectedReportId) =>
    scenes.filter(({ report: reportId }) => reportId === selectedReportId)
);

export const selectReportsMapData = createSelector(
  [selectScenes, selectScenesBySelectedReportId, selectSelectedScenes],
  (scenes, scenesByReportId, selectedScenes) => {
    const data = scenesByReportId.length > 0 ? scenesByReportId : scenes;

    return data.reduce((accum, scene, index) => {
      const isSelected = Object.keys(selectedScenes).some(id => +id === +scene.id);
      const locationCoords = scene?.location?.coords;
      const location =
        locationCoords?.lat && locationCoords?.lng
          ? locationCoords
          : getCoordsFromFormattedAddress(scene?.location?.formattedAddress);

      if (location?.lat && location?.lng) {
        accum.push({
          icon: reportPinIcon({
            isSelected,
            type: scene.itemType,
            children: calcSceneIndex(index),
          }),
          id: scene.id,
          isSelected,
          name: scene.sourceName,
          position: [location.lat, location.lng],
          resultType: scene.itemType,
        });
      }

      return accum;
    }, []);
  }
);
