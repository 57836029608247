import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';

import { selectPersonAcceptedExtensions } from 'common/redux/formats/selectors';
import PersonEmbeddingWarning from 'common/components/popups/warnings/PersonEmbeddingWarning';
import { createImagePreviewUrl } from 'common/helpers/urlUtils';
import { extractMirageFormError } from 'common/helpers/apiErrorUtils';
import { alertRejected } from 'common/redux/formats/utils';
import {
  createIdentity,
  editPerson,
  fetchSinglePerson,
  removeImagesFromPerson,
  uploadIdentityImage,
  fetchImageDetections,
  removePerson,
} from 'library/redux/person/actions';
import { addToSearchQuery, addToSearchQueryMonitor } from 'plan/redux/actions';
import { getConfirmedFaces } from 'analysis/siftView/redux/actions';
import { selectSelectedSiftDataSource } from 'analysis/siftView/redux/selectors';
import { IDENTITY, ROOT_PERSON_KEY, MAX_EMBEDDINGS_PER_PERSON } from 'common/constants/app';
import { DEFAULT_OPERATOR } from 'common/constants/operators';
import getObjectTypeFromSearch from 'analysis/siftView/getObjectTypeFromSearch';

import Webcam from './Webcam';
import AddIdentity from './AddIdentity';
import DetectionSelection from './DetectionSeletion';
import { removeDuplicateFiles, isNamingError } from './utils';

function getDefaultState(props) {
  return {
    openAddIdentity: props.open || false,
    imagesToAdd: props.imagesToAdd || [],
    identity: props.identity || {},
    isProcessing: false,
    errorMessage: '',
    showPersonEmbeddingWarning: false,
    openDetectionSelection: false,
    isEditingExistingIdentity: !isEmpty(props.identity),
    useCamera: props.fromCameraIcon || false,
  };
}

export const NO_DETECTIONS_FOUND = 'No identifiable detections found in the image provided.';
export const IS_OBJ_STR_ERROR = 'Name cannot be reserved object name.';
export const DOES_MATCH_REGEX_ERROR = 'Name must satisfy the regular expression';

class UploadIdentity extends Component {
  static defaultProps = {
    openAddIdentity: false,
    identity: {},
  };

  constructor(props) {
    super(props);
    this.state = getDefaultState(props);
  }

  componentDidMount() {
    this.areImagesToAddWithinLimit();
  }

  componentDidUpdate(prevProps) {
    if (!isEmpty(this.props.identity) && !isEqual(prevProps.identity, this.props.identity)) {
      this._setup(this.props);
    }

    if (
      prevProps.imagesToAdd !== this.props.imagesToAdd ||
      (prevProps.imagesToAdd &&
        this.props.imagesToAdd &&
        prevProps.imagesToAdd.length !== this.props.imagesToAdd.length)
    ) {
      this.areImagesToAddWithinLimit();
    }
  }

  areImagesToAddWithinLimit = () => {
    if (this.props.imagesToAdd?.length > MAX_EMBEDDINGS_PER_PERSON) {
      this.setState({ imagesToAdd: [] });
      this.openPersonEmbeddingWarning();
    }
  };

  _setup = props => this.setState({ ...getDefaultState(props), openAddIdentity: true });

  openPersonEmbeddingWarning = () => this.setState({ showPersonEmbeddingWarning: true });

  closePersonEmbeddingWarning = () => this.setState({ showPersonEmbeddingWarning: false });

  areEmbeddingsWithinLimit = (acceptedFiles = [], { images = [] } = {}) =>
    !(
      acceptedFiles.length > MAX_EMBEDDINGS_PER_PERSON ||
      this.state.imagesToAdd?.length + acceptedFiles.length > MAX_EMBEDDINGS_PER_PERSON ||
      images.length + acceptedFiles.length > MAX_EMBEDDINGS_PER_PERSON
    );

  addImages = (acceptedFiles, rejectedFiles = []) => {
    if (rejectedFiles.length > 0) {
      alertRejected(rejectedFiles, this.props.acceptedExtensions);
    }

    if (!this.areEmbeddingsWithinLimit(acceptedFiles, this.state.identity)) {
      return this.openPersonEmbeddingWarning();
    }

    acceptedFiles = acceptedFiles.map(createImagePreviewUrl);
    this.setState(state => ({
      imagesToAdd: removeDuplicateFiles(acceptedFiles.concat(state.imagesToAdd)),
      errorMessage: '',
    }));
  };

  removeImage = key => {
    const { identity: { id, images } = {} } = this.state;
    if (id && images.includes(key)) {
      this.setState({ isLoading: true });
      return this.props
        .dispatchRemoveImagesFromPerson({ id, images: [key] })
        .then(() => this.fetchIdentity(id))
        .finally(() => this.setState({ isLoading: false }));
    }

    this.setState(state => ({
      imagesToAdd: state.imagesToAdd.filter(({ preview = '' }) => preview !== key),
    }));
  };

  hasSelectableDetections = ({ objectType } = {}, detections = []) =>
    !Number.isInteger(objectType) || detections.find(({ k }) => k === objectType);

  processImages = async ({ name, tags }) => {
    const { imagesToAdd, identity } = this.state;
    const files = removeDuplicateFiles(imagesToAdd);

    if (!files.length) {
      this.toggleProcessing(false);
      return Promise.resolve({});
    }

    this.toggleProcessing(true);
    this.setErrorMessage();

    const processing = imagesToAdd[0];
    const _identity = { ...identity, name, tags, processing };
    _identity.imageId = await this.props.dispatchUploadIdentityImage(processing);

    const detections = await this.props.dispatchFetchImageDetections(_identity);
    if (detections?.length && this.hasSelectableDetections(this.state.identity, detections)) {
      _identity.detections = detections;
      return this.setState({ identity: _identity }, this.checkForMultipleDetections);
    }

    // continue to process images, if no detections were found and there are more images to process
    if (this.state.imagesToAdd.length > 1) return this.continueProcessing({ name, tags });

    if (identity?.processing?.preview) this.removeImage(identity.processing.preview);
    this.setErrorMessage(NO_DETECTIONS_FOUND);
    this.toggleProcessing(false);
  };

  continueProcessing = ({ name, tags }) => {
    this.setState(
      state => ({
        imagesToAdd: state.imagesToAdd.length ? state.imagesToAdd.slice(1) : [],
        identity: { ...state.identity, detections: [] },
      }),
      () => this.processImages({ name, tags })
    );
  };

  handleError = error => {
    const { status, data = {} } = extractMirageFormError(error);

    if (data?.message) this.setErrorMessage(data.message);
    /*
      If some goes wrong with the current image, unrelated to a bad user-specified name,
      continue the processing for the rest of the images
    */
    if (status === 400 && !isNamingError(data)) return this.continueProcessing(this.state.identity);

    this.setState(state => ({
      isProcessing: false,
      identity: { ...state.identity, detections: [] },
    }));

    return Promise.reject(error);
  };

  checkForMultipleDetections = () => {
    const { identity: { detections = [] } = {} } = this.state;

    /* if more than 1 face detected, render MultipleDetections */
    if (detections.length > 1) {
      return this.setState({
        isProcessing: true,
        openDetectionSelection: true,
      });
    }

    return this.onCreateIdentity();
  };

  setErrorMessage = errorMessage => this.setState({ errorMessage });

  toggleProcessing = isProcessing => this.setState({ isProcessing });

  toggleWebcam = () => this.setState(state => ({ useCamera: !state.useCamera }));

  fetchIdentity = async id => {
    const identity = await this.props.dispatchFetchSinglePerson(id);
    if (id === identity?.id) {
      this.setState({ identity });
      return identity;
    }
  };

  onDetectionSelection = async ({ detections = [] }) => {
    this.setState(
      state => ({ openDetectionSelection: false, identity: { ...state.identity, detections } }),
      this.onCreateIdentity
    );
  };

  onCreateIdentity = async () => {
    const { selectedFolderKey, dispatchCreateIdentity, dispatchFetchSinglePerson } = this.props;
    const { identity: { id, name, tags = '', detections = [] } = {} } = this.state;
    if (!name || !detections?.length) return Promise.resolve({});

    const data = {
      n: name,
      tags,
      ...detections[0],
      objectType: detections[0].k,
      f: selectedFolderKey,
    };

    if (id) {
      data.id = id;
      data.i = id;
    }

    const identity = await dispatchCreateIdentity(data)
      .then(({ id: _id } = {}) => {
        if (_id) return dispatchFetchSinglePerson(_id);
      })
      .catch(err => {
        if (this.props.imagesToAdd.length) {
          this.addImages([this.props.imagesToAdd[0]]);
        }
        return this.handleError(err);
      });

    if (identity?.id) {
      this.setState(
        state => ({
          isProcessing: false,
          imagesToAdd: state.imagesToAdd.length ? state.imagesToAdd.slice(1) : [],
          identity,
        }),
        () => this.processImages(identity)
      );
    }
  };

  handleSubmit = async ({ id, name = '', tags = '' }) => {
    const {
      dispatchEditIdentity,
      dispatchAddToSearchQuery,
      dispatchFetchSinglePerson,
      dispatchGetConfirmedFaces,
      updateSift,
      siftDataSource,
      fromPlan,
      location,
      handleClose,
    } = this.props;

    let identity = {};
    if (id) {
      identity = await dispatchFetchSinglePerson(id);
    }

    if (identity.id === id && (identity.name !== name || identity.tags !== tags)) {
      await dispatchEditIdentity({ id, name, tags }).catch(this.handleError);
    }

    /* TODO: This should be refactored as a onSubmit hook which should be defined
             in the plan page and passed in as a prop
    */
    if (id && fromPlan) {
      // set default operator
      identity.operator = DEFAULT_OPERATOR;
      dispatchAddToSearchQuery({
        queryType: IDENTITY,
        query: { identities: [identity] },
      });
    }

    this.setState({ errorMessage: '', openAddIdentity: false });
    handleClose();
    if (updateSift) {
      const objectType = getObjectTypeFromSearch(location.search);
      dispatchGetConfirmedFaces({ id: siftDataSource.id, objectType });
    }
  };

  onClose = async () => {
    const { dispatchRemovePerson, handleClose } = this.props;
    this.setState({ isLoading: true });

    if (this.state.identity?.id && !this.state.isEditingExistingIdentity) {
      await dispatchRemovePerson(this.state.identity.id);
    }
    this.setState({
      isLoading: false,
      openAddIdentity: false,
      errorMessage: '',
      openDetectionSelection: false,
    });

    handleClose();
  };

  onAddWebcamImages = (images = []) => {
    this.addImages(images);
    this.toggleWebcam();
  };

  render() {
    return (
      <>
        <AddIdentity
          open={this.state.openAddIdentity}
          identity={this.state.identity}
          isProcessing={this.state.isProcessing}
          processImages={this.processImages}
          removeImage={this.removeImage}
          imagesToAdd={this.state.imagesToAdd}
          errorMessage={this.state.errorMessage}
          onError={this.setErrorMessage}
          title={this.state.title}
          addImages={this.addImages}
          isLoading={this.state.isLoading}
          onClose={this.onClose}
          onReview={this.props.onReview}
          handleSubmit={this.handleSubmit}
          fromPlan={this.props.fromPlan}
          toggleWebcam={this.toggleWebcam}
          isEditingExistingIdentity={this.state.isEditingExistingIdentity}
        />
        <PersonEmbeddingWarning
          person={this.state.identity}
          open={this.state.showPersonEmbeddingWarning}
          onClose={this.closePersonEmbeddingWarning}
        />
        <DetectionSelection
          identity={this.state.identity}
          open={this.state.openDetectionSelection}
          onClose={this.onClose}
          onDetectionSelection={this.onDetectionSelection}
        />
        <Webcam
          addImages={this.onAddWebcamImages}
          open={this.state.useCamera}
          onClose={this.toggleWebcam}
        />
      </>
    );
  }
}

UploadIdentity.propTypes = {
  fromPlan: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  imagesToAdd: PropTypes.arrayOf(PropTypes.object),
  onReview: PropTypes.bool,
  identity: PropTypes.object,
  selectedFolderKey: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => ({
  selectedFolderKey: ownProps.selectedFolderKey || ROOT_PERSON_KEY,
  acceptedExtensions: selectPersonAcceptedExtensions(state),
  siftDataSource: selectSelectedSiftDataSource(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  let dispatchAddToSearchQuery = (...args) => dispatch(addToSearchQuery(...args));

  if (ownProps.fromMonitor) {
    dispatchAddToSearchQuery = (...args) => dispatch(addToSearchQueryMonitor(...args));
  }

  return {
    dispatchAddToSearchQuery,
    dispatchUploadIdentityImage: (...args) => dispatch(uploadIdentityImage(...args)),
    dispatchFetchImageDetections: (...args) => dispatch(fetchImageDetections(...args)),
    dispatchCreateIdentity: (...args) => dispatch(createIdentity(...args)),
    dispatchEditIdentity: (...args) => dispatch(editPerson(...args)),
    dispatchFetchSinglePerson: (...args) => dispatch(fetchSinglePerson(...args)),
    dispatchRemoveImagesFromPerson: (...args) => dispatch(removeImagesFromPerson(...args)),
    dispatchGetConfirmedFaces: (...args) => dispatch(getConfirmedFaces(...args)),
    dispatchRemovePerson: (...args) => dispatch(removePerson(...args)),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UploadIdentity);
