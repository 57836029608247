import { createUseStyles } from 'react-jss';

import TextInput from 'common/components/base/TextInput';
import { PRIMARY_GREY } from 'common/constants/colors';

const useStyles = createUseStyles(theme => ({
  top: {
    cursor: 'move',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 'min-content',
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(0.75),
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: 96,
    marginLeft: theme.spacing(1.5),
  },
  input: {
    width: '100%',
  },
  title: {
    width: '100%',
  },
  inputDescription: {
    color: PRIMARY_GREY,
    fontSize: 12,
    fontWeight: 300,
    padding: `${theme.spacing(0.5)}px 0`,
    width: '100%',
  },
}));

const DetectionPersonFilterListTitle = ({
  title,
  subtitle,
  error,
  onChangeName,
  onKeyPress,
  value,
  thumbnailUrl,
}) => {
  const classes = useStyles();

  return (
    <div id="dragHandleTarget" className={classes.top}>
      <div className={classes.left}>
        <h3 className={classes.title}>{title}</h3>
        <span className={classes.inputDescription}>{subtitle}</span>
        <TextInput
          autoFocus
          label="Enter Name"
          className={classes.input}
          value={value}
          onChange={onChangeName}
          onKeyPress={onKeyPress}
          error={!!error}
          helperText={error}
          data-testid="DetectionPersonFilterList-enterPersonFace"
        />
      </div>
      <img className={classes.image} alt="thumb" src={thumbnailUrl} />
    </div>
  );
};

export default DetectionPersonFilterListTitle;
