import React from 'react';
import injectSheet from 'react-jss';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Button from 'common/components/base/Button';
import closeIcon from 'common/images/close.png';
import Delete from 'common/icons/deleteicon';
import Capture from 'common/icons/capture';
import { dataURLtoFile } from './utils';
import webcamStyles from './Webcam.module.scss';

const styles = {
  button: {
    width: '100%',
  },
};

class WebcamModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { images: [] };
  }

  removeImage(i) {
    const { images } = this.state;
    images.splice(i, 1);
    this.setState({ images });
  }

  addPicture(image) {
    const file = dataURLtoFile(image, 'webcam-image.jpeg');
    file.preview = image;
    this.setState(state => ({ images: [file].concat(state.images) }));
  }

  render() {
    const { onClose, addImages, classes } = this.props;
    const { images } = this.state;

    return (
      <Dialog
        open={this.props.open}
        onClose={onClose}
        onClick={e => e.stopPropagation()}
        data-testid="UploadIdentity-webcam"
      >
        <div className={webcamStyles.webcam}>
          <div className={webcamStyles['webcam-content']}>
            <header>
              <span>Use your camera to add photos to this identity</span>
              <div role="button" tabIndex="0" onClick={onClose} className={webcamStyles.cancel}>
                <img src={closeIcon} alt="close" />
              </div>
            </header>
            <div className={webcamStyles.content__body}>
              <div className={webcamStyles['webcam-preview']}>
                <Webcam
                  audio={false}
                  height={288}
                  style={{ objectFit: 'contain', background: 'black' }}
                  ref={node => (this.webcam = node)}
                  screenshotFormat="image/jpeg"
                  width="100%"
                />
                <div className={webcamStyles['capture-container']}>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      const { webcam } = this;
                      this.addPicture(webcam.getScreenshot());
                    }}
                    className={webcamStyles['webcam-capture']}
                  >
                    <Capture selected />
                  </div>
                </div>
              </div>
              <div className={webcamStyles['preview-wrapper']}>
                <div className={webcamStyles.preview}>
                  {images.map((image, i) => (
                    <div key={image.preview} className={webcamStyles['preview-image']}>
                      <div
                        role="button"
                        tabIndex="0"
                        className={webcamStyles['preview-image__delete']}
                        onClick={() => this.removeImage(i)}
                      >
                        <Delete />
                      </div>
                      <img src={image.preview} alt="preview" />
                    </div>
                  ))}
                </div>
                <div className={webcamStyles['cancel-wrapper']}>
                  <Button
                    variant="outlined"
                    onClick={() => addImages(this.state.images)}
                    className={classes.button}
                    disabled={!this.state.images.length}
                  >
                    ADD
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

WebcamModal.propTypes = {
  open: PropTypes.bool,
  addImages: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default injectSheet(styles)(WebcamModal);
