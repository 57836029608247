import { BRIGHT_RED, ORANGE, BLACK, WHITE } from 'common/constants/colors';
import { getHexadecimalOpacity } from 'common/helpers/colorHelpers';

export const GEO_ALERT_POLLING_INTERVAL = 15000;
export const GEO_ALERT_COLUMN_WIDTH = 200;

export const ALERTS_CAUGHT_UP_MESSAGE = 'Up to date, nothing to review.';
export const NO_NEW_IMAGERY_MESSAGE = 'No new imagery has been processed for this query.';
export const CATEGORIES = 'objectCategories';
export const THRESHOLD = 'threshold';
export const CONDITION = 'condition';
export const ORIGINAL_COUNT = 'originalCount';
export const ALERT_CLASS = 'alertClass';
export const CONDITION_ALERT_CLASS = 'alertClass';
export const CONDITION_VEHICLES = 'vehicles';
export const CONDITION_COUNT = 'count';
export const CONDITION_CRITERIA = 'criteria';
export const ALERT_IMAGE_DATE = 'imageDate';
export const ALERT_QUALITY = 'quality';
export const ALERT_QUERY_NAME = 'alertName';
export const ALERT_GEOFENCE_NAME = 'geofenceName';
export const ALERT_MARK_AS_READ = 'markAsRead';
export const ALERT_ACTIONS = 'actions';
export const ZOOM_TO_ALERT = 'zoomToAlert';

export const YELLOW_ALERT_COUNT = 'yellowAlertCount';
export const RED_ALERT_COUNT = 'redAlertCount';

export const YELLOW_ALERT_COLOR = ORANGE;
export const RED_ALERT_COLOR = BRIGHT_RED;
export const YELLOW_ALERT = 1;
export const RED_ALERT = 2;

export const GREATER_THAN_THRESHOLD = 'gt_t';
export const LESS_THAN_THRESHOLD = 'lt_t';
export const INCREASED_BY_THRESHOLD = 'inc_t';
export const DECREASED_BY_THRESHOLD = 'dec_t';
export const INCREASED_BY_PERCENT = 'inc_%';
export const DECREASED_BY_PERCENT = 'dec_%';
export const CHANGED_BY_PERCENT = 'change_%';
export const CHANGED_BY_THRESHOLD = 'change_t';

export const CHANGES_BY = 'changes by';
export const INCREASES_BY = 'increases by';
export const DECREASES_BY = 'decreases by';
export const CHANGE_BASED = 'Change vs previous dataset';
export const ABSOLUTE_COUNT = 'Absolute count';

export const PERCENTAGE = '%';
export const VEHICLES = 'vehicles';

export const COUNT_PREFIX = '>';

export const CONDITIONS = [
  {
    label: 'is greater than',
    value: GREATER_THAN_THRESHOLD,
    symbol: '>',
  },
  {
    label: 'is less than',
    value: LESS_THAN_THRESHOLD,
    symbol: '<',
  },
  {
    label: 'has increased by',
    value: INCREASED_BY_THRESHOLD,
    symbol: 'increase >',
  },
  {
    label: 'has decreased by',
    value: DECREASED_BY_THRESHOLD,
    symbol: 'decrease >',
  },
  {
    label: 'has increased by percentage',
    value: INCREASED_BY_PERCENT,
    symbol: 'increase >',
  },
  {
    label: 'has decreased by percentage',
    value: DECREASED_BY_PERCENT,
    symbol: 'decrease >',
  },
  {
    label: 'changed by percentage',
    value: CHANGED_BY_PERCENT,
    symbol: 'change by >',
  },
  {
    label: 'has changed by',
    value: CHANGED_BY_THRESHOLD,
    symbol: 'change by >',
  },
];

export const ALERT_CONDITION_COUNT_TYPES = [
  {
    label: VEHICLES,
    value: VEHICLES,
  },
  {
    label: PERCENTAGE,
    value: PERCENTAGE,
  },
];

export const ALERT_CONDITION_TYPES = [
  {
    label: CHANGES_BY,
    value: CHANGES_BY,
    group: CHANGE_BASED,
  },
  {
    label: INCREASES_BY,
    value: INCREASES_BY,
    group: CHANGE_BASED,
  },
  {
    label: DECREASES_BY,
    value: DECREASES_BY,
    group: CHANGE_BASED,
  },
  {
    label: 'is greater than',
    value: GREATER_THAN_THRESHOLD,
    group: ABSOLUTE_COUNT,
  },
  {
    label: 'is less than',
    value: LESS_THAN_THRESHOLD,
    group: ABSOLUTE_COUNT,
  },
];

export const ALERT_CLASSES = [
  {
    label: 'yellow alert',
    alerttype: 'warn',
    value: YELLOW_ALERT,
    background: getHexadecimalOpacity(YELLOW_ALERT_COLOR, 0.2),
    color: ORANGE,
    textColor: BLACK,
  },
  {
    label: 'red alert',
    alerttype: 'alert',
    value: RED_ALERT,
    background: getHexadecimalOpacity(RED_ALERT_COLOR, 0.2),
    color: BRIGHT_RED,
    textColor: WHITE,
  },
];

export const ALERTS_TABLE_COLUMNS = {
  [CONDITION_ALERT_CLASS]: {
    iconName: 'notifications',
    key: CONDITION_ALERT_CLASS,
    isSortable: true,
    tooltip: 'Sort by Severity',
  },
  [ZOOM_TO_ALERT]: {
    key: ZOOM_TO_ALERT,
    isSortable: false,
  },
  [CONDITION_COUNT]: {
    title: 'Count',
    key: CONDITION_COUNT,
    isSortable: true,
  },
  [ALERT_IMAGE_DATE]: {
    title: 'Image Date',
    key: ALERT_IMAGE_DATE,
    isSortable: true,
  },
  [ALERT_QUALITY]: {
    title: 'Image Quality',
    key: ALERT_QUALITY,
    isSortable: true,
  },
  [ALERT_QUERY_NAME]: {
    title: 'Alert Name',
    key: ALERT_QUERY_NAME,
    isSortable: true,
  },
  [ALERT_GEOFENCE_NAME]: {
    title: 'Geofence Name',
    key: ALERT_GEOFENCE_NAME,
    isSortable: true,
  },
  [CONDITION_VEHICLES]: {
    title: 'Vehicles',
    key: CONDITION_VEHICLES,
    isSortable: false,
  },
  [CONDITION_CRITERIA]: {
    title: 'Criteria',
    key: CONDITION_CRITERIA,
    isSortable: false,
  },
  [ALERT_MARK_AS_READ]: {
    title: 'Clear',
    key: ALERT_MARK_AS_READ,
    isSortable: false,
  },
  [ALERT_ACTIONS]: {
    title: 'Actions',
    key: ALERT_ACTIONS,
    isSortable: false,
  },
};
