import { batchActions } from 'redux-batched-actions';

import { createCustomObject, fetchDetectionObjects } from 'common/redux/models/objectActions';
import { setObjectModels } from 'common/redux/models/actions';
import { patchDetectionObject } from 'common/api/detectionObjectApi';
import { setAllObjectIdsGeo } from 'library/geospatial/redux/object/actions';

import { OBJECT_KEY } from './constants';

export const setAllObjects = data => ({
  type: `LIBRARY/${OBJECT_KEY}/SET_ALL_DATA`,
  payload: data,
});

/* ========================= THUNK ACTION CREATORS ========================== */

export function libraryFetchDetectionObjects() {
  return function libraryFetchDetectionObjectsThunk(dispatch) {
    return dispatch(fetchDetectionObjects()).then(({ activeObjects, fmvObjects, geoObjects }) => {
      const fmvObjectIds = fmvObjects.map(({ id }) => id);
      const geoObjectIds = geoObjects.map(({ id }) => id);

      const actions = [
        setObjectModels(activeObjects),
        setAllObjects(fmvObjectIds),
        setAllObjectIdsGeo(geoObjectIds),
      ];

      dispatch(batchActions(actions));
    });
  };
}

export function libraryCreateCustomObject(params) {
  return async function libraryCreateCustomObjectThunk(dispatch) {
    const newCustomObject = await dispatch(createCustomObject(params));

    // NOTE: Cannot merge the new model instead of fetching as the returned value is
    // not the complete model and is missing properties
    await dispatch(libraryFetchDetectionObjects());

    return newCustomObject;
  };
}

export function libraryEditCustomObject(object) {
  return async function libraryEditCustomObjectThunk(dispatch) {
    const updatedObject = await patchDetectionObject(object);

    console.log('updatedObject', updatedObject);

    // NOTE: Cannot merge the new model instead of fetching as the returned value is
    // not the complete model and is missing properties
    await dispatch(libraryFetchDetectionObjects());

    return updatedObject;
  };
}
