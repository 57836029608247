export function getDateInMs(datasource) {
  const { startEpochMs, date } = datasource;

  if (startEpochMs) {
    return startEpochMs;
  }
  if (date) {
    return new Date(date).getTime();
  }

  return 0;
}

export const isProcessedWithSwl = datasource => {
  try {
    const { swl } = datasource;
    if (!swl) return false;

    const {
      num_sliding_window_rows: numSlidingWindowRows,
      num_sliding_window_columns: numSlidingWindowColumns,
    } = JSON.parse(swl);

    if (!(numSlidingWindowRows && numSlidingWindowColumns)) return false;

    return !(numSlidingWindowRows === 1 && numSlidingWindowColumns === 1);
  } catch (err) {
    console.error(err);
    return false;
  }
};
