import React from 'react';
import ExpansionPanel from 'common/components/base/ExpansionPanel';

import { Example } from '../helpers';

const ExpansionPanelExample = () => (
  <div>
    <Example label="Basic Examples">
      <ExpansionPanel label="Default">The content within this panel is now visible</ExpansionPanel>
      <ExpansionPanel divider label="With Divider">
        The content within this panel is now visible
      </ExpansionPanel>
      <ExpansionPanel elevation={0} label="With No Elevation">
        The content within this panel is now visible
      </ExpansionPanel>
    </Example>

    <Example label="Padding and Margin Options">
      <ExpansionPanel padding={false} label="No Summary or Detail Padding">
        The content within this panel is now visible
      </ExpansionPanel>
      <ExpansionPanel margin={false} label="Will not add margins between surrounding panels">
        The content within this panel is now visible
      </ExpansionPanel>
      <ExpansionPanel dense label="Dense Styling">
        The content within this panel is now visible
      </ExpansionPanel>
    </Example>
  </div>
);

export default ExpansionPanelExample;
