import React from 'react';
import classnames from 'classnames';
import injectSheet from 'react-jss';

import LoadingIcon from './LoadingIcon';

const styles = {
  container: {
    zIndex: props => props.zIndex || 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: props => props.backgroundColor || 'rgba(255, 255, 255, 0.6)',
  },
};

const LoadingOverlay = ({ backgroundColor, className, classes, ...rest }) => (
  <div className={classnames(classes.container, className)} data-testid="LoadingOverlay">
    <LoadingIcon {...rest} />
  </div>
);

export default injectSheet(styles)(LoadingOverlay);
