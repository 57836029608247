import React from 'react';
import throttle from 'lodash/throttle';

import { getLiveDelay } from 'liveSearch/redux/utils';
import Video from './Video';

const dashjs = require('dashjsOld');

class DashVideo extends React.Component {
  componentDidMount() {
    if (this.props.bindRef) this.props.bindRef(this);

    this._logLiveLatency = throttle(this._logLiveLatency, 2000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.src && this.props.src !== prevProps.src) {
      this._setDashSrc(this.props.src);
    }

    if (this.props.isLive) {
      this._logLiveLatency();
    }
  }

  componentWillUnmount() {
    if (this._dashPlayer) {
      this._dashPlayer.reset();
    }
  }

  _dashPlayer = null;

  getDashPlayer = () => this._dashPlayer;

  getLiveLatency = () => this._dashPlayer && this._dashPlayer.getCurrentLiveLatency();

  _bindVideoRef = node => (this._video = node);

  _logLiveLatency = () => {
    const latency = this.getLiveLatency();

    console.log('Live Player latency: ', latency);
  };

  _setDashSrc = src => {
    if (this._dashPlayer) {
      this._dashPlayer.reset();
    }

    if (!src) {
      return;
    }

    this._dashPlayer = dashjs.MediaPlayer().create();

    const settings = {
      /* Only show critical errors in the console */
      debug: {
        logLevel: dashjs.Debug.LOG_LEVEL_ERROR,
      },
      streaming: {
        /*
          Prevents the dash player from fetching too many chunks. The user will usually be jumping around
          looking at detections and will not usually need 60 - 80 seconds of data ahead (which is the default)
        */
        bufferAheadToKeep: 15,
        bufferTimeAtTopQuality: 15,
        bufferTimeAtTopQualityLongForm: 15,
        jumpGaps: this.props.isLive,
      },
    };

    if (this.props.isLive) {
      const liveDelay = getLiveDelay();
      settings.streaming = {
        ...settings.streaming,
        lowLatencyEnabled: true,
        liveDelay: this.props.isDsProcessedWithSwl ? liveDelay + 2 : liveDelay,
        liveCatchUpMinDrift: 0.2,
        liveCatchUpPlaybackRate: 0.9,
      };
    }

    this._dashPlayer.updateSettings(settings);

    this._dashPlayer.initialize(this._video, src, false);

    this._dashPlayer.on('error', error => {
      if (this.props.onDashError) {
        this.props.onDashError(error);
      }
    });
  };

  _onVideoMount = () => {
    this._setDashSrc(this.props.src);
  };

  render() {
    const { forwardRef, ...rest } = this.props;
    return (
      <Video
        {...rest}
        ref={forwardRef}
        bindRef={this._bindVideoRef}
        getDashPlayer={this.getDashPlayer}
        onMount={this._onVideoMount}
      />
    );
  }
}

export default React.forwardRef((props, ref) => <DashVideo {...props} forwardRef={ref} />);
