import { pagination } from 'library/redux/sharedDefaults';
import { SORT_BY_CREATED, SORT_BY_NAME, LIST_VIEW, SORT_DIRECTION_DESC } from './constants';

const defaults = {
  loadingReports: true,
  isLoadingPage: false,
  pagination: {
    reports: pagination,
    scenes: pagination,
  },
  reports: [],
  reportSort: SORT_BY_NAME,
  scenes: [],
  selectedReportKey: null,
  selectedScenes: {},
  selectedView: LIST_VIEW,
  sceneSort: SORT_BY_CREATED,
  sceneSortDirection: SORT_DIRECTION_DESC,
};

export default function reportReducer(state = defaults, action) {
  switch (action.type) {
    case 'REPORTS/TOGGLE_LOADING_REPORTS': {
      return {
        ...state,
        loadingReports: action.payload,
      };
    }
    case 'REPORTS/SET_IS_LOADING_PAGE': {
      return {
        ...state,
        isLoadingPage: action.payload,
      };
    }
    case 'REPORTS/SELECT_SCENES': {
      const scenes = action.payload;
      const newState = {
        ...state,
        selectedScenes: {
          ...state.selectedScenes,
        },
      };

      return scenes.reduce((acc, key) => {
        acc.selectedScenes[key] = true;
        return acc;
      }, newState);
    }
    case 'REPORTS/DESELECT_ALL_SCENES': {
      return {
        ...state,
        selectedScenes: {},
      };
    }
    case 'REPORTS/SET_CASE_FILES_VIEW': {
      return {
        ...state,
        selectedView: action.payload,
      };
    }
    case 'REPORTS/SET_SELECTED_REPORT': {
      return {
        ...state,
        selectedReportKey: action.payload,
      };
    }
    case 'REPORTS/TOGGLE_SELECTED_SCENE': {
      const key = action.payload;
      const newState = {
        ...state,
        selectedScenes: {
          ...state.selectedScenes,
        },
      };

      if (newState.selectedScenes[key]) {
        delete newState.selectedScenes[key];
      } else {
        newState.selectedScenes[key] = true;
      }

      return newState;
    }
    case 'REPORTS/SET_ALL_REPORTS': {
      return {
        ...state,
        reports: action.reports,
      };
    }
    case 'REPORTS/SET_ALL_SCENES': {
      return {
        ...state,
        scenes: action.scenes,
      };
    }
    case 'REPORTS/SET_SINGLE_SCENE': {
      return {
        ...state,
        scenes: {
          ...state.scenes,
          [action.scene.id]: action.scene,
        },
      };
    }
    case 'REPORTS/SET_SCENE_SORT_OPTION': {
      return {
        ...state,
        sceneSort: action.sceneSort,
        sceneSortDirection: action.sceneSortDirection,
      };
    }
    case 'REPORTS/SET_REPORTS_PAGINATION': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          reports: {
            ...state.pagination.reports,
            ...action.payload,
          },
        },
      };
    }
    case 'REPORTS/SET_SCENES_PAGINATION': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          scenes: {
            ...state.pagination.scenes,
            ...action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
}
