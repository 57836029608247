import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-jss';

import MapIcon from 'common/icons/map';
import Typography from 'components/Typography';
import { BLUE_GREY, LIGHT_GREY } from 'common/constants/colors';
import { LOCATION_NOT_AVAILABLE_MESSAGE } from 'common/helpers/networkUtils';

const StyledContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: LIGHT_GREY,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  minHeight: theme.spacing(30),
  position: 'relative',
  textAlign: 'center',
}));

export const StyledMapIcon = styled(MapIcon)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginBottom: theme.spacing(4),
}));

const MapViewEmpty = ({ hasError, message, ...rest }) => {
  const myMessage = hasError
    ? LOCATION_NOT_AVAILABLE_MESSAGE
    : message || LOCATION_NOT_AVAILABLE_MESSAGE;

  return (
    <StyledContainer data-testid="MapViewEmpty" {...rest}>
      <div>
        <StyledMapIcon htmlColor={BLUE_GREY} />
        <Typography variant="subtitle1" data-testid="MapViewMessage">
          {myMessage}
        </Typography>
      </div>
    </StyledContainer>
  );
};

MapViewEmpty.propTypes = {
  hasError: PropTypes.bool,
  message: PropTypes.string,
};

export default MapViewEmpty;
