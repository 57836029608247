import axios from 'axios.js';

import { ANALYTICS_BACKEND, ASSET_BACKEND, VERSION } from 'common/constants/urls';

export function getFrontendBuild() {
  return axios.get(`${ASSET_BACKEND}/frontend_build.json`).then(({ data }) => data);
}

export function getVersion() {
  return axios.get(`${ANALYTICS_BACKEND}${VERSION}/`).then(({ data }) => data);
}
