import React from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

import { ERROR, WARNING, INFO } from 'app/redux/constants';
import { TEXT, PRIMARY_GREY } from 'common/constants/colors';

import Button from 'common/components/base/Button';
import Icon from 'common/components/base/Icon';
import Dialog from '@material-ui/core/Dialog';

import Typography from 'components/Typography';

const styles = theme => ({
  main: {
    maxWidth: 360,
    width: 'unset',
    height: 'unset',

    '& > *': {
      display: 'flex',
      padding: theme.spacing(2.5),
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 13,
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1.5),
  },
  content: {
    fontWeight: 300,
    fontSize: 11,
    color: TEXT,
    lineHeight: 1.5,
    padding: '0 20px 2px 20px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  actions: {
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& button': {
      marginLeft: 12,
      minWidth: 80,
    },
    '& > a': {
      textDecoration: 'underline',
      color: PRIMARY_GREY,
      fontSize: 11,
    },
  },
  details: {
    maxHeight: 192,
    whiteSpace: 'pre-wrap',
    overflowY: 'auto',
    fontWeight: 300,
    fontSize: 11,
    color: TEXT,
    lineHeight: 1.5,
    wordBreak: 'break-word',
  },
});

class MirageAlert extends React.PureComponent {
  state = { open: !!this.props.alert.message, showDetails: false };

  componentDidUpdate(prevProps) {
    if (this.props.alert !== prevProps.alert && this.props.alert && this.props.alert.message) {
      this.handleOpen();
    }
  }

  handleClose = () => this.setState({ open: false, showDetails: false });

  handleOpen = () => this.setState({ open: true });

  renderError = () => (
    <Typography variant="h5">
      <Icon iconName="error" theme="red" className={this.props.classes.icon} />
      {this.props.alert.title || 'Error'}
    </Typography>
  );

  renderWarning = () => (
    <Typography variant="h5">
      <Icon iconName="warning" theme="orange" className={this.props.classes.icon} />
      {this.props.alert.title || 'Warning'}
    </Typography>
  );

  renderInfo = () => (
    <Typography variant="h5">
      <Icon iconName="info" theme="grey" className={this.props.classes.icon} />
      {this.props.alert.title || 'Notice'}
    </Typography>
  );

  renderHeader = () => {
    switch (this.props.alert.type) {
      case ERROR:
        return this.renderError();
      case WARNING:
        return this.renderWarning();
      case INFO:
        return this.renderInfo();
      default:
        return this.renderError();
    }
  };

  toggleDetails = () => this.setState(state => ({ showDetails: !state.showDetails }));

  render() {
    const { classes, alert } = this.props;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        classes={{ paper: classes.main }}
        /* This is higher than the other MUI dialogs so it will always render on top */
        style={{ zIndex: 1350 }}
        data-testid="MirageAlert-dialog"
      >
        {this.renderHeader()}

        <section className={classes.content}>{alert.message}</section>
        {this.state.showDetails && <div className={classes.details}>{alert.details}</div>}
        <div className={classes.actions}>
          {alert.details && (
            <Button onClick={this.toggleDetails} link data-testid="MirageAlert-dialog-details">
              {this.state.showDetails ? 'Hide Details' : 'Show Details'}
            </Button>
          )}
          <Button onClick={this.handleClose} autoFocus data-testid="MirageAlert-dialog-dismiss">
            DISMISS
          </Button>
        </div>
      </Dialog>
    );
  }
}

MirageAlert.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = ({ app }) => ({ alert: app.alert });
const WithProps = connect(mapStateToProps)(MirageAlert);

export default injectSheet(styles)(WithProps);
