import React from 'react';
import orderBy from 'lodash/orderBy';

import { getReports } from 'common/api/reportApi';
import AutoSuggest from './AutoSuggest';

/**
  required:
  @prop {Function} onChange callback when input changes
  @prop {Function} value value of input

  optional:
  @prop {Function} onSuggestionSelected callback when a suggestion is selected
*/
class ReportAutoSuggest extends React.Component {
  state = {
    reports: [],
  };

  componentDidMount() {
    getReports().then(({ data: reports }) => this.setState({ reports: this.sortReports(reports) }));
  }

  sortReports = reports => orderBy(reports, [report => report.name.toLowerCase()]);

  onGetSuggestions = input => {
    if (!input) {
      return this.state.reports;
    }

    const filteredReports = this.state.reports.filter(report =>
      report.name.toLowerCase().includes(input.toLowerCase())
    );
    return filteredReports;
  };

  onSuggestionSelected = (e, { suggestionValue: name }) => {
    if (this.props.onSuggestionSelected) {
      const report = this.state.reports.find(p => p.name === name);
      this.props.onSuggestionSelected(e, report);
    }
  };

  render() {
    const { onSuggestionSelected, ...rest } = this.props;
    return (
      <AutoSuggest
        getSuggestions={this.onGetSuggestions}
        onSuggestionSelected={this.onSuggestionSelected}
        {...rest}
      />
    );
  }
}

export default ReportAutoSuggest;
