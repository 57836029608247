import {
  VLIST_INDETERMINATE,
  VLIST_CHECKED,
} from 'common/components/virtualizedList/CheckboxVirtualizedList';
import {
  DATASOURCE,
  PERSON,
  OBJECT,
  ROOT_DATASOURCE_KEY,
  ROOT_PERSON_KEY,
  ROOT_OBJECT_KEY,
} from 'common/constants/app';
import {
  AVAILABLE,
  OFFLINE,
  STARTING,
  RECORDING,
  STARTED,
  STOPPING,
  LINKED,
  UNLINKED,
  MACULA_OFFLINE,
  RESTART_MACULA,
} from 'library/redux/camera/constants';

// TODO: refactor usage of ASC and DESC from library/redux/constants
import { ASC, DESC } from 'components/Table';

export { ASC, DESC };

export const NO_ROTATION_LIVE_PROC_ERROR =
  'Rotation feature is unavailable while Data Processing Service is offline';

export const ONE_GIGABYTE = 1_073_741_824;
export const DATASOURCE_SIZE_LIMIT = 4 * ONE_GIGABYTE;

export const FILE_MAX_LENGTH = 256;
export const MAX_NUM_FILES_TO_UPLOAD = 20;
export const MAX_NUM_IMAGES_TO_UPLOAD = 5000;
export const FILE_UPLOAD_CHUNK_SIZE = 500;

export const SELECT_ALL_PROVISIONAL_PERSONS = 8;
export const SELECT_ALL_NAMED_PERSONS = 7;
export const SELECT_ALL_IMAGES = 6;
export const SELECT_ALL_VIDEOS = 5;
export const SELECT_ALL_CAMERAS = 4;
export const SELECT_ALL_FOLDERS = 3;
export const ALL_SELECTED = 2;
export const SOME_SELECTED = 1;
export const NONE_SELECTED = 0;

export const INTERNAL_DATE = 'internal_date';
export const CREATED = 'created';
export const LOCATION = 'location';
export const STATUS = 'status';
export const DURATION = 'duration';
export const UPDATED = 'last_updated';
export const NAME = 'name';
export const TITLE = 'title';
export const CURRENTLY_LIVE = 'live';
export const NONE = 'none';
export const MAKE = 'make';
export const MODEL = 'model';
export const SERIAL_NUMBER = 'serial number';
export const IP_ADDRESS = 'ip address';
export const MACULA_FPS = 'macula fps';
export const MACULA_FPS_DESCRIPTION = 'Processing Frame Rate (fps)';

export const ROOT_DIRECTORIES = {
  [DATASOURCE]: ROOT_DATASOURCE_KEY,
  [PERSON]: ROOT_PERSON_KEY,
  [OBJECT]: ROOT_OBJECT_KEY,

  [ROOT_DATASOURCE_KEY]: DATASOURCE,
  [ROOT_PERSON_KEY]: PERSON,
  [ROOT_OBJECT_KEY]: OBJECT,
};

export const SELECTION_TO_STATUS = {
  [SOME_SELECTED]: VLIST_INDETERMINATE,
  [ALL_SELECTED]: VLIST_CHECKED,
  [NONE_SELECTED]: null,
};

export const DEFAULT_SORT_DIRECTIONS = {
  [CREATED]: DESC,
  [INTERNAL_DATE]: DESC,
};

/* vidproc statuses */
export const FAILED = 'failed';
export const UPLOAD_FAILED = 'upload failed';
export const COMPLETED = 'completed';
export const UPLOADING = 'uploading';
export const ENQUEUED = 'enqueued';
export const ACCEPTED = 'accepted';
export const KILLED = 'killed';
export const RUNNING = 'running';
export const CANCELED = 'canceled';
export const WAITING = 'waiting';
export const SUBMITTED = 'submitted';
export const PROC_OFFLINE = 'proc_offline';

export const CANCELING = 'canceling';
export const COMPLETING = 'completing';

export const ENCODING_COMPLETED = 'Encoding Completed';

/* vidproc pipeline service types */
export const VIDEO_PROC = 'video_proc';
export const VID_ENC = 'vid_enc';
export const PROC_SVC = 'processing';

export const MOVE_TOP_OF_GROUP = 'Move to top of this group';
export const MOVE_BOTTOM_OF_GROUP = 'Move to bottom of this group';
export const MOVE_TO_TOP = 'Move to Top';
export const MOVE_GROUP_TO_TOP = 'Move Group to Top';

export const STATUS_PRIORITIES = {
  [RECORDING]: 1,
  [STARTED]: 2,
  [STARTING]: 3,
  [LINKED]: 4,
  [AVAILABLE]: 5,
  [UNLINKED]: 6,
  [STOPPING]: 7,
  [OFFLINE]: 8,
  [MACULA_OFFLINE]: 9,
  [RESTART_MACULA]: 10,

  /* Backend has some bad data, keeping this extra parameter so the sort order doesn't keep messed up */
  [null]: 11,
};

export const UPLOAD_STATUS_PRIORITIES = [
  { type: PROC_SVC, status: RUNNING },
  { type: PROC_SVC, status: PROC_OFFLINE },
  { type: PROC_SVC, status: CREATED },
  { type: PROC_SVC, status: ENQUEUED },
  { type: VID_ENC, status: RUNNING },
  { type: VID_ENC, status: ENQUEUED },
  { type: PROC_SVC, status: FAILED },
  { type: PROC_SVC, status: COMPLETED },
  { type: VID_ENC, status: FAILED },
  { type: PROC_SVC, status: CANCELED },
  { type: PROC_SVC, status: CANCELING },
  { type: PROC_SVC, status: COMPLETING },
];

// Library Pagination
export const DEFAULT_LIMIT = 50;

export const ARROW_UP_ICON = 'arrow_upward';
export const ARROW_DOWN_ICON = 'arrow_downward';
export const CANCEL_ICON = 'cancel';
