import React from 'react';
import injectSheet from 'react-jss';
import ChevronIcon from '@material-ui/icons/ChevronRight';
import Tooltip from 'common/components/base/Tooltip';
import OverflowText from 'common/components/base/OverflowText';

const styles = {
  simpleBreadcrumbs: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

const WIDTH = 100;

const DisplayPath = breadcrumbs =>
  breadcrumbs
    .filter(x => x) // TODO: is this filter necessary?
    .map(crumb => (
      <React.Fragment key={crumb}>
        <OverflowText showTooltip={false} width={WIDTH}>
          {crumb}
        </OverflowText>
        <ChevronIcon style={{ verticalAlign: 'middle' }} />
      </React.Fragment>
    ));

const DestinationFilePath = ({ classes, title, mirageFolderPath, ...rest }) => {
  if (!mirageFolderPath && !title) return '';

  // mirageFolderPath starts with /, so splice is to get rid of empty first element
  const breadCrumbs = mirageFolderPath
    ? mirageFolderPath
        .toString()
        .replace(/^\/|\/$/, '')
        .split('/')
    : [];
  let path = null;
  // We need to remove the first element "My Library" for displaying the path
  if (breadCrumbs.length > 2) {
    path = DisplayPath([breadCrumbs[1], '...', breadCrumbs[breadCrumbs.length - 1]]);
  } else {
    path = DisplayPath(breadCrumbs);
  }

  // The tooltip should contain the first element "My Library"
  const header = (
    <Tooltip title={title}>
      <span className={classes.simpleBreadcrumbs} {...rest}>
        {path}
      </span>
    </Tooltip>
  );

  return header;
};

export default injectSheet(styles)(DestinationFilePath);
