import * as d3 from 'd3';

import { ZONE_STYLES } from 'common/components/videoPlayer/constants';

import { useCameraZones } from 'library/libraryBody/cameraView/CameraZonesContext';
import { useZones } from './ZonesContext';

const { pointSize, defaultColor, highlightedColor, inactiveColor } = ZONE_STYLES;

const Polygon = ({
  data = [],
  cameraData,
  mouseOffset,
  editModeEnabled,
  highlightedZone,
  liveZones,
  zoneBeingEditedCoordinates,
}) => {
  const {
    zones,
    active,
    add,
    remove,
    cancel,
    complete,
    onPointMouseOver,
    onPointMouseOut,
  } = useZones();

  const { updateConfirmModal } = useCameraZones();

  const handleClickPoint = ({ e, zoneIndex, pointIndex }) => {
    e.preventDefault();
    e.stopPropagation();
    if (!editModeEnabled) return;
    const isClickingFirstPoint = zoneIndex === active && pointIndex === 0 && zones.length > 0;
    if (isClickingFirstPoint) {
      if (liveZones) {
        const currentZone = zones[zoneIndex];
        if (currentZone.length === 1) cancel({ retainIndex: true });
        else {
          add({ point: currentZone[0] });
          updateConfirmModal(true);
        }
      } else {
        complete();
      }
    } else {
      remove({ zoneIndex, pointIndex });
    }
  };

  const defaultPolygonColor = liveZones && editModeEnabled ? inactiveColor : defaultColor;

  const lines = data.map((zone, index) => {
    const determineLine = () => {
      if (active === index && !!mouseOffset) {
        return d3.line()(zone.concat([mouseOffset]));
      }

      return d3.line()(zone);
    };
    return { color: defaultColor, line: determineLine() };
  });
  const uninteractiveLines = [
    ...cameraData.map(zone => ({
      color: defaultPolygonColor,
      line: d3.line()(zone),
    })),
    ...zoneBeingEditedCoordinates.map(zone => ({
      color: defaultColor,
      line: d3.line()(zone),
    })),
  ];
  const highlightedZoneLines = highlightedZone.map(zone => ({
    color: highlightedColor,
    line: d3.line()(zone),
  }));

  const defaultZones = data.map(zone => ({ zone, color: defaultColor }));
  const savedCameraZones = [
    ...cameraData.map(zone => ({
      zone,
      color: defaultPolygonColor,
      disableClickAction: true,
    })),
    ...zoneBeingEditedCoordinates.map(zone => ({
      zone,
      color: defaultColor,
      disableClickAction: true,
    })),
  ];
  const highlightedZonePoints = highlightedZone.map(zone => ({
    zone,
    color: highlightedColor,
    disableClickAction: true,
  }));

  const polygonLines = [...lines, ...uninteractiveLines, ...highlightedZoneLines];
  const zonePoints = [...defaultZones, ...savedCameraZones, ...highlightedZonePoints];
  return (
    <>
      {polygonLines.map(({ color, line }) => (
        <path
          key={[line, color].join(';')}
          d={line}
          stroke={color}
          fill="none"
          filter="url(#boxShadow)"
          strokeWidth={pointSize}
          strokeDasharray={pointSize}
          style={{ pointerEvents: 'none' }}
        />
      ))}

      {zonePoints.map(({ zone, color, disableClickAction }, zoneIndex) =>
        zone.map(([x, y], pointIndex) => (
          <circle
            key={[zoneIndex, pointIndex, x, y, color].join(',')}
            cx={x}
            cy={y}
            r="8"
            fill={color}
            stroke="#000"
            cursor={!disableClickAction ? 'pointer' : 'default'}
            style={{ zIndex: 35 }}
            onClick={e => {
              if (!disableClickAction) handleClickPoint({ e, zoneIndex, pointIndex });
            }}
            onMouseOver={() => {
              onPointMouseOver({ zoneIndex, pointIndex, inactivePoint: disableClickAction });
            }}
            onMouseOut={onPointMouseOut}
          />
        ))
      )}
    </>
  );
};

export default Polygon;
