import axios from 'axios.js';

import { renderAlert } from 'app/redux/actions';
import { WARNING } from 'app/redux/constants';
import { ANALYTICS_BACKEND, DATASOURCES, VID_PROC } from 'common/constants/urls';

import { transformDatasourceParams } from './params';

export function getDatasources(params) {
  return axios.get(`${ANALYTICS_BACKEND}${DATASOURCES}/`, { params }).then(({ data }) => data);
}

export function getDatasourcesById(data) {
  return axios.put(`${ANALYTICS_BACKEND}${DATASOURCES}/`, data).then(res => res.data);
}

export function getImageDatasources(params) {
  return axios.get(`${ANALYTICS_BACKEND}/images/`, { params }).then(({ data }) => data);
}

export function getExportZip(id, body) {
  const options = {
    responseType: 'arraybuffer',
  };
  return axios
    .post(`${ANALYTICS_BACKEND}${DATASOURCES}/${id}/export_zip/`, body, options)
    .then(({ data }) => data);
}

export function bulkDeleteDatasources(body) {
  return axios.post(`${ANALYTICS_BACKEND}${DATASOURCES}/delete/`, body).then(({ data }) => data);
}

export function patchDatasource(datasource) {
  const { id } = datasource;
  return axios
    .patch(`${ANALYTICS_BACKEND}${DATASOURCES}/${id}/`, transformDatasourceParams(datasource))
    .then(({ data }) => data);
}

export function postDatasource(body, config) {
  return axios.post(`${ANALYTICS_BACKEND}${VID_PROC}/`, body, config).then(({ data }) => data);
}

export function getTransactionID(body) {
  return axios.post(`${ANALYTICS_BACKEND}/upload/transactions/`, body).then(({ data }) => data);
}

export function postDataSources(txID, body, config) {
  return axios.post(`${ANALYTICS_BACKEND}/upload/media/`, body, config).then(({ data }) => data);
}

function processResponse(status, statusText, stateText) {
  if (status === 204) {
    renderAlert(`One or more files failed to ${stateText} due to: ${statusText}`, {
      type: WARNING,
      title: `File(s) Failed to ${stateText}`,
    });
  }
}

export function endDataSourceProcessing(txID, body) {
  return axios
    .post(`${ANALYTICS_BACKEND}/upload/transactions/${txID}/process/`, body)
    .then(({ data, status, statusText }) => {
      processResponse(status, statusText, 'process');
      return data;
    });
}

export function endDataSourceUpload(txID, body) {
  return axios
    .post(`${ANALYTICS_BACKEND}/upload/transactions/${txID}/cancel/`, body)
    .then(({ data, status, statusText }) => {
      processResponse(status, statusText, 'upload');
      return data;
    });
}

export function getSingleDatasource(id) {
  return axios.get(`${ANALYTICS_BACKEND}${DATASOURCES}/${id}/`).then(({ data }) => data);
}

export function getDatasourceFormats() {
  return axios
    .get(`${ANALYTICS_BACKEND}${DATASOURCES}/supported-formats/`)
    .then(({ data }) => data);
}

export function getDatasourceFrameRate(id) {
  return axios.get(`${ANALYTICS_BACKEND}${DATASOURCES}/${id}/fps/`).then(({ data }) => data);
}

export function getPeriodStartTime(id) {
  return axios
    .get(`${ANALYTICS_BACKEND}${DATASOURCES}/${id}/range-start-time/`)
    .then(({ data }) => data);
}

export function getDetectionsInRange(id, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search/detections/`, {
      params,
    })
    .then(({ data }) => data);
}

export function postDatasourceReprocess(id, body) {
  return axios
    .post(`${ANALYTICS_BACKEND}${DATASOURCES}/${id}/reprocess_v2/`, body)
    .then(({ data }) => data);
}

export function getCodecFormats() {
  return axios.get(`${ANALYTICS_BACKEND}${DATASOURCES}/supported-codecs/`).then(({ data }) => data);
}
