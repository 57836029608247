import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectIsAuthenticated, selectIsOIDCSession } from 'auth/redux/selectors';
import { selectCustomerIdleLogoutTime } from 'settings/redux/selectors';

import { refreshOpenIDToken } from 'common/api/authApi';

const useOIDCSessionRefreshPolling = () => {
  const isOIDCSession = useSelector(selectIsOIDCSession);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const idleLogoutTime = useSelector(selectCustomerIdleLogoutTime);

  // Refresh once 30% of the expiration time has been exceeded
  const POLL_INTERVAL_MS = idleLogoutTime * 1000 * 0.3;

  useEffect(() => {
    if (!isOIDCSession || !isAuthenticated) return;

    refreshOpenIDToken();

    const poll = setInterval(() => {
      refreshOpenIDToken();
    }, POLL_INTERVAL_MS);

    return () => {
      clearInterval(poll);
    };
  });
};

export default useOIDCSessionRefreshPolling;
