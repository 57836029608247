import * as C from './constants';

const OPEN_STATE = 1;

function onOpen() {
  while (this.pendingSend.length) {
    const toSend = this.pendingSend.pop();
    this.send(toSend);
  }
}

function onSend(payload) {
  const readyPayload = JSON.stringify(payload);

  if (this.readyState !== OPEN_STATE) {
    this.pendingSend.push(readyPayload);
  } else {
    this.send(readyPayload);
  }
}

function onMessage(message) {
  const data = JSON.parse(message.data);

  this.listeners[data.type]?.forEach(listener => {
    if (typeof listener === 'function') {
      listener(data);
    }
  });
}

function subscribe({ type, listener }) {
  const isSubscribed = this.listeners[type]?.length;
  // No need to subscribe more than once
  if (!isSubscribed) {
    this.onSend({ command: C.SUBSCRIBE, type });
  }

  this.addListener({ type, listener });
}

function unsubscribe({ type, listener }) {
  if (listener) {
    this.removeListener({ type, listener });
  } else {
    // Unsubscribe from all if the listener is not passed
    this.removeAllListenersForType(type);
  }

  // Only unsubscribe if all listeners are removed
  if (!this.listeners[type]?.length) {
    this.onSend({ command: C.UNSUBSCRIBE, type });
  }
}

function addListener({ type, listener }) {
  this.listeners[type] = this.listeners[type] || [];
  this.listeners[type].push(listener);
}

function removeListener({ type, listener }) {
  const listenersForType = this.listeners[type];
  this.listeners[type] = listenersForType?.filter(_listener => _listener !== listener);
}

function removeAllListenersForType(type) {
  delete this.listeners[type];
}

function connectSocket(externalEventListeners) {
  const socket = new WebSocket(`wss://${window.location.host}/ws/subscribe/`);

  socket.pendingSend = [];
  socket.listeners = {};

  socket.onopen = () => {
    onOpen.call(socket);
    externalEventListeners?.onopen?.();
  };
  socket.onclose = externalEventListeners?.onclose;
  socket.onmessage = onMessage;
  socket.onSend = onSend;

  socket.subscribe = subscribe;
  socket.unsubscribe = unsubscribe;

  socket.addListener = addListener;
  socket.removeListener = removeListener;
  socket.removeAllListenersForType = removeAllListenersForType;

  return socket;
}

function connect(externalEventListeners) {
  const currentListeners = this.socket?.listeners;
  const socket = connectSocket(externalEventListeners);

  // If the socket connection has been reopened, re-subscribe to any listeners that
  // existed on the previous connection
  if (currentListeners) {
    Object.keys(currentListeners).forEach(type => {
      const listenersForType = currentListeners[type];

      listenersForType?.forEach(listener => {
        socket.subscribe({ type, listener });
      });
    });
  }

  this.socket = socket;
}

function get() {
  return this.socket;
}

function MirageSocket() {
  this.connect = connect;
  this.get = get;
}

const socket = new MirageSocket();

export default socket;
