import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="68" viewBox="0 0 81 85">
    <g fill="none" fillRule="evenodd">
      <path d="M-24-22h128v128H-24z" />
      <g fill="#659ADE">
        <path d="M44.333 27.072c-1.51-.343-3.135-.895-4.592-.654-11.3 1.87-21.408 6.872-31.687 12.267 2.524 4.62 4.933 9.066 7.378 13.491 3.466 6.276 6.842 12.585 8.485 19.655.104.447.417 1.039.788 1.201 4.39 1.899 8.96 2.942 13.863 2.92l1.51-14.85c.112.014.227.024.339.039.5 4.933 1 9.867 1.535 15.165 3.861-.798 7.328-1.61 10.834-2.209 2.736-.469 4.012-1.862 4.699-4.652 1.93-7.842 6.325-14.595 10.146-21.589 1.636-2.995 3.215-6.023 4.912-9.21-9.335-4.512-18.251-9.311-28.21-11.574zM4.523 78.305c6.267 2.375 12.35 1.52 18.38-.978.784-.324 1.953-.494 2.647-.144 9.759 4.934 19.535 4.45 29.328.22 1.316-.566 2.391-.65 3.758-.072 7.061 2.992 14.13 3.31 21.127-.289.24-.122.532-.155 1.143-.321-.837 3.793-4.742 5.114-6.446 5.731-2.284.827-12.61 1.761-17.583-3.739-7.064 4.663-9.384 5.692-16.14 5.706-6.778.018-10.096-.877-16.346-5.767-7.88 5.143-11.455 5.296-17.092 3.966-2.776-.655-5.552-2.024-6.667-5.208.137-.158.274-.324.41-.487 1.158.462 2.313.938 3.481 1.382z" />
        <path d="M62.681 13.57h-12.81V7.962h-8.652V.805h-2.3v7.157h-8.652v5.608h-12.81v2.834c1.175 1.053 2.265 2.028 3.61 3.237-.288 1.064 1.485 3.37-1.805 3.59v6.688c.636-.19 1.172-.346 1.704-.516.212-.068.406-.187.615-.267 8.942-3.457 13.66-5.15 18.488-5.089 4.832-.06 9.55 1.632 18.488 5.09.209.079.403.198.615.266.532.173 1.068.325 1.704.516v-6.687c-3.29-.22-1.513-2.527-1.805-3.591l3.61-3.237V13.57zM31.89 22.893h-6.012v-3.79h6.012v3.79zm11.534.025h-6.716v-3.811h6.716v3.811zm10.834-.025h-6.012v-3.79h6.012v3.79z" />
      </g>
    </g>
  </svg>
);
