import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as AuthSelectors from 'auth/redux/selectors';
import { getAuthorizationURL } from 'common/helpers/authUtils';
import { getOpenIDConfig } from 'common/api/authApi';
import Button from 'common/components/base/Button';
import GLogo from 'common/images/g-logo.png';
import OpenIdLogo from 'common/images/openid-logo.png';
import { GOOGLE_OPEN_ID_DOMAIN } from 'common/constants/urls';
import { useLogin } from 'auth/LoginContext';
import ConsentCheckbox from 'auth/ConsentCheckbox';

function getTheme(domain) {
  if (domain === GOOGLE_OPEN_ID_DOMAIN) {
    return 'white';
  }
  return 'orange';
}

function getStyle(domain) {
  if (domain === GOOGLE_OPEN_ID_DOMAIN) {
    return { textTransform: 'unset', fontWeight: 500 };
  }
  return { fontWeight: 500 };
}

function getText(domain) {
  if (domain === GOOGLE_OPEN_ID_DOMAIN) {
    return (
      <React.Fragment>
        <img
          src={GLogo}
          style={{
            height: 22,
            width: 22,
            marginRight: 6,
          }}
          alt="google"
        />
        Sign in with Google
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <img
        src={OpenIdLogo}
        style={{
          height: 16,
          width: 16,
          marginRight: 6,
        }}
        alt="openid"
      />
      Sign in with Open ID
    </React.Fragment>
  );
}

const OpenIDButton = ({ onClick, ...rest }) => {
  const OIDC_ENDPOINT = useSelector(AuthSelectors.selectOIDCEndpoint);
  const OIDC_CLIENT_ID = useSelector(AuthSelectors.selectOIDCClientId);
  const theme = getTheme(OIDC_ENDPOINT);
  const style = getStyle(OIDC_ENDPOINT);
  const text = getText(OIDC_ENDPOINT);
  const { isLoginDisabled } = useLogin();

  return (
    <>
      <ConsentCheckbox />
      <Button
        theme={theme}
        style={style}
        disabled={isLoginDisabled}
        onClick={e => {
          getOpenIDConfig(OIDC_ENDPOINT).then(({ authorization_endpoint: authEndpoint }) => {
            const config = { client_id: OIDC_CLIENT_ID };
            const url = getAuthorizationURL(authEndpoint, config);

            window.location.assign(url);
            if (onClick) onClick(e);
          });
        }}
        {...rest}
      >
        {text}
      </Button>
    </>
  );
};

OpenIDButton.propTypes = {
  onClick: PropTypes.func,
};

export default OpenIDButton;
