import first from 'lodash/first';

import { parseToShorthandDate } from 'common/helpers/dateUtils';
import { toHHMMSS } from 'common/components/videoPlayer/utils/utils';
import { getDateInMs } from 'common/helpers/datasourceUtils';
import { THRESHOLD_DATE } from 'common/constants/times';

export function generateConfirmIconTooltip(isConfirmed, isUpdating) {
  if (isConfirmed) {
    return isUpdating ? 'Removing...' : 'Remove Confirmation';
  }

  return isUpdating ? 'Confirming...' : 'Confirm?';
}

export function generateKey(matchResult) {
  return `${matchResult.mediaId}_${matchResult.fromTime}_${matchResult.toTime}`;
}

export function generateImageResultKey(matchResult) {
  return `${matchResult.mediaId}_${matchResult.timestamp}`;
}

export function getTimeFromMatchResult(matchResult) {
  const firstResult = first(matchResult.matchData);
  return firstResult?.thumb?.timestamp;
}

export function getTrackletTime(startTime, endTime) {
  const detectionSeconds = (endTime - startTime) / 1000;
  return `${detectionSeconds.toFixed(1)} seconds`;
}

export function getDetectionPosition(match, datasource = {}) {
  let time = match.timestamp;
  if (!time) return '';
  time -= datasource.startEpochMs || 0;
  return toHHMMSS(time / 1000);
}

export function getDetectionTime(match, datasource) {
  const dsTime = getDateInMs(datasource);
  const videoTime = match.timestamp - datasource.startEpochMs;
  const displayTimezoneName = datasource?.displayTimezoneName;
  /*
    videoTime is the relative timestamp in the video, dsTime is the date of the datasource,
    this may be the startEpochMs, but we will take the date of datasource instead if
    it does NOT exist (e.g. if startEpochMs is 0).
  */
  const time = dsTime + videoTime;
  return dsTime > THRESHOLD_DATE
    ? `${parseToShorthandDate(time, {
        showDayOfWeek: true,
        showSeconds: true,
        showTimeZone: true,
        displayTimezoneName,
      })}`
    : '';
}

/* Detections don't have a unique identifier yet, so we will need to make one */
export function getDetectionId(matchResult) {
  const time = getTimeFromMatchResult(matchResult);
  const videoId = matchResult.mediaId;

  return createDetectionId(time, videoId);
}

export function createDetectionId(time, videoId) {
  return `${videoId}_${time}`;
}

/* Determines whether or not the matchData is the exact image that it references */
export function isExactReference(matchData) {
  return !matchData.confirmedThumbHash && matchData.exactMatch;
}

// Convert to hours with the decimal off by 1 position, round to integer,
// and then convert back to true hours. Aka "1073" -> "1070", "91824" -> "91820"
export const formatSecondsToRoundedHours = seconds =>
  seconds < 60 * 60 * 10 ? Math.floor(seconds / 60 / 60) : Math.floor(seconds / 60 / 60 / 10) * 10;
