import React from 'react';
import classnames from 'classnames';
import MuiTextField from '@material-ui/core/TextField';
import { withStyles } from 'tss-react-local';

const styles = {
  helperTextRoot: {
    fontSize: 9,
    fontWeight: 300,
  },
  root: {
    fontSize: 13,
  },
  inputMarginDense: {
    paddingBottom: 3,
  },
};

function getLabel(label, shouldShrink) {
  if (!label) {
    /*
      This allows the helperText and label to maintain a static height so the input
      doesn't decrease in height when they are empty.
    */
    return shouldShrink ? '' : ' ';
  }

  return label;
}

class TextField extends React.PureComponent {
  static defaultProps = {
    customClasses: {},
    margin: 'dense',
  };

  onChange = e => {
    const { onChange, onValueChange } = this.props;
    if (onChange) onChange(e);
    if (onValueChange) onValueChange(e.target.value);
  };

  render() {
    const {
      classes,
      customClasses,
      disabled,
      endAdornment,
      FormHelperTextProps,
      hasError,
      helperText,
      inputRef,
      inputProps,
      InputLabelProps,
      InputIcon,
      InputProps,
      label,
      readOnly,
      onValueChange,
      shrinkOnEmptyLabel,
      startAdornment,
      ...rest
    } = this.props;

    const {
      formHelperTextClasses = {},
      inputLabelClasses = {},
      inputClasses = {},
      textfieldClasses = {},
    } = customClasses;

    return (
      <MuiTextField
        classes={textfieldClasses}
        disabled={disabled}
        error={hasError}
        helperText={getLabel(helperText, shrinkOnEmptyLabel)}
        inputRef={inputRef}
        inputProps={{
          autoComplete: 'off',
          readOnly,
          disabled: readOnly || disabled,
          ...inputProps,
        }}
        InputLabelProps={{
          classes: {
            root: classes.root,
            ...inputLabelClasses,
          },
          ...InputLabelProps,
        }}
        /* eslint-disable react/jsx-no-duplicate-props */
        InputProps={{
          startAdornment,
          endAdornment,
          classes: {
            ...inputClasses,
            root: classes.root,
            inputMarginDense: classnames(classes.inputMarginDense, inputClasses.inputMarginDense),
          },
          ...InputProps,
        }}
        FormHelperTextProps={{
          classes: {
            ...formHelperTextClasses,
            root: classnames(classes.helperTextRoot, formHelperTextClasses.root),
          },
          ...FormHelperTextProps,
        }}
        label={getLabel(label, shrinkOnEmptyLabel)}
        onChange={this.onChange}
        data-testid="TextInput"
        {...rest}
      />
    );
  }
}

export default withStyles(TextField, styles);
