import axios from 'axios.js';

import { ANALYTICS_BACKEND, DATASOURCES, SCENES, THUMB } from 'common/constants/urls';

import { parseContentRange } from './utils';

export function getScenes(params) {
  return axios.get(`${ANALYTICS_BACKEND}${SCENES}/`, { params }).then(({ data, headers }) => {
    const range = parseContentRange(headers);

    return { data, range };
  });
}

export function getSceneImageURL({ source, timestamp }) {
  return axios
    .get(`${ANALYTICS_BACKEND}${DATASOURCES}/${source}${THUMB}/${timestamp}/`)
    .then(({ data }) => data);
}

export function getSceneStillImageURL({ source }) {
  return axios.get(`${ANALYTICS_BACKEND}/images/${source}/screenshot/`).then(({ data }) => data);
}

export function deleteScene(id) {
  return axios.delete(`${ANALYTICS_BACKEND}/case/scene/${id}/`).then(({ data }) => data);
}

export function postScene(scene) {
  return axios.post(`${ANALYTICS_BACKEND}${SCENES}/`, scene).then(({ data }) => data);
}

export function patchScene(scene) {
  return axios.patch(`${ANALYTICS_BACKEND}${SCENES}/${scene.id}/`, scene).then(({ data }) => data);
}
