import { useEffect, useRef } from 'react';
import { createUseStyles, styled } from 'react-jss';
import FormHelperText from '@material-ui/core/FormHelperText';

import Button from 'common/components/base/Button';
import TextInput from 'common/components/base/TextInput';

import { useLogin } from './LoginContext';
import ConsentCheckbox from './ConsentCheckbox';

const useInputLabelStyles = createUseStyles({ asterisk: { display: 'none' } });
const useHelperTextStyles = createUseStyles({ root: { display: 'none' } });
const useStyles = createUseStyles(theme => ({ password: { marginTop: theme.spacing(2) } }));

const ErrorBox = styled(FormHelperText)(({ theme }) => ({
  margin: theme.spacing(1, 0, 3),
  minHeight: 19,
}));

const Signin = () => {
  const classes = useStyles();
  const { isLoading, error, names, isLoginDisabled } = useLogin();
  const usernameRef = useRef(null);
  const customClasses = {
    inputLabelClasses: useInputLabelStyles(),
    formHelperTextClasses: useHelperTextStyles(),
  };

  useEffect(() => usernameRef.current?.focus(), []);

  return (
    <>
      <TextInput
        customClasses={customClasses}
        data-testid="Signin-username"
        inputRef={usernameRef}
        label="Username"
        name={names.USERNAME}
        required
        type="text"
      />
      <TextInput
        className={classes.password}
        customClasses={customClasses}
        data-testid="Signin-password"
        label="Password"
        name={names.PASSWORD}
        required
        type="password"
      />
      <ErrorBox error data-testid="signin-error">
        {error}
      </ErrorBox>
      <ConsentCheckbox />
      <Button
        data-testid="Signin-signin-button"
        disabled={isLoginDisabled}
        fullWidth
        isLoading={isLoading}
        type="submit"
      >
        Sign In
      </Button>
    </>
  );
};

export default Signin;
