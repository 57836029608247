import React from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { createUseStyles } from 'react-jss';
import Icon from 'common/components/base/Icon';

import { TITLE_SELECTED } from 'common/constants/colors';

import IdentityPackageImages from './IdentityPackageImages';
import IdentityPackageNetworks from './IdentityPackageNetworks';

const useStyles = createUseStyles(theme => ({
  tabRoot: {
    minWidth: theme.spacing(30),
  },
  iconRoot: {
    color: theme.palette.text.secondary,
    marginBottom: '0!important',
    marginRight: theme.spacing(1),
  },
}));

const IdentityPackageMainContent = () => {
  const { tabName, personId } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const iconProps = {
    classes: {
      root: classes.iconRoot,
    },
  };

  const TABS = [
    {
      name: 'networks',
      label: 'Networks',
      icon: <Icon iconName="group" {...iconProps} />,
    },
    {
      name: 'images',
      label: 'Images',
      icon: <Icon iconName="image" {...iconProps} />,
    },
  ];

  const TABS_MAP = TABS.reduce(
    (accum, { name }, index) => ({
      ...accum,
      [name]: index,
    }),
    {}
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs
          value={TABS_MAP[tabName]}
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              background: TITLE_SELECTED,
            },
          }}
          onChange={(_, value) => {
            const { name } = TABS[value];
            history.push(`/identity-package/${personId}/${name}`);
          }}
        >
          {TABS.map(({ name, label, icon }) => (
            <Tab
              key={name}
              label={label}
              icon={icon}
              classes={{
                root: classes.tabRoot,
              }}
              selected={name === tabName}
            />
          ))}
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        <Switch>
          <Route path={`/identity-package/${personId}/images`} component={IdentityPackageImages} />
          <Route
            path={`/identity-package/${personId}/networks`}
            component={IdentityPackageNetworks}
          />
        </Switch>
      </Grid>
    </Grid>
  );
};

export default IdentityPackageMainContent;
