import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="59" height="80" viewBox="0 0 74 100">
    <g fill="none" fillRule="evenodd">
      <path d="M-27-14h128v128H-27z" />
      <path
        fill="#6599DE"
        fillRule="nonzero"
        d="M.219 23.051h17.965c.68-5.422 2.71-10.508 5.086-14.238 7.457-11.863 20-11.527 27.457 0 2.71 3.73 4.406 8.812 5.086 14.238h17.965v64.406c0 6.781-5.422 12.543-12.203 12.543H12.423C5.642 100 .22 94.238.22 87.457L.219 23.051zm21.355 0h30.848c-.68-4.746-2.371-9.152-4.406-12.543-6.102-9.492-15.594-9.492-22.035 0-2.035 3.39-3.73 7.797-4.406 12.543h-.001zm32.883 5.086c-1.355 0-2.71 1.015-2.71 2.71 0 1.356 1.355 2.712 2.71 2.712 1.695 0 2.71-1.356 2.71-2.711 0-1.695-1.015-2.711-2.71-2.711z"
      />
      <path
        fill="#FFF"
        d="M19.543 28.137c1.695 0 2.71 1.015 2.71 2.71 0 1.356-1.015 2.712-2.71 2.712-1.355 0-2.71-1.356-2.71-2.711 0-1.695 1.355-2.711 2.71-2.711z"
      />
    </g>
  </svg>
);
