import { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { isEqual } from 'lodash';

import { DEFAULT_ZOOM, DEFAULT_TRANSLATE, ZOOM_STEP, MAX_ZOOM } from 'common/constants/panAndZoom';
import useWindowEventListener from 'common/hooks/useWindowEventListener';

import PanAndZoomControls from './PanAndZoomControls';

const useStyles = createUseStyles({
  pandAndZoomContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  pandAndZoomWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div': {
      minWidth: 0,
      minHeight: 0,
      cursor: 'pointer',
    },
  },
});

const PanAndZoom = ({
  id = 'PanAndZoom',
  defaultZoom = DEFAULT_ZOOM,
  defaultTranslate = DEFAULT_TRANSLATE,
  maxZoom = MAX_ZOOM,
  children,
  resolutionWidth,
  resolutionHeight,
  hidePanAndZoomControls,
  additionalControlsPadding,
  disabled = false,
  onChildClick,
  onChange,
}) => {
  const classes = useStyles();
  const overlayRef = useRef(null);
  const mousePositionRef = useRef(null);
  const didPanRef = useRef(false);

  const [translate, setTranslate] = useState(defaultTranslate); // pan x, y
  const [zoom, setZoom] = useState(defaultZoom); // zoom %
  const [scaledDimensions, setScaledDimensions] = useState([resolutionWidth, resolutionHeight]);

  // If TRUE, it indicates that the user has neither zoomed nor panned
  const isPristine = useMemo(
    () => isEqual(zoom, defaultZoom) && isEqual(translate, defaultTranslate),
    [zoom, translate, defaultZoom, defaultTranslate]
  );

  const onMouseDown = useCallback(
    e => {
      if (disabled || isPristine) {
        return;
      }

      mousePositionRef.current = [e.pageX, e.pageY];
    },
    [disabled, isPristine]
  );

  const onMouseMove = useCallback(
    e => {
      if (disabled || !mousePositionRef.current) {
        return;
      }

      const offsetX = e.pageX - mousePositionRef.current[0];
      const offsetY = e.pageY - mousePositionRef.current[1];

      setTranslate(prevTranslate => [prevTranslate[0] + offsetX, prevTranslate[1] + offsetY]);

      didPanRef.current = true;
      mousePositionRef.current = [e.pageX, e.pageY];
    },
    [disabled]
  );

  const onMouseUp = useCallback(
    e => {
      if (disabled) {
        return;
      }

      if (!didPanRef.current && onChildClick) {
        onChildClick(e);
      }

      didPanRef.current = null;
      mousePositionRef.current = null;
    },
    [disabled, onChildClick]
  );

  const onReset = useCallback(() => {
    setTranslate([0, 0]);
    setZoom(defaultZoom);
  }, [defaultZoom]);

  const onZoomIn = () => {
    setZoom(Math.min(zoom + ZOOM_STEP, maxZoom));
  };

  const onZoomOut = () => {
    setZoom(Math.max(zoom - ZOOM_STEP, defaultZoom));
  };

  const calculateScaledDimensions = useCallback(() => {
    const scaleFactor = Math.min(
      overlayRef.current.offsetHeight / resolutionHeight,
      overlayRef.current.offsetWidth / resolutionWidth
    );

    const scaleAdjustedHeight = resolutionHeight * scaleFactor;
    const scaleAdjustedWidth = resolutionWidth * scaleFactor;

    setScaledDimensions([scaleAdjustedWidth, scaleAdjustedHeight]);
  }, [resolutionWidth, resolutionHeight]);

  // if overlayRef changes (and is not undefined), scrub prev mouse translate & zoom
  useEffect(() => {
    if (id) {
      onReset();
    }
  }, [id, onReset]);

  useEffect(() => {
    if (overlayRef.current) {
      const scale = zoom / 100;
      const translateX = (translate[0] / zoom) * 100;
      const translateY = (translate[1] / zoom) * 100;

      overlayRef.current.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
    }

    if (onChange) {
      onChange({ zoom, translate });
    }
  }, [zoom, translate]); // eslint-disable-line

  useEffect(() => {
    if (overlayRef.current) {
      calculateScaledDimensions();
    }
  }, [calculateScaledDimensions]);

  useWindowEventListener('mouseup', onMouseUp, [onMouseUp]);
  useWindowEventListener('resize', calculateScaledDimensions, [calculateScaledDimensions]);
  useWindowEventListener('enlarge', onReset, [onReset]);

  return (
    <div className={classes.pandAndZoomContainer}>
      <div
        id={id}
        data-testid="PanAndZoom"
        className={classes.pandAndZoomWrapper}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        data-scaleddimensions={scaledDimensions}
        ref={overlayRef}
        role="dialog"
      >
        {children}
      </div>

      {!disabled && !hidePanAndZoomControls && (
        <PanAndZoomControls
          additionalControlsPadding={additionalControlsPadding}
          isPristine={isPristine}
          onReset={onReset}
          onZoomIn={onZoomIn}
          onZoomOut={onZoomOut}
        />
      )}
    </div>
  );
};

export default PanAndZoom;
