import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SEARCH_REQUEST_COMPLETE, SEARCH_REQUEST_EXPIRED } from 'analysis/redux/constants';
import { ANALYSIS_ROUTE } from 'common/constants/urls';

import ConfirmDialog from 'common/components/popups/dialogs/ConfirmDialog';
import { clearSearchResults, setSearchRequest } from 'analysis/redux/actions';
import { getSearchRequestStatus } from 'analysis/redux/utils';
import {
  getSearchRequest,
  getSearchRequests,
  getSearchRequestResults,
} from 'common/api/searchRequest';
import buildIdentityRows from 'common/helpers/buildIdentityRows';
import { conditionalPluralize } from 'common/helpers/stringUtils';

function isPersonInQuery(searchRequest, personId) {
  const identityRows = buildIdentityRows(searchRequest.query);

  return identityRows.some(({ identities }) => identities.some(({ id }) => id === personId));
}

class EditIdentityNameConfirm extends React.Component {
  state = {
    activeSearchRequests: [],
  };

  componentDidMount() {
    this.fetchActiveSearchRequests();
  }

  fetchActiveSearchRequests = () =>
    getSearchRequests().then(data => {
      const activeSearchRequests = data.filter(
        searchRequest =>
          getSearchRequestStatus(searchRequest) === SEARCH_REQUEST_COMPLETE &&
          searchRequest.status.matchedSegmentsCount > 0 && // Has results
          isPersonInQuery(searchRequest, this.props.identity?.id)
      );

      if (activeSearchRequests.length) {
        this.setState({ activeSearchRequests });
        this.props.setHasActiveSearchRequests(true);
      }
    });

  onConfirm = () =>
    this.props.onConfirm().then(() => {
      // If the user updated the name of the Person from the Analysis view,
      // the currently-viewed search request will become invalid
      if (this.props.history.location.pathname.includes(ANALYSIS_ROUTE)) {
        this.fetchSearchRequest();
      }
    });

  fetchSearchRequest = () => {
    const { requestId } = this.props.searchRequest;

    // After the identity name has updated, getSearchRequestResults currently
    // returns a 500 Internal Server Error, but it is only after calling
    // getSearchRequestResults that the search request will show as expired.
    // Call getSearchRequestResults to expire the search request, then fetch and
    // set the expired request
    return (
      getSearchRequestResults(requestId, {
        limit: 1,
        offset: 0,
      })
        // .finally must be called since getSearchRequestResults returns an error
        .finally(() =>
          getSearchRequest(requestId).then(searchRequest => {
            if (searchRequest.status.status === SEARCH_REQUEST_EXPIRED) {
              this.props.dispatchSetSearchRequest(searchRequest);
              this.props.dispatchClearSearchResults();
            }
          })
        )
    );
  };

  render() {
    return (
      <ConfirmDialog
        dialogTitle={`Are you sure you want to edit '${this.props.identity?.name}'?`}
        dialogContent={`This identity is currently in use in ${conditionalPluralize`${{
          count: this.state.activeSearchRequests.length,
        }} active search ${{
          word: 'request',
        }}`}. Updating a identity's name will expire all active search requests that contain this identity.`}
        dialogAcceptFunc={this.onConfirm}
        handleClose={this.props.handleClose}
        open={this.props.open}
        acceptText="Proceed Anyway"
      />
    );
  }
}

const mapStateToProps = ({ analysis }) => ({
  searchRequest: analysis.main.searchRequest,
});

const mapDispatchToProps = {
  dispatchClearSearchResults: clearSearchResults,
  dispatchSetSearchRequest: setSearchRequest,
};

const WithProps = connect(mapStateToProps, mapDispatchToProps)(EditIdentityNameConfirm);

export default withRouter(WithProps);
