import React from 'react';

import Checkbox from 'common/components/base/Checkbox';
import Icon from 'common/components/base/Icon';
import VirtualizedList from './VirtualizedList';

const sortIconStyle = { fontSize: 15 };
const NOOP = () => {};

/* VirtualizedList constants */
export const VLIST_INDETERMINATE = 'indeterminate';
export const VLIST_CHECKED = 'checked';

/* This component is slightly special and will create a checkbox header if labeled as __checkbox */
class CheckboxVirtualizedList extends React.Component {
  checkboxHeaderRenderer = ({
    dataKey,
    label,
    sortBy,
    sortDirection,
    status,
    onStatusClick = NOOP,
  }) => {
    const showSortIndicator = sortBy === dataKey;
    const tooltip = this.props.config.find(x => x.dataKey === dataKey)?.tooltipLabel;

    const header =
      label === '__checkbox' ? (
        <Checkbox
          key="checkbox"
          size="small"
          indeterminate={status === VLIST_INDETERMINATE}
          checked={status === VLIST_CHECKED}
          disabled={this.props.disableStatusClick}
          onChange={onStatusClick}
        />
      ) : (
        <span
          className="ReactVirtualized__Table__headerTruncatedText"
          key="label"
          title={tooltip || label}
        >
          {label}
        </span>
      );

    const children = [header];

    if (showSortIndicator) {
      children.push(
        <Icon
          iconName={sortDirection === 'ASC' ? 'arrow_upward' : 'arrow_downward'}
          key="SortIndicator"
          style={sortIconStyle}
        />
      );
    }

    return children;
  };

  render() {
    const { status, onStatusClick, ...rest } = this.props;
    return (
      <VirtualizedList
        {...rest}
        headerRenderer={this.checkboxHeaderRenderer}
        headerProps={{
          status,
          onStatusClick,
        }}
      />
    );
  }
}

export default CheckboxVirtualizedList;
