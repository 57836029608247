import { useMemo, useState, useCallback } from 'react';

import { STATUS } from 'common/constants/api';
import { normalizeList } from 'common/helpers/helperFunctions';
import {
  getIdentityLinksForPerson,
  postIdentityLink,
  deleteIdentityLink,
  patchIdentityLink,
} from 'common/api/identityApi';

const useIdentityLinks = principalId => {
  const [fetchError, setFetchError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [data, setData] = useState([]);
  const [fetchStatus, setFetchStatus] = useState(STATUS.IDLE);
  const [createStatus, setCreateStatus] = useState(STATUS.IDLE);
  const [deleteStatus, setDeleteStatus] = useState(STATUS.IDLE);
  const [updateStatus, setUpdateStatus] = useState(STATUS.IDLE);

  const fetchIdentityLinks = useCallback(() => {
    setFetchStatus(STATUS.PENDING);
    getIdentityLinksForPerson({ from_identity: principalId, page: 1, page_size: 50 })
      .then(response => {
        setData(response.data);
        setFetchStatus(STATUS.SUCCESS);
      })
      .catch(e => {
        setFetchStatus(STATUS.FAILURE);
        setFetchError(e.message);
      });
  }, [principalId]);

  const createLink = useCallback(
    async ({ to, relationship, strength }) => {
      try {
        setCreateStatus(STATUS.PENDING);
        const responseData = await postIdentityLink({
          from_identity: principalId,
          to_identity: to,
          relationship,
          strength,
        });
        setData(prev => prev.concat(responseData));
        setCreateStatus(STATUS.SUCCESS);
        return responseData;
      } catch (e) {
        setCreateStatus(STATUS.FAILURE);
        setCreateError(e.message);
      }
      return undefined;
    },
    [principalId]
  );

  const deleteLink = useCallback(async linkId => {
    try {
      setDeleteStatus(STATUS.PENDING);
      await deleteIdentityLink(linkId);
      setData(prev => prev.filter(({ id }) => id !== linkId));
      setDeleteStatus(STATUS.SUCCESS);
    } catch (e) {
      setDeleteStatus(STATUS.FAILURE);
      setDeleteError(e.message);
    }
  }, []);

  const updateIdentityLink = useCallback(async options => {
    try {
      setUpdateStatus(STATUS.PENDING);
      const response = await patchIdentityLink(options);
      setData(prev =>
        prev.reduce((acc, v) => {
          acc.push(v.id === response.id ? response : v);
          return acc;
        }, [])
      );
      setUpdateStatus(STATUS.SUCCESS);
    } catch (e) {
      setUpdateStatus(STATUS.FAILURE);
      setUpdateError(e.message);
    }
  }, []);

  const statuses = [fetchStatus, createStatus, deleteStatus, updateStatus];
  const { ids, toNames } = useMemo(
    () =>
      data.reduce(
        (acc, v) => {
          acc.ids.push(v.id);
          acc.toNames.push(v.toIdentity.name);
          return acc;
        },
        { ids: [], toNames: [] }
      ),
    [data]
  );

  return {
    data,
    dataById: normalizeList(data),
    ids,
    toNames,
    createLink,
    deleteLink,
    fetchIdentityLinks,
    updateLink: updateIdentityLink,
    fetchError,
    createError,
    deleteError,
    updateError,
    fetchStatus,
    createStatus,
    deleteStatus,
    updateStatus,
    isLoaded: statuses.every(s => s !== STATUS.PENDING) && statuses.some(s => s !== STATUS.IDLE),
  };
};

export default useIdentityLinks;
