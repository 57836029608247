/* eslint-disable import/no-cycle */
import { batchActions } from 'redux-batched-actions';

import { ASSET_BACKEND } from 'common/constants/urls';
import { IDENTITY_TYPES } from 'common/constants/objects';
import {
  deletePerson,
  deleteImagesFromPerson,
  getPersons,
  getSinglePerson,
  patchPerson,
  postConfirmIdentity,
} from 'common/api/personApi';
import { postIdentityImage } from 'common/api/identityApi';
import { mergePersonModels, setPersonModels } from 'common/redux/models/actions';
import { normalizeList } from 'common/helpers/helperFunctions';
import { removePersonsFromPlan } from 'plan/redux/actions';
import { fetchInspectModeImageDetections } from 'analysis/redux/actions';

function makeDetectionKey({ x, y, w, h, k, e }) {
  return `${x}${y}${w}${h}${k}${e.length}`;
}

function thumbnailUrl(imageId, { x, y, w, h }) {
  const url = `api/images/${imageId}/snip/?x=${x}&y=${y}&w=${w}&h=${h}`;
  return ASSET_BACKEND ? `${ASSET_BACKEND}/${url}` : url;
}

const setAllPersons = data => ({
  type: 'LIBRARY/PERSON/SET_ALL_DATA',
  payload: data,
});

export function setObjectTypeFilter(payload) {
  return {
    type: 'LIBRARY/PERSON/SET_FILTER_OBJECT_TYPE',
    payload,
  };
}

export function setIdentityStatusFilter(payload) {
  return {
    type: 'LIBRARY/PERSON/SET_FILTER_IDENTITY_STATUS',
    payload,
  };
}

/* ========================= THUNK ACTION CREATORS ========================== */

export const fetchSinglePerson = id => dispatch =>
  getSinglePerson(id).then(data => {
    dispatch(mergePersonModels({ [data.id]: data }));
    return data;
  });

export const fetchPersons = () => dispatch =>
  getPersons().then(data => {
    const persons = normalizeList(data);
    const actions = [setPersonModels(persons), setAllPersons(Object.keys(persons))];

    dispatch(batchActions(actions));
    return persons;
  });

export const editPerson = person => dispatch =>
  patchPerson(person).then(editedPerson => {
    dispatch(mergePersonModels({ [editedPerson.id]: editedPerson }));
    return editedPerson;
  });

export const uploadIdentityImage = file => () => {
  const config = {
    headers: {
      'Content-Type': file.type,
    },
  };

  const body = new FormData();
  body.append(file.name, file);

  return postIdentityImage(body, config).then(({ id }) => id);
};

export function fetchImageDetections({ imageId }) {
  return async function fetchImageDetectionsThunk(dispatch) {
    const detections = await dispatch(fetchInspectModeImageDetections(imageId));
    return detections
      .filter(({ k }) => IDENTITY_TYPES.includes(k))
      .map(detection => ({
        ...detection,
        image_id: imageId,
        key: makeDetectionKey(detection),
        thumbnailUrl: thumbnailUrl(imageId, detection),
      }));
  };
}

const removePersons = keys => (dispatch, getState) => {
  const requests = [];
  const normalizedList = {};

  getState().library.person.allData.forEach(id => (normalizedList[id] = true));

  dispatch(removePersonsFromPlan(keys));

  keys.forEach(id => {
    requests.push(deletePerson(id));
    delete normalizedList[id];
  });

  return Promise.all(requests).then(() => {
    const persons = Object.keys(normalizedList);
    dispatch(setAllPersons(persons));
  });
};

export const removePerson = id => dispatch => dispatch(removePersons([id]));

export const removeImagesFromPerson = ({ id, images }) => dispatch =>
  deleteImagesFromPerson({ id, images }).then(() => dispatch(fetchSinglePerson(id)));

export function createIdentity(identity) {
  return async function createIdentityThunk(dispatch) {
    const _identity = await postConfirmIdentity(identity);
    dispatch(mergePersonModels({ [_identity.id]: _identity }));
    return _identity;
  };
}
