import axios from 'axios.js';
import { ANALYTICS_BACKEND } from 'common/constants/urls';

export function getLiveQueries() {
  return axios.get(`${ANALYTICS_BACKEND}/live-query/`).then(({ data }) => data);
}

export function patchLiveQuery(id, body) {
  return axios.patch(`${ANALYTICS_BACKEND}/live-query/${id}/`, body).then(({ data }) => data);
}

export function postLiveQuery(body) {
  /* Weird hack for server */
  Object.assign(body, { userid: 1234 });

  return axios.post(`${ANALYTICS_BACKEND}/live-query/`, body).then(({ data }) => data);
}

export function deleteLiveQuery(id) {
  return axios.delete(`${ANALYTICS_BACKEND}/live-query/${id}`).then(({ data }) => data);
}

export function postSendAlert(body) {
  return axios.post(`${ANALYTICS_BACKEND}/send-alert/`, body).then(({ data }) => data);
}
