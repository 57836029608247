import compact from 'lodash/compact';

import store from 'redux/store';

import { CAMERAS, ANALYTICS_BACKEND } from 'common/constants/urls';
import { CAMERA_ACTIONS } from 'common/constants/callToAction';
import {
  RECORDING,
  OFFLINE,
  LINKED,
  STARTED,
  STARTING,
  MACULA_OFFLINE,
  RESTART_MACULA,
  SLOT_UNAVAILABLE,
} from './constants';

export function getCameraSnapshotURL(id) {
  return `${ANALYTICS_BACKEND}${CAMERAS}/${id}/snapshot/`;
}

export function getRTSPSnapshotURL(rtsp) {
  return `${ANALYTICS_BACKEND}${CAMERAS}/${encodeURIComponent(rtsp)}/rtsp-snapshot/`;
}

function isLiveProcConnectionFailed(camera) {
  return camera.status === RESTART_MACULA;
}

export function isCameraLive(input) {
  /**
    @param input either the camera object or the camera status as a string
  */

  if (typeof input === 'string') {
    return input === RECORDING;
  }

  return input && input.status === RECORDING;
}

export function isCameraOffline(camera) {
  return camera.status === null || camera.status === OFFLINE || camera.status === MACULA_OFFLINE;
}

export function isCameraStarting(camera) {
  return camera && camera.status === STARTING;
}

export function isCameraInitializing(camera) {
  return camera && [LINKED, STARTING, STARTED].includes(camera.status);
}

export function isCameraStoppable(camera) {
  return (
    camera && [RECORDING, STARTED, LINKED, RESTART_MACULA, SLOT_UNAVAILABLE].includes(camera.status)
  );
}

const OCCUPYING_STATES = [RECORDING, STARTED, STARTING, LINKED];

export function isCameraOccupyingSlots(camera) {
  return camera && OCCUPYING_STATES.includes(camera.status);
}

export function getCameraMenuOptions(camera) {
  return isCameraStoppable(camera)
    ? isLiveProcConnectionFailed(camera)
      ? CAMERA_ACTIONS.FORCE_QUIT
      : CAMERA_ACTIONS.STOP
    : CAMERA_ACTIONS.START;
}

export function getCameraNameStringFromIds(cameraIds) {
  const { camera: cameraModels } = store.getState().common.models;
  const names = cameraIds.map(id => (cameraModels[id] ? cameraModels[id].displayName : null));

  return compact(names).join(', ');
}
