import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useCamera from 'library/fmv/context/useCamera';
import { selectZoneBeingEdited, selectIsEditModeEnabled } from 'library/redux/camera/selectors';
import getViewPropsFromURL from 'library/common/getViewPropsFromURL';
import { frontendZones } from 'analysis/redux/utils';

import { generateQueryActions } from 'common/components/popups/zones/utils';

const CameraZonesContext = createContext();

const CameraZonesProvider = ({ zonesCameraId, ...rest }) => {
  const { fetch: fetchCamera, data: camera, isSuccess: isCameraSuccess } = useCamera();
  const [isCameraFetching, setIsCameraFetching] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [hoverMenuZone, setHoverMenuZone] = useState({});
  const [isZonesSidebarExpanded, setIsZonesSidebarExpanded] = useState(true);

  const location = useLocation();
  const { selectSearchQuery, onAddToPlan, onRemoveFromPlan, inPlan } = generateQueryActions(
    location.pathname
  );

  const urlViewProps = getViewPropsFromURL(location.pathname);
  const zoneBeingEdited = useSelector(selectZoneBeingEdited);

  const searchQuery = selectSearchQuery ? useSelector(selectSearchQuery) : {};
  const editModeEnabled = useSelector(selectIsEditModeEnabled);

  const planningMode = inPlan && !urlViewProps.isLibraryOpen;
  const queryCameraZones = searchQuery.cameraZones;

  useEffect(() => {
    const fetchCameraAsync = async () => {
      setIsCameraFetching(true);
      await fetchCamera(zonesCameraId);
      setIsCameraFetching(false);
    };
    if (zonesCameraId != null) fetchCameraAsync();
  }, [fetchCamera, zonesCameraId]);

  const cameraZones = camera?.zones || [];
  let cameraZonesWithoutTempDrawings =
    zoneBeingEdited.id !== undefined
      ? cameraZones.filter(zone => zone.id !== zoneBeingEdited.id)
      : cameraZones;
  if (!editModeEnabled && planningMode && cameraZones?.length > 0 && queryCameraZones) {
    cameraZonesWithoutTempDrawings = cameraZonesWithoutTempDrawings.filter(
      zone => queryCameraZones[zone.id] === true
    );
  }
  const normalizedZones =
    camera?.zones?.length > 0 ? frontendZones(cameraZonesWithoutTempDrawings) : [];

  const updateConfirmModal = (update = false) => {
    setIsConfirmModalOpen(update);
  };

  const updateHoveringZoneMenuItem = (obj = {}) => {
    setHoverMenuZone(obj);
  };

  const updateIsSiderbarExpanded = () => {
    setIsZonesSidebarExpanded(!isZonesSidebarExpanded);
  };

  return (
    <CameraZonesContext.Provider
      value={{
        isConfirmModalOpen,
        camera,
        isCameraSuccess,
        normalizedZones,
        isZonesSidebarExpanded,
        isCameraFetching,
        planningMode,
        hoverMenuZone,
        cameraZones,
        urlViewProps,
        zonesCameraId,
        fetchCamera,
        updateConfirmModal,
        updateHoveringZoneMenuItem,
        updateIsSiderbarExpanded,
        onAddToPlan,
        onRemoveFromPlan,
      }}
      {...rest}
    />
  );
};

const useCameraZones = () => useContext(CameraZonesContext);

export { CameraZonesProvider, useCameraZones };
