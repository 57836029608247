import MuiChip from '@material-ui/core/Chip';
import { ThemeProvider } from '@material-ui/core/styles';

import { makeStyles } from 'tss-react-local';

import { getTheme } from './themes';

const useStyles = makeStyles()({
  disabled: {
    pointerEvents: 'none',
    opacity: 0.3,
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sizeSmall: {
    fontSize: 11,
  },
});

const Component = ({
  className,
  color,
  customClasses,
  disabled,
  label,
  theme,
  variant,
  ...rest
}) => {
  const { classes, cx } = useStyles({ color });

  return (
    <MuiChip
      color={color}
      variant={variant}
      className={cx(className, { [classes.disabled]: disabled })}
      classes={{
        ...customClasses,
        root: customClasses.root,
        sizeSmall: classes.sizeSmall,
        avatar: cx(classes.avatar, customClasses.avatar),
      }}
      label={<span className={cx(classes.label, customClasses.label)}>{label}</span>}
      {...rest}
    />
  );
};

const Chip = props => {
  const customTheme = getTheme(props.theme);
  if (customTheme) {
    return (
      <ThemeProvider theme={customTheme}>
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return <Component {...props} />;
};

Chip.defaultProps = {
  size: 'small',
  customClasses: {},
  color: 'primary',
};

export default Chip;
