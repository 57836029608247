export function getConfirmedPersonName(person) {
  return person?.name;
}

export function hasAllDataSourceProcessed(dataSources) {
  return dataSources.length === 0 || dataSources.every(source => source.trackletSummary);
}

export const searchlightEnabledForType = (ds, type) =>
  Boolean(
    ds?.trackletSummary?.objectSightings?.[type]?.recognitionEnable && ds?.searchlightEnabled
  );

export function getTrackletInFilter(tracklets, datasource, filteredDate) {
  const { startDate, endDate } = filteredDate;

  if (!startDate || !endDate) return true;

  const includedTracklets = {};

  tracklets.forEach(tracklet => {
    const dsStart = datasource ? new Date(datasource.date).getTime() : null;
    const trackletStartTime = datasource.camera
      ? Math.floor(tracklet?.sampleTimestamp / 1000)
      : Math.floor((dsStart + tracklet?.sampleTimestamp) / 1000);
    const trackletEndTime = datasource.camera
      ? Math.floor(tracklet?.endTimestamp / 1000)
      : Math.floor((dsStart + tracklet?.endTimestamp) / 1000);
    const startDateMinusMS = Math.floor(startDate / 1000);
    const endDateMinusMS = Math.floor(endDate / 1000);

    const hasDate = startDate && endDate;
    const trackletStartInFilterBounds =
      trackletStartTime >= startDateMinusMS && trackletStartTime <= endDateMinusMS;
    const filterStartInTrackletBounds =
      startDateMinusMS >= trackletStartTime && startDateMinusMS <= trackletEndTime;

    if (!(hasDate && !trackletStartInFilterBounds && !filterStartInTrackletBounds)) {
      includedTracklets[tracklet?.id] = true;
    }
  });

  return includedTracklets;
}

export function buildSiftTimelineBounds(startTime, endTime, duration) {
  if (startTime === undefined || startTime === null || !endTime || !duration) {
    return {
      start: 0,
      end: 0,
    };
  }

  const startRatio = startTime / duration;

  const endRatio = endTime / duration;

  return {
    start: startRatio < 0 ? 0 : startRatio,
    end: endRatio > 1 ? 1 : endRatio,
  };
}

export function isGroupedView(location) {
  return location.pathname.includes('/grouping/') && !location.pathname.includes('/all');
}

export function isAllGroupedView(location) {
  return location.pathname.includes('/grouping/') && location.pathname.includes('/all');
}

export function isRelatedView(location) {
  return location.pathname.includes('/related/');
}
