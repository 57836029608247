import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsProcOnline } from 'app/header/mirageHealth/redux/selectors';
import Button from 'common/components/base/Button';

const UploadCountHeader = ({ uploadCount, groupName, switchToGroupView }) => {
  const isProcOnline = useSelector(selectIsProcOnline);
  const processingText = isProcOnline
    ? `Processing ${uploadCount} Upload Group`
    : 'Paused - Data Processing Service Offline';

  return (
    <React.Fragment>
      {!groupName && <span>{processingText}</span>}
      {groupName && (
        <Button variant={null} onClick={switchToGroupView}>
          {processingText}
        </Button>
      )}
    </React.Fragment>
  );
};

export default UploadCountHeader;
