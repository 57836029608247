import React from 'react';
import classnames from 'classnames';
import MuiIconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';

import { withStyles } from 'tss-react-local';
import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';

import { getTheme } from './themes';
import { DISABLED_OPACITY } from './constants';

const styles = {
  disabled: {
    opacity: DISABLED_OPACITY,
  },
};

class IconButton extends React.PureComponent {
  static defaultProps = {
    color: 'primary',
    customClasses: {},
    loadingIconProps: {},
  };

  render() {
    const {
      classes,
      children,
      customClasses,
      isLoading,
      loadingIconProps,
      theme,
      color,
      ...rest
    } = this.props;
    const { disabled, ...restCustomClasses } = customClasses;

    const customTheme = getTheme(theme);
    const Component = (
      <MuiIconButton
        color={color}
        classes={{
          disabled: classnames(classes.disabled, customClasses.disabled),
          ...restCustomClasses,
        }}
        data-testid="IconButton"
        {...rest}
      >
        {isLoading && (
          <LoadingOverlay size={22} backgroundColor="transparent" {...loadingIconProps} />
        )}
        {children}
      </MuiIconButton>
    );

    if (customTheme) {
      return <ThemeProvider theme={customTheme}>{Component}</ThemeProvider>;
    }

    return Component;
  }
}

export default withStyles(IconButton, styles);
