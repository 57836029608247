import { batchActions } from 'redux-batched-actions';
import { isEmpty } from 'lodash';

import {
  getTrackletGrid,
  postSIFTAction,
  fetchConfirmedFaces,
  fetchGroupedTracklets,
  fetchSearchQueryDataSources,
} from 'common/api/siftApi';
import { getSearchRequest, postSearchRequest } from 'common/api/searchRequest';
import { postOrderedTracklets } from 'common/api/personApi';
import { renderAlert, renderErrorMessage } from 'app/redux/actions';
import { fetchPersons } from 'analysis/redux/actions';
import { addToPlan } from 'plan/redux/actions';
import * as SiftSelectors from 'analysis/siftView/redux/selectors';
import { IDENTITY } from 'common/constants/app';
import QUERY from 'common/constants/query';
import { SEARCH_TYPE_SEARCHLIGHT } from 'analysis/constants';
import { DEFAULT_CONFIDENCE_INTERVAL, OBJECT_TYPES } from 'analysis/siftView/constants';
import { extractErrorMessage } from 'common/helpers/apiErrorUtils';
import * as SiftUtils from '../utils';

export const setTrackletData = payload => ({
  type: 'SIFT_VIEW/SET_TRACKLET_DATA',
  payload,
});

export const resetTrackletData = () => ({
  type: 'SIFT_VIEW/RESET_TRACKLET_DATA',
});

export const addTracklets = payload => ({
  type: 'SIFT_VIEW/ADD_TRACKLETS',
  payload,
});

export const updateTracklets = tracklets => ({
  type: 'SIFT_VIEW/UPDATE_TRACKLETS',
  payload: tracklets,
});

export function setIsRelatedSightingsView(isRelatedSightingsView) {
  return {
    type: 'SIFT_VIEW/SET_IS_RELATED_SIGHTINGS_VIEW',
    payload: isRelatedSightingsView,
  };
}

export function setIsGroupedSightingsView(isGroupedSightingsView) {
  return {
    type: 'SIFT_VIEW/SET_IS_GROUPED_SIGHTINGS_VIEW',
    payload: isGroupedSightingsView,
  };
}

export function setIsGroupedSightingsLoadAllView(isGroupedSightingsLoadAll) {
  return {
    type: 'SIFT_VIEW/SET_IS_GROUPED_SIGHTINGS_LOAD_ALL_VIEW',
    payload: isGroupedSightingsLoadAll,
  };
}

export const setOrderedTracklets = orderedTracklets => ({
  type: 'SIFT_VIEW/SET_ORDERED_TRACKLETS',
  payload: orderedTracklets,
});

export function setMatchConfidence(matchConfidence) {
  return {
    type: 'SIFT_VIEW/SET_MATCH_CONFIDENCE',
    payload: matchConfidence,
  };
}

export function setGroupedSightingsMatchConfidence(groupedMatchConfidence) {
  return {
    type: 'SIFT_VIEW/SET_GROUPED_SIGHTINGS_MATCH_CONFIDENCE',
    payload: groupedMatchConfidence,
  };
}

export function setIsSortedByTime(isSortedByTime) {
  return {
    type: 'SIFT_VIEW/SET_IS_SORTED_BY_TIME',
    payload: isSortedByTime,
  };
}

export function setSelectedConfirmedPerson(selectedConfirmedPerson) {
  return {
    type: 'SIFT_VIEW/SET_SELECTED_CONFIRMED_PERSON',
    payload: selectedConfirmedPerson,
  };
}

export function setSelectedHoveredPerson(selectedHoveredPerson) {
  return {
    type: 'SIFT_VIEW/SET_SELECTED_HOVERED_PERSON',
    payload: selectedHoveredPerson,
  };
}

export function setHoveredTrackletId(hoveredTrackletId) {
  return {
    type: 'SIFT_VIEW/SET_SELECTED_HOVERED_TRACKLET_ID',
    payload: hoveredTrackletId,
  };
}

export function setSelectedSiftDataSources(selectedSiftDataSources) {
  return {
    type: 'SIFT_VIEW/SET_SELECTED_DATASOURCES',
    payload: selectedSiftDataSources,
  };
}

export function setSelectedSiftDataSource(selectedSiftDataSource) {
  return {
    type: 'SIFT_VIEW/SET_SELECTED_DATASOURCE',
    payload: selectedSiftDataSource,
  };
}

export function setSelectedTracklet(selectedTracklet) {
  return {
    type: 'SIFT_VIEW/SET_SELECTED_TRACKLET',
    payload: selectedTracklet,
  };
}

export function setClearTracklets(shouldClearTracklets) {
  return {
    type: 'SIFT_VIEW/CLEAR_TRACKLET',
    payload: shouldClearTracklets,
  };
}

export function setRightPanelOptions(rightPanelOption) {
  return {
    type: 'SIFT_VIEW/RIGHT_PANEL_OPTION',
    payload: rightPanelOption,
  };
}

export function setSelectedTracklets(...selectedTracklets) {
  return {
    type: 'SIFT_VIEW/ADD_SELECTED_TRACKLETS',
    payload: selectedTracklets,
  };
}

export function setGroupedTracklets(...groupedTracklets) {
  return {
    type: 'SIFT_VIEW/ADD_GROUPED_TRACKLETS',
    payload: groupedTracklets,
  };
}

export function removeSelectedTracklet(tracklet) {
  return {
    type: 'SIFT_VIEW/REMOVE_SELECTED_TRACKLET',
    payload: tracklet,
  };
}

export function removeGroupedTracklet(tracklet) {
  return {
    type: 'SIFT_VIEW/REMOVE_GROUPED_TRACKLET',
    payload: tracklet,
  };
}

export function removeOrderedTracklet(tracklet) {
  return {
    type: 'SIFT_VIEW/REMOVE_ORDERED_TRACKLET',
    payload: tracklet,
  };
}

export function removeRejectedTracklets(tracklets) {
  return {
    type: 'SIFT_VIEW/REMOVE_REJECTED_TRACKLETS',
    payload: tracklets,
  };
}

export function clearSelectedTracklets() {
  return {
    type: 'SIFT_VIEW/CLEAR_SELECTED_TRACKLETS',
    payload: [],
  };
}

export function setConfirmedFaces(confirmedFaces) {
  return {
    type: 'SIFT_VIEW/CONFIRMED_FACES',
    payload: confirmedFaces,
  };
}

export function setSeekTime(seekTime) {
  return {
    type: 'SIFT_VIEW/SEEK_TIME',
    payload: seekTime,
  };
}

export function setInitialState() {
  return {
    type: 'SIFT_VIEW/RESET_SIFT',
  };
}

function setLoadingConfirmedFaces(loadingConfirmedFaces) {
  return {
    type: 'SIFT_VIEW/LOADING_CONFIRMED_FACES',
    payload: loadingConfirmedFaces,
  };
}

export function setLoadingAllSightings(loadingAllSightings) {
  return {
    type: 'SIFT_VIEW/LOADING_ALL_SIGHTINGS',
    payload: loadingAllSightings,
  };
}

function setLoadingRelatedSightings(loadingRelatedSightings) {
  return {
    type: 'SIFT_VIEW/LOADING_RELATED_SIGHTINGS',
    payload: loadingRelatedSightings,
  };
}

export function setRefreshDetections(shouldRefreshFrameDetections) {
  return {
    type: 'SIFT_VIEW/SET_REFRESH_FRAME_DETECTIONS',
    payload: shouldRefreshFrameDetections,
  };
}

export function setLoadingDataSources(loadingDataSources) {
  return {
    type: 'SIFT_VIEW/SET_LOADING_DATASOURCES',
    payload: loadingDataSources,
  };
}

export function setFilterDates({ startDate, endDate }) {
  return {
    type: 'SIFT_VIEW/SET_FILTER_DATES',
    payload: { startDate, endDate },
  };
}

export function setTimelineBounds({ start, end }) {
  return {
    type: 'SIFT_VIEW/SET_TIMELINE_BOUNDS',
    payload: { start, end },
  };
}

export function setVideoDuration(videoDuration) {
  return {
    type: 'SIFT_VIEW/SET_VIDEO_DURATION',
    payload: videoDuration,
  };
}

export function setUnconfirmedChecked(unconfirmedChecked) {
  return {
    type: 'SIFT_VIEW/SET_UNCONFIRMED_CHECKED',
    payload: unconfirmedChecked,
  };
}

export function setConfirmedChecked(confirmedChecked) {
  return {
    type: 'SIFT_VIEW/SET_CONFIRMED_CHECKED',
    payload: confirmedChecked,
  };
}

export function setGroupedIdentityCreated(groupedIdentityCreated) {
  return {
    type: 'SIFT_VIEW/SET_GROUPED_IDENTITY_CREATED',
    payload: groupedIdentityCreated,
  };
}

export function setExitRelatedSightings() {
  return {
    type: 'SIFT_VIEW/SET_EXIT_RELATED_SIGHTINGS',
  };
}

export function setDataSourceDoesNotExist(dataSourceDoesNotExist) {
  return {
    type: 'SIFT_VIEW/SET_DATA_SOURCE_DOES_NOT_EXIST',
    payload: dataSourceDoesNotExist,
  };
}

export function setSearchRequest(searchRequest) {
  return { type: 'SIFT_VIEW/SET_SEARCH_REQUEST', payload: { searchRequest } };
}

/* ====================================== THUNK ACTIONS ========================================= */
export function fetchTrackletGrid({
  id,
  requestId,
  offset,
  startEpochMs,
  scrollOrigination,
  objectType,
}) {
  return function fetchTrackletGridThunk(dispatch, getState) {
    const params = {
      cols: 5,
      rows: 20,
      confidence: 0.8,
      objectType: objectType || 0,
    };
    if (startEpochMs != null) params.startTimestamp = startEpochMs;
    if (offset != null) params.offset = offset;

    if (!scrollOrigination) {
      dispatch(setLoadingAllSightings(true));
    }

    return getTrackletGrid(requestId, id, params)
      .then(data => {
        const state = getState();
        const currentTracklets = SiftSelectors.selectTracklets(state);

        const actions = [
          setTrackletData({
            ...data,
            tracklets: currentTracklets.concat(data.tracklets),
          }),
          setLoadingAllSightings(false),
          setDataSourceDoesNotExist(false),
        ];

        dispatch(batchActions(actions));

        return data;
      })
      .catch(e => {
        dispatch(setLoadingAllSightings(false));
        dispatch(setDataSourceDoesNotExist(true));
        renderErrorMessage(extractErrorMessage(e));
      });
  };
}

export function doSIFTAction(requestId, body) {
  return function doSIFTActionThunk(dispatch) {
    return postSIFTAction(requestId, body)
      .then(data => {
        const tracklets = Object.values(data?.datasourceIds)?.[0]?.tracklets || [];
        const actions = [updateTracklets(tracklets), setRefreshDetections(true)];
        dispatch(batchActions(actions));
        return data;
      })
      .catch(e => {
        renderErrorMessage(extractErrorMessage(e));
      });
  };
}

export function clearCluster(isRelatedSightingsView, selectedDataSource, objectType) {
  return function clearClusterThunk(dispatch) {
    const actions = [setRefreshDetections(false), clearSelectedTracklets([])];
    dispatch(batchActions(actions));
    if (!isRelatedSightingsView) {
      dispatch(setSelectedConfirmedPerson(null));
    }
    dispatch(getConfirmedFaces({ id: selectedDataSource.id, objectType }));
  };
}

export function createOrderedTracklets(body) {
  return function createOrderedTrackletsThunk(dispatch) {
    dispatch(setLoadingRelatedSightings(true));

    return postOrderedTracklets(body)
      .then(data => {
        const actions = [setOrderedTracklets(data), setLoadingRelatedSightings(false)];
        dispatch(batchActions(actions));
        return data;
      })
      .catch(e => {
        dispatch(setLoadingRelatedSightings(false));
        const msg = extractErrorMessage(e);
        renderErrorMessage(
          msg.includes('An invalid Person identifier was provided')
            ? 'That identity has been removed from the library.'
            : msg
        );
      });
  };
}

export function createGroupedTracklets(body) {
  return function createGroupedTrackletsThunk(dispatch) {
    dispatch(setLoadingRelatedSightings(true));

    return fetchGroupedTracklets(body)
      .then(data => {
        const actions = [setOrderedTracklets(data), setLoadingRelatedSightings(false)];
        dispatch(batchActions(actions));
        return data;
      })
      .catch(e => {
        dispatch(setLoadingRelatedSightings(false));
        renderErrorMessage(extractErrorMessage(e));
      });
  };
}

export function getConfirmedFaces({ id, objectType = 0 }) {
  return function getConfirmedFacesThunk(dispatch) {
    dispatch(setLoadingConfirmedFaces(true));

    return fetchConfirmedFaces(id, { objectType })
      .then(data => {
        const actions = [
          setConfirmedFaces(data),
          setLoadingConfirmedFaces(false),
          setRefreshDetections(false),
        ];
        dispatch(batchActions(actions));
        return data;
      })
      .catch(e => {
        dispatch(setLoadingConfirmedFaces(false));
        renderErrorMessage(extractErrorMessage(e));
      });
  };
}

export function updateOrderedTrackletsRelationship({ id, reject }) {
  return function updateOrderedTrackletsRelationshipThunk(dispatch, getState) {
    const state = getState();
    const prevOrderedTracklets = SiftSelectors.selectOrderedTracklets(state);
    const relationship = reject ? 'SEARCHED' : 'CONFIRMED';
    const updatedTracklet = prevOrderedTracklets.map(tracklet => {
      if (tracklet.detectionId === id) {
        tracklet.relationship = relationship;
      }
      return tracklet;
    });

    dispatch(setOrderedTracklets(updatedTracklet));
  };
}

export function viewRelatedSighting(person, selectedDataSource) {
  return function viewRelatedSightingThunk(dispatch) {
    dispatch(
      createOrderedTracklets({
        datasourceId: selectedDataSource.id,
        personId: person.id,
      })
    );
    dispatch(setSelectedConfirmedPerson(person));
    dispatch(
      batchActions([
        setIsGroupedSightingsView(false),
        setIsGroupedSightingsLoadAllView(false),
        setMatchConfidence(DEFAULT_CONFIDENCE_INTERVAL),
        setIsRelatedSightingsView(true),
      ])
    );
  };
}

export function addRelatedSightingsToPerson(person) {
  return function addRelatedSightingsToPersonThunk(dispatch) {
    return dispatch(addToPlan({ selectedIds: { [IDENTITY]: [person.id] } }));
  };
}

export function clearSelectedSightings(isRelatedSightingsView, selectedDataSource, objectType) {
  return function clearSelectedSightingsThunk(dispatch) {
    dispatch(clearSelectedTracklets([]));
    if (!isRelatedSightingsView) {
      dispatch(setSelectedConfirmedPerson(null));
    }
    dispatch(getConfirmedFaces({ id: selectedDataSource.id, objectType }));
  };
}

export function setNewDataSource(dataSourceId, selectedDataSource) {
  return batchActions([
    resetTrackletData(),
    setSelectedTracklet(null),
    setSelectedSiftDataSource(selectedDataSource),
    setLoadingAllSightings(false),
  ]);
}

export function updateAllOrderedTracklets(unconfirmedSelected) {
  return function updateAllOrderedTrackletsThunk(dispatch) {
    unconfirmedSelected.forEach(trackletId =>
      dispatch(updateOrderedTrackletsRelationship({ id: trackletId, reject: false }))
    );
  };
}

export const onTrackletSelected = ({ detectionId }) => (dispatch, getState) => {
  const state = getState();
  const isSelected = SiftSelectors.selectCanTrackletBeSelected(state, detectionId);
  // Tracklet already exists, user is un-selecting the tracklet,
  if (isSelected) {
    dispatch(removeSelectedTracklet(detectionId));
    return;
  }

  dispatch(setSelectedTracklets(detectionId));
};

export function rejectAndClearSelection({ personId, detectionIds, objectType, requestId }) {
  return async function rejectAndClearSelectionThunk(dispatch, getState) {
    const selectedDataSource = SiftSelectors.selectSelectedSiftDataSource(getState());
    const clearSelections = () => dispatch(clearCluster(true, selectedDataSource, objectType));

    await dispatch(
      doSIFTAction(requestId, {
        action: 'REJECT',
        datasourceIds: { [selectedDataSource.id]: detectionIds },
        personId,
        objectType,
      })
    ).then(clearSelections);
  };
}

export function rejectSighting({ personId, detectionId, objectType, requestId }) {
  return async function rejectSightingThunk(dispatch) {
    dispatch(
      rejectAndClearSelection({ personId, detectionIds: [detectionId], objectType, requestId })
    );
    dispatch(updateOrderedTrackletsRelationship({ id: detectionId, reject: true }));
    return dispatch(fetchPersons());
  };
}

export function rejectSightings({ personId, detectionIds, objectType, requestId }) {
  return async function rejectSightingThunk(dispatch) {
    dispatch(rejectAndClearSelection({ personId, detectionIds, objectType, requestId }));
    return dispatch(
      removeRejectedTracklets({
        tracklets: detectionIds,
      })
    );
  };
}

export function updateTrackletsOnConfidenceChange({ newValue }) {
  return async function updateTrackletsOnConfidenceChangeThunk(dispatch, getState) {
    const state = getState();
    const selectedDataSource = SiftSelectors.selectSelectedSiftDataSource(state);
    const selectedConfirmedPerson = SiftSelectors.selectSelectedConfirmedPerson(state);
    await dispatch(
      createOrderedTracklets({
        datasourceId: selectedDataSource.id,
        personId: selectedConfirmedPerson.id,
      })
    );
    dispatch(setMatchConfidence(newValue));
  };
}

export const resetGroupedTracklets = trackletIds => dispatch => {
  const actions = [];
  trackletIds.forEach(id => actions.push(removeGroupedTracklet(id)));
  return dispatch(batchActions(actions));
};

export const resetOrderedTracklets = trackletIds => dispatch => {
  const actions = [];
  trackletIds.forEach(id => actions.push(removeOrderedTracklet(id)));
  return dispatch(batchActions(actions));
};

export const resetChosenTracklets = () => (dispatch, getState) => {
  const state = getState();
  const actions = [];
  const chosenTracklets = SiftSelectors.selectChosenTracklets(state);
  const groupedTracklets = SiftSelectors.selectGroupedTracklets(state);
  Object.keys(chosenTracklets).forEach(id => actions.push(removeSelectedTracklet(id)));
  Object.keys(groupedTracklets).forEach(id => actions.push(removeGroupedTracklet(id)));
  return dispatch(batchActions(actions));
};

export function createPersons({ person, objectType, location, requestId }) {
  return async function createPersonsThunk(dispatch, getState) {
    const state = getState();
    const selectedTrackletsCount = SiftSelectors.selectSelectedTrackletCount(state);
    const unconfirmedOrderedTracklets = SiftSelectors.selectUnconfirmedOrderedTrackletsSelected(
      state
    );
    const selectedDataSource = SiftSelectors.selectSelectedSiftDataSource(state);
    const isRelatedSightingsView = SiftUtils.isRelatedView(location);
    const isGroupedSightingsView = SiftUtils.isGroupedView(location);
    const isGroupedSightingsLoadAll = SiftUtils.isAllGroupedView(location);
    const selectedTrackletIds = SiftSelectors.selectSelectedTrackletIds(state);

    dispatch(setLoadingConfirmedFaces(true));

    if (selectedTrackletsCount === 0) {
      return;
    }

    const tracketIdsForPerson = isRelatedSightingsView
      ? unconfirmedOrderedTracklets
      : selectedTrackletIds;

    await dispatch(
      doSIFTAction(requestId, {
        action: 'CONFIRM',
        datasourceIds: { [selectedDataSource.id]: tracketIdsForPerson },
        objectType,
        personId: person?.id,
      })
    );

    dispatch(clearSelectedSightings(isRelatedSightingsView, selectedDataSource, objectType));

    if (isRelatedSightingsView) {
      dispatch(updateAllOrderedTracklets(unconfirmedOrderedTracklets));
    }
    if (isGroupedSightingsView) {
      dispatch(resetChosenTracklets());
      dispatch(resetGroupedTracklets(tracketIdsForPerson));
    }

    if (isGroupedSightingsLoadAll) {
      dispatch(resetOrderedTracklets(tracketIdsForPerson));
    }

    return dispatch(fetchPersons())
      .catch(e => renderAlert(e.message))
      .finally(() => {
        dispatch(setLoadingConfirmedFaces(false));
      });
  };
}

export const viewGroupedSightings = ({ tracklet }) => (dispatch, getState) => {
  const state = getState();
  const selectedPersonTracklets = SiftSelectors.selectPersonTracklets(state, tracklet.id);
  dispatch(resetChosenTracklets());

  const actions = [
    setIsGroupedSightingsView(true),
    setIsRelatedSightingsView(false),
    setSelectedTracklets(...selectedPersonTracklets),
    setGroupedTracklets(...selectedPersonTracklets),
  ];
  return dispatch(batchActions(actions));
};

export const viewAllGroupedSightings = (trackletId, dataSourceId, objectType) =>
  async function viewAllGroupedSightingsThunk(dispatch, getState) {
    const state = getState();
    const selectedPersonTracklets = SiftSelectors.selectPersonTracklets(state, trackletId);
    dispatch(resetChosenTracklets());
    await dispatch(
      createGroupedTracklets({
        datasourceId: dataSourceId,
        trackletId,
        objectType,
      })
    );

    const actions = [
      setIsGroupedSightingsView(true),
      setIsGroupedSightingsLoadAllView(true),
      setSelectedTracklets(...selectedPersonTracklets),
      setGroupedTracklets(...selectedPersonTracklets),
    ];
    return dispatch(batchActions(actions));
  };

export const createNewPerson = (objectType, location, requestId) =>
  async function createPersonsThunk(dispatch, getState) {
    const state = getState();
    dispatch(setLoadingConfirmedFaces(true));
    const selectedTrackletsCount = SiftSelectors.selectSelectedTrackletCount(state);
    const selectedDataSource = SiftSelectors.selectSelectedSiftDataSource(state);
    const selectedTrackletIds = SiftSelectors.selectSelectedTrackletIds(state);
    const isRelatedSightingsView = SiftUtils.isRelatedView(location);
    const isGroupedSightingsView = SiftUtils.isGroupedView(location);
    const isGroupedSightingsLoadAll = SiftUtils.isAllGroupedView(location);

    if (selectedTrackletsCount === 0) {
      return;
    }

    await dispatch(
      doSIFTAction(requestId, {
        action: 'CONFIRM',
        datasourceIds: { [selectedDataSource.id]: selectedTrackletIds },
        objectType,
      })
    );
    dispatch(clearSelectedTracklets([]));
    if (isRelatedSightingsView) {
      dispatch(setSelectedConfirmedPerson(null));
    }
    if (isGroupedSightingsView) {
      dispatch(resetGroupedTracklets(selectedTrackletIds));
      dispatch(resetOrderedTracklets(selectedTrackletIds));
    }

    if (isGroupedSightingsLoadAll) {
      dispatch(resetChosenTracklets());
      dispatch(resetOrderedTracklets(selectedTrackletIds));
    }

    dispatch(setGroupedIdentityCreated(true));

    return dispatch(fetchPersons())
      .catch(e => renderAlert(e.message))
      .finally(() => {
        dispatch(clearCluster(isRelatedSightingsView, selectedDataSource, objectType));
      });
  };

export const clearSelections = location => dispatch => {
  const isRelatedSightingsView = SiftUtils.isRelatedView(location);
  dispatch(clearSelectedTracklets([]));
  if (isRelatedSightingsView) {
    return dispatch(setSelectedConfirmedPerson(null));
  }
};

export function updateSeekTimeOnFilter() {
  return async function updateSeekTimeOnFilterThunk(dispatch, getState) {
    const state = getState();
    const seekStartTime = SiftSelectors.selectSeekStartTimeOnFilter(state);

    const seekTime = seekStartTime || 0;

    dispatch(setSeekTime(seekTime));

    return seekStartTime;
  };
}

export function resetTrackletWithNewData(objectType, requestId) {
  return async function resetTrackletWithNewDataThunk(dispatch, getState) {
    const state = getState();
    const seekStartTime = SiftSelectors.selectSeekStartTimeOnFilter(state);
    const datasource = SiftSelectors.selectSelectedSiftDataSource(state);

    dispatch(setLoadingAllSightings(true));

    const actions = [resetTrackletData(), setSelectedTracklet(null), clearSelectedTracklets()];
    dispatch(batchActions(actions));

    const startEpochMs = Math.round((datasource?.startEpochMs ?? 0) + seekStartTime * 1000);

    await dispatch(
      fetchTrackletGrid({
        id: datasource.id,
        requestId,
        startEpochMs,
        scrollOrigination: true,
        objectType,
      })
    );
  };
}

export function updateTimelineBounds() {
  return function updateTimelineBoundsThunk(dispatch, getState) {
    const state = getState();
    const { start, end } = SiftSelectors.selectTimelineBoundsOnFilter(state);

    dispatch(setTimelineBounds({ start, end }));
  };
}

export function handleDateChange({ startDate, endDate, objectType, requestId }) {
  return async function handleDateChangeThunk(dispatch) {
    dispatch(setLoadingAllSightings(true));
    dispatch(
      setFilterDates({
        startDate,
        endDate,
      })
    );
    dispatch(updateTimelineAndSeekTime());
    return dispatch(resetTrackletWithNewData(objectType, requestId));
  };
}

export function updateTimelineAndSeekTime() {
  return function updateTimelineAndSeekTimeThunk(dispatch) {
    dispatch(updateTimelineBounds());

    dispatch(updateSeekTimeOnFilter());
  };
}

export function onVideoPreviewTimeChange(seconds, objectType, requestId, location) {
  return function onVideoPreviewTimeChangeThunk(dispatch, getState) {
    const state = getState();
    const datasource = SiftSelectors.selectSelectedSiftDataSource(state);
    const isGroupedSightingsView = SiftUtils.isGroupedView(location);
    const isRelatedSightingsView = SiftUtils.isRelatedView(location);
    const timestamp = SiftSelectors.makeSelectTimeStamp(state, seconds);

    // Do not update the sightings grid for grouped and related sighting view
    if (isGroupedSightingsView || isRelatedSightingsView) {
      return;
    }
    dispatch(setLoadingAllSightings(true));
    const actions = [
      resetTrackletData(),
      setSelectedTracklet(null),
      clearSelectedTracklets(),
      setSeekTime(seconds),
    ];
    dispatch(batchActions(actions));

    dispatch(
      fetchTrackletGrid({
        id: datasource.id,
        requestId,
        startEpochMs: timestamp,
        scrollOrigination: true,
        objectType,
      })
    );
  };
}

export function initMiddlePanel(datasourceID, requestId, objectType) {
  return async function initMiddlePanelThunk(dispatch, getState) {
    dispatch(resetTrackletData());

    const state = getState();
    const datasource = SiftSelectors.selectSelectedSiftDataSource(state);
    const timestamp = SiftSelectors.selectSeekStartTimeOnFilter(state);

    dispatch(fetchSearchLightDataSources(requestId));

    if (datasource !== null) {
      await dispatch(
        fetchTrackletGrid({
          id: datasourceID,
          requestId,
          startEpochMs: timestamp * 1000,
          objectType,
        })
      );
      dispatch(getConfirmedFaces({ id: datasourceID, objectType }));
      dispatch(updateTimelineAndSeekTime());
    }
  };
}

export function refreshSiftView({ id, objectType }) {
  return async function refreshSiftViewThunk(dispatch, getState) {
    const state = getState();
    const selectedDataSource = SiftSelectors.selectSelectedSiftDataSource(state);

    dispatch(getConfirmedFaces({ id: id || selectedDataSource.id, objectType }));
    dispatch(onVideoPreviewTimeChange(0, objectType));
  };
}

export function fetchQueryObjects(requestId) {
  return async function fetchQueryObjectsThunk(dispatch) {
    dispatch(setFilterDates({ startDate: null, endDate: null }));
    const searchDetails = await getSearchRequest(requestId);
    dispatch(setSearchRequest(searchDetails));
    if (searchDetails?.filterArgs?.st && searchDetails?.filterArgs?.et) {
      const { st, et } = searchDetails.filterArgs;
      dispatch(setFilterDates({ startDate: Number(st), endDate: Number(et) }));
    }
  };
}

export function fetchSearchLightDataSources(requestId) {
  return async function fetchSearchLightDataSourcesThunk(dispatch, getState) {
    return fetchSearchQueryDataSources(requestId).then(dataSources => {
      const state = getState();
      const actions = [setSelectedSiftDataSources(dataSources)];

      //  Only use the selected datasource if it exists in those that are currently available
      const selectedDataSource = SiftSelectors.selectSelectedSiftDataSource(state);
      if (isEmpty(selectedDataSource) || !dataSources.find(ds => ds.id === selectedDataSource.id)) {
        actions.push(setSelectedSiftDataSource(dataSources[0]));
      }
      return dispatch(batchActions(actions));
    });
  };
}

export function submitSearchlightQuery(video) {
  return function submitSearchlightQueryThunk(dispatch) {
    const queries = [];
    if (SiftUtils.searchlightEnabledForType(video, OBJECT_TYPES.PEOPLE)) {
      queries.push(QUERY.FACE);
    }
    if (SiftUtils.searchlightEnabledForType(video, OBJECT_TYPES.VEHICLES)) {
      queries.push(QUERY.CAR);
    }

    if (queries.length) {
      const reqParams = {
        t: 5,
        q: queries.join('|'),
        searchType: SEARCH_TYPE_SEARCHLIGHT,
        v: video.id,
      };

      return postSearchRequest(reqParams).then(response => {
        dispatch(setInitialState());
        dispatch(setSelectedSiftDataSources([video]));

        return response;
      });
    }
  };
}
