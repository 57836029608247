import { jss } from 'react-jss';

let counter = 0;

export function createGenerateClassName() {
  return rule => `${rule.key}-${counter++}`;
}

/*
  MUI styles will by default override styles using injectSheet, in order to make injectSheet
  work over MUI styles, we need to set the injection point after the MUI styles
*/
jss.options.insertionPoint = document.getElementById('styles');

export default jss;
