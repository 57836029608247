import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useInterval from 'common/hooks/useInterval';
import { fetchProcessingData, reprioritizeProcessingJob } from 'library/redux/actions';
import ProcessingPanelDialog from 'library/common/ProcessingPanelDialog';
import { RE_PRIORITY } from 'common/constants/app';
import { JOB_GRP_LEVEL } from 'library/common/globalProcessingPanel/constants';
import { getJobGroupInfoOptions, getReprioritizationOptions } from 'library/redux/utils';
import Menu from 'common/components/base/Menu';
import Icon from 'common/components/base/Icon';
import { showCancelJobGroup } from 'library/common/globalProcessingPanel/redux/actions';
import { selectGlobalProcessingPanelOpen } from 'library/common/globalProcessingPanel/redux/selectors';

import { selectGlobalJobs } from 'app/redux/selectors';
import CancelJobGroup from 'common/components/popups/dialogs/CancelJobGroup';

const JobProcessingDialog = () => {
  const isGlobalProcessingPanelOpen = useSelector(selectGlobalProcessingPanelOpen);
  const dispatch = useDispatch();
  const jobs = useSelector(selectGlobalJobs);

  const [options, setOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);

  const getProcessingJobs = () => dispatch(fetchProcessingData());
  useInterval(() => {
    if (!isLoadingJobs) {
      setIsLoadingJobs(true);
      getProcessingJobs().finally(() => setIsLoadingJobs(false));
    }
  }, 5000);

  const closeMenu = () => setAnchorEl(null);

  const setData = ({ dataSource: nextDS }) => {
    dispatch(showCancelJobGroup(nextDS));
  };

  const onReprioritize = data => dispatch(reprioritizeProcessingJob(data));

  const getIcon = name => <Icon iconName={name} theme="grey" />;

  const getJobGroupOptions = jobGroup => {
    const jobGroupOptions = getJobGroupInfoOptions(jobGroup, onReprioritize, setData);

    return jobGroupOptions.map(group => {
      group.icon = getIcon(group.icon);
      return group;
    });
  };

  const getReprioritizeOptions = jobInfo => {
    const jobOptions = getReprioritizationOptions(jobInfo, jobs.uploadGroups, onReprioritize);

    return jobOptions.map(job => {
      job.icon = getIcon(job.icon);
      return job;
    });
  };

  const _defaultMenuOptions = {
    [RE_PRIORITY]: getReprioritizeOptions,
    [JOB_GRP_LEVEL]: getJobGroupOptions,
  };

  const openMenu = (e, item) => {
    const { itemType, id } = item;
    e.stopPropagation();

    let dataObj = item;

    switch (item.itemType) {
      case RE_PRIORITY: {
        dataObj = jobs.inProgressJobs.find(job => job.id === id);
        break;
      }
      default: {
        break;
      }
    }
    setAnchorEl(e.currentTarget);
    setOptions(_defaultMenuOptions[itemType](dataObj));
  };

  if (!isGlobalProcessingPanelOpen) {
    return null;
  }

  return (
    <React.Fragment>
      <Menu open={Boolean(anchorEl)} options={options} anchorEl={anchorEl} onClose={closeMenu} />
      <CancelJobGroup />
      <ProcessingPanelDialog openMenu={openMenu} closeMenu={closeMenu} />
    </React.Fragment>
  );
};

export default JobProcessingDialog;
