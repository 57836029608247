import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { removeNotification } from 'app/redux/actions';

import { ERROR, NOTIFICATION, LIBRARY } from 'app/redux/constants';
import { selectNotifications } from 'app/redux/selectors';
import MirageError from './MirageError';
import MirageNotification from './MirageNotification';
import ViewPlan from './ViewPlan';

const BOTTOM_MARGIN = 20;

const NOTIFICATIONS = {
  [ERROR]: MirageError,
  [NOTIFICATION]: MirageNotification,
  [LIBRARY]: ViewPlan,
};

const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const containerRef = useRef(null);
  const notifications = useSelector(selectNotifications);

  const footerPages = ['/plan', '/plan-monitor', '/reports'];
  const hasFooter = footerPages.some(url => location.pathname.includes(url));

  const onClose = notification => dispatch(removeNotification(notification));

  const calculateHeight = index => {
    let height = 0;
    for (let i = 0; i < index; i++) {
      height += (containerRef.current?.children[i]?.clientHeight ?? 0) + 15;
    }
    return height;
  };

  const renderNotification = (notification, index) => {
    const { id, type, message } = notification;
    const adjustMargin = hasFooter ? 64 : 0;
    const style = { bottom: BOTTOM_MARGIN + calculateHeight(index) + adjustMargin };
    const Notification = NOTIFICATIONS[type];

    return (
      <Notification
        data={notification}
        history={history}
        key={id || message}
        onClose={onClose}
        open
        style={style}
      />
    );
  };

  return <div ref={containerRef}>{notifications.map(renderNotification)}</div>;
};

export default Notifications;
