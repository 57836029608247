import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectGoogleAPIKey } from 'settings/redux/selectors';
import { selectIsAuthenticated } from 'auth/redux/selectors';

const GoogleMapsApi = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const googleAPIKey = useSelector(selectGoogleAPIKey);

  useEffect(() => {
    if (isAuthenticated && googleAPIKey && navigator.onLine) {
      // set window.google
      const script = document.createElement('script');
      script.setAttribute('defer', 'defer');
      script.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?v=3.29&libraries=places,drawing&key=${googleAPIKey}`
      );
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [isAuthenticated, googleAPIKey]);

  return null;
};

export default GoogleMapsApi;
