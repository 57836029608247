/* Popup constants */
export const WEBCAM = 3;

/* display constants */
export const GRID = 'grid';
export const LIST = 'list';
export const MAP = 'map';
export const VIEW_IN_MAP = 'viewInMap';
export const COLUMNAR = 'columnar';

/* Sidebar constants */
export const SIDEBAR_WIDTH = 50;
export const SIDEBAR_EXPANDED_WIDTH = 200;

export const HEADER_HEIGHT = 50;
export const ZONES_EDIT_MODE_BANNER_HEIGHT = 40;

/* app constants */
export const LIBRARY = 'library';
export const CAMERA = 'camera';
export const CAMERA_ZONE = 'cameraZone';
export const RECORDING = 'cameraRecording';
export const VIDEO = 'video';
export const IMAGE = 'image';
export const REPORT = 'report';
export const SCENE = 'scene';
export const DATASOURCE = 'datasource';
export const PERSON = 'person';
export const NAMED = 'named';
export const PROVISIONAL = 'provisional';
export const DOCUMENT = 'document';
export const USER = 'user';
export const MACULA = 'macula';
export const OBJECT = 'detectionobject';
export const RE_PRIORITY = 'priority';
export const ACTIVITY = 'activity';
export const FOLDER = 'folder';
export const FOLDER_V2 = 'folder_v2';
export const FOLDER_ITEM = 'folderitem';
export const IDENTITY = 'identity';
export const IMSI = 'imsi';
export const LIVE_QUERY = 'liveQuery';
export const LIVE_QUERY_GROUP = 'liveQueryGroup';
export const GEOFENCE = 'geofence';
export const GEOIMAGE = 'geoimage';
export const GEODATASET = 'geodataset';
export const DATASETS = 'datasets';
export const GEO_SEARCH_REQUEST = 'geoSearchRequest';
export const GEO_REPORT = 'geoReport';
export const GEO_SCENE = 'geoScene';
export const GEO_SECTION = 'geoSection';
export const GEO_CLASSIFIER = 'geoClassifier';
export const GEO_DETECTOR = 'geoDetector';
export const ADHOC_S3_FILE = 'adhocS3File';
export const GEOSPATIAL = 'geospatial';
export const VENUE_MAP = 'venueMap';
export const MISSION = 'mission';
export const GEO_ALERTING = 'geoAlerting';
export const ALERT_QUERY = 'alertQuery';
export const DATA_ROOT = 'dataRoot';
export const DATA_ROOT_SNAKE = 'data_root';

export const UNKNOWN = 'Unknown';
export const UNKNOWN_PERSON = 'Unknown Person';

/* module constants */
export const FMV = 'fmv';
export const ISR = 'isr';
export const GEO = 'geo';

export const ROOT_DATASOURCE_KEY = 1;
export const ROOT_PERSON_KEY = 2;
export const ROOT_OBJECT_KEY = 3;
export const ROOT_GEOSPATIAL_KEY = 4;

export const MIRAGE_MODULES = {
  [FMV]: 'FMV',
  [ISR]: 'ISR FMV',
  [GEO]: 'GEOSPATIAL',
};

export const EDIT_MODE = 1;

export const FACE = 'face';
export const NON_EXPERIMENTAL_OBJECT_TYPES = [{ id: 0, name: FACE }];

/* Mirage Health Statuses */
export const MIRAGE_HEALTH_UNKNOWN = 0;
export const MIRAGE_HEALTH_GOOD = 1;
export const MIRAGE_HEALTH_WARNING = 2;
export const MIRAGE_HEALTH_ERROR = 3;

export const MAX_PERSONS_IN_LIVE_QUERY = 10;
export const MAX_LIVE_QUERIES = 20;
export const MAX_EMBEDDINGS_PER_PERSON = 20;

export const ANALYSIS_VIDEO = 'VIDEO RESULTS';
export const ANALYSIS_IMAGE = 'IMAGE RESULTS';

export const MODEL_SIZE_512 = 0.068359375;
export const MODEL_SIZE_720 = 0.04861111111;
export const MODEL_SIZE_1024 = 0.0341796875;

export const MODEL_INPUT = {
  [MODEL_SIZE_512]: {
    label: 'Universal model',
    short: 'Univ',
  },
  [MODEL_SIZE_720]: {
    label: '720 x 720',
    short: '720',
  },
  [MODEL_SIZE_1024]: {
    label: 'Universal model + Long Range optimization',
    short: 'Univ+LR',
  },
};

export const MODEL_NAMES = {
  'faceRecognition': 'Face Recognition',
  'faceDetection': 'Face Detection',
  'objectDetection': 'Object Detection',
  'vehicleDetection': 'Vehicle Detection',
  'vehicleRecognition': 'Vehicle Recognition',
};

export const MIRAGE_HEALTH_COLOR_TEXT = {
  [MIRAGE_HEALTH_GOOD]: 'good',
  [MIRAGE_HEALTH_WARNING]: 'warning',
  [MIRAGE_HEALTH_ERROR]: 'error',
  [MIRAGE_HEALTH_UNKNOWN]: 'unknown',
};

export const DS_TYPE_VIDEO = 'segment';
export const DS_TYPE_IMAGE = 'image';
export const DS_TYPE_FOLDER = 'folder';
export const DS_TYPE_CAMERA = 'camera';

export const DEFAULT_CELL_DATA = '— —';

export const BACKGROUND_OBJECT_ID = 9999;

export const RELATIONSHIP_STATUS = {
  CONFIRMED: 'CONFIRMED',
  FOR_REVIEW: 'SEARCHED',
  REJECTED: 'REJECTED',
};

export const ITEM_TYPE = {
  FOLDER,
  CAMERA,
  VIDEO,
  IDENTITY,
  IMAGE,
  OBJECT,
  CAMERA_ZONE,
};

export const PERSON_TYPE = {
  PERMANENT: 'PERMANENT',
  PROVISIONAL: 'PROVISIONAL',
};
