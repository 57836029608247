import { GEODATASET_KEY } from 'library/geospatial/redux/constants';
import { pagination } from 'library/redux/sharedDefaults';

import { filter } from '../sharedDefaults';

const defaults = {
  allDataIds: [],
  selectedIds: {},
  filter,
  pagination,
};

export default function reducer(state = defaults, action) {
  switch (action.type) {
    case `LIBRARY_GEOSPATIAL/${GEODATASET_KEY}/SET_ALL_IDS`: {
      return {
        ...state,
        allDataIds: action.payload,
      };
    }

    case `LIBRARY_GEOSPATIAL/${GEODATASET_KEY}/SET_PAGINATION`: {
      return {
        ...state,
        pagination: action.payload,
      };
    }

    case `LIBRARY_GEOSPATIAL/${GEODATASET_KEY}/SET_SELECTED_IDS`: {
      return {
        ...state,
        selectedIds: {
          ...action.payload,
        },
      };
    }

    case `LIBRARY_GEOSPATIAL/${GEODATASET_KEY}/SET_TEXT_FILTER`: {
      return {
        ...state,
        filter: {
          ...state.filter,
          text: action.payload,
        },
      };
    }

    case `LIBRARY_GEOSPATIAL/${GEODATASET_KEY}/SET_SORT_FILTER`: {
      return {
        ...state,
        filter: {
          ...state.filter,
          sort: action.payload.sort,
          sortDirection: action.payload.sortDirection,
        },
      };
    }

    default:
      return state;
  }
}
