import axios from 'axios.js';

import { ANALYTICS_BACKEND, DATASOURCES } from 'common/constants/urls';

export function getLiveFeed(feedId) {
  return `${ANALYTICS_BACKEND}${DATASOURCES}/${feedId}/live/manifest.mpd`;
}

export function getLiveFeedStartTime(feedId) {
  return axios
    .get(`${ANALYTICS_BACKEND}${DATASOURCES}/${feedId}/live-start-time/`)
    .then(({ data }) => data);
}
