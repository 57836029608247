import { createUseStyles } from 'react-jss';
import Dialog from '@material-ui/core/Dialog';

const useStyles = createUseStyles({
  paper: {
    height: '80%',
    width: '80%',
    borderRadius: 4,
  },
});

const FullScreenDialog = ({ classes, ...rest }) => {
  const innerClasses = useStyles();
  const { paper, ...restClasses } = classes || {};

  return (
    <Dialog
      classes={{ paper: `${innerClasses.paper} ${paper}`, ...restClasses }}
      fullScreen
      {...rest}
    />
  );
};

export default FullScreenDialog;
