import { get } from 'lodash';

export const MIRAGE_EXCEPTION = 'MirageException';
export const MIRAGE_EXCEPTION_POPUP = 'MiragePopup';

export const getFromError = (...path) => error => get(error, path);

export const getFromResponse = (...path) => getFromError('response', ...path);
export const getStatus = getFromResponse('status');
export const getData = getFromResponse('data');

export const getFromHeaders = (...path) => getFromResponse('headers', ...path);
export const getContentType = getFromHeaders('content-type');

export const getFromData = (...path) => getFromResponse('data', ...path);
export const getType = getFromData('type');
export const getMessage = getFromData('message');
export const getResolution = getFromData('resolution');
export const getErrorCode = getFromData('errorCode');

export const isMirageException = error => getType(error) === MIRAGE_EXCEPTION;
export const isMiragePopup = error => getType(error) === MIRAGE_EXCEPTION_POPUP;
