import CryptoJS from 'crypto-js';
import DateTime from 'dateTime';

import store from 'redux/store';
import { uniq } from 'lodash';

import { selectIsLiveNGEnabled } from 'settings/redux/selectors';
import { fetchDatasourcesById } from 'common/redux/models/actions';

function getCameraIdFromResult(thumb) {
  const datasourceId = thumb.feedId;
  const datasource = store.getState().common.models.datasource[datasourceId];

  if (!datasource) {
    store.dispatch(fetchDatasourcesById([datasourceId]));
    return null;
  }

  return datasource ? datasource.camera : null;
}

export function getCameraFromResult(thumb) {
  const cameraId = getCameraIdFromResult(thumb);
  return getCameraFromId(cameraId);
}

function getCameraFromId(cameraId) {
  const cameraModels = store.getState().common.models.camera;
  return cameraModels[cameraId] || {};
}

export function getQueryName(result, personModels, objectModels) {
  return uniq(
    result.matchedLiterals.map(({ sourceLiteral }) => {
      const id = sourceLiteral.literal;
      const person = personModels[id];
      if (person) {
        return person.name;
      }

      /* if person does not exist, check if this id is an object */
      const idSplit = id ? id.split('.') : [];
      const hasObject = Object.values(objectModels).find(
        ({ reservedName }) => reservedName === idSplit[0]
      );

      if (hasObject) {
        return idSplit.join('.');
      }

      return null;
    })
  ).join('&'); // join on & because only AND queries produce multiple matchedLiterals
}

export function getUnknownPersonName() {
  /* Create a random hash for the name */
  const hash = String(CryptoJS.lib.WordArray.random(8));
  return `Unknown_${hash}`;
}

export function getLastWeekOfHours() {
  const endTime = DateTime.fromJSDateWithTZ().valueOf();
  const startTime = DateTime.fromJSDateWithTZ().minus({ days: 7 }).valueOf();
  return { startTime, endTime };
}

export const getLiveDelay = () => {
  const isLiveNGEnabled = selectIsLiveNGEnabled(store.getState());
  return isLiveNGEnabled ? 15 : 4;
};
