import { cloneDeep, update } from 'lodash';

import { DATASOURCE_VIEW } from './constants';

export const PLAN_ANALYSIS_KEY = 'plan';
export const PLAN_MONITOR_KEY = 'planMonitor';

const defaults = {
  searchQuery: {
    identityRows: [{ identities: [], groupOperator: 'and' }],
    datasources: {},
    cameras: {},
    cameraZones: {},
    folders: {},
    zones: null,
    minLingerMillis: 0,
    filters: {
      startDate: null,
      endDate: null,
    },
  },
  datasourcesSortIndex: 0,
  isNameDialogOpen: false,
  isLoading: false,
  isLoadingFolders: false,
  selectedFolders: {},
  selectedDatasources: {},
  geoFilterCoordinates: {},
  datasourcesInGeofence: [],
  selectedView: DATASOURCE_VIEW,
};

export function createPlanReducer(suffix) {
  return function planReducer(state = defaults, action) {
    switch (action.type) {
      case 'PLAN_ANALYSIS/SET_ZONES': {
        const { zones, minLingerMillis } = action.payload;
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            zones,
            minLingerMillis,
          },
        };
      }
      case 'PLAN_ANALYSIS/CLEAR_ZONES': {
        const { zones, minLingerMillis, cameraZones } = defaults.searchQuery;
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            zones,
            cameraZones,
            minLingerMillis,
          },
        };
      }
      case 'PLAN_ANALYSIS/SET_IS_LOADING': {
        return {
          ...state,
          isLoading: action.payload,
        };
      }
      case 'PLAN_ANALYSIS/SET_FOLDER_LOADING': {
        return {
          ...state,
          isLoadingFolders: action.payload,
        };
      }
      case 'PLAN_ANALYSIS/SET_PLAN_VIEW': {
        return {
          ...state,
          selectedView: action.payload,
        };
      }
      case 'PLAN_ANALYSIS/SET_GEO_FILTER_COORDINATES': {
        return {
          ...state,
          geoFilterCoordinates: action.payload,
        };
      }
      case 'PLAN_ANALYSIS/CLEAR_GEO_FILTER_COORDINATES': {
        return {
          ...state,
          geoFilterCoordinates: {},
        };
      }
      case 'PLAN_ANALYSIS/SET_DATASOURCES_IN_GEOFENCE': {
        return {
          ...state,
          datasourcesInGeofence: action.payload,
        };
      }
      case `PLAN${suffix}/SET_PLAN_FILTER_DATES`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            filters: action.payload,
          },
        };
      }
      case `PLAN${suffix}/CLEAR_CAMERAS`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            cameras: {},
          },
        };
      }
      case `PLAN${suffix}/CLEAR_CAMERA_ZONES`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            cameraZones: {},
          },
        };
      }
      case `PLAN${suffix}/SET_DATASOURCES_SEARCH_QUERY`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            datasources: action.payload,
          },
        };
      }
      case `PLAN${suffix}/SET_FOLDERS_SEARCH_QUERY`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            folders: action.payload,
          },
        };
      }
      case `PLAN${suffix}/SET_CAMERAS_SEARCH_QUERY`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            cameras: action.payload,
          },
        };
      }
      case `PLAN${suffix}/SET_CAMERA_ZONES_SEARCH_QUERY`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            cameraZones: action.payload,
          },
        };
      }
      case `PLAN${suffix}/CLEAR_IDENTITY_ROWS`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            identityRows: [{ identities: [], groupOperator: 'and' }],
          },
        };
      }
      case `PLAN${suffix}/SET_IDENTITY_ROWS`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            identityRows: action.payload,
          },
        };
      }
      case `PLAN${suffix}/CLEAR_DATASOURCES`: {
        return {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            cameras: {},
            datasources: {},
            folders: {},
            filters: {
              ...state.searchQuery.filters,
              startDate: null,
              endDate: null,
            },
          },
        };
      }
      case `PLAN${suffix}/TOGGLE_QUERY_NAME_DIALOG`: {
        const { isOpen = !state.isNameDialogOpen } = action.payload;

        return {
          ...state,
          isNameDialogOpen: isOpen,
        };
      }
      case `PLAN${suffix}/TOGGLE_IDENTITY_SEARCH_OPERATOR`: {
        const newState = cloneDeep(state);
        const replaceAtIndex = newState.searchQuery.identityRows[action.row].identities.findIndex(
          identity => identity.id === action.id
        );

        update(
          newState,
          `searchQuery.identityRows[${action.row}].identities[${replaceAtIndex}].operator`,
          operator => (operator === 'and' ? 'or' : 'and')
        );

        return newState;
      }
      case `PLAN${suffix}/ADD_IDENTITY_ROW`: {
        const newState = {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            identityRows: [...state.searchQuery.identityRows],
          },
        };

        newState.searchQuery.identityRows.push({
          identities: [],
          groupOperator: 'and',
        });

        return newState;
      }
      case `PLAN${suffix}/REMOVE_IDENTITY_ROW`: {
        const newState = {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            identityRows: [...state.searchQuery.identityRows],
          },
        };

        const { row } = action;

        if (newState.searchQuery.identityRows.length === 1) {
          newState.searchQuery.identityRows = [
            {
              identities: [],
              groupOperator: 'and',
            },
          ];
        } else if (row !== null) {
          newState.searchQuery.identityRows.splice(row, 1);
        } else {
          newState.searchQuery.identityRows.splice(-1);
        }

        return newState;
      }
      case `PLAN${suffix}/CHANGE_ROW_OPERATOR`: {
        const newState = {
          ...state,
          searchQuery: {
            ...state.searchQuery,
            identityRows: [...state.searchQuery.identityRows],
          },
        };
        const { operator, row } = action.payload;

        newState.searchQuery.identityRows[row].groupOperator = operator;
        return newState;
      }
      case `PLAN${suffix}/SET_DATASOURCES_SORT_INDEX`: {
        return {
          ...state,
          datasourcesSortIndex: action.payload,
        };
      }
      default:
        return state;
    }
  };
}
