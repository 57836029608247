import React, { Component } from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import Popover from '@material-ui/core/Popover';
import { ACCENT, DARK_BORDER, PRIMARY_GREY } from 'common/constants/colors';
import MenuItem from 'common/components/base/MenuItem';

const styles = {
  header: {
    background: ACCENT,
    height: 22,
    width: '100%',
    display: 'flex',
    padding: '0 12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 11,
    fontWeight: 500,
  },
  sectionHeader: {
    height: 22,
    width: '100%',
    display: 'flex',
    padding: '0 12px',
    alignItems: 'center',
    borderTop: `1px solid ${DARK_BORDER}`,
    borderBottom: `1px solid ${DARK_BORDER}`,
  },
  item: {
    fontSize: 11,
    fontWeight: 400,

    '& > *:first-child': {
      marginRight: 6,
    },
  },
  data: {
    margin: '0 12px 10px 12px',
  },
  sectionIcon: {
    width: 20,
    height: 20,
    minWidth: 20,
    minHeight: 20,
    marginRight: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionText: {
    fontSize: 11,
    width: '100%',
    color: PRIMARY_GREY,
  },
};

class MiragePopoverMenu extends Component {
  static defaultProps = {
    dense: true,
    clickable: true,
    anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
  };

  renderItem = ({ key, icon, label, data, className, ...rest }) => (
    <React.Fragment key={key}>
      <MenuItem
        className={classnames(this.props.classes.item, className)}
        dense={this.props.dense}
        clickable={this.props.clickable}
        {...rest}
      >
        {icon}
        {label}
      </MenuItem>
      {data && <div className={this.props.classes.data}> {data} </div>}
    </React.Fragment>
  );

  renderSection = ({ label, icon, items = [] }) => {
    const { classes } = this.props;
    const hasContent = Boolean(label || icon);

    return (
      <React.Fragment key={label}>
        {hasContent && (
          <div className={classes.sectionHeader}>
            {icon && <div className={classes.sectionIcon}>{icon}</div>}
            <div className={classes.sectionText}>{label}</div>
          </div>
        )}
        {items.map(this.renderItem)}
      </React.Fragment>
    );
  };

  render() {
    const { className, classes, clickable, config, dense, header, ...rest } = this.props;

    return (
      <Popover classes={{ paper: classes.paper }} data-testid="MiragePopoverMenu" {...rest}>
        <header className={this.props.classes.header}>{header}</header>
        {config.map(this.renderSection)}
      </Popover>
    );
  }
}

export default injectSheet(styles)(MiragePopoverMenu);
