import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'common/components/base/Button';
import Notifications from 'app/notifications/Notifications';
import * as Actions from 'app/redux/actions';
import { LIBRARY } from 'app/redux/constants';
import { Example } from './helpers';

const NotificationsExample = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState(0);

  return (
    <>
      <Example>
        <div>
          <Button
            onClick={() => {
              const note = { id, message: `woo${id}` };
              dispatch(Actions.renderNotification(note));
              setId(prev => prev + 1);
            }}
          >
            Notify Me
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              dispatch(Actions.renderErrorMessage(`error${id}`));
              setId(prev => prev + 1);
            }}
          >
            Error Me
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              const note = { notification: { id, message: `library${id}` }, type: LIBRARY };
              dispatch(Actions.renderGenericNotification(note));
              setId(prev => prev + 1);
            }}
          >
            Library Me
          </Button>
        </div>
        <Notifications />
      </Example>
    </>
  );
};

export default NotificationsExample;
