import React from 'react';
import { VIDEO_HAS_CURRENT_DATA } from './constants';

/*
  This is the default HTML5 Video DOM Element. This component is standalone
  and can be used by itself. The DashVideo.jsx is a thin wrapper around this
  basic HTML5 Video Element that replaces the video with a Dash Player, but it
  has the same fundamental API as this one
*/

class Video extends React.Component {
  static defaultProps = {
    onMount: () => {},
  };

  componentDidMount() {
    this._video.addEventListener('play', this.onPlay);
    this._video.addEventListener('pause', this.onPause);
    this._video.addEventListener('ready', this.props.onReady);
    this._video.addEventListener('ended', this.props.onEnded);
    this._video.addEventListener('seeking', this.props.onSeekStart);
    this._video.addEventListener('seeked', this.props.onSeekFinish);
    this._video.addEventListener('error', this.props.onError);
    this._video.addEventListener('loadstart', this.props.onLoadStart);
    this._video.addEventListener('loadeddata', this.props.onLoadFinish);
    this._video.addEventListener('volumechange', this.props.onVolumeChange);
    this._video.addEventListener('durationchange', this.props.onDurationChange);
    this.props.onMount();
  }

  componentWillUnmount() {
    if (this._reqId) cancelAnimationFrame(this.reqId);
    this._video.removeEventListener('play', this.onPlay);
    this._video.removeEventListener('pause', this.onPause);
    this._video.removeEventListener('ready', this.props.onReady);
    this._video.removeEventListener('ended', this.props.onEnded);
    this._video.removeEventListener('seeking', this.props.onSeekStart);
    this._video.removeEventListener('seeked', this.props.onSeekFinish);
    this._video.removeEventListener('error', this.props.onError);
    this._video.removeEventListener('loadstart', this.props.onLoadStart);
    this._video.removeEventListener('loadeddata', this.props.onLoadFinish);
    this._video.removeEventListener('volumechange', this.props.onVolumeChange);
    this._video.removeEventListener('durationchange', this.props.onDurationChange);

    /*
      We must cancel this frame when unmounting otherwise this can potentially start
      leaking memory when users leave a page without pausing the video
    */
    this._cancelAnimationFrame();
  }

  onPlay = () => {
    this._requestAnimationFrame();
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  onPause = () => {
    this._cancelAnimationFrame();
    if (this.props.onPause) {
      this.props.onPause();
    }
  };

  setSrc = src => (this._video.src = src);

  canPlay = () => {
    const frameLength = this.getFrameLength();
    return this.getTime() <= this.getDuration() - frameLength;
  };

  canPlayType = type => this._video && this._video.canPlayType(type);

  getDuration = () => {
    if (this.props.isLive) return this._getLiveDuration();

    return this._video && this._video.duration;
  };

  getFrameLength = () => 1 / this.props.frameRate;

  _getLiveDuration = () => {
    // _getLiveDuration is needed as this._video.duration returns Infinity for
    // live videos. This returns the duration relative to the live datasource
    // start time
    const dashPlayer = this.props.getDashPlayer();

    if (!dashPlayer) return 0;

    return dashPlayer.duration() + this._getLiveAdjustment();
  };

  _getLiveAdjustment = () => (this.props.liveFeedStartTime - this.props.startEpochMs) / 1000;

  getTime = () => {
    if (!this._video) return;

    let { currentTime } = this._video;
    if (this.props.isLive) {
      // If the video is live, adjust currentTime by the difference between the
      // created date and the last updated timestamp
      const adjustBy = this._getLiveAdjustment();
      currentTime += adjustBy;
    }

    return currentTime;
  };

  isPaused = () => this._video.paused;

  play = () => this._video.play();

  pause = () => this._video.pause();

  getReadyState = () => this._video && this._video.readyState;

  getVideo = () => this._video;

  hasLoadedCurrentFrame = () => this.getReadyState() >= VIDEO_HAS_CURRENT_DATA;

  setTime = seconds => {
    if (Number.isFinite(seconds) && seconds >= 0) {
      // This fixes a bug for videos > 12 hours where if the time being
      // set is 0, and we add one millsecond to it the chunk stream is
      // fetched indefinitely and the video will not load
      if (seconds === 0) {
        this._video.currentTime = seconds;
      } else {
        // One MS is added to the time being set because if the video is set to
        // the exact time of the detection, the frame is a fraction behind the
        // detection
        this._video.currentTime = seconds + 0.001;
      }
    }
  };

  _requestAnimationFrame = () => {
    this.props.onProgress(this.getTime());
    this._reqId = requestAnimationFrame(this._requestAnimationFrame);
  };

  _cancelAnimationFrame = () => {
    if (this._reqId) cancelAnimationFrame(this._reqId);
  };

  _setRef = node => {
    if (this.props.bindRef) this.props.bindRef(node);
    this._video = node;
  };

  render() {
    return (
      <video
        autoPlay={this.props.autoPlay}
        controls={this.props.controls}
        muted={this.props.muted}
        onClick={this.props.onClick}
        ref={this._setRef}
        style={this.props.style}
      />
    );
  }
}

export default Video;
