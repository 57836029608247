import React from 'react';
import classnames from 'classnames';
import MuiIcon from '@material-ui/core/Icon';
import { ThemeProvider } from '@material-ui/core/styles';

import { withStyles } from 'tss-react-local';
import { getTheme } from './themes';
import { SMALL_ICON_FONT, MED_ICON_FONT, LARGE_ICON_FONT } from './constants';

const styles = (theme, props) => ({
  root: {
    fontSize: MED_ICON_FONT,
    color: props.color,
  },
  margin: {
    marginRight: theme.spacing(0.75),
  },
  wrapper: {
    display: 'inline-block',
    height: 'min-content',
    width: 'min-content',
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
  },
  fontSizeLarge: {
    fontSize: LARGE_ICON_FONT,
  },
  fontSizeSmall: {
    fontSize: SMALL_ICON_FONT,
  },
});

export function getColorFromTheme(theme) {
  /* certain components will inherit its parent's color if color = 'inherit' */
  return theme ? 'primary' : 'inherit';
}

class Icon extends React.PureComponent {
  static defaultProps = {
    addMargin: false,
    size: 'default', // default, small, or large
    customClasses: {},
  };

  render() {
    const {
      addMargin,
      classes,
      customClasses,
      iconName,
      color,
      theme,
      size,
      disabled,
      ...rest
    } = this.props;

    const { root, fontSizeLarge, fontSizeSmall, ...restClasses } = customClasses;

    const customTheme = getTheme(theme);
    const Component = (
      <MuiIcon
        color={getColorFromTheme(theme)}
        classes={{
          root: classnames(classes.root, customClasses.root, {
            [classes.disabled]: disabled,
            [classes.margin]: addMargin,
          }),
          fontSizeLarge: classnames(classes.fontSizeLarge, customClasses.fontSizeLarge),
          fontSizeSmall: classnames(classes.fontSizeSmall, customClasses.fontSizeSmall),
          ...restClasses,
        }}
        fontSize={size}
        {...rest}
      >
        {iconName}
      </MuiIcon>
    );

    // WARNING: This component does not seem to properly re-render when the theme prop updates
    // You may only get the theme that was provided on first render, regardless of the value changing
    if (customTheme) {
      return <ThemeProvider theme={customTheme}>{Component}</ThemeProvider>;
    }

    return Component;
  }
}

export default withStyles(Icon, styles);
