import store from 'redux/store';
import { selectCurrentUser } from 'auth/redux/selectors';

export function createKey(type, model) {
  return type.concat(model ? `/${model}` : '');
}

export function createRequestId(key) {
  const currentUser = selectCurrentUser(store.getState());

  return `${currentUser.username}_${Date.now()}_${key}`;
}

export function parseIndexer(indexer) {
  let key = indexer;
  let type;
  let model;

  if (typeof indexer === 'string') {
    [type, model] = indexer.split('/');
  } else {
    ({ type, model } = indexer);
    key = createKey(type, model);
  }

  return { key, type, model };
}
