import DateTime from 'dateTime';

const getUTCOffsetString = (date, { year } = {}, skipParse = false) => {
  const l = skipParse ? date : DateTime.fromJSDateWithTZ(date, {});

  if (year !== undefined) {
    l.set({ year });
  }

  const utcOffset = l.toFormat('ZZ').trim();
  return utcOffset;
};

export default getUTCOffsetString;
