import React from 'react';

import MirageErrorPage from 'app/errorpage/MirageErrorPage';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = { hasError: false };

  render() {
    return this.state.hasError ? <MirageErrorPage /> : this.props.children;
  }
}

export default ErrorBoundary;
