import React from 'react';
import Avatar from 'common/components/base/Avatar';
import Chip from 'common/components/base/Chip';
import Icon from 'common/components/base/Icon';
import { withStyles } from 'tss-react-local';
import { Example } from '../helpers';

const styles = {
  wrapper: {
    '& > div': {
      marginRight: 8,
    },
  },
  maxWidthLabel: {
    maxWidth: 250,
  },
};

class ChipExample extends React.Component {
  onDelete = () => alert('Deleted'); // eslint-disable-line

  onClick = () => alert('Clicked'); // eslint-disable-line

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <Example label="Chip" className={classes.wrapper}>
          <Chip label="Default Chip" color="default" />
          <Chip label="Primary Color" />
          <Chip label="Avatar Chip" avatar={<Avatar>MR</Avatar>} />
          <Chip
            label="Clickable Avatar Chip"
            avatar={
              <Avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
          <Chip
            label="Clickable Avatar Chip"
            avatar={
              <Avatar>
                <Icon iconName="edit" size="small" />
              </Avatar>
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
          <Chip
            disabled
            label="Clickable Avatar Chip"
            avatar={
              <Avatar>
                <Icon iconName="edit" size="small" />
              </Avatar>
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
          <Chip
            customClasses={{
              label: classes.maxWidthLabel,
            }}
            label="Really Long Label that Overflows the Container"
            avatar={
              <Avatar>
                <Icon iconName="edit" size="small" />
              </Avatar>
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
        </Example>

        <Example label="Outlined" className={classes.wrapper}>
          <Chip variant="outlined" label="Default Chip" color="default" />
          <Chip variant="outlined" label="Primary Color" />
          <Chip variant="outlined" label="Avatar Chip" avatar={<Avatar>MR</Avatar>} />
          <Chip
            variant="outlined"
            label="Clickable Avatar Chip"
            avatar={
              <Avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
          <Chip
            variant="outlined"
            label="Clickable Avatar Chip"
            avatar={
              <Avatar>
                <Icon iconName="edit" size="small" />
              </Avatar>
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
          <Chip
            disabled
            variant="outlined"
            label="Clickable Avatar Chip"
            avatar={
              <Avatar>
                <Icon iconName="edit" size="small" />
              </Avatar>
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
        </Example>

        <Example label="Small chips" className={classes.wrapper}>
          <Chip size="small" variant="outlined" label="Default Chip" color="default" />
          <Chip size="small" variant="outlined" label="Primary Color" />
          <Chip size="small" variant="outlined" label="Avatar Chip" avatar={<Avatar>MR</Avatar>} />
          <Chip
            size="small"
            variant="outlined"
            label="Clickable Avatar Chip"
            avatar={
              <Avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
          <Chip
            size="small"
            variant="outlined"
            label="Clickable Avatar Chip"
            avatar={
              <Avatar>
                <Icon iconName="edit" style={{ fontSize: 18 }} />
              </Avatar>
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
          <Chip
            size="small"
            disabled
            variant="outlined"
            label="Clickable Avatar Chip"
            avatar={
              <Avatar>
                <Icon iconName="edit" style={{ fontSize: 18 }} />
              </Avatar>
            }
            onClick={this.onClick}
            onDelete={this.onDelete}
          />
        </Example>

        <Example label="Colored Chips" className={classes.wrapper}>
          <Chip label="Black" theme="black" />
          <Chip label="Green" theme="green" />
          <Chip label="Red" theme="red" />
          <Chip label="Grey" theme="grey" />
          <Chip variant="outlined" label="Black" theme="black" />
          <Chip variant="outlined" label="Green" theme="green" />
          <Chip variant="outlined" label="Red" theme="red" />
          <Chip variant="outlined" label="Grey" theme="grey" />
        </Example>
      </div>
    );
  }
}

export default withStyles(ChipExample, styles);
