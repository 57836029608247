import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';
import { get, filter, partition, isEmpty } from 'lodash';

import * as Types from 'types';

import {
  CAMERA,
  DATASOURCE,
  FOLDER,
  FOLDER_V2,
  GEO_CLASSIFIER,
  GEO_DETECTOR,
  GEO_SEARCH_REQUEST,
  GEO,
  GEODATASET,
  GEOIMAGE,
  GEOFENCE,
  IMAGE,
  MISSION,
  OBJECT,
  PERSON,
  USER,
  VENUE_MAP,
  ROOT_GEOSPATIAL_KEY,
  ROOT_DATASOURCE_KEY,
} from 'common/constants/app';
import { ALL_OBJECTS, IDENTITY_TYPES } from 'common/constants/objects';
import { ALL_VEHICLE_TYPES } from 'common/constants/strings';
import { ROOT_DIRECTORIES } from 'library/redux/constants';

import { getObjectName } from 'common/helpers/objectUtils';
import { frontendZones } from 'analysis/redux/utils';
import { selectFetchAllDetectionsInInspectMode } from 'settings/redux/selectors';

export const selectCameraModels = (state: DefaultRootState): { [key: number]: Types.BaseCamera } =>
  get(state, `common.models.${CAMERA}`);

export const selectDatasourceModels = (
  state: DefaultRootState
): { [key: string]: Types.BaseDatasource } => get(state, `common.models.${DATASOURCE}`);

// Ignore type for v1 folders since they are deprecated
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const selectDeprecatedFolderModels = (state: DefaultRootState) =>
  get(state, `common.models.${FOLDER}`);
/* eslint-enable */

export const selectFolderV2Models = (
  state: DefaultRootState
): { [key: number]: Types.BaseFolder } => get(state, `common.models.${FOLDER_V2}`);

export const selectFolderModelsWithKey = (
  state: DefaultRootState,
  selectedFolderKey: number
): Types.BaseFolder => get(state, `common.models.${FOLDER_V2}.${selectedFolderKey}`);

export const selectRootFolderOfType = (
  state: DefaultRootState,
  type: 'datasource' | 'person' | 'detectionobject'
): Types.BaseFolder => get(state, ['common', 'models', FOLDER_V2, ROOT_DIRECTORIES[type]]);

export const selectRootDatasourceFolder = (state: DefaultRootState): Types.BaseFolder =>
  get(state, `common.models.${FOLDER_V2}.${ROOT_DATASOURCE_KEY}`);

export const createSelectSharedFoldersOfType = (type: string) =>
  createSelector(selectFolderV2Models, models =>
    Object.values(models).filter(f => f.isShared && f.rootFolderType === type)
  );

export const selectFoldersOfType = createSelector(
  [selectFolderV2Models, (_: DefaultRootState, type: string) => type],
  (models, type) =>
    Object.values(models).filter(
      f =>
        f.rootFolderType === type ||
        (f.hierarchy &&
          f.hierarchy.length > 1 &&
          models[f.hierarchy[0]?.id]?.rootFolderType === type)
    )
);

export const selectSharedPersonFolders = createSelectSharedFoldersOfType(PERSON);

const findTopParent = (
  folders: { [key: number]: Types.BaseFolder },
  id: number
): Types.BaseFolder | null => {
  const folder = folders[id];
  if (!folder) return null;
  return !folder.parent ? folder : findTopParent(folders, folder.parent);
};

const selectCurrentLibraryParent = createSelector(
  [selectFolderV2Models, (_: DefaultRootState, folderId: number) => folderId],
  (folders, folderId) => {
    if (isEmpty(folders)) return null;
    return findTopParent(folders, folderId);
  }
);

export const selectCurrentLibraryDatasourcesFolder = createSelector(
  [selectFolderV2Models, selectCurrentLibraryParent],
  (folders, parentLibraryFolder) =>
    Object.values(folders).find(
      f => f.name === parentLibraryFolder?.name && f.rootFolderType === DATASOURCE
    )
);

export const selectCurrentLibraryPersonsFolder = createSelector(
  [selectFolderV2Models, selectCurrentLibraryParent],
  (folders, parentLibraryFolder) =>
    Object.values(folders).find(
      f => f.name === parentLibraryFolder?.name && f.rootFolderType === PERSON
    )
);

export const selectFolderIdOfCurrentLibrary = createSelector(
  selectCurrentLibraryDatasourcesFolder,
  selectCurrentLibraryPersonsFolder,
  (currentLibraryDatasourcesFolder, currentLibraryPersonsFolder) =>
    currentLibraryDatasourcesFolder?.id || currentLibraryPersonsFolder?.id
);

// Ignore type for v1 folders since they are deprecated
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const selectRootGeoFolder = (state: DefaultRootState) =>
  get(state, `common.models.${FOLDER}.${ROOT_GEOSPATIAL_KEY}`);
/* eslint-enable */

export const selectGeoDatasetModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseGeoDataset } => get(state, `common.models.${GEODATASET}`);

export const selectGeoClassifierModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseGeoClassifier } => get(state, `common.models.${GEO_CLASSIFIER}`);

export const selectGeoDetectorModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseGeoDetector } => get(state, `common.models.${GEO_DETECTOR}`);

export const selectGeofenceModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseGeofence } => get(state, `common.models.${GEOFENCE}`);

export const selectGeoImageModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseGeoImage } => get(state, `common.models.${GEOIMAGE}`);

export const selectGeoSearchRequestModels = (state: DefaultRootState) =>
  get(state, `common.models.${GEO_SEARCH_REQUEST}`);

export const selectImageModels = (state: DefaultRootState): { [key: number]: Types.BaseImage } =>
  get(state, `common.models.${IMAGE}`);

export const selectMissionModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseMission } => get(state, `common.models.${MISSION}`);

export const selectObjectModels = (
  state: DefaultRootState,
  options: { omitCustom?: boolean } = {}
): { [key: number]: Types.BaseObject } => {
  const models = get(state, `common.models.${OBJECT}`);
  return options.omitCustom ? filter(models, { custom: false }) : models;
};

export const selectPersonModels = (state: DefaultRootState): { [key: number]: Types.BasePerson } =>
  get(state, `common.models.${PERSON}`);

export const selectUserModels = (state: DefaultRootState): { [key: number]: Types.BaseUser } =>
  get(state, `common.models.${USER}`);

export const selectVenueMapModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseVenueMap } => get(state, `common.models.${VENUE_MAP}`);

export const selectReportModels = (state: DefaultRootState): { [key: number]: Types.BaseReport } =>
  get(state, 'common.models.reports');

export const selectSceneModels = (state: DefaultRootState): { [key: number]: Types.BaseScene } =>
  get(state, 'common.models.scenes');

export const selectMaculaModels = (state: DefaultRootState): Types.BaseMacula[] =>
  get(state, 'common.models.maculas');

export const selectLiveQueryModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseLiveQuery } => get(state, 'common.models.liveQueries');

export const selectLiveQueryGroupModels = (
  state: DefaultRootState
): { [key: number]: Types.BaseLiveQueryGroup } => get(state, 'common.models.liveQueryGroups');

export const getMapName = (venue: Types.BaseVenueMap): string => get(venue, 'mapName');

/* separates the geo objects into "custom" objects and "default" mirage vehicles */
export const selectGeoObjectGroups = createSelector(selectObjectModels, models => {
  const data = Object.values(models);
  const objects = data.filter(({ modules }) => modules.includes(GEO));
  const [expObj, defaultObj] = partition(objects, ({ custom }) => custom);

  return {
    custom: expObj,
    default: defaultObj,
  };
});

export const selectMaculas = createSelector(selectMaculaModels, Object.values);

export const selectGeoObjects = createSelector(selectObjectModels, models => {
  const data = Object.values(models);
  return data.filter(({ modules }) => modules.includes(GEO));
});

export const selectGeoObjectIds = createSelector(selectGeoObjectGroups, groups =>
  Object.values(groups.default).map(({ id }) => id)
);

export const selectObjectModelIds = createSelector(selectObjectModels, models =>
  Object.values(models).map(({ id }) => id)
);

export const selectObjectModelOptions = createSelector(selectGeoObjects, models =>
  Object.values(models).map(object => ({
    label: getObjectName(object),
    value: object.id,
  }))
);

export const selectLiveQueryGroupModelValues = createSelector(
  selectLiveQueryGroupModels,
  Object.values
);

export const createSelectGroupHasOneQuery = (groupId: number) =>
  createSelector(
    selectLiveQueryGroupModels,
    (groups): boolean => get(groups, [groupId, 'liveQueries', 'length']) === 1
  );

export const selectZonesFromCamera = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => frontendZones(cameraModels[cameraId]?.zones)
);

export const selectZonesDataFromCamera = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => cameraModels[cameraId]?.zones
);

export const selectCamerasWithZones = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraData: Types.BaseCamera[]) => cameraData,
  (cameraModels, cameraData) =>
    cameraData.reduce((acc: { [key: number]: boolean }, camera) => {
      if (!isEmpty(frontendZones(cameraModels[camera.id]?.zones))) {
        acc[camera.id] = true;
      }
      return acc;
    }, {})
);

export const selectNumberOfRecordingsFromCamera = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => cameraModels[cameraId]?.datasources?.length
);

export const makeSelectNumberOfRecordingsFromCamera = (): typeof selectNumberOfRecordingsFromCamera =>
  selectNumberOfRecordingsFromCamera;

export const selectDatasourceIdFromCamera = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => cameraModels[cameraId]?.liveFeedId
);

export const selectStreamUriFromCamera = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => cameraModels[cameraId]?.streamingUri
);

export const selectCameraFromCameraId = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => cameraModels[cameraId]
);

export const selectFPSFromCamera = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => get(cameraModels, [cameraId, 'fps'])
);

export const selectDisplayNameFromCamera = createSelector(
  selectCameraModels,
  (_: DefaultRootState, cameraId: number) => cameraId,
  (cameraModels, cameraId) => get(cameraModels, [cameraId, 'displayName'])
);

export const selectObjectByReserveName = createSelector(
  selectObjectModels,
  (_: DefaultRootState, { reservedName }: { reservedName: string }) => reservedName,
  (objectModels, reservedName) =>
    Object.values(objectModels).find(object => object.reservedName === reservedName)
);

// create a ',' separated pluralized string of category names,
// replace ',' with '&' before the last category name
export const selectObjectCategoryNames = createSelector(
  selectObjectModels,
  (_, { objectCategories = [] } = {}) => objectCategories,
  (objectModels, categories: string | number[]): string => {
    if (categories === ALL_OBJECTS) return ALL_VEHICLE_TYPES;

    return Array.isArray(categories)
      ? categories
          .map((category: number) => getObjectName(objectModels[category]))
          .join(', ')
          .replace(/,([^,]*)$/, ' &$1')
      : '';
  }
);

export const selectInspectModeTypes = createSelector(
  selectFetchAllDetectionsInInspectMode,
  selectObjectModels,
  (showAllDetections, models) =>
    showAllDetections
      ? Object.values(models)
      : Object.values(models).filter(({ id }) => IDENTITY_TYPES.includes(id))
);
