import React, { useState } from 'react';
import classnames from 'classnames';
import { styled, createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { SECONDARY, LIGHT_ACCENT } from 'common/constants/colors';
import IdentityTileMenu from './IdentityTileMenu';

const size = 24;

const useRootStyles = createUseStyles(theme => ({
  root: {
    minWidth: theme.spacing(size),
    maxWidth: theme.spacing(size),
    background: ({ isSelected }) => isSelected && LIGHT_ACCENT,
    outline: ({ isSelected }) => isSelected && `2px solid ${SECONDARY}`,
  },
}));

export const Root = ({ className, isSelected, ...rest }) => {
  const { root } = useRootStyles({ isSelected });
  return <Card square className={classnames(root, className)} {...rest} />;
};

const Image = styled(CardMedia)({
  height: 0,
  paddingTop: '100%',
});

const Container = styled('div')({
  position: 'relative',
});

const useScreenStyles = createUseStyles({
  screen: {
    background: 'rgba(0,0,0,0.3)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    visibility: ({ show }) => (show ? 'visible' : 'hidden'),
  },
});

const Screen = ({ show, ...rest }) => {
  const classes = useScreenStyles({ show });
  return <div className={classes.screen} {...rest} />;
};

const useMenuStyles = createUseStyles({
  menu: {
    position: 'absolute',
    top: 0,
    right: 0,
    visibility: ({ show }) => (show ? 'visible' : 'hidden'),
  },
});

const StyledIdentityTileMenu = ({ show, ...rest }) => {
  const classes = useMenuStyles({ show });
  return <IdentityTileMenu className={classes.menu} {...rest} />;
};

const IdentityTile = ({
  imgSrc,
  imgAlt,
  isSelected,
  options,
  onMouseEnter,
  onMouseLeave,
  children,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Root
      isSelected={isSelected}
      onMouseEnter={e => {
        setIsHovered(true);
        if (onMouseEnter) onMouseEnter(e);
      }}
      onMouseLeave={e => {
        setIsHovered(false);
        if (onMouseLeave) onMouseLeave(e);
      }}
      {...rest}
    >
      <Container>
        <Image image={imgSrc} title={imgAlt} />
        <Screen show={isHovered || isSelected}>
          <StyledIdentityTileMenu show={isHovered} options={options} />
        </Screen>
      </Container>
      <CardContent>{children}</CardContent>
    </Root>
  );
};

IdentityTile.propTypes = {
  isSelected: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default IdentityTile;
