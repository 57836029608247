import { forwardRef } from 'react';
import MuiListSubheader from '@material-ui/core/ListSubheader';

import { makeStyles } from 'tss-react-local';
import { DARK_BORDER } from 'common/constants/colors';

const useStyles = makeStyles()(theme => ({
  root: {
    minHeight: 35,
    lineHeight: '35px',
    display: 'flex',
    alignItems: 'center',
  },
  dense: {
    minHeight: 28,
    lineHeight: '28px',
    padding: theme.spacing(0.5, 1.5),
  },
  divider: {
    borderBottom: `1px solid ${DARK_BORDER}`,
  },
}));

const ListSubheader = forwardRef((props, ref) => {
  const { className, clickable, children, customClasses, divider, dense, size, ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <MuiListSubheader
      className={cx(classes.root, className, {
        [classes.dense]: dense,
        [customClasses.dense]: dense,
        [classes.divider]: divider,
      })}
      classes={customClasses}
      ref={ref}
      {...rest}
    >
      {children}
    </MuiListSubheader>
  );
});

ListSubheader.defaultProps = {
  clickable: true,
  customClasses: {},
  dense: true,
  size: 'default',
};

export default ListSubheader;
