import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import IdentityTile, { Name, Relationship } from 'components/IdentityTile';
import { useIdentityPackage } from './IdentityPackageContext';

const IdentityPackageLinkedPerson = ({ linkId }) => {
  const { identityLinksById, deleteLink } = useIdentityPackage();
  const { toIdentity: person, relationship, strength, id } = identityLinksById[linkId];
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <IdentityTile
      imgSrc={person?.images?.[0]}
      imgAlt={person.name}
      options={[
        {
          label: 'Delete Relationship',
          onClick: () => deleteLink(id),
        },
        {
          label: 'Edit Relationship',
          onClick: () => history.replace(`${url}/link/${id}`),
        },
      ]}
    >
      <Name value={person.name} />
      <Relationship value={relationship} strength={strength} />
    </IdentityTile>
  );
};

export default IdentityPackageLinkedPerson;
