import React from 'react';
import injectSheet from 'react-jss';
import defaultAxios from 'axios'; // Must import node_module, not local version for CancelToken
import axios from 'axios.js'; // Need local version of axios to send token in header

import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';

const { CancelToken } = defaultAxios;

const styles = {
  root: {
    position: 'relative',
  },
};

class AuthorizedImage extends React.Component {
  static defaultProps = {
    src: '',
    useDefaultSrc: false,
  };

  state = {
    isLoading: false,
  };

  componentDidMount() {
    if (!this.props.useDefaultSrc) {
      this.getImageData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.src && this.props.src !== prevProps.src) {
      this.getImageData();
    }
  }

  componentWillUnmount() {
    this.cancelToken();

    if (this._objectURL) URL.revokeObjectURL(this._objectURL);
    if (this.props.onUnmount) this.props.onUnmount();
  }

  cancelToken = () => {
    if (this.setImageSource && this.setImageSource.cancel) {
      this.setImageSource.cancel('API canceled');
    }
  };

  bindRef = node => {
    this.image = node;
    if (this.props.bindRef) this.props.bindRef(node);
  };

  onError = err => {
    if (defaultAxios.isCancel(err)) return;
    if (this.props.onLoadError) {
      this.props.onLoadError(err);
    }
  };

  onLoad = ({ data }) => {
    this._objectURL = data;
    const imageData = URL.createObjectURL(data);
    this.image.src = imageData;
    this.image.onload = this.props.onLoad;
  };

  getImageData = () => {
    this.setState({ isLoading: true });
    this.cancelToken();

    this.image.src = '';
    this.setImageSource = CancelToken.source();

    if (this.props.onLoadingSrc) {
      this.props.onLoadingSrc();
    }

    return axios
      .get(this.props.src, {
        cancelToken: this.setImageSource.token,
        responseType: 'blob',
      })
      .then(this.onLoad)
      .catch(this.onError)
      .finally(() => this.setState({ isLoading: false }));
  };

  render() {
    const {
      src,
      useDefaultSrc,
      bindRef,
      onLoad,
      onUnmount,
      onLoadError,
      onLoadingSrc,
      ...rest
    } = this.props;

    return (
      <div className={this.props.classes.root} data-testid="authorizedImage">
        {this.state.isLoading && <LoadingOverlay />}
        <img
          alt=""
          ref={this.bindRef}
          src={useDefaultSrc ? src : ''}
          onLoad={useDefaultSrc ? onLoad : undefined}
          data-testid="AuthorizedImage"
          {...rest}
        />
      </div>
    );
  }
}

export default injectSheet(styles)(AuthorizedImage);
