import React from 'react';
import Minimize from 'common/components/base/Minimize';
import Icon from 'common/components/base/Icon';
import Fab from 'common/components/base/Fab';

import { Example } from '../helpers';

export default class ButtonExample extends React.Component {
  state = { open: true };

  toggle = () => this.setState(state => ({ open: !state.open }));

  renderList = count => {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(this.renderListItem(i));
    }

    return (
      <ul
        style={{
          width: 300,
          height: 300,
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 40,
          paddingTop: 40,
          background: 'white',
          borderRadius: 5,
        }}
      >
        {result}
      </ul>
    );
  };

  renderListItem = i => <li style={{ display: 'flex', alignItems: 'center' }}>Result {i}</li>;

  renderIcon = () => {
    const icon = this.state.open ? (
      <Icon iconName="chevron_left" color="blue" />
    ) : (
      <Icon iconName="menu" color="blue" />
    );

    return (
      <Fab
        variant="fab"
        size="small"
        onClick={this.toggle}
        style={{
          bottom: -10,
          left: -10,
          cursor: 'pointer',
          zIndex: 10,
          position: 'absolute',
          transition: 'all 0.5s',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
      </Fab>
    );
  };

  render() {
    return (
      <div>
        <Example label="Drawer">
          <div
            style={{
              width: '100%',
              height: 500,
              background: 'pink',
              position: 'relative',
            }}
          >
            <Minimize
              open={this.state.open}
              onToggle={this.toggle}
              style={{ position: 'absolute', top: 10, left: 10 }}
            >
              {this.renderList(10)}
            </Minimize>
            <Minimize
              open={this.state.open}
              onToggle={this.toggle}
              transformOrigin="bottom left"
              style={{ position: 'absolute', top: 10, left: 330 }}
              renderIcon={this.renderIcon}
            >
              {this.renderList(10)}
            </Minimize>
          </div>
        </Example>
      </div>
    );
  }
}
