import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from 'common/components/base/Button';

import { ERROR, DARK_GREY, TEXT } from 'common/constants/colors';
import { renderErrorReport } from 'app/redux/actions';

import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';

const styles = {
  main: {
    maxWidth: 320,
    minWidth: 320,

    '& > *': {
      display: 'flex',
      padding: 20,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 13,
    alignItems: 'center',
  },
  icon: {
    color: ERROR,
    marginRight: 16,
  },
  content: {
    fontWeight: 300,
    fontSize: 11,
    color: TEXT,
    lineHeight: 1.5,
    padding: '0 20px 20px 20px',
  },
  bottom: {
    paddingTop: 0,
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& button': {
      marginLeft: 12,
      minWidth: 80,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: -1,
    top: 22,

    '& a': {
      fontSize: 11,
    },

    '& aside': {
      width: '100%',
      overflowY: 'auto',
      boxSizing: 'border-box',
      padding: 8,
      marginTop: 8,
      marginBottom: 40,
      maxHeight: 120,
      border: `1px solid ${DARK_GREY}`,
      borderRadius: 2,
      fontWeight: 300,
      fontSize: 11,
      color: TEXT,
    },
  },
};

class SendError extends React.PureComponent {
  state = { showDetails: false };

  handleClose = () => {
    this.props.dispatchRenderErrorReport(null);
  };

  sendErrorReport = () => {
    this.handleClose();
  };

  toggleDetails = () => this.setState(state => ({ showDetails: !state.showDetails }));

  render() {
    const { classes, errorReport, open } = this.props;
    if (!errorReport) return null;

    const detailStyles = { display: this.state.showDetails ? 'flex' : 'none' };

    return (
      <Dialog open={open} onClose={this.handleClose} classes={{ paper: classes.main }}>
        <header className={classes.title}>
          <Icon className={classes.icon}>error</Icon>
          <span>{errorReport.title}</span>
        </header>

        <section className={classes.content}>{errorReport.content}</section>

        <div className={classes.bottom}>
          <div className={classes.details}>
            <Button variant={null} onClick={this.toggleDetails}>
              Details
            </Button>
            <aside style={detailStyles}>{errorReport.details}</aside>
          </div>
          <div className={classes.actions}>
            <Button variant="outlined" onClick={this.handleClose}>
              DISMISS
            </Button>
            <Button theme="blue" onClick={this.sendErrorReport}>
              SEND REPORT
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

SendError.propTypes = {
  errorReport: PropTypes.object,
};

const mapStateToProps = store => ({
  errorReport: store.app.errorReport,
});

const mapDispatchToProps = {
  dispatchRenderErrorReport: renderErrorReport,
};

const withProps = connect(mapStateToProps, mapDispatchToProps)(SendError);

export default injectSheet(styles)(withProps);
