import React, { useState } from 'react';
import Table, { ASC } from 'components/Table';
import Button from 'common/components/base/Button';
import { sortBy } from 'lodash';
import { Example } from '../helpers';

const data = [
  {
    name: 'fred',
    location: 'boston',
    date: 500,
    details: 'fred works at facebook',
  },
  {
    name: 'henry',
    location: 'london',
    date: 1000,
    details: 'henry does not like the color blue',
  },
  {
    name: 'lisa',
    location: 'los angeles',
    date: 2500,
    details: 'lisa only wears supreme',
  },
  {
    name: 'julia',
    location: 'austin',
    date: 2000,
    details: 'julia is likes her coffee with almond milk',
  },
];

const columns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Name',
  },
  {
    id: 'location',
    accessor: 'location',
    Header: 'Location',
    maxWidth: 240,
  },
  {
    id: 'date',
    accessor: 'date',
    Header: 'Date',
    maxWidth: 240,

    Cell: ({ value }) => value + 3000,
  },
];

const TableExample = () => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const switchState = () => setIsLoading(!isLoading);

  // Fake rotation and sorting of the data
  const pageData = data.slice(page % data.length).concat(data.slice(0, page % data.length));
  const sortedData = !sort.sortBy
    ? pageData
    : sort.sortDir === ASC
    ? sortBy(pageData, [sort.sortBy])
    : sortBy(pageData, [sort.sortBy]).reverse();

  return (
    <div>
      <Example label="Table with Row Expansion">
        <Table
          columns={columns}
          data={data}
          enableExpanded
          renderExpandableContent={({ row }) => <div>{row.original.details}</div>}
        />
      </Example>
      <Example label="Table with Custom Pagination and Sorting" style={{ marginTop: 32 }}>
        <code>
          {JSON.stringify({
            page,
            ...sort,
          })}
        </code>
        <Table
          columns={columns}
          data={sortedData}
          enableSorting
          enablePagination
          onSortChange={s => setSort(s)}
          onPageChange={p => setPage(p)}
          pagination={{
            totalCount: 1000,
          }}
        />
      </Example>
      <Example label="Loading and No Data state" style={{ marginTop: 32 }}>
        <Button onClick={switchState}>switch</Button>
        <Table
          columns={columns}
          data={[]}
          isLoading={isLoading}
          noDataText="There is no data available"
        />
      </Example>
    </div>
  );
};

export default TableExample;
