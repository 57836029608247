import React from 'react';
import injectSheet from 'react-jss';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';

import { SECONDARY, TEXT } from 'common/constants/colors';

const styles = {
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    fontSize: 13,

    '& span': {
      marginTop: 24,
      fontWeight: 300,
    },
  },
};

const LoadingIcon = ({
  description = null,
  color = SECONDARY,
  size = 40,
  textColor = TEXT,
  classes,
  className,
  style,
  thickness,
  ...rest
}) => (
  <div style={style} className={classnames(classes.main, className)}>
    <CircularProgress
      style={{ color }}
      size={size}
      thickness={thickness}
      data-testid="LoadingIcon-progress"
      {...rest}
    />
    {description && <span style={{ color: textColor }}>{description}</span>}
  </div>
);

export default injectSheet(styles)(LoadingIcon);
