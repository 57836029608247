import { createUseStyles } from 'react-jss';

import Button from 'common/components/base/Button';
import { BORDER_COLOR } from 'common/constants/colors';
import CircularLoader from 'common/components/base/CircularLoader';

const useStyles = createUseStyles(theme => ({
  main: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,
    height: theme.spacing(6),
    borderTop: `solid 1px ${BORDER_COLOR}`,
    padding: theme.spacing(1.5),
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const FormFooter = ({
  onClose,
  labels = { submit: 'Process', close: 'Cancel' },
  disabled = false,
  form,
  isLoading,
}) => {
  const classes = useStyles();
  return (
    <footer className={classes.main}>
      <Button
        variant="outlined"
        onClick={onClose}
        className={classes.button}
        disabled={isLoading}
        data-testid="Identity-cancel-button"
      >
        {isLoading ? <CircularLoader variant="indeterminate" /> : labels.close}
      </Button>
      <Button
        disabled={disabled || isLoading}
        form={form}
        type="submit"
        className={classes.button}
        data-testid="Identity-submit-button"
      >
        {labels.submit}
      </Button>
    </footer>
  );
};

export default FormFooter;
