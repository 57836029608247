import React from 'react';
import { createUseStyles } from 'react-jss';
import Slider from '@material-ui/core/Slider';
import Typography from 'components/Typography';
import RelativeProximityIndicator from 'components/IdentityPackage/RelativeProximityIndicator';

const useStyles = createUseStyles(theme => ({
  main: {
    margin: theme.spacing(1, 0, 3, 0),
  },
  markerLabels: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  relationShipMarker: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const strengthMarkers = [
  {
    value: 1,
    displayText: 'Strong',
    component: <RelativeProximityIndicator strength={1} />,
    align: 'left',
  },
  {
    value: 2,
    displayText: 'Moderate',
    component: <RelativeProximityIndicator strength={2} />,
    align: 'center',
  },
  {
    value: 3,
    displayText: 'Weak',
    component: <RelativeProximityIndicator strength={3} />,
    align: 'right',
  },
];

const StrengthSlider = props => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Slider
        aria-labelledby="discrete-slider-custom"
        step={null}
        marks={strengthMarkers}
        min={1}
        max={3}
        {...props}
      />
      <div className={classes.markerLabels}>
        {strengthMarkers.map(marker => {
          const strengthKey = `${marker.value} - ${marker.displayText}`;
          return (
            <div key={strengthKey} className={classes.relationShipMarker}>
              <Typography variant="subtitle2"> {marker.displayText} </Typography>
              <div style={{ textAlign: marker.align }}>{marker.component}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StrengthSlider;
