export const DESC_CONFIDENCE = 'desc(confidence)';
export const DESC_CONFIRMATION = 'desc(confirmation_ts)';

export const GRID_RESULTS = 'resultsGrid';
export const PANEL_RESULTS = 'resultsPanel';

export const CONFIRMED_CONFIDENCE_VALUE = 127;
export const MANUALLY_CONFIRMED_CONFIDENCE_VALUE = 126;

// For filtering out divs from image capture for capture scene
export const CAPTURE_SCENE_MINIMAP = 'capture_scene_minimap';
export const CAPTURE_SCENE_ZOOM = 'capture_scene_zoom';
export const CAPTURE_SCENE_TO_IGNORE = {
  [CAPTURE_SCENE_MINIMAP]: true,
  [CAPTURE_SCENE_ZOOM]: true,
};
