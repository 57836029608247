import { get } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';

import { selectPersonModels } from 'common/redux/models/selectors';
import { selectSearchRequest, selectSearchStatus } from 'analysis/redux/selectors';
import { frontendZones, hasSearchRequestLoaded } from 'analysis/redux/utils';
import { selectGeoFilterCoordinates } from 'plan/redux/selectors';
import buildSearchQuery from 'common/helpers/buildSearchQuery';
import getQueryStringFromIdentityRows from 'common/helpers/getQueryStringFromIdentityRows';

import { SEARCH_PENDING } from 'analysis/redux/constants';

export const selectSearchQueryEstimate = state => get(state, 'analysis.searchQuery.estimate');

export const selectSearchQueryFolders = state => get(state, 'analysis.searchQuery.folders');

export const selectSearchQueryDataSources = state => get(state, 'analysis.searchQuery.datasources');

export const selectSearchQuery = state => get(state, 'analysis.searchQuery');
export const selectSearchQueryQuery = state => get(state, 'analysis.searchQuery.query');
export const selectSearchQueryFolderIds = state => get(state, 'analysis.searchQuery.folderIds');
export const selectSearchQueryIdentityRows = state =>
  get(state, 'analysis.searchQuery.identityRows');
export const selectSearchQueryDataSourceIds = state =>
  get(state, 'analysis.searchQuery.datasourceIds');

export const selectSearchQueryStartDate = state =>
  get(state, 'analysis.searchQuery.filters.startDate');
export const selectSearchQueryEndDate = state => get(state, 'analysis.searchQuery.filters.endDate');
export const selectSearchQueryCameraIds = state => get(state, 'analysis.searchQuery.cameraIds');

export const selectZones = state => {
  const zones = get(state, 'analysis.searchQuery.searchRequest.filterArgs.zones');
  return frontendZones(zones);
};

export const selectSearchQueryTotalItemCount = state =>
  get(state, 'analysis.searchQuery.searchRequest.totalItemCount');

export const selectSearchRequestFilterArgs = state =>
  get(state, 'analysis.searchQuery.searchRequest.filterArgs');

export const selectMinLingerMillis = state =>
  get(state, 'analysis.searchQuery.searchRequest.filterArgs.lingerMs');

export const selectIsLoadingSearchRequest = state =>
  get(state, 'analysis.searchQuery.isLoadingSearchRequest');

export const selectIsLoadingDatasources = state =>
  get(state, 'analysis.searchQuery.isLoadingDatasources');

export const selectHasSearchRequestLoaded = createSelector(
  selectSearchRequest,
  hasSearchRequestLoaded
);

export const selectIsLoading = createSelector(
  selectHasSearchRequestLoaded,
  selectSearchStatus,
  selectIsLoadingSearchRequest,
  selectIsLoadingDatasources,
  (hasLoaded, searchStatus, isLoadingSearchRequest, isLoadingDatasources) =>
    !hasLoaded || searchStatus === SEARCH_PENDING || isLoadingSearchRequest || isLoadingDatasources
);

export const selectQueryStringFromIdentityRows = createSelector(
  selectSearchQueryIdentityRows,
  selectPersonModels,
  getQueryStringFromIdentityRows
);

export const selectSerializedSearchQueryParams = createStructuredSelector({
  ...selectSearchRequestFilterArgs,
  q: selectQueryStringFromIdentityRows,
  v: selectSearchQueryDataSourceIds,
  c: selectSearchQueryCameraIds,
  folders: selectSearchQueryFolderIds,
  st: selectSearchQueryStartDate,
  et: selectSearchQueryEndDate,
  zones: selectZones,
  minLingerMillis: selectMinLingerMillis,
  geofence: selectGeoFilterCoordinates,
});

export const selectSerializedSearchQuery = createSelector(
  selectSerializedSearchQueryParams,
  buildSearchQuery
);
