import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import { isNumber, throttle } from 'lodash';
import { toHHMMSS } from 'common/components/videoPlayer/utils/utils';
import { getBaseLog } from 'common/helpers/mathUtils';

import Tooltip from 'common/components/base/Tooltip';
import Typography from 'components/Typography';
import { SECONDARY, ACCENT } from 'common/constants/colors';
import emitter, { RESET_TIMELINE_ZOOM } from 'common/constants/emitter';

export const HANDLE_WIDTH = 6;
export const HANDLE_HEIGHT = 20;
const THROTTLE_TIME = 32;
const MIN_WIDTH = 320;
const TIMELINE_POINT_RATIO = 3;

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    userSelect: 'none',
    height: 'min-content',
    padding: '1px 2px',
    minWidth: MIN_WIDTH,
  },
  sliderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: `calc(100% - ${HANDLE_WIDTH}px)`,
    height: '100%',
    position: 'absolute',
    left: 0,
  },
  slider: {
    width: '100%',
    position: 'relative',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
  },
  handle: {
    position: 'absolute',
    borderRadius: 4,
    width: HANDLE_WIDTH,
    height: HANDLE_HEIGHT,
    background: SECONDARY,
    cursor: 'ew-resize',
    boxSizing: 'border-box',
    zIndex: 3,
  },
  label: {
    position: 'absolute',
    fontSize: 10,
    fontWeight: 500,
    color: SECONDARY,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    minHeight: HANDLE_HEIGHT,
  },
  timepointLabel: {
    composes: '$label',
    alignItems: 'flex-end',
  },
  top: {
    display: 'flex',
    alignItems: 'flex-end',
    height: HANDLE_HEIGHT,
    width: '100%',
    position: 'relative',
  },

  bottom: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    height: HANDLE_HEIGHT,
    width: '100%',

    /* the minor timeline should be slightly transparent for better ux */
    '& > span': {
      opacity: 0.6,
    },
  },
  middleUnit: {
    position: 'absolute',
    background: ACCENT,
    height: HANDLE_HEIGHT,
    opacity: 0.4,
    zIndex: 2,
    cursor: 'move',
  },
  timeElement: {
    top: 2,
    position: 'absolute',
    fontSize: 10,
    fontWeight: 500,
    color: SECONDARY,
  },
  timepointLine: {
    background: SECONDARY,
    width: 1,
    height: 12,
    marginRight: 3,
    position: 'relative',
    bottom: -3,
    alignSelf: 'flex-end',
  },
  timeDivisionLine: {
    alignSelf: 'flex-end',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
};

const NOOP = () => {};
const defaultBorderStyles = {
  opacity: 0.8,
  height: 1,
  margin: '1px 0',
  background: SECONDARY,
  width: '100%',
};

const DEFAULT_TIMELINE_PARTS = 4;

class Timeline extends React.Component {
  static defaultProps = {
    customClasses: {},
    customStyles: {},
    formatTimePoint: time => toHHMMSS(time / 1000),
    formatTimeDivision: time => toHHMMSS(time / 1000),
    initialBounds: { start: 0, end: 1 },
    onClickMinorTimeline: NOOP,
    renderBorder: () => <div style={defaultBorderStyles} />,
    showMajorTimeline: true,
    showMinorTimeline: true,
    showSlider: true,
    timeDivionsSpacing: 240,
    timePointSpacing: 240,
  };

  state = {
    isDraggingLeft: false,
    isDraggingMiddle: false,
    isDraggingRight: false,
    isOverLeft: false,
    isOverRight: false,
    leftHandlePercent: this.props.initialBounds.start,
    rightHandlePercent: this.props.initialBounds.end,
    timeDivionsParts: DEFAULT_TIMELINE_PARTS,
    timePointParts: DEFAULT_TIMELINE_PARTS,
  };

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.unbind = emitter.on(RESET_TIMELINE_ZOOM, this.initializeBounds);

    if (this.props.initialBounds) {
      this.initializeBounds();
    }
  }

  componentDidUpdate(prevProps) {
    const { initialBounds, resultId } = this.props;

    if (
      initialBounds &&
      (initialBounds.start !== prevProps.initialBounds?.start ||
        initialBounds.end !== prevProps.initialBounds?.end ||
        resultId !== prevProps.resultId)
    ) {
      this.initializeBounds();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.unbind();
  }

  majorTimeline = null;

  minorTimeline = null;

  wrapper = null;

  initializeBounds = () => {
    const { start, end } = this.props.initialBounds;
    this.setState({ leftHandlePercent: start, rightHandlePercent: end });
  };

  onResize = () => {
    const width = this.wrapper.clientWidth;
    const timeDivisionRatio = Math.floor((width - MIN_WIDTH) / this.props.timeDivionsSpacing);

    const timepointRatio = Math.floor((width - MIN_WIDTH) / this.props.timePointSpacing);

    /* There should always be a start and end time on the timeline */
    const timeDivionsParts = timeDivisionRatio < 0 ? 2 : 2 + timeDivisionRatio;
    const timePointParts = timepointRatio < 0 ? 2 : 2 + timepointRatio;

    if (
      timeDivionsParts !== this.state.timeDivionsParts ||
      timePointParts !== this.state.timePointParts
    ) {
      this.setState({ timeDivionsParts, timePointParts });
    }
  };

  setLeftHandle = num => this.setState({ leftHandlePercent: num });

  setRightHandle = num => this.setState({ rightHandlePercent: num });

  /* Left Handle */
  onLeftHandleMouseDown = event => {
    if (event.shiftKey) {
      return this.onMiddleUnitMouseDown(event);
    }

    this.setState({ isDraggingLeft: true });
    this._throttledLeftMouseDown = throttle(this.onLeftHandleMouseMove, THROTTLE_TIME);
    document.addEventListener('mousemove', this._throttledLeftMouseDown);
    document.addEventListener('mouseup', this.onDoneDraggingLeftHandle, {
      once: true,
    });
  };

  onDoneDraggingLeftHandle = () => {
    this.setState({ isDraggingLeft: false });
    document.removeEventListener('mousemove', this._throttledLeftMouseDown);
  };

  onLeftHandleMouseMove = event => {
    event.preventDefault();
    const { rightHandlePercent } = this.state;
    const wrapperBounds = this.wrapper.getBoundingClientRect();
    const distanceFromLeft = event.pageX - wrapperBounds.x - HANDLE_WIDTH / 2;
    const leftPercent = distanceFromLeft / wrapperBounds.width;

    if (leftPercent > 0 && rightHandlePercent > leftPercent) {
      this.setLeftHandle(leftPercent);
    } else if (leftPercent <= 0) {
      this.setLeftHandle(0);
    }
  };

  /* Right Handle */
  onRightHandleMouseDown = event => {
    if (event.shiftKey) {
      return this.onMiddleUnitMouseDown(event);
    }

    this.setState({ isDraggingRight: true });
    this._throttledRightMouseDown = throttle(this.onRightHandleMouseMove, THROTTLE_TIME);
    document.addEventListener('mousemove', this._throttledRightMouseDown);
    document.addEventListener('mouseup', this.onDoneDraggingRightHandle, {
      once: true,
    });
  };

  onDoneDraggingRightHandle = () => {
    this.setState({ isDraggingRight: false });
    document.removeEventListener('mousemove', this._throttledRightMouseDown);
  };

  onRightHandleMouseMove = event => {
    event.preventDefault();
    const { leftHandlePercent } = this.state;
    const wrapperBounds = this.wrapper.getBoundingClientRect();
    const distanceFromLeft = event.pageX - wrapperBounds.x - HANDLE_WIDTH / 2;
    const leftPercent = distanceFromLeft / wrapperBounds.width;

    if (leftPercent < 1 && leftHandlePercent < leftPercent) {
      this.setRightHandle(leftPercent);
    } else if (leftPercent >= 1) {
      this.setRightHandle(1);
    }
  };

  /* Middle Unit */
  onMiddleUnitMouseDown = event => {
    this.setState({ isDraggingMiddle: true });
    this._mousePositionMiddleUnit = event.pageX;
    this._throttledMiddleMouseDown = throttle(this.onMiddleUnitMove, THROTTLE_TIME);
    document.addEventListener('mousemove', this._throttledMiddleMouseDown);
    document.addEventListener('mouseup', this.onDoneDraggingMiddleUnit, {
      once: true,
    });
  };

  onDoneDraggingMiddleUnit = () => {
    this.setState({ isDraggingMiddle: false });
    document.removeEventListener('mousemove', this._throttledMiddleMouseDown);
  };

  onMiddleUnitMove = event => {
    event.preventDefault();
    const { leftHandlePercent, rightHandlePercent } = this.state;

    const prevMousePositionMiddleUnit = this._mousePositionMiddleUnit;
    const nextMousePositionMiddleUnit = event.pageX;
    this._mousePositionMiddleUnit = nextMousePositionMiddleUnit;

    const mouseDiff = nextMousePositionMiddleUnit - prevMousePositionMiddleUnit;
    const percentChange = mouseDiff / this.wrapper.clientWidth;
    const leftPercent = leftHandlePercent + percentChange;
    const rightPercent = rightHandlePercent + percentChange;

    if (leftPercent < 0) {
      this.setLeftHandle(0);
      return;
    }
    if (rightPercent > 1) {
      this.setRightHandle(1);
      return;
    }

    this.setLeftHandle(leftPercent);
    this.setRightHandle(rightPercent);
  };

  onMouseWheel = e => {
    const wrapper = e.currentTarget;
    const { width: wrapperWidth, x: wrapperLeft } = wrapper.getBoundingClientRect();
    const ratio = (e.pageX - wrapperLeft) / wrapperWidth;
    const { leftHandlePercent, rightHandlePercent } = this.state;

    const percentDiff = rightHandlePercent - leftHandlePercent;
    let delta;
    if (percentDiff < 0.1) {
      delta = e.deltaY < 0 ? percentDiff / 4 : -percentDiff / 4;
    } else {
      delta = e.deltaY < 0 ? 0.05 : -0.05;
    }

    const newLeftPercent = leftHandlePercent + delta * ratio;
    const newRightPercent = rightHandlePercent - delta * (1 - ratio);

    if (newLeftPercent < 0) {
      this.setLeftHandle(0);
    } else {
      this.setLeftHandle(newLeftPercent);
    }

    if (newRightPercent > 1) {
      this.setRightHandle(1);
    } else {
      this.setRightHandle(newRightPercent);
    }
  };

  onMouseDown = e => {
    const { leftHandlePercent, rightHandlePercent } = this.state;
    const { startTime, endTime, onSeek } = this.props;

    if (!onSeek) return;

    const duration = endTime - startTime;
    const timelineStart = leftHandlePercent * duration + startTime;
    const timelineEnd = rightHandlePercent * duration + startTime;

    this.isMouseDown = true;
    this.currentMouseTarget = e.currentTarget;

    this._onMouseMove = throttle(
      evt => this.onMouseMove(evt, timelineStart, timelineEnd),
      THROTTLE_TIME
    );

    this.onMouseMove(e, timelineStart, timelineEnd);

    document.addEventListener('mousemove', this._onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp, {
      once: true,
    });
  };

  onMouseMove = (e, timelineStart, timelineEnd) => {
    if (!this.isMouseDown) return;

    const wrapper = this.currentMouseTarget;
    const { width: wrapperWidth, x: wrapperLeft } = wrapper.getBoundingClientRect();

    const ratio = (e.pageX - wrapperLeft) / wrapperWidth;
    const timeRange = timelineEnd - timelineStart;
    const offset = ratio * timeRange;

    this.props.onSeek(offset + timelineStart);
  };

  onMouseUp = () => {
    this.isMouseDown = false;
    document.removeEventListener('mousemove', this._onMouseMove);
  };

  getTimeDivisions = () => {
    const { timeDivionsParts } = this.state;
    const { startTime, endTime } = this.props;
    const timeDivisions = [];

    if (isNumber(startTime) && isNumber(endTime)) {
      /*
        adjustedParts is needed to make timeDivionsParts more transparent to the developer, otherwise
        without adjustedParts, we will render 3 parts, even though timeDivionsParts is 2
      */
      const adjustedParts = timeDivionsParts - 1;
      const timeRange = (endTime - startTime) / adjustedParts;

      for (let i = 0; i <= adjustedParts; i++) {
        const time = startTime + timeRange * i;
        const style = {};
        if (i === adjustedParts) {
          style.right = 0;
        } else {
          style.left = `${(100 * i) / adjustedParts}%`;
        }

        timeDivisions.push({ time, style });
      }
    }

    return timeDivisions;
  };

  getTimelinePoints = (timelineStart, timelineEnd) => {
    const { interval, remainder, segmentDuration } = this.getTimelineReferenceInfo({
      timelineStart,
      timelineEnd,
      ratio: TIMELINE_POINT_RATIO,
    });

    const timepoints = [];

    let current = timelineStart - remainder;
    while (current < timelineEnd) {
      const style = {
        left: `${((current - timelineStart) / segmentDuration) * 100}%`,
      };

      timepoints.push({ time: current, style });
      current += interval;
    }

    return timepoints;
  };

  getTimelineReferenceInfo = ({ timelineStart, timelineEnd, ratio = 3 }) => {
    const { timePointParts } = this.state;
    const segmentDuration = timelineEnd - timelineStart;

    /* Safe coding, make sure nothing breaks */
    if (segmentDuration <= 1) return [];

    /* Zoom level is based on a logarithmic scale, the smaller the log, the more the "zoom level" will update */
    const zoomLevel = Math.ceil(getBaseLog(1.98, segmentDuration));

    /* render x number of times the number of timeline parts there are */
    const interval = 2 ** zoomLevel / (timePointParts * ratio);

    /*
      Calculating the remainder lets us define a absolute position where we will start the time,
      thereby letting us create a "sliding" effect as you update the slider range
    */
    const remainder = timelineStart === this.props.startTime ? 0 : timelineStart % interval;

    return { remainder, segmentDuration, interval };
  };

  renderTimePoint = ({ time, style }, i, arr) => {
    const { classes, customClasses, displayTimezoneName } = this.props;
    return (
      <span
        key={i}
        style={style}
        className={classnames(classes.timepointLabel, customClasses.timepointLabel)}
        role="img"
        data-testid="Timeline-timepointLabel"
      >
        <div className={classes.timepointLine} />
        {this.props.formatTimePoint(time, i, arr, displayTimezoneName)}
      </span>
    );
  };

  renderTimeDivision = ({ time, style }, i, arr) => {
    const { classes, customClasses, displayTimezoneName } = this.props;
    return (
      <span
        key={i}
        style={style}
        className={classnames(classes.label, customClasses.timeDivisionLabel)}
        role="img"
      >
        <div className={classnames(classes.timeDivisionLine, customClasses.timeDivisionLine)} />
        {this.props.formatTimeDivision(time, i, arr, displayTimezoneName)}
      </span>
    );
  };

  renderTimelineSlider = (timelineStart, timelineEnd) => {
    const { leftHandlePercent, rightHandlePercent } = this.state;
    const {
      classes,
      customClasses,
      customStyles,
      majorTimelineWidth,
      minorTimelineWidth,
      showMajorTimeline,
      showMinorTimeline,
      showSlider,
    } = this.props;

    const timeDivisions = this.getTimeDivisions();
    const timelinePoints = this.getTimelinePoints(timelineStart, timelineEnd);

    const leftStyle = {
      left: `${100 * leftHandlePercent}%`,
    };

    const rightStyle = {
      left: `calc(${100 * rightHandlePercent}%)`,
    };

    const middleStyle = {
      left: `calc(${HANDLE_WIDTH / 2}px + ${100 * leftHandlePercent}%)`,
      right: `calc(${-HANDLE_WIDTH / 2}px + ${100 * (1 - rightHandlePercent)}%)`,
    };

    return (
      <div
        className={classnames(classes.wrapper, customClasses.timeline)}
        style={customStyles.timeline}
      >
        <div
          className={classnames(classes.sliderWrapper, {
            // Hide this slider instead of removing it from DOM because there are events that
            // require this.wrapper to render
            [classes.hidden]: !showSlider,
          })}
        >
          <div
            className={classes.slider}
            style={{ width: majorTimelineWidth }}
            ref={node => (this.wrapper = node)}
          >
            <Tooltip
              onClose={() => this.setState({ isOverLeft: false })}
              open={
                this.state.isOverLeft || this.state.isDraggingLeft || this.state.isDraggingMiddle
              }
              onOpen={() => this.setState({ isOverLeft: true })}
              placement={this.state.isDraggingMiddle ? 'top' : 'bottom'}
              title={
                <Typography>
                  {this.props.formatTimeDivision(timelineStart, 0, timeDivisions)}
                </Typography>
              }
            >
              <span
                style={leftStyle}
                className={classes.handle}
                ref={node => (this.leftHandle = node)}
                onMouseDown={this.onLeftHandleMouseDown}
                role="img"
              />
            </Tooltip>
            <span
              style={middleStyle}
              className={classes.middleUnit}
              onMouseDown={this.onMiddleUnitMouseDown}
              role="img"
              data-testid="Timeline-middleUnit"
            />
            <Tooltip
              onClose={() => this.setState({ isOverRight: false })}
              open={
                this.state.isOverRight || this.state.isDraggingRight || this.state.isDraggingMiddle
              }
              onOpen={() => this.setState({ isOverRight: true })}
              title={
                <Typography>
                  {this.props.formatTimeDivision(
                    timelineEnd,
                    timeDivisions.length - 1,
                    timeDivisions
                  )}
                </Typography>
              }
            >
              <span
                style={rightStyle}
                className={classes.handle}
                ref={node => (this.rightHandle = node)}
                onMouseDown={this.onRightHandleMouseDown}
                role="img"
              />
            </Tooltip>
          </div>
        </div>
        <div className={classes.body}>
          {showMajorTimeline && (
            <div
              className={classnames(classes.top, customClasses.majorTimeline)}
              style={{ width: majorTimelineWidth }}
              ref={node => (this.majorTimeline = node)}
            >
              {timeDivisions.map(this.renderTimeDivision)}
            </div>
          )}
          {this.props.renderBorder({
            leftHandlePercent,
            rightHandlePercent,
            majorTimeline: this.majorTimeline,
            minorTimeline: this.minorTimeline,
          })}
          {showMinorTimeline && (
            <div
              className={classnames(classes.bottom, customClasses.minorTimeline)}
              ref={node => (this.minorTimeline = node)}
              role="none"
              style={{ width: minorTimelineWidth }}
              onMouseDown={this.onMouseDown}
            >
              {timelinePoints.map(this.renderTimePoint)}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { leftHandlePercent, rightHandlePercent } = this.state;
    const { className, children, startTime, endTime } = this.props;
    const duration = endTime - startTime;

    const timelineStart = leftHandlePercent * duration + startTime;
    const timelineEnd = rightHandlePercent * duration + startTime;

    return (
      <div className={className} data-testid="Timeline">
        {this.renderTimelineSlider(timelineStart, timelineEnd)}
        {children &&
          children({
            timelineStart,
            timelineEnd,
            leftHandlePercent,
            rightHandlePercent,
            setLeftHandle: this.setLeftHandle,
            setRightHandle: this.setRightHandle,
            onMouseWheel: this.onMouseWheel,
            onTimelineMouseDown: this.onMouseDown,
          })}
      </div>
    );
  }
}

export default injectSheet(styles)(Timeline);
