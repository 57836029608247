import cloneDeep from 'lodash/cloneDeep';
import { isEmpty } from 'lodash';
import { IDENTITY_TYPES } from 'common/constants/objects';

export default class Detection {
  constructor({
    id,
    bounds,
    type,
    confidence = 0.1,
    name = null,
    embedding = null,
    detectionType,
    isInspectedFrame,
    person = null,
    trackletId,
    key,
  }) {
    this._id = id;
    this._bounds = bounds;
    this._type = type;
    this._name = name;
    this._embedding = embedding;
    this._confidence = confidence;
    this._interpolated = false;
    this._detectionType = detectionType;
    this._isInspectedFrame = isInspectedFrame;
    this._person = person;
    this._trackletId = trackletId;
    this._key =
      key || `${detectionType}:${confidence}:${bounds?.x}:${bounds?.y}:${bounds?.w}:${bounds?.h}}`;
  }

  clone = () =>
    new Detection({
      id: this._id,
      type: this._type,
      name: this._name,
      confidence: this._confidence,
      /* clone all non primitives */
      bounds: cloneDeep(this._bounds),
      embedding: cloneDeep(this._embedding),
      detectionType: this._detectionType,
      isInspectedFrame: this._isInspectedFrame,
      person: this._person,
      trackletId: this._trackletId,
      key: this._key,
    });

  getKey = () => this._key;

  getId = () => this._id; // only identity based results have an id

  getBounds = () => this._bounds;

  getConfidence = () => this._confidence;

  setType = type => (this._type = type);

  getType = () => this._type;

  hasValidType = () => Number.isInteger(this._type);

  getName = () => this._name;

  getEmbedding = () => this._embedding;

  hasEmbeddings = () => !!this._embedding?.length;

  getInterpolated = () => this._interpolated;

  isInspectedFrame = () => this._isInspectedFrame;

  isIdentity = () => this._name && !this._name.startsWith(':');

  isIdentityType = () => IDENTITY_TYPES.includes(this._type);

  isConfirmedDetection = () => !isEmpty(this._person);

  setBounds = bounds => (this._bounds = bounds);

  setInterpolated = bool => (this._interpolated = bool);

  isActionable = () => (this.isIdentity() || this.isIdentityType()) && this.hasEmbeddings();

  isSame = (other = {}) => !!this.getKey && !!other.getKey && this.getKey() === other.getKey();
}
