import React from 'react';

import Icon from 'common/components/base/Icon';

const sortIconStyle = { fontSize: 15 };

/*
  Default header renderer for react-virtualized, except with custom sort icon:
  https://github.com/bvaughn/react-virtualized/blob/master/source/Table/defaultHeaderRenderer.js
*/
export default function defaultHeaderRenderer({ dataKey, label, sortBy, sortDirection }) {
  const showSortIndicator = sortBy === dataKey;

  const header = (
    <span className="ReactVirtualized__Table__headerTruncatedText" key="label" title={label}>
      {label}
    </span>
  );

  const children = [header];

  if (showSortIndicator) {
    children.push(
      <Icon
        key="SortIndicator"
        iconName={sortDirection === 'ASC' ? 'arrow_upward' : 'arrow_downward'}
        style={sortIconStyle}
      />
    );
  }

  return children;
}
