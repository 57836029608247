import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { styled } from 'react-jss';

import { isGoogleActive } from 'common/helpers/networkUtils';
import {
  buildLocationFromGooglePlace,
  buildDefaultLocation,
} from 'common/components/generalComponents/googleMaps/utils';
import TextInput from 'common/components/base/TextInput';

import './GoogleMaps.css';

const StyledTextInput = styled(TextInput)({
  maxWidth: '320px', // Wide enough to max out the video details editing dialog
});

const GoogleSearchBox = ({ defaultValue, onChange, onPlacesChanged, shouldDisable, ...rest }) => {
  const inputRef = useRef(null);
  const searchBox = useRef(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = defaultValue;
  }, [defaultValue]);

  const handleKeyPress = e => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) e.preventDefault();
  };

  const handleTextInputChange = e => {
    const location = buildDefaultLocation(e.target.value);

    if (onChange) onChange(location);
    if (onPlacesChanged) onPlacesChanged(location);
  };

  return isGoogleActive() ? (
    <StandaloneSearchBox
      ref={searchBox}
      onPlacesChanged={() => {
        if (onPlacesChanged) {
          const places = searchBox.current.getPlaces();
          const location = buildLocationFromGooglePlace(places[0], inputRef.current.value);
          onPlacesChanged(location);
        }
      }}
    >
      <TextInput
        InputProps={{ inputRef }}
        defaultValue={defaultValue}
        disabled={shouldDisable || false}
        onChange={handleTextInputChange}
        onKeyPress={handleKeyPress}
        {...rest}
      />
    </StandaloneSearchBox>
  ) : (
    <StyledTextInput
      inputRef={inputRef}
      type="text"
      onChange={() => {
        if (onPlacesChanged) {
          const location = buildDefaultLocation(inputRef.current.value);
          onPlacesChanged(location);
        }
      }}
      value={defaultValue}
      disabled={shouldDisable || false}
      helperText="In air gapped environments, location information will only be displayed on maps if the lat/long fields are populated."
      {...rest}
    />
  );
};

GoogleSearchBox.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  shouldDisable: PropTypes.bool,
  onPlacesChanged: PropTypes.func,
};

GoogleSearchBox.defaultProps = {
  placeholder: 'Enter a query',
};

export default GoogleSearchBox;
