export default class KeyFrame {
  constructor({ timestamp, vid, detections }) {
    this._detections = detections;
    this._timestamp = timestamp;
    this._vid = vid;
  }

  getTimestamp = () => this._timestamp;

  getDetections = () => this._detections;
}
