import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 99" color={color} {...rest}>
    <g>
      <path d="M8.427 94.565c0 2.769 1.337 4.157 4.003 4.157 2.674 0 4.018-1.388 4.018-4.157V57.127h4.112v37.438c0 2.769 1.344 4.157 4.018 4.157 2.674 0 4.018-1.388 4.018-4.157V30.78h1.959v22.97c0 1.454.491 2.514 1.474 3.171.976.658 1.98.658 3.035 0 1.04-.657 1.561-1.717 1.561-3.17l-.014-24.753c0-2.638-.89-4.683-2.645-6.137-1.756-1.454-4.206-2.185-7.343-2.185H10.566c-3 0-5.442.665-7.335 1.98-1.893 1.323-2.84 3.368-2.84 6.145v26.346c0 1.183.52 2.046 1.568 2.572 1.04.526 2.052.526 3.035 0 .976-.527 1.474-1.389 1.474-2.572V30.782h1.966v63.784l-.007-.001zM9.986 8.696c0 2.316.81 4.296 2.443 5.94 1.64 1.651 3.591 2.477 5.875 2.477 2.29 0 4.242-.826 5.883-2.477 1.626-1.651 2.442-3.668 2.442-6.042 0-2.243-.817-4.194-2.442-5.838C22.547 1.105 20.595.28 18.304.28c-2.276 0-4.235.826-5.875 2.477-1.626 1.651-2.443 3.631-2.443 5.94z" />
    </g>
  </SvgIcon>
);
