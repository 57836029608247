import React from 'react';
import { styled } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';

import { HEADER_HEIGHT, SIDEBAR_WIDTH, SIDEBAR_EXPANDED_WIDTH } from 'common/constants/app';

import Tooltip from 'common/components/base/Tooltip';
import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';
import MirageLogo from 'common/icons/mirage-logo';
import Typography from 'components/Typography';

import { selectIsNavExpanded } from 'app/redux/selectors';
import { toggleHamburgerMenu } from 'app/redux/actions';

import SidebarNavItem from './SidebarNavItem';

const MirageLogoWrapper = styled(MirageLogo)({
  position: 'absolute',
  zIndex: 1,
  left: 70,
  top: 11,
  color: 'white',
});

const HamburgerMenu = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: HEADER_HEIGHT,
  paddingLeft: 0,
  position: 'relative',
  width: SIDEBAR_EXPANDED_WIDTH,
  borderBottom: '1px solid white',
});

const Group = styled('div')(({ theme, index }) => ({
  '&:first-child': {
    boxShadow: 'none',
  },

  marginBottom: theme.spacing(4),
  borderTop: index === 0 ? 'none' : '1px solid white',
  overflow: 'hidden',
}));

const Title = styled(({ theme, isNavExpanded, ...rest }) => <Typography {...rest} />)(
  ({ theme, isNavExpanded }) => ({
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    padding: `0 ${theme.spacing(2.5)}px`,
    transitionDuration: '0.3s',
    whiteSpace: 'nowrap',
    height: isNavExpanded ? 32 : 0,
  })
);

const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: SIDEBAR_WIDTH,
});

const TopSidebar = ({ views }) => {
  const dispatch = useDispatch();
  const isNavExpanded = useSelector(selectIsNavExpanded);

  const onToggle = () => dispatch(toggleHamburgerMenu());

  return (
    <div>
      <HamburgerMenu>
        <IconContainer>
          <Tooltip title={isNavExpanded ? 'Collapse' : 'Expand'} placement="right">
            <div>
              <IconButton theme="white" onClick={onToggle} data-testid="Header-expandLeftMenu">
                <Icon iconName="menu" />
              </IconButton>
            </div>
          </Tooltip>
        </IconContainer>
        <MirageLogoWrapper />
      </HamburgerMenu>

      {views.map(({ groupTitle, navItems }, i) => (
        <Group key={`Sidebar:${groupTitle}`} index={i}>
          <Title isNavExpanded={isNavExpanded} variant="h6">
            {groupTitle}
          </Title>
          {navItems?.map(props => (
            <SidebarNavItem key={`sidebarNavItem:${props.label}`} {...props} />
          ))}
        </Group>
      ))}
    </div>
  );
};

export default TopSidebar;
