import { useEffect, useState } from 'react';
import DateTime from 'dateTime';
import { InputAdornment, TextField } from '@material-ui/core';

import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';

const DATE_FORMAT = 'dd LLL yyyy, HH:mm:ss';

function DateInput(props) {
  const { setDate, label, onBlur, placeholder, date } = props;

  const [dateString, setDateString] = useState('');

  // only update the dateString to match the date if the parsed date is valid
  useEffect(() => {
    if (!date?.isValid) return;
    setDateString(date?.toFormat(DATE_FORMAT) || '');
  }, [date]);

  return (
    <TextField
      placeholder={placeholder || '01 Jan 2000, 00:00:00'}
      error={!(date?.isValid || dateString === '')}
      variant="outlined"
      label={label}
      size="small"
      type="text"
      onBlur={onBlur}
      value={dateString}
      onChange={event => {
        const newDateString = event.target.value;
        const parsedDate = DateTime.fromFormat(newDateString, DATE_FORMAT);
        setDate(parsedDate);
        setDateString(newDateString);
      }}
      InputProps={{
        endAdornment: !!dateString && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setDateString('');
              }}
              disabled={!dateString}
              size="small"
              style={{ order: 1 }}
            >
              <Icon iconName="highlight_off" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default DateInput;
