import React from 'react';
import injectSheet from 'react-jss';

import Dialog from '@material-ui/core/Dialog';
import Icon from 'common/components/base/Icon';
import Button from 'common/components/base/Button';
import { MAX_EMBEDDINGS_PER_PERSON } from 'common/constants/app';
import { WARNING_COLOR } from 'common/constants/colors';

const styles = {
  main: {
    width: 368,
    padding: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  icon: {
    color: WARNING_COLOR,
    marginRight: 8,
  },
  group: {
    fontWeight: 300,
    margin: '0 0 16px 24px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const PersonEmbeddingWarning = ({ classes, onClose, open, person }) => {
  const message = person.name
    ? `Please remove images from '${person.name}' in the identity library in order to add or confirm new images.`
    : `Please remove some images in order to create this identity.`;

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.main }}>
      <header className={classes.header}>
        <Icon className={classes.icon} iconName="warning" />
        <h4>System Limit Reached: Images in Identity</h4>
      </header>

      <div className={classes.group} data-testid="PersonEmbeddingWarning-message">
        Mirage supports up to {MAX_EMBEDDINGS_PER_PERSON} images in a single identity for this
        hardware configuration.
        {message}
      </div>
      <div className={classes.group}>
        Tip: Having a variety of images for the identity produces the best results.
      </div>

      <footer className={classes.footer}>
        <Button style={{ marginRight: 8 }} onClick={onClose}>
          DISMISS
        </Button>
      </footer>
    </Dialog>
  );
};

PersonEmbeddingWarning.defaultProps = {
  person: {},
};

export default injectSheet(styles)(PersonEmbeddingWarning);
