export const ERROR = 'error';
export const NOTIFICATION = 'notification';
export const LIBRARY = 'library';
export const MOVE = 'move';
export const COPY = 'copy';
export const WARNING = 'warning';
export const INFO = 'info';
export const CONFIRM_DELETE = 'Confirm Delete';
export const PROCEED = 'Proceed';
export const CANCEL = 'Cancel';
export const CONFIRM_REMOVE_DELETE = 'Confirm Remove & Delete';
