import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import Dialog from '@material-ui/core/Dialog';

import { TEXT } from 'common/constants/colors';

import Icon from 'common/components/base/Icon';
import Button from 'common/components/base/Button';
import Report from 'common/icons/report';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 28,
    color: TEXT,
    fontSize: 11,
    fontStyle: 'italic',
    fontFamily: 'Helvetica, sans-serif',
    width: 400,
    textAlign: 'center',
    lineHeight: '18px',
  },
  body: {
    margin: '20px 0',
  },
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
    cursor: 'pointer',
  },
};

const SceneSavedPopover = props => {
  const { open, onClose, classes } = props;

  return (
    <Dialog onClose={onClose} open={open} data-testid="SceneSavedPopover">
      <article className={classes.main}>
        <Icon onClick={onClose} iconName="close" className={classes.icon} />
        <Report style={{ color: '#c7cfdc', width: 96 }} />
        <div className={classes.body}>
          <p>Your scene was saved successfully.</p>
          <br />
          <p>
            Please navigate to the&nbsp;
            <Link to="/reports">Reports</Link>
          </p>
          <p>page to review.</p>
        </div>
        <Button style={{ width: 120 }} onClick={onClose} data-testid="DismissPopover">
          DISMISS
        </Button>
      </article>
    </Dialog>
  );
};

export default injectSheet(styles)(SceneSavedPopover);
