import React from 'react';
import { createUseStyles } from 'react-jss';
import Card from '@material-ui/core/Card';
import usePageTitle from 'common/hooks/usePageTitle';

import { PRIMARY } from 'common/constants/colors';
import MirageLogo from 'common/icons/mirage-logo';
import { LOGIN } from 'common/constants/pageTitles';

import USGWarningAndConsent from './USGWarningAndConsent';
import { LoginProvider } from './LoginContext';
import SignInForm from './SignInForm';

const useStyles = createUseStyles(theme => ({
  main: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/login_background.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  content: {
    width: theme.spacing(40),
    background: 'white',
  },
  header: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    background: PRIMARY,
    color: 'white',
  },
  logo: {
    color: 'white',
    height: 29,
    width: 74,
  },
}));

const Auth = () => {
  const classes = useStyles();
  usePageTitle(LOGIN);

  return (
    <LoginProvider>
      <div className={classes.main}>
        <Card raised className={classes.content}>
          <header className={classes.header}>
            <MirageLogo className={classes.logo} />
          </header>
          <SignInForm />
        </Card>
      </div>
      <USGWarningAndConsent />
    </LoginProvider>
  );
};

export default Auth;
