import { zeroPad } from 'common/helpers/helperFunctions';
import { capitalize, isNumber } from 'lodash';
import pluralize from 'pluralize';

pluralize.addPluralRule('person', 'persons');

export function isOnlyDigits(string) {
  return /^[0-9]*$/gm.test(string);
}

export function isOnlyLetters(string) {
  return /^[A-Za-z]*$/gm.test(string);
}

export function formatNumberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/* formatting for geospatial lat/lngs */
export function formatLat(coord, useDMS) {
  if (useDMS) {
    const cardinalLabel = coord < 0 ? 'S' : 'N';
    return `${formatCoord(coord)}${cardinalLabel}`;
  }
  return coord.toFixed(7);
}

export function formatLng(coord, useDMS) {
  if (useDMS) {
    const cardinalLabel = coord < 0 ? 'W' : 'E';
    return `${formatCoord(coord)}${cardinalLabel}`;
  }
  return coord.toFixed(7);
}

function formatCoord(coord) {
  coord = Math.abs(coord);

  const degrees = Math.floor(coord);
  const minutes = Math.floor((coord - degrees) * 60);
  const seconds = parseFloat(Math.round(((coord - degrees) * 60 - minutes) * 60 * 10) / 10).toFixed(
    1
  ); // ensures one decimal place even if 0, so 1.0

  const paddedSeconds = seconds.length === 3 ? `0${seconds}` : seconds;

  return `${zeroPad(degrees, 2)}°${zeroPad(minutes, 2)}'${paddedSeconds}"`;
}

/* converts numbers to letter format, e.g. 0 === 'A', 26 === 'AA', 29 === 'AD' */
export function getLetterFromNumber(idx) {
  let num = idx + 1;
  let result = '';

  /* ASCII value for 'A' */
  const A_CHAR = 65;

  while (num > 0) {
    num -= 1;
    result = String.fromCharCode(A_CHAR + (num % 26)) + result;
    num = Math.floor(num / 26);
  }

  return result;
}

const FILE_SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

/* return n if it is not a number */
export const toInteger = n => {
  const integer = Number(n);
  return !Number.isNaN(integer) ? integer : n;
};

export const intOrNull = str => {
  const maybeNumber = toInteger(str);
  return isNumber(maybeNumber) ? maybeNumber : null;
};

export function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const level = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = (bytes / k ** level).toFixed(decimals);
  return `${size} ${FILE_SIZES[level]}`;
}

export function replaceRange(s, start, end, substitute) {
  return s.substr(0, start) + substitute + s.substr(end);
}

export function conditionalPluralize(strings, ...args) {
  /**
    For conditionalPluralize to work, there must be an argument with property
    'count' that is a number, and an argument with property 'word' that is a string.
  */
  const countIndex = args.findIndex(arg => arg.count !== undefined);
  const wordIndex = args.findIndex(arg => arg.word !== undefined);

  const { count } = args[countIndex] || {};
  let { word } = args[wordIndex] || {};

  if (count !== 1 && word) {
    word = pluralize(word);
  }

  return strings.reduce((result, string, i) => {
    result += strings[i];

    if (i === wordIndex) {
      result += word;
    } else if (i === countIndex && countIndex !== wordIndex) {
      result += String(count);
    } else if (args[i] !== undefined) {
      result += args[i];
    }

    return result;
  }, '');
}

/* currently only supports snake case */
export function humanize(str) {
  const words = str.split('_');
  return words.map(word => capitalize(word)).join(' ');
}

export function removePeriod(str) {
  return str[str.length - 1] === '.' ? str.slice(0, -1) : str;
}

export const getFolderCountString = count => (count > 0 ? `(${count.toLocaleString()})` : '- - ');
