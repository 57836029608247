import { FMV, ISR } from 'common/constants/app';
import {
  CYAN_BLUE,
  LIGHT_RED,
  LIVE as LIVE_COLOR,
  MEDIUM_GREY,
  TEXT,
} from 'common/constants/colors';

export const LIVE = 'live';
export const RECORDING = 'recording';
export const AVAILABLE = 'available';
export const OFFLINE = 'offline';
export const STARTING = 'starting';
export const STARTED = 'started';
export const STOPPING = 'stopping';

export const LINKED = 'linked';
export const UNLINKED = 'unlinked';

export const MACULA_OFFLINE = 'macula_offline';
export const RESTART_MACULA = 'restart_macula';
export const SLOT_UNAVAILABLE = 'slot_unavailable';

export const START_RECORDING = 'start_recording';
export const STOP_RECORDING = 'stop_recording';

export const VIDEO_CAMERA = 'videocam';
export const VIDEO_CAMERA_OFF = 'videocam_off';

export const CAMERA_ICONS = {
  [RECORDING]: VIDEO_CAMERA,
  [AVAILABLE]: VIDEO_CAMERA,
  [OFFLINE]: VIDEO_CAMERA_OFF,
  [STARTING]: VIDEO_CAMERA,
  [STARTED]: VIDEO_CAMERA,
  [STOPPING]: VIDEO_CAMERA_OFF,
  [LINKED]: VIDEO_CAMERA,
  [UNLINKED]: VIDEO_CAMERA_OFF,
  [MACULA_OFFLINE]: VIDEO_CAMERA_OFF,
  [RESTART_MACULA]: VIDEO_CAMERA_OFF,
  [SLOT_UNAVAILABLE]: VIDEO_CAMERA_OFF,
  [null]: VIDEO_CAMERA_OFF,
};

export const ICON_TO_TEXT = {
  [FMV]: 'FMV',
  [ISR]: 'ISR',
  [LIVE]: 'LIVE',
  [RECORDING]: 'Live',
  [AVAILABLE]: 'Available',
  [OFFLINE]: 'Offline',
  [STARTING]: 'Starting',
  [STARTED]: 'Started',
  [STOPPING]: 'Stopping',
  [LINKED]: 'Acquiring Resources',
  [UNLINKED]: 'Unlinked',
  [MACULA_OFFLINE]: 'Live Processing Offline',
  [RESTART_MACULA]: 'Live Processing Connection Failed',
  [SLOT_UNAVAILABLE]: 'Slot Unavailable',
};

export const CAMERA_STYLES = {
  status: {
    textTransform: 'capitalize',
  },
  recording: {
    color: LIVE_COLOR,
  },
  recordingLiveNG: {
    color: CYAN_BLUE,
  },
  available: {
    color: TEXT,
  },
  offline: {
    color: TEXT,
  },
  starting: {
    color: TEXT,
  },
  started: {
    color: TEXT,
  },
  stopping: {
    color: TEXT,
  },
  linked: {
    color: TEXT,
  },
  unlinked: {
    color: TEXT,
  },
  maculaOffline: {
    color: TEXT,
  },
  restartMacula: {
    color: LIGHT_RED,
  },
  icon: {
    color: MEDIUM_GREY,
  },
};
