import React from 'react';
import isNumber from 'lodash/isNumber';
import padStart from 'lodash/padStart';
import throttle from 'lodash/throttle';

import { THRESHOLD_DATE } from 'common/constants/times';
import { parseToShorthandDate } from 'common/helpers/dateUtils';

const dateStyles = {
  position: 'absolute',
  left: 38,
  width: 'max-content',
  color: 'white',
  fontWeight: 500,
  fontFamily: 'Roboto',
  fontSize: 16,

  /*
    We do not want to block the user from clicking the detection boxes. If the user wishes to copy
    the date, they can click the calendar icon instead
  */
  pointerEvents: 'none',

  /* won't work on all browsers, but stroke is an optional feature to help with text clarity anyway */
  WebkitTextStrokeWidth: 1,
  WebkitTextStrokeColor: 'black',
};

function getRelativeTimestampString(time) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = Math.floor(time - hours * 3600 - minutes * 60);

  return (
    `+ ${padStart(hours, 2, '0')}` +
    `:${padStart(minutes, 2, '0')}` +
    `:${padStart(seconds, 2, '0')}`
  );
}

class DateOverlay extends React.Component {
  date = 0;

  setTime = seconds => this._throttledSetTime(seconds);

  getDate = () => this.date;

  _setTime = seconds => {
    if (this.overlay) {
      const text = isNumber(seconds) ? this._getTimestampString(seconds) : '';
      this.overlay.innerHTML = text;
      this.date = this._getAbsoluteTime(seconds);
    }
  };

  _throttledSetTime = throttle(this._setTime, 100);

  _getAbsoluteTime = seconds => {
    const { date } = this.props;
    return date > THRESHOLD_DATE ? seconds * 1000 + date : seconds * 1000;
  };

  _getTimestampString = seconds => {
    const { date, displayTimezoneName } = this.props;

    if (date > THRESHOLD_DATE) {
      const timestamp = seconds * 1000 + date;

      return parseToShorthandDate(timestamp, {
        showDayOfWeek: true,
        showSeconds: true,
        showTimeZone: true,
        displayTimezoneName,
        // showMilliseconds: true, // Uncomment to debug
      });
    }

    return getRelativeTimestampString(seconds);
  };

  render() {
    return (
      <span data-testid="DateOverlay" ref={node => (this.overlay = node)} style={dateStyles} />
    );
  }
}

export default DateOverlay;
