import OverflowText from 'common/components/base/OverflowText';
import { Example } from '../helpers';

const OverflowTextExample = () => (
  <>
    <Example>
      <OverflowText width={100}>Some text that will not fit within the given width</OverflowText>
    </Example>

    <Example>
      <OverflowText width={250}>Some text that DOES fit in the given width</OverflowText>
    </Example>

    <Example>
      <OverflowText tooltip="🐈 If it fits... I sits 🐈" width={100}>
        Showing a custom tooltip
      </OverflowText>
    </Example>

    <Example>
      <OverflowText showTooltip width={250}>
        Shows the tooltip even though the text fits
      </OverflowText>
    </Example>
  </>
);

export default OverflowTextExample;
