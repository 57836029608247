import axios from 'axios.js';
import { ANALYTICS_BACKEND } from 'common/constants/urls';

export const getFolders = (params = {}) =>
  axios.get(`${ANALYTICS_BACKEND}/folder/`, { params }).then(({ data }) => data);

export const postFolder = folder =>
  axios.post(`${ANALYTICS_BACKEND}/folder/`, folder).then(({ data }) => data);

export const getSingleFolderModel = id =>
  axios.get(`${ANALYTICS_BACKEND}/folder/${id}/`).then(({ data }) => data);

export const patchFolder = (id, body) =>
  axios.patch(`${ANALYTICS_BACKEND}/folder/${id}/`, body).then(({ data }) => data);

export const deleteFolder = id => axios.delete(`${ANALYTICS_BACKEND}/folder/${id}/`).then(() => id);

export const getZoneableDsFromFolder = ({ id, ...params }) =>
  axios.get(`${ANALYTICS_BACKEND}/folder/${id}/zoneable-ds`, { params }).then(({ data }) => data);

export const postFolderItem = body =>
  axios.post(`${ANALYTICS_BACKEND}/folder/item/`, body).then(({ data }) => data);

export const patchFolderItem = (id, body) =>
  axios.patch(`${ANALYTICS_BACKEND}/folder/item/${id}/`, body).then(({ data }) => data);

export const deleteFolderItem = id =>
  axios.delete(`${ANALYTICS_BACKEND}/folder/item/${id}/`).then(() => id);
