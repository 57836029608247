import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableFooter from '@material-ui/core/TableFooter';

import { useTable, useFlexLayout, useExpanded, usePagination, useSortBy } from 'react-table';
import Typography from 'components/Typography';

import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import TablePaginationActions from './TablePaginationActions';
import TableRow from './TableRow';
import TableHeadGroup from './TableHeadGroup';
import useStyles from './styles';

export const DESC = 'DESC';
export const ASC = 'ASC';
const PAGE_SIZE_OPTIONS = [50, 100];

const useTableContainerStyles = createUseStyles({
  root: {
    height: '100%',
    overflowX: 'hidden',
  },
});

/**
 * @desc react-table v7 table that should handle almost all Mirage's needs when rendering tables
 *
 * @prop {<Object>} data -- data that will be rendered
 * @prop {<Object>} columns -- column properties that will determine how the data is rendered
 *
 * @prop {Func} renderExpandableContent -- determines how row expansion will render if enabled
 * @prop {Func} onPageChange -- callback when the page changes
 * @prop {Func} onPageSizeChange -- callback when page size changes
 * @prop {Func} onSortChange -- callback when the sort has changed
 * @prop {Object} initialState -- determines initial state for the table
 * @prop {Object} pagination -- determines default pagination state for the table
 *   @property {Number} pageSize -- size of the page
 *   @property {Number} totalCount -- total number of items (required if pagination is manual)
 *   @property {Number} page -- the page the table starts at
 *
 * @prop {<Number>} pageSizeOptions -- determins which page sizes can be chosen
 * @prop {<Func>} plugins -- array of hooks that must be imported from react-table
 * @prop {Object} options -- other react-table options that determine the state of the table
 *
 * @prop {<Func>} disableMultiSort -- disables multi sort, defaults to true
 * @prop {<Func>} manualSortBy -- enables sorting programatically from outside the component, defaults to true
 * @prop {<Func>} manualPagination -- enables pagination programatically from outside the component, defaults to true
 *
 * @prop {String} size -- determines how "dense" the table will look
 * @prop {Boolean} enableExpanded -- enables row expansion
 * @prop {Boolean} enablePagination -- enables pagination
 * @prop {Boolean} enableSorting -- enables sorting
 * @prop {Boolean} hideHeaders -- hides headers
 * @prop {Boolean} stripeTable -- adds striping to the table
 * @prop {Boolean} addPaddingLeft -- adds extra padding on the left
 */
const Table = ({
  addPaddingLeft = false,
  customClasses = {},
  columns,
  data,
  enableExpanded,
  enablePagination,
  enableSorting,
  hideHeaders = false,
  hideRowBorders = false,
  initialState,
  isLoading = false,
  noDataText = '',
  renderExpandableContent,
  stripeTable = false,
  size = 'small',

  // Manual Pagination Props
  defaultSortBy = [],
  onPageChange,
  onPageSizeChange,
  onSortChange,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  pagination: paginationFromProps = {},

  // Other Options
  disableMultiSort = true,
  manualSortBy = true,
  manualPagination = true,
  options = {},
  plugins: pluginsFromProps = [],
}) => {
  const classes = useStyles();
  const plugins = [useFlexLayout, ...pluginsFromProps];

  const pagination = {
    pageSize: pageSizeOptions[0],
    totalCount: 0,
    page: 0,
    ...paginationFromProps,
  };

  if (enableSorting) plugins.push(useSortBy);
  if (enableExpanded) plugins.push(useExpanded);
  if (enablePagination) plugins.push(usePagination);

  const {
    getTableProps,
    gotoPage,
    headerGroups,
    rows,
    page,
    prepareRow,
    setPageSize,
    state,
  } = useTable(
    {
      columns,
      data,
      disableMultiSort,
      manualSortBy,
      manualPagination,
      initialState: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.page,
        sortBy: defaultSortBy,
        ...initialState,
      },
      pageCount:
        pagination.totalCount && pagination.pageSize
          ? Math.ceil(pagination.totalCount / pagination.pageSize)
          : -1,
      ...options,
    },
    ...plugins
  );

  const handlePageChange = (e, newPage) => {
    gotoPage(newPage);
    if (onPageChange) onPageChange(newPage);
  };

  const handleRowsPerPageChange = e => {
    setPageSize(e.target.value);
    if (onPageSizeChange) onPageSizeChange(e.target.value);
  };

  useEffect(() => {
    // We currently do not support multi-sorting yet
    if (onSortChange) {
      const sort = state.sortBy?.[0];
      onSortChange({
        sortBy: sort ? sort.id : undefined,
        sortDir: !sort || sort.desc === undefined ? undefined : sort.desc ? DESC : ASC,
      });
    }
  }, [state.sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableContainer classes={useTableContainerStyles()}>
      <MuiTable className={classnames(customClasses.tableRoot, classes.table)} {...getTableProps()}>
        {!hideHeaders && (
          <MuiTableHead className={classnames(classes.tableHead, customClasses.tableHead)}>
            {headerGroups.map(headerGroup => (
              <TableHeadGroup
                columns={columns}
                customClasses={customClasses}
                addPaddingLeft={addPaddingLeft}
                enableExpanded={enableExpanded}
                enableSorting={enableSorting}
                headers={headerGroup.headers}
                {...headerGroup.getHeaderGroupProps()}
              />
            ))}
          </MuiTableHead>
        )}
        <MuiTableBody className={classnames(classes.body, { [classes.stripedTable]: stripeTable })}>
          {isLoading && (
            <MuiTableRow>
              <td>
                <LoadingOverlay />
              </td>
            </MuiTableRow>
          )}
          {!(manualPagination ? rows : page).length && noDataText ? (
            <MuiTableRow className={classes.emptyRow} size={size}>
              <td>{!isLoading && <Typography variant="body1">{noDataText}</Typography>}</td>
            </MuiTableRow>
          ) : (
            (manualPagination ? rows : page).map((row, index) => (
              <TableRow
                key={row.original.id || index}
                addPaddingLeft={addPaddingLeft}
                columns={columns}
                customClasses={customClasses}
                enableExpanded={enableExpanded}
                hideRowBorders={hideRowBorders}
                prepareRow={prepareRow}
                renderExpandableContent={renderExpandableContent}
                row={row}
                size={size}
              />
            ))
          )}
        </MuiTableBody>
        {enablePagination && (
          <MuiTableFooter className={classes.footer}>
            <MuiTableRow>
              <TablePagination
                count={pagination.totalCount}
                rowsPerPage={state.pageSize}
                rowsPerPageOptions={pageSizeOptions}
                page={state.pageIndex}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                ActionsComponent={TablePaginationActions}
              />
            </MuiTableRow>
          </MuiTableFooter>
        )}
      </MuiTable>
    </TableContainer>
  );
};

Table.SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

Table.propTypes = {
  addPaddingLeft: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disableMultiSort: PropTypes.bool,
  enableExpanded: PropTypes.bool,
  enablePagination: PropTypes.bool,
  enableSorting: PropTypes.bool,
  initialState: PropTypes.object,
  isLoading: PropTypes.bool,
  manualPagination: PropTypes.bool,
  manualSortBy: PropTypes.bool,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortChange: PropTypes.func,
  options: PropTypes.object,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  pagination: PropTypes.shape({
    totalCount: PropTypes.number,
  }),
  plugins: PropTypes.arrayOf(PropTypes.func),
  renderExpandableContent: PropTypes.func,
  size: PropTypes.oneOf([Table.SIZES.SMALL, Table.SIZES.MEDIUM]),
  stripeTable: PropTypes.bool,
};

export default Table;
