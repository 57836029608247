import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="80" viewBox="0 0 70 100">
    <g fill="none" fillRule="evenodd">
      <path d="M-29-10H99v128H-29z" />
      <path
        fill="#659ADE"
        d="M62.762 0H6.25C2.812 0 0 2.813 0 6.25V100h25V81.25h18.75V100h25.262V6.25c0-3.438-2.813-6.25-6.25-6.25zM12.5 68.75H6.25v-12.5h6.25v12.5zm0-18.75H6.25V37.5h6.25V50zm0-18.75H6.25v-12.5h6.25v12.5zM25 68.75h-6.25v-12.5H25v12.5zM25 50h-6.25V37.5H25V50zm0-18.75h-6.25v-12.5H25v12.5zm12.5 37.5h-6.25v-12.5h6.25v12.5zm0-18.75h-6.25V37.5h6.25V50zm0-18.75h-6.25v-12.5h6.25v12.5zM50 68.75h-6.25v-12.5H50v12.5zM50 50h-6.25V37.5H50V50zm0-18.75h-6.25v-12.5H50v12.5zm12.5 37.5h-6.25v-12.5h6.25v12.5zm0-18.75h-6.25V37.5h6.25V50zm0-18.75h-6.25v-12.5h6.25v12.5z"
      />
    </g>
  </svg>
);
