import { CREATED, UPDATED, sortByCreated, sortByUpdated } from 'common/constants/sortFunctions';

export const CAMERA_MANAGEMENT = 'camera-management';
export const DEMO_FEATURES = 'demo-features';
export const BETA_FEATURES = 'beta-features';
export const LOCAL_SETTINGS = 'local-settings';
export const MIRAGE_SETTINGS = 'mirage-settings';
export const CHANGE_PASSWORD = 'password';
export const VENUE_MAP_MANAGEMENT = 'venue-map-management';
export const SYSTEM_SETTINGS = 'system-settings';
export const DATASHIFT = 'datashift';
export const GEO_DASHBOARD_SETTINGS = 'geo-dashboard-settings';
export const USER_PROFILE = 'profile';

export const NAME = 'mapName';

export const DEFAULT_BANNER_SETTINGS = {};

// Coordinate system types
export const DMS = 'dms';
export const LAT_LNG = 'dd';
export const MGRS = 'mgrs';
export const GCS_ROTATIONS = [LAT_LNG, DMS, MGRS];

// BE Feature Constants
export const FEATURE_ADDITIONAL_OBJECTS = 'feature.additional_objects_in_live_monitoring';
export const FEATURE_COLOR_DETECTION = 'feature.color_detection';
export const FEATURE_CAN_ACCESS_ALL_SEARCH_QUERIES =
  'feature.show_all_search_queries_in_analysis_queue';
export const FEATURE_TILESERVER = 'feature.tileserver_url';

export const GEO_CUSTOM_TILE_SERVER = 'geo.map_tile_url';
export const GEO_OSM_GEOCODER_SERVER = 'geo.osm_geocoder_url';
export const GEO_DATASHIFT_REPO = 'geo.datalake_url';
export const GEO_DATASHIFT_NAMESPACE = 'geo.export_namespace';

export const GEO_MISSIONS_FILTER_SETTING = 'geo.missionsFilter';

export const PROFILE_USER_TIME_ZONE = 'profile.customUserTimezone';
export const PROFILE_USER_TIME_DISPLAY = 'profile.timeDisplayMethod';
export const PROFILE_BULK_UPDATE_LIBRARY_ITEMS_IGNORE_CONFIRM_UNTIL =
  'profile.bulkUpdateLibraryItemsIgnoreConfirmUntil';
export const DISABLE_BULK_SUBMIT_WARNING = 'profile.disableBulkSubmitWarning';

export function sortByMapName(a, b) {
  return a.mapName.localeCompare(b.mapName);
}

export const venueMapSortFunctions = {
  [NAME]: sortByMapName,
  [CREATED]: sortByCreated,
  [UPDATED]: sortByUpdated,
};

/* Special Settings */
export const CUSTOMER_MAPS_API_KEY = 'CUSTOMER_MAPS_API_KEY';

/* Local/User features and settings */
export const ENABLE_GEO_ALERT_SHARING = 'enable_geo_alert_sharing';
export const ENABLE_GEO_ALERTING = 'enable_geo_alerting';
export const ENABLE_GEO_EMAIL_NOTIFICATION = 'enable_geo_email_notification';
export const SHOW_GEO_ANALYSIS_RESULTS_CONFIDENCE = 'show_geo_analysis_results_confidence';
export const ENABLE_KINETIC_SEEK = 'enable_kinetic_seek';
export const ENABLE_SEARCHLIGHT = 'enable_searchlight';
export const ENABLE_BULK_FACE_EXTRACTION = 'enable_bulk_face_extraction';
export const ENABLE_MANUAL_IDENTITY_DRAWING = 'enable_manual_identity_drawing';
export const ENABLE_FETCH_ALL_DETECTIONS_IN_INSPECT_MODE =
  'enable_fetch_all_detections_in_inspect_mode';
export const ENABLE_BBOX_POINT_VISUALIZATION = 'enable_bbox_point_visualization';
export const ENABLE_IDENTITY_PACKAGES = 'ENABLE_IDENTITY_PACKAGES';
export const ENABLE_MAP_SEARCH = 'enable_map_search';
