import React, { useContext, createContext, useEffect, useState } from 'react';
import useIdentityLinks from './useIdentityLinks';
import { useFetchPerson, useFetchConnectCandidates } from './usePpl';

const IdentityPackageContext = createContext();

export const useIdentityPackage = () => useContext(IdentityPackageContext);

export const IdentityPackageProvider = ({ principalId, ...rest }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { fetchPerson, data: principal, isLoaded: isPrincipalLoaded } = useFetchPerson(principalId);
  const {
    fetchIdentityLinks,
    dataById: identityLinksById,
    ids: identityLinkIds,
    toNames,
    createLink,
    deleteLink,
    updateLink,
    fetchStatus,
    isLoaded: areLinksLoaded,
  } = useIdentityLinks(principalId);
  const { fetchConnectCandidates, data: drawerContents } = useFetchConnectCandidates(principalId);

  useEffect(() => {
    fetchPerson();
    fetchIdentityLinks();
  }, [fetchPerson, fetchIdentityLinks]);

  useEffect(() => {
    fetchConnectCandidates();
  }, [identityLinkIds, fetchConnectCandidates]);

  const name = principal?.name;

  return (
    <IdentityPackageContext.Provider
      value={{
        drawerOpen,
        openDrawer: () => setDrawerOpen(true),
        closeDrawer: () => setDrawerOpen(false),
        fetchStatus,
        createLink,
        deleteLink,
        updateLink,
        principal,
        identityLinksById,
        identityLinkIds,
        areLinksLoaded,
        isPrincipalLoaded,
        query: [name, ...toNames].join('|'),
        getLinkToIdentityThumb: linkId => identityLinksById?.[linkId]?.toIdentity?.images?.[0],
        getLinkToIdentityName: linkId => identityLinksById?.[linkId]?.toIdentity?.name,
        getLinkRelationship: linkId => identityLinksById?.[linkId]?.relationship,
        getLinkStrength: linkId => identityLinksById?.[linkId]?.strength,
        drawerContents,
        id: principalId,
        library: principal?.library,
        images: principal?.images || [],
        name,
        thumb:
          principal?.images?.[0] ??
          '//upload.wikimedia.org/wikipedia/commons/a/ad/Placeholder_no_text.svg',
      }}
      {...rest}
    />
  );
};
