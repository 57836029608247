import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 104" color={color} {...rest}>
    <g>
      <path d="M66.667 76.69H3.333A2.678 2.678 0 0 0 .667 79.37v8.172a2.678 2.678 0 0 0 2.666 2.678l6-.005c.532 6.163 5.6 10.98 11.865 10.98 6.265 0 11.333-4.823 11.864-10.98h33.604a2.678 2.678 0 0 0 2.667-2.679v-8.171a2.677 2.677 0 0 0-2.667-2.674zm-45.47 18.75c-3.468 0-6.265-2.814-6.265-6.293 0-3.485 2.802-6.294 6.265-6.294 3.47 0 6.266 2.815 6.266 6.294.005 3.479-2.932 6.293-6.266 6.293zM122.803 65.573L109.068 63.7l-7.865-13.796c-2.135-3.751-6.135-6.027-10.4-6.027H75.733a2.678 2.678 0 0 0-2.666 2.679v40.848a2.678 2.678 0 0 0 2.666 2.679h19.068c.532 6.162 5.6 10.98 11.865 10.98 6.266 0 11.333-4.823 11.864-10.98h3.6c2.932 0 5.333-2.412 5.333-5.358l.005-13.796c-.135-2.678-2.136-4.954-4.667-5.357zM81.6 51.913c0-.266.266-.533.531-.533l8.537-.005c1.599 0 3.067.936 3.864 2.275l5.068 8.972c.266.403 0 .806-.401.806H82.136c-.265 0-.531-.267-.531-.534l-.005-10.98zm24.932 43.527c-3.469 0-6.266-2.814-6.266-6.293 0-3.485 2.803-6.294 6.266-6.294 3.469 0 6.266 2.815 6.266 6.294.005 3.479-2.797 6.293-6.266 6.293zM3.333 72.939h63.334a2.678 2.678 0 0 0 2.666-2.679V30.615a2.678 2.678 0 0 0-2.666-2.679H3.333a2.678 2.678 0 0 0-2.666 2.679V70.26a2.681 2.681 0 0 0 2.666 2.679z" />
    </g>
  </SvgIcon>
);
