import axios from 'axios.js';

import { ANALYTICS_BACKEND } from 'common/constants/urls';

export function getLibraries(params = {}) {
  return axios.get(`${ANALYTICS_BACKEND}/library/`, { params }).then(({ data }) => data);
}

export function getLibrary(id) {
  return axios.get(`${ANALYTICS_BACKEND}/library/${id}/`).then(({ data }) => data);
}

export function postGeofenceFilter(body) {
  return axios.post(`${ANALYTICS_BACKEND}/library/geofence_filter/`, body).then(({ data }) => data);
}
