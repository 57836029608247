import { OBJECT_KEY } from './constants';

const defaults = {
  allData: [],
};

export default function objectsReducer(state = defaults, action) {
  switch (action.type) {
    case `LIBRARY/${OBJECT_KEY}/SET_ALL_DATA`: {
      return {
        ...state,
        allData: action.payload,
      };
    }
    default:
      return state;
  }
}
