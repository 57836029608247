import accepts from 'attr-accept';

export function downloadBlob(blob, name) {
  const url = URL.createObjectURL(blob);
  const download = document.createElement('a');
  download.download = name;
  download.href = url;
  download.click();
  removeURL(url, download);
}

export function downloadUrl(url, name) {
  const download = document.createElement('a');
  download.download = name;
  download.href = url;
  download.click();
}

export function extractFileNameFromDisposition(disposition) {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
}

/* Apparently there is some trouble with certain browsers when you try to removeObjectURL immediately without setTimeout */
export function removeURL(url) {
  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
}

const FILE_REGEX = /(.*)(\.[a-z0-9]+)/i;
export function parseFileName(file) {
  const result = {
    name: '',
    extension: '',
  };

  if (file && file.name) {
    const match = file.name.match(FILE_REGEX);

    if (match) {
      result.name = match[1];
      result.extension = match[2];
    }
  }

  return result;
}

export function getExtension(file) {
  return parseFileName(file).extension;
}

export function getFileExtension(filename) {
  if (typeof filename !== 'string') return;

  return filename.split('.').pop().toLowerCase();
}

export function getImageFiles(acceptedFiles, imageExtensions) {
  return acceptedFiles.filter(file => imageExtensions.includes(getFileExtension(file.name)));
}

export function getVideoFiles(acceptedFiles, videoExtensions) {
  return acceptedFiles.filter(file => videoExtensions.includes(getFileExtension(file.name)));
}

export function isFileAccepted(file, extensions) {
  const ext = getExtension(file);
  return extensions.includes(ext);
}

const IGNORED_FILENAMES = {
  // Thumbnail cache files for macOS and Windows
  '.DS_Store': true, // macOs
  'Thumbs.db': true, // Windows
};

const shouldSkipFile = ({ name }) => name.startsWith('.') || IGNORED_FILENAMES[name];

export const COMMON_MIME_TYPES = {
  avi: 'video/avi',
  gif: 'image/gif',
  ico: 'image/x-icon',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  mkv: 'video/x-matroska',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  pdf: 'application/pdf',
  png: 'image/png',
  zip: 'application/zip',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

const addType = f => {
  const { name } = f;
  const hasExtension = name && name.lastIndexOf('.') !== -1;

  if (hasExtension && !f.type) {
    const ext = name.split('.').pop().toLowerCase();
    const type = COMMON_MIME_TYPES[ext];
    if (type) {
      Object.defineProperty(f, 'type', {
        value: type,
        writable: false,
        configurable: false,
        enumerable: true,
      });
    }
  }
  return f;
};

const addPath = f => {
  const { path, name, webkitRelativePath } = f;
  Object.defineProperty(f, 'path', {
    value:
      typeof path === 'string'
        ? path
        : typeof webkitRelativePath === 'string' && webkitRelativePath.length > 0
        ? webkitRelativePath
        : name,
    writeable: false,
    configurable: false,
    enumerable: true,
  });
  return f;
};

const addPathAndType = f => addType(addPath(f));

export const fromEvent = ({ currentTarget: { files = [] } } = {}, acceptedTypes) => {
  const acceptedFiles = [];
  const rejectedFiles = [];

  Array.from(files).forEach(file => {
    if (!shouldSkipFile(file)) {
      const doctoredFile = addPathAndType(file);
      if (accepts(file, acceptedTypes)) {
        acceptedFiles.push(doctoredFile);
      } else {
        rejectedFiles.push(doctoredFile);
      }
    }
  });

  return { acceptedFiles, rejectedFiles };
};
