import React from 'react';
import { createUseStyles } from 'react-jss';
import { Card, CardContent } from '@material-ui/core';

import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import PrincipalName from './PrincipalName';
import { useIdentityPackage } from './IdentityPackageContext';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    position: 'relative',
  },

  photo: {
    width: '100%',
  },
});

const PrincipalProfile = () => {
  const classes = useStyles();
  const { isPending, name, thumb } = useIdentityPackage();

  return (
    <Card className={classes.root}>
      {isPending && <LoadingOverlay />}

      <img src={thumb} alt="principal entity" className={classes.photo} />

      <CardContent>
        <PrincipalName name={name} />
      </CardContent>
    </Card>
  );
};

export default PrincipalProfile;
