import React from 'react';

export default ({ selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 27 27">
    <defs>
      <rect id="a" width="27" height="27" rx="13.5" />
    </defs>
    <g fill={selected ? '#6599DE' : '#FFF'} fillRule="evenodd">
      <g>
        <use fill={selected ? '#6599DE' : '#FFF'} />
        <rect width="26" height="26" x=".5" y=".5" stroke="#6599DE" strokeWidth="0.5" rx="13" />
      </g>
      <path
        fill={selected ? '#FFF' : '#6599DE'}
        fillRule="nonzero"
        d="M9.1 9.111h1.65L11.85 8h3.3l1.1 1.111h1.65c.608 0 1.1.498 1.1 1.111v6.667c0 .614-.492 1.111-1.1 1.111H9.1c-.608 0-1.1-.497-1.1-1.111v-6.667c0-.613.492-1.11 1.1-1.11zm4.4 1.667c-1.519 0-2.75 1.243-2.75 2.778 0 1.534 1.231 2.777 2.75 2.777s2.75-1.243 2.75-2.777c0-1.535-1.231-2.778-2.75-2.778zm0 1.11c.911 0 1.65.747 1.65 1.668 0 .92-.739 1.666-1.65 1.666-.911 0-1.65-.746-1.65-1.666 0-.92.739-1.667 1.65-1.667z"
      />
    </g>
  </svg>
);
