import axios from 'axios.js';

import { ANALYTICS_BACKEND, CAMERAS, CAMERA_MANAGER, MACULAS, ZONE } from 'common/constants/urls';

import { transformCameraParams } from './params';

export function getCameras(params) {
  return axios.get(`${ANALYTICS_BACKEND}${CAMERAS}/`, { params }).then(({ data }) => data);
}

export function getCamera(id) {
  return axios.get(`${ANALYTICS_BACKEND}${CAMERAS}/${id}/`).then(({ data }) => data);
}

export function getCameraDatasources(id, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}${CAMERAS}/${id}/datasources/`, { params })
    .then(({ data }) => data);
}

export function getDefaultNetworkNumbers() {
  return axios
    .get(`${ANALYTICS_BACKEND}${CAMERA_MANAGER}/network-number/`)
    .then(({ data }) => data);
}

export function getNetworkScanResult() {
  // Returns the status of the currently-running scan
  // or last-run scan if none are currently running
  return axios.get(`${ANALYTICS_BACKEND}${CAMERA_MANAGER}/result/`).then(({ data }) => data);
}

export function getNetworkScanDetails(networkScanID) {
  return axios
    .get(`${ANALYTICS_BACKEND}${CAMERA_MANAGER}/details/${networkScanID}/`)
    .then(({ data }) => data);
}

export function cancelNetworkScan(networkScanID) {
  return axios
    .patch(`${ANALYTICS_BACKEND}${CAMERA_MANAGER}/cancel/${networkScanID}/`)
    .then(({ data }) => data);
}

export function patchCamera(id, unsafeParams) {
  return axios
    .patch(`${ANALYTICS_BACKEND}${CAMERAS}/${id}/`, transformCameraParams(unsafeParams))
    .then(({ data }) => data);
}

export function deleteCamera(id) {
  return axios.delete(`${ANALYTICS_BACKEND}${CAMERAS}/${id}/`).then(({ data }) => data);
}

export function postCamera(camera) {
  return axios
    .post(`${ANALYTICS_BACKEND}${CAMERAS}/`, transformCameraParams(camera))
    .then(({ data }) => data);
}

export function postNetworkScan(body) {
  /**
    Example body:
    {
      "network_number": "192.168.11.",
      "logins": [[ "user1", "password1" ], [ "user2", "password2" ]],
      "ports": [ 80, 580 ]
    }
  */
  return axios.post(`${ANALYTICS_BACKEND}${CAMERA_MANAGER}/scan/`, body).then(({ data }) => data);
}

export function getMaculas() {
  return axios.get(`${ANALYTICS_BACKEND}${MACULAS}/`).then(({ data }) => data);
}

export function getMaculaSlots() {
  return axios.get(`${ANALYTICS_BACKEND}${MACULAS}/slots/`).then(({ data }) => data);
}

/*
  Function needs to be called before trying to start/stop cameras
  in order to make sure Macula Slots is synced with Django Database
*/
export function initMacula() {
  return axios.get(`${ANALYTICS_BACKEND}${MACULAS}/init/`);
}

/* API that will be used later to get camera snapshot */
export function getCameraSnapshot(id) {
  return axios.get(`${ANALYTICS_BACKEND}${CAMERAS}/${id}/snapshot/`).then(({ data }) => data);
}

export function validateCamera(camera, shouldEdit) {
  const data = {
    ...camera,
    validateOnly: true,
  };

  return shouldEdit ? patchCamera(camera.id, data) : postCamera(data);
}

export function postCameraZone(zone) {
  return axios.post(`${ANALYTICS_BACKEND}${ZONE}/`, zone).then(({ data }) => data);
}

export function patchCameraZone(zoneId, zone) {
  return axios.patch(`${ANALYTICS_BACKEND}${ZONE}/${zoneId}/`, zone).then(({ data }) => data);
}

export function deleteCameraZone(zone) {
  return axios.delete(`${ANALYTICS_BACKEND}${ZONE}/${zone}/`).then(({ data }) => data);
}
