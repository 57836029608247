import { isNumber, get } from 'lodash';
import { createSelector } from 'reselect';

import { getDateInMs } from 'common/helpers/datasourceUtils';
import { buildInitialTimelineBounds, buildTimelineData } from 'common/components/timeline/utils';
import { flattenResultsAndFilterByMediaId } from 'analysis/utils';
import { getTimeFromMatchResult } from 'analysis/searchResultsPanel/utils';

import { selectDatasourceModels, selectImageModels } from 'common/redux/models/selectors';
import getVideoIcon from 'common/components/map/mapIcons/video';
import getImageIcon from 'common/components/map/mapIcons/image';
import { DS_TYPE_VIDEO, DS_TYPE_IMAGE } from 'common/constants/app';

export const selectShowAllSearches = state => get(state, 'analysis.main.showAllSearches');

export const selectSearchRequestHistory = state => get(state, 'analysis.main.searchRequestHistory');

export const selectIsLoadingHistory = state => get(state, 'analysis.main.isLoadingHistory');

export const selectSearchRequest = state => get(state, 'analysis.main.searchRequest');

export const selectSearchRequestId = state => get(state, 'analysis.main.searchRequestId');
export const selectMapConfig = state => get(state, 'analysis.main.mapConfig');

export const selectImageDetails = state => get(state, 'analysis.main.imageDetails');

export const selectSearchResultsFrames = state => get(state, 'analysis.main.searchResultsFrames');

export const selectSearchResultsRangeStart = state =>
  get(state, 'analysis.main.searchResultsRangeStart');

export const selectSearchResultsRangeEnd = state =>
  get(state, 'analysis.main.searchResultsRangeEnd');

export const selectIsSubmittingSearch = state => get(state, 'analysis.main.isSubmittingSearch');

export const selectRelatedImageId = state => get(state, 'analysis.main.selectedRelatedImageId');

export const selectResultImageId = state => get(state, 'analysis.main.selectedResultImageId');

export const selectResultTimestamp = state => get(state, 'analysis.main.selectedResultTimestamp');

export const selectResultVideoId = state => get(state, 'analysis.main.selectedResultVideoId');

export const selectVideoTimelineData = state => get(state, 'analysis.main.videoTimelineData');

export const selectHighlightedResults = state => get(state, 'analysis.main.highlightedResults');

const selectGroupBy = state => get(state, 'analysis.main.groupBy');

const selectGroupByFilter = state => get(state, 'analysis.main.groupByFilter');

const selectGroupedSearchResults = state => get(state, 'analysis.main.groupedSearchResults');

const selectSearchResults = state => get(state, 'analysis.main.searchResults');

export const selectSearchStatus = state => get(state, 'analysis.main.searchStatus');

export const selectSelectedId = createSelector(
  selectResultImageId,
  selectResultVideoId,
  (imageId, videoId) => imageId || videoId
);

export const selectFilteredSearchResults = createSelector(
  selectGroupBy,
  selectGroupByFilter,
  selectGroupedSearchResults,
  selectSearchResults,
  (groupBy, groupByFilter, groupedSearchResults, searchResults) =>
    flattenResultsAndFilterByMediaId(
      groupBy && !groupByFilter ? groupedSearchResults : searchResults
    )
);

export const selectFilteredSearchResultImageData = createSelector(
  [selectFilteredSearchResults, selectImageModels],
  (filteredSearchResults, imageModels) =>
    filteredSearchResults.reduce((accum, { mediaId }) => {
      const data = imageModels[mediaId];
      if (!data) return accum;
      return [...accum, data];
    }, [])
);

export const selectFilteredSearchResultDatasources = createSelector(
  [selectFilteredSearchResults, selectDatasourceModels],
  (filteredSearchResults, datasources) =>
    filteredSearchResults.reduce((accum, { mediaId }) => {
      const data = datasources[mediaId];
      if (!data) return accum;
      return [...accum, data];
    }, [])
);

export const selectAnalysisMapData = createSelector(
  [
    selectFilteredSearchResults,
    selectFilteredSearchResultImageData,
    selectFilteredSearchResultDatasources,
    selectSelectedId,
  ],
  (
    filteredSearchResults,
    filteredSearchResultImageData,
    filteredSearchResultDatasources,
    selectedId
  ) =>
    filteredSearchResults.reduce((accum, matchResult) => {
      const { mediaId, resultType } = matchResult;
      const isSelected = mediaId === selectedId;
      const imageData = filteredSearchResultImageData.find(({ id }) => id === mediaId);
      const dsData = filteredSearchResultDatasources.find(({ id }) => id === mediaId);
      if (!imageData && !dsData) return accum;

      const data = imageData || dsData;
      const location = data?.location?.coords;

      if (location && location.lat && location.lng) {
        accum.push({
          icon:
            resultType === DS_TYPE_IMAGE
              ? getImageIcon({ isSelected })
              : resultType === DS_TYPE_VIDEO
              ? getVideoIcon({ isSelected })
              : undefined,
          isSelected,
          resultType,
          mediaId,
          position: [location.lat, location.lng],
          options:
            resultType === DS_TYPE_VIDEO ? { timestamp: getTimeFromMatchResult(matchResult) } : {},
        });
      }

      return accum;
    }, [])
);

export const selectSearchHours = createSelector(selectSearchRequest, searchRequest =>
  get(searchRequest, 'status.totalVideoMillisSearched', 0)
);

export const selectImageId = createSelector(
  selectRelatedImageId,
  selectResultImageId,
  (relatedId, resultId) => relatedId || resultId
);

export const selectSelectedResultDatasource = createSelector(
  selectResultVideoId,
  selectDatasourceModels,
  (selectedResultVideoId, dsModels) => dsModels[selectedResultVideoId]
);

export const selectDuration = createSelector(selectSelectedResultDatasource, datasource =>
  get(datasource, 'duration')
);
export const selectStartEpochMs = createSelector(selectSelectedResultDatasource, datasource =>
  get(datasource, 'startEpochMs')
);

export const selectStartDateInMs = createSelector(selectSelectedResultDatasource, datasource =>
  datasource ? getDateInMs(datasource) : undefined
);

export const selectCurrentVideoTime = createSelector(
  selectResultTimestamp,
  selectStartEpochMs,
  (timestamp, start) => (isNumber(timestamp) && start != null ? timestamp - start : undefined)
);

export const selectVideoTimelineSegments = createSelector(
  selectVideoTimelineData,
  videoTimelineData =>
    videoTimelineData.reduce((acc, group) => {
      acc = acc.concat(group.segments);
      return acc;
    }, [])
);

export const selectInitialBounds = createSelector(
  selectCurrentVideoTime,
  selectDuration,
  buildInitialTimelineBounds
);

export const selectTimelineData = createSelector(
  selectVideoTimelineData,
  selectStartEpochMs,
  selectStartDateInMs,
  buildTimelineData
);
