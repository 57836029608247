import { styled } from 'react-jss';
import Typography from '@material-ui/core/Typography';

import CreateIcon from '@material-ui/icons/Create';
import PersonIcon from '@material-ui/icons/Person';
import Icon from 'common/components/base/Icon';
import RelativeProximityIndicator from 'components/IdentityPackage/RelativeProximityIndicator';

import { PRIMARY_GREY, REGENT_GREY } from 'common/constants/colors';

export const Box = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Label = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const Name = ({ value }) => (
  <Box>
    <PersonIcon htmlColor={REGENT_GREY} />
    <Label noWrap>{value}</Label>
  </Box>
);

export const Relationship = ({ value, strength }) => (
  <Box>
    <RelativeProximityIndicator strength={strength} />
    <Label variant="body2" noWrap>
      {value}
    </Label>
  </Box>
);

export const Create = () => (
  <Box>
    <Icon component={CreateIcon} style={{ color: PRIMARY_GREY }} />
    <Label style={{ color: PRIMARY_GREY }} noWrap>
      Add a description
    </Label>
  </Box>
);
