import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';

import Button from 'common/components/base/Button';
import { ColorPicker } from 'common/components/pickers/ColorPicker';

import { Example } from '../helpers';

const ColorsExample = () => {
  const [color, setColor] = useState('#ffffff');
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Example label="Color Picker">
      <p>hi this is the picker sample...current color is {color}.</p>
      <Button onClick={event => setAnchorEl(event.currentTarget)}>ColorPicker</Button>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ColorPicker color={color} setColor={setColor} />
      </Popover>
    </Example>
  );
};
export default ColorsExample;
