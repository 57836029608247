import { makeStyles } from 'tss-react-local';
import { isNumber } from 'lodash';
import { formatBytes } from 'common/helpers/stringUtils';

import StatusLinearProgress from 'common/components/base/StatusLinearProgress';
import { getVideoAndImageInfo } from 'app/header/mirageHealth/computeHealthInfoStatus';

import {
  MIRAGE_HEALTH_UNKNOWN,
  MIRAGE_HEALTH_GOOD,
  MIRAGE_HEALTH_WARNING,
  MIRAGE_HEALTH_ERROR,
  UNKNOWN,
  MIRAGE_HEALTH_COLOR_TEXT,
} from 'common/constants/app';

import { DARK_GREY, BRIGHT_GREEN, PRIMARY_YELLOW, ERROR } from 'common/constants/colors';

const getCircleColor = status => {
  switch (status) {
    case MIRAGE_HEALTH_GOOD:
      return BRIGHT_GREEN;
    case MIRAGE_HEALTH_WARNING:
      return PRIMARY_YELLOW;
    case MIRAGE_HEALTH_ERROR:
      return ERROR;
    case MIRAGE_HEALTH_UNKNOWN:
      return DARK_GREY;

    default:
      return DARK_GREY;
  }
};

const useStyles = makeStyles()((_theme, { status }) => ({
  circle: {
    backgroundColor: getCircleColor(status),
    borderRadius: '50%',
    height: 12,
    minHeight: 12,
    minWidth: 12,
    width: 12,
  },
  label: {
    display: 'flex',
    flexGrow: 1,
    fontWeight: 300,
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const Circle = props => {
  const { className, status, ...rest } = props;
  const { classes, cx } = useStyles({ status });
  return <div className={cx(classes.circle, className)} {...rest} />;
};

export const getConfig = (
  mirageStatus,
  internetStatus,
  searchStatus,
  health,
  wsHealth,
  isLiveNGEnabled = false
) => {
  const {
    searchInfo = {},
    systemInfo = {},
    servicesInfo = {},
    dsInfoImage = {},
    dsInfoVideo = {},
  } = health;

  const { classes } = useStyles({});
  const { sparkNumberOfExecutors, sparkNumberOfCores, sparkTotalMemory } = searchInfo;
  const { cpuInfo, diskInfo, diskMediaInfo, memoryInfo, gpuInfo } = systemInfo;
  const {
    maculaHeartbeat = {},
    processorHeartbeat = {},
    camcoderHeartbeat = {},
    mintHeartbeat = {},
  } = servicesInfo;

  const liveHeartbeat = isLiveNGEnabled ? camcoderHeartbeat : maculaHeartbeat;
  /* these are ON if the value is greater than 0 -- meaning that more than 0 successful pings has hit the service */
  const procStatus = processorHeartbeat.value > 0;
  const liveStatus = liveHeartbeat.value > 0;
  const mintStatus = mintHeartbeat.value > 0;

  const cpuUsage = cpuInfo && isNumber(cpuInfo.value) ? `${Math.round(cpuInfo.value)}%` : UNKNOWN;

  const gpuUsage =
    gpuInfo && isNumber(gpuInfo.value)
      ? `${Math.round(gpuInfo.value)}%`
      : gpuInfo && gpuInfo.flag === -1
      ? null
      : UNKNOWN;

  const diskUsage =
    diskInfo && diskInfo.value
      ? `${formatBytes(diskInfo.lastUsedBytes)} / ${formatBytes(
          diskInfo.lastTotalBytes
        )} (${Math.round(diskInfo.value)}%)`
      : UNKNOWN;

  const mediaUsage =
    diskMediaInfo && diskMediaInfo.value
      ? `${formatBytes(diskMediaInfo.lastUsedBytes)} / ${formatBytes(
          diskMediaInfo.lastTotalBytes
        )} (${Math.round(diskMediaInfo.value)}%)`
      : null;

  const memoryUsage = memoryInfo && memoryInfo.value ? `${Math.round(memoryInfo.value)}%` : UNKNOWN;
  const videoAndImageInfo = getVideoAndImageInfo(dsInfoVideo, dsInfoImage);

  const services = [
    {
      key: 'Live Processing',
      label: (
        <div className={classes.label}>
          <span>Live Processing</span>
          <span>{liveStatus ? 'ON' : 'OFF'}</span>
        </div>
      ),
      icon: <Circle status={liveHeartbeat.flag} />,
      'data-testid': 'MirageHealthIcon-liveprocStatus',
    },
    {
      key: 'Data Processing',
      label: (
        <div className={classes.label}>
          <span>Data Processing</span>
          <span>{procStatus ? 'ON' : 'OFF'}</span>
        </div>
      ),
      icon: <Circle status={processorHeartbeat.flag} />,
      'data-testid': 'MirageHealthIcon-procStatus',
    },
    {
      key: 'Mint',
      label: (
        <div className={classes.label}>
          <span>Mint Service</span>
          <span>{mintStatus ? 'ON' : 'OFF'}</span>
        </div>
      ),
      icon: <Circle status={mintHeartbeat.flag} />,
      'data-testid': 'MirageHealthIcon-mintStatus',
    },
    {
      key: 'Search Service',
      label: (
        <div className={classes.label}>
          <span>Search Service</span>
          <span>{searchStatus === MIRAGE_HEALTH_GOOD ? 'ON' : 'OFF'}</span>
        </div>
      ),
      icon: <Circle status={searchStatus} />,
      'data-testid': 'MirageHealthIcon-searchService',
    },
    {
      key: 'sparkNumberOfCores',
      label: (
        <div className={classes.label}>
          <span>Cores</span>
          <span>{sparkNumberOfCores?.value || UNKNOWN}</span>
        </div>
      ),
      'data-testid': 'MirageHealthIcon-sparkNumberOfCores',
      style: { paddingLeft: '44px' },
    },
    {
      key: 'sparkNumberOfExecutors',
      label: (
        <div className={classes.label}>
          <span>Executors</span>
          <span>{sparkNumberOfExecutors?.value || UNKNOWN}</span>
        </div>
      ),
      'data-testid': 'MirageHealthIcon-sparkNumberOfExecutors',
      style: { paddingLeft: '44px' },
    },
    {
      key: 'sparkTotalMemory',
      label: (
        <div className={classes.label}>
          <span>Total Memory</span>
          <span>{sparkTotalMemory?.value || UNKNOWN} MB</span>
        </div>
      ),
      'data-testid': 'MirageHealthIcon-sparkTotalMemory',
      style: { paddingLeft: '44px' },
    },
  ];

  const systems = [
    {
      key: 'CPU',
      label: (
        <div className={classes.label}>
          <span>CPU</span>
          <span>{cpuUsage}</span>
        </div>
      ),
      icon: <Circle status={cpuInfo && cpuInfo.flag} />,
      'data-testid': 'MirageHealthIcon-cpuUsage',
    },
    {
      key: 'Memory',
      label: (
        <div className={classes.label}>
          <span>Memory</span>
          <span>{memoryUsage}</span>
        </div>
      ),
      icon: <Circle status={memoryInfo && memoryInfo.flag} />,
      'data-testid': 'MirageHealthIcon-memoryUsage',
    },
  ];

  if (gpuUsage) {
    systems.unshift({
      key: 'GPU',
      label: (
        <div className={classes.label}>
          <span>GPU</span>
          <span>{gpuUsage}</span>
        </div>
      ),
      icon: <Circle status={gpuInfo && gpuInfo.flag} />,
      'data-testid': 'MirageHealthIcon-gpuUsage',
    });
  }

  const limits = [
    {
      key: 'System',
      label: (
        <div className={classes.label}>
          <span>System</span>
          <span>{diskUsage}</span>
        </div>
      ),
      icon: <Circle status={diskInfo?.flag} />,
      data: (
        <StatusLinearProgress
          variant="determinate"
          value={Math.round(diskInfo?.value)}
          color={MIRAGE_HEALTH_COLOR_TEXT[diskInfo?.flag]}
        />
      ),
      'data-testid': 'MirageHealthIcon-diskUsage',
    },
    {
      key: 'Number of Videos',
      label: (
        <div className={classes.label}>
          <span>Number of Videos</span>
          <span>{videoAndImageInfo.videoInfo}</span>
        </div>
      ),
      icon: <Circle status={dsInfoVideo?.flag} />,
      data: (
        <StatusLinearProgress
          variant="determinate"
          value={videoAndImageInfo.videoPercent}
          color={MIRAGE_HEALTH_COLOR_TEXT[dsInfoVideo?.flag]}
        />
      ),
    },
    {
      key: 'Number of Images',
      label: (
        <div className={classes.label}>
          <span>Number of Images</span>
          <span>{videoAndImageInfo.imageInfo}</span>
        </div>
      ),
      icon: <Circle status={dsInfoImage?.flag} />,
      data: (
        <StatusLinearProgress
          variant="determinate"
          value={videoAndImageInfo.imagePercent}
          color={MIRAGE_HEALTH_COLOR_TEXT[dsInfoImage?.flag]}
        />
      ),
    },
  ];

  if (mediaUsage) {
    limits.unshift({
      key: 'Media',
      label: (
        <div className={classes.label}>
          <span>Media</span>
          <span>{mediaUsage}</span>
        </div>
      ),
      icon: <Circle status={diskMediaInfo?.flag} />,
      data: (
        <StatusLinearProgress
          variant="determinate"
          value={Math.round(diskMediaInfo.value)}
          color={MIRAGE_HEALTH_COLOR_TEXT[diskMediaInfo?.flag]}
        />
      ),
      'data-testid': '`MirageHealthIcon-mediaUsage',
    });
  }

  return [
    {
      label: 'Connectivity',
      items: [
        {
          key: 'Internet',
          label: (
            <div className={classes.label}>
              <span>Internet</span>
              <span>{window.PERCIPIENT_IS_ONLINE ? 'Connected' : 'Offline'}</span>
            </div>
          ),
          icon: <Circle status={internetStatus} />,
          'data-testid': 'MirageHealthIcon-internetInfo',
        },
        {
          key: 'Mirage Backend',
          label: (
            <div className={classes.label}>
              <span>Mirage Backend</span>
              <span>{mirageStatus.value}</span>
            </div>
          ),
          icon: <Circle status={mirageStatus?.flag} />,
          'data-testid': 'MirageHealthIcon-backendInfo',
        },
        {
          key: 'Websocket',
          label: (
            <div className={classes.label}>
              <span>Websocket</span>
              <span>{wsHealth === MIRAGE_HEALTH_GOOD ? 'Connected' : 'Disconnected'}</span>
            </div>
          ),
          icon: <Circle status={wsHealth} />,
        },
      ],
    },
    {
      label: 'Storage Limits',
      items: limits,
    },
    {
      label: 'System Activity',
      items: systems,
    },
    {
      label: 'Services',
      items: services,
    },
  ];
};

export const getMirageConnectivity = responseTime => {
  if (responseTime === undefined) {
    return {
      value: 'Loading',
      flag: MIRAGE_HEALTH_UNKNOWN,
    };
  }
  if (!responseTime) {
    return {
      value: 'Offline',
      flag: MIRAGE_HEALTH_ERROR,
    };
  }
  if (responseTime > 2000) {
    return {
      value: 'Very Slow',
      flag: MIRAGE_HEALTH_ERROR,
    };
  }
  if (responseTime > 500) {
    return {
      value: 'Slow',
      flag: MIRAGE_HEALTH_WARNING,
    };
  }

  return {
    value: 'Normal',
    flag: MIRAGE_HEALTH_GOOD,
  };
};

export const getHealth = ({ health, mirageStatus, wsHealth, searchHealth, serviceHealth }) => {
  if (wsHealth !== MIRAGE_HEALTH_GOOD) {
    return wsHealth;
  }

  if (searchHealth !== MIRAGE_HEALTH_GOOD) {
    return searchHealth;
  }

  if (serviceHealth !== MIRAGE_HEALTH_GOOD) {
    if (serviceHealth === MIRAGE_HEALTH_UNKNOWN) return MIRAGE_HEALTH_WARNING;
    return serviceHealth;
  }

  if (mirageStatus !== MIRAGE_HEALTH_GOOD) {
    return mirageStatus;
  }

  return health;
};
