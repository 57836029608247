import React from 'react';
import UserSelect from 'common/components/generalComponents/inputs/UserSelect';

const UserSelectExample = () => (
  <>
    <h4>User Select</h4>
    <UserSelect
      disabledUserIds={[1, 2, 3, 4]}
      onChange={selectedUser => console.log('selected: ', selectedUser)}
    />
  </>
);

export default UserSelectExample;
