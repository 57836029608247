export function getBaseLog(x, y) {
  return Math.log(y) / Math.log(x);
}

export function round(num, digits = 0) {
  const base = 10 ** digits;
  return Math.round(num * base) / base;
}

export function constrain(num, min, max) {
  if (num < min) return min;
  if (num > max) return max;
  return num;
}

export function inRange(num, min, max) {
  return num >= min && num <= max;
}

export function truncatePercentage(num) {
  return `${Math.trunc(num * 100)}%`;
}
