import React from 'react';

import { REGENT_GREY } from 'common/constants/colors';

export default ({ style: { color = REGENT_GREY, width = 20, height = width } = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M10.521 18.254h1.128v1.593h-1.128V22H8.546v-2.153H4.465l-.089-1.244 4.15-6.556h1.995v6.207zm-4.176 0h2.2V14.74l-.129.226-2.071 3.288zm9.262-1.442l1.245-2.208h2.112l-2.106 3.623L19.053 22h-2.12l-1.319-2.324L14.302 22h-2.126l2.194-3.773-2.099-3.623h2.12l1.216 2.208zM12.5 5.888l-5 3.182v-7l5 3.182V2.07l5.5 3.5-5.5 3.5V5.888z"
      />
    </g>
  </svg>
);
