import React from 'react';
import { createUseStyles } from 'react-jss';
import TextField from '@material-ui/core/TextField';
import { BLACK } from 'common/constants/colors';

const useStyles = createUseStyles(theme => ({
  root: {
    position: 'relative',
  },
  personName: {
    textTransform: 'capitalize',
    fontSize: theme.spacing(2.2),
    color: BLACK,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const PrincipalName = ({ name = '' }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        disabled
        variant="standard"
        label="Name"
        value={name}
        inputProps={{
          className: classes.personName,
        }}
      />
    </div>
  );
};

export default PrincipalName;
