import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { BRIGHT_GREEN, MEDIUM_GREY, WARNING_COLOR } from 'common/constants/colors';

const size = '24px'; // should match the default size of other Icons in the app

const useStyles = createUseStyles({
  root: {
    fontSize: 14,
    minWidth: size,
    minHeight: size,
    maxWidth: size,
    maxHeight: size,
    display: 'inline-block',
    lineHeight: size,
    textAlign: 'center',
  },
  strength1: {
    color: BRIGHT_GREEN,
    letterSpacing: '-1px',
  },
  strength2: {
    color: WARNING_COLOR,
    letterSpacing: '2px',
  },
  strength3: {
    color: MEDIUM_GREY,
    letterSpacing: '6px',
  },
});

const RelativeProximityIndicator = ({ strength = 3, className }) => {
  const classes = useStyles();

  return (
    <span className={classnames(classes.root, classes[`strength${strength}`], className)}>III</span>
  );
};

RelativeProximityIndicator.propTypes = {
  strength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default RelativeProximityIndicator;
