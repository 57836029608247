import { createSelector } from 'reselect';
import { get } from 'lodash';
import { JOB_FILTER_PARAM, JOB_FILTER_STATUS } from '../constants';

export const selectGlobalProcessingPanel = state => get(state, 'library.globalProcessingPanel');
export const selectGlobalProcessingPanelOpen = state =>
  get(state, 'library.globalProcessingPanel.open');

export const selectPaginationOffset = state => get(state, 'library.globalProcessingPanel.offset');

export const selectPaginationSortBy = state => get(state, 'library.globalProcessingPanel.sortBy');

export const selectIsPaginated = state => get(state, 'library.globalProcessingPanel.isPaginated');

export const selectCanceljobGroup = state => get(state, 'library.globalProcessingPanel.jobGroup');

export const selectShowCancelJobGroup = state =>
  get(state, 'library.globalProcessingPanel.showCancelJobGroup');

export const getPaginatedParameters = createSelector(
  selectGlobalProcessingPanel,
  selectIsPaginated,
  (params, isPaginated) => ({
    ...(isPaginated ? params : {}),
    grouped: true,
    [JOB_FILTER_PARAM]: JOB_FILTER_STATUS,
  })
);
