import React from 'react';
import { Box, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Button from 'common/components/base/Button';
import useIdentityPackagePageTitle from './useIdentityPackagePageTitle';
import IdentityPackageNewPersonsDrawer from './IdentityPackageNewPersonsDrawer';
import ProxySearchForm from './ProxySearchForm';
import ExistingNetwork from './ExistingNetwork';
import { useIdentityPackage } from './IdentityPackageContext';

const IdentityPackageNetworks = () => {
  useIdentityPackagePageTitle('Networks');
  const { openDrawer } = useIdentityPackage();

  return (
    <Box p={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button onClick={openDrawer} color="primary" size="small" startIcon={<AddIcon />}>
            New Connection
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ExistingNetwork />
        </Grid>
      </Grid>

      <IdentityPackageNewPersonsDrawer />
      <Box py={2}>
        <ProxySearchForm />
      </Box>
    </Box>
  );
};

export default IdentityPackageNetworks;
