import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import injectSheet from 'react-jss';
import ReactTable from 'react-table';
import { isEqual } from 'lodash';

import Pagination from 'common/components/pagination/Pagination';
import { DARK_BORDER } from 'common/constants/colors';
import { ASC, DESC } from 'library/redux/constants';

import {
  JOB_LEVEL_MINIMUM_ROWS,
  JOBS_SORT_COLUMNS,
  MAX_PAGINATED_JOB_COUNT,
  STATUS,
  STRIPED_HIGHLIGHT,
  DB_PRIORITY,
} from './constants';

import { getTableProps, getTheadProps, getTheadTrProps, getTrProps } from './styles';
import { isJobGroupLevel, sortByOrdinalPriority } from './utils';
import {
  selectPaginationOffset,
  selectIsPaginated,
  selectPaginationSortBy,
} from './redux/selectors';

const styles = {
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  paginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 38,
    backgroundColor: 'white',
    borderTop: `1px solid ${DARK_BORDER}`,
  },
};

function hasProcessingJobsUpdated(prevProps, nextProps) {
  return isEqual(
    prevProps.jobProcessingData.inProgressJobs,
    nextProps.jobProcessingData.inProgressJobs
  );
}

const JobsTable = ({
  rowInfo,
  jobProcessingData,
  getColumns,
  setPagination,
  showGroupedView,
  classes,
}) => {
  const [isASC, setIsASC] = useState(ASC);
  const defaultOffset = useSelector(selectPaginationOffset);
  const defaultSortBy = useSelector(selectPaginationSortBy);
  const isPaginatedView = useSelector(selectIsPaginated);
  const { tableHeader } = classes;

  const jobs = jobProcessingData.inProgressJobs;
  const rowCount = jobs.length < JOB_LEVEL_MINIMUM_ROWS ? JOB_LEVEL_MINIMUM_ROWS : jobs.length;

  const onPaginate = (sortBy, offset) => {
    setPagination({
      sortBy,
      limit: MAX_PAGINATED_JOB_COUNT,
      offset,
      total: rowInfo.jobsCount,
      showJobLevel: true,
      batchId: rowInfo.batchId,
    });
  };

  const onPageChange = ({ offset: currentOffset }) => {
    const sortBy = isASC ? defaultSortBy : `-${defaultSortBy}`;
    onPaginate(sortBy, currentOffset);
  };

  const renderPagination = () => {
    if (!isPaginatedView) return null;

    return (
      <div className={classes.paginationWrapper}>
        <Pagination
          style={{ display: 'inline-block' }}
          onPageChange={onPageChange}
          limit={MAX_PAGINATED_JOB_COUNT}
          offset={defaultOffset}
          total={rowInfo.jobsCount}
        />
      </div>
    );
  };

  const getSortBy = sortBy => {
    let sortDirection = ASC;
    if (defaultSortBy === sortBy) {
      sortDirection = defaultSortBy.startsWith('-') ? ASC : DESC;
    }
    setIsASC(sortDirection);

    return sortDirection === ASC ? sortBy : `-${sortBy}`;
  };

  const handleColumnClick = (column, instance) => {
    const sortBy = JOBS_SORT_COLUMNS[column.id];
    if (isJobGroupLevel(column.id)) {
      showGroupedView();
      return;
    }

    if (column.id !== STATUS) {
      if (isPaginatedView) {
        onPaginate(getSortBy(sortBy), 0);
      } else {
        instance.sortColumn(column);
      }
    }
  };

  const getSortedJobs = () => {
    if (isPaginatedView) {
      return jobs;
    }

    if (defaultSortBy.endsWith(DB_PRIORITY)) {
      const sortDirection = defaultSortBy.startsWith('-') ? DESC : ASC;
      return sortByOrdinalPriority(jobs, sortDirection);
    }

    return jobs;
  };

  return (
    <div>
      <ReactTable
        className={STRIPED_HIGHLIGHT}
        minRows={rowCount}
        emptyRowsWhenPaging={false}
        data={getSortedJobs(jobs) || []}
        columns={getColumns}
        defaultPageSize={rowCount}
        pageSize={rowCount}
        showPagination={false}
        resizable={false}
        getTrProps={getTrProps}
        getTbodyProps={getTableProps}
        getTheadProps={getTheadProps}
        getTheadTrProps={getTheadTrProps}
        getTheadThProps={(state, row, column, instance) => ({
          style: { tableHeader },
          onClick: () => {
            handleColumnClick(column, instance);
          },
        })}
      />
      {renderPagination()}
    </div>
  );
};

export default injectSheet(styles)(React.memo(JobsTable, hasProcessingJobsUpdated));
