import { fetchProcessingData } from 'library/redux/actions';
import { MAX_PAGINATED_JOB_COUNT } from '../constants';
import { selectGlobalProcessingPanelOpen } from './selectors';

export const setProcessingPanelResultsMeta = (
  { sortBy, limit, offset, total, batchId },
  isPaginated
) => ({
  type: 'ANALYSIS/SET_PROCESSING_PANEL_RESULTS',
  payload: { sortBy, limit, offset, total, batchId, isPaginated },
});

const setProcessingPanelMeta = open => ({
  type: 'ANALYSIS/SET_PROCESSING_PANEL_OPEN',
  payload: { open },
});

const setShowCancelJobGroup = showCancelJobGroup => ({
  type: 'ANALYSIS/SHOW_CANCEL_JOB_GROUP',
  payload: { showCancelJobGroup },
});

const setJobGroup = jobGroup => ({
  type: 'ANALYSIS/SET_JOB_GROUP',
  payload: { jobGroup },
});

export const setPaginationParameters = jobData => (dispatch, getState) => {
  const isPaginated =
    getState().library.globalProcessingPanel.open &&
    jobData.total > MAX_PAGINATED_JOB_COUNT &&
    jobData.showJobLevel;
  dispatch(setProcessingPanelResultsMeta(jobData, isPaginated));
};

export const setGlobalProcessingPanelOpen = open => dispatch => {
  dispatch(setProcessingPanelMeta(open));
};

export const closeProcessingPanel = () => (dispatch, getState) => {
  const isProcessingPanelOpen = selectGlobalProcessingPanelOpen(getState());

  if (isProcessingPanelOpen) {
    dispatch(
      setPaginationParameters({
        sortBy: 'priority',
        limit: MAX_PAGINATED_JOB_COUNT,
        offset: 0,
        total: 0,
        showJobLevel: false,
        batchId: '',
      })
    );

    dispatch(setGlobalProcessingPanelOpen(false));
  }
};

export const showGroupView = () => dispatch => {
  dispatch(fetchProcessingData());
  dispatch(setGlobalProcessingPanelOpen(true));
};

export const showCancelJobGroup = datasource => dispatch => {
  dispatch(setShowCancelJobGroup(true));
  dispatch(setJobGroup(datasource));
};

export const closeCancelJobGroup = () => dispatch => {
  dispatch(setShowCancelJobGroup(false));
  dispatch(setJobGroup(null));
};
