import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Zoom from '@material-ui/core/Zoom';

import { ERROR } from 'common/constants/colors';
import SEVERITY from './constants';

const styles = {
  main: {
    right: 16,
    transition: 'bottom 0.5s',

    '& > div': {
      minWidth: 224,
    },
  },
  error: {
    '& > div': {
      backgroundColor: ERROR,
    },
  },
  warning: {
    '& > div': {
      backgroundColor: '#FFCC00',
    },
  },
  button: {
    boxShadow: 'none',
    padding: '0 12px',
    height: 38,
    width: 38,
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 120,
    overflowY: 'auto',

    '& span': {
      fontSize: 11,
      fontWeight: 300,
    },
  },
};

function zoomTransition(props) {
  return <Zoom {...props} />;
}

class MirageError extends React.Component {
  anchorOrigin = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  _handleClose = (e, reason) => {
    if (reason !== 'clickaway') this.props.onClose(this.props.data);
  };

  _renderAction = error => (
    <IconButton
      key="close"
      color="inherit"
      className={this.props.classes.button}
      onClick={() => this.props.onClose(error)}
    >
      <CloseIcon />
    </IconButton>
  );

  _renderMessage = error => (
    <div className={this.props.classes.message}>
      <span>
        {error.message} {error.code && `(${error.code})`}
      </span>
      <span>{error.resolution}</span>
      {error.traceid && <span>Trace ID: {error.traceid.session_key}</span>}
    </div>
  );

  render() {
    const { style, data, open } = this.props;
    const { severity } = data;
    const action = this._renderAction(data);
    const message = this._renderMessage(data);

    return (
      <Snackbar
        style={style}
        action={action}
        anchorOrigin={this.anchorOrigin}
        classes={{
          root: classnames({
            [this.props.classes.main]: true,
            [this.props.classes.error]: severity === SEVERITY.ERROR,
            [this.props.classes.warning]: severity === SEVERITY.WARNING,
          }),
        }}
        message={message}
        onClose={this._handleClose}
        open={open}
        TransitionComponent={zoomTransition}
        transitionDuration={200}
        autoHideDuration={20000}
      />
    );
  }
}

export default injectSheet(styles)(MirageError);
