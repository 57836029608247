export const DEFAULT_LIMIT = 50;

export const STATUS = {
  UNCONFIRMED: 0,
  CONFIRMED: 1,
  REJECTED: 2,
  DUPLICATE: 3,
  IGNORED: 4,
};

export const UNCONFIRMED_STATUS = 0;
export const CONFIRMED_STATUS = 1;
export const REJECTED_STATUS = 2;
export const DUPLICATE_STATUS = 3;
export const IGNORED_STATUS = 4;

/* Not a real status, but used for analyst confirmation data view */
export const RECLASSIFIED_STATUS = 5;

export const REVIEW = 'For Review';
export const CONFIRMED = 'Confirmed';
export const REJECTED = 'Not a Vehicle';
export const DUPLICATE = 'Duplicates';
export const IGNORED = 'Ignored';

export const statusToText = {
  [UNCONFIRMED_STATUS]: REVIEW,
  [CONFIRMED_STATUS]: CONFIRMED,
  [REJECTED_STATUS]: REJECTED,
  [DUPLICATE_STATUS]: DUPLICATE,
  [IGNORED_STATUS]: IGNORED,
};
