import React, { Component } from 'react';
import classnames from 'classnames';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table-v6';
import { isEmpty } from 'lodash';
import 'react-table-v6/react-table.css';
import Dialog from '@material-ui/core/Dialog';
import OnDemandVideo from '@material-ui/icons/OndemandVideo';
import Collections from '@material-ui/icons/Collections';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

import Button from 'common/components/base/Button';
import Icon from 'common/components/base/Icon';
import Tooltip from 'common/components/base/Tooltip';

import {
  fetchProcessingData,
  reprioritizeProcessingJob,
  restartProcessingJob,
} from 'library/redux/actions';
import {
  shouldRenderReprioritizationIcon,
  hasFailed,
  hasPaused,
} from 'library/redux/datasource/utils';
import { getStartDate } from 'library/redux/utils';
import { formatBytes } from 'common/helpers/stringUtils';
import { selectIsProcOnline } from 'app/header/mirageHealth/redux/selectors';
import {
  selectGlobalProcessingPanelOpen,
  selectPaginationSortBy,
} from 'library/common/globalProcessingPanel/redux/selectors';
import LoadingIcon from 'common/components/generalComponents/LoadingIcon';
import { IMAGE, RE_PRIORITY, VIDEO } from 'common/constants/app';
import {
  BRIGHT_GREEN,
  ERROR as ERROR_COLOR,
  WHITE,
  NAVY_BLUE,
  DARKEST_GREY,
  OFF_BLACK,
  SKY_BLUE,
  LIGHT_BLACK,
} from 'common/constants/colors';
import { UPLOADING, VID_ENC, FAILED } from 'library/redux/constants';
import ProcessingPanelHeader from 'library/libraryBody/headers/ProcessingPanelHeader';

import JobStatus from './JobStatus';

import { getTheadProps, getTableProps, getTrPropsForJobGroup, getTrProps } from './styles';
import {
  getTextColor,
  shouldRenderActionButtons,
  shouldRenderCancel,
  getUploadGroupName,
  shouldRenderViewAllJobsText,
} from './utils';

import {
  PRIORITY,
  UPLOAD_GROUP,
  DATASOURCE,
  STATUS,
  DESTINATION,
  UPLOADED_BY,
  UPLOADED_DATE,
  FILE_SIZE,
  ACTION,
  COLUMNS_VALES,
  COLUMNS,
  JOB_GRP_LEVEL,
  JOB_LEVEL,
  JOB_GRP_DETAIL_LEVEL,
  MAX_PAGINATED_JOB_COUNT,
  STRIPED_HIGHLIGHT,
} from './constants';
import { setPaginationParameters, setGlobalProcessingPanelOpen } from './redux/actions';
import DestinationFilePath from './DestinationFilePath';
import JobsTable from './JobsTable';
import { selectIsPaginated } from './redux/selectors';
import JobHeader from './JobHeader';

const styles = {
  root: {
    fontSize: 13,
  },
  icon: {
    marginRight: 8,
    cursor: 'pointer',
  },
  tableWrapper: {
    '& .rt-thead.-header': {
      backgroundColor: '#b2ccee',
    },
    '& .rt-thead .rt-th': {
      display: 'flex',
      alignItems: 'center',
      verticalAlign: 'middle',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  cancel: {
    color: ERROR_COLOR,
    cursor: 'pointer',
    background: WHITE,
    borderRadius: '50%',
    opacity: 1,
    fontSize: 20,
  },
  resume: {
    color: BRIGHT_GREEN,
    cursor: 'pointer',
    borderRadius: '50%',
    opacity: 1,
    fontSize: 20,
  },
  headerColor: {
    backgroundColor: SKY_BLUE,
  },
  headerColumn: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      paddingLeft: '16px',
    },
    color: NAVY_BLUE,
    fontWeight: 400,
    textTransform: 'initial',
    marginTop: '4px',
  },
  columnSpec: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    '& > span': {
      paddingLeft: '16px',
    },
    color: DARKEST_GREY,
  },
  statusDisplay: {
    fontSize: '11px',
    color: NAVY_BLUE,
  },
  cellOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  viewAllJobs: {
    fontSize: 11,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: LIGHT_BLACK,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  createdByCell: {
    textTransform: 'capitalize',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'text',
  },
};

const getNoDataProps = () => ({ 'data-testid': 'noData' });

class GlobalProcessingPanel extends Component {
  static defaultProps = {
    jobProcessingData: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: {},
      showJobLevel: false,
      loading: false,
    };

    this.table = {
      [UPLOAD_GROUP]: this.renderUploadGroupName,
      [PRIORITY]: this.renderPriority,
      [DATASOURCE]: this.renderDataSource,
      [STATUS]: this.renderStatus,
      [DESTINATION]: this.renderDestination,
      [UPLOADED_BY]: this.renderCreatedBy,
      [UPLOADED_DATE]: this.renderUploadDate,
      [FILE_SIZE]: this.renderFileSize,
      [ACTION]: this.renderActionButtons,
    };
  }

  componentDidUpdate(prevProps) {
    this.closeMenuIfAnyJobsChanged(prevProps.jobProcessingData);
  }

  closeMenuIfAnyJobsChanged = jobProcessingDataPrevious => {
    const { jobProcessingData } = this.props;
    if (
      jobProcessingDataPrevious.topLevelJobs.length !== jobProcessingData.topLevelJobs.length ||
      jobProcessingDataPrevious.inProgressJobs.length !== jobProcessingData.inProgressJobs.length ||
      jobProcessingDataPrevious.uploadingJobs.length !== jobProcessingData.uploadingJobs.length
    ) {
      this.props.closeMenu();
    }
  };

  setLoading = value => this.setState({ loading: value });

  switchToGroupView = () => {
    this.setPagination({});
    this.setState({ showJobLevel: false, loading: this.props.isPaginatedView });
  };

  getCellValue = (column, tableType, row) => {
    const showColumn = COLUMNS_VALES[column][tableType];
    if (showColumn) {
      return this.table[column](row.original, tableType);
    }
    return <div />;
  };

  getColumns = (tableType, rowInfo = {}) => [
    {
      columns: [
        {
          expander: true,
          show: false,
        },
        {
          Header: () => this.renderGroupHeader(),
          accessor: COLUMNS[UPLOAD_GROUP].accessor,
          width: COLUMNS[UPLOAD_GROUP].width,
          Cell: row => this.getCellValue(UPLOAD_GROUP, tableType, row),
          sortable: false,
        },
        {
          Header: () =>
            this.renderHeader({
              columnName: PRIORITY,
              showToolTip: tableType !== JOB_LEVEL,
            }),
          accessor: COLUMNS[PRIORITY].accessor,
          Cell: row => this.getCellValue(PRIORITY, tableType, row),
          width: COLUMNS[PRIORITY].width,
          sortable: tableType === JOB_LEVEL,
        },
        {
          id: 'dataSource',
          Header: () => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {this.renderHeader({ columnName: DATASOURCE })}
            </div>
          ),
          width: COLUMNS[DATASOURCE].width,
          Cell: row => this.getCellValue(DATASOURCE, tableType, row),
          accessor: d => {
            if (d.displayInfo.videoFilename) return d.displayInfo.videoFilename;

            if (d.displayInfo.uploadFolderNames) return d.displayInfo.uploadFolderNames;
          },
          Footer: this.getViewAllJobsFooterText(tableType, rowInfo),
          sortable: tableType === JOB_LEVEL,
        },
        {
          Header: () => this.renderHeader({ columnName: STATUS }),
          accessor: COLUMNS[STATUS].accessor,
          width: COLUMNS[STATUS].width,
          Cell: row => this.getCellValue(STATUS, tableType, row),
          disableSortBy: true,
          Footer: this.getFooterText(tableType, rowInfo),
          sortable: false,
        },
        {
          Header: () => this.renderHeader({ columnName: DESTINATION }),
          accessor: COLUMNS[DESTINATION].accessor,
          Cell: row => this.getCellValue(DESTINATION, tableType, row),
          width: COLUMNS[DESTINATION].width,
          sortable: false,
        },
        {
          Header: () => this.renderHeader({ columnName: UPLOADED_BY }),
          accessor: COLUMNS[UPLOADED_BY].accessor,
          width: COLUMNS[UPLOADED_BY].width,
          Cell: row => this.getCellValue(UPLOADED_BY, tableType, row),
          sortable: false,
        },
        {
          Header: () => this.renderHeader({ columnName: UPLOADED_DATE }),
          accessor: COLUMNS[UPLOADED_DATE].accessor,
          width: COLUMNS[UPLOADED_DATE].width,
          Cell: row => this.getCellValue(UPLOADED_DATE, tableType, row),
          sortable: false,
        },
        {
          Header: () =>
            this.renderHeader({
              columnName: FILE_SIZE,
              showToolTip: tableType !== JOB_LEVEL,
            }),
          accessor: 'displayInfo.size',
          width: COLUMNS[FILE_SIZE].width,
          Cell: row => this.getCellValue(FILE_SIZE, tableType, row),
          sortable: tableType === JOB_LEVEL,
        },
        {
          accessor: COLUMNS[ACTION].accessor,
          width: COLUMNS[ACTION].width,
          Cell: row => this.getCellValue(ACTION, tableType, row),
          style: { textAlign: 'left', marginLeft: '10px' },
        },
      ],
    },
  ];

  getViewAllJobsFooterText = (tableType, rowInfo = {}) => {
    if (!shouldRenderViewAllJobsText(tableType, rowInfo)) {
      return null;
    }

    return (
      <div>
        <Button variant={null} onClick={() => this.getViewAllJobs(rowInfo)}>
          VIEW ALL JOBS IN GROUP
        </Button>
      </div>
    );
  };

  getFooterText = (tableType, rowInfo = {}) => {
    const { jobsCount, completedJobsCount } = rowInfo;
    if (tableType !== JOB_GRP_DETAIL_LEVEL || !jobsCount) {
      return null;
    }

    const remainingJobsCount = jobsCount - MAX_PAGINATED_JOB_COUNT;
    let jobCountText = `( Total: ${jobsCount}, Completed: ${completedJobsCount} )`;
    if (jobsCount > MAX_PAGINATED_JOB_COUNT) {
      jobCountText = `+ ${remainingJobsCount} more ${jobCountText}`;
    }
    return (
      <Tooltip title={jobCountText}>
        <div style={{ padding: 8 }} data-testid="GlobalProcessingPanel-footer-count">
          {jobCountText}
        </div>
      </Tooltip>
    );
  };

  getDataSourceName = ({ displayInfo }) => {
    if (!displayInfo) return '';

    return displayInfo.videoFilename || displayInfo.uploadFolderNames;
  };

  getDataSourceIcon = ({ config, type, status }) => {
    const style = {
      minWidth: 40,
      width: 40,
    };

    if (!this.props.isProcOnline) {
      return <PauseCircleOutline style={style} />;
    }

    if (status === FAILED) {
      style.color = ERROR_COLOR;
      return <ErrorOutline style={style} />;
    }

    return (config && config.mediaType === VIDEO) || type === VID_ENC ? (
      <OnDemandVideo style={style} />
    ) : (
      <Collections style={style} />
    );
  };

  getTrGroupPropsForJobGroup = (state, rowInfo) => {
    if (rowInfo) {
      return {
        'data-testid': `GlobalProcessingPanel-group-${rowInfo.original.batchId}`,
      };
    }
    return {};
  };

  getTrPropsForJob = (state, rowInfo) => {
    if (rowInfo) {
      return {
        ...getTrProps(state, rowInfo),
        'data-testid': `GlobalProcessingPanel-job-${rowInfo.original.id}`,
      };
    }
    return {};
  };

  getExpandedRows = data => {
    if (isEmpty(this.state.expanded)) {
      // Initially keep all the rows expanded by default.
      return data.map(() => true);
    }
    return this.state.expanded;
  };

  getViewAllJobs = rowInfo => {
    this.setState({ showJobLevel: true, rowInfo, loading: true });
    this.setPagination({
      limit: MAX_PAGINATED_JOB_COUNT,
      total: rowInfo.jobsCount,
      showJobLevel: true,
      batchId: rowInfo.batchId,
    });
  };

  setPagination = ({
    sortBy = 'priority',
    limit = MAX_PAGINATED_JOB_COUNT,
    offset = 0,
    total = 0,
    showJobLevel = false,
    batchId = '',
  }) => {
    this.setState({
      showJobLevel: batchId || false,
      loading: showJobLevel,
    });

    this.props.dispatchSetPaginationParameters({
      sortBy,
      limit,
      offset,
      total,
      showJobLevel,
      batchId,
    });

    this.props.dispatchFetchProcessingData().then(() => {
      this.setLoading(false);
    });
  };

  onClose = () => {
    this.setPagination({});
    this.props.dispatchSetGlobalProcessingPanelOpen(false);
    this.setState({ showJobLevel: false });
  };

  openMenu = (e, row) => {
    this.props.openMenu(e, {
      itemType: JOB_GRP_LEVEL,
      totalGroupCount: this.props.jobProcessingData.topLevelJobs.length,
      ...row,
    });
  };

  renderGroupHeader = () => (
    <div className={this.props.classes.headerColumn}>
      <span>{COLUMNS[UPLOAD_GROUP].header}</span>
    </div>
  );

  renderHeader = ({ columnName, showToolTip = false }) => (
    <JobHeader columnName={columnName} showToolTip={showToolTip} />
  );

  renderUploadingGroupName = jobGroupName => (
    <Tooltip title={jobGroupName}>
      <div className={this.props.classes.columnSpec}>
        <span className={this.props.classes.cellOverflow} style={{ color: OFF_BLACK }}>
          {jobGroupName}
        </span>
      </div>
    </Tooltip>
  );

  renderUploadGroupName = ({ title, status, displayInfo }, tableType) => {
    let jobGroupName = title || '';

    if (status === UPLOADING) {
      return this.renderUploadingGroupName(jobGroupName);
    }

    const uploadStartTime = getStartDate(
      displayInfo.uploadTs || displayInfo.clientUploadStartTs,
      true
    );
    jobGroupName =
      displayInfo.batchName || displayInfo.uploadFolderNames || uploadStartTime || title;

    return (
      <Tooltip title={jobGroupName}>
        <div className={this.props.classes.columnSpec}>
          <div
            className={this.props.classes.cellOverflow}
            style={{
              color: tableType === JOB_GRP_LEVEL ? OFF_BLACK : getTextColor(status),
              marginLeft: 24,
            }}
            data-testid="GlobalProcessingPanel-group-name"
          >
            {jobGroupName && <span>{jobGroupName}</span>}
            {!jobGroupName && <span>{uploadStartTime}</span>}
          </div>
        </div>
      </Tooltip>
    );
  };

  renderPriority = ({ ordinalPriority }) => (
    <div style={{ textAlign: 'center' }} data-testid="GlobalProcessingPanel-job-priority">
      <span>{ordinalPriority}</span>
    </div>
  );

  renderDataSource = row => {
    const dataSourceName = this.getDataSourceName(row);
    const dataSourceIcon = this.getDataSourceIcon(row);

    return (
      <Tooltip title={dataSourceName}>
        <div
          className={this.props.classes.flexContainer}
          data-testid="GlobalProcessingPanel-job-datasource"
        >
          <div>{dataSourceIcon}</div>
          <div className={this.props.classes.cellOverflow}>{dataSourceName}</div>
        </div>
      </Tooltip>
    );
  };

  renderDestination = ({ displayInfo }) => {
    if (!displayInfo.destinationFolder) {
      return '';
    }

    return (
      <DestinationFilePath
        title={displayInfo.destinationFolder}
        mirageFolderPath={displayInfo.destinationFolder}
        data-testid="GlobalProcessingPanel-group-destination"
      />
    );
  };

  renderCreatedBy = ({ createdBy }) => (
    <Tooltip title={createdBy}>
      <span
        className={this.props.classes.createdByCell}
        data-testid="GlobalProcessingPanel-group-uploadedby"
      >
        {createdBy}
      </span>
    </Tooltip>
  );

  renderUploadDate = ({ displayInfo }) => {
    if (!displayInfo.uploadTs) {
      return null;
    }

    const uploadDateToolTipText = getStartDate(displayInfo.uploadTs, true);
    return (
      <Tooltip title={uploadDateToolTipText}>
        <div data-testid="GlobalProcessingPanel-group-started">
          {getStartDate(displayInfo.uploadTs)}
        </div>
      </Tooltip>
    );
  };

  renderFileSize = ({ displayInfo }) => (
    <div data-testid="GlobalProcessingPanel-job-filesize">
      {displayInfo.size ? formatBytes(displayInfo.size) : ''}
    </div>
  );

  renderStatus = row => {
    const { config, encodingInfo, processingInfo } = row;

    const renderStatusOnly = !config; // uploading

    if (renderStatusOnly || (config.mediaType === IMAGE && this.props.isProcOnline)) {
      const groupOrJob = !config ? 'group' : 'job';
      return (
        <div
          className={this.props.classes.statusDisplay}
          data-testid={`GlobalProcessingPanel-${groupOrJob}-status`}
        >
          <JobStatus statusInfo={row} />
        </div>
      );
    }

    return (
      <div
        className={this.props.classes.statusDisplay}
        data-testid="GlobalProcessingPanel-job-status"
      >
        {encodingInfo && encodingInfo.status && <JobStatus statusInfo={encodingInfo} />}
        {processingInfo && processingInfo.status && <JobStatus statusInfo={processingInfo} />}
        {!processingInfo.status && !encodingInfo.status && <JobStatus statusInfo={row} />}
      </div>
    );
  };

  renderCancelIcon = row => {
    if (!shouldRenderCancel(row)) {
      return null;
    }

    return (
      <Tooltip title="Cancel Processing">
        <Icon
          iconName="cancel"
          className={this.props.classes.cancel}
          onClick={() => {
            this.props.openCancelJob(row);
          }}
        />
      </Tooltip>
    );
  };

  renderJobGroupOptions = row => {
    if (hasPaused(row.status)) {
      return null;
    }

    return (
      <div>
        <Icon
          iconName="more_horiz"
          onClick={e => this.openMenu(e, row)}
          className={this.props.classes.reprioritization}
        />
      </div>
    );
  };

  renderReprioritizeIcon = ({ status, id, ordinalPriority }) => {
    if (!ordinalPriority || hasPaused(status) || !shouldRenderReprioritizationIcon(status)) {
      return null;
    }

    return (
      <Icon
        iconName="more_horiz"
        onClick={e =>
          this.props.openMenu(e, {
            itemType: RE_PRIORITY,
            id,
          })
        }
        className={this.props.classes.reprioritization}
      />
    );
  };

  renderResumeIcon = row => {
    const { status } = row;

    if (hasPaused(status) || !hasFailed(status)) {
      return null;
    }

    return (
      <Tooltip title="Resume Processing">
        <Icon
          iconName="refresh"
          className={this.props.classes.resume}
          onClick={() => {
            this.props.dispatchRestartProcessingJob(row);
          }}
        />
      </Tooltip>
    );
  };

  renderActionButtons = (row, tableType) => {
    if (tableType === JOB_GRP_LEVEL) {
      return this.renderJobGroupOptions(row);
    }

    if (!shouldRenderActionButtons(tableType)) {
      return null;
    }

    return (
      <div data-testid="GlobalProcessingPanel-job-actions">
        {this.renderCancelIcon(row)}
        {this.renderResumeIcon(row)}
        {this.renderReprioritizeIcon(row)}
      </div>
    );
  };

  renderJobLevelTable = () => (
    <JobsTable
      jobProcessingData={this.props.jobProcessingData}
      getColumns={this.getColumns(JOB_LEVEL)}
      rowInfo={this.state.rowInfo}
      setPagination={this.setPagination}
      showGroupedView={this.switchToGroupView}
    />
  );

  renderJobGroupDetailTable = row => {
    const NullComponent = () => null;
    const columns = this.getColumns(JOB_GRP_DETAIL_LEVEL, row);

    return (
      <div>
        <ReactTable
          className={classnames(this.props.classes.root, STRIPED_HIGHLIGHT)}
          getTbodyProps={getTableProps}
          getTableProps={getTableProps}
          getTheadProps={getTheadProps}
          minRows={0}
          emptyRowsWhenPaging={false}
          data={row.children}
          columns={columns}
          TheadComponent={() => null}
          showPagination={false}
          resizable={false}
          getTrProps={this.getTrPropsForJob}
          pageSize={row.children.length}
          NoDataComponent={NullComponent}
          getNoDataProps={getNoDataProps}
        />
      </div>
    );
  };

  renderJobGroupTable = data => (
    // const { tableHeader } = this.props.classes;

    <ReactTable
      className={classnames(this.props.classes.root, STRIPED_HIGHLIGHT)}
      minRows={10}
      emptyRowsWhenPaging={false}
      data={data}
      columns={this.getColumns(JOB_GRP_LEVEL)}
      expanded={this.getExpandedRows(data)}
      showPagination={false}
      resizable={false}
      getTrGroupProps={this.getTrGroupPropsForJobGroup}
      getTrProps={getTrPropsForJobGroup}
      getTbodyProps={getTableProps}
      getTheadProps={getTheadProps}
      getNoDataProps={getNoDataProps}
      // NOTE: Disabling this  for now, will enabled when BE supports
      // pagination for flat view of jobs for all job groups.
      // getTheadThProps={(state, rowInfo, column) => ({
      //   style: { tableHeader },
      //   onClick: () => {
      //     if (isJobLevel(column.id)) {
      //       this.setPagination({});
      //       this.setState({ showJobLevel: true });
      //     }
      //   },
      // })}
      SubComponent={row => this.renderJobGroupDetailTable(row.original)}
    />
  );

  renderJobsView = () => {
    const { uploadingJobs = [], topLevelJobs = [] } = this.props.jobProcessingData;
    const allJobs = topLevelJobs.concat(uploadingJobs);

    if (this.state.loading) {
      return <LoadingIcon size={40} style={{ height: '88vh' }} />;
    }
    return !this.state.showJobLevel
      ? this.renderJobGroupTable(allJobs)
      : this.renderJobLevelTable();
  };

  render() {
    return (
      <Dialog
        open={this.props.isGlobalProcessingPanelOpen}
        onBackdropClick={this.onClose}
        onClose={this.onClose}
        maxWidth="xl"
        fullWidth
      >
        <ProcessingPanelHeader
          processingData={this.props.jobProcessingData}
          toggleOpen={this.onClose}
          switchToGroupView={this.switchToGroupView}
          groupName={getUploadGroupName(this.state.rowInfo)}
          showJobLevel={this.state.showJobLevel}
        />
        <div className={this.props.classes.tableWrapper}>{this.renderJobsView()}</div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  isProcOnline: selectIsProcOnline(state),
  isGlobalProcessingPanelOpen: selectGlobalProcessingPanelOpen(state),
  isPaginatedView: selectIsPaginated(state),
  sortBy: selectPaginationSortBy(state),
});

const mapDispatchToProps = {
  dispatchReprioritizeProcessingJob: reprioritizeProcessingJob,
  dispatchRestartProcessingJob: restartProcessingJob,
  dispatchSetPaginationParameters: setPaginationParameters,
  dispatchSetGlobalProcessingPanelOpen: setGlobalProcessingPanelOpen,
  dispatchFetchProcessingData: fetchProcessingData,
};

export default compose(
  injectSheet(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(GlobalProcessingPanel);
