import axios from 'axios.js';

import { GEO_BACKEND, ANALYTICS_BACKEND, MODULES, CUSTOMER_SETTINGS } from 'common/constants/urls';

export function getGlobalSettings() {
  return axios.get(`${ANALYTICS_BACKEND}/settings/`).then(({ data }) => data);
}

/* bulk updates takes an array of settings that will be updated */
export function patchBulkGlobalSettings(settings, config) {
  return axios
    .patch(`${ANALYTICS_BACKEND}/settings/bulk_update/`, settings, config)
    .then(({ data }) => data);
}

export function postMapTileServerChoices({ id, choice }) {
  return axios
    .post(`${ANALYTICS_BACKEND}/settings/${id}/choices/`, { choice })
    .then(({ data }) => data);
}

export function deleteMapTileServerChoices({ id, choice }) {
  return axios
    .delete(`${ANALYTICS_BACKEND}/settings/${id}/choices/`, { data: { choice } })
    .then(({ data }) => data);
}

export function getActiveModules() {
  return axios
    .get(`${ANALYTICS_BACKEND}${MODULES}/`)
    .then(({ data = {} }) => data.enabledModules || []);
}

export function getCustomerSettings() {
  return axios.get(`${ANALYTICS_BACKEND}${CUSTOMER_SETTINGS}/`).then(({ data }) => data);
}

// This is new for S3 import but will hopefully be merged along with some of the other
// redundant settings endpoints.
export function getConfig() {
  return axios.get(`${ANALYTICS_BACKEND}/config/`).then(({ data }) => data);
}

/*
  Specifically for geo-settings. This is a first pass for settings on the geo-side, and we will
  likely want to combine this with FMV somehow later down the road. Currently this only houses
  the custom tile server URL for geo
*/
export function getGeoSettings() {
  return axios.get(`${GEO_BACKEND}/setting/`).then(({ data }) => data);
}

export function postGeoSettings({ key, value }) {
  return axios.post(`${GEO_BACKEND}/setting/`, { key, value }).then(({ data }) => data);
}

export function patchGeoSettings({ id, key, value }) {
  return axios.patch(`${GEO_BACKEND}/setting/${id}/`, { key, value }).then(({ data }) => data);
}
