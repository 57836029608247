import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Report = props => (
  <SvgIcon viewBox="0 0 21 26" {...props}>
    <path
      fillRule="evenodd"
      d="M.652.25l19.55-.063c.383 0 .638.313.638.626v24.562c0 .375-.255.625-.638.625H.652c-.384 0-.639-.25-.639-.625V.875C.013.5.268.25.652.25zm6.835 6.374h9.838c.384 0 .639-.25.639-.624 0-.375-.255-.625-.639-.625H7.488c-.32 0-.64.313-.64.625 0 .375.256.624.639.624zm9.84 3.189H7.488c-.383 0-.638.25-.638.624 0 .375.255.625.638.625h9.839c.383 0 .638-.25.638-.624 0-.375-.255-.625-.638-.625zm0 4.437H7.488c-.383 0-.638.25-.638.624 0 .375.255.625.638.625h9.839c.383 0 .638-.25.638-.625 0-.374-.255-.624-.638-.624zm0 4.438H7.488c-.383 0-.638.25-.638.624 0 .375.255.625.638.625h9.839c.383 0 .638-.25.638-.625s-.255-.625-.638-.625zM4.997 5.374h-1.47c-.384 0-.639.25-.639.625s.255.624.639.624h1.47c.383 0 .638-.25.638-.624 0-.375-.255-.625-.638-.625zm0 4.438h-1.47c-.384 0-.639.25-.639.624 0 .375.255.625.639.625h1.47c.383 0 .638-.25.638-.624 0-.375-.255-.625-.638-.625zm0 4.437h-1.47c-.384 0-.639.25-.639.624 0 .375.255.625.639.625h1.47c.383 0 .638-.25.638-.625 0-.374-.255-.624-.638-.624zm0 4.438h-1.47c-.384 0-.639.25-.639.624 0 .375.255.625.639.625h1.47c.383 0 .638-.25.638-.625s-.255-.625-.638-.625z"
    />
  </SvgIcon>
);

export default Report;
