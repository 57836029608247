import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MoreLikeThisPerson = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M16,13.5 C17.485, 13.5 18.895,13.815 20.17,14.37 C21.28,14.85 22,15.93 22,17.145 L22,18 L10,18 L10,17.145 C10,15.93 10.72,14.85 11.83,14.37 C13.105,13.815 14.515,13.5 16,13.5 Z M6,8 L10,12 L6,16 L6,12.8 L2,12.8 L2,11.2 L6,11.2 L6,8 Z M16,6 C17.65,6 19,7.35 19,9 C19,10.65 17.65,12 16,12 C14.35,12 13,10.65 13,9 C13,7.35 14.35,6 16,6 Z" />
  </SvgIcon>
);

export default MoreLikeThisPerson;
