import { useState, useCallback } from 'react';

import { STATUS } from 'common/constants/api';
import { getSinglePerson, getConnectCandidates, getPersons } from 'common/api/personApi';

export const useFetchPplForLibrary = () => {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(STATUS.IDLE);

  const fetchPplForLibrary = useCallback(async libraryId => {
    try {
      setStatus(STATUS.PENDING);
      const response = await getPersons();
      setData(response.filter(p => p.library === libraryId));
      setStatus(STATUS.SUCCESS);
    } catch (e) {
      setStatus(STATUS.FAILURE);
      setError(e.message);
    }
  }, []);

  return {
    error,
    status,
    data,
    fetchPplForLibrary,
    isLoaded: status === STATUS.SUCCESS,
  };
};

export const useFetchConnectCandidates = id => {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(STATUS.IDLE);

  const fetchConnectCandidates = useCallback(async () => {
    try {
      setStatus(STATUS.PENDING);
      const response = await getConnectCandidates(id, { page: 1, page_size: 50 });
      setData(response.data);
      setStatus(STATUS.SUCCESS);
    } catch (e) {
      setStatus(STATUS.FAILURE);
      setError(e.message);
    }
  }, [id]);

  return {
    error,
    status,
    data,
    fetchConnectCandidates,
    isLoaded: status === STATUS.SUCCESS,
  };
};

export const useFetchPerson = id => {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(STATUS.IDLE);

  const fetchPerson = useCallback(async () => {
    try {
      setStatus(STATUS.PENDING);
      setData(await getSinglePerson(id));
      setStatus(STATUS.SUCCESS);
    } catch (e) {
      setStatus(STATUS.FAILURE);
      setError(e.message);
    }
  }, [id]);

  return {
    error,
    status,
    data,
    fetchPerson,
    isLoaded: status === STATUS.SUCCESS,
  };
};
