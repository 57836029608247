import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { noop } from 'lodash';

import { ImageOverlay, useMap } from 'react-leaflet';

const MapImage = ({ imageUrl, onImageLoad = noop }) => {
  const map = useMap();
  const [imageBounds, setImageBounds] = useState(null);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      const isHorizontal = image.width > image.height;
      const ratio = isHorizontal ? image.height / image.width : image.width / image.height;

      const bounds = isHorizontal
        ? [
            [-ratio / 2, 0.5],
            [ratio / 2, -0.5],
          ]
        : [
            [-0.5, ratio / 2],
            [0.5, -ratio / 2],
          ];

      onImageLoad(bounds);

      map.setView([0, 0], 10);
      map.setMaxBounds(
        new L.LatLngBounds([
          [-1, ratio],
          [1, -ratio],
        ])
      );
      map.fitBounds(bounds);
      setImageBounds(bounds);
    };
  }, [imageUrl, map, onImageLoad]);

  if (!imageBounds) return null;

  return <ImageOverlay bounds={imageBounds} minZoom={1} maxZoom={18} url={imageUrl} zIndex={10} />;
};

MapImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onImageLoad: PropTypes.func,
};

export default MapImage;
