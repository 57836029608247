import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';
import { DateTime } from 'luxon';

import { selectCustomerIdleLogoutTime } from 'settings/redux/selectors';
import { selectIsAuthenticated } from 'auth/redux/selectors';

import { signout } from 'auth/redux/actions';

const useAuthenticationIdleTimer = () => {
  const dispatch = useDispatch();
  const idleTimeoutSeconds = useSelector(selectCustomerIdleLogoutTime);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const lastActiveTimestamp = useRef(DateTime.now());

  const resetTimer = throttle(() => {
    lastActiveTimestamp.current = DateTime.now();
  }, 10000);

  useEffect(() => {
    if (!idleTimeoutSeconds || !isAuthenticated) return;
    resetTimer();

    window.addEventListener('keypress', resetTimer);
    window.addEventListener('resize', resetTimer);
    window.addEventListener('scroll', resetTimer);
    window.addEventListener('mousemove', resetTimer);

    return () => {
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('resize', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      window.removeEventListener('mousemove', resetTimer);
    };
  }, [idleTimeoutSeconds, isAuthenticated, resetTimer]);

  useEffect(() => {
    if (!idleTimeoutSeconds || !isAuthenticated) return;

    const interval = setInterval(() => {
      const lastActiveMoment = lastActiveTimestamp.current;
      const currentMoment = DateTime.now();
      const difference = currentMoment.diff(lastActiveMoment, 'seconds').seconds;

      if (difference > idleTimeoutSeconds) {
        dispatch(signout());
      }
    }, 10000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [dispatch, idleTimeoutSeconds, isAuthenticated]);
};

export default useAuthenticationIdleTimer;
