import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import { isNumber, throttle } from 'lodash';

import { createCancelablePromise } from 'common/helpers/helperFunctions';
import { getCacheBreakURL } from 'common/helpers/urlUtils';
import { getLiveFeed, getLiveFeedStartTime } from 'common/api/liveFeed';
import { parseDurationToHHMMSS } from 'common/helpers/dateUtils';
import { sortByFromTime } from 'common/helpers/sortFunctions';
import { getDateInMs } from 'common/helpers/datasourceUtils';
import { selectInspectModeTypes } from 'common/redux/models/selectors';
import { isCameraLive } from 'library/redux/camera/utils';
import { getVideoTimestampBoundaries } from 'library/redux/datasource/utils';
import { getDatasourceFrameRate } from 'common/api/datasourceApi';
import { fetchDetectionObjects } from 'common/redux/models/objectActions';
import { renderErrorMessage } from 'app/redux/actions';
import { extractErrorMessage } from 'common/helpers/apiErrorUtils';
import {
  selectFetchAllDetectionsInInspectMode,
  selectIsManualIdentityDrawingEnabled,
  selectShowConfidenceSlider,
} from 'settings/redux/selectors';

import { ANALYSIS_VIDEO } from 'common/constants/app';
import { TEXT } from 'common/constants/colors';
import emitter, {
  SHORTCUT_ANIMATION,
  VIDEOPLAYER_PROGRESS,
  VIDEOPLAYER_SEEK,
} from 'common/constants/emitter';
import { DEFAULT_ZOOM } from 'common/constants/panAndZoom';
import { PLAY_PAUSE } from 'common/constants/shortcuts';

import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import Delay from 'common/components/base/Delay';
import InspectOverlay from 'components/Detections/InspectOverlay';
import DetectionOverlay from 'components/Detections/DetectionOverlay';
import PanAndZoom from 'common/components/panAndZoom/PanAndZoom';

import DashVideo from './DashVideo';
import DashVideoLatest from './DashVideoLatest';
import KineticPlayer from './KineticPlayer';
import VideoControlsAndProgressBar from './controls/VideoControlsAndProgressBar';
import VideoShortcutAnimations from './lib/VideoShortcutAnimations';
import VideoPlayerDateInfo from './VideoPlayerDateInfo';

import LabelingModeButton from './lib/LabelingModeButton';
import VideoSidebar from './lib/sidebar/VideoSidebar';
import { normalizeVideoResolution } from './lib/utils';

import {
  buildInterpolator,
  getNearbyDetections,
  normalizeFrames,
  shouldFetchDetections,
} from './utils/utils';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    flexGrow: 1,
    minHeight: 0,
    opacity: props => (!props.videoId ? 0.7 : 1),

    '& video': {
      height: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    minHeight: 0,
    flexGrow: 1,
  },
  controlWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  videoWrapper: {
    position: 'relative',
    textAlign: 'center',
    background: TEXT,
    overflow: 'hidden',
    flexGrow: 1,
    minHeight: 0,

    '& video': {
      width: '100%',
      objectFit: 'contain',
    },
  },
  noVideoOverlay: {
    background: TEXT,
    textAlign: 'center',
    fontSize: 20,
    color: 'white',
    paddingTop: 24,
  },
};

function buildManifestUrl({ isLive, videoId }) {
  const baseUrl = getCacheBreakURL(
    isLive ? getLiveFeed(videoId) : `api/ds/${videoId}/manifest.mpd`
  );

  return baseUrl;
}

/* This component uses several non-standard React patterns in order
to make efficiency gains. We do not want to rely on React Lifecycle for
any high speed changes as it can be expensive operation. Updates are event driven
and powered by requestAnimationFrame which runs approx every 17 milliseconds
from inside inside DashVideo.jsx. We also pass this.video to child components
which is also non-standard and creates a coupling, but this helps with code
modularity. We should make very light use of componentDidUpdate (only
for major prop changes) and explicity call functions on this component
from parent components when neccessary such as: videoPlayer.seekTo(1020) */
class VideoPlayer extends React.Component {
  static defaultProps = {
    datasource: {},
    inspecting: false,
    isFrameInspection: false,
    onLoadStartTime: 0,
    searchFrames: [],
    segments: [],
    startEpochMs: 0,
    startDate: 0,
    useLatestPlayer: false,
    videoStyles: {},
  };

  static propTypes = {
    inspecting: PropTypes.bool, // whether or not you are in inspect mode, overrides current state
    isFrameInspection: PropTypes.bool, // whether you are in the mode where current/named detections are always shown
    hideSidebar: PropTypes.bool,
    playVideoOnLoad: PropTypes.bool, // play the video automatically when ready
    hideInspectOverlay: PropTypes.bool, // hide the inspect mode tooltip
    bindRef: PropTypes.func, // bind to the VideoPlayer React Component element
    searchFrames: PropTypes.arrayOf(PropTypes.object).isRequired, // frames given by the backend sparksearch
    // onLoadStartTime is the time in seconds for where the video initializes. This is the time
    // provided by the detection (the 13-digit date integer in milliseconds / 1000) or a
    // relative timestamp based on the video
    onLoadStartTime: PropTypes.number,
    datasource: PropTypes.object.isRequired, // the entire datasource object from the database
    useLatestPlayer: PropTypes.bool, // Set to true to use the latest version of dashjs
    videoStyles: PropTypes.object, // styles applied directly to the video DOM element
  };

  constructor(props) {
    super(props);

    this._fetchDetectionsIfInRange = throttle(this._fetchDetectionsIfInRange, 500);
    this._seekToOnLoadStartTime = throttle(this._seekToOnLoadStartTime, 300);
  }

  state = {
    confidenceThreshold: 20,
    frames: [],
    frameRate: 16,
    hasInitialized: false,
    inspectFrames: this.props.isFrameInspection,
    inspecting: this.props.inspecting || this.props.isFrameInspection,
    isLabeling: false,
    isFetching: false,
    isLoadingNewSource: false,
    isPlaying: false,
    isPlayDisabled: false,
    isSeeking: false,
    liveFeedStartTime: 0,
    manifestUrl: '',
    playbackRate: 1,
    rangeStart: null,
    rangeEnd: null,
    inspectTypes: [],
    additionalControlsPadding: false,
    zoom: DEFAULT_ZOOM,
  };

  componentDidMount() {
    if (this.props.bindRef) {
      this.props.bindRef(this);
    }

    if (this.props.videoId) {
      this.handleManifestRefresh();
      this._getVideoMetadata();

      if (this.state.inspecting) {
        this._fetchDetections();
      }
    }

    // The object models must be fetched because when inspect mode is active detections
    // are filtered by inspectTypes, and inspectTypes is built from object models
    this.cancelablePromises.fetchDetections = createCancelablePromise(
      this.props.dispatchFetchDetectionObjects
    );
    this.cancelablePromises
      .fetchDetections()
      .then(() => {
        this.setState({ inspectTypes: this.props.inspectModeTypes.map(({ id }) => id) });
      })
      .catch(() => {});

    this.unbind = emitter.on(VIDEOPLAYER_SEEK, this.seekTo);
  }

  componentDidUpdate(prevProps, prevState) {
    const { videoId, isLive, onLoadStartTime } = this.props;

    if (videoId) {
      if (prevProps.videoId !== videoId) {
        this._getVideoMetadata();
        if (this.props.isFrameInspection) {
          this.showInspectModeDetections();
        }
      }

      if (
        prevProps.isFrameInspection !== this.props.isFrameInspection ||
        (this.state.inspecting && prevState.inspectTypes !== this.state.inspectTypes)
      ) {
        this.showInspectModeDetections();
      }

      if (
        prevProps.shouldRefreshFrameDetections !== this.props.shouldRefreshFrameDetections ||
        prevState.inspectFrames !== this.state.inspectFrames
      ) {
        this.refreshInspectModeDetections();
      }

      if (this.state.playbackRate !== this.video._video.playbackRate) {
        this.setPlaybackRate(this.state.playbackRate);
      }

      if (onLoadStartTime !== prevProps.onLoadStartTime) {
        this._seekToOnLoadStartTime();
      } else if (prevProps.videoId !== videoId && this.state.inspecting) {
        // If the datasource updates but the onLoadStartTime does not, fetch
        // inspect detections for the new datasource
        this._fetchDetections();
      }

      if (this.props.searchFrames !== prevProps.searchFrames) {
        this.sync();
      }

      if (prevProps.videoId !== videoId || isLive !== prevProps.isLive) {
        this.resetVideoDefaults();
        this.handleManifestRefresh();

        if (this.detectionOverlay) {
          this.detectionOverlay.clearCanvas();
        }
      }

      if (prevProps.onLoadStartTime !== onLoadStartTime) {
        if (this.state.hasInitialized !== this._getHasStreamLoaded()) {
          this.resetVideoDefaults();
        }

        if (onLoadStartTime > this._getActiveStreamLength()) {
          this.handleManifestRefresh();
        }
      }
    }
  }

  componentWillUnmount() {
    this.unbind();
    Object.keys(this.cancelablePromises).forEach(key => this.cancelablePromises[key]?.cancel());
  }

  cancelablePromises = {};

  dashVideo = null;

  dateOverlay = null;

  detectionOverlay = null;

  progress = null;

  seekToValue = null;

  video = null;

  bindDashRef = node => (this.dashVideo = node);

  bindDateOverlayRef = node => (this.dateOverlay = node);

  bindDetetionOverlayRef = node => (this.detectionOverlay = node);

  bindProgressBarRef = node => (this.progress = node);

  getVideoDuration = () => (this.video ? this.video.getDuration() : 0);

  refreshInspectModeDetections = secondsValue => {
    if (this.props.isFrameInspection) {
      const seconds = secondsValue || this.video.getTime();
      const absoluteTime = this._getAbsoluteTime(seconds);
      this._fetchDetections(absoluteTime);
    }
  };

  showInspectModeDetections = () => {
    const seconds = this.video.getTime();
    const absoluteTime = this._getAbsoluteTime(seconds);
    this.setState({ inspecting: true }, () => {
      /* Fetch detections will update bounding boxes when the response returns */
      this._fetchDetections(absoluteTime);
    });
  };

  handleManifestRefresh = () => {
    const manifestUrl = buildManifestUrl({
      isLive: this.props.isLive,
      videoId: this.props.videoId,
    });

    if (this.props.isLive) {
      this.cancelablePromises.getLiveFeedStartTime = createCancelablePromise(getLiveFeedStartTime);
      this.cancelablePromises
        .getLiveFeedStartTime(this.props.videoId)
        .then(liveFeedStartTime => this.setState({ liveFeedStartTime }))
        .catch(() => {});
    }

    this.setState({ manifestUrl });
    this._setIsLoadingNewSource(true);
  };

  getIsPlayDisabled = () => !(this.video && this.video.canPlay());

  onConfidenceThresholdChange = confidenceThreshold =>
    this.setState({ confidenceThreshold }, this.sync);

  onLoadFinish = () => {
    this._onVideoLoadFinish();

    if (this.props.onLoadFinish) this.props.onLoadFinish();
  };

  pause = () => {
    if (!this.video) return;

    if (this.props.isLive) {
      this.seekToValue = this.video.getTime(); // Required to properly pause a live feed > 12 hours
    }

    this.video.pause();
  };

  play = () => {
    if (!this.video) return;

    this.video.play();
  };

  onPause = () => this.setState({ isPlaying: false });

  onPlay = () => this.setState({ isPlaying: true });

  replay = () => {
    if (this.props.isFrameInspection) {
      this.props.onStartTimeChanged(0);
    }

    this.seekTo(0);
    this.play();
  };

  resetVideoDefaults = () => this.setState({ hasInitialized: false });

  seekTo = seconds => {
    if (!this.video) return;

    if (
      this.props.isLive ||
      // The following two checks are required for camera recordings. They are
      // recorded in 12-hour streams, so if the seekTo time is less than the
      // start time or greater than the length we are seeking from one 12-hour
      // stream to the next. Test with a camera recording > 12 hours in length
      seconds < this._getActiveStreamStartTime() ||
      seconds > this._getActiveStreamLength()
    ) {
      this.seekToValue = seconds;
    }

    this.video.setTime(seconds);
    this._updateVideoProgressBar(seconds);
  };

  setPlaybackRate = playbackRate => {
    if (playbackRate !== this.state.playbackRate) {
      this.setState({
        playbackRate,
      });
    }
    this.video._video.playbackRate = playbackRate;
  };

  sync = () => {
    if (!this.video) return;
    this._syncTime(this.video.getTime());
  };

  togglePlay = (shouldPlay = this.video.isPaused()) => (shouldPlay ? this.play() : this.pause()); // eslint-disable-line no-unused-expressions

  /* When the video is done seeking update overlay */
  _doneSeeking = () => {
    const hasLoaded = this.props.isLive ? this._getHasStreamLoaded() : this.state.hasInitialized;

    if (hasLoaded) {
      this.sync();
      this._setIsLoadingNewSource(false);
      this._setIsSeeking(false);
    }
  };

  _fetchDetections = (absoluteTime = this.props.onLoadStartTime * 1000) => {
    /* Don't send too many api requests */
    // NOTE: Currently, fetching inspect detections for the live player is
    // disabled because inspect detections are ~30 seconds behind, so the call
    // does not return recent-enough detections to be relevant
    if (this.state.isFetching || this.props.isLive) return;

    this.setState({ isFetching: true });

    const {
      datasource: { id },
      showAllDetections,
    } = this.props;
    const { start: videoStart, end: videoEnd } = getVideoTimestampBoundaries(this.props.datasource);
    const includePersons = this.props.isFrameInspection;

    this.cancelablePromises.getNearbyDetections = createCancelablePromise(getNearbyDetections);

    return this.cancelablePromises
      .getNearbyDetections(
        id,
        absoluteTime,
        videoStart,
        videoEnd,
        showAllDetections || this.state.inspecting,
        includePersons
      )
      .then(this._setFrames)
      .catch(err => {
        if (!err.isCanceled) {
          this.setState({ isFetching: false });
          renderErrorMessage(
            `Retrieving additional detections failed: ${extractErrorMessage(err)}`
          );
        }
      });
  };

  _fetchDetectionsIfInRange = absoluteTime => {
    const { rangeStart, rangeEnd } = this.state;
    if (
      this.props.isLive ||
      (this.state.inspecting && shouldFetchDetections(absoluteTime, rangeStart, rangeEnd))
    ) {
      this._fetchDetections(absoluteTime);
    }
  };

  _getAbsoluteTime = seconds => {
    const { isLive, startEpochMs } = this.props;

    if (isLive) return this._getDurationAsUTC();
    if (!startEpochMs) return seconds * 1000;

    return startEpochMs ? Math.round(seconds * 1000) + startEpochMs : Math.round(seconds * 1000);
  };

  _getDurationAsUTC = () => this.dashVideo?.getDashPlayer()?.durationAsUTC() * 1000 || 0;

  _getActiveStream = () => this.dashVideo?.getDashPlayer()?.getActiveStream();

  _getActiveStreamLength = () => {
    const activeStream = this._getActiveStream();

    if (!activeStream) return 0;

    return activeStream.getStartTime() + activeStream.getDuration();
  };

  _getActiveStreamStartTime = () => {
    const activeStream = this._getActiveStream();

    if (!activeStream) return null;

    return activeStream.getStartTime();
  };

  _getHasStreamLoaded = () => {
    const seekValue = this._getSeekValue();

    return seekValue <= this._getActiveStreamLength();
  };

  _getInspectFrames = () => (this.state.inspecting ? this.state.frames : []);

  _getSeekValue = () => this.seekToValue || this.props.onLoadStartTime;

  _getIsVideoLoading = () =>
    this.state.isFetching || this.state.isLoadingNewSource || this.state.isSeeking;

  _getVideoMetadata = () => {
    this.cancelablePromises.getDatasourceFrameRate = createCancelablePromise(
      getDatasourceFrameRate
    );
    this.cancelablePromises
      .getDatasourceFrameRate(this.props.videoId)
      .then(data => {
        const frameRate = data.n / data.d;

        if (!Number.isNaN(frameRate)) {
          this.setState({ frameRate });
        }
      })
      .catch(() => {});
  };

  _handleExternalPause = () => this.setState({ isPlaying: false });

  _onTimeChange = seconds => {
    if (isNumber(seconds)) {
      const absoluteTime = this._getAbsoluteTime(seconds);
      this._fetchDetectionsIfInRange(absoluteTime);
      emitter.emit(VIDEOPLAYER_PROGRESS, absoluteTime);
      if (this.props.onTimeChange) this.props.onTimeChange(absoluteTime);
    }
  };

  _onVideoMount = node => (this.video = node);

  _onVideoLoadStart = () => {
    this.onPause();

    if (!this.state.isLoadingNewSource) {
      this._setIsLoadingNewSource(true);
    }

    /* Remove date when video is loading since the date will not be correct */
    this._updateDateOverlay(null);
  };

  _onVideoLoadFinish = () => {
    if (this.props.playVideoOnLoad && this.video && this.video.isPaused()) {
      this.play();
    }

    if (!this.state.hasInitialized) {
      // If the activeStream does not contain the value to be seeked to
      // another video segment will be loaded, and _onVideoLoadFinish will be
      // called again. Although the seek time is outside of the activeStream,
      // we must call seekTo on that value in order to load the appropriate stream
      const hasStreamLoaded = this._getHasStreamLoaded();
      const seekValue = this._getSeekValue();

      if (!this.props.isLive) {
        this.seekTo(seekValue);
        this.sync();

        if (hasStreamLoaded) this.seekToValue = null;
      }

      // isLoadingNewSource is set to false here if the video player will
      // not seek to the onLoadStartTime. Otherwise, isLoadingNewSource is set to
      // false in the _doneSeeking method. By setting it in _doneSeeking, we
      // prevent the first frame from flashing
      if (!this.props.isLive && seekValue <= 0) {
        this._setIsLoadingNewSource(false);
      }

      if (this.state.hasInitialized !== hasStreamLoaded) {
        this.setState({ hasInitialized: hasStreamLoaded });
      }
    }
    // This is required for seeking with the progress bar in non-live datasources
    // greater than 12 hours
    else if (this.seekToValue) {
      this.seekTo(this.seekToValue);
      this.sync();
      this.seekToValue = null;
    }
  };

  _onVideoClick = e => {
    const clickedOnVideo = e.target.getAttribute('data-testid') === 'VideoPlayer-detectionOverlay';
    if (this.video && !this.getIsPlayDisabled() && clickedOnVideo) {
      this.togglePlay();
      this.sync();

      emitter.emit(SHORTCUT_ANIMATION, PLAY_PAUSE);
    }
  };

  _onJumpToTime = time => {
    if (this.props.isLive) {
      this.props.toggleIsLive(false);
    }

    this.seekTo(time);
  };

  // The detection overlays capture all events so knetic overlay cannot
  // receive wheel events directly, so we have to wrap them from the top
  // like this.
  _onWheel = e => {
    if (this.video && this.video.handleWheelEvent) {
      this.video.handleWheelEvent(e);
    }
  };

  _onPanAndZoomChange = ({ zoom }) => {
    this.setState({ zoom });
  };

  _seekToOnLoadStartTime = () => {
    // Pause video when switching detections
    if (!this.props.isLive) this.pause();

    /* Kind of a hack for now, but the live monitoring requires the inspect mode to be permanently on */
    if (this.state.inspecting) {
      this._fetchDetections();
    }
  };

  _setFrames = ({ frames, start, end }) => {
    this.setState(
      {
        frames,
        rangeStart: start,
        rangeEnd: end,
        isFetching: false,
      },
      this.sync
    );
  };

  _setIsLoadingNewSource = isLoadingNewSource => {
    if (this.props.setIsLoadingNewSource) {
      this.props.setIsLoadingNewSource(isLoadingNewSource);
    }

    this.setState({ isLoadingNewSource });
  };

  _setIsSeeking = isSeeking => {
    if (this.props.setIsSeeking) {
      this.props.setIsSeeking(isSeeking);
    }

    this.setState({ isSeeking });
  };

  _shouldRenderLiveIcon = () =>
    // The LIVE icon is shown if the onClick function for the LIVE icon is defined
    !!this.props.onClickLiveIcon;

  _syncTime = seconds => {
    if (this.getIsPlayDisabled() !== this.state.isPlayDisabled) {
      this.setState({ isPlayDisabled: this.getIsPlayDisabled() });
    }

    if (!this.video || !this.video.hasLoadedCurrentFrame()) return;
    if (this.props.isLive && !this.dashVideo) return;
    const isRecording = isCameraLive(this.props.cameraStatus);
    const shouldConvertToLiveMode =
      this.props.livePlayer &&
      !this.props.isLive &&
      isRecording &&
      !this.video.canPlay() &&
      this.state.isPlaying;
    if (shouldConvertToLiveMode) {
      return this.props.onClickLiveIcon();
    }
    this._updateDurationOverlay(seconds);
    this._updateDateOverlay(seconds);
    this._updateDetectionsOverlay(seconds);
    this._updateVideoProgressBar(seconds);
    this._onTimeChange(seconds);
  };

  _startSeeking = () => this._setIsSeeking(true);

  _toggleInspectMode = () => {
    if (this.props.isFrameInspection) {
      this.setState(prevState => ({ inspectFrames: !prevState.inspectFrames }));
    } else if (this.state.inspecting) {
      // Get rid of bounding boxes by syncing video player
      // Exit labeling mode when inspect mode is turned off
      this.setState({ isLabeling: false, inspecting: false }, this.sync);
    } else {
      this.showInspectModeDetections();
    }

    if (!this.props.isFrameInspection) {
      this.setState(prevState => ({
        additionalControlsPadding: !prevState.additionalControlsPadding,
      }));
    }

    if (this.props.onToggleInspectMode) {
      this.props.onToggleInspectMode(this.state.inspecting);
    }
  };

  _toggleLabelingMode = () => {
    const { isLabeling } = this.state;

    this.setState({ isLabeling: !isLabeling }, () => {
      // When entering into labeling mode, pause video
      if (!isLabeling) {
        this.pause();
      }
    });
  };

  _updateVideoProgressBar = seconds => this.progress?.setTime(seconds);

  _updateDetectionsOverlay = seconds => this.detectionOverlay?.setTime(seconds);

  _updateDateOverlay = seconds => this.dateOverlay?.setTime(seconds);

  _updateDurationOverlay = seconds => {
    const duration = parseDurationToHHMMSS(this.getVideoDuration() * 1000);
    const time = parseDurationToHHMMSS(seconds * 1000);

    if (this.props.isLive) {
      this.durationOverlay.innerHTML = `+ ${time}`;
    } else {
      this.durationOverlay.innerHTML = `+ ${time} / ${duration}`;
    }
  };

  renderControls = () => {
    const hasSrc = !!this.props.videoId;
    const isRecording = isCameraLive(this.props.cameraStatus);
    const isVideoLoading = this._getIsVideoLoading();
    const areControlsDisabled = !hasSrc || !this.state.hasInitialized || isVideoLoading;

    const segments = this.props.segments
      .concat(this._getInspectFrames().map(normalizeFrames))
      .sort(sortByFromTime);

    return (
      <VideoControlsAndProgressBar
        areControlsDisabled={areControlsDisabled}
        bindProgressBarRef={this.bindProgressBarRef}
        cameraDisplayName={this.props.cameraDisplayName}
        cameraStatus={this.props.cameraStatus}
        frameRate={this.state.frameRate}
        getVideoDuration={this.getVideoDuration}
        hasInitialized={this.state.hasInitialized}
        inspecting={this.state.inspecting}
        isCameraRecording={isRecording}
        isLive={this.props.isLive}
        isNearLiveEdge={this.props.isNearLiveEdge}
        isPlaying={this.state.isPlaying}
        isPlayDisabled={this.state.isPlayDisabled}
        isProgressBarDisabled={!hasSrc || !this.state.hasInitialized}
        offset={this.props.startDate}
        onClickLiveIcon={this.props.onClickLiveIcon}
        onStartTimeChanged={this.props.onStartTimeChanged}
        pause={this.pause}
        play={this.play}
        replay={this.replay}
        seekTo={this.seekTo}
        segments={segments}
        setDurationOverlayRef={node => (this.durationOverlay = node)}
        shouldRenderLiveIcon={this._shouldRenderLiveIcon()}
        startEpochMs={this.props.startEpochMs}
        togglePlay={this.togglePlay}
        toggleIsLive={this.props.toggleIsLive}
        video={this.video}
      />
    );
  };

  renderNoVideo = () => {
    const { classes, error, hideSidebar, showAllDetections } = this.props;

    const overlay = this.state.isFetching ? (
      <LoadingOverlay backgroundColor="transparent" />
    ) : (
      <div className={classes.noVideoOverlay}>{error && 'Video could not be played'}</div>
    );

    return (
      <div className={classes.main}>
        <div className={classes.wrapper}>
          <div className={classes.controlWrapper}>
            <div className={classes.videoWrapper}>{overlay}</div>
            {this.renderControls()}
          </div>

          <VideoSidebar
            disabled
            hideSidebar={hideSidebar}
            showAllDetections={showAllDetections}
            playbackRate={this.state.playbackRate}
          />
        </div>
      </div>
    );
  };

  renderDetectionOverlay = () => {
    const {
      disablePlayPause,
      isLive,
      isNearLiveEdge,
      searchFrames,
      showAllDetections,
      startEpochMs,
      videoHeight,
      videoWidth,
      videoId,
      trackletId,
    } = this.props;

    const interpolator = buildInterpolator({
      searchedFrames: searchFrames,
      inspectedFrames: this._getInspectFrames(),
      options: {
        videoStartTime: startEpochMs,
        frameRate: this.state.frameRate,
        confidenceThreshold: this.state.confidenceThreshold,
        showAllDetections,
      },
    });

    const isVideoLoading = this._getIsVideoLoading();

    /**
     Canvas pixel size calculations are based on width and height, since videos
     have different resolutions, the text and boxes will change sizes depending on
     the video, which can lead to awkward user experience and off-screen/tiny text.
     We therefore normalize the resolution to keep text and box sizes consistent
     */
    const { width, height } = normalizeVideoResolution(videoWidth, videoHeight);

    return (
      <DetectionOverlay
        analysisType={ANALYSIS_VIDEO}
        confidenceThreshold={this.state.confidenceThreshold}
        contentHeight={height}
        contentWidth={width}
        disablePlayPause={disablePlayPause}
        frameRate={this.state.frameRate}
        inspectFrames={this.state.inspectFrames}
        inspecting={this.state.inspecting}
        inspectTypes={this.state.inspectTypes}
        interpolator={interpolator}
        isFrameInspection={this.props.isFrameInspection}
        isLive={isLive && isNearLiveEdge}
        isLoading={isVideoLoading}
        isLabeling={this.state.isLabeling}
        pause={this.pause}
        ref={this.bindDetetionOverlayRef}
        startEpochMs={startEpochMs}
        video={this.video}
        videoId={videoId}
        trackletId={trackletId}
        zones={this.props.zones}
        zoom={this.state.zoom}
        livePlayer={this.props.livePlayer}
        liveQuery={this.props.liveQuery}
      />
    );
  };

  onInspectTypesSelection = (inspectTypes = []) => this.setState({ inspectTypes });

  renderInspectOverLay = () => {
    const { hideInspectOverlay, showConfidenceSlider } = this.props;

    return (
      <InspectOverlay
        hideInspectOverlay={hideInspectOverlay}
        inspecting={this.state.inspecting}
        confidenceThreshold={this.state.confidenceThreshold}
        showConfidenceSlider={showConfidenceSlider}
        inspectModeTypes={this.props.inspectModeTypes}
        onInspectTypesSelection={this.onInspectTypesSelection}
      />
    );
  };

  renderPlayer = () => {
    const {
      autoPlay,
      isDsProcessedWithSwl,
      isLive,
      kinetic,
      muted,
      startEpochMs,
      useLatestPlayer,
      videoStyles,
    } = this.props;

    const dashStyles = {
      ...videoStyles,
      // When video is loading new source, it flashes the beginning of the video on load. We prevent
      // the user from seeing this by hiding the videoplayer entirely for a second
      ...(this.state.isLoadingNewSource && { opacity: 0 }),
      // When in labeling mode, we disable playing or pausing video by clicking
      ...(this.state.isLabeling && { pointerEvents: 'none' }),
    };
    const isKineticSeekEnabled =
      kinetic && 'requestVideoFrameCallback' in HTMLVideoElement.prototype;
    const Player = isKineticSeekEnabled
      ? KineticPlayer
      : useLatestPlayer
      ? DashVideoLatest
      : DashVideo;

    const startTag = this.props.onLoadStartTime ? `#t=${this.props.onLoadStartTime}` : '';
    const source = this.state.manifestUrl ? `${this.state.manifestUrl}${startTag}` : '';

    return (
      <Player
        autoPlay={autoPlay}
        bindRef={this.bindDashRef}
        frameRate={this.state.frameRate}
        isLive={isLive}
        isDsProcessedWithSwl={isDsProcessedWithSwl}
        liveFeedStartTime={this.state.liveFeedStartTime}
        muted={muted}
        onKinetic={this._handleExternalPause}
        onPause={this.onPause}
        onPlay={this.onPlay}
        onProgress={this._syncTime}
        onLoadStart={this._onVideoLoadStart}
        onLoadFinish={this.onLoadFinish}
        onSeekStart={this._startSeeking}
        onSeekFinish={this._doneSeeking}
        ref={this._onVideoMount}
        src={source}
        startEpochMs={startEpochMs}
        style={dashStyles}
      />
    );
  };

  renderPlayerBody = () => {
    const {
      classes,
      hidePanAndZoomControls,
      isFrameInspection,
      isManualIdentityDrawingEnabled,
      startDate,
      videoWidth,
      videoHeight,
      datasource,
    } = this.props;
    const { inspecting, inspectFrames, isLabeling } = this.state;
    const isVideoLoading = this._getIsVideoLoading();

    return (
      <div className={classes.videoWrapper}>
        {isVideoLoading && (
          <Delay>
            <LoadingOverlay backgroundColor="transparent" />
          </Delay>
        )}
        <VideoShortcutAnimations video={this.video} />
        <VideoPlayerDateInfo
          ref={this.bindDateOverlayRef}
          date={startDate}
          getVideoDuration={this.getVideoDuration}
          onJumpToTime={this._onJumpToTime}
          displayTimezoneName={datasource?.displayTimezoneName}
        />
        <PanAndZoom
          id="VideoPlayer-pan-and-zoom"
          resolutionWidth={videoWidth}
          resolutionHeight={videoHeight}
          additionalControlsPadding={this.state.additionalControlsPadding}
          hidePanAndZoomControls={hidePanAndZoomControls}
          onChildClick={this._onVideoClick}
          onChange={this._onPanAndZoomChange}
        >
          {this.renderPlayer()}
          {this.renderDetectionOverlay()}
        </PanAndZoom>

        {isManualIdentityDrawingEnabled && !inspectFrames && inspecting && (
          <LabelingModeButton isLabeling={isLabeling} onToggle={this._toggleLabelingMode} />
        )}
        {!isFrameInspection && this.renderInspectOverLay()}
      </div>
    );
  };

  render() {
    const {
      className,
      classes,
      hideSidebar,
      isFrameInspection,
      showAllDetections,
      showConfidenceSlider,
      videoId,
    } = this.props;

    const hasSrc = !!videoId;

    if (!hasSrc) {
      return this.renderNoVideo();
    }

    return (
      <div
        data-testid="VideoPlayer"
        className={classnames(classes.main, className)}
        onWheel={this._onWheel}
      >
        <div className={classes.wrapper}>
          <div className={classes.controlWrapper}>
            {this.renderPlayerBody()}
            {this.renderControls()}
          </div>

          <VideoSidebar
            confidenceThreshold={this.state.confidenceThreshold}
            hideSidebar={hideSidebar}
            inspectFrames={this.state.inspectFrames}
            inspecting={this.state.inspecting}
            isFrameInspection={isFrameInspection}
            onConfidenceThresholdChange={this.onConfidenceThresholdChange}
            play={this.play}
            playbackRate={this.state.playbackRate}
            seekTo={this.seekTo}
            setPlaybackRate={this.setPlaybackRate}
            showAllDetections={showAllDetections}
            showConfidenceSlider={showConfidenceSlider}
            toggleInspectMode={this._toggleInspectMode}
            video={this.video}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    inspectModeTypes: selectInspectModeTypes(state),
    isManualIdentityDrawingEnabled: selectIsManualIdentityDrawingEnabled(state),
    startDate: getDateInMs(ownProps.datasource),
    startEpochMs: ownProps.datasource.startEpochMs,
    showConfidenceSlider: selectShowConfidenceSlider(state),
    showAllDetections: selectFetchAllDetectionsInInspectMode(state),
    videoId: ownProps.datasource.id,
    videoHeight: ownProps.datasource.height,
    videoWidth: ownProps.datasource.width,
  };
}

const mapDispatchToProps = {
  dispatchFetchDetectionObjects: fetchDetectionObjects,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles)
)(VideoPlayer);
