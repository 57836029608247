import React from 'react';
import { withStyles } from 'tss-react-local';
import LinearProgress from 'common/components/base/LinearProgress';
import CircularLoader from 'common/components/base/CircularLoader';
import { Example } from '../helpers';

const styles = {
  loader: {
    marginBottom: 16,
    marginRight: 16,
  },
};

const LoaderExample = ({ classes }) => (
  <div>
    <Example label="Default" style={{ maxWidth: 600 }}>
      <LinearProgress className={classes.loader} value={75} />
      <CircularLoader className={classes.loader} value={60} />
      <CircularLoader className={classes.loader} value={70} size={20} />
      <CircularLoader className={classes.loader} value={80} size={60} thickness={6} />
    </Example>

    <Example label="Buffer" style={{ maxWidth: 600 }}>
      <LinearProgress className={classes.loader} valueBuffer={75} value={40} variant="buffer" />
    </Example>
    <Example label="Query" style={{ maxWidth: 600 }}>
      <LinearProgress className={classes.loader} value={75} variant="query" />
    </Example>
    <Example label="Indeterminate" style={{ maxWidth: 600 }}>
      <LinearProgress className={classes.loader} value={75} variant="indeterminate" />
      <CircularLoader className={classes.loader} variant="indeterminate" />
      <CircularLoader className={classes.loader} variant="indeterminate" disableShrink />
    </Example>
    <Example label="Colors" style={{ maxWidth: 600 }}>
      <CircularLoader color="purple" className={classes.loader} variant="indeterminate" />
      <CircularLoader color="navy" className={classes.loader} variant="indeterminate" />
    </Example>
  </div>
);

export default withStyles(LoaderExample, styles);
