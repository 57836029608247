import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography';

import { BLUE_GREY, ERROR, PRIMARY_GREY } from 'common/constants/colors';
import { typographyDefaults } from 'common/components/base/themes';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  gutterBottom: {
    marginBottom: 6,
  },
  body3: {
    fontSize: 10,
    fontWeight: 400,
    ...typographyDefaults,
  },
  body4: {
    fontSize: 8,
    fontWeight: 400,
    ...typographyDefaults,
  },
  subtitle3: {
    color: PRIMARY_GREY,
    fontSize: 10,
    fontWeight: 400,
    ...typographyDefaults,
  },
  link1: {
    fontSize: 13,
    fontWeight: 400,
    textDecoration: 'underline',
    ...typographyDefaults,
  },
  link2: {
    fontSize: 11,
    fontWeight: 400,
    textDecoration: 'underline',
    ...typographyDefaults,
  },
  link3: {
    fontSize: 10,
    fontWeight: 400,
    textDecoration: 'underline',
    ...typographyDefaults,
  },
  error: {
    color: ERROR,
    fontSize: 10,
    ...typographyDefaults,
  },
  empty: {
    color: BLUE_GREY,
    fontSize: 10,
    ...typographyDefaults,
  },
});

type MuiVariant = MuiTypographyProps['variant'];

type MyVariant =
  | MuiVariant
  | 'body3'
  | 'body4'
  | 'link2'
  | 'link3'
  | 'subtitle3'
  | 'empty'
  | 'error';

/*
  Currently MUI doesn't officially support typography overrides using the theme, so we are
  adding our own custom variants here. However, because MUI adds additional styling based on
  whether it's a "body" or a "subtitle" variant, we need to maintain those styles, while
  overriding the things we care about, such as fontSize and fontWeight
*/
function getDefaultVariant(variant: MyVariant): MuiVariant {
  switch (variant) {
    case 'body4':
    case 'body3':
      return 'body1';
    case 'subtitle3':
      return 'subtitle1';
    case 'link2':
    case 'link3':
    case 'error':
    case 'empty':
      return 'inherit';
    default:
      return variant;
  }
}

interface Props extends Omit<MuiTypographyProps, 'variant'> {
  addMargin?: boolean;
  customClasses?: any;
  variant?: MyVariant;
  forwardRef: any;
}

const Typography = ({ forwardRef, addMargin, customClasses = {}, variant, ...rest }: Props) => {
  const classes = useStyles();
  const defaultVariant = getDefaultVariant(variant);
  const variantClass = variant !== undefined ? classes[variant as keyof typeof classes] : '';

  return (
    <MuiTypography
      ref={forwardRef}
      classes={{
        ...customClasses,
        root: classnames(classes.root, variantClass, customClasses.root),
        gutterBottom: classnames(classes.gutterBottom, customClasses.gutterBottom),
      }}
      gutterBottom={addMargin}
      variant={defaultVariant}
      data-testid="Typography"
      {...rest}
    />
  );
};

export default React.forwardRef((props, ref) => <Typography forwardRef={ref} {...props} />);
