import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 92" {...props}>
    <g fill="#6599DE" fillRule="evenodd">
      <path d="M61.398 12.102c-.102-1.898-1.7-3.3-3.602-3.3h-4.601v-4.4c0-1.898-1.602-3.5-3.5-3.5h-3.399c-1.898 0-3.5 1.602-3.5 3.5v4.4h-4.601c-1.899 0-3.399 1.398-3.602 3.3l-.5 5.8h27.7l-.395-5.8zM33.301 33.898c.2-2.102 1.898-3.602 3.898-3.602h21.5c2.102 0 3.801 1.602 3.899 3.602l.398 4.602 9.801 2.5-1-12.801c-.3-3.301-3.102-5.899-6.398-5.899H30.5c-3.301 0-6.102 2.602-6.399 5.899l-1 12.8 9.801-2.5.398-4.601z" />
      <path d="M25.699 79.398c.2.102.398.102.602.102 1.3 0 2-.801 2.5-1.602l1.699-2.8c1.5-2.302 4-3.7 6.699-3.7 2.8 0 5.3 1.398 6.7 3.7l1.698 2.698c.399.7 1.2 1.602 2.5 1.602 1.2 0 2-.8 2.5-1.602l1.7-2.8c1.398-2.301 4-3.7 6.699-3.7s5.199 1.399 6.699 3.7l1.7 2.8c.398.7 1.198 1.602 2.5 1.602h.198l12.2-27.898c.8-1.8-.2-3.898-2.102-4.398L54.89 40.8c-4.602-1.2-9.399-1.2-14.102 0l-25.301 6.3c-1.899.5-3 2.602-2.2 4.399L25.7 79.398z" />
      <path d="M91.898 83.801c-2.5 0-4.7-1.3-6.102-3.602L84.097 77.5c-.699-1.101-1.8-1.699-3.101-1.699-1.2 0-2.399.602-3.102 1.7l-1.7 2.698c-1.398 2.301-3.698 3.602-6.1 3.602-2.5 0-4.7-1.3-6.102-3.602l-1.7-2.699c-.699-1.101-1.8-1.699-3.101-1.699-1.2 0-2.399.602-3.102 1.7l-1.699 2.8c-1.398 2.3-3.602 3.602-6.102 3.602-2.5 0-4.699-1.301-6.101-3.602l-1.7-2.7c-.699-1.1-1.8-1.698-3.101-1.698-1.2 0-2.398.601-3.102 1.699l-1.699 2.8c-1.398 2.301-3.699 3.602-6.101 3.602-2.399 0-4.7-1.3-6.102-3.601l-1.7-2.7c-.698-1.101-1.8-1.699-3.1-1.699-1.2 0-2.4.602-3.102 1.7l-1.7 2.699c-1.5 2.398-3.8 3.699-6.398 3.601-2-.101-3.7 1.5-3.8 3.5-.102 2 1.5 3.7 3.5 3.801 4.5.2 8.6-1.7 11.5-5.102 2.8 3.301 6.698 5.102 10.897 5.102s8.2-1.898 10.898-5.102c2.801 3.301 6.7 5.102 10.898 5.102 4.2 0 8.2-1.898 10.898-5.102 2.801 3.301 6.7 5.102 10.898 5.102 4.199 0 8.2-1.898 10.898-5.102 2.801 3.2 6.7 5.102 10.898 5.102 2 0 3.602-1.602 3.602-3.602.027-2.304-1.574-3.902-3.574-3.902h.003z" />
    </g>
  </SvgIcon>
);
