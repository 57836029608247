import { renderAlert } from 'app/redux/actions';

export function alertRejected(rejectedFiles, extensions) {
  renderAlert(getFilesRejectedText(rejectedFiles, extensions), {
    title: 'Unsupported File Types',
  });
}

export function getFilesRejectedText(rejectedFiles, extensions) {
  const prefix =
    rejectedFiles.length === 1
      ? `There is ${rejectedFiles.length} file`
      : `There are ${rejectedFiles.length} files`;

  return `${prefix} that will not be uploaded.\n\n${getAcceptedExtensionsText(extensions)}`;
}

export function getFilesList(files) {
  return `- ${files.map(({ name }) => name).join('\n- ')}`;
}

function getAcceptedExtensionsText(extensions) {
  return `Mirage currently supports file types: ${extensions}`;
}

export function getMirageSupportedFilesMessage(videoExtensions, imageExtensions, codecFormats) {
  return `Mirage currently supports these file types\
           \nVideo File Types: ${videoExtensions}\
           \nImage File Types: ${imageExtensions}\
           \nDocument File Types: .docx, .pptx, .pdf\
           \nCodec Formats: ${codecFormats}`;
}
