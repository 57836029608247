import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import { SIDEBAR_WIDTH, SIDEBAR_EXPANDED_WIDTH } from 'common/constants/app';
import * as AppSelectors from 'app/redux/selectors';
import * as SettingsSelectors from 'settings/redux/selectors';

import { BANNER_HEIGHT } from './ClassificationBanner';

const MIN_APP_WIDTH = 1024;

const useBodyStyles = createUseStyles({
  body: {
    display: 'flex',
    height: props => `calc(100% - ${props.bannerHeight}px)`,
  },
});

const AppBody = props => {
  const headerText = useSelector(SettingsSelectors.selectBannerHeaderText);
  const footerText = useSelector(SettingsSelectors.selectBannerFooterText);
  const bannerHeight = Boolean(headerText) * BANNER_HEIGHT + Boolean(footerText) * BANNER_HEIGHT;
  const classes = useBodyStyles({ bannerHeight });

  return <div className={classes.body} {...props} />;
};

const useContentStyles = createUseStyles({
  content: {
    boxSizing: 'border-box',
    height: '100%',
    flex: '1 1 auto',
    overflow: 'auto',
    width: props => `calc(100% - ${props.sidebarWidth}px)`,

    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
});

const AppContent = props => {
  const isNavExpanded = useSelector(AppSelectors.selectIsNavExpanded);
  const sidebarWidth = isNavExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_WIDTH;
  const classes = useContentStyles({ sidebarWidth });

  return <div className={classes.content} {...props} />;
};

const useAppStyles = createUseStyles({
  appRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minWidth: MIN_APP_WIDTH,
  },
});

const AppRoot = props => {
  const classes = useAppStyles();

  return <div className={classes.appRoot} {...props} />;
};

export default AppRoot;

export { AppBody, AppContent };
