import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

/* Only used for custom checkbox functionality for Checkbox.jsx, we should not need this anywhere else */
export default props => (
  <SvgIcon {...props}>
    <path d="M17,13H7V11H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
    <path
      fill="#78909C"
      d="M19,19V5H5V19H19M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5C3,3.89 3.9,3 5,3H19M17,11V13H7V11H17Z"
    />
  </SvgIcon>
);
