import { formatLat, formatLng } from 'common/helpers/stringUtils';
import { get, isEmpty } from 'lodash';
import mgrs from 'mgrs';
import { DMS, MGRS } from 'settings/redux/constants';

export function getLocationString(location = {}, { gcs } = {}) {
  const address = getFormattedAddress(location);
  if (!isEmpty(address)) return address;
  return getDefaultLatLng(location, gcs);
}

function getFormattedAddress(location = {}) {
  if (!isEmpty(location.formattedAddress)) return location.formattedAddress;

  const { city, countryCode, region, state } = get(location, 'address', {});

  let formattedAddress = '';
  if (!isEmpty(city)) {
    formattedAddress += city;
  }

  if (!isEmpty(region) || !isEmpty(state)) {
    formattedAddress += `${formattedAddress.length ? ',' : ''} ${region || state}`;
  }

  if (!isEmpty(countryCode)) {
    formattedAddress += `${formattedAddress.length ? ',' : ''} ${countryCode}`;
  }

  return formattedAddress;
}

export function getRegionString(location = {}, { gcs } = {}) {
  const { city, region, state } = get(location, 'address', {});
  const _region = region || state || city;
  if (!isEmpty(_region)) return _region;

  return getDefaultLatLng(location, gcs);
}

function getDefaultLatLng(location, gcs) {
  const { lat, lng } = getLatLng(location);

  if (gcs === MGRS) {
    return mgrs.forward([lng, lat]);
  }

  return lat && lng ? `${formatLat(lat, gcs === DMS)}, ${formatLng(lng, gcs === DMS)}` : '';
}

function getLatLng(location = {}) {
  if (location.lat && location.lng) return location;

  if (location.coords) {
    return {
      lat: location.coords.lat,
      lng: location.coords.lng,
    };
  }

  return { lat: '', lng: '' };
}

const hasLat = ({ lat }) => Boolean(lat);
const hasLng = ({ lng }) => Boolean(lng);

const hasCoords = ({ coords = {} }) => hasLat(coords) && hasLng(coords);

const hasFormattedAddress = ({ formattedAddress }) => Boolean(formattedAddress);

export function doesLocationExist(location = {}) {
  return hasFormattedAddress(location) || hasCoords(location);
}
