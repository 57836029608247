import L from 'leaflet';

export const getDefaultImageIcon = () =>
  new L.Icon({
    iconUrl: '/pin-image.svg',
    iconSize: [25],
    iconAnchor: [12.5, 36],
  });

export const getSelectedImageIcon = () =>
  new L.Icon({
    iconUrl: '/pin-image-selected.svg',
    iconSize: [30],
    iconAnchor: [15, 43.2],
  });

const getImageIcon = ({ isSelected }) =>
  (isSelected ? getSelectedImageIcon : getDefaultImageIcon)();

export default getImageIcon;
