import { isEqual, isEmpty, compact, get } from 'lodash';

import store from 'redux/store';
import {
  DATASOURCE,
  PERSON,
  OBJECT,
  LIBRARY,
  NON_EXPERIMENTAL_OBJECT_TYPES,
} from 'common/constants/app';
import {
  ROOT_DIRECTORIES,
  MOVE_TOP_OF_GROUP,
  MOVE_BOTTOM_OF_GROUP,
  MOVE_TO_TOP,
  MOVE_GROUP_TO_TOP,
} from 'library/redux/constants';
import { parseToSameDayFormat, parseToShorthandDate } from 'common/helpers/dateUtils';
import * as CommonModelSelectors from 'common/redux/models/selectors';

import {
  hasPaused,
  isEnqueuedOrRunning,
  isUploading,
  shouldRenderCancelIcon,
} from './datasource/utils';
import { ARROW_DOWN_ICON, ARROW_UP_ICON, CANCEL_ICON } from './constants';

/* sort of a hacky solution to obtain correct URL when switching tabs */
export function getBaseLibraryURL(url) {
  return url.slice(0, url.indexOf(LIBRARY) - 1);
}

export function getCurrentLibraryRootFolderKey({
  type,
  currentLibraryDatasourcesFolder,
  currentLibraryPersonsFolder,
}) {
  if (type === PERSON) return currentLibraryPersonsFolder?.id || ROOT_DIRECTORIES[PERSON];
  if (type === OBJECT) return ROOT_DIRECTORIES[OBJECT];

  return currentLibraryDatasourcesFolder?.id || ROOT_DIRECTORIES[DATASOURCE];
}

export function buildBreadcrumbs(folder, folderModels, breadcrumb = []) {
  if (!folder) return breadcrumb;

  const { parent: parentId } = folder;
  const parentFolder = folderModels[parentId];

  breadcrumb.unshift(folder);
  if (!parentFolder) return breadcrumb;

  return buildBreadcrumbs(parentFolder, folderModels, breadcrumb);
}

export function sortFoldersByName(folderIds, folderModels) {
  return folderIds.sort((folderA, folderB) => {
    const nameA = (folderModels[folderA] && folderModels[folderA].name) || '';
    const nameB = (folderModels[folderB] && folderModels[folderB].name) || '';

    return nameA.localeCompare(nameB);
  });
}

export function getFolderItemIds(selectedData, currentFolder) {
  const itemDict = {};
  const data = Object.keys(selectedData);

  if (currentFolder && currentFolder.items) {
    currentFolder.items.forEach(({ itemId, id }) => (itemDict[itemId] = id));
  }

  return compact(data.map(id => itemDict[id]));
}

// Helper functions for moving data from folder to folder
export function ensureTreeStructure(
  foldersToMove,
  destinationId,
  folderModels = CommonModelSelectors.selectFolderV2Models(store.getState())
) {
  const folderDict = {};
  foldersToMove.forEach(id => (folderDict[id] = true));

  if (folderDict[destinationId]) return false;
  return checkParent(folderModels[destinationId], folderDict, folderModels);
}

function checkParent(childFolder, folderDict, folderModels) {
  const id = childFolder.parent;
  if (!id) return true;

  const folder = folderModels[id];
  if (folderDict[id]) return false;

  return checkParent(folder, folderDict, folderModels);
}

export const createThumbnailURL = ({ vid, imageId, x, y, w, h, t }) => {
  if (imageId) {
    return `api/images/${imageId}/snip/?x=${x}&y=${y}&w=${w}&h=${h}`;
  }
  return `api/ds/${vid}/snip/?t=${Math.round(t)}&x=${x}&y=${y}&w=${w}&h=${h}`;
};

export function getNonExperimentalModels(objectModels) {
  return Object.values(objectModels).filter(x =>
    NON_EXPERIMENTAL_OBJECT_TYPES.some(
      objectType => x.id === objectType.id && x.name.toLowerCase() === objectType.name
    )
  );
}

export function getJobGroupTitle(displayInfo) {
  if (isEmpty(displayInfo)) {
    return '';
  }

  const { uploadFolderNames, clientUploadStartTs, batchName } = displayInfo;

  if (uploadFolderNames) {
    return uploadFolderNames;
  }

  if (batchName) {
    return batchName;
  }

  if (clientUploadStartTs) {
    return parseToShorthandDate(clientUploadStartTs * 1000, {
      showSeconds: true,
      showTimeZone: true,
    });
  }
}

export function getStartDate(clientUploadStartTs, showSeconds) {
  if (showSeconds) {
    return parseToShorthandDate(clientUploadStartTs * 1000, {
      showSeconds: true,
      showTimeZone: true,
    });
  }

  return parseToSameDayFormat(clientUploadStartTs * 1000, {
    addTimezone: true,
  });
}

export function parseToDateTimeZone(clientUploadStartTs) {
  return parseToShorthandDate(clientUploadStartTs * 1000, {
    showTime: true,
    showTimeZone: true,
  });
}

export function hasBatchContentsChanged(prevBatch, newBatch) {
  const isPrevBatchEmpty = get(prevBatch, 'batches', []).length === 0;
  const isNewBatchEmpty = get(newBatch, 'batches', []).length === 0;

  if (isPrevBatchEmpty && isNewBatchEmpty) {
    return false;
  }

  const getBatches = ({ batches }) => Object.values(batches || []);

  const prevBatches = getBatches(prevBatch);
  const newBatches = getBatches(newBatch);

  return !isEqual(prevBatches, newBatches);
}

export function getJobGroupInfoOptions(jobGroupInfo, reprioritizeProcessingJob, cancelClick) {
  const disabledMoveTopOfGroup =
    hasPaused(jobGroupInfo.status) ||
    !isEnqueuedOrRunning(jobGroupInfo.status) ||
    jobGroupInfo.totalGroupCount <= 1;

  const disabledCancel =
    hasPaused(jobGroupInfo.status) || !shouldRenderCancelIcon(jobGroupInfo.status);

  const jobStatus = isUploading(jobGroupInfo.status) ? 'Uploading' : 'Processing';

  return [
    {
      disabled: disabledMoveTopOfGroup,
      icon: ARROW_UP_ICON,
      label: MOVE_GROUP_TO_TOP,
      onClick: () =>
        reprioritizeProcessingJob({
          batch_id: jobGroupInfo.batchId,
        }),
    },
    {
      disabled: disabledCancel,
      icon: CANCEL_ICON,
      label: `Cancel ${jobStatus}`,
      onClick: () =>
        cancelClick({
          showCancelJobGroup: true,
          dataSource: jobGroupInfo,
        }),
    },
  ];
}

export function getReprioritizationOptions(job, uploadGroups, reprioritize) {
  const group = uploadGroups[job?.batchId];
  const isLastProcessingItem = group ? group.jobsCount - group.completedJobsCount - 1 === 0 : false;
  return [
    {
      icon: ARROW_UP_ICON,
      label: MOVE_TOP_OF_GROUP,
      disabled: isLastProcessingItem,
      onClick: () =>
        handleReprioritizationClick(job, MOVE_TOP_OF_GROUP, uploadGroups, reprioritize),
    },
    {
      icon: ARROW_DOWN_ICON,
      label: MOVE_BOTTOM_OF_GROUP,
      disabled: isLastProcessingItem,
      onClick: () =>
        handleReprioritizationClick(job, MOVE_BOTTOM_OF_GROUP, uploadGroups, reprioritize),
    },
    {
      icon: ARROW_UP_ICON,
      label: MOVE_TO_TOP,
      onClick: () => handleReprioritizationClick(job, MOVE_TO_TOP, uploadGroups, reprioritize),
    },
  ];
}

export function getTopOfGroupJobIndex(uploadGroups, id) {
  const group = uploadGroups[id];
  if (group) {
    const job = group.children[0];
    return job.ordinalPriority - 1;
  }
}

export function getBottomOfGroupJobIndex(uploadGroups, id) {
  const group = uploadGroups[id];
  if (group) {
    const lastIndex = group.jobsCount - group.completedJobsCount - 1;
    const job = group.children[lastIndex];
    return job.ordinalPriority + 1;
  }
}

function handleReprioritizationClick(job, priority, uploadGroups, reprioritize) {
  const args = {};

  switch (priority) {
    case MOVE_TOP_OF_GROUP:
      args.workflowId = job.workflowId;
      args.index = getTopOfGroupJobIndex(uploadGroups, job.batchId);
      break;
    case MOVE_BOTTOM_OF_GROUP:
      args.workflowId = job.workflowId;
      args.index = getBottomOfGroupJobIndex(uploadGroups, job.batchId);
      break;
    case MOVE_TO_TOP:
      args.workflowId = job.workflowId;
      args.index = 0;
      break;
    case MOVE_GROUP_TO_TOP:
      args.workflowId = job.workflowId;
      break;
    default:
      break;
  }
  return reprioritize(args);
}

export function processGeoJobs(jobs = {}) {
  if (!jobs.batches) return jobs;

  const batches = jobs?.batches?.reduce((acc, batch) => {
    batch.workflows.forEach(workflow => {
      const job = workflow.jobs[0];
      acc.push({
        ...workflow,
        batchId: batch.batchId,
        batchStatus: batch.batchStatus,
        created: job?.created && Date.parse(job?.created),
        dataset: job?.config?.dataset,
        imageCount: job?.config?.images?.length,
        jobs: workflow.jobs,
      });
    });

    return acc;
  }, []);

  return { ...jobs, batches };
}
