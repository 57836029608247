import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 70" color={color} {...rest}>
    <path
      fillRule="evenodd"
      d="M87.98 30.37c-1.503 0-2.97.172-4.377.48l-3.277-8.354a28.983 28.983 0 0 1 16.922.648 3 3 0 0 0 .986.168c1.237 0 2.392-.762 2.818-1.982a2.95 2.95 0 0 0-1.833-3.77c-6.848-2.367-14.108-2.567-21.07-.603l-4.222-10.76L62.991.357c-1.453-.776-3.264-.24-4.047 1.197a2.939 2.939 0 0 0 1.21 4.005l8.997 4.808 3.392 8.65-1.265.672c-1.696-3.198-8.952-8.99-17.326-4.704-4.396 2.25-11.977 6.3-11.977 6.3l-31.393-7.693c-1.6-.39-3.222.577-3.62 2.16-.4 1.583.582 3.188 2.181 3.583l27.502 6.74 4.794 9.413-5.372 2.844a20.071 20.071 0 0 0-16.042-7.966C8.983 30.365 0 39.255 0 50.183 0 61.11 8.984 70 20.025 70c11.042 0 20.025-8.89 20.025-19.817 0-2.327-.413-4.559-1.16-6.636l.316-.168h22.066a2.988 2.988 0 0 0 2.8-1.923l5.29-14.044 5.363-2.84 3.304 8.424C72.016 36.42 67.95 42.839 67.95 50.183 67.95 61.11 76.934 70 87.975 70 99.017 70 108 61.11 108 50.183c.01-10.923-8.975-19.813-20.02-19.813zM32.87 50.167C32.87 57.243 27.075 63 19.955 63S7.043 57.242 7.043 50.167c0-7.076 5.794-12.834 12.913-12.834 3.832 0 7.272 1.68 9.64 4.32l-8.101 4.308c-.48-.174-1-.275-1.539-.275-2.486 0-4.503 2.005-4.503 4.476 0 2.472 2.017 4.476 4.503 4.476a4.503 4.503 0 0 0 4.375-3.414l8.106-4.311c.271 1.048.433 2.132.433 3.254zM88.042 63c-7.12 0-12.913-5.757-12.913-12.831 0-4.366 2.211-8.223 5.573-10.543l3.33 8.516a4.412 4.412 0 0 0-.493 2.027c0 2.471 2.017 4.475 4.504 4.475 2.486 0 4.503-2.004 4.503-4.475 0-1.917-1.215-3.546-2.92-4.187l-3.324-8.516a12.88 12.88 0 0 1 1.74-.133c7.12 0 12.914 5.757 12.914 12.831C100.96 57.248 95.167 63 88.044 63z"
    />
  </SvgIcon>
);
