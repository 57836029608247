import { createSelector } from 'reselect';
import get from 'lodash/get';
import { VENUE_MAP } from 'common/constants/app';

export const selectVenueExtensions = state => get(state, `common.formats.${VENUE_MAP}.extensions`);

const joinIfExists = arr => arr && arr.join(',');

export const selectPersonAcceptedExtensions = createSelector(
  state => get(state, 'common.formats.person.extensions'),
  joinIfExists
);

export const selectPersonMimeTypes = createSelector(
  state => get(state, 'common.formats.person.mimeTypes'),
  joinIfExists
);

export const selectImageExtensions = createSelector(
  state => get(state, 'common.formats.image.extensions'),
  joinIfExists
);

export const selectImageMimeTypes = createSelector(
  state => get(state, 'common.formats.image.mimeTypes'),
  joinIfExists
);

export const selectVideoExtensions = createSelector(
  state => get(state, 'common.formats.datasource.extensions'),
  joinIfExists
);

export const selectShapefileExtensions = createSelector(
  state => get(state, 'common.formats.shapefiles.extensions'),
  joinIfExists
);

export const selectDatasourceMimeTypes = createSelector(
  state => get(state, 'common.formats.datasource.mimeTypes'),
  joinIfExists
);

export const selectDocumentMimeTypes = createSelector(
  state => state?.common?.formats?.document?.mimeTypes,
  joinIfExists
);

export const selectDatasourceExtensions = createSelector(
  selectImageExtensions,
  selectVideoExtensions,
  (...types) => types.join(',')
);

export const selectDatasourceUploadTypes = createSelector(
  selectImageMimeTypes,
  selectDatasourceMimeTypes,
  selectDocumentMimeTypes,
  selectDatasourceExtensions,
  (...types) => types.join(',')
);
