import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import { SECONDARY, DARK_GREY } from 'common/constants/colors';
import { MED_HEIGHT, SMALL_HEIGHT, LARGE_HEIGHT, MED_FONT } from './constants';

const styles = {
  main: {
    minWidth: 'unset',
    minHeight: 'unset',
    height: MED_HEIGHT,
    width: MED_HEIGHT,
    padding: 0,
    boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',

    '&:disabled': {
      color: SECONDARY,
      background: DARK_GREY,
      opacity: 1,
      boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
    },
    '&:hover': {
      backgroundColor: 'white',
      opacity: 1,
    },
  },
  'theme-default': {
    color: SECONDARY,
    fontSize: MED_FONT,
    borderColor: SECONDARY,
    background: 'white',
  },
  'size-large': {
    height: LARGE_HEIGHT,
    width: LARGE_HEIGHT,
    fontSize: MED_FONT,
  },
  'size-small': {
    height: SMALL_HEIGHT,
    width: SMALL_HEIGHT,
    fontSize: MED_FONT,
  },
};

const Fab = props => {
  const { classes, children, theme, size, className, type, ...rest } = props;
  const rootClass = classnames(classes.main, classes[`theme-${theme}`], classes[`size-${size}`]);

  return (
    <Button
      {...rest}
      size={size}
      type={type}
      variant="fab"
      classes={{
        root: classnames(rootClass, className),
        sizeLarge: classes.sizeLarge,
        sizeSmall: classes.sizeSmall,
      }}
    >
      {children}
    </Button>
  );
};

Fab.defaultProps = {
  type: 'button',
  theme: 'default',
};

export default injectSheet(styles)(Fab);
