import { MIRAGE_HEALTH_UNKNOWN } from 'common/constants/app';

export const DEFAULTS = {
  // Popups
  alert: {
    message: '',
    type: null,
    details: '',
    title: '',
  },
  errorReport: null,
  wsConnectionHealth: MIRAGE_HEALTH_UNKNOWN,
  loggingError: null,
  notifications: [],

  // LocalStorage returns stringified booleans so need to compare against string `true`
  isNavExpanded: localStorage.getItem('isNavExpanded') === 'true' || false,
  showAbout: false,
  videoSummary: {},
  showProcessingList: false,
};

export default function appReducer(state = DEFAULTS, action) {
  switch (action.type) {
    case 'APP/SET_SHOW_PROCESSING_IMAGES_VIEW': {
      return {
        ...state,
        showProcessingList: action.payload,
      };
    }
    case 'APP/SET_VIDEO_SUMMARY': {
      return {
        ...state,
        videoSummary: action.videoSummary,
      };
    }
    case 'APP/SET_ALERT_DIALOG': {
      return {
        ...state,
        alert: {
          title: action.payload.title,
          message: action.payload.alert,
          type: action.payload.type,
          details: action.payload.details,
        },
      };
    }
    case 'APP/TOGGLE_ABOUT_VIEW': {
      return {
        ...state,
        showAbout: action.payload,
      };
    }
    case 'APP/TOGGLE_HAMBURGER_MENU': {
      localStorage.setItem('isNavExpanded', !state.isNavExpanded);

      return {
        ...state,
        isNavExpanded: !state.isNavExpanded,
      };
    }
    case 'APP/SET_ERROR_REPORT': {
      return {
        ...state,
        errorReport: action.errorReport,
      };
    }
    case 'APP/SET_LOGGING_ERROR': {
      return {
        ...state,
        loggingError: action.payload,
      };
    }
    case 'APP/ADD_NOTIFICATION': {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.notification.id]: action.notification,
        },
      };
    }
    case 'APP/SET_NOTIFICATIONS': {
      return {
        ...state,
        notifications: action.notifications,
      };
    }
    case 'APP/SET_WS_CONNECTION_HEALTH': {
      return {
        ...state,
        wsConnectionHealth: action.payload,
      };
    }
    default:
      return state;
  }
}
