import React from 'react';

import GoogleSearchBox from 'common/components/generalComponents/googleMaps/GoogleSearchBox';

const LocationInput = ({ className, classes, onPlacesChanged, value, disabled, ...rest }) => (
  <GoogleSearchBox
    onPlacesChanged={onPlacesChanged}
    onChange={onPlacesChanged}
    defaultValue={value}
    shouldDisable={disabled}
    data-testid="locationInput"
    {...rest}
  />
);

LocationInput.defaultProps = {
  label: 'Location',
  placeholder: 'Enter Location',
  value: '',
};

export default LocationInput;
