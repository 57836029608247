import axios from 'axios.js';
import { parseContentRange } from 'common/api/utils';

import { ANALYTICS_BACKEND } from 'common/constants/urls';

export const getIdentityLinksForPerson = ({ page = 1, page_size: pageSize = 50, ...rest }) =>
  axios
    .get(`${ANALYTICS_BACKEND}/identity-links/`, { params: { page, page_size: pageSize, ...rest } })
    .then(({ data, headers }) => ({ data, range: parseContentRange(headers) }));

export const postIdentityLink = body =>
  axios.post(`${ANALYTICS_BACKEND}/identity-links/`, body).then(({ data }) => data);

export const deleteIdentityLink = id =>
  axios.delete(`${ANALYTICS_BACKEND}/identity-links/${id}/`).then(({ data }) => data);

export const patchIdentityLink = ({ id, ...params }) =>
  axios.patch(`${ANALYTICS_BACKEND}/identity-links/${id}/`, params).then(({ data }) => data);

export const postIdentityImage = (body, config) =>
  axios.post(`${ANALYTICS_BACKEND}/images/uploadidentity/`, body, config).then(({ data }) => data);
