import React from 'react';

export default ({
  style,
  style: { color = '#d8e5f7', width = 18, height = width * 0.74 } = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      ...style,
      color,
      width,
      height,
    }}
    viewBox="0 0 23 17"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M23 3.375A1.995 1.995 0 0 0 21.85 3h-5.482c-.499 0-.997.188-1.342.563L13.608 4.95c-.23.225-.498.337-.805.337L1.15 5.288c-.422 0-.843.15-1.15.375V1.125C0 .488.499 0 1.15 0h5.482c.307 0 .614.113.806.337l1.418 1.387c.344.337.843.563 1.341.563l11.653.001C22.5 2.25 23 2.738 23 3.375zm-6.632.375c-.307 0-.614.113-.806.337l-1.418 1.389a1.974 1.974 0 0 1-1.341.562H1.15C.499 6.038 0 6.526 0 7.163v8.25c0 .637.499 1.125 1.15 1.125h20.7c.651 0 1.15-.488 1.15-1.125V4.875c0-.637-.499-1.125-1.15-1.125h-5.482z"
      opacity=".98"
    />
  </svg>
);
