export function buildLocationFromGooglePlace(googleLocation, defaultLocation = '') {
  if (!googleLocation) return buildDefaultLocation(defaultLocation);

  const {
    formatted_address: formattedAddress,
    geometry: { location } = {},
    place_id: placeId,
  } = googleLocation;

  return {
    coords: {
      lat: location.lat(),
      lng: location.lng(),
    },
    /* prefer the location given over the google location since it is not always accurate to what the user specifies */
    formattedAddress: defaultLocation || formattedAddress,
    placeId,
  };
}

export function buildDefaultLocation(location = '') {
  return {
    formattedAddress: location,
    placeId: null,
    coords: {
      lat: '',
      lng: '',
    },
  };
}
