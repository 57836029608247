import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 109" color={color} {...rest}>
    <path
      fillRule="evenodd"
      d="M34.912 73.99c-.38 1.135-1.514 1.895-2.274 3.034h38.273c-.76-1.135-2.274-1.894-2.274-3.034H34.912zm40.546-.38l26.908 28.802c2.274 2.654 0 8.337-2.274 6.063l-6.442-7.202H9.899l-6.442 7.202c-2.654 2.274-4.928-3.41-2.274-6.063L28.09 73.611c-7.201-1.894-12.504-8.337-12.504-16.293V17.152c0-8.716 7.201-16.293 16.293-16.293H71.67c9.096 0 16.293 7.577 16.293 16.293v40.166c0 7.956-5.307 14.398-12.504 16.293zM62.954 8.053c3.034 0 3.034 4.927 0 4.927H40.595c-3.033 0-3.033-4.927 0-4.927h22.36zm12.125 9.095c4.169 0 7.202 3.033 7.202 7.202V40.64c0 4.169-3.033 7.202-7.202 7.202H28.471c-4.168 0-7.202-3.033-7.202-7.202V24.35c0-4.169 3.034-7.202 7.202-7.202h46.608zM29.606 53.905c3.033 0 5.683 2.654 5.683 5.683 0 3.033-2.654 5.307-5.683 5.307-3.033 0-5.683-2.274-5.683-5.307 0-3.034 2.654-5.683 5.683-5.683zm44.335 0c-3.033 0-5.683 2.654-5.683 5.683 0 3.033 2.654 5.307 5.683 5.307 3.033 0 5.683-2.274 5.683-5.307 0-3.034-2.654-5.683-5.683-5.683zm-22.36 27.662H28.469l-4.928 4.928v.38h56.464v-.38l-4.928-4.928H51.582zm0 9.85h-32.59l-4.547 4.928-.38.38h75.41l-.38-.38-4.547-4.927h-32.97.005z"
    />
  </SvgIcon>
);
