import { get } from 'lodash';
import DateTime from 'dateTime';
import { createSelector } from 'reselect';

import { STATUS } from 'common/constants/api';
import { GEO } from 'common/constants/app';

import * as C from './constants';

const getFromState = (...path) => state => get(state, path);

const getFromSettings = (...path) => getFromState('settings', ...path);
const getFromActiveModules = (...path) => getFromSettings('activeModules', ...path);

export const selectActiveModules = getFromActiveModules();
export const selectActiveFeatures = getFromSettings('activeFeatures');

export const selectIsGeoActive = getFromActiveModules(GEO);

export const selectAvailableS3Buckets = state =>
  get(state, `settings.config.ALLOWED_S3_IMPORT_PATH`);

export const selectIsLiveNGEnabled = state => get(state, `settings.config.LIVE_NG_ENABLED`, false);

const selectCustomerSettings = state => get(state, 'settings.customerSettings');

export const selectBannerSettings = state =>
  get(state, 'settings.customerSettings.CUSTOMER_SITE_BANNER', C.DEFAULT_BANNER_SETTINGS);

export const selectBannerColor = createSelector(selectBannerSettings, settings =>
  get(settings, 'color')
);
export const selectBannerTextColor = createSelector(selectBannerSettings, settings =>
  get(settings, 'textColor')
);
export const selectBannerHeaderText = createSelector(selectBannerSettings, settings =>
  get(settings, 'headerText')
);
export const selectBannerFooterText = createSelector(selectBannerSettings, settings =>
  get(settings, 'footerText')
);

export const selectUserProfile = getFromSettings(C.USER_PROFILE);

export const selectGeoMissionsFilterSetting = createSelector(selectUserProfile, profile =>
  get(profile, C.GEO_MISSIONS_FILTER_SETTING)
);
export const selectUserCustomTimeZone = createSelector(selectUserProfile, profile =>
  get(profile, C.PROFILE_USER_TIME_ZONE)
);
export const selectedUserTimeDisplayMethod = createSelector(selectUserProfile, profile =>
  get(profile, C.PROFILE_USER_TIME_DISPLAY)
);
export const selectProfileBulkUpdateLibraryItemsIgnoreConfirmUntil = createSelector(
  selectUserProfile,
  profile => get(profile, C.PROFILE_BULK_UPDATE_LIBRARY_ITEMS_IGNORE_CONFIRM_UNTIL)
);
export const selectDisableBulkSubmitWarning = createSelector(
  selectUserProfile,
  profile => !!get(profile, C.DISABLE_BULK_SUBMIT_WARNING)
);

export const selectGeoMissionsFilter = createSelector(
  selectGeoMissionsFilterSetting,
  ({ resultsAfterValue = '', resultsAfterValueType = '' } = {}) =>
    resultsAfterValue &&
    resultsAfterValueType && {
      results_after: Math.round(
        DateTime.now()
          .minus({ [resultsAfterValueType]: Number(resultsAfterValue) })
          .valueOf() / 1000
      ),
    }
);

export const selectShowObjectConfidence = getFromSettings('showObjectConfidence');
export const selectShowIdentityConfidence = getFromSettings('showIdentityConfidence');
export const selectShowConfidenceSlider = getFromSettings('showConfidenceSlider');
export const selectConfidenceThresholdSetting = getFromSettings('confidenceThreshold') || 60;

export const selectUseCache = getFromSettings('useCache');
export const selectGCS = getFromSettings('gcs');
export const selectGlobalSettings = getFromSettings('globalSettings');
export const selectTileServerUrl = state =>
  get(state, ['settings', 'globalSettings', C.FEATURE_TILESERVER, 'value']);
export const selectTileServerSettingId = state =>
  get(state, ['settings', 'globalSettings', C.FEATURE_TILESERVER, 'id']);
export const selectTileServerSettingDefaults = state =>
  state?.settings?.globalSettings?.[C.FEATURE_TILESERVER]?.defaults;
export const selectTileServerSettingChoices = state =>
  get(state, ['settings', 'globalSettings', C.FEATURE_TILESERVER, 'choices']);
export const selectTileServerStatuses = getFromSettings('tileServerStatuses');
export const selectGlobalSettingsFetchStatus = getFromSettings('globalSettingsFetchStatus');
export const selectGlobalSettingsUpdateStatus = getFromSettings('globalSettingsUpdateStatus');
export const selectTileServerConnectionStatus = getFromSettings('tileServerConnectionStatus');
export const selectTileServerConnectionError = getFromSettings('tileServerConnectionError');
export const selectGlobalSettingsUpdateError = getFromSettings('globalSettingsUpdateError');
export const selectGlobalSettingsFetchError = getFromSettings('globalSettingsFetchError');

export const selectTileServerFormError = createSelector(
  selectTileServerConnectionError,
  selectGlobalSettingsUpdateError,
  selectGlobalSettingsFetchError,
  (connection, update, fetch) => fetch || update || connection
);

export const selectIsTileServerFormFailure = createSelector(
  selectGlobalSettingsFetchStatus,
  selectGlobalSettingsUpdateStatus,
  selectTileServerConnectionStatus,
  (...args) => args.some(status => status === STATUS.FAILURE)
);

export const selectIsGlobalSettingsUpdateSuccess = createSelector(
  selectGlobalSettingsUpdateStatus,
  status => status === STATUS.SUCCESS
);
export const selectIsGlobalSettingsUpdateFailure = createSelector(
  selectGlobalSettingsUpdateStatus,
  status => status === STATUS.FAILURE
);

export const selectShowAdditionalObjects = createSelector(selectGlobalSettings, globalSettings =>
  get(globalSettings, [C.FEATURE_ADDITIONAL_OBJECTS, 'value'])
);

export const selectUseColor = createSelector(selectGlobalSettings, globalSettings =>
  get(globalSettings, [C.FEATURE_COLOR_DETECTION, 'value'])
);

export const selectCanAccessAllSearches = createSelector(selectGlobalSettings, globalSettings =>
  get(globalSettings, [C.FEATURE_CAN_ACCESS_ALL_SEARCH_QUERIES, 'value'])
);

export const selectGeoSettings = getFromSettings('geoSettings');
export const selectCustomTileServer = getFromSettings('geoSettings', C.GEO_CUSTOM_TILE_SERVER);
export const selectGeocoderServer = getFromSettings('geoSettings', C.GEO_OSM_GEOCODER_SERVER);
export const selectDatashiftRepo = getFromSettings('geoSettings', C.GEO_DATASHIFT_REPO);
export const selectDatashiftNamespace = getFromSettings('geoSettings', C.GEO_DATASHIFT_NAMESPACE);

export const selectConsentBannerContent = state =>
  get(state, 'settings.customerSettings.CUSTOMER_CONSENT_BANNER_CONTENT');

export const selectGoogleAPIKey = state =>
  get(state, `settings.customerSettings.${C.CUSTOMER_MAPS_API_KEY}`);

export const selectIsBrandingEnabled = state =>
  get(state, 'settings.customerSettings.CUSTOMER_ENABLE_BRANDING', true);

export const selectCustomerIdleLogoutTime = state =>
  selectCustomerSettings(state)?.IDLE_LOGOUT_TIME;

/* active feature selectors */
export const selectIsGeoAlertingEnabled = state =>
  // Default Alerting to be on. If the value is undefined/unset, we return true
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_GEO_ALERTING], true);

export const selectIsGeoEmailNotificationEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_GEO_EMAIL_NOTIFICATION]);

export const selectIsPointVisualizationEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_BBOX_POINT_VISUALIZATION]);

export const selectShowConfidenceGeoAnalysisResults = state =>
  !!get(state, ['settings', 'activeFeatures', C.SHOW_GEO_ANALYSIS_RESULTS_CONFIDENCE]);

export const selectIsKineticSeekEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_KINETIC_SEEK]);

export const selectIsSearchlightEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_SEARCHLIGHT]);

export const selectIsBulkFaceExtractionEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_BULK_FACE_EXTRACTION]);

export const selectIsManualIdentityDrawingEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_MANUAL_IDENTITY_DRAWING]);

export const selectFetchAllDetectionsInInspectMode = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_FETCH_ALL_DETECTIONS_IN_INSPECT_MODE]);

export const selectIsIdentityPackagesEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_IDENTITY_PACKAGES]);

export const selectIsMapSearchEnabled = state =>
  !!get(state, ['settings', 'activeFeatures', C.ENABLE_MAP_SEARCH]);
