import { useEffect } from 'react';

function useWindowEventListener(event, listener, deps = []) {
  useEffect(() => {
    window.addEventListener(event, listener);

    return () => {
      window.removeEventListener(event, listener);
    };
  }, deps); // eslint-disable-line
}

export default useWindowEventListener;
