import { isEmpty, get, compact, sum } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import * as CommonModelSelectors from 'common/redux/models/selectors';
import { planDatasourceSortFunctions } from 'plan/redux/constants';
import { dateRangeParams } from 'common/constants/parameters';
import { CAMERA, IMAGE, RECORDING, VIDEO } from 'common/constants/app';
import { getIdStringFromObject } from 'common/helpers/searchRequestUtils';
import buildSearchQuery from 'common/helpers/buildSearchQuery';
import getQueryStringFromIdentityRows from 'common/helpers/getQueryStringFromIdentityRows';

import {
  removeExtraneousIdentityRows,
  getExcludedVideos,
  countFolderItems,
  getExcludedVideosWithGeoFilter,
  getDatasourcesDatetimeRange,
} from './utils';

export const keysIfExists = obj => obj && Object.keys(obj);
export const lengthIfExists = (arr = []) => arr.length;

export const selectMonitorIsNameDialogOpen = state => get(state, 'planMonitor.isNameDialogOpen');
export const selectMonitorFilters = state => get(state, 'planMonitor.searchQuery.filters');
export const selectSelectedView = state => get(state, 'plan.selectedView');
export const selectGeoFilterCoordinates = state => get(state, 'plan.geoFilterCoordinates');
export const selectIsLoadingPlanData = state => get(state, 'plan.isLoading');
export const selectDatasourcesInGeofence = state => get(state, 'plan.datasourcesInGeofence');
export const selectMonitorSearchQuery = state => state?.planMonitor?.searchQuery;
export const selectMonitorCameras = state => get(state, 'planMonitor.searchQuery.cameras');
export const selectMonitorCamerasIds = createSelector(selectMonitorCameras, keysIfExists);
export const selectMonitorCamerasCount = createSelector(selectMonitorCamerasIds, lengthIfExists);
export const selectMonitorDatasources = state => state?.planMonitor?.searchQuery?.datasources;
export const selectMonitorDatasourcesIds = createSelector(selectMonitorDatasources, keysIfExists);
export const selectMonitorDatasourcesCount = createSelector(
  selectMonitorDatasourcesIds,
  lengthIfExists
);
export const selectMonitorIdentityRows = state => state?.planMonitor?.searchQuery?.identityRows;
export const selectMonitorFolders = state => state?.planMonitor?.searchQuery?.folders;
export const selectMonitorDatasourcesSortIndex = state => state?.planMonitor?.datasourcesSortIndex;
export const selectMonitorFolderIds = createSelector(selectMonitorFolders, keysIfExists);

export const selectMonitorFolderCount = createSelector(selectMonitorFolderIds, lengthIfExists);

export const selectHasLiveSources = createSelector(
  CommonModelSelectors.selectDatasourceModels,
  selectMonitorCamerasCount,
  selectMonitorDatasourcesIds,
  (dsModels, cameraCount, selectedDatasourceIds) => {
    const liveDatasourcesCount = selectedDatasourceIds?.filter(key => dsModels?.[key]?.isLive)
      .length;

    const totalSources = cameraCount + liveDatasourcesCount;
    return totalSources > 0;
  }
);

export const selectIdentityCounts = createSelector(selectMonitorIdentityRows, identityRows => {
  let selectedObjectsCount = 0;
  let poiCount = 0;

  identityRows.forEach(({ identities }) =>
    identities.forEach(({ object }) => (object ? (selectedObjectsCount += 1) : (poiCount += 1)))
  );

  return { selectedObjectsCount, poiCount };
});

export const selectSearchQuery = state => get(state, 'plan.searchQuery');
export const selectFilters = createSelector(selectSearchQuery, query => get(query, 'filters'));
export const selectStartDate = createSelector(selectFilters, filters => get(filters, 'startDate'));
export const selectEndDate = createSelector(selectFilters, filters => get(filters, 'endDate'));
export const selectDateRangeParams = createSelector(selectStartDate, selectEndDate, (start, end) =>
  dateRangeParams([start, end])
);
export const selectDatasources = createSelector(selectSearchQuery, query =>
  get(query, 'datasources')
);
export const selectCameras = createSelector(selectSearchQuery, query => get(query, 'cameras'));
export const selectFolders = createSelector(selectSearchQuery, query => get(query, 'folders'));
export const selectIdentityRows = createSelector(selectSearchQuery, query =>
  get(query, 'identityRows')
);
export const selectZones = createSelector(selectSearchQuery, query => get(query, 'zones'));
export const selectCameraZones = createSelector(selectSearchQuery, query =>
  get(query, 'cameraZones')
);
export const selectMinLingerMillis = createSelector(selectSearchQuery, query =>
  get(query, 'minLingerMillis')
);

export const selectIsLoadingFolders = state => get(state, 'plan.isLoadingFolders');
export const selectSearchQueryIfFromMonitor = createSelector(
  selectSearchQuery,
  selectMonitorSearchQuery,
  (_, { fromMonitor }) => fromMonitor,
  (analysisQuery, monitorQuery, fromMonitor) => (fromMonitor ? monitorQuery : analysisQuery)
);

export const selectDatasourcesSortIndex = state => get(state, 'plan.datasourcesSortIndex');

export const selectHasGeoFilter = createSelector(
  selectGeoFilterCoordinates,
  geoFilterCoords => !isEmpty(geoFilterCoords)
);

export const selectDatasourceKeys = createSelector(selectDatasources, keysIfExists);

export const selectDatasourcesCount = createSelector(selectDatasourceKeys, lengthIfExists);

export const selectDatasourceSortFunction = createSelector(
  selectDatasourcesSortIndex,
  index => planDatasourceSortFunctions[index]
);

export const selectSortData = createSelector(
  CommonModelSelectors.selectDatasourceModels,
  selectDatasourceKeys,
  (models, keys) => keys?.map(key => models[key])
);

export const selectSortedDatasources = createSelector(
  selectDatasourceSortFunction,
  selectSortData,
  (sortFunction, data) => data?.sort(sortFunction)
);

export const selectExcludedVideosFromDatasources = createSelector(
  selectSortedDatasources,
  selectFilters,
  (ds, filters) => ds && filters && getExcludedVideos(ds, filters)
);

export const selectAllExcludedVideos = createSelector(
  selectSortedDatasources,
  selectExcludedVideosFromDatasources,
  selectDatasourcesInGeofence,
  (datasources, excludedVideosFromDatasource, datasourcesInGeofence) => {
    const { excludedVideos, excludedVideosCount } = excludedVideosFromDatasource;
    const {
      excludedVideosWithGeoFilter,
      excludedVideosWithGeoFilterCount,
    } = getExcludedVideosWithGeoFilter(datasources, datasourcesInGeofence);

    const allExcludedVideos = { ...excludedVideos, ...excludedVideosWithGeoFilter };

    const allExcludedVideosCount = excludedVideosCount + excludedVideosWithGeoFilterCount;

    return { allExcludedVideos, allExcludedVideosCount };
  }
);

export const selectExcludedVideos = createSelector(
  selectAllExcludedVideos,
  excludedVideosFromDatasources => excludedVideosFromDatasources?.allExcludedVideos
);

export const selectExcludedVideosCount = createSelector(
  selectAllExcludedVideos,
  excludedVideosFromDatasources => excludedVideosFromDatasources?.allExcludedVideosCount
);

export const selectAreAllDatasourcesExcluded = createSelector(
  selectSortedDatasources,
  selectExcludedVideosCount,
  (datasources, excludedVideosCount = 0) => datasources?.length === excludedVideosCount
);

const createSelectObjectsCount = identityRowsSelector =>
  createSelector(identityRowsSelector, rows =>
    rows.reduce(
      (total, { identities }) =>
        total + identities.reduce((count, { object }) => (object ? count + 1 : count), 0),
      0
    )
  );

const createSelectPersonsCount = identityRowsSelector =>
  createSelector(identityRowsSelector, rows =>
    rows.reduce(
      (total, { identities }) =>
        total + identities.reduce((count, { object }) => (object ? count : count + 1), 0),
      0
    )
  );

export const selectObjectsCountHistorical = createSelectObjectsCount(selectIdentityRows);

export const selectPersonsCountHistorical = createSelectPersonsCount(selectIdentityRows);

export const selectObjectsCountLive = createSelectObjectsCount(selectMonitorIdentityRows);

export const selectPersonsCountLive = createSelectPersonsCount(selectMonitorIdentityRows);

export const selectFolderIds = createSelector(selectFolders, keysIfExists);

export const selectFoldersCount = createSelector(selectFolderIds, lengthIfExists);

const selectCameraIds = createSelector(selectCameras, keysIfExists);

export const selectFolderModels = createSelector(
  selectFolderIds,
  CommonModelSelectors.selectFolderV2Models,
  (keys, models) => compact(keys?.map(id => models[id]))
);

export const getSubfolderIds = (folderModels, id) => {
  const subfolderIds = get(folderModels, [id, 'folders'], []);

  return subfolderIds.reduce(
    (accum, _id) => [...accum, ...getSubfolderIds(folderModels, _id)],
    subfolderIds
  );
};

export const selectParentFolderIds = createSelector(
  [selectFolderIds, CommonModelSelectors.selectFolderV2Models],
  (folderIds, folderModels) => {
    if (isEmpty(folderModels)) return [];

    const _folderIds = folderIds.map(id => Number(id));

    const allSubfolderIds = _folderIds.reduce(
      (accum, id) => [...accum, ...getSubfolderIds(folderModels, id)],
      []
    );

    return _folderIds.filter(id => !allSubfolderIds.includes(id));
  }
);

export const selectTotalFolderItemCount = createSelector(
  [selectParentFolderIds, CommonModelSelectors.selectFolderV2Models, (_, filters) => filters],
  (folderIds, folderModels, filters = [CAMERA, RECORDING, IMAGE, VIDEO]) =>
    isEmpty(folderModels)
      ? 0
      : folderIds.reduce((accum, id) => {
          const itemCounts = folderModels[id]?.itemCounts;
          if (!itemCounts) return accum;

          const total = Object.keys(itemCounts)
            .filter(itemKey => filters.includes(itemKey))
            .reduce((_accum, itemKey) => _accum + itemCounts[itemKey], 0);
          return accum + total;
        }, 0)
);

export const selectDatasourceIdsFromCameras = createSelector(
  selectCameraIds,
  CommonModelSelectors.selectCameraModels,
  (ids, models) =>
    ids?.reduce((acc, id) => {
      const datasources = get(models, [id, 'datasources']);
      return datasources ? acc.concat(datasources) : acc;
    }, [])
);

export const selectAreCamerasZoneable = createSelector(
  selectDatasourceIdsFromCameras,
  ids => ids?.length > 0
);

export const selectAreDatasourcesZoneable = createSelector(
  selectDatasourceKeys,
  selectAreAllDatasourcesExcluded,
  (ids, allExcluded) => ids?.length > 0 && !allExcluded
);

export const selectAreFoldersZoneable = createSelector(
  selectFolderModels,
  folders =>
    !countFolderItems(folders, [IMAGE]) > 0 && countFolderItems(folders, [RECORDING, VIDEO]) > 0
);

export const selectDoesFoldersIncludeImages = createSelector(
  selectFolderModels,
  folders => countFolderItems(folders, [IMAGE]) > 0
);

export const selectIsPlanZoneable = createSelector(
  selectDoesFoldersIncludeImages,
  selectAreDatasourcesZoneable,
  selectAreFoldersZoneable,
  selectAreCamerasZoneable,
  (hasImages, ...args) => !hasImages && args.some(Boolean)
);

export const selectFirstFolderId = createSelector(selectFolderIds, ids => get(ids, 0));

export const selectFirstDatasourceId = createSelector(
  selectDatasourceIdsFromCameras,
  selectDatasourceKeys,
  (cameraDsIds = [], otherDsIds = []) => [...cameraDsIds, ...otherDsIds][0]
);

export const selectFolderItemsCount = createSelector(selectFolderModels, folderModels =>
  countFolderItems(folderModels, [RECORDING, IMAGE, VIDEO])
);

export const selectCameraDsCount = createSelector(selectDatasourceIdsFromCameras, lengthIfExists);

export const selectFilteredDatasourcesCount = createSelector(
  selectDatasourcesCount,
  selectExcludedVideosCount,
  (datasourcesCount = 0, excludedCount = 0) => Math.max(0, datasourcesCount - excludedCount)
);

export const selectPlanDsCount = createSelector(
  selectCameraDsCount,
  selectFilteredDatasourcesCount,
  (...args) => sum(args)
);

export const selectHasDatasources = createSelector(
  selectPlanDsCount,
  selectFolderItemsCount,
  (...args) => args.some(a => a > 0)
);

export const selectHasIdentitiesHistorical = createSelector(
  selectPersonsCountHistorical,
  selectObjectsCountHistorical,
  (...args) => sum(args) > 0
);

export const selectHasIdentitiesLive = createSelector(
  selectPersonsCountLive,
  selectObjectsCountLive,
  (...args) => sum(args) > 0
);

export const selectCanAnalyze = createSelector(
  selectHasDatasources,
  selectHasIdentitiesHistorical,
  (...args) => args.every(Boolean)
);

export const selectCanMonitor = createSelector(
  selectHasIdentitiesLive,
  selectHasLiveSources,
  (hasIdentities, hasLiveSources) => hasIdentities && hasLiveSources
);

export const selectQueryStringFromMonitorIdentityRows = createSelector(
  selectMonitorIdentityRows,
  CommonModelSelectors.selectPersonModels,
  getQueryStringFromIdentityRows
);

export const selectQueryStringFromIdentityRows = createSelector(
  selectIdentityRows,
  CommonModelSelectors.selectPersonModels,
  getQueryStringFromIdentityRows
);

export const selectSerializedDatasourceIds = createSelector(
  selectDatasources,
  getIdStringFromObject
);

export const selectSerializedCameraIds = createSelector(selectCameras, getIdStringFromObject);

export const selectSerializedFolderIds = createSelector(selectFolders, getIdStringFromObject);

export const selectSerializedCameraIdsFromMonitor = createSelector(
  selectMonitorCameras,
  getIdStringFromObject
);

export const selectSerializedSearchQueryParams = createStructuredSelector({
  q: selectQueryStringFromIdentityRows,
  v: selectSerializedDatasourceIds,
  c: selectSerializedCameraIds,
  folders: selectSerializedFolderIds,
  st: selectStartDate,
  et: selectEndDate,
  zones: selectZones,
  minLingerMillis: selectMinLingerMillis,
});

export const selectSerializedSearchQuery = createSelector(
  selectSerializedSearchQueryParams,
  buildSearchQuery
);

export const selectIdentityRowsWithoutExtraneous = createSelector(
  selectIdentityRows,
  removeExtraneousIdentityRows
);

export const selectQueryStringFromIdentityRowsWithoutExtra = createSelector(
  selectIdentityRowsWithoutExtraneous,
  CommonModelSelectors.selectPersonModels,
  getQueryStringFromIdentityRows
);

export const selectSerializedSearchQueryParamsWithoutExtra = createStructuredSelector({
  q: selectQueryStringFromIdentityRowsWithoutExtra,
  v: selectSerializedDatasourceIds,
  c: selectSerializedCameraIds,
  folders: selectSerializedFolderIds,
  st: selectStartDate,
  et: selectEndDate,
  zones: selectZones,
  minLingerMillis: selectMinLingerMillis,
  geofence: selectGeoFilterCoordinates,
  cameraZones: selectCameraZones,
});

export const selectSerializedSearchQueryWithoutExtra = createSelector(
  selectSerializedSearchQueryParamsWithoutExtra,
  buildSearchQuery
);

export const selectCameraCount = createSelector([selectCameraIds], cameraIds => cameraIds.length);

export const selectCameraDatasourcesCount = createSelector(
  [selectDatasourceIdsFromCameras],
  datasourceIds => datasourceIds.length
);

export const selectDatasourceQuery = createSelector(
  selectSerializedDatasourceIds,
  selectSerializedCameraIds,
  selectSerializedFolderIds,
  (allDatasourceIds, allCameraIds, allFolderIds) => {
    const searchQuery = {
      folderIds: allFolderIds,
      datasourceIds: allDatasourceIds,
      cameraIds: allCameraIds,
    };

    return searchQuery;
  }
);

export const selectMonitorQuery = createSelector(
  selectSerializedCameraIdsFromMonitor,
  monitorCameraIds => {
    const searchQuery = {
      cameraIds: monitorCameraIds,
    };
    return searchQuery;
  }
);

export const selectLatLngCoordinates = createSelector(selectGeoFilterCoordinates, geofilter => {
  const { coordinates } = geofilter;
  return coordinates && coordinates[0].map(coord => [coord[1], coord[0]].map(String));
});

export const selectDatasourceModels = createSelector(
  CommonModelSelectors.selectDatasourceModels,
  selectDatasources,
  (dsModels, datasources) => Object.keys(datasources).map(datasourceId => dsModels[datasourceId])
);

export const selectDefaultFilterDatetimeRange = createSelector(
  selectFoldersCount,
  selectDatasourceModels,
  (foldersCount, dsModels) => {
    // We do not hint the datetime range of plan when folder is selected
    // because we do not know what videos are inside the folder.
    if (foldersCount > 0) {
      return [null, null];
    }

    return getDatasourcesDatetimeRange(dsModels);
  }
);
