import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';

import store from 'redux/store';

import { IDENTITY_TYPES } from 'common/constants/objects';
import DetectionInterpolator from 'common/components/videoPlayer/utils/DetectionInterpolator';
import KeyFrame from 'common/components/videoPlayer/utils/KeyFrame';
import Detection from 'components/Detections/Detection';

import { selectObjectModels, selectObjectByReserveName } from 'common/redux/models/selectors';
import { getDetectionsInRange } from 'common/api/datasourceApi';

const INSPECT_FRAME = 'inspect_frame';
const SEARCH_FRAME = 'search_frame';
const MAX_SEARCH_TIME = 60 * 60 * 1000; // 1 hour
const TIME_INTERVAL = 30000;

/* Modified from Stack Overflow */
export function toHHMMSS(value, displayZeroHours = false) {
  const secNum = parseInt(value, 10); // don't forget the second param
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  if (!displayZeroHours && hours === '00') {
    return `${minutes}:${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
}

/* Returns the range covered by the current frames. If the videoplayer currentTime
is outside the range, more detections should be fetched. start or end being null
means we are at the end of the time range and do not need to fetch any more.
Search results frame has timestamp key 'timestamp' while allDetections has 't'
*/
export function getDetectionTimeRange(
  frames,
  timestamp,
  detectionsBefore,
  detectionsAfter,
  timestampKey
) {
  const timeRange = { start: null, end: null };

  let pivotIndex = 0;

  // First find the pivotIndex
  for (let i = 0; i < frames.length; i++) {
    // One millisecond is subtracted from the timestamp because when setTime
    // is called in Video.jsx one millisecond is added, so the timestamp
    // eventually fed to getDetectionTimeRange is one MS ahead of the actual frame
    if (frames[i][timestampKey] >= timestamp - 1) {
      pivotIndex = i;
      break;
    }
  }

  // We re-fetch the detections whenever outside or near the edge of the time interval
  if (pivotIndex >= detectionsBefore - 2) {
    const fromStart = Math.round(detectionsBefore * 0.2);
    timeRange.start = frames[fromStart][timestampKey];
  }

  if (pivotIndex <= frames.length - detectionsAfter + 2) {
    const fromStart = Math.round(frames.length - detectionsAfter * 0.2);
    timeRange.end = frames[fromStart][timestampKey];
  }

  return timeRange;
}

export function shouldFetchDetections(timestamp, rangeStart, rangeEnd) {
  return (
    (isNumber(rangeStart) && timestamp <= rangeStart) ||
    (isNumber(rangeEnd) && timestamp >= rangeEnd)
  );
}

/* Helper function for buildInterpolator */
function _makeKey({ x, y, w, h }) {
  return `${x}_${y}_${w}_${h}`;
}

function normalizeSearchDetection(detection) {
  const nameWithoutColorInfo = detection.name?.split('.')[0];
  const objectModel = selectObjectByReserveName(store.getState(), {
    reservedName: nameWithoutColorInfo,
  });
  return {
    x: detection.bbox.x,
    y: detection.bbox.y,
    w: detection.bbox.width,
    h: detection.bbox.height,
    id: detection.id,
    e: detection.embedding,
    n: detection.name,
    c: detection.confidence,
    k: detection.objectType ?? objectModel?.id,
    detectionType: SEARCH_FRAME,
  };
}

function normalizeInspectDetection(detection) {
  const objectModels = selectObjectModels(store.getState());
  const objectModel = objectModels[detection.k];

  return {
    x: detection.x,
    y: detection.y,
    w: detection.w,
    h: detection.h,
    id: detection.id,
    e: detection.e,
    n: objectModel?.reservedName,
    c: detection.c,
    k: detection.k,
    detectionType: INSPECT_FRAME,
    person: detection.person,
    trackletId: detection.trackletId,
  };
}

/*
  This code got messy when the server changed the searched frame strucuture
  to be different from the all frames strcuture. We should probably redo it.
*/
export function buildInterpolator({
  searchedFrames = [],
  inspectedFrames = [],
  options: {
    frameRate,
    videoStartTime = 0,
    confidenceThreshold = 0,
    showAllDetections = false,
  } = {},
}) {
  const keyFramesDict = {};

  searchedFrames.forEach(frame => {
    if (!keyFramesDict[frame.timestamp]) {
      keyFramesDict[frame.timestamp] = { searched: [], inspected: [] };
    }

    if (frame.matches) {
      frame.matches.forEach(detection =>
        keyFramesDict[frame.timestamp].searched.push(normalizeSearchDetection(detection))
      );
    }
  });

  inspectedFrames.forEach(frame => {
    if (!keyFramesDict[frame.t]) {
      keyFramesDict[frame.t] = { searched: [], inspected: [] };
    }

    if (frame.d) {
      keyFramesDict[frame.t].inspected = frame.d.map(detection =>
        normalizeInspectDetection(detection)
      );
    }
  });

  const keyFrames = Object.keys(keyFramesDict).map(frameTimestamp => {
    const keyFrameDict = keyFramesDict[frameTimestamp];
    const detectionDict = {};

    keyFrameDict.inspected.forEach(detection => {
      /* For now remove detections that are not faces and do not meet the confidence threshold */
      if (
        detection.c * 100 < confidenceThreshold ||
        (!showAllDetections && !IDENTITY_TYPES.includes(detection.k))
      ) {
        return;
      }

      detectionDict[_makeKey(detection)] = detection;
    });

    keyFrameDict.searched.forEach(detection => {
      /* Overwrite the the inspect detections if they conflict */
      detectionDict[_makeKey(detection)] = detection;
    });

    const detections = Object.values(detectionDict).map(
      detection =>
        new Detection({
          id: detection.id,
          name: detection.n,
          type: detection.k,
          embedding: detection.e,
          confidence: detection.c,
          bounds: {
            x: detection.x,
            y: detection.y,
            w: detection.w,
            h: detection.h,
          },
          detectionType: detection.detectionType,
          isInspectedFrame: detection.detectionType === INSPECT_FRAME,
          person: detection.person,
          trackletId: detection.trackletId,
        })
    );

    const timestamp = videoStartTime
      ? parseFloat(frameTimestamp) - videoStartTime
      : parseFloat(frameTimestamp);

    return new KeyFrame({ detections, timestamp });
  });

  const disableInterpolation = frameRate && frameRate <= 5;
  return new DetectionInterpolator({
    disableInterpolation,
    frameRate,
    keyFrames: sortBy(
      keyFrames.filter(keyframe => keyframe.getDetections().length),
      d => d.getTimestamp()
    ),
  });
}

export function buildImageInterpolator({
  searchedFrames = [],
  inspectModeDetections = [],
  confidenceThreshold,
  showAllDetections = false,
} = {}) {
  let searchedDetections = [];
  const inspectedDict = {};

  searchedFrames.forEach(frame => {
    if (frame && frame.matchData) {
      searchedDetections = frame.matchData.map(detection => normalizeSearchDetection(detection));
    }
  });

  inspectModeDetections.forEach(detection => {
    if (
      detection.c * 100 < confidenceThreshold ||
      (!showAllDetections && !IDENTITY_TYPES.includes(detection.k))
    ) {
      return;
    }

    const normalizedDetection = normalizeInspectDetection(detection);
    inspectedDict[_makeKey(normalizedDetection)] = normalizedDetection;
  });

  searchedDetections.forEach(detection => {
    delete inspectedDict[_makeKey(detection)];
  });

  return [...searchedDetections, ...Object.values(inspectedDict)].map(
    detection =>
      new Detection({
        id: detection.id,
        name: detection.n,
        type: detection.k,
        embedding: detection.e,
        confidence: detection.c,
        bounds: {
          x: detection.x,
          y: detection.y,
          w: detection.w,
          h: detection.h,
        },
        detectionType: detection.detectionType,
        isInspectedFrame: detection.detectionType === INSPECT_FRAME,
      })
  );
}

function getExpandedTime(time, count, direction) {
  const expand = 2 ** count;
  const delta = TIME_INTERVAL * expand * direction;

  return time + delta;
}

export function getNearbyDetections(
  videoId,
  timestamp,
  videoStart,
  videoEnd,
  showAllDetections,
  includePersons
) {
  let startCount = 0;
  let endCount = 0;

  /* initial parameter data */
  return getDetectionData({
    v: videoId,
    s: timestamp - TIME_INTERVAL,
    e: timestamp + TIME_INTERVAL,
    d: showAllDetections,
    includePersons,
  });

  function getDetectionData(params) {
    const requests = [getDetectionsBehind(params), getDetectionsAhead(params)];

    return Promise.all(requests).then(data => {
      const framesBefore = data[0].frames || [];
      const framesAfter = data[1].frames || [];

      return {
        frames: framesBefore.concat(framesAfter),
        start: data[0].start,
        end: data[1].end,
      };
    });
  }

  /* tries to find at least 50 detections ahead of the current timestamp */
  function getDetectionsAhead(params) {
    return getDetectionsInRange(videoId, { ...params, s: timestamp }).then(response => {
      const { frames } = response;

      if (frames.length < 50 && params.e < videoEnd) {
        endCount += 1;
        const end = getExpandedTime(timestamp, endCount, 1);

        if (end - timestamp <= MAX_SEARCH_TIME) {
          const newParams = {
            v: videoId,
            s: timestamp,
            e: end > videoEnd ? videoEnd : end,
            includePersons: params.includePersons,
          };

          return getDetectionsAhead(newParams);
        }
      }

      return {
        frames,
        /* Add some buffer time to when we should fetch new detections */
        end: params.e - 2000,
      };
    });
  }

  /* tries to find at least 50 detections before the current timestamp */
  function getDetectionsBehind(params) {
    return getDetectionsInRange(videoId, { ...params, e: timestamp }).then(response => {
      const { frames } = response;

      if (frames.length < 50 && params.s > videoStart) {
        startCount += 1;
        const start = getExpandedTime(timestamp, startCount, -1);

        if (timestamp - start <= MAX_SEARCH_TIME) {
          const newParams = {
            v: videoId,
            s: start < videoStart ? videoStart : start,
            e: timestamp,
            includePersons: params.includePersons,
          };

          return getDetectionsBehind(newParams);
        }
      }

      return {
        frames,
        /* Add some buffer time to when we should fetch new detections */
        start: params.s + 2000,
      };
    });
  }
}

export function normalizeFrames(frame) {
  frame.fromTime = frame.t || frame.timestamp;
  return frame;
}
