import { batchActions } from 'redux-batched-actions';

import {
  getReports,
  patchReport,
  deleteReport,
  postReport,
  getSingleReport,
} from 'common/api/reportApi';
import { getScenes, patchScene, deleteScene, postScene } from 'common/api/sceneApi';
import * as CommonModelActions from 'common/redux/models/actions';
import { fetchCamerasLibrary } from 'library/redux/camera/actions';
import { fetchPersons } from 'library/redux/person/actions';
import { libraryFetchDetectionObjects } from 'library/redux/object/actions';
import {
  selectReportSort,
  selectReportsPagination,
  selectSceneSort,
  selectSceneSortDirection,
  selectScenesPagination,
  selectSelectedReportKey,
} from 'reports/redux/selectors';
import {
  fetchDatasourcesById,
  mergeReportModels,
  mergeSceneModels,
  setSceneModels,
} from 'common/redux/models/actions';
import { selectDatasourceModels, selectReportModels } from 'common/redux/models/selectors';
import { normalizeList } from 'common/helpers/helperFunctions';
import { DESC } from 'library/redux/constants';

function getMissingDatasources(scenes, dsModels) {
  return Object.keys(
    scenes
      .map(({ source }) => source)
      .reduce((acc, dsId) => {
        if (dsId && !dsModels[dsId]) {
          acc[dsId] = true;
        }
        return acc;
      }, {})
  );
}

export function deselectScenes() {
  return {
    type: 'REPORTS/DESELECT_ALL_SCENES',
  };
}

export function selectScenesByKey({ sceneKeys }) {
  return {
    type: 'REPORTS/SELECT_SCENES',
    payload: sceneKeys,
  };
}

export function setReportsView(type) {
  return {
    type: 'REPORTS/SET_CASE_FILES_VIEW',
    payload: type,
  };
}

function setAllReports(reports) {
  return {
    type: 'REPORTS/SET_ALL_REPORTS',
    reports,
  };
}

export function setAllScenes(scenes) {
  return {
    type: 'REPORTS/SET_ALL_SCENES',
    scenes,
  };
}

export function setSingleScene(scene) {
  return {
    type: 'REPORTS/SET_SINGLE_SCENE',
    scene,
  };
}

function setSceneSort(sceneSort, sceneSortDirection) {
  return {
    type: 'REPORTS/SET_SCENE_SORT_OPTION',
    sceneSort,
    sceneSortDirection,
  };
}

export function setReportsPagination(pagination) {
  return {
    type: 'REPORTS/SET_REPORTS_PAGINATION',
    payload: pagination,
  };
}

export function setScenesPagination(pagination) {
  return {
    type: 'REPORTS/SET_SCENES_PAGINATION',
    payload: pagination,
  };
}

function setIsLoadingPage(isLoading) {
  return {
    type: 'REPORTS/SET_IS_LOADING_PAGE',
    payload: isLoading,
  };
}

function setIsLoadingReports(bool) {
  return {
    type: 'REPORTS/TOGGLE_LOADING_REPORTS',
    payload: bool,
  };
}

export function toggleSelectedScene(key) {
  return {
    type: 'REPORTS/TOGGLE_SELECTED_SCENE',
    payload: key,
  };
}

/* ================================= THUNK ACTION CREATORS ====================================== */

export function fetchReports(params = {}) {
  return async function fetchReportsThunk(dispatch, getState) {
    dispatch(setIsLoadingPage(true));

    const state = getState();
    const pagination = selectReportsPagination(state);
    const reportSort = selectReportSort(state);

    params.page = params.page || pagination.page;
    params.page_size = params.limit || pagination.limit;
    params.sort_by = reportSort;

    const { data, range } = await getReports(params).catch(() => dispatch(setIsLoadingPage(false)));

    const reports = normalizeList(data);
    const reportIds = data.map(report => report.id);

    const actions = [mergeReportModels(reports), setAllReports(reportIds), setIsLoadingPage(false)];

    const isFiltered = Boolean(params.ids || params.search);

    if (!isFiltered) {
      actions.push(setReportsPagination({ count: range.total }));
    }

    dispatch(batchActions(actions));

    return data;
  };
}

export const createReport = report => dispatch =>
  postReport(report).then(data => {
    dispatch(fetchReports());
    return data;
  });

export const editReport = report => dispatch =>
  patchReport(report).then(data => {
    dispatch(mergeReportModels({ [data.id]: data }));
    return data;
  });

function fetchScenes(params = {}) {
  return async function fetchScenesThunk(dispatch, getState) {
    dispatch(setIsLoadingPage(true));

    const state = getState();
    const sceneSort = selectSceneSort(state);
    const sortDirection = selectSceneSortDirection(state);
    const sortSuffix = sortDirection === DESC ? '-' : '';

    params.sort_by = `${sortSuffix}${params.sort_by || sceneSort}`;

    const { data, range } = await getScenes(params).catch(() => dispatch(setIsLoadingPage(false)));

    const dsModels = selectDatasourceModels(getState());
    const dsIds = getMissingDatasources(data, dsModels);

    if (dsIds.length) {
      await dispatch(fetchDatasourcesById(dsIds));
    }

    const scenes = normalizeList(data);
    const sceneIds = data.map(({ id }) => id); // NOTE: sceneIds should retain the sort-order returned by the API
    const newPagination = { page: params.page, offset: Math.max(0, range.start - 1) };

    const actions = [
      setAllScenes(sceneIds),
      setIsLoadingPage(false),
      setSceneModels(scenes),
      setScenesPagination(newPagination),
    ];

    dispatch(batchActions(actions));

    return data;
  };
}

export function fetchPaginatedScenes(params = {}) {
  return function fetchPaginatedScenesThunk(dispatch, getState) {
    const pagination = selectScenesPagination(getState());

    params.page = params.page || pagination.page;
    params.page_size = params.limit || pagination.limit;

    return dispatch(fetchScenes(params));
  };
}

function fetchScenesForReport(reportKey, params = {}) {
  return async function fetchScenesForReportThunk(dispatch) {
    const report = await getSingleReport(reportKey);
    params.ids = report?.scenes?.join(',');

    return dispatch(fetchScenes(params));
  };
}

export function fetchScenesByIds(sceneIds, params = {}) {
  return async function fetchScenesForReportThunk(dispatch) {
    dispatch(setIsLoadingPage(true));
    params.ids = sceneIds?.join(',');
    const { data } = await getScenes(params).finally(() => dispatch(setIsLoadingPage(false)));
    const scenes = normalizeList(data);
    dispatch(CommonModelActions.mergeSceneModels(scenes));
    return data;
  };
}

export function refreshScenes(params) {
  return function refreshScenesThunk(dispatch, getState) {
    const selectedReportKey = selectSelectedReportKey(getState());

    if (selectedReportKey) {
      return dispatch(fetchScenesForReport(selectedReportKey, params));
    }

    return dispatch(fetchPaginatedScenes(params));
  };
}

function fetchSceneCount() {
  return async function fetchSceneCountThunk(dispatch) {
    const { range } = await getScenes({ page: 1, page_size: 1 });

    dispatch(setScenesPagination({ count: range.total }));
  };
}

export function setSceneSortAndRefetch(sceneSort, sceneSortDirection) {
  return function setSceneSortAndRefetchThunk(dispatch) {
    dispatch(setSceneSort(sceneSort, sceneSortDirection));

    return dispatch(refreshScenes({ sort_by: sceneSort }));
  };
}

export const removeReport = id => dispatch =>
  deleteReport(id).then(data => {
    dispatch(setSelectedReport(null));
    dispatch(fetchReports());
    dispatch(fetchPaginatedScenes());
    dispatch(fetchSceneCount());

    return data;
  });

export const removeScenes = ids => dispatch => {
  const requests = [];
  ids.forEach(id => requests.push(deleteScene(id)));

  return Promise.all(requests).finally(async data => {
    await Promise.all([
      dispatch(fetchReports()),
      dispatch(refreshScenes()),
      dispatch(fetchSceneCount()),
    ]);
    return data;
  });
};

export const editScene = scene => dispatch =>
  patchScene(scene).then(data => {
    dispatch(fetchReports());
    dispatch(mergeSceneModels({ [data.id]: data }));
    return data;
  });

export const createScene = scene => (dispatch, getState) =>
  postScene(scene).then(data => {
    const sceneIds = getState().report.scenes.concat(data.id);

    dispatch(fetchReports());
    dispatch(fetchSceneCount());
    dispatch(batchActions([mergeSceneModels({ [data.id]: data }), setAllScenes(sceneIds)]));

    return data;
  });

export function setSelectedReport(reportKey) {
  return async function setSelectedReportThunk(dispatch, getState) {
    const report = selectReportModels(getState())[reportKey];
    const actions = [
      {
        type: 'REPORTS/SET_SELECTED_REPORT',
        payload: reportKey,
      },
      deselectScenes(), // Scene selection should be reset whenever selected report changes
    ];

    // If the report is defined but has no scenes, we reset pagination and skip fetching
    if (report?.scenes?.length === 0) {
      actions.push(setAllScenes([]), setScenesPagination({ page: 1, offset: 0 }));
    } else if (reportKey) {
      await dispatch(fetchScenesForReport(reportKey));
    } else {
      await dispatch(fetchPaginatedScenes());
    }

    return dispatch(batchActions(actions));
  };
}

export const initReportPage = () => dispatch => {
  dispatch(setIsLoadingReports(true));

  return Promise.all([
    dispatch(fetchCamerasLibrary()),
    dispatch(fetchPersons()),
    dispatch(libraryFetchDetectionObjects()),
    dispatch(CommonModelActions.fetchFoldersV2()),
    dispatch(fetchPaginatedScenes()),
    dispatch(fetchSceneCount()),
    dispatch(fetchReports()),
  ]).finally(() => dispatch(setIsLoadingReports(false)));
};
