import { makeStyles } from 'tss-react-local';
import { PRIMARY } from 'common/constants/colors';
import Button from 'common/components/base/Button';

const useStyles = makeStyles()({
  main: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: PRIMARY,
    color: 'white',
    minHeight: 50,
    padding: '0 24px',
    boxSizing: 'border-box',
  },
  logo: {
    fontFamily: 'Orbitron, sans-serif',
    letterSpacing: '0.2em',
  },
  light: {
    color: '#95a0b7',
  },
  content: {
    width: 960,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 40,

    '& h2': {
      marginBottom: 24,
    },
    '& p': {
      fontWeight: 300,
    },
    '& h4': {
      fontWeight: 400,
      cursor: 'pointer',
      width: 'max-content',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 16,

    '& button': {
      marginRight: 8,
    },
  },
});

const MirageErrorPage = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.main}>
      <header className={classes.header}>
        <h3 className={classes.logo}>
          percipient.
          <span className={classes.light}>ai</span>
        </h3>
      </header>
      <section className={classes.content}>
        <h2>Mirage has encountered an error</h2>
        <p>
          The application has unexpectedly crashed. Please restart the application by reloading the
          page.
        </p>

        <br />
        <br />
        <br />

        <div className={classes.actions}>
          <Button onClick={() => window.location.reload()} theme="blue">
            RELOAD PAGE
          </Button>
        </div>
      </section>
    </div>
  );
};

export default MirageErrorPage;
