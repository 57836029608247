import React from 'react';
import { withStyles } from 'tss-react-local';
import { SECONDARY } from 'common/constants/colors';

const styles = {
  canvas: {
    cursor: 'crosshair',
    border: '1px solid black',
    pointerEvents: 'unset',
  },
};

class CanvasSketchTool extends React.Component {
  componentDidMount() {
    this._canvas.getContext('2d').strokeStyle = SECONDARY;
    this._canvas.getContext('2d').setLineDash([15, 4]);
  }

  getBoundingRect = () => this.boundingRect;

  _clearCanvas = () =>
    this._canvas.getContext('2d').clearRect(0, 0, this._canvas.width, this._canvas.height);

  _onMouseDown = e => {
    this._origin = [e.nativeEvent.layerX, e.nativeEvent.layerY];
    this._isMouseDown = true;
  };

  _onMouseUp = () => {
    this._isMouseDown = false;
  };

  _onMouseMove = e => {
    if (this._isMouseDown) {
      const [originX, originY] = this._origin;
      const eventX = e.nativeEvent.layerX;
      const eventY = e.nativeEvent.layerY;
      const w = Math.abs(originX - eventX);
      const h = Math.abs(originY - eventY);
      const x = eventX < originX ? eventX : originX;
      const y = eventY < originY ? eventY : originY;
      this._clearCanvas();
      this._canvas.getContext('2d').strokeRect(x, y, w, h);
      this.boundingRect = { x, y, w, h };
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <canvas
        className={classes.canvas}
        ref={node => (this._canvas = node)}
        onMouseDown={this._onMouseDown}
        onMouseUp={this._onMouseUp}
        onMouseMove={this._onMouseMove}
        width="1200"
        height="900"
      />
    );
  }
}

export default withStyles(CanvasSketchTool, styles);
