import { useState } from 'react';
import { makeStyles } from 'tss-react-local';

import Button from 'common/components/base/Button';
import Dialog, { DialogActions } from 'components/Dialog';
import DraggableDialog from 'components/Dialog/DraggableDialog';
import FullScreenDialog from 'components/Dialog/FullScreenDialog';

import { Example } from '../helpers';

const useStyles = makeStyles()({
  fullScreen: {
    backgroundColor: 'blanchedalmond',
  },
  paper: {
    height: 550,
    padding: 20,
    width: 550,
  },
  title: {
    cursor: 'move',
    height: 100,
    textAlign: 'center',
    width: '100%',
  },
});

const DialogExample = () => {
  const [openDialogNumber, setOpenDialogNumber] = useState(null);
  const { classes } = useStyles();

  const onClose = () => setOpenDialogNumber(null);

  return (
    <div>
      <Example label="Default Dialog">
        <Button onClick={() => setOpenDialogNumber(1)}>Open</Button>
        <Dialog open={openDialogNumber === 1} onClose={onClose}>
          Default Dialog with DialogActions
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Close
            </Button>
            <Button onClick={onClose}>Cool</Button>
          </DialogActions>
        </Dialog>
      </Example>
      <Example label="Full Screen Dialog">
        <Button onClick={() => setOpenDialogNumber(4)}>Open</Button>
        <FullScreenDialog
          classes={{ paper: classes.fullScreen }}
          open={openDialogNumber === 4}
          onClose={onClose}
        >
          Full Screen Dialog
        </FullScreenDialog>
      </Example>
      <Example label="Draggable Dialog">
        <Button onClick={() => setOpenDialogNumber(3)}>Open</Button>
        <DraggableDialog dragHandleId="dragTarget" open={openDialogNumber === 3} onClose={onClose}>
          <div className={classes.title} id="dragTarget">
            Draggable Dialog Content
            <br />
            <br />
            This title section is the drag target
          </div>
          <div>This is not the drag target</div>
        </DraggableDialog>
      </Example>
    </div>
  );
};

export default DialogExample;
