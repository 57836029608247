import React from 'react';

export function lazyWithPreload(factory) {
  const Component = React.lazy(factory);
  Component.preload = factory;
  return Component;
}

/*
  navigator.onLine detects whether or not there is an internet connection, e.g. if you are connected to some network.
  It does not know what this network is. If the network is local, there is technically no "real"
  internet connection, but rather only connections to other devices on the local network.

  The ping function is supposed to determine actual internet connection by trying to ping a random
  image from the cloud. If the response succeeds, we assume there is internet connection to the outside.
*/
export function checkInternet() {
  const ip = `https://www.gstatic.com/webp/gallery3/1.sm.png?cachebreaker=${Date.now()}`;
  const img = new Image();
  img.onload = () => {
    window.PERCIPIENT_IS_ONLINE = true;
    return true;
  };
  img.onerror = () => {
    window.PERCIPIENT_IS_ONLINE = false;
    return false;
  };
  img.src = ip;
}

export const isGoogleActive = () => Boolean(window.PERCIPIENT_IS_ONLINE && window.google);

export function hasValidBrowser() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  const isChrome =
    userAgent.includes('chrome') &&
    !userAgent.includes('opr') &&
    !userAgent.includes('edge') &&
    !userAgent.includes('edg');

  const isFirefox = userAgent.includes('firefox');

  return isChrome || isFirefox;
}

export const INTERNET_IS_INACTIVE_MESSAGE = 'Map Service is not available or reachable';

export const NO_INTERNET_LIVE_CAMERA_MESSAGE =
  'Camera does not have associated location information';

export const LOCATION_NOT_AVAILABLE_MESSAGE = 'Location information is not available';

export const LOCATION_NOT_AVAILABLE_FOR_PAGE_MESSAGE =
  'Location information is not available for current page';

export const NO_LOCATION_LIVE_CAMERA_MESSAGE = 'No Location Assigned to this camera';
