import React from 'react';
import { useSelector } from 'react-redux';
import injectSheet from 'react-jss';
import Tooltip from 'common/components/base/Tooltip';
import { NAVY_BLUE } from 'common/constants/colors';

import { COLUMNS } from './constants';

import { getSortedByField } from './utils';
import { selectPaginationSortBy } from './redux/selectors';

const styles = {
  headerColumn: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      paddingLeft: 24,
    },
    color: NAVY_BLUE,
    fontWeight: 400,
    textTransform: 'initial',
    marginTop: 4,
  },
};

const JobHeader = ({ columnName, showToolTip, classes }) => {
  const defaultSortBy = useSelector(selectPaginationSortBy);

  const headerText = COLUMNS[columnName].header || '';
  const toolTipText = showToolTip
    ? `Sort by ${columnName} not yet available in upload group view`
    : '';

  const shouldRenderSortIcon = getSortedByField(defaultSortBy) === columnName;
  const sortIcon = !defaultSortBy.startsWith('-') ? <>&#x2193;</> : <>&#x2191;</>;

  return (
    <Tooltip title={toolTipText}>
      <div className={classes.headerColumn}>
        {shouldRenderSortIcon && sortIcon} {headerText}
      </div>
    </Tooltip>
  );
};

export default injectSheet(styles)(JobHeader);
