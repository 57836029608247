import { batchActions } from 'redux-batched-actions';
import { isNumber, cloneDeep } from 'lodash';

import history from 'history.js';
import { fetchDatasourcesById } from 'common/redux/models/actions';
import * as AnalysisActions from 'analysis/redux/actions';
import * as PlanActions from 'plan/redux/actions';
import { renderAlert, renderError } from 'app/redux/actions';
import { fetchPersons } from 'analysis/redux/actions';
import {
  setInitialState,
  setSelectedSiftDataSource,
  setSelectedSiftDataSources,
  setFilterDates,
} from 'analysis/siftView/redux/actions';
import {
  postSearchRequest,
  postSearchRequestEstimate,
  postSearchRequestRecommendation,
} from 'common/api/searchRequest';
import * as FolderApiV2 from 'common/api/folder/v2';
import { getCameraDatasources } from 'common/api/cameraApi';
import QUERY from 'common/constants/query';
import { extractMirageFormError } from 'common/helpers/apiErrorUtils';
import createSearchRequestParams from 'common/helpers/createSearchRequestParams';
import { findMatchingIdentityInRow } from 'plan/redux/utils';
import isSearchQueryValid from 'plan/redux/isSearchQueryValid';
import { selectUseCache, selectIsSearchlightEnabled } from 'settings/redux/selectors';
import { selectStartDate, selectEndDate } from 'plan/redux/selectors';
import { ANALYSIS_ROUTE, ANALYSIS_SIFT_ROUTE } from 'common/constants/urls';
import { OBJECT_TYPES } from 'analysis/siftView/constants';
import { DSFOLDERS_PARAMS } from 'common/constants/parameters';
import { DATASOURCE, IMAGE } from 'common/constants/app';
import { SEARCH_TYPE_COMMON_PERSON, SEARCH_TYPE_SEARCHLIGHT } from 'analysis/constants';
import { searchlightEnabledForType } from 'analysis/siftView/utils';

import * as myQs from 'common/helpers/queryString';
import * as SearchBarSelectors from './selectors';
import { buildAnalysisSearchQuery } from '../utils';
import { MAX_SUPPORTED_DATASOURCES, SEARCH_PENDING } from '../constants';

export function clearAnalysisIdentityRows() {
  return {
    type: 'ANALYSIS/CLEAR_IDENTITY_ROWS',
  };
}

export function resetAnalysisSearchQuery() {
  return {
    type: 'ANALYSIS/RESET_SEARCH_QUERY',
  };
}

export function setAnalysisSearchQuery(searchQuery) {
  return {
    type: 'ANALYSIS/SET_SEARCH_QUERY',
    payload: searchQuery,
  };
}

export function setAnalysisSearchQueryEstimate(estimate) {
  return {
    type: 'ANALYSIS/SET_SEARCH_QUERY_ESTIMATE',
    payload: estimate,
  };
}

export function setAnalysisIdentityRows(identityRows) {
  return {
    type: 'ANALYSIS/SET_IDENTITY_ROWS',
    payload: identityRows,
  };
}

export const setIsLoadingSearchRequest = payload => ({
  type: 'ANALYSIS/SET_LOADING_REQUEST',
  payload,
});

export const setIsLoadingDatasources = payload => ({
  type: 'ANALYSIS/SET_LOADING_DATASOURCES',
  payload,
});

export const setIsLoadingGroupBy = payload => ({ type: 'ANALYSIS/SET_LOADING_GROUPBY', payload });

const containsDatasourceWithTypeOfEmbeddings = (type, datasources) =>
  datasources.some(ds => searchlightEnabledForType(ds, type));

export function shouldSwitchToSearchlight(query, dataSources) {
  if (!dataSources?.length) return false;

  const isFaceQuery = query.includes(QUERY.FACE);
  const isCarQuery = query.includes(QUERY.CAR);
  if (isFaceQuery || isCarQuery) {
    const allowedNumberOfOrs = isCarQuery && isFaceQuery ? 1 : 0;
    const queryIncludesAndOrOtherObjectTypes =
      query.includes('&') || query.split('|').length - 1 > allowedNumberOfOrs;
    if (queryIncludesAndOrOtherObjectTypes) {
      return false;
    }

    return (
      (!isFaceQuery || containsDatasourceWithTypeOfEmbeddings(OBJECT_TYPES.PEOPLE, dataSources)) &&
      (!isCarQuery || containsDatasourceWithTypeOfEmbeddings(OBJECT_TYPES.VEHICLES, dataSources))
    );
  }

  return false;
}

/* ========================= THUNK ACTION CREATORS ========================== */

export function estimateAndSubmitSearch({ searchQuery }) {
  return function estimateAndSubmitSearchThunk(dispatch, getState) {
    const useCache = selectUseCache(getState());

    const requestParams = createSearchRequestParams({
      searchQuery,
      useCache,
    });

    dispatch(AnalysisActions.setAnalysisIsSubmitting(true));

    return postSearchRequestEstimate(requestParams)
      .then(({ estimate }) => {
        dispatch(setAnalysisSearchQueryEstimate(estimate));
        return dispatch(submitSearch({ searchQuery }));
      })
      .catch(err => {
        handleSearchError(err);
      })
      .finally(() => {
        dispatch(AnalysisActions.setAnalysisIsSubmitting(false));
      });
  };
}

export function getUserSelectedDataSources(searchQuery) {
  return async function getUserSelectedDataSourcesThunk(dispatch) {
    const folderIds = searchQuery.folderIds ? searchQuery?.folderIds?.split(',') : [];
    const dataSourceIds = searchQuery.datasourceIds ? searchQuery?.datasourceIds?.split(',') : [];
    const cameraIds = searchQuery.cameraIds ? searchQuery?.cameraIds?.split(',') : [];

    let datasources = [];
    let folderDatasources = [];
    let folderImages = [];
    let cameraDatasources = [];
    if (dataSourceIds.length > 0) {
      datasources = await dispatch(fetchDatasourcesById(dataSourceIds));
    }

    if (folderIds.length > 0) {
      folderDatasources = await Promise.all(folderIds.map(getItemsForFolder)).then(responses =>
        responses.reduce((acc, x) => {
          acc = acc.concat(x.data);
          return acc;
        }, [])
      );
      folderImages = await Promise.all(folderIds.map(getImagesForFolder)).then(responses =>
        responses.reduce((acc, x) => {
          acc = acc.concat(x.data);
          return acc;
        }, [])
      );
    }

    if (cameraIds.length > 0) {
      cameraDatasources = await Promise.all(cameraIds.map(getCameraDatasources)).then(responses =>
        responses.reduce((acc, x) => {
          acc = acc.concat(x.results);
          return acc;
        }, [])
      );
    }

    return [...datasources, ...folderDatasources, ...cameraDatasources, ...folderImages];
  };
}

function initializeSiftSearch(datasources, trackletEnabledDataSource) {
  return async function initializeSiftSearchThunk(dispatch, getState) {
    const state = getState();
    const planStartTime = selectStartDate(state);
    const planEndTime = selectEndDate(state);

    // TODO : possibly consolidate to just one action initializeSift?
    dispatch(setSelectedSiftDataSources(datasources));
    dispatch(
      batchActions([
        setInitialState(),
        setSelectedSiftDataSource(trackletEnabledDataSource),
        AnalysisActions.setAnalysisIsSubmitting(false),
        setFilterDates({
          startDate: planStartTime,
          endDate: planEndTime,
        }),
      ])
    );
  };
}

export function submitSearch({ searchQuery } = {}) {
  return async function submitSearchThunk(dispatch, getState) {
    const state = getState();
    const useCache = selectUseCache(state);
    const isSearchlightEnabled = selectIsSearchlightEnabled(state);

    dispatch(AnalysisActions.setAnalysisIsSubmitting(true));

    if (!isSearchQueryValid(searchQuery)) {
      renderAlert('Search query is not valid');
      return Promise.reject();
    }

    const reqParams = createSearchRequestParams({
      searchQuery,
      useCache,
    });

    dispatch(AnalysisActions.setSearchStatus(SEARCH_PENDING));

    const dataSources = await dispatch(getUserSelectedDataSources(searchQuery));
    if (isSearchlightEnabled && shouldSwitchToSearchlight(searchQuery.query, dataSources)) {
      if (dataSources.length > MAX_SUPPORTED_DATASOURCES) {
        renderAlert(
          `A maximum of ${MAX_SUPPORTED_DATASOURCES} data sources can be viewed in Searchlight at a time. Please reduce the number of data sources and try again.`
        );
        return;
      }

      const objectType = searchQuery.query.includes(QUERY.FACE)
        ? OBJECT_TYPES.PEOPLE
        : OBJECT_TYPES.VEHICLES;
      const trackletEnabledDataSource = dataSources.find(ds =>
        searchlightEnabledForType(ds, objectType)
      );

      dispatch(initializeSiftSearch(dataSources, trackletEnabledDataSource));
      reqParams.searchType = SEARCH_TYPE_SEARCHLIGHT;

      return postSearchRequest(reqParams)
        .then(response =>
          history.push(
            myQs.stringifyUrl({
              url: `${ANALYSIS_SIFT_ROUTE}/${response.requestId}/ds/${trackletEnabledDataSource.id}`,
              query: { objectType },
            })
          )
        )
        .catch(handleSearchError)
        .finally(() => dispatch(AnalysisActions.setAnalysisIsSubmitting(false)));
    }

    return postSearchRequest(reqParams)
      .then(response => history.push(`${ANALYSIS_ROUTE}/${response.requestId}`))
      .catch(handleSearchError)
      .finally(() => dispatch(AnalysisActions.setAnalysisIsSubmitting(false)));
  };
}

const prevalenceSearchRequestParams = (datetimes, searchQuery, getState) => {
  const useCache = selectUseCache(getState());
  const requestParams = createSearchRequestParams({
    searchQuery: { ...searchQuery, searchType: SEARCH_TYPE_COMMON_PERSON },
    useCache,
  });

  //  Add the filtering by datetimes as needed.
  requestParams.datasources = Object.keys(datetimes).reduce((acc, dsId) => {
    acc[dsId] = {
      endTimestamp: datetimes[dsId].endDate,
      startTimestamp: datetimes[dsId].startDate,
    };
    return acc;
  }, {});

  return requestParams;
};

export function submitPrevalenceSearchEstimate({ datetimes, searchQuery } = {}) {
  return function submitPrevalenceSearchEstimateThunk(dispatch, getState) {
    const requestParams = prevalenceSearchRequestParams(datetimes, searchQuery, getState);

    return postSearchRequestEstimate(requestParams).catch(handleSearchError);
  };
}

export function submitPrevalenceSearchRecommendation({ datasources }) {
  //  Since the recommendation uses the search-request endpoint, we have to include some cruft
  return postSearchRequestRecommendation({
    datasources,
    searchType: SEARCH_TYPE_COMMON_PERSON,
    q: 'unused',
    t: 'unused',
  }).catch(handleSearchError);
}

export function submitPrevalenceSearch({
  datetimes,
  incidentTimes,
  planState,
  searchQuery,
  sliderValues,
} = {}) {
  return function submitPrevalenceSearchThunk(dispatch, getState) {
    const requestParams = prevalenceSearchRequestParams(datetimes, searchQuery, getState);

    //  Add incident times, if there are any, since this is an actual search
    Object.keys(incidentTimes).map(
      id => (requestParams.datasources[id].incidentTimestamp = incidentTimes[id])
    );

    //  If given slider values, add them as well
    if (sliderValues?.length) {
      requestParams.sliderValuesInMinutes = sliderValues;
    }

    //  Add the complete plan state so we can later edit/re-run this analysis
    requestParams.planState = planState;

    dispatch(AnalysisActions.setAnalysisIsSubmitting(true));
    return postSearchRequest(requestParams)
      .then(response => response)
      .catch(handleSearchError)
      .finally(() => dispatch(AnalysisActions.setAnalysisIsSubmitting(false)));
  };
}

function handleSearchError(err) {
  const { message } = extractMirageFormError(err);

  renderError({ code: message ? undefined : 'Unknown Error', message, severity: 'error' });

  return Promise.reject(err);
}

export function searchRequestComplete({ searchRequest, searchId }) {
  return async function searchRequestCompleteThunk(dispatch) {
    // NOTE: Once the property in searchQuery.query references the ID of the person
    // instead of name, we will not need to await this. But so long as it references name we'll
    // need to fetch these models before we can call buildAnalysisSearchQuery
    await dispatch(fetchPersons());

    return dispatch(
      batchActions([
        setAnalysisSearchQuery(buildAnalysisSearchQuery(searchRequest)),
        setAnalysisSearchQueryEstimate(null),
        AnalysisActions.setSearchRequestId(searchId),
        AnalysisActions.setSearchStatus(SEARCH_PENDING),
        setIsLoadingSearchRequest(false),
      ])
    );
  };
}

export function removeIdentityFromAnalysisQuery({ id, rowIndex, isObject }) {
  return (dispatch, getState) => {
    const { searchQuery } = getState().analysis;
    const identityRows = cloneDeep(searchQuery.identityRows);
    const identityIndex = findMatchingIdentityInRow(identityRows[rowIndex], id, isObject);

    // If only one identity remains in the row, remove entire row
    if (identityRows[rowIndex].identities.length === 1) {
      dispatch(removeAnalysisIdentityRow(rowIndex));
      return;
    }

    if (isNumber(identityIndex)) {
      identityRows[rowIndex].identities = [
        ...identityRows[rowIndex].identities.slice(0, identityIndex),
        ...identityRows[rowIndex].identities.slice(identityIndex + 1),
      ];
    }

    return dispatch(setAnalysisIdentityRows(identityRows));
  };
}

export function removeAnalysisIdentityRow(rowIndex = null) {
  return (dispatch, getState) => {
    const { searchQuery } = getState().analysis;

    if (searchQuery.identityRows.length === 1) {
      return dispatch(clearAnalysisIdentityRows());
    }

    const identityRows = cloneDeep(searchQuery.identityRows);

    return dispatch(
      setAnalysisIdentityRows([
        ...identityRows.slice(0, rowIndex),
        ...identityRows.slice(rowIndex + 1),
      ])
    );
  };
}

export const editPlan = () => (dispatch, getState) => {
  const state = getState();
  const query = SearchBarSelectors.selectSerializedSearchQueryParams(state);
  return dispatch(PlanActions.editPlan(query));
};

export const analysisResultSubmitSearch = (query = {}) => (dispatch, getState) => {
  const state = getState();
  // Merge searchQuery with the overriden query
  const searchQuery = {
    ...SearchBarSelectors.selectSerializedSearchQuery(state),
    ...query,
  };
  dispatch(AnalysisActions.resetAnalysis());
  dispatch(AnalysisActions.clearSearchResults());
  dispatch(estimateAndSubmitSearch({ searchQuery }));
};

export function getItemsForFolder(id) {
  const params = {
    [DSFOLDERS_PARAMS.page]: 1,
    [DSFOLDERS_PARAMS.pageSize]: 10000,
    [DSFOLDERS_PARAMS.isDeepSearchEnabled]: true,
    [DSFOLDERS_PARAMS.itemType]: DATASOURCE,
  };

  return FolderApiV2.getItemsForSingleFolder(id, params);
}

export function getImagesForFolder(id) {
  const params = {
    [DSFOLDERS_PARAMS.page]: 1,
    [DSFOLDERS_PARAMS.pageSize]: 10000,
    [DSFOLDERS_PARAMS.isDeepSearchEnabled]: true,
    [DSFOLDERS_PARAMS.itemType]: IMAGE,
  };

  return FolderApiV2.getItemsForSingleFolder(id, params);
}
