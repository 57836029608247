import { OPERATORS, DEFAULT_OPERATOR } from 'common/constants/operators';
import GroupRow from './GroupRow';
import getParsedQuery from './getParsedQuery';
import findPersonByName from './findPersonByName';
import findObjectByName from './findObjectByName';

export default function buildIdentityRows(query) {
  const identityRows = [new GroupRow()];
  if (!query) return identityRows;

  const parsedQuery = getParsedQuery(query);

  parsedQuery.forEach((group, index) => {
    const isLastGroup = index === parsedQuery.length - 1;

    group.forEach((term, i) => {
      const isOperator = !!OPERATORS[term];

      if (!isOperator) {
        const isLastIdentity = group[i + 2] === undefined;
        const identity = findPersonByName(term) || findObjectByName(term);

        if (!identity) return;

        identity.operator = (!isLastIdentity && OPERATORS[group[i + 1]]) || DEFAULT_OPERATOR;

        if (identity.object) {
          identityRows[index].identities.push(identity);
        } else {
          identityRows[index].identities.push({
            id: identity.id,
            operator: identity.operator,
          });
        }
      }
    });

    if (!isLastGroup) {
      const groupTerm = group[group.length - 1];

      identityRows[index].groupOperator = OPERATORS[groupTerm];
      identityRows.push(new GroupRow());
    }
  });

  return identityRows;
}
