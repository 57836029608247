import uniqWith from 'lodash/uniqWith';
import flatMap from 'lodash/flatMap';
import isNumber from 'lodash/isNumber';

import {
  SEARCH_REQUEST_FAILED,
  SEARCH_REQUEST_EXPIRED,
  SEARCH_REQUEST_CANCELED,
  SEARCH_REQUEST_DROPPED,
  SEARCH_STATUS_TO_TERM,
} from 'analysis/redux/constants';

export function getStatusTextFromKey(statusKey) {
  return SEARCH_STATUS_TO_TERM[statusKey];
}

export function getDisabledState(status) {
  return (
    status === SEARCH_REQUEST_FAILED ||
    status === SEARCH_REQUEST_EXPIRED ||
    status === SEARCH_REQUEST_CANCELED ||
    status === SEARCH_REQUEST_DROPPED
  );
}

export function flattenResults(results = []) {
  return flatMap(results, result => {
    if (result.items && result.items.length) {
      return result.items;
    }
    if (result.subGroups && result.subGroups.length) {
      return flatMap(result.subGroups, group =>
        flattenResultsAndFilterByMediaId(group.items || [])
      );
    }
    return result;
  });
}

export function flattenResultsAndFilterByMediaId(results = []) {
  return uniqWith(flattenResults(results), (a, b) => a.mediaId === b.mediaId);
}

export function toMillSec(date) {
  return new Date(date).getTime();
}

function createSegment(timelineEnd, timelineStart, startDate, tracklet, datasource) {
  return {
    ...tracklet,
    fromTime: datasource.camera ? tracklet.startTimestamp : tracklet.startTimestamp + startDate,
    toTime: datasource.camera ? tracklet.endTimestamp : tracklet.endTimestamp + startDate,
    tu: tracklet.thumbUrl,
    confidence: isNumber(tracklet.confidence) ? tracklet.confidence : 1,
    isConfidenceValid: isNumber(tracklet.confidence),
  };
}

function isWithinConfidence(tracklet, confidenceRange) {
  return tracklet.confidence >= confidenceRange[0] && tracklet.confidence <= confidenceRange[1];
}

function getSegments({
  timelineEnd,
  timelineStart,
  startDate,
  tracklets,
  confidenceRange,
  shouldCheckConfidence,
  datasource,
}) {
  const segments = [];
  if (!tracklets || tracklets.length === 0) {
    return segments;
  }

  tracklets.forEach(tracklet => {
    if (shouldCheckConfidence) {
      if (isWithinConfidence(tracklet, confidenceRange)) {
        segments.push(createSegment(timelineEnd, timelineStart, startDate, tracklet, datasource));
      }
    } else {
      segments.push(createSegment(timelineEnd, timelineStart, startDate, tracklet, datasource));
    }
  });

  return segments;
}

// Note: Temporary workaround to structure the data to fit the general timeline component.
// Once BE changes the field names to be consistent, we can remove this.
export function computeTimeLineData({
  timelineEnd,
  timelineStart,
  startDate,
  groupingTracklet,
  confirmedPersons,
  confidenceRange,
  confirmedPerson,
  groupedIdentityCreated,
  shouldCheckConfidence,
  datasource,
}) {
  const timeLinePersons = [];
  if (groupingTracklet && !groupedIdentityCreated) {
    timeLinePersons.push({
      ...groupingTracklet,
      segments: getSegments({
        timelineEnd,
        timelineStart,
        startDate,
        tracklets: groupingTracklet.segments,
        confidenceRange,
        shouldCheckConfidence,
        datasource,
      }),
    });
  }

  const confirmedPersonsInfo = confirmedPersons.map(person => ({
    ...person,
    groupId: person.name,
    thumbURL: person.thumbUrl,
    segments: person.sightings
      .filter(tracklet => {
        if (confirmedPerson && confirmedPerson.id === tracklet.detectionId) {
          return isWithinConfidence(tracklet, confidenceRange);
        }
        return true;
      })
      .map(sighting => ({
        ...sighting,
        fromTime: datasource.camera ? sighting.startTimestamp : sighting.startTimestamp + startDate,
        toTime: datasource.camera ? sighting.endTimestamp : sighting.endTimestamp + startDate,
        tu: sighting.thumbUrl,
        confidence: sighting.confidence,
      })),
  }));

  return timeLinePersons.concat(confirmedPersonsInfo);
}
