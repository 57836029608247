import injectSheet from 'react-jss';
import MaterialLinearProgress from '@material-ui/core/LinearProgress';

import { SECONDARY, ERROR } from 'common/constants/colors';

const styles = {
  root: {
    borderRadius: 2,
  },
  primary: {
    backgroundColor: SECONDARY,
  },
  secondary: {
    backgroundColor: ERROR,
  },
};

const LinearProgress = ({ classes, ...rest }) => (
  <MaterialLinearProgress
    classes={{
      root: classes.root,
      barColorPrimary: classes.primary,
      barColorSecondary: classes.secondary,
    }}
    {...rest}
  />
);

LinearProgress.defaultProps = {
  color: 'primary',
  variant: 'determinate',
  value: 0,
};

export default injectSheet(styles)(LinearProgress);
