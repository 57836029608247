import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'react-jss';

import { makeStyles } from 'tss-react-local';
import { setGlobalProcessingPanelOpen } from 'library/common/globalProcessingPanel/redux/actions';
import { selectIsGeoActive } from 'settings/redux/selectors';
import { selectIsNavExpanded } from 'app/redux/selectors';

import AvatarIcon from 'common/icons/avatar';

import Typography from 'components/Typography';
import MenuItem from 'common/components/base/MenuItem';
import Tooltip from 'common/components/base/Tooltip';
import Icon from 'common/components/base/Icon';

import { SIDEBAR_EXPANDED_WIDTH, SIDEBAR_WIDTH } from 'common/constants/app';
import { SETTINGS_ROUTE } from 'common/constants/urls';
import { renderNotification } from 'app/redux/actions';
import { LOCAL_SETTINGS } from 'settings/redux/constants';

import MirageHealthIcon from 'app/header/mirageHealth/MirageHealthIcon';
import MirageHealthDialog from 'app/header/mirageHealth/MirageHealthDialog';
import ProfileMenu from 'app/header/ProfileMenu';
import JobProcessingIcon from 'app/header/processingJobs/JobProcessingIcon';
import JobProcessingDialog from 'app/header/processingJobs/JobProcessingDialog';
import ProcessingListView from 'app/header/processingList/ProcessingListView';

import SidebarNavItem from './SidebarNavItem';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1),
}));

const IconWrapper = styled('div')({
  minWidth: 50,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
});

const MenuItemContent = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  width: SIDEBAR_EXPANDED_WIDTH,
  paddingLeft: 0,
  transitionDuration: '0.3s',
  transitionProperty: 'width',
});

const MenuItemTypography = styled(Typography)({
  color: 'white',
});

const useStyles = makeStyles()({
  tooltip: {
    whiteSpace: 'pre-wrap',
  },
});

const BottomSidebar = () => {
  const classes = useStyles();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showMirageHealth, setShowMirageHealth] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isGeoActive = useSelector(selectIsGeoActive);
  const isNavExpanded = useSelector(selectIsNavExpanded);

  const renderMenuItem = ({ Icon: IconComponent, label, onClick, ...rest }) => (
    <Tooltip title={isNavExpanded ? '' : label} placement="right">
      <span>
        <MenuItemContent
          onClick={onClick}
          key={label}
          style={isNavExpanded ? {} : { width: SIDEBAR_WIDTH }}
          data-testid={`BottomSidebar-${label}`}
          {...rest}
        >
          <IconWrapper>
            <IconComponent style={{ fontSize: 22 }} />
          </IconWrapper>
          <MenuItemTypography variant="body2">{label}</MenuItemTypography>
        </MenuItemContent>
      </span>
    </Tooltip>
  );

  const renderSupportLink = () => {
    const label = 'geospatial@percipient.ai';
    const tooltip = [`For questions and comments, email ${label}.`, 'Click to copy email address.'];
    return (
      <Tooltip title={tooltip.join('\n')} placement="right" classes={{ tooltip: classes.tooltip }}>
        <span>
          <MenuItemContent
            key={label}
            style={{ cursor: 'unset', width: isNavExpanded ? 'unset' : SIDEBAR_WIDTH }}
            onClick={() =>
              navigator.clipboard
                .writeText(label)
                .then(() =>
                  renderNotification({ message: 'Support email address successfully copied' })
                )
            }
            data-testid="BottomSidebar-supportEmail"
          >
            <IconWrapper>
              <Icon iconName="email_outline" size="large" theme="white" />
            </IconWrapper>
            <MenuItemTypography variant="body2">{label}</MenuItemTypography>
          </MenuItemContent>
        </span>
      </Tooltip>
    );
  };

  const dispatch = useDispatch();

  const openJobProcessing = () => dispatch(setGlobalProcessingPanelOpen(true));

  const openProfileMenu = e => {
    setShowProfileMenu(true);
    setAnchorEl(e.currentTarget);
  };
  const closeProfileMenu = () => {
    setShowProfileMenu(false);
    setAnchorEl(null);
  };

  const openMirageHealth = e => {
    setShowMirageHealth(true);
    setAnchorEl(e.currentTarget);
  };
  const closeMirageHealth = () => {
    setShowMirageHealth(false);
    setAnchorEl(null);
  };

  return (
    <Root>
      {isGeoActive && renderSupportLink()}
      {renderMenuItem({
        Icon: JobProcessingIcon,
        label: 'Processing Panel',
        onClick: openJobProcessing,
        'data-testid': 'GlobalProcessingPanel-icon',
      })}
      {!isGeoActive &&
        renderMenuItem({
          Icon: MirageHealthIcon,
          label: 'Mirage Health',
          onClick: openMirageHealth,
        })}
      <SidebarNavItem
        path={`${SETTINGS_ROUTE}/${LOCAL_SETTINGS}`}
        Icon={() => <Icon iconName="settings" size="large" theme="white" />}
        id="Sidebar-settings"
        label="Settings"
      />
      {renderMenuItem({
        Icon: AvatarIcon,
        label: 'Profile Menu',
        onClick: openProfileMenu,
        'data-testid': 'bottomSideBar-profileMenu',
      })}
      {!isGeoActive && (
        <MirageHealthDialog
          open={showMirageHealth}
          anchorEl={anchorEl}
          onClose={closeMirageHealth}
        />
      )}
      {isGeoActive && <ProcessingListView />}
      {!isGeoActive && <JobProcessingDialog />}
      <ProfileMenu anchorEl={anchorEl} open={showProfileMenu} onClose={closeProfileMenu} />
    </Root>
  );
};

export default BottomSidebar;
