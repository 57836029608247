import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

const filteredProps = (props, excludedProps) => {
  if (!excludedProps.length) {
    return props;
  }

  const clone = cloneDeep(props);
  excludedProps.forEach(excludedProp => delete clone[excludedProp]);
  return clone;
};

const filteredKeys = (props, excludedProps) => {
  const keys = Object.keys(props);
  return !excludedProps.length ? keys : keys.filter(key => !excludedProps.includes(key));
};

export const areEqualProps = ({ deep = false, excludedProps = [] } = {}) => (
  prevProps,
  nextProps
) => {
  if (deep) {
    return isEqual(
      filteredProps(prevProps, excludedProps),
      filteredProps(nextProps, excludedProps)
    );
  }

  const prevKeys = filteredKeys(prevProps, excludedProps);
  const nextKeys = filteredKeys(nextProps, excludedProps);

  return (
    prevKeys.length === nextKeys.length &&
    prevKeys.every(propName => prevProps[propName] === nextProps[propName])
  );
};
