import { isEmpty } from 'lodash';
import { renderAlert } from 'app/redux/actions';

function isSearchQueryValid(searchQuery) {
  if (!searchQuery) return false;
  const { cameras, datasources, query, folderIds } = searchQuery;

  if (!query) {
    renderAlert('Please enter search criteria');
    return false;
  }

  if (isEmpty(cameras) && isEmpty(datasources) && !folderIds) {
    renderAlert('Please select data sources to analyze');
    return false;
  }

  return true;
}

export default isSearchQueryValid;
