import { createUseStyles } from 'react-jss';

import { LIGHT_GREY } from 'common/constants/colors';
import Button from 'common/components/base/Button';
import Typography from 'components/Typography';
import closeIcon from 'common/images/close.png';

import { exportIdentity } from 'common/api/personApi';

const useStyles = createUseStyles(theme => ({
  main: {
    height: theme.spacing(8),
    borderBottom: `solid 0.5px ${LIGHT_GREY}`,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: theme.spacing(1.5),
  },
  title: {
    width: '100%',
    justifyContent: 'center',
  },
  cancel: {
    width: theme.spacing(2),
    height: '100%',
    cursor: 'pointer',
  },
  img: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
}));

const FormHeader = ({ onClose, title, identity }) => {
  const classes = useStyles();

  const renderIdentityExport = () => (
    <Button variant="outlined" onClick={() => exportIdentity(identity)}>
      Export
    </Button>
  );

  const renderCloseIcon = () => (
    <div
      onClick={onClose}
      className={classes.cancel}
      role="button"
      tabIndex="0"
      data-testid="Identity-close-icon"
    >
      <img className={classes.img} alt="close icon" src={closeIcon} />
    </div>
  );

  return (
    <header className={classes.main}>
      <Typography className={classes.title} variant="h3">
        {title}
      </Typography>
      {identity?.id && identity?.name ? renderIdentityExport() : renderCloseIcon()}
    </header>
  );
};

export default FormHeader;
