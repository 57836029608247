import { SECONDARY, SKY_BLUE, WHITE } from 'common/constants/colors';
import Tooltip from 'common/components/base/Tooltip';

import DetectionMeta from './DetectionMeta';
import { getCanvasMargins } from './utils';

const HEIGHT_RANGE = 30;

const tooltipStyles = {
  cursor: 'pointer',
  display: 'block',
  color: WHITE,
  position: 'absolute',
  zIndex: 99,
  padding: 0,
  margin: 0,
  fontSize: 12,
};

export default class BoundingBox {
  constructor({ detection = {}, canvas = {}, scale = {}, trackletId = '', livePlayer = false }) {
    this.canvas = canvas;
    this.detection = detection;
    this.trackletId = trackletId;
    this.scale = scale;
    this.livePlayer = livePlayer;

    const { width: canvasWidth, height: canvasHeight } = this.canvas;

    const bounds = this.detection.getBounds();
    const x = Math.round(bounds.x * canvasWidth);
    const y = Math.round(bounds.y * canvasHeight);
    const width = Math.round(bounds.w * canvasWidth);
    const _height = Math.round(bounds.h * canvasHeight);

    // If height exceeds or is within HEIGHT_RANGE of canvasHeight, reduce the
    // bounding box height so the DetectionMeta does not overflow the video container
    const height =
      _height + y + HEIGHT_RANGE > canvasHeight
        ? canvasHeight - y - HEIGHT_RANGE
        : Math.round(bounds.h * canvasHeight);

    this.rect = { x, y, width, height };

    const { scaleX, scaleY, rect: scaleAdjustedRect } = this.scale;
    const canvasRect = this.canvas.getBoundingClientRect();

    const { marginTop, marginLeft } = getCanvasMargins(canvasRect, scaleAdjustedRect);

    this.boundingBox = {
      left: Math.round(marginLeft + this.rect.x / scaleX),
      top: Math.round(marginTop + this.rect.y / scaleY),
      width: Math.round(this.rect.width / scaleX),
      height: Math.round(this.rect.height / scaleY),
    };
  }

  draw(_color) {
    const canvasContext = this.canvas.getContext('2d');

    canvasContext.lineWidth = this.detection.isActionable() ? 2 : 1;
    if (this.detection.isInspectedFrame()) {
      let color = WHITE;
      if (this.trackletId && this.trackletId === this.detection._trackletId) {
        color = SKY_BLUE;
      }

      canvasContext.strokeStyle = _color || color;
      canvasContext.fillStyle = _color || color;
    } else {
      canvasContext.strokeStyle = SECONDARY;
      canvasContext.fillStyle = SECONDARY;
    }

    if (this.detection.getInterpolated()) {
      if (this.trackletId === this.detection._trackletId) {
        canvasContext.setLineDash([]);
      } else {
        canvasContext.setLineDash([2]);
      }
    } else {
      canvasContext.setLineDash([]);
    }

    canvasContext.beginPath();
    canvasContext.strokeRect(this.rect.x, this.rect.y, this.rect.width, this.rect.height);

    return null;
  }

  isPointInPath({ offsetX: x, offsetY: y }) {
    const { top, left, width, height } = this.boundingBox;
    return y <= top + height && y >= top && x <= left + width && x >= left;
  }

  showNameText = (isHovering = false) =>
    !this.detection.isInspectedFrame() || isHovering || this.isFrameInspection;

  renderTooltip = ({ onClickTooltip, onMouseOver, onMouseLeave }) => {
    const title = this.detection.isIdentity() ? 'Click to reassign identity' : 'Click to identify';
    return (
      <Tooltip
        title={title}
        key={`tooltip:${this.detection.getKey()}`}
        onOpen={() => onMouseOver(this)}
        onClose={onMouseLeave}
      >
        <div
          role="button"
          aria-label="Add Name To Identity"
          tabIndex="0"
          onClick={() => onClickTooltip(this)}
          style={{ ...this.boundingBox, ...tooltipStyles }}
          data-testid="DetectionOverlay-addNameToIdentity"
        />
      </Tooltip>
    );
  };

  renderBoundingBox(isHovering = false) {
    this.draw(isHovering && SECONDARY);
    return (
      <DetectionMeta
        key={this.detection.getKey()}
        detection={this}
        showText={this.showNameText(isHovering)}
      />
    );
  }
}
