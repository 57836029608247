import React from 'react';
import classnames from 'classnames';
import DateTime from 'dateTime';

import { withStyles } from 'tss-react-local';
import IconButton from 'common/components/base/IconButton';
import { PRIMARY_TEXT, LIGHT_BLUE, SECONDARY, PRIMARY_GREY } from 'common/constants/colors';

import { isSameDate } from 'common/helpers/dateUtils';
import { getWeekArray } from './utils';
import { MIN_DATE, MAX_DATE } from './constants';
import CalendarHeader from './CalendarHeader';

const styles = {
  transitionContainer: {
    minHeight: 184,
    marginTop: 10,
  },
  week: {
    marginTop: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  dayLabel: {
    fontSize: 10,
    color: PRIMARY_TEXT,
    fontWeight: 400,
  },
  day: {
    width: 28,
    height: 28,
    padding: 0,
  },
  dayWrapper: {
    position: 'relative',
  },
  today: {
    color: SECONDARY,
  },
  selected: {
    borderRadius: '50%',
    background: LIGHT_BLUE,
    color: 'white',
  },
  nonCurrentMonthDay: {
    color: PRIMARY_GREY,
    opacity: 0.3,
    background: 'white !important',
    pointerEvents: 'none',
  },
};

class Calendar extends React.Component {
  static defaultProps = {
    date: new Date(),
    minDate: MIN_DATE,
    maxDate: MAX_DATE,
    disablePast: false,
    disableFuture: false,
    allowKeyboardControl: true,
  };

  state = {
    slideDirection: 'left',
    currentMonth: DateTime.fromJSDateWithTZ(this.props.date).startOf('month'),
  };

  componentDidUpdate(prevProps) {
    if (this.props.date && !isSameDate(prevProps.date, this.props.date, 'month')) {
      this.onDateUpdated();
    }
  }

  onDateSelect = day => {
    this.props.onChange(day);
  };

  onDateUpdated = () =>
    this.setState({
      currentMonth: DateTime.fromJSDateWithTZ(this.props.date).startOf('month'),
    });

  handleChangeMonth = (month, slideDirection) => {
    const nextMonth = month.endOf('month');
    if (this.props.onMonthChange) {
      this.props.onMonthChange(nextMonth);
    }

    this.setState({ currentMonth: nextMonth, slideDirection });
  };

  shouldDisablePrevMonth = () => {
    const { disablePast, minDate } = this.props;
    const minDateTime = DateTime.fromJSDateWithTZ(minDate);
    const now = DateTime.fromJSDateWithTZ();
    const firstEnabledMonth = (disablePast && now > minDateTime ? now : minDateTime).startOf(
      'month'
    );

    return !(firstEnabledMonth < this.state.currentMonth);
  };

  shouldDisableNextMonth = () => {
    const { disableFuture, maxDate } = this.props;
    const maxDateTime = DateTime.fromJSDateWithTZ(maxDate);
    const now = DateTime.fromJSDateWithTZ();
    const lastEnabledMonth = (disableFuture && now < maxDateTime ? now : maxDateTime).startOf(
      'month'
    );

    return !(lastEnabledMonth > this.state.currentMonth);
  };

  renderWeeks = () => {
    const { classes } = this.props;
    const weeks = getWeekArray(this.state.currentMonth);

    return weeks.map((week, idx) => (
      /* eslint-disable react/no-array-index-key */
      <div key={idx} className={classes.week}>
        {this.renderDays(week)}
      </div>
      /* eslint-enable */
    ));
  };

  renderDays = week => {
    const currentMonthNumber = this.state.currentMonth.month;
    return week.map((day, idx) => {
      const isDayInCurrentMonth = day.month === currentMonthNumber;

      return this.props.renderDay
        ? this.props.renderDay(day, isDayInCurrentMonth, idx)
        : this.renderDay(day, isDayInCurrentMonth, idx);
    });
  };

  renderDay = (date, isDayInCurrentMonth, idx) => {
    const { classes } = this.props;
    const isSelected = isSameDate(date, this.props.date);
    const isToday = isSameDate(date, new Date());

    const wrapperClassName = classnames({
      [classes.selected]: isSelected,
    });

    const dayClassName = classnames(classes.day, {
      [classes.nonCurrentMonthDay]: !isDayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !isDayInCurrentMonth,
    });

    const labelClass = classnames(classes.dayLabel, {
      [classes.today]: isToday,
    });

    return (
      <div className={wrapperClassName} key={idx}>
        <IconButton
          customClasses={{ root: dayClassName, label: labelClass }}
          onClick={() => this.onDateSelect(date)}
          data-testid={`Calendar-day-${isDayInCurrentMonth ? 'current-month' : 'other-month'}`}
        >
          {date.day}
        </IconButton>
      </div>
    );
  };

  render() {
    const { currentMonth, slideDirection } = this.state;

    return (
      <React.Fragment>
        <CalendarHeader
          slideDirection={slideDirection}
          currentMonth={currentMonth}
          onMonthChange={this.handleChangeMonth}
          leftArrowIcon={this.props.leftArrowIcon}
          rightArrowIcon={this.props.rightArrowIcon}
          disablePrevMonth={this.shouldDisablePrevMonth()}
          disableNextMonth={this.shouldDisableNextMonth()}
        />

        <div>{this.renderWeeks()}</div>
      </React.Fragment>
    );
  }
}

export default withStyles(Calendar, styles);
