import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="96" height="44" viewBox="0 0 120 55">
    <g fill="none" fillRule="evenodd">
      <path d="M-4-37h128V91H-4z" />
      <g fill="#659ADE">
        <path d="M22.8 36.864c4.402 0 7.866 3.587 7.866 7.837s-3.6 7.838-7.866 7.838c-4.402 0-7.866-3.587-7.866-7.838 0-4.25 3.6-7.837 7.866-7.837zm0-2.125a9.965 9.965 0 0 0-9.998 9.962c0 5.582 4.532 9.963 9.998 9.963a9.965 9.965 0 0 0 9.998-9.963c.136-5.445-4.397-9.962-9.998-9.962zM90.802 36.864c4.401 0 7.865 3.587 7.865 7.837 0 4.386-3.6 7.838-7.865 7.838-4.402 0-7.866-3.587-7.866-7.838-.136-4.25 3.464-7.837 7.866-7.837zm0-2.125a9.965 9.965 0 0 0-9.999 9.962c0 5.582 4.533 9.963 9.999 9.963A9.965 9.965 0 0 0 100.8 44.7c0-5.445-4.533-9.962-9.998-9.962z" />
        <path d="M120 31.815c0-3.718-2.002-5.315-3.731-5.978-1.735-.663-4.932-1.593-8.133-2.26-3.202-.664-3.998-1.463-5.466-2.924-1.467-1.462-8.4-8.767-13.866-13.55C81.6.728 63.066.201 63.066.201H7.2C2.132.2 2.132 4.184 2.132 4.184S0 39.924 0 41.782c0 1.859 1.467 1.728 1.467 1.728L7.2 44.705l3.998.8v-.8c0-6.51 5.334-11.69 11.733-11.69 6.398 0 11.732 5.315 11.732 11.69v.8h44.4v-.8c0-6.51 5.335-11.69 11.733-11.69 6.399 0 11.733 5.315 11.733 11.69v.8h14.934c1.468 0 1.332-1.327 1.332-1.327.004-.005 1.204-8.64 1.204-12.363H120zM16.134 27.967a2.524 2.524 0 0 1-2.531-2.523c0-1.326 1.2-2.522 2.531-2.522a2.524 2.524 0 0 1 2.531 2.522 2.524 2.524 0 0 1-2.531 2.523zm77.068-7.973c-3.066-.266-10.8-.93-16.134-1.327l-1.599-7.304c-.131-.663-.398-1.065-.665-1.065l-.136-.005c-.802 0-.666.93-.666 1.462l-.267 6.642c-1.467-.131-2.4-.131-2.667-.131-1.07 0-.933-1.196-.933-1.196V6.44c0-.663-.131-1.065 1.069-1.065s9.998 1.728 13.598 3.984c2.798 1.728 6.933 7.44 8.667 9.832.666.27.263.934-.267.803zm14.667 6.375l8.133 2.391s-4.669 3.587-8.133-2.39z" />
      </g>
    </g>
  </svg>
);
