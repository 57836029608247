const defaults = {
  backwardImages: [],
  fwdImages: [],
  loadingBackImages: false,
  loadingFwdImages: false,
  hasError: false,
  detectionImages: [],
  relatedImages: [],
  sortBy: 'internal_date',
};

export default function bottomBarReducer(state = defaults, action) {
  switch (action.type) {
    case 'ANALYSIS/SET_HAS_ERROR': {
      return {
        ...state,
        hasError: action.payload,
      };
    }
    case 'ANALYSIS/SET_RELATED_FWD_IMAGES': {
      return {
        ...state,
        fwdImages: action.payload,
      };
    }
    case 'ANALYSIS/SET_RELATED_BACK_IMAGES': {
      return {
        ...state,
        backwardImages: action.payload,
      };
    }
    case 'ANALYSIS/SET_RELATED_BACK_IMAGES_LOADING': {
      return {
        ...state,
        loadingBackImages: action.payload,
      };
    }

    case 'ANALYSIS/SET_RELATED_FWD_IMAGES_LOADING': {
      return {
        ...state,
        loadingFwdImages: action.payload,
      };
    }
    case 'ANALYSIS/SET_RELATED_DETECTION_IMAGES': {
      return {
        ...state,
        detectionImages: action.payload,
      };
    }
    case 'ANALYSIS/SET_RELATED_SORT_BY': {
      return {
        ...state,
        sortBy: action.payload,
      };
    }
    case 'ANALYSIS/SET_RELATED_IMAGES': {
      return {
        ...state,
        relatedImages: action.payload,
      };
    }
    default:
      return state;
  }
}
