import React from 'react';
import { withStyles } from 'tss-react-local';
import MiragePopoverMenu from 'common/components/miragePopoverMenu/MiragePopoverMenu';
import Icon from 'common/components/base/Icon';
import { Example } from '../helpers';

const styles = {
  main: {
    minWidth: 324,
  },
};

class MiragePopoverMenuExample extends React.Component {
  state = { open: false, anchorEl: null };

  getConfig = () => [
    {
      label: 'Systems',
      items: [
        {
          key: 0,
          label: this.renderLabel({
            attribute: 'Disk Usage',
            value: '256MB / 512GB',
          }),
          icon: this.renderIcon(),
        },
        {
          key: 1,
          label: this.renderLabel({ attribute: 'CPU', value: '76%' }),
          icon: this.renderIcon(),
        },
        {
          key: 2,
          label: this.renderLabel({
            attribute: 'GPU Utilization',
            value: '76%',
          }),
          icon: this.renderIcon(),
        },
        {
          key: 3,
          label: this.renderLabel({ attribute: 'Memory', value: '76%' }),
          icon: this.renderIcon(),
        },
      ],
    },
    {
      label: 'Services',
      items: [
        {
          key: 4,
          label: this.renderLabel({
            attribute: 'Macula',
            value: 'ON',
          }),
          icon: this.renderIcon(),
        },
        {
          key: 5,
          label: this.renderLabel({ attribute: 'VidProc', value: 'OFF' }),
          icon: this.renderIcon('red'),
        },
      ],
    },
  ];

  open = e => this.setState({ anchorEl: e.currentTarget, open: true });

  close = () => this.setState({ anchorEl: null, open: false });

  renderIcon = (backgroundColor = 'green') => (
    <div
      style={{
        width: 12,
        height: 12,
        backgroundColor,
        borderRadius: '50%',
      }}
    />
  );

  renderLabel = ({ attribute, value }) => (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        justifyContent: 'space-between',
        fontWiehgt: 300,
      }}
    >
      <span>{attribute}</span>
      <span>{value}</span>
    </div>
  );

  renderHeader = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>Mirage™ Health Check</span>
      <span>View Details</span>
    </div>
  );

  render() {
    return (
      <div>
        <Example label="Filter List">
          <Icon iconName="check_circle" color="green" onClick={this.open} />
          <MiragePopoverMenu
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            header={this.renderHeader()}
            config={this.getConfig()}
            onClose={this.close}
            classes={{ paper: this.props.classes.main }}
          />
        </Example>
      </div>
    );
  }
}

export default withStyles(MiragePopoverMenuExample, styles);
