import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="86" height="55" viewBox="0 0 108 69">
    <path
      fill="#6599DE"
      fillRule="evenodd"
      d="M1.3 48.208L0 48.19l.208 1.286c.4 2.472 1.046 4.522 1.926 6.084 1.666 2.96 3.868 3.583 5.42 3.583.922 0 1.913-.214 2.941-.632 2.61-1.063 5.322-2.425 8.285-4.163l1.947-1.145-2.104-.82C8.876 48.592 2.9 48.23 1.301 48.207zm84.462 4.038l1.51.84-1.408 1.001c-.15.107-14.866 10.57-17.491 11.806-.226.106-.517.264-.854.447-1.827.994-4.887 2.66-9.206 2.66-4.021 0-8.238-1.479-12.532-4.394-24.897-16.903-40.779-17.39-42.507-17.39h-.162L0 47.323l2.352-2.02c.205-.175 5.237-4.357 25.98-10.196l.214-.06.221.026c3.645.448 36.161 4.697 55.539 16.38.294.183.576.328.875.481.187.103.38.2.58.311v.001zM28.497 33.835l-1.224-.14.277-1.187c.068-.284 1.685-7.027 6.156-14.351 2.636-4.32 5.725-7.934 9.172-10.744C47.22 3.878 52.143 1.619 57.509.699 60.235.233 63.047 0 65.866 0c7.04 0 14.052 1.458 20.285 4.217l.418.187.162.428c.43 1.14 1.224 4.742-.64 13.515-1.966 9.274-5.52 22.332-6.953 27.515l-.342 1.233-1.177-.534c-20.682-9.413-48.842-12.698-49.122-12.727zm79.437 3.348c-.297 4.967-1.607 10.198-3.891 15.549l-.056.136-.095.115c-.146.183-1.525 1.779-5.106 1.779-3.749 0-8.532-1.72-14.216-5.112a70.307 70.307 0 0 0-4.132-2.264l-.802-.405.237-.85c1.504-5.38 5.214-18.915 7.24-28.407.986-4.635 1.378-8.432 1.167-11.287l-.155-2.056 1.814 1.034c7.868 4.486 13.461 10.88 16.185 18.49 1.482 4.141 2.09 8.61 1.81 13.28v-.002z"
    />
  </svg>
);
