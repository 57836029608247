import isEmpty from 'lodash/isEmpty';

/* Next two functions are kind of hacky since we don't have proper backend support */

const SAVE_AS_VIDEO = 'true';

export function getSceneSavedAsVideo(scene) {
  return {
    ...scene,
    videoUrl: SAVE_AS_VIDEO,
  };
}

export function isSceneSavedAsVideo(scene) {
  return scene && scene.videoUrl === SAVE_AS_VIDEO;
}

/* End of hacky functions */

export function getArchivedSourceName(scene) {
  if (isEmpty(scene.archivedSource)) {
    return '';
  }

  return scene.archivedSource.title || '';
}

export const calcSceneIndex = index => index + 1;
