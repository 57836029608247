import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import MuiTooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';

const useStyles = createUseStyles({
  tooltip: {
    fontSize: '0.5rem',
    'word-break': 'break-word',
  },
});

/**
  @prop {Boolean} showTooltip -- will remove the tooltip completely if false
*/
const Tooltip = ({ children, showTooltip = true, customClasses = {}, ...rest }) => {
  const classes = useStyles();

  return showTooltip ? (
    <MuiTooltip
      enterDelay={500}
      TransitionComponent={Fade}
      classes={{ tooltip: classnames(classes.tooltip, customClasses.tooltip), ...customClasses }}
      {...rest}
    >
      {children}
    </MuiTooltip>
  ) : (
    children
  );
};

Tooltip.defaultProps = {
  title: '',
};

export default Tooltip;
