import { OBJECT } from 'common/constants/app';
import store from 'redux/store';

export default function findObjectByName(name) {
  const {
    common: {
      models: { [OBJECT]: objectModels },
    },
  } = store.getState();

  /*
    if the name starts with a colon, it is a detection object,
    we will need to strip the colon as well as the color from the name
  */
  if (name && name.startsWith(':')) {
    name = name.substring(1);
  }

  const parts = name.split('.');

  name = parts[0];
  const color = parts[1];

  const { id } =
    Object.values(objectModels).find(item => item.name?.toLowerCase() === name.toLowerCase()) || {};

  if (!id && id !== 0) return null;

  const result = { id, name: objectModels[id].name, object: true };

  if (color) result.color = color;

  return result;
}
