import NanoEvents from 'nanoevents';

export const DRAW_DETECTION = 'draw_detection';
export const SHORTCUT_ANIMATION = 'shortcut_animation';
export const VIDEOPLAYER_PROGRESS = 'videoplayer_progress';
export const DISABLE_VIDEO_SHORTCUTS = 'disable_video_shortcuts';
export const ENABLE_VIDEO_SHORTCUTS = 'enable_video_shortcuts';
export const VIDEOPLAYER_SEEK = 'videoplayer_seek';

/* when collapsable drawer is closed on analysis results in geospatial */
export const GEO_MAP_LEFT_PANEL_TOGGLE = 'geo_map_left_panel_close';

export const RESET_TIMELINE_ZOOM = 'reset_timeline_zoom';

const emitter = new NanoEvents();

export default emitter;
