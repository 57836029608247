import { createContext, useContext, useReducer, useState } from 'react';
import { isEqual } from 'lodash';

import { useSelector } from 'react-redux';
import { selectMinLingerMillis } from 'plan/redux/selectors';

import { add, areZonesComplete, complete, remove, update, insert } from './zoneUtils';

const ZonesContext = createContext();

// Note on initialState.hovering – Currently these are only updated when hovering over a
// point, but these can be feasibly re-used to handle hovering over line segments for adding
// new mid-points within an existing zone.

const initialState = {
  zones: [],
  active: 0,
  hovering: {
    zoneIndex: null,
    pointIndex: null,
    inactivePoint: null,
  },
};

const reducer = (prevState, action) => {
  switch (action.type) {
    case 'add': {
      const { zones: prev, active } = prevState;
      const { point } = action;

      const activeZone = prev[active];

      // If new point is same as the previous point in active zone, return previous state.
      if (activeZone && isEqual(point, activeZone[activeZone.length - 1])) {
        return prevState;
      }

      return {
        ...prevState,
        zones: add(prev, active, point),
      };
    }
    case 'complete': {
      const { zones: prev, active, hovering } = prevState;
      return { zones: complete(prev, active), active: active + 1, hovering };
    }
    case 'insert': {
      const { zones: prev } = prevState;
      const { zoneIndex, pointIndex, point } = action;

      return {
        ...prevState,
        zones: insert(prev, [zoneIndex, pointIndex], point),
      };
    }
    case 'remove': {
      const { zones: prev, active: prevActive } = prevState;
      const { zoneIndex, pointIndex, skipCompleteCheck } = action;
      const nextZones = remove(prev, [zoneIndex, pointIndex], skipCompleteCheck);

      return {
        ...prevState,
        active: nextZones.length < prevActive ? nextZones.length : prevActive,
        zones: nextZones,
        hovering: initialState.hovering,
      };
    }
    case 'update': {
      const { zones: prev } = prevState;
      const { zoneIndex, pointIndex, point } = action;

      return {
        ...prevState,
        zones: update(prev, [zoneIndex, pointIndex], point),
      };
    }
    case 'cancel': {
      const { zones: prev, active } = prevState;
      const { retainIndex } = action;
      const newActiveIndex = retainIndex ? active : active - 1;
      return {
        ...prevState,
        active: newActiveIndex,
        zones: prev.slice(0, prev.length - 1),
        hovering: initialState.hovering,
      };
    }
    case 'clear': {
      return {
        ...prevState,
        zones: initialState.zones,
        active: initialState.active,
      };
    }
    case 'pointMouseOver': {
      const { zoneIndex, pointIndex, inactivePoint } = action;

      return {
        ...prevState,
        hovering: {
          zoneIndex,
          pointIndex,
          inactivePoint,
        },
      };
    }
    case 'pointMouseOut': {
      return {
        ...prevState,
        hovering: initialState.hovering,
      };
    }
    default: {
      throw new Error();
    }
  }
};

const ZonesProvider = ({ defaultValue, ...rest }) => {
  const [{ zones, active, hovering }, dispatch] = useReducer(
    (prev, action) => {
      const next = reducer(prev, action);
      return next;
    },
    defaultValue
      ? { ...initialState, active: defaultValue.length, zones: defaultValue }
      : initialState
  );

  const storedMinLingerMillis = useSelector(selectMinLingerMillis);
  const defaultMinLingerSeconds =
    (storedMinLingerMillis === undefined ? 0 : storedMinLingerMillis) / 1000;

  const [unsafeMinLingerSeconds, setMinLingerSeconds] = useState(defaultMinLingerSeconds);

  return (
    <ZonesContext.Provider
      value={{
        zones,
        active,
        hovering,
        areZonesComplete: areZonesComplete(zones),
        add: args => dispatch({ type: 'add', ...args }),
        complete: () => dispatch({ type: 'complete' }),
        insert: args => dispatch({ type: 'insert', ...args }),
        remove: args => dispatch({ type: 'remove', ...args }),
        update: args => dispatch({ type: 'update', ...args }),
        cancel: args => dispatch({ type: 'cancel', ...args }),
        clear: () => dispatch({ type: 'clear' }),
        onPointMouseOver: args => dispatch({ type: 'pointMouseOver', ...args }),
        onPointMouseOut: () => dispatch({ type: 'pointMouseOut' }),
        unsafeMinLingerSeconds,
        setMinLingerSeconds,
      }}
      {...rest}
    />
  );
};

const useZones = () => useContext(ZonesContext);

export { ZonesProvider, useZones };
