import { isEmpty } from 'lodash';

import { checkInternet } from 'common/helpers/networkUtils';
import { getMirageHealth, pingMirageBackend } from 'common/api/mirageHealthApi';
import { getMaculas } from 'common/api/cameraApi';
import { normalizeList } from 'common/helpers/helperFunctions';
import { setMaculaModels } from 'common/redux/models/actions';

const setHealth = payload => ({ type: 'HEALTH/SET_HEALTH', payload });

const setResponseTime = payload => ({ type: 'HEALTH/SET_RESPONSE_TIME', payload });

const setLiveProcStatus = payload => ({ type: 'HEALTH/SET_IS_LIVE_PROC_ONLINE', payload });

export const updateMirageHealth = () =>
  function updateMirageHealthThunk(dispatch) {
    checkInternet();

    getMirageHealth()
      .then(health => dispatch(setHealth(health)))
      .catch(err => {
        setHealth({});
        return Promise.reject(err);
      });

    pingMirageBackend().then(ping => {
      dispatch(setResponseTime(ping.responseTime));
    });

    getMaculas().then(maculas => {
      // On a multi-GPU machine please check if any camera is online that mean LiveProc is online.
      const isLiveProcOnline = !isEmpty(maculas) && maculas.some(x => x.online);

      dispatch(setLiveProcStatus(isLiveProcOnline));
      dispatch(setMaculaModels(normalizeList(maculas)));
    });
  };
