import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobalJobs } from 'app/redux/selectors';
import CancelJob from 'common/components/popups/dialogs/CancelJob';
import GlobalProcessingPanel from './globalProcessingPanel/GlobalProcessingPanel';

const ProcessingPanelDialog = ({ openMenu, closeMenu }) => {
  const [showCancelJob, setShowCancelJob] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const jobs = useSelector(selectGlobalJobs);

  const openCancelJob = data => {
    setShowCancelJob(true);
    setDataSource(data);
  };

  const closeCancelJob = () => {
    setShowCancelJob(false);
    setDataSource(null);
  };

  return (
    <React.Fragment>
      <CancelJob datasource={dataSource} open={showCancelJob} handleClose={closeCancelJob} />

      <GlobalProcessingPanel
        openMenu={openMenu}
        jobProcessingData={jobs}
        openCancelJob={openCancelJob}
        closeMenu={closeMenu}
      />
    </React.Fragment>
  );
};

export default ProcessingPanelDialog;
