import React, { useState } from 'react';
import { withStyles } from 'tss-react-local';
import Grid from '@material-ui/core/Grid';
import Button from 'common/components/base/Button';
import Tooltip from 'common/components/base/Tooltip';
import Checkbox from 'common/components/base/Checkbox';

import { Example } from '../helpers';

const styles = {
  main: {
    '& button': {
      margin: '0 8px',
    },
  },
  wrapper: {
    marginTop: 20,
    width: 500,
  },
};

const useAdvancingEnum = () => {
  const [status, setStatus] = useState('idle');
  const [title, setTitle] = useState('confirm?');

  const cycle = () => {
    setTitle(prev =>
      prev === 'confirm?'
        ? 'confirming...'
        : prev === 'confirming...'
        ? 'remove?'
        : prev === 'remove?'
        ? 'removing...'
        : prev === 'removing...'
        ? 'confirm?'
        : 'uh oh'
    );
  };

  const advance = () => {
    if (status === 'pending') return;
    cycle();
    setStatus('pending');
    setTimeout(() => {
      cycle();
      setStatus('success');
    }, 1000);
  };

  return { title, isPending: status === 'pending', advance };
};

const AdvancingTitleCheckbox = () => {
  const { title, isPending, advance } = useAdvancingEnum();
  const [checked, setChecked] = useState(false);
  return (
    <Tooltip title={title}>
      <span>
        <Checkbox
          checked={checked}
          disabled={isPending}
          onChange={() => {
            setChecked(prev => !prev);
            advance();
          }}
        />
      </span>
    </Tooltip>
  );
};

const arr = [1, 2, 3, 4, 5];
const ChangingTitleExample = () => (
  <Example label="Changing Title Example">
    {arr.map(v => (
      <div key={v}>
        <AdvancingTitleCheckbox />
      </div>
    ))}
  </Example>
);

const TooltipExample = ({ classes }) => (
  <>
    <ChangingTitleExample />
    <div className={classes.main}>
      <Example label="Default Tooltip" className={classes.wrapper}>
        <Grid container justify="center">
          <Grid item>
            <Tooltip title="Add" placement="top-start">
              <Button>TOP-START</Button>
            </Tooltip>
            <Tooltip title="Add" placement="top">
              <Button>TOP</Button>
            </Tooltip>
            <Tooltip title="Add" placement="top-end">
              <Button>TOP-END</Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={6}>
            <Tooltip title="Add" placement="left-start">
              <Button>LEFT-START</Button>
            </Tooltip>
            <br />
            <Tooltip title="Add" placement="left">
              <Button>LEFT</Button>
            </Tooltip>
            <br />
            <Tooltip title="Add" placement="left-end">
              <Button>LEFT-END</Button>
            </Tooltip>
          </Grid>
          <Grid item container xs={6} alignItems="flex-end" direction="column">
            <Grid item>
              <Tooltip title="Add" placement="right-start">
                <Button>RIGHT-START</Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Add" placement="right">
                <Button>RIGHT</Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Add" placement="right-end">
                <Button>RIGHT-END</Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item>
            <Tooltip title="Add" placement="bottom-start">
              <Button>BOTTOM-START</Button>
            </Tooltip>
            <Tooltip title="Add" placement="bottom">
              <Button>BOTTOM</Button>
            </Tooltip>
            <Tooltip title="Add" placement="bottom-end">
              <Button>BOTTOM-END</Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Example>
    </div>
  </>
);

export default withStyles(TooltipExample, styles);
