/* eslint-disable global-require */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */

export default [
  {
    label: 'Button',
    url: 'button',
    Component: require('./button/ButtonExample').default,
    fileText: require('!raw-loader!./button/ButtonExample'),
  },
  {
    label: 'Theme',
    url: 'theme',
    Component: require('./theme/ThemeExample').default,
    fileText: require('!raw-loader!./theme/ThemeExample'),
  },
  {
    label: 'Canvas Sketch Tool',
    url: 'canvas',
    Component: require('./canvas/Canvas').default,
    fileText: require('!raw-loader!./canvas/Canvas'),
  },
  {
    label: 'Checkbox',
    url: 'checkbox',
    Component: require('./checkbox/CheckboxExample').default,
    fileText: require('!raw-loader!./checkbox/CheckboxExample'),
  },
  {
    label: 'Chip',
    url: 'chip',
    Component: require('./chip/ChipExample').default,
    fileText: require('!raw-loader!./chip/ChipExample'),
  },
  {
    label: 'Color Picker',
    url: 'color-picker',
    Component: require('./colorPicker/ColorPickerExample').default,
    fileText: require('!raw-loader!./colorPicker/ColorPickerExample'),
  },
  {
    label: 'Colors',
    url: 'colors',
    Component: require('./colors/ColorsExample').default,
    fileText: require('!raw-loader!./colors/ColorsExample'),
  },
  {
    label: 'Dialog',
    url: 'dialog',
    Component: require('./dialog/DialogExample').default,
    fileText: require('!raw-loader!./dialog/DialogExample'),
  },
  {
    label: 'Drawer',
    url: 'drawer',
    Component: require('./drawer/DrawerExample').default,
    fileText: require('!raw-loader!./drawer/DrawerExample'),
  },
  {
    label: 'Expansion Panel',
    url: 'expansion-panel',
    Component: require('./expansionPanel/ExpansionPanelExample').default,
    fileText: require('!raw-loader!./expansionPanel/ExpansionPanelExample'),
  },
  {
    label: 'Icon',
    url: 'icon',
    Component: require('./icon/IconExample').default,
    fileText: require('!raw-loader!./icon/IconExample'),
  },
  {
    label: 'IdentityTile',
    url: 'identity-tile',
    Component: require('./IdentityTileExample').default,
    fileText: require('!raw-loader!./IdentityTileExample'),
  },
  {
    label: 'Interactive Image',
    url: 'interactive-image',
    Component: require('./interactiveImage/InteractiveImageExample').default,
    fileText: require('!raw-loader!./interactiveImage/InteractiveImageExample'),
  },
  {
    label: 'Loaders',
    url: 'loaders',
    Component: require('./loader/LoaderExample').default,
    fileText: require('!raw-loader!./loader/LoaderExample'),
  },
  {
    label: 'Map basic',
    url: 'map',
    Component: require('./map/MapExample').default,
    fileText: require('!raw-loader!./map/MapExample'),
  },
  {
    label: 'Map with lines',
    url: 'map-with-lines',
    Component: require('./map/MapWithLinesExample').default,
    fileText: require('!raw-loader!./map/MapWithLinesExample'),
  },
  {
    label: 'Map with drawing',
    url: 'map-with-drawing',
    Component: require('./map/MapDrawingExample').default,
    fileText: require('!raw-loader!./map/MapDrawingExample'),
  },
  {
    label: 'Minimize',
    url: 'minimize',
    Component: require('./minimize/MinimizeExample').default,
    fileText: require('!raw-loader!./minimize/MinimizeExample'),
  },
  {
    label: 'Menu',
    url: 'menu',
    Component: require('./menu/MenuExample').default,
    fileText: require('!raw-loader!./menu/MenuExample'),
  },
  {
    label: 'Mirage Popover Menu',
    url: 'mirage-popover-menu',
    Component: require('./miragePopoverMenu/MiragePopoverMenuExample').default,
    fileText: require('!raw-loader!./miragePopoverMenu/MiragePopoverMenuExample'),
  },
  {
    label: 'Notifications',
    url: 'notifications',
    Component: require('./NotificationsExample').default,
    fileText: require('!raw-loader!./NotificationsExample'),
  },
  {
    label: 'Overflow Text',
    url: 'overflowText',
    Component: require('./overflowText/OverflowTextExample').default,
    fileText: require('!raw-loader!./overflowText/OverflowTextExample'),
  },
  {
    label: 'Pickers',
    url: 'pickers',
    Component: require('./pickers/PickersExample').default,
    fileText: require('!raw-loader!./pickers/PickersExample'),
  },
  {
    label: 'Select',
    url: 'select',
    Component: require('./select/SelectExample').default,
    fileText: require('!raw-loader!./select/SelectExample'),
  },
  {
    label: 'Split Panel',
    url: 'split-panel',
    Component: require('./splitPanel/SplitPanelExample').default,
    fileText: require('!raw-loader!./splitPanel/SplitPanelExample'),
  },
  {
    label: 'Shadows',
    url: 'shadows',
    Component: require('./shadows/ShadowsExample').default,
    fileText: require('!raw-loader!./shadows/ShadowsExample'),
  },
  {
    label: 'Table',
    url: 'table',
    Component: require('./table/TableExample').default,
    fileText: require('!raw-loader!./table/TableExample'),
  },
  {
    label: 'Tabs',
    url: 'tabs',
    Component: require('./tabs/TabsExample').default,
    fileText: require('!raw-loader!./tabs/TabsExample'),
  },
  {
    label: 'Text Input',
    url: 'text-input',
    Component: require('./textInput/TextInputExample').default,
    fileText: require('!raw-loader!./textInput/TextInputExample'),
  },
  {
    label: 'Timeline',
    url: 'timeline',
    Component: require('./timeline/TimelineExample').default,
    fileText: require('!raw-loader!./timeline/TimelineExample'),
  },
  {
    label: 'Tooltip',
    url: 'tooltip',
    Component: require('./tooltip/TooltipExample').default,
    fileText: require('!raw-loader!./tooltip/TooltipExample'),
  },
  {
    label: 'Ripple',
    url: 'ripple',
    Component: require('./ripple/RippleExample').default,
    fileText: require('!raw-loader!./ripple/RippleExample'),
  },
  {
    label: 'Typography',
    url: 'typography',
    Component: require('./typography/TypographyExample').default,
    fileText: require('!raw-loader!./typography/TypographyExample'),
  },
  {
    label: 'User Select',
    url: 'userSelect',
    Component: require('./userSelect/UserSelectExample').default,
    fileText: require('!raw-loader!./userSelect/UserSelectExample'),
  },
  {
    label: 'Video Player',
    url: 'video-player',
    Component: require('./videoPlayer/VideoPlayerExample').default,
  },
  {
    label: 'Video Summary Grid',
    url: 'video-summary-grid',
    Component: require('./videoSummaryGrid/VideoSummaryGridExample').default,
  },
];
