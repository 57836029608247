import {
  FOLDER,
  GEOFENCE,
  GEOIMAGE,
  GEODATASET,
  GEOSPATIAL,
  OBJECT,
  ROOT_GEOSPATIAL_KEY,
  ROOT_OBJECT_KEY,
} from 'common/constants/app';

export const OBJECT_KEY = OBJECT.toUpperCase();
export const GEOFENCE_KEY = GEOFENCE.toUpperCase();
export const GEOIMAGE_KEY = GEOIMAGE.toUpperCase();
export const GEODATASET_KEY = GEODATASET.toUpperCase();
export const FOLDER_KEY = FOLDER.toUpperCase();

export const SHOW_ALL_GEODATASETS = 'datasets';
export const SHOW_ALL_GEOFENCES = 'fences';
export const SHOW_ALL = 'all';

export const SORT_ASC = 'ASC';

export const CHANNELS = {
  1: 'Panchromatic',
  3: 'Pansharpened',
};

export const ROOT_DIRECTORIES = {
  /* Default to all geofences when going to geofence list */
  [GEOSPATIAL]: ROOT_GEOSPATIAL_KEY,
  [OBJECT]: ROOT_OBJECT_KEY,

  [ROOT_OBJECT_KEY]: OBJECT,
  [ROOT_GEOSPATIAL_KEY]: GEOSPATIAL,
};

export const ADHOC_IMPROC_ROOT_FOLDER = '/';
export const S3_FILE = 'file';
export const S3_FOLDER = 'folder';

function sortByName({ dataRoot: dataRootA, name: nameA }, { dataRoot: dataRootB, name: nameB }) {
  return dataRootA ? dataRootA.localeCompare(dataRootB) : nameA.localeCompare(nameB);
}

function sortByCreated({ created: createdA = 0 }, { created: createdB = 0 }) {
  return new Date(createdA).getTime() - new Date(createdB).getTime();
}

function sortByChronology({ datetime: dateA = 0 }, { datetime: dateB = 0 }) {
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

function sortByLocation({ geoAddress: addressA }, { geoAddress: addressB }) {
  if (!addressA) return 1;
  if (!addressB) return -1;
  return addressA.localeCompare(addressB);
}

function sortByProcessedDate({ processedDate: dateA }, { processedDate: dateB }) {
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

function sortByLastModified({ updated: dateA }, { updated: dateB }) {
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

function sortByID({ id: idA }, { id: idB }) {
  return idA - idB;
}

function sortByGeotiff({ images: imagesA }, { images: imagesB }) {
  if (!imagesA) return 1;
  if (!imagesB) return -1;
  return imagesA.length - imagesB.length;
}

export const CREATED = 'created';
export const CHRONOLOGICAL = 'chronological';
export const LAST_MODIFIED = 'last_modified';
export const LOCATION = 'location';
export const NAME = 'name';
export const NONE = 'none';
export const PATH = 'path';
const PROCESSED_DATE = 'processed_date';
const ID = 'id';
export const GEOTIFF = 'geotiff';

const sortFunctions = {
  [NONE]: () => true,
  [NAME]: sortByName,
  [CREATED]: sortByCreated,
  [CHRONOLOGICAL]: sortByChronology,
  [LOCATION]: sortByLocation,
  [PROCESSED_DATE]: sortByProcessedDate,
  [ID]: sortByID,
  [GEOTIFF]: sortByGeotiff,
  [LAST_MODIFIED]: sortByLastModified,
  updated: sortByLastModified,
  images: sortByGeotiff,
};

export function sortLibraryGeoData(data, { sort, sortDirection }) {
  // Default to sortByName if the sort function does not exist
  const sortFunction = sortFunctions[sort] || sortByName;
  const sortedData = data.sort(sortFunction);

  return sortDirection === SORT_ASC ? sortedData : sortedData.reverse();
}
