import { makeStyles } from 'tss-react-local';
import { PRIMARY_GREY } from 'common/constants/colors';

import Icon from './Icon';
import Tooltip from './Tooltip';

const useStyles = makeStyles()((_theme, props) => ({
  icon: {
    color: PRIMARY_GREY,
    fontSize: props.size,
    opacity: 0.8,
  },
}));

const Info = props => {
  const { title, className, ...rest } = props;
  const { classes, cx } = useStyles({ size: rest.size });

  return (
    <Tooltip title={title}>
      <Icon iconName="info" className={cx(classes.icon, className)} {...rest} />
    </Tooltip>
  );
};

Info.defaultProps = {
  title: '',
  className: '',
};

export default Info;
