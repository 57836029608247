import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Popover from '@material-ui/core/Popover';

import { TEST_SELECT_DATE_RANGE } from '__testSetup__/constants';

import { ERROR } from 'common/constants/colors';
import Chip from 'common/components/base/Chip';
import DateTimeRangePicker from './DateTimeRangePicker';
import DateTimeRangePickerInput from './DateTimeRangePickerInput';

const styles = {
  chip: {
    overflow: 'hidden',
  },
  chipError: {
    overflow: 'hidden',
    border: `1px solid ${ERROR}`,
  },
  deleteIcon: {
    color: ERROR,
  },
  label: {
    paddingLeft: 0,
  },
};

class DateTimeRangePickerChip extends React.Component {
  state = {
    expanded: false,
    open: false,
    anchorEl: null,
    hasInvalidInput: false,
  };

  componentDidUpdate(prevProps) {
    //  If we've gotten a different date range, reset the date picker
    if (prevProps.dateRange !== this.props.dateRange) {
      this.shrink();
    }
  }

  open = e => this.setState({ open: true, anchorEl: e.currentTarget });

  close = () => this.setState({ open: false, anchorEl: null });

  expand = () => this.setState({ expanded: true });

  shrink = () => this.setState({ expanded: false });

  onChange = ({ startDate, endDate }) => {
    this.props.onChange({ startDate, endDate });
  };

  setHasInvalidInput = hasInvalidInput => this.setState({ hasInvalidInput });

  onClear = () => {
    if (this.props.startDate || this.props.endDate) {
      this.props.onChange({ startDate: null, endDate: null });
    }

    if (this.state.hasInvalidInput) {
      this.setHasInvalidInput(false);
    }

    this.shrink();
  };

  onKeyDown = e => {
    if (e.key === 'Escape') {
      this.shrink();
    }
  };

  render() {
    const { disabled, startDate, endDate, dateRange = [], className, classes, size } = this.props;
    const { hasInvalidInput } = this.state;
    const showDateRange = !!(startDate || endDate || this.state.expanded);
    const deleteIconClassName = hasInvalidInput ? classes.deleteIcon : '';
    const showDeleteIcon = !disabled && showDateRange && !this.props.hideDeleteIcon;

    return (
      <React.Fragment>
        <Chip
          className={classnames(className, {
            [classes.chip]: !hasInvalidInput,
            [classes.chipError]: hasInvalidInput,
          })}
          clickable={false}
          customClasses={{ label: classes.label, deleteIcon: deleteIconClassName }}
          disabled={disabled}
          label={
            <DateTimeRangePickerInput
              TextInputProps={{ InputProps: { disableUnderline: true } }}
              onChange={this.onChange}
              startDate={startDate}
              endDate={endDate}
              expanded={showDateRange}
              dateRange={dateRange}
              minTimespan={this.props.minTimespan}
              showPresent={this.props.showPresent}
              showSeconds={this.props.showSeconds}
              setHasInvalidInput={this.setHasInvalidInput}
            />
          }
          onKeyDown={this.onKeyDown}
          onClick={showDateRange ? undefined : this.expand}
          onDelete={showDeleteIcon ? this.onClear : undefined}
          color="default"
          variant="outlined"
          size={size}
          {...TEST_SELECT_DATE_RANGE}
        />
        <Popover
          open={this.state.open}
          onClose={this.close}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <DateTimeRangePicker
            startDate={startDate || dateRange[0]}
            endDate={endDate || dateRange[1]}
            onChange={this.onChange}
            onClose={this.close}
            dateRange={dateRange}
          />
        </Popover>
      </React.Fragment>
    );
  }
}

export default injectSheet(styles)(DateTimeRangePickerChip);
