import axios from 'axios.js';

import { ANALYTICS_BACKEND } from 'common/constants/urls';
import { parseContentRange } from './utils';

export function getUserInfo(user) {
  return axios.get(`${ANALYTICS_BACKEND}/user/${user}/`).then(({ data }) => data);
}

export function getUsers() {
  return axios.get(`${ANALYTICS_BACKEND}/user/`).then(({ data }) => data);
}

export function getUsersQuery(params = {}) {
  return axios.get(`${ANALYTICS_BACKEND}/user/`, { params }).then(({ data, headers }) => {
    const range = parseContentRange(headers);
    return { data, range };
  });
}

export function getCurrentUser() {
  return axios.get(`${ANALYTICS_BACKEND}/user/me/`).then(({ data }) => data);
}

export function getUserProfile() {
  return axios
    .get(`${ANALYTICS_BACKEND}/user/me/profile/`)
    .then(({ data: { profile } = {} } = {}) => profile);
}

export function postUserProfile(params = {}) {
  return axios
    .post(`${ANALYTICS_BACKEND}/user/me/profile/`, params)
    .then(({ data: { profile } = {} } = {}) => profile);
}

export function getUserAuthType() {
  return axios.get(`${ANALYTICS_BACKEND}/current_user_auth_mode/`).then(({ data }) => data);
}

export function postPassword(userId, { oldPassword, newPassword }) {
  return axios
    .post(`${ANALYTICS_BACKEND}/user/${userId}/password/`, { oldPassword, newPassword })
    .then(({ data }) => data);
}
