import React from 'react';
import injectSheet from 'react-jss';
import throttle from 'lodash/throttle';

import Tooltip from 'common/components/base/Tooltip';

import { isCameraLive } from 'library/redux/camera/utils';
import { PRIMARY_GREY, LIVE, BRIGHT_RED } from 'common/constants/colors';

import VideoCameraSetupIcon from 'common/icons/ic-camera-setup';

const TRIANGLE_HEIGHT = 5;
const BORDER_WIDTH = 1;

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: props => (props.useIcon ? 'flex-end' : 'center'),
    justifyContent: 'center',
    position: 'absolute',
  },
  main: {
    display: 'flex',
    height: props => (props.useIcon ? props.size : 0.66 * props.size),
    width: props => (props.useIcon ? props.size : 0.66 * props.size),
    position: 'absolute',
    borderRadius: props => (props.useIcon ? 2 : '50%'),
    border: `${BORDER_WIDTH}px solid white`,
    alignItems: 'center',
    justifyContent: 'center',
    bottom: props => (props.useIcon ? TRIANGLE_HEIGHT : 'unset'),
    zIndex: 3,
  },
  boxShadow: {
    height: props => (props.useIcon ? props.size : 0.66 * props.size),
    width: props => (props.useIcon ? props.size : 0.66 * props.size),
    position: 'absolute',
    borderRadius: props => (props.useIcon ? 2 : '50%'),
    border: `${BORDER_WIDTH}px solid white`,
    background: 'white',
    bottom: props => (props.useIcon ? TRIANGLE_HEIGHT : 'unset'),
    boxShadow: '0 0 2px 2px rgba(0,0,0,0.2)',
    zIndex: 1,
  },
  triangle: {
    height: TRIANGLE_HEIGHT,
    width: TRIANGLE_HEIGHT,
    transform: 'rotate(45deg)',
    boxShadow: '0 0 2px 2px rgba(0,0,0,0.2)',
    position: 'absolute',
    background: 'white',
    bottom: TRIANGLE_HEIGHT / 2,
    zIndex: 2,
  },
};

function getBackgroundColor(camera, useIcon) {
  if (useIcon) {
    return BRIGHT_RED;
  }
  if (isCameraLive(camera)) {
    return LIVE;
  }

  return PRIMARY_GREY;
}

class VideoCameraMarker extends React.Component {
  static defaultProps = {
    useIcon: false,
    camera: {},
    size: 20,
  };

  constructor(props) {
    super(props);
    this.handleMove = throttle(this.handleMove, 50);
  }

  onMouseDown = e => {
    e.stopPropagation();

    if (this.props.disabled) return;

    document.addEventListener('mousemove', this.handleMove);
    document.addEventListener('mouseup', this.handleMouseUp, { once: true });
  };

  handleMouseUp = e => {
    document.removeEventListener('mousemove', this.handleMove);

    const { pageX, pageY } = e;
    if (this.props.onDragFinish) {
      this.props.onDragFinish({ x: pageX, y: pageY });
    }
  };

  handleMove = e => {
    const { pageX, pageY } = e;
    if (this.props.onDrag) this.props.onDrag({ x: pageX, y: pageY });
  };

  render() {
    const { style, classes, camera, useIcon } = this.props;
    const background = getBackgroundColor(camera, useIcon);
    return (
      <Tooltip title={camera.displayName}>
        <div
          role="none"
          className={classes.wrapper}
          onMouseDown={this.onMouseDown}
          style={{ zIndex: useIcon ? 10 : 1, ...style }}
          data-testid="VideoCameraMarker"
        >
          {useIcon ? (
            <VideoCameraSetupIcon />
          ) : (
            <>
              <div className={classes.main} style={{ background }} />
              <div className={classes.boxShadow} />
            </>
          )}
        </div>
      </Tooltip>
    );
  }
}

export default injectSheet(styles)(VideoCameraMarker);
