import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

import Tooltip from 'common/components/base/Tooltip';
import Delete from 'common/icons/deleteicon';

const useStyles = createUseStyles(theme => ({
  main: {
    position: 'relative',
    height: '100%',
    flexShrink: 0,
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      '& $isHovered': {
        display: 'flex',
      },
    },
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    maxWidth: theme.spacing(30),
    objectFit: 'cover',
  },
  highlight: {
    opacity: ({ highlight }) => (highlight ? '1' : '0.2'),
  },
  isHovered: {
    position: 'absolute',
    width: theme.spacing(2),
    height: theme.spacing(2),
    top: theme.spacing(0.5),
    right: 0,
    cursor: 'pointer',
    display: 'block',
    pointerEvents: ({ canDelete }) => (canDelete ? '' : 'none'),
    opacity: ({ canDelete }) => (canDelete ? 1 : 0.5),
  },
  delete: {
    display: 'none',
  },
}));

const IdentityThumbnail = React.forwardRef(
  (
    {
      thumbnailUrl,
      onDelete,
      isProcessing,
      canRemove = false,
      highlight = false,
      isLoading = false,
    },
    ref
  ) => {
    const canDelete = !isProcessing && canRemove;
    const classes = useStyles({ canDelete, highlight, isLoading });
    const title = 'An Identity must have at least one image.';

    const renderDelete = key => (
      <Tooltip placement="top" showTooltip={!canDelete} title={title}>
        <div className={classnames(classes.delete, classes.isHovered)}>
          <Delete onClick={() => onDelete(key)} />
        </div>
      </Tooltip>
    );

    return (
      <div
        ref={ref}
        className={classnames(classes.main, {
          [classes.highlight]: isProcessing,
        })}
        data-testid="Identity-thumbnail"
      >
        {onDelete && !isLoading && renderDelete(thumbnailUrl)}
        <img className={classes.thumbnail} alt="Preview" src={thumbnailUrl} />
      </div>
    );
  }
);

export default IdentityThumbnail;
