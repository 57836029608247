import { Info } from 'luxon';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from 'tss-react-local';
import IconButton from 'common/components/base/IconButton';
import Icon from 'common/components/base/Icon';

const useStyles = makeStyles()({
  switchHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 3,
    marginBottom: 6,

    /*
        Required to fix the problems that the styles.css cause for the button components here.
        We should remove these styles once we get rid of the default styles in styles.css
      */
    '& button': {
      height: 'unset',
    },
  },
  daysHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 12,
    marginBottom: 6,
  },
  dayLabel: {
    width: 28,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.38)',
  },
});

const CalendarHeader = props => {
  const {
    currentMonth,
    onMonthChange,
    leftArrowIcon,
    rightArrowIcon,
    disablePrevMonth,
    disableNextMonth,
  } = props;
  const { classes } = useStyles();
  const selectNextMonth = () => onMonthChange(currentMonth.plus({ months: 1 }), 'left');
  const selectPreviousMonth = () => onMonthChange(currentMonth.minus({ months: 1 }), 'right');

  return (
    <div>
      <div className={classes.switchHeader} data-testid="CalendarHeader">
        <IconButton
          disabled={disablePrevMonth}
          onClick={selectPreviousMonth}
          data-testid="CalendarHeader-left-arrow"
        >
          {leftArrowIcon}
        </IconButton>

        <Typography align="center" variant="body1" data-testid="CalendarHeader-month">
          {currentMonth.toLocaleString({ month: 'short', year: 'numeric' })}
        </Typography>

        <IconButton
          disabled={disableNextMonth}
          onClick={selectNextMonth}
          data-testid="CalendarHeader-right-arrow"
        >
          {rightArrowIcon}
        </IconButton>
      </div>

      <div className={classes.daysHeader}>
        {Info.weekdays('narrow').map((day, index) => (
          <Typography
            key={index} // eslint-disable-line react/no-array-index-key
            variant="caption"
            classes={{ root: classes.dayLabel }}
          >
            {day}
          </Typography>
        ))}
      </div>
    </div>
  );
};

CalendarHeader.defaultProps = {
  leftArrowIcon: <Icon iconName="chevron_left" theme="grey" />,
  rightArrowIcon: <Icon iconName="chevron_right" theme="grey" />,
  disablePrevMonth: false,
  disableNextMonth: false,
};

export default CalendarHeader;
