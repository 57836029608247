import Typography from 'components/Typography';
import MirageHeading from 'common/components/base/MirageHeading';

import { Example } from '../helpers';

const TypographyExample = () => (
  <div>
    <Example label="Displays">
      <Typography variant="h1">h1. Header 1</Typography>
      <Typography variant="h2">h2. Header 2</Typography>
      <Typography variant="h3">h3. Header 3</Typography>
      <Typography variant="h4">h4. Header 4</Typography>
      <Typography variant="h5">h5. Header 5</Typography>
      <Typography variant="h6" addMargin>
        h6. Header 6
      </Typography>

      <Typography variant="subtitle1">subtitle1. Subtitle 1</Typography>
      <Typography variant="subtitle2">subtitle2. Subtitle 2</Typography>
      <Typography variant="subtitle3" addMargin>
        subtitle3. Subtitle 3
      </Typography>
      <Typography variant="body1">body1. Body 1</Typography>
      <Typography variant="body2">body2. Body 2</Typography>
      <Typography variant="body3">body3. Body 3</Typography>
      <Typography variant="body4" addMargin>
        body4. Body 4
      </Typography>
      <Typography display="block" variant="overline">
        overline
      </Typography>
      <Typography display="block" variant="caption">
        caption
      </Typography>
      <Typography display="block" variant="button">
        button
      </Typography>
    </Example>
    <Example label="Mirage Heading" style={{ marginTop: 24 }}>
      <MirageHeading
        top="PLAN YOUR MISSION"
        bottom="RECOGNIZE • CHARACTERIZE • CONTEXTUALIZE"
        style={{ marginBottom: 24 }}
      />
      <MirageHeading top="Viewport Center" bottom="Santa Clara, California" switchHeaders />
    </Example>
  </div>
);

export default TypographyExample;
