export const initialState = {
  health: {
    servicesInfo: {
      mintHeartbeat: {
        flag: 1, // Default Mint service flag to true so that warning message does not flash on login
      },
    },
  },
  isLiveProcOnline: true,
  responseTime: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'HEALTH/SET_HEALTH': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'HEALTH/SET_IS_LIVE_PROC_ONLINE': {
      return {
        ...state,
        isLiveProcOnline: action.payload,
      };
    }
    case 'HEALTH/SET_RESPONSE_TIME': {
      return {
        ...state,
        responseTime: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
