import React from 'react';
import { SECONDARY } from 'common/constants/colors';

export default ({ style: { color = SECONDARY } = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="13" viewBox="0 0 22 16">
    <path
      fill={color}
      fillRule="nonzero"
      d="M11 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 8a5 5 0 1 1 0-10 5 5 0 0 1 0 10zM11 .5C6 .5 1.73 3.61 0 8c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5C20.27 3.61 16 .5 11 .5z"
    />
  </svg>
);
