import get from 'lodash/get';
import { createSelector } from 'reselect';
import DateTime from 'dateTime';
import { chunk, isEmpty } from 'lodash';
import orderBy from 'lodash/orderBy';
import flatten from 'lodash/flatten';
import {
  DEFAULT_CONFIDENCE_INTERVAL,
  NUM_RELATED_SIGHTINGS_COLS,
  RELATIONSHIP_STATUS,
  SORT_BY_TIME,
  TIME_STAMP_FORMAT,
} from 'analysis/siftView/constants';
import { getDateInMs } from 'common/helpers/datasourceUtils';
import {
  buildSiftTimelineBounds,
  getTrackletInFilter,
  searchlightEnabledForType,
} from 'analysis/siftView/utils';
import { parseDurationToHHMMSS } from 'common/helpers/dateUtils';
import { getExcludedVideos } from 'plan/redux/utils';
import { UNKNOWN_PERSON } from 'common/constants/app';
import { FACE } from 'common/constants/objects';

export const selectSearchRequest = state => get(state, 'siftView.searchRequest');
export const selectQuery = state => get(state, 'siftView.searchRequest.query', '');
export const selectTracklets = state => get(state, `siftView.tracklets`, []);
export const selectPagination = state => get(state, `siftView.pagination`);

export const selectOrderedTracklets = state => get(state, `siftView.orderedTracklets`);

export const selectUnconfirmedTracklets = createSelector(selectTracklets, tracklets =>
  tracklets.map(sightings => sightings.filter(x => !x.bestSighting))
);

export const selectTrackletsById = createSelector(selectTracklets, tracklets =>
  flatten(tracklets).reduce((acc, tracklet) => {
    acc[tracklet.id] = tracklet;
    return acc;
  }, {})
);

export const selectStartDateFilter = state => get(state, `siftView.filters.startDate`);

export const selectEndDateFilter = state => get(state, `siftView.filters.endDate`);

export const selectSiftFilter = state => get(state, `siftView.filters`);

export const selectTimelineBounds = state => get(state, `siftView.timelineBounds`);

export const selectHasDateFilter = createSelector(
  selectStartDateFilter,
  selectEndDateFilter,
  (startDate, endDate) => !!startDate && !!endDate
);

export const selectGroupedTracklets = state => get(state, `siftView.groupedTracklets`);

export const selectSelectedSiftDataSources = state =>
  get(state, `siftView.selectedSiftDataSources`);

export const selectUnconfirmedOrderedTracklets = createSelector(selectOrderedTracklets, tracklets =>
  tracklets.filter(x => x.relationship !== RELATIONSHIP_STATUS.CONFIRMED)
);

export const selectConfirmedOrderedTracklets = createSelector(selectOrderedTracklets, tracklets =>
  tracklets.filter(x => x.relationship === RELATIONSHIP_STATUS.CONFIRMED)
);

export const selectTotalNumOrderedTracklets = createSelector(
  selectOrderedTracklets,
  tracklets => tracklets.length
);

export const selectMatchConfidence = state => get(state, `siftView.matchConfidence`);
export const selectGroupedMatchConfidence = state => get(state, `siftView.groupedMatchConfidence`);

export const selectSelectedTracklet = state => get(state, `siftView.selectedTracklet`, null);

export const selectMinConfidence = createSelector(
  selectMatchConfidence,
  confidence => confidence[0] / 100
);

export const selectMaxConfidence = createSelector(
  selectMatchConfidence,
  confidence => confidence[1] / 100
);

export const selectGroupedMinConfidence = createSelector(
  selectGroupedMatchConfidence,
  confidence => confidence[0] / 100
);

export const selectGroupedMaxConfidence = createSelector(
  selectGroupedMatchConfidence,
  confidence => confidence[1] / 100
);

export const selectMinMaxConfidence = createSelector(
  selectGroupedMinConfidence,
  selectGroupedMaxConfidence,
  selectMinConfidence,
  selectMaxConfidence,
  (_, confidence) => confidence,
  (groupedMinConfidence, groupedMaxConfidence, minConfidence, maxConfidence, confidence) => {
    if (confidence.isGroupedSightingsView && !confidence.isGroupedSightingsLoadAll) {
      return DEFAULT_CONFIDENCE_INTERVAL;
    }

    if (confidence.isGroupedSightingsView && confidence.isGroupedSightingsLoadAll) {
      return [groupedMinConfidence, groupedMaxConfidence];
    }

    if (confidence.isRelatedSightingsView) {
      return [minConfidence, maxConfidence];
    }

    return DEFAULT_CONFIDENCE_INTERVAL;
  }
);

export const selectUnconfirmedOrderedByConfidence = createSelector(
  selectUnconfirmedOrderedTracklets,
  tracklets => orderBy(tracklets, ['confidence'], ['desc'])
);

export const selectConfirmedOrderedByConfidence = createSelector(
  selectConfirmedOrderedTracklets,
  tracklets => orderBy(tracklets, ['confidence'], ['desc'])
);

export const selectAllOrderedByConfidence = createSelector(selectOrderedTracklets, tracklets =>
  orderBy(tracklets, ['confidence'], ['desc'])
);

export const selectIsSortedByTime = state => get(state, `siftView.isSortedByTime`);

export const selectSelectedConfirmedPerson = state =>
  get(state, `siftView.selectedConfirmedPerson`);

export const selectSelectedSiftDataSource = state => get(state, `siftView.selectedSiftDataSource`);

export const selectShouldClearTracklets = state => get(state, `siftView.shouldClearTracklets`);

export const selectVideoDuration = state => get(state, `siftView.videoDuration`);

export const selectRightPanelOption = state => get(state, `siftView.rightPanelOption`);

export const selectHoveredPerson = state => get(state, `siftView.selectedHoveredPerson`);

export const selectHoveredTrackletId = state => get(state, `siftView.hoveredTrackletId`);

export const selectHoveredTracklet = createSelector(
  selectHoveredTrackletId,
  selectTrackletsById,
  (hoveredTrackletId, tracklets) => tracklets[hoveredTrackletId]
);

export const selectChosenTracklets = state => get(state, `siftView.selectedTracklets`);

export const selectConfirmedPersons = state => get(state, `siftView.confirmedFaces`);

export const selectLoadingConfirmedFaces = state => get(state, `siftView.loadingConfirmedFaces`);

export const selectLoadingAllSightings = state => get(state, `siftView.loadingAllSightings`);

export const selectUnconfirmedChecked = state => get(state, `siftView.unconfirmedChecked`);

export const selectConfirmedChecked = state => get(state, `siftView.confirmedChecked`);

export const selectGroupedIdentityCreated = state => get(state, `siftView.groupedIdentityCreated`);

export const selectLoadingRelatedSightings = state =>
  get(state, `siftView.loadingRelatedSightings`);

export const selectSeekTime = state => get(state, `siftView.seekTime`);

export const selectIsLoadingDataSources = state => get(state, `siftView.loadingDataSources`);

export const selectIsDataSourceDoesNotExist = state =>
  get(state, `siftView.dataSourceDoesNotExist`);

export const selectShouldRefreshFrameDetections = state =>
  get(state, `siftView.shouldRefreshFrameDetections`);

export const selectCurrentDsStartAndEndTime = createSelector(
  selectSelectedSiftDataSource,
  datasource => {
    let dsEnd = 0;
    let dsStart = 0;
    if (isEmpty(datasource)) {
      return { dsStart, dsEnd };
    }

    dsStart = new Date(datasource.date).getTime();
    dsEnd = dsStart + datasource.duration;

    return { dsStart, dsEnd };
  }
);

export const selectDatasourcesMinStartTime = createSelector(
  selectSelectedSiftDataSources,
  datasources => {
    const datasourcesStartTime = datasources
      .filter(datasource => datasource.date)
      .map(datasource => new Date(datasource.date).getTime());
    return datasourcesStartTime.length
      ? DateTime.fromJSDateWithTZ(Math.min(...datasourcesStartTime))
      : null;
  }
);

export const selectDatasourcesMaxEndTime = createSelector(
  selectSelectedSiftDataSources,
  dataSources => {
    const dataSourcesEndTime = dataSources
      .filter(dataSource => dataSource.date)
      .map(dataSource => {
        const dsStart = new Date(dataSource.date).getTime();
        return dsStart + dataSource.duration;
      });
    return dataSourcesEndTime.length
      ? DateTime.fromJSDateWithTZ(Math.max(...dataSourcesEndTime))
      : null;
  }
);

export const selectDsStartAndEndTimeOnFilter = createSelector(
  selectHasDateFilter,
  selectStartDateFilter,
  selectEndDateFilter,
  selectCurrentDsStartAndEndTime,
  (hasDateFilter, filterStartTime, filterEndTime, dsStartAndEndTime) => {
    const { dsStart, dsEnd } = dsStartAndEndTime;
    const dsStartTimeOnFilter =
      hasDateFilter && filterStartTime >= dsStart && filterStartTime <= dsEnd
        ? filterStartTime
        : dsStart;

    const dsEndTimeOnFilter =
      hasDateFilter && filterEndTime >= dsStart && filterEndTime <= dsEnd ? filterEndTime : dsEnd;

    return { dsStartTimeOnFilter, dsEndTimeOnFilter };
  }
);

export const selectCurrentDsDuration = createSelector(
  selectSelectedSiftDataSource,
  datasource => datasource?.duration
);

export const selectCanTrackletBeSelected = createSelector(
  selectChosenTracklets,
  (_, detectionId) => detectionId,
  (tracklets, detectionId) => (!isEmpty(tracklets) ? tracklets[detectionId] : false)
);
export const makeSelectCanTrackletBeSelected = () => selectCanTrackletBeSelected;

export const selectSelectedTrackletCount = createSelector(
  selectChosenTracklets,
  tracklets => Object.keys(tracklets).length
);

export const selectSelectedTrackletIds = createSelector(selectChosenTracklets, tracklets =>
  Object.keys(tracklets)
);

function getTrackletClusters(tracklets) {
  return flatten(tracklets).reduce((acc, tracklet) => {
    const clusterId = tracklet?.clusterId;
    if (!acc[clusterId]) {
      acc[clusterId] = { id: clusterId, tracklets: [tracklet.id] };
    } else {
      acc[clusterId].tracklets.push(tracklet.id);
    }
    return acc;
  }, {});
}

export const selectPeopleById = createSelector(selectUnconfirmedTracklets, tracklets =>
  getTrackletClusters(tracklets)
);

export const selectTracklet = createSelector(
  selectTrackletsById,
  (_, trackletId) => trackletId,
  (tracklets, trackletId) => tracklets[trackletId]
);

export const selectTrackletClusterId = createSelector(
  selectTracklet,
  tracklet => tracklet?.clusterId
);

export const selectHoveredClusterId = createSelector(
  selectHoveredTracklet,
  hoveredTracklet => hoveredTracklet?.clusterId
);

export const selectPersonTracklets = createSelector(
  selectPeopleById,
  selectTrackletClusterId,
  (peoples, clusterId) => peoples[clusterId]?.tracklets
);

const selectCanHoveredTrackletBeSelected = createSelector(
  selectHoveredClusterId,
  selectTrackletClusterId,
  (hoveredPersonId, trackletPersonId) => hoveredPersonId === trackletPersonId
);
export const makeSelectCanHoveredTrackletBeSelected = () => selectCanHoveredTrackletBeSelected;

const selectTrackletCount = createSelector(
  selectPeopleById,
  (_, trackletId) => trackletId,
  (peoples, trackletId) => peoples[trackletId]?.tracklets?.length || 0
);
export const makeSelectTrackletCount = () => selectTrackletCount;

export const selectSightingRowTimeStamp = createSelector(
  selectSelectedSiftDataSource,
  (_, { sightingTime }) => sightingTime,
  (selectedDataSource, sightingTime) => {
    if (isEmpty(selectedDataSource)) {
      return DateTime.fromJSDateWithTZ(sightingTime).toFormat(TIME_STAMP_FORMAT);
    }
    if (getDateInMs(selectedDataSource) === 0) {
      return parseDurationToHHMMSS(sightingTime, { showHours: true });
    }

    const { displayTimezoneName } = selectedDataSource;
    return selectedDataSource.camera
      ? DateTime.fromJSDateWithTZ(sightingTime, {
          timeZone: displayTimezoneName,
        }).toFormat(TIME_STAMP_FORMAT)
      : DateTime.fromJSDateWithTZ(sightingTime + getDateInMs(selectedDataSource), {
          timeZone: displayTimezoneName,
        }).toFormat(TIME_STAMP_FORMAT);
  }
);

export const selectUnconfirmedOrderedTrackletsSelected = createSelector(
  selectUnconfirmedOrderedTracklets,
  selectChosenTracklets,
  (unconfirmedOrderedTracklets, selectedTracklets) =>
    unconfirmedOrderedTracklets
      .filter(tracklet => selectedTracklets.hasOwnProperty(tracklet.id))
      .map(tracklet => tracklet.detectionId)
);

const selectIsTrackletHovered = createSelector(
  selectHoveredTrackletId,
  (_, trackletId) => trackletId,
  (hoveredTrackletId, trackletId) => hoveredTrackletId === trackletId
);
export const makeSelectIsTrackletHovered = () => selectIsTrackletHovered;

export const selectIsGroupedAllTrackletWithinConfidence = createSelector(
  selectGroupedMinConfidence,
  selectGroupedMaxConfidence,
  (_, tracklet) => tracklet,
  (groupedMinConfidence, groupedMaxConfidence, tracklet) =>
    tracklet.confidence >= groupedMinConfidence && tracklet.confidence <= groupedMaxConfidence
);

export const selectIsRelatedTrackletWithinConfidence = createSelector(
  selectMinConfidence,
  selectMaxConfidence,
  (_, tracklet) => tracklet,
  (minConfidence, maxConfidence, tracklet) =>
    tracklet.confidence >= minConfidence && tracklet.confidence <= maxConfidence
);

export const selectOrderedImages = createSelector(
  selectUnconfirmedOrderedTracklets,
  selectConfirmedOrderedTracklets,
  selectUnconfirmedOrderedByConfidence,
  selectConfirmedOrderedByConfidence,
  selectOrderedTracklets,
  selectAllOrderedByConfidence,
  selectIsSortedByTime,
  selectUnconfirmedChecked,
  selectConfirmedChecked,
  (
    unconfirmedOrderedTracklets,
    confirmedOrderedTracklets,
    unconfirmedOrderedByConfidence,
    confirmedOrderedByConfidence,
    orderedTracklets,
    orderedTrackletsByConfidence,
    isSortedByTime,
    unconfirmedChecked,
    confirmedChecked
  ) => {
    const sortByTime = isSortedByTime === SORT_BY_TIME;
    if (unconfirmedChecked && confirmedChecked) {
      return sortByTime ? orderedTracklets : orderedTrackletsByConfidence;
    }
    if (unconfirmedChecked && !confirmedChecked) {
      return sortByTime ? unconfirmedOrderedTracklets : unconfirmedOrderedByConfidence;
    }
    if (!unconfirmedChecked && confirmedChecked) {
      return sortByTime ? confirmedOrderedTracklets : confirmedOrderedByConfidence;
    }
    return [];
  }
);

export const selectEmptyTrackletRowsCount = createSelector(
  selectOrderedImages,
  (_, confidence) => confidence,
  (tracklets, confidence) =>
    chunk(tracklets, NUM_RELATED_SIGHTINGS_COLS).reduce((acc, sightings) => {
      const isWithinConfidence = sightings.some(
        tracklet =>
          tracklet.confidence >= confidence.minConfidence &&
          tracklet.confidence <= confidence.maxConfidence
      );

      if (!isWithinConfidence) {
        acc++;
      }

      return acc;
    }, 0)
);

export const selectGroupSightingsToCreate = createSelector(
  selectGroupedTracklets,
  selectTrackletsById,
  (groupingTracklets, tracklets) =>
    Object.keys(groupingTracklets).flatMap(id => tracklets[id] || [])
);

export const selectGroupedSightings = createSelector(
  selectTrackletsById,
  selectGroupedTracklets,
  (tracklets, groupingTracklets) => {
    const filteredTracklets = [];
    Object.keys(groupingTracklets).forEach(id => {
      const tracklet = tracklets[id];
      if (tracklet) {
        filteredTracklets.push(tracklet);
      }
    });
    return chunk(filteredTracklets, NUM_RELATED_SIGHTINGS_COLS);
  }
);

const getRelatedOrGroupedSightings = (orderedImages, minConfidence, maxConfidence) =>
  chunk(orderedImages, NUM_RELATED_SIGHTINGS_COLS).reduce((acc, sightings) => {
    const isWithinConfidence = sightings.some(
      tracklet => tracklet.confidence >= minConfidence && tracklet.confidence <= maxConfidence
    );

    if (isWithinConfidence) {
      acc.push(sightings);
    }

    return acc;
  }, []);

export const selectAllGroupedSightings = createSelector(
  selectOrderedImages,
  selectGroupedMinConfidence,
  selectGroupedMaxConfidence,
  (orderedImages, minConfidence, maxConfidence) =>
    getRelatedOrGroupedSightings(orderedImages, minConfidence, maxConfidence)
);

export const selectRelatedSightings = createSelector(
  selectOrderedImages,
  selectMinConfidence,
  selectMaxConfidence,
  (orderedImages, minConfidence, maxConfidence) =>
    getRelatedOrGroupedSightings(orderedImages, minConfidence, maxConfidence)
);

export const selectConfirmedTrackletsCount = createSelector(
  selectConfirmedOrderedTracklets,
  tracklets => tracklets.length
);

export const selectPersonToCreate = createSelector(
  selectChosenTracklets,
  selectTrackletsById,
  (chosenTracklets, tracklets) => Object.keys(chosenTracklets).map(id => tracklets[id])
);

export const selectShouldRenderLoadingIcon = createSelector(
  selectLoadingAllSightings,
  selectLoadingRelatedSightings,
  (isLoadingAllSightings, isLoadingRelatedSightings) =>
    isLoadingAllSightings || isLoadingRelatedSightings
);

export const selectGroupingTracklets = createSelector(
  selectGroupSightingsToCreate,
  selectOrderedTracklets,
  (_, groupingType) => groupingType,
  (chosenTracklets, orderedTracklets, groupingType) => {
    if (
      (orderedTracklets.length === 0 && chosenTracklets.length === 0) ||
      !groupingType.isGroupedSightingsView
    ) {
      return;
    }

    let tracklet = {};
    let segmentTracklets = [];

    if (chosenTracklets.length > 0 && !groupingType.isGroupedSightingsLoadAll) {
      tracklet = chosenTracklets[0];
      segmentTracklets = chosenTracklets;
    } else if (orderedTracklets.length > 0) {
      if (groupingType.isGroupedSightingsLoadAll) {
        tracklet = orderedTracklets[0];
        segmentTracklets = orderedTracklets;
      } else {
        return;
      }
    }

    return {
      name: UNKNOWN_PERSON,
      groupId: tracklet?.id,
      thumbURL: tracklet?.thumbUrl,
      segments: segmentTracklets,
    };
  }
);

export const selectRowNotMatchingTimeFilter = createSelector(
  selectHasDateFilter,
  selectSelectedSiftDataSource,
  selectSiftFilter,
  (_, tracklets) => tracklets,
  (hasDateFilter, datasource, filteredTime, tracklets) => {
    const includedTracklets = getTrackletInFilter(tracklets, datasource, filteredTime);
    if (hasDateFilter) return tracklets.every(tracklet => !includedTracklets[tracklet.id]);
    return false;
  }
);

export const selectTrackletNotMatchingFilter = createSelector(
  selectHasDateFilter,
  selectSelectedSiftDataSource,
  selectSiftFilter,
  (_, tracklet) => tracklet,
  (hasDateFilter, datasource, filteredTime, tracklet) => {
    const includedTracklet = getTrackletInFilter([tracklet], datasource, filteredTime);
    if (hasDateFilter) return !includedTracklet[tracklet?.id];
    return false;
  }
);

export const selectGetExcludedDatasourcesFromFilter = createSelector(
  selectSelectedSiftDataSources,
  selectSiftFilter,
  (datasources, dateFilter) => {
    const { excludedVideos } = getExcludedVideos(datasources, dateFilter);

    return excludedVideos;
  }
);

export const selectSeekStartTimeOnFilter = createSelector(
  selectSelectedSiftDataSource,
  selectStartDateFilter,
  selectCurrentDsDuration,
  (datasource, startTimeFilter, duration) => {
    const dsStart = datasource ? Math.floor(new Date(datasource.date).getTime()) / 1000 : null;
    const dsEnd = datasource ? dsStart + duration : null;
    const startTimeFilterMinusMs = Math.floor(startTimeFilter / 1000);

    if (startTimeFilterMinusMs - dsStart < 0 || dsEnd - startTimeFilterMinusMs < 0) return 0;

    return dsStart ? startTimeFilterMinusMs - dsStart : startTimeFilterMinusMs;
  }
);

export const selectTimelineBoundsOnFilter = createSelector(
  selectSelectedSiftDataSource,
  selectDsStartAndEndTimeOnFilter,
  selectCurrentDsDuration,
  (dataSource, dsStartAndEndTimeOnFilter, duration) => {
    const dsStart = dataSource ? new Date(dataSource.date).getTime() : null;
    const { dsStartTimeOnFilter, dsEndTimeOnFilter } = dsStartAndEndTimeOnFilter;
    const startTime = dsStartTimeOnFilter - dsStart;
    const endTime = dsEndTimeOnFilter - dsStart;
    const { start, end } = buildSiftTimelineBounds(startTime, endTime, duration);

    return { start, end };
  }
);

export const makeSelectTimeStamp = createSelector(
  selectSelectedSiftDataSource,
  (_, seconds) => seconds,
  (dataSource, seconds) => Math.round((dataSource?.startEpochMs ?? 0) + seconds * 1000)
);

export const selectGetAllExcludedDatasources = createSelector(
  selectSelectedSiftDataSources,
  selectGetExcludedDatasourcesFromFilter,
  (allDataSources, excludedDatasourcesFromFilter) => {
    const dataSourcesWithoutTracklets = allDataSources.filter(
      dataSource => !dataSource.trackletSummary
    );

    const dataSourcesNotMatchingFilter = allDataSources.filter(
      dataSource => excludedDatasourcesFromFilter?.[dataSource.id]
    );

    const dataSourcesWithoutDetections = allDataSources.filter(
      dataSource => !searchlightEnabledForType(dataSource, FACE)
    );

    return {
      datasourcesWithoutTracklets: dataSourcesWithoutTracklets,
      datasourcesNotMatchingFilter: dataSourcesNotMatchingFilter,
      datasourcesWithoutDetections: dataSourcesWithoutDetections,
    };
  }
);
