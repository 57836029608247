import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 88" color={color} {...rest}>
    <g>
      <path d="M38.371.422c-5.512 0-10.039 4.504-10.039 10.016v3.23h8.75v-3.23h.02c0-.695.57-1.285 1.261-1.285h23.234c.707 0 1.262.59 1.262 1.285v3.23h8.75v-3.23c0-5.512-4.527-10.016-10.04-10.016H38.36h.012zM23.68 18.039V87.55h52.363V18.039H23.68zm61.254 0V87.55h4.07c6.063 0 10.996-4.898 10.996-10.973V29.012c0-6.05-4.933-10.973-10.996-10.973h-4.07zm-73.957.02C4.914 18.046.004 22.968.004 29.03v47.566c0 6.063 4.91 10.973 10.973 10.973h3.742V18.058h-3.742z" />
    </g>
  </SvgIcon>
);
