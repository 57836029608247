/* Absolute colors */
export const NAVY_BLUE = '#2a4171';
export const SKY_BLUE = '#659adb';
export const LIGHT_BLUE = '#d8e5f7';
export const LIGHTEST_BLUE = '#f3f8ff';
export const CYAN_BLUE = '#009FFF';

export const WHITE = '#ffffff';
export const OFF_WHITE = '#f7fafe';
export const TRANSPARENT_WHITE = '#ffffff4D';
export const LIGHT_GREY = '#f2f2f2';
export const LIGHTER_GREY = '#E3E7ED';
export const MEDIUM_GREY = '#c7cfdb';
export const DARK_GREY = '#e8e8e8';
export const DARKEST_GREY = '#b3b3b3';
export const LIGHT_BLACK = '#5a5a5a';
export const OFF_BLACK = '#2c3032';
export const BLACK = '#000000';

export const REGENT_GREY = '#78909c';
export const BLUE_GREY = '#c7cfdc';

export const PURPLE = {
  dark: '#572b71',
  light: '#ddd5e3',
  main: '#9a80aa',
};

export const ICON_DEFAULT = SKY_BLUE;
export const YELLOW = '#ffd600';
export const HIGHLIGHT_YELLOW = '#ffff00';
export const ORANGE = '#ffc107';
export const MIDDLE_CONFIDENCE_ORANGE = '#FFDA6A';
export const LOW_CONFIDENCE_ORANGE = '#FFF3CD';

export const GREEN = '#689f38';
export const BRIGHT_GREEN = '#8bc34a';
export const LIGHT_GREEN = '#b7d09d';
export const HIGH_CONFIDENCE_GREEN = '#b4d98b';
export const MEDIUM_CONFIDENCE_YELLOW = '#f8ef91';
export const LOW_CONFIDENCE_GREY = '#dce0e6';

export const DARK_RED = '#542344';
export const LIGHT_RED = '#af5b5b';
export const BRIGHT_RED = '#ef5350';
export const ALERT_MUTE = 'rgba(44, 48, 50, 0.5)';

// This color is used for the stripes in tables using stripes
export const LIGHT_VIOLET = 'rgba(230, 236, 246, 0.2)';

/* General App Colors */
export const DEFAULT_BACKGROUND = 'white';
export const TINTED_BACKGROUND = OFF_WHITE;
export const LIGHT_BORDER = LIGHT_GREY;
export const DARK_BORDER = BLUE_GREY;

export const PRIMARY_TEXT = OFF_BLACK;
export const SECONDARY_TEXT = 'white';

export const BOX_SHADOW = 'rgba(0, 0, 0, 0.08)';

// temporary
export const BORDER_COLOR = LIGHT_BORDER;

export const PRIMARY = NAVY_BLUE;
export const SECONDARY = SKY_BLUE;
export const ACCENT = LIGHT_BLUE;
export const LIGHT_ACCENT = LIGHTEST_BLUE;

export const PRIMARY_YELLOW = YELLOW;

export const PRIMARY_GREY = REGENT_GREY;
export const SECONDARY_GREY = BLUE_GREY;

/* Miscellaneous (need to refactor) */

export const TEXT = OFF_BLACK;
export const ERROR = BRIGHT_RED;
export const WARNING_COLOR = ORANGE;
export const TITLE_SELECTED = YELLOW;

/* Color for Live Processing of Cameras */
export const LIVE = GREEN; // Live or Recording or Processing
export const STARTING = LIGHT_GREEN;
export const HEADER_TEXT = NAVY_BLUE;
export const CLICKABLE_ICON_LIGHT = REGENT_GREY;
export const SETTINGS_TEXT = BLUE_GREY;

/* Report Page */
export const REPORT_TIMEPOINT_SELECTED = PRIMARY_YELLOW;
export const REPORT_FOOTER = OFF_WHITE;

/* VideoPlayer */
export const VIDEOPLAYER_TIMELINE_ITEM = PRIMARY_YELLOW;

/* Library Page */
export const LIBRARY_POPUP_HEADER = OFF_BLACK;
export const LIBRARY_POPUP_TEXT = 'white';

/* Geo Plan page */
export const GEOFENCE_POPUP_HEADER = OFF_BLACK;

/* Plan Page */
export const PLAN_FOOTER = OFF_WHITE;

/* Map Page */
export const MAP_PIN_VIDEO = '#542344';
export const MAP_PIN_IMAGE = '#4529ba';

export const FOLDER_COLOR = '#B2CCEE';

export const VERTICAL_LINE_COLOR = '#ccc';

export const LOW_CONFIDENCE_GREY_TIMELINE = DARKEST_GREY;
export const MEDIUM_CONFIDENCE_YELLOW_TIMELINE = MIDDLE_CONFIDENCE_ORANGE;
export const HIGH_CONFIDENCE_GREEN_TIMELINE = ORANGE;
export const CONFIRMED_CONFIDENCE_GREEN_TIMELINE = BRIGHT_GREEN;
