import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import Typography from 'components/Typography';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'pre',
  },
};

const MirageHeading = ({
  bottom,
  bottomClass,
  classes,
  className,
  switchHeaders,
  top,
  ...rest
}) => (
  <div className={classnames(classes.main, className)} {...rest}>
    <Typography data-testid="MirageHeading-main" variant={switchHeaders ? 'subtitle3' : 'h5'}>
      {top}
    </Typography>
    <Typography
      className={bottomClass}
      data-testid="MirageHeading-subtext"
      variant={!switchHeaders ? 'subtitle3' : 'h5'}
    >
      {bottom}
    </Typography>
  </div>
);

export default injectSheet(styles)(MirageHeading);
