import { NAME, SORT_ASC } from 'library/geospatial/redux/constants';

export const filter = {
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  sort: NAME,
  sortDirection: SORT_ASC,
  text: '',
};
