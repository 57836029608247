import { DateTime } from 'luxon';
import store from 'redux/store';

import { selectUserCustomTimeZone } from 'settings/redux/selectors';

const localDateTime = DateTime;

const convertToLuxonDate = (date = null) =>
  date.isLuxonDateTime ? date : DateTime.fromJSDate(new Date(date));

const userPreferredTZ = (displayLocalTZName = false) => {
  const userAdjustedTimeZone = selectUserCustomTimeZone(store.getState());
  const localTZ = DateTime.now().zoneName || 'local';
  if (displayLocalTZName) return localTZ;
  const userTZ = userAdjustedTimeZone?.name || localTZ;

  return userTZ;
};

const fromJSDateWithTZ = (
  date = null,
  { timeZone = null, keepLocalTime = false, skipTZAdjustment = false } = {}
) => {
  const systemTimeZone = userPreferredTZ();
  let newDateTime = convertToLuxonDate(date || DateTime.now());
  if (!skipTZAdjustment) {
    newDateTime = newDateTime.setZone(timeZone || systemTimeZone, {
      keepLocalTime,
    });
  }
  return newDateTime;
};

localDateTime.fromJSDateWithTZ = fromJSDateWithTZ;
localDateTime.convertToLuxonDate = convertToLuxonDate;
localDateTime.userPreferredTZ = userPreferredTZ;

export default localDateTime;
