import React from 'react';
import { createUseStyles } from 'react-jss';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import 'react-colorful/dist/index.css';
import './colorpicker.css';

const useStyles = createUseStyles({
  colorPicker: {
    padding: '12px',
    borderRadius: '10px',
    boxShadow: '0 5px 10px #999',
  },
  colorInput: {
    display: 'block',
    boxSizing: 'border-box',
    width: '72px',
    margin: '16px 44px 0',
    padding: '5px',
    border: '1px solid #ddd',
    borderRadius: '3px',
    background: '#eee',
    outline: 'none',
    font: 'inherit',
    textTransform: 'uppercase',
    textAlign: 'center',
    '&:hover': {
      borderColor: '#4298ef',
    },
  },
});
export function ColorPicker({ color, setColor }, props) {
  const { colorPicker, colorInput } = useStyles(props);

  return (
    <div className={colorPicker}>
      <HexColorPicker color={color} onChange={setColor} />
      <HexColorInput color={color} onChange={setColor} className={colorInput} />
    </div>
  );
}
