import isEqual from 'lodash/isEqual';

import {
  ALERT_QUERY,
  CAMERA,
  DATASOURCE,
  FOLDER,
  FOLDER_V2,
  GEO_CLASSIFIER,
  GEO_DETECTOR,
  GEOIMAGE,
  GEODATASET,
  IMAGE,
  MISSION,
  OBJECT,
  PERSON,
  USER,
  VENUE_MAP,
} from 'common/constants/app';

const defaults = {
  [DATASOURCE]: {},
  [IMAGE]: {},
  [PERSON]: {},
  [USER]: {},
  [OBJECT]: {},
  [CAMERA]: {},
  [VENUE_MAP]: {},
  [FOLDER]: {},
  [FOLDER_V2]: {},
  reports: {},
  scenes: {},
  liveQueries: {},
  liveQueryGroups: {},
  geofence: {},
  [GEOIMAGE]: {},
  [GEODATASET]: {},
  geoSearchRequest: {},
  geoReport: {},
  geoScene: {},
  geoSection: {},
  adhocS3File: {},
  [GEO_CLASSIFIER]: {},
  [GEO_DETECTOR]: {},
  [ALERT_QUERY]: {},
  [MISSION]: {},
};

export default function commonReducer(state = defaults, action) {
  switch (action.type) {
    case 'COMMON/MODELS/SET_MODELS': {
      //  Prevent unnecessary component update when data has not changed
      if (!isEqual(state[action.payload.modelName], action.payload.models)) {
        return {
          ...state,
          [action.payload.modelName]: action.payload.models,
        };
      }
      return state;
    }

    case 'COMMON/MODELS/MERGE_MODELS': {
      return {
        ...state,
        [action.payload.modelName]: {
          ...state[action.payload.modelName],
          ...action.payload.models,
        },
      };
    }

    case 'COMMON/MODELS/PURGE_GEODATASETS': {
      return {
        ...state,
        [GEODATASET]: defaults[GEODATASET],
      };
    }

    default:
      return state;
  }
}
