import React from 'react';
import { styled } from 'react-jss';
import { useParams } from 'react-router-dom';
import Typography from 'components/Typography';
import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import { useMission } from './MissionContext';
import Sighting from './Sighting';

const Root = styled('div')({
  position: 'relative',
  height: '100%',
  overflow: 'scroll',
});

const Sightings = styled('div')({});

const SightingsForIdentityPackage = () => {
  const { personId, depth } = useParams();
  const { sightingsByPersonId, isPending, nameByPersonId } = useMission();
  const title = `Sightings for ${nameByPersonId(personId)}'s known associates at depth=${depth}`;

  return (
    <Root>
      <Typography variant="h3">{title}</Typography>
      {isPending && <LoadingOverlay />}
      <Sightings>
        {sightingsByPersonId(personId)?.map(sighting => (
          <Sighting data={sighting} key={sighting.id} />
        ))}
      </Sightings>
    </Root>
  );
};

export default SightingsForIdentityPackage;
