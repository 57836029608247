import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Button from 'common/components/base/Button';
import Icon from 'common/components/base/Icon';

const styles = {
  button: {
    color: 'black',
    fontWeight: 500,
    fontSize: 16,
    width: 26,
    height: 26,
    minWidth: 'unset',
    padding: 0,
  },
  label: {
    height: 26,
  },
};

class MarkerControl extends React.Component {
  onClick = e => {
    e.stopPropagation();
    this.props.onClick(e);
  };

  render() {
    const { className, classes } = this.props;

    return (
      <Button
        theme="white"
        className={classnames(classes.button, className)}
        customClasses={{ label: classes.label }}
        onClick={this.onClick}
        style={{ fontSize: 20 }}
      >
        <Icon iconName="room" />
      </Button>
    );
  }
}
export default injectSheet(styles)(MarkerControl);
