import React from 'react';

import { LIVE } from 'common/constants/colors';

export default ({
  style: { backgroundColor = LIVE, borderColor = LIVE, color = 'white' } = {},
} = {}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="14.5" viewBox="0 0 42 18">
    <defs>
      <rect id="a" width="40" height="16" rx="2" />
    </defs>
    <g fill={backgroundColor} fillRule="evenodd">
      <g transform="translate(1 1)">
        <use fill={backgroundColor} />
        <rect width="41" height="17" x="-.5" y="-.5" stroke={borderColor} rx="2" />
      </g>
      <g fill={color} transform="translate(6 4)">
        <text fontFamily="Roboto-Medium, Roboto" fontSize="10" fontWeight="400" letterSpacing=".25">
          <tspan x="9" y="9">
            LIV
          </tspan>{' '}
          <tspan x="24.452" y="9" letterSpacing=".208">
            E
          </tspan>
        </text>
        <circle cx="3" cy="5.5" r="3" />
      </g>
    </g>
  </svg>
);
