import React from 'react';
import { createUseStyles } from 'react-jss';

import Avatar from 'common/components/base/Avatar';
import Typography from 'components/Typography';
import { getUserAbbreviation } from 'common/helpers/userUtils';

const useStyles = createUseStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(0.75),
    height: 20,
    width: 20,
    fontSize: 11,
  },
}));

const UserNameWithIcon = ({ name, color, subtext }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar size="small" className={classes.icon} style={color ? { backgroundColor: color } : {}}>
        {getUserAbbreviation(name)}
      </Avatar>
      <div>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="subtitle2">{subtext}</Typography>
      </div>
    </div>
  );
};

export default UserNameWithIcon;
