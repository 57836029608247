import React from 'react';
import injectSheet from 'react-jss';
import Paper from '@material-ui/core/Paper';
import DateTime from 'dateTime';
import Typography from '@material-ui/core/Typography';

import Button from 'common/components/base/Button';
import { SECONDARY, PRIMARY, ERROR } from 'common/constants/colors';

import CustomTimePickerView from './lib/CustomTimePickerView';

const styles = {
  main: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: 'min-content',
  },
  timeRange: {
    display: 'flex',
    padding: '0 6px',
  },
  pickerWrapper: {
    overflow: 'hidden',
    margin: '0 2px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 8,
    position: 'relative',
  },
  button: {
    textDecoration: 'none',
    color: SECONDARY,
    fontWeight: 500,
  },
  timePicker: {
    minHeight: 176,
    marginRight: 6,
  },
  dash: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px !important',
  },
  header: {
    position: 'relative',
    background: PRIMARY,
    height: 62,
    padding: 12,
    display: 'flex',
    alignItems: 'flex-end',
  },
  headerText: {
    color: 'white',
  },
  error: {
    marginRight: 12,
    fontWeight: 300,
    fontSize: 10,
    color: ERROR,
  },
};

function getState(start, end) {
  const internalStartTime = start
    ? DateTime.fromJSDateWithTZ(start)
    : DateTime.fromJSDateWithTZnow();
  const internalEndTime = end ? DateTime.fromJSDateWithTZ(end) : internalStartTime;

  return {
    internalStartTime,
    internalEndTime,
  };
}

const BASE_DATE = DateTime.fromJSDateWithTZ();
class TimeRangePicker extends React.Component {
  static defaultProps = {
    startTime: BASE_DATE,
    endTime: BASE_DATE,
  };

  state = {
    ...getState(this.props.startTime, this.props.endTime),
  };

  componentDidUpdate(prevProps) {
    const hasTimeChanged =
      prevProps.startTime !== this.props.startTime || this.props.endTime !== prevProps.endTime;

    if (this.props.startTime && this.props.endTime && hasTimeChanged) {
      this.onTimeChanged();
    }
  }

  resetError = () => this.setState;

  inputRefEndHours = node => (this.endHours = node);

  inputRefButton = node => (this.button = node);

  onChange = () => {
    const startTime = this.state.internalStartTime.valueOf();
    const endTime = this.state.internalEndTime.valueOf();

    if (startTime.valueOf() <= endTime.valueOf()) {
      this.props.onChange({
        startTime,
        endTime,
      });

      this.props.onClose();
    }
  };

  onStartChange = internalStartTime => this.setState({ internalStartTime });

  onEndChange = internalEndTime => this.setState({ internalEndTime });

  onTimeChanged = () =>
    this.setState({
      ...getState(this.props.startTime, this.props.endTime),
    });

  onFinishStartTime = () => {
    if (this.endHours) {
      this.endHours.select();
    }
  };

  onFinishEndTime = () => {
    if (this.button) {
      this.button.focus();
    }
  };

  render() {
    const { classes } = this.props;
    const { internalStartTime, internalEndTime } = this.state;
    const error =
      internalStartTime.valueOf() > internalEndTime.valueOf()
        ? 'Start Time must be before End Time'
        : '';

    return (
      <Paper className={classes.main}>
        <div className={classes.header}>
          <Typography classes={{ root: classes.headerText }} variant="h2">
            Select Time Range
          </Typography>
        </div>

        <div className={classes.timeRange}>
          <div className={classes.pickerWrapper}>
            <CustomTimePickerView
              className={this.props.classes.timePicker}
              onChange={this.onStartChange}
              onFinish={this.onFinishStartTime}
              time={this.state.internalStartTime}
            />
          </div>
          <Typography classes={{ root: classes.dash }} variant="h3">
            –
          </Typography>
          <div className={classes.pickerWrapper}>
            <CustomTimePickerView
              className={this.props.classes.timePicker}
              inputRefHours={this.inputRefEndHours}
              onChange={this.onEndChange}
              onFinish={this.onFinishEndTime}
              time={this.state.internalEndTime}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          <span className={classes.error}>{error}</span>
          <Button theme="transparent" className={classes.button} onClick={this.props.onClose}>
            CANCEL
          </Button>
          <Button
            ref={this.inputRefButton}
            theme="transparent"
            className={classes.button}
            onClick={this.onChange}
            disabled={!!error}
          >
            OK
          </Button>
        </div>
      </Paper>
    );
  }
}

export default injectSheet(styles)(TimeRangePicker);
