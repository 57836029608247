import React from 'react';
import Autosuggest from 'react-autosuggest';

import TextInput from 'common/components/base/TextInput';

import './autosuggest.css';

/**
  required:
  @prop {Function} getSuggestions takes in the inputValue as an argument and returns an array of suggestions
  @prop {Function} onChange is default onChange for text inputs
  @prop {Function} value is the value of the text input

  optional:
  @prop {Function} renderSuggestion determines how to render the suggestion, defaults to using the 'name' key
  @prop {Function} getSuggestionValue determines how to get the suggestion value
*/

class AutoSuggest extends React.Component {
  static defaultProps = {
    renderSuggestion: ({ name, isdefault = false }) => (
      <span isdefault={isdefault ? 'true' : undefined}>{name}</span>
    ),
    getSuggestionValue: suggestion => suggestion.name,
    inputProps: {},
  };

  state = { suggestions: [] };

  componentDidMount() {
    if (this.props.onMount) this.props.onMount();
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.props.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderInput = inputProps =>
    this.props.renderInput ? (
      this.props.renderInput(inputProps)
    ) : (
      <TextInput autoFocus inputProps={{ ...inputProps }} />
    );

  shouldRenderSuggestions = () => true;

  render() {
    const {
      value,
      classes,
      onMount,
      onChange,
      disabled,
      renderSuggestion,
      getSuggestionValue,
      inputProps,
      ...rest
    } = this.props;
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        renderInputComponent={this.renderInput}
        inputProps={{
          ...inputProps,
          value,
          onChange,
        }}
        {...rest}
      />
    );
  }
}

export default AutoSuggest;
