import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BackpackIcon = props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 96" {...props}>
    <g fillRule="evenodd">
      <path d="M.614 63.629v14.979c0 3.125 2.059 5.667 4.586 5.667h2.993l.004-27.413-4.1 1.275C2.05 58.771.613 61.033.613 63.63zM80.904 58.133l-4.1-1.275v27.417h2.992c2.532 0 4.586-2.542 4.586-5.667l.004-14.979c0-2.596-1.436-4.858-3.482-5.496zM31.618 60.642h9.073v-3.954h3.78v3.954h9.073c4.52 0 8.197-3.691 8.197-8.229V21.13a3.748 3.748 0 0 0-3.453-3.738c-.195-.012-.39-.012-.589-.012h-30.24c-.2 0-.394.004-.593.012a3.744 3.744 0 0 0-3.45 3.738v31.283c.005 4.542 3.682 8.23 8.202 8.23z" />
      <path d="M65.331 19.425c.129.546.195 1.117.195 1.7v31.283c0 6.634-5.375 12.03-11.982 12.03h-9.073v3.512h-3.78v-3.513h-9.073c-6.608 0-11.982-5.396-11.982-12.029V21.125c0-.587.066-1.158.195-1.704-4.59 2.654-7.69 7.637-7.69 13.325V80.62C12.143 89.104 19.012 96 27.458 96h30.249c8.442 0 15.31-6.897 15.31-15.372V32.75c.004-5.692-3.096-10.667-7.686-13.325h-.001zm-7.338 61.15h-30.82v-3.796h30.816l.004 3.796zM33.697 12.717c0-4.921 3.989-8.921 8.886-8.921 4.902 0 8.886 4 8.886 8.92v.863h3.781v-.862C55.25 5.704 49.568 0 42.583 0c-6.985 0-12.671 5.704-12.671 12.717v.862h3.78l.005-.862z" />
    </g>
  </SvgIcon>
);

BackpackIcon.defaultProps = {
  color: 'primary',
};

export default BackpackIcon;
