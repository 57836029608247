import React from 'react';

import { REGENT_GREY } from 'common/constants/colors';

export default ({ style: { color = REGENT_GREY, width = 20, height = width } = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        d="M11.5 2c4.65 0 8.58 3.03 9.97 7.22L19.1 10c-1.05-3.19-4.06-5.5-7.6-5.5-1.96 0-3.73.72-5.12 1.88L9 9H2V2l2.6 2.6C6.45 3 8.85 2 11.5 2zM5.253 17.059L5.827 12h5.578v1.647H7.447l-.246 2.14c.47-.25.969-.376 1.497-.376.948 0 1.691.294 2.229.882.538.588.806 1.41.806 2.468 0 .642-.135 1.218-.406 1.726a2.87 2.87 0 0 1-1.166 1.182c-.506.28-1.103.42-1.79.42-.602 0-1.16-.121-1.676-.365a2.97 2.97 0 0 1-1.22-1.029A2.804 2.804 0 0 1 5 19.185h1.955c.041.414.186.737.434.967.249.23.573.345.974.345.447 0 .791-.16 1.033-.482.241-.321.362-.776.362-1.364 0-.565-.14-.998-.417-1.298-.278-.301-.672-.452-1.183-.452-.47 0-.85.123-1.141.37l-.192.177-1.572-.39zm11.826 2.85c0-.241-.12-.432-.359-.57-.239-.14-.623-.264-1.152-.373-1.759-.37-2.638-1.117-2.638-2.242 0-.657.272-1.205.817-1.644.544-.44 1.256-.66 2.136-.66.939 0 1.69.22 2.252.663.563.442.844 1.016.844 1.723h-1.975a.956.956 0 0 0-.274-.701c-.182-.185-.467-.277-.854-.277-.333 0-.59.075-.772.226a.712.712 0 0 0-.274.574c0 .219.104.395.311.53.207.134.557.25 1.05.348a8.57 8.57 0 0 1 1.244.332c1.043.383 1.565 1.046 1.565 1.99 0 .674-.29 1.22-.868 1.636-.579.417-1.326.626-2.242.626-.62 0-1.17-.11-1.651-.332-.481-.22-.858-.524-1.132-.909-.273-.385-.41-.8-.41-1.247h1.873c.019.35.148.62.39.806.241.187.565.28.97.28.379 0 .665-.071.859-.215a.67.67 0 0 0 .29-.564z"
      />
    </g>
  </svg>
);
