import injectSheet from 'react-jss';
import MaterialLinearProgress from '@material-ui/core/LinearProgress';

import { BRIGHT_GREEN, PRIMARY_YELLOW, ERROR, DARK_GREY } from 'common/constants/colors';

const styles = theme => ({
  good: {
    backgroundColor: BRIGHT_GREEN,
  },
  warning: {
    backgroundColor: PRIMARY_YELLOW,
  },
  error: {
    backgroundColor: ERROR,
  },
  unknown: {
    backgroundColor: DARK_GREY,
  },
  statusBar: {
    width: '100%',
    borderRadius: theme.spacing(1),
  },
});

const StatusLinearProgress = ({
  classes,
  color = 'unknown',
  className = classes.statusBar,
  variant = 'indeterminate',
  value = 0,
  ...rest
}) => (
  <MaterialLinearProgress
    classes={{
      barColorPrimary: classes[color],
      colorPrimary: classes.unknown,
    }}
    className={className}
    variant={variant}
    value={value}
    {...rest}
  />
);

export default injectSheet(styles)(StatusLinearProgress);
