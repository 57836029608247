import { combineReducers } from 'redux';

import bottomBarReducer from 'analysis/analysisMain/bottomBar/redux/reducer';

import { SEARCH_FINISHED, DEFAULT_LIMIT } from './constants';
import searchBarReducer from './searchBar/reducer';

const defaults = {
  isSubmittingSearch: false,
  searchRequest: {},
  searchRequestId: null,
  searchStatus: SEARCH_FINISHED,
  mapConfig: {},

  searchResults: [],
  searchResultsLimit: DEFAULT_LIMIT,
  searchResultsOffset: 0,
  searchResultsTotal: 0,

  groupedSearchResults: [],
  groupedSearchResultsLimit: DEFAULT_LIMIT,
  groupedSearchResultsOffset: 0,
  groupedSearchResultsTotal: 0,

  groupBy: null,
  groupByFilter: null,
  selectedResultVideoId: null,
  selectedResultImageId: null,
  selectedRelatedImageId: null,
  selectedResultTimestamp: null, // This will be in absoulte time for live videos
  videoTimelineData: [],

  searchResultsFrames: [],
  searchResultsImagesFrames: [],
  searchResultsRangeStart: null,
  searchResultsRangeEnd: null,

  allVideoDetections: [],
  allVideoDetectionsRangeStart: null,
  allVideoDetectionsRangeEnd: null,

  /* Analysis History */
  searchRequestHistory: [],
  isLoadingHistory: true,
  showAllSearches: false,

  // Analysis Image
  imageDetails: {},
  inspectModeDetections: {},
};

function main(state = defaults, action) {
  switch (action.type) {
    case 'ANALYSIS/RESET': {
      return {
        ...defaults,
        searchRequestId: state.searchRequestId,
        showAllSearches: state.showAllSearches,
      };
    }
    case 'ANALYSIS/SET_SHOW_ALL_SEARCHED': {
      return {
        ...state,
        showAllSearches: action.payload,
      };
    }
    case 'ANALYSIS/SET_SEARCH_REQUEST_HISTORY': {
      return {
        ...state,
        searchRequestHistory: action.payload,
      };
    }
    case 'ANALYSIS/SET_LOADING_HISTORY': {
      return {
        ...state,
        isLoadingHistory: action.payload,
      };
    }
    case 'ANALYSIS/SET_SEARCH_REQUEST': {
      return {
        ...state,
        searchRequest: action.payload.request,
      };
    }
    case 'ANALYSIS/SET_SEARCH_REQUEST_ID': {
      return {
        ...state,
        searchRequestId: action.payload.id,
      };
    }
    case 'ANALYSIS/SET_MAP_CONFIG': {
      return {
        ...state,
        mapConfig: action.payload.mapConfig,
      };
    }
    case 'ANALYSIS/SET_GROUP_BY': {
      return {
        ...state,
        groupBy: action.payload.groupBy,
      };
    }
    case 'ANALYSIS/SET_GROUP_BY_FILTER': {
      return {
        ...state,
        groupByFilter: action.payload.groupByFilter,
      };
    }
    case 'ANALYSIS/SET_SEARCH_RESULTS_META': {
      return {
        ...state,
        searchResults: action.payload.results,
        searchResultsLimit: action.payload.limit,
        searchResultsOffset: action.payload.offset,
        searchResultsTotal: action.payload.total,
      };
    }
    case 'ANALYSIS/SET_SEARCH_RESULTS_GROUP_META': {
      return {
        ...state,
        groupedSearchResults: action.payload.results,
        groupedSearchResultsLimit: action.payload.limit,
        groupedSearchResultsOffset: action.payload.offset,
        groupedSearchResultsTotal: action.payload.total,
      };
    }
    case 'ANALYSIS/SET_SEARCH_STATUS': {
      return {
        ...state,
        searchStatus: action.payload.searchStatus,
      };
    }

    case 'ANALYSIS/SET_VIDEO_TIMELINE_DATA': {
      return {
        ...state,
        videoTimelineData: action.payload.timelineData,
      };
    }
    case 'ANALYSIS/SET_SELECTED_RESULT_VIDEO_ID': {
      return {
        ...state,
        selectedResultVideoId: action.payload.video,
      };
    }
    case 'ANALYSIS/SET_SELECTED_RESULT_IMAGE_ID': {
      return {
        ...state,
        selectedResultImageId: action.payload.image,
      };
    }
    case 'ANALYSIS/SET_HIGHLIGHTED_RESULTS': {
      return {
        ...state,
        highlightedResults: action.payload.highlight,
      };
    }
    case 'ANALYSIS/SET_SELECTED_RELATED_IMAGE_ID': {
      return {
        ...state,
        selectedRelatedImageId: action.payload.image,
      };
    }
    case 'ANALYSIS/SET_SELECTED_RESULT_TIMESTAMP': {
      return {
        ...state,
        selectedResultTimestamp: action.payload.timestamp,
      };
    }
    case 'ANALYSIS/SET_VIDEO_PLAYER_SEARCH_RESULTS': {
      return {
        ...state,
        searchResultsFrames: action.payload.results,
        searchResultsRangeStart: action.payload.allRangeStart,
        searchResultsRangeEnd: action.payload.allRangeEnd,
      };
    }
    case 'ANALYSIS/SET_IMAGE_SEARCH_RESULTS': {
      return {
        ...state,
        searchResultsImagesFrames: action.payload.results,
      };
    }
    case 'ANALYSIS/SET_ANALYSIS_IS_SUBMITTING': {
      return {
        ...state,
        isSubmittingSearch: action.payload,
      };
    }
    case 'ANALYSIS/SET_ALL_VIDEO_DETECTIONS': {
      return {
        ...state,
        allVideoDetections: action.payload.allDetections,
        allVideoDetectionsRangeStart: action.payload.allRangeStart,
        allVideoDetectionsRangeEnd: action.payload.allRangeEnd,
      };
    }
    case 'ANALYSIS/SET_IMAGE_DETAILS': {
      return {
        ...state,
        imageDetails: action.payload,
      };
    }
    case 'ANALYSIS/SET_INSPECT_MODE_DETECTIONS': {
      return {
        ...state,
        inspectModeDetections: action.payload,
      };
    }

    default:
      return state;
  }
}

export default combineReducers({
  main,
  bottomBar: bottomBarReducer,
  searchQuery: searchBarReducer,
});
