import React, { useContext, createContext, useEffect, useState } from 'react';

import { STATUS } from 'common/constants/api';
import { getLibraryData } from 'common/api/missionsApi';

const MissionContext = createContext();

export const useMission = () => useContext(MissionContext);

export const MissionProvider = ({ id: missionId, depth, ...rest }) => {
  const [mission, setMission] = useState(null);
  const [status, setStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    const fetchMission = async () => {
      try {
        setStatus(STATUS.PENDING);
        setMission(await getLibraryData(missionId, { depth }));
        setStatus(STATUS.SUCCESS);
      } catch (e) {
        setStatus(STATUS.FAILURE);
      }
    };

    fetchMission();
  }, [missionId, depth]);

  const normalizedPersons = mission?.persons?.reduce(
    (acc, p) => {
      acc.personsById[p.id] = p;
      acc.persons.push(p.id);
      return acc;
    },
    { personsById: {}, persons: [] }
  );
  const normalizedDatasources = mission?.datasources?.reduce(
    (acc, d) => {
      acc.datasourcesById[d.id] = d;
      acc.datasources.push(d.id);
      return acc;
    },
    { datasourcesById: {}, datasources: [] }
  );

  return (
    <MissionContext.Provider
      value={{
        persons: normalizedPersons?.persons,
        personsById: normalizedPersons?.personsById,
        nameByPersonId: pid => normalizedPersons?.personsById?.[pid]?.name,
        thumbByPersonId: pid => normalizedPersons?.personsById?.[pid]?.images[0],
        titleByDatasourceId: id => normalizedDatasources?.datasourcesById?.[id]?.title,
        sightingsByPersonId: pid =>
          normalizedPersons?.personsById?.[pid]?.groups.reduce(
            (acc, g) => acc.concat(g.sightings),
            []
          ),
        isPending: status === STATUS.PENDING,
      }}
      {...rest}
    />
  );
};
