import isNumber from 'lodash/isNumber';
import compact from 'lodash/compact';

const DISTANCE_THRESHOLD = 0.25;
const VIDEO_FRAME_BUFFER = 1.1;
const KEYFRAME_BUFFER = 1.1;

/* These constants are exported purely to create tests. You should never need these constants anywhere else */
export const LARGE_NUMBER = 10000000000;

/*
  public methods:
  addKeyFrames - add key frames in sorted order
  getBoundingBoxesAtTime - returns either an exact or interpolated bounding box
  enableInterpolation/disableIntrerpolation - sets interpolation manually
*/
export default class DetectionInterpolator {
  constructor(options = {}) {
    this._keyFrames = options.keyFrames || [];
    this._interpolableDetectionsCache = {};

    const { frameRate = 25, keyFrameRate = 5 } = options;
    /**
     * See https://percipientai.atlassian.net/browse/MIR-14701
     * The keyFrameRate is set to 5 for most videos but it should never be above the
     * frameRate of the video
     */
    this.setKeyFrameRate(frameRate < keyFrameRate ? frameRate : keyFrameRate);
    this.setVideoFrameRate(frameRate);

    this._disableInterpolation = options.disableInterpolation || false;
  }

  enableInterpolation = () => (this._disableInterpolation = true);

  disableInterpolation = () => (this._disableInterpolation = false);

  flush() {
    this._keyFrames = [];
    this._interpolableDetectionsCache = {};
  }

  addKeyFrames(keyFrames) {
    this._keyFrames = keyFrames;
  }

  getNumberOfKeyfames(timestamp) {
    const total = this._keyFrames.length;
    if (!isNumber(timestamp)) {
      return {
        total,
        after: null,
        before: null,
      };
    }

    for (let i = 0; i < this._keyFrames.length; i++) {
      if (this._keyFrames[i].getTimestamp() + 2 > timestamp) {
        return {
          total,
          after: total - i - 1,
          before: i,
        };
      }
    }

    return {
      total,
      after: 0,
      before: total,
    };
  }

  /* searchTime in milliseconds */
  getBoundingBoxesAtTime(searchTime, frameRate, skipInterpolation = this._disableInterpolation) {
    if (frameRate) {
      this.setVideoFrameRate(frameRate);
    }

    const lastKeyFrameIndex = this._keyFrames.length - 1;

    /* keyframes array empty */
    if (lastKeyFrameIndex === -1) {
      return [];
    }

    /* Find keyFrame directly previous to searchTime */
    const previousKeyframeIndex = this._findPreviousKeyFrameIndex(searchTime);
    if (!isNumber(previousKeyframeIndex)) {
      return [];
    }

    /* If too far away from last key frame retun [] */
    const previousKeyframe = this._keyFrames[previousKeyframeIndex];
    const timeFromPrev = searchTime - previousKeyframe.getTimestamp();
    if (this._isMoreThanOneKeyFrame(timeFromPrev)) {
      return [];
    }

    /* If last key frame return it */
    if (skipInterpolation || previousKeyframeIndex === lastKeyFrameIndex) {
      return this._getDetectionsWithinOneVideoFrame(searchTime, previousKeyframe);
    }

    /* If the gap between previous and next is too much do not interpolate */
    const nextKeyFrame = this._keyFrames[previousKeyframeIndex + 1];
    const timeFromNext = nextKeyFrame.getTimestamp() - searchTime;
    if (this._isMoreThanOneKeyFrame(timeFromNext)) {
      return this._getDetectionsWithinOneVideoFrame(searchTime, previousKeyframe);
    }

    return this._getInterpolatedDetections(searchTime, previousKeyframe, nextKeyFrame);
  }

  getPreviousKeyFrame(searchTime) {
    const previousKeyframeIndex = this._findPreviousKeyFrameIndex(searchTime);
    return !isNumber(previousKeyframeIndex) ? null : this._keyFrames[previousKeyframeIndex];
  }

  getNextKeyFrame(searchTime) {
    const previousKeyframeIndex = this._findPreviousKeyFrameIndex(searchTime);

    /* Default to -1 in case left of left most keyFrame */
    const prevIndex = isNumber(previousKeyframeIndex) ? previousKeyframeIndex : -1;

    return !this._keyFrames[prevIndex + 1] ? null : this._keyFrames[prevIndex + 1];
  }

  setVideoFrameRate(frameRate) {
    this._videoFrameRate = frameRate;
    this._avgVideoFrameDistance = (1 / frameRate) * 1000;
  }

  setKeyFrameRate(frameRate) {
    this._keyFrameRate = frameRate;
    this._avgKeyFrameDistance = (1 / frameRate) * 1000;
  }

  /* Get the timestamp of the previous video frame timestamp based on a keyFrame */
  _getPreviousVideoFrameTimestamp(keyFrame) {
    const frameTime = keyFrame.getTimestamp();
    // We round to the nearest frameNumber because the detection timestamp is represented in milliseconds
    // so cannot be fractions of a millisecond. At 3 FPS, the first frame is from 0-333.32 milliseconds
    // and the next is from 333.33-666.65 milliseconds, but because the detection timestamp is in milliseconds
    // and cannot be fractional, the detection timestamp provided by the backend for a detection in the
    // second frame will be 333 milliseconds. Math.round(333 / 333.3333333) = 1, which is expected
    const frameNumber = Math.round(frameTime / this._avgVideoFrameDistance);
    return frameNumber * this._avgVideoFrameDistance;
  }

  _getDetectionsWithinOneVideoFrame(searchTime, keyFrame) {
    /*
      Dashed videos are guaranteed to have constant frame rate, but the processed keyFrame detections may not,
      so we will need to find the video keyFrame bounds to evaluate whether or not we should render the detection
    */
    const videoFrameTimestamp = this._getPreviousVideoFrameTimestamp(keyFrame);
    if (this._isMoreThanOneVideoFrame(searchTime - videoFrameTimestamp)) {
      return [];
    }
    return keyFrame.getDetections();
  }

  /*
    Should only round a single keyframe away based on KeyFrameRate
    Add 10% buffer for rounding
  */
  _isMoreThanOneKeyFrame(timeDiff) {
    return Math.abs(timeDiff) > this._avgKeyFrameDistance * KEYFRAME_BUFFER;
  }

  /* Should only show a detection that is not interpolated for a single video frame  */
  _isMoreThanOneVideoFrame(timeDiff) {
    return Math.abs(timeDiff) > this._avgVideoFrameDistance * VIDEO_FRAME_BUFFER;
  }

  /* Use binary search to find previous keyFrame */
  _findPreviousKeyFrameIndex(searchTime) {
    const search = (lower, upper) => {
      const lowerTime = this._keyFrames[lower].getTimestamp();

      /* Can't be in this interval */
      if (searchTime < lowerTime) {
        return null;
      }

      if (lowerTime === searchTime) {
        return lower;
      }

      /* Last KeyFrame */
      if (lower === this._keyFrames.length - 1) {
        return lower;
      }

      /* If next index greater than search or last keyFrame */
      if (searchTime < this._keyFrames[lower + 1].getTimestamp()) {
        return lower;
      }

      /* No interval left */
      if (upper === lower) {
        return null;
      }

      const midpoint = lower + Math.floor((upper - lower) / 2);

      return search(lower, midpoint) || search(midpoint + 1, upper);
    };

    if (this._keyFrames.length === 0) {
      return null;
    }

    return search(0, this._keyFrames.length - 1);
  }

  _getInterpolatedDetections(searchTime, prevKeyFrame, nextKeyFrame) {
    const key = `${prevKeyFrame.getTimestamp()}_${nextKeyFrame.getTimestamp()}`;

    /* Cache boxes that can be interpolated between keyFrames. Reduces
    this function from being fully exectued by a factor of 10 */
    if (!(key in this._interpolableDetectionsCache)) {
      this._interpolableDetectionsCache[key] = DetectionInterpolator._getInterpolationData(
        prevKeyFrame,
        nextKeyFrame
      );
    }

    const {
      interpolatedNextDetections,
      interpolatedPrevDetections,
    } = this._interpolableDetectionsCache[key];

    const prevDetections = prevKeyFrame.getDetections();
    const nextDetections = nextKeyFrame.getDetections();

    const ratio = DetectionInterpolator._getDistanceRatio(
      searchTime,
      prevKeyFrame.getTimestamp(),
      nextKeyFrame.getTimestamp()
    );

    const interpolatedPrevFrames = prevDetections.map((detection, index) => {
      const timeDiff = searchTime - this._getPreviousVideoFrameTimestamp(prevKeyFrame);
      const found = interpolatedPrevDetections[index];

      if (found) {
        const interpolated = DetectionInterpolator._interpolate(
          ratio,
          prevDetections[found.prev],
          nextDetections[found.next]
        );

        /* Assume not interpolated frame if within less than one video frame */
        if (this._isMoreThanOneVideoFrame(timeDiff)) {
          interpolated.setInterpolated(true);
        }

        return interpolated;
      }

      /* Do not return detections more then 1 videoKey frame away if it does not interpolate */
      if (this._isMoreThanOneVideoFrame(timeDiff)) {
        return null;
      }

      return detection;
    });

    const interpolatedNextFrames = nextDetections.map((detection, index) => {
      const timeDiff = searchTime - this._getPreviousVideoFrameTimestamp(nextKeyFrame);
      const found = interpolatedNextDetections[index];

      /* if interpolated, ignore since it is already interpolated by prev detections */
      if (found) {
        return null;
      }

      if (this._isMoreThanOneVideoFrame(timeDiff)) {
        return null;
      }

      return detection;
    });

    /* Loop through prevDetections and either return interpolated or previous */
    return compact(interpolatedPrevFrames.concat(interpolatedNextFrames));
  }

  /*
    Finds detections that should be interpolated/non-interpolated between 2 keyFrames
    returns list of index mappings [{prev: 0, next: 1}] for interpolated frames
    returns list of index mappings [1] for non-interpolated frames
  */
  static _getInterpolationData(prevKeyFrame, nextKeyFrame) {
    const prevDetections = prevKeyFrame.getDetections();
    const nextDetections = nextKeyFrame.getDetections();
    const matrix = DetectionInterpolator._generateCostMatrix(prevDetections, nextDetections);

    const interpolatedNextDetections = Array(nextDetections.length);
    const interpolatedPrevDetections = Array(prevDetections.length);
    const visitedRows = new Set();
    const visitedCols = new Set();

    const searchMatrix = () => {
      let result = {
        distance: LARGE_NUMBER,
        prev: null,
        next: null,
      };

      for (let i = 0; i < matrix.length; i += 1) {
        if (visitedRows.has(i)) continue; // eslint-disable-line no-continue

        for (let j = 0; j < matrix[i].length; j += 1) {
          if (visitedCols.has(j)) continue; // eslint-disable-line no-continue

          if (matrix[i][j] < result.distance) {
            result = { prev: i, next: j, distance: matrix[i][j] };
          }
        }
      }

      return result;
    };

    for (let i = 0; i < matrix.length; i += 1) {
      const result = searchMatrix();
      visitedRows.add(result.prev);
      visitedCols.add(result.next);

      /* Only do interpolation if min is less than DISTANCE_THRESHOLD */
      if (result.distance > DISTANCE_THRESHOLD) {
        break;
      }

      interpolatedNextDetections[result.next] = true;
      interpolatedPrevDetections[result.prev] = {
        prev: result.prev,
        next: result.next,
      };
    }

    return { interpolatedPrevDetections, interpolatedNextDetections };
  }

  static _generateCostMatrix(prevDetections, nextDetections) {
    return prevDetections.map(prevDetection =>
      nextDetections.map(nextDetection =>
        prevDetection.getType() !== nextDetection.getType()
          ? LARGE_NUMBER - 1
          : DetectionInterpolator._distanceBetweenDetections(prevDetection, nextDetection)
      )
    );
  }

  /* This distance penalty is just a guess, could probably improve */
  static _distanceBetweenDetections(detectionA, detectionB) {
    const aBounds = detectionA.getBounds();
    const bBounds = detectionB.getBounds();

    return (
      Math.abs(aBounds.x - bBounds.x) +
      Math.abs(aBounds.y - bBounds.y) +
      Math.abs(aBounds.w - bBounds.w) +
      Math.abs(aBounds.h - bBounds.h)
    );
  }

  static _getDistanceRatio(searchTime, prevKeyFrameTime, nextKeyFrameTime) {
    const pastPreviousTime = searchTime - prevKeyFrameTime;
    const timeBetween = nextKeyFrameTime - prevKeyFrameTime;
    return pastPreviousTime / timeBetween;
  }

  static _interpolate(ratio, prevDetection, nextDetection) {
    const prevBounds = prevDetection.getBounds();
    const nextBounds = nextDetection.getBounds();

    /* We dont want to modify actual detections, however we do want to
    preserve data stored in initial prevDetection such as person or embedding */
    const clone = prevDetection.clone();

    clone.setBounds({
      x: prevBounds.x * (1 - ratio) + ratio * nextBounds.x,
      y: prevBounds.y * (1 - ratio) + ratio * nextBounds.y,
      w: prevBounds.w * (1 - ratio) + ratio * nextBounds.w,
      h: prevBounds.h * (1 - ratio) + ratio * nextBounds.h,
    });

    return clone;
  }
}
