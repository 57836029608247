import Typography from '@material-ui/core/Typography';

import { makeStyles } from 'tss-react-local';
import Button from 'common/components/base/Button';

const useStyles = makeStyles()({
  button: {
    color: 'rgba(255,255,255,0.4)',
    padding: 2,
    width: 'min-content',
    height: 'min-content',
    minWidth: 'unset',
    textTransform: 'unset',
  },
  selected: {
    color: 'rgba(255,255,255,0.95)',
  },
  label: {
    width: 'min-content',
    whiteSpace: 'nowrap',
  },
});

const ToolbarButton = props => {
  const { className, onClick, label, selected, variant, dataTestId } = props;
  const { classes, cx } = useStyles();

  return (
    <Button
      theme="transparent"
      onClick={onClick}
      customClasses={{
        root: cx(classes.button, className, { [classes.selected]: selected }),
        label: classes.label,
      }}
      data-testid={dataTestId}
    >
      <Typography variant={variant}>{label}</Typography>
    </Button>
  );
};

export default ToolbarButton;
