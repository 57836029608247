import { useRef, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { createUseStyles } from 'react-jss';

import Typography from 'components/Typography';

import { BORDER_COLOR, ERROR } from 'common/constants/colors';

const INVALID_CHAR_INPUT = /[^a-zA-Z0-9_\-. ]+/;

const useStyles = createUseStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(12),
    padding: theme.spacing(1, 2),
    boxSizing: 'border-box',
  },
  input: {
    width: '100%',
    height: theme.spacing(4),
    boxShadow: 'none',
    borderRadius: 2,
    border: `1px solid ${BORDER_COLOR}`,
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(0.5, 0),
    boxSizing: 'border-box',
    fontSize: 11,
  },
  error: {
    color: ERROR,
    marginLeft: theme.spacing(1.5),
  },
}));

const IdentityForm = ({
  identity = {},
  defaultName,
  errorMessage,
  onSubmit,
  isLoading = false,
}) => {
  const classes = useStyles();
  const input = useRef();
  const [name, setName] = useState(identity?.name || defaultName || '');
  const [tags, setTags] = useState(identity?.tags || '');
  const [error, setError] = useState(errorMessage);

  useEffect(() => {
    input.current.focus();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({ name, tags });
  };

  useEffect(() => {
    let _errorMessage = '';

    if (isEmpty(name)) {
      _errorMessage = 'Name cannot be blank.';
    } else if (INVALID_CHAR_INPUT.test(name)) {
      _errorMessage =
        'Invalid Name: Only letters, numbers, and the following special characters _ - . are allowed.';
    } else if (name.length > 64) {
      _errorMessage = 'Name cannot exceed 64 characters.';
    }

    setError(_errorMessage);
  }, [name]);

  return (
    <form id="identity-form" className={classes.main} onSubmit={handleSubmit}>
      <Typography className={classes.error} variant="body3" data-testid="IdentityForm-errorMessage">
        {errorMessage || error}
      </Typography>
      <input
        className={classes.input}
        value={name}
        ref={node => (input.current = node)}
        type="text"
        placeholder="Enter Identity Name"
        onChange={({ target: { value = '' } = {} }) => setName(value)}
        required
        disabled={isLoading}
        data-testid="Identity-form-name"
      />
      <input
        className={classes.input}
        type="text"
        value={tags}
        onChange={({ target: { value = '' } = {} }) => setTags(value)}
        placeholder="Enter Additional Information"
        disabled={isLoading}
        data-testid="Identity-form-tags"
      />
    </form>
  );
};

export default IdentityForm;
