import { pagination } from 'library/redux/sharedDefaults';
import { ASC, CREATED, DESC, NAME } from 'library/redux/constants';

const defaultReportsPagination = { ...pagination, limit: 25 };
const defaultScenesPagination = { ...pagination, limit: 20 };
const defaultReport = {
  scenes: [],
  sections: [],
  strayScenes: [],
};

const defaults = {
  report: defaultReport,
  reportIsLoading: false,
  reports: {
    isLoading: false,
    filter: {
      text: '',
      sort: NAME,
      sortDirection: ASC,
    },
    filteredReports: [],
    pagination: defaultReportsPagination,
  },
  scenes: {
    isLoading: false,
    filter: {
      text: '',
      sort: CREATED,
      sortDirection: DESC,
    },
    filteredScenes: [],
    pagination: defaultScenesPagination,
  },
};

export default function reportGeoReducer(state = defaults, action) {
  switch (action.type) {
    case 'REPORTS_GEO/SET_REPORT_IS_LOADING': {
      return {
        ...state,
        reportIsLoading: action.payload,
      };
    }
    case 'REPORTS_GEO/SET_GEO_REPORT_TO_DEFAULT': {
      return {
        ...state,
        report: defaultReport,
        reportIsLoading: false,
      };
    }
    case 'REPORTS_GEO/SET_GEO_REPORT': {
      return {
        ...state,
        reportIsLoading: false,
        report: action.payload,
      };
    }
    case 'REPORTS_GEO/SET_REPORTS_IS_LOADING': {
      return {
        ...state,
        reports: {
          ...state.reports,
          isLoading: action.payload,
        },
      };
    }
    case 'REPORTS_GEO/SET_SCENES_IS_LOADING': {
      return {
        ...state,
        scenes: {
          ...state.scenes,
          isLoading: action.payload,
        },
      };
    }
    case 'REPORTS_GEO/SET_REPORTS_FILTERED_REPORTS': {
      return {
        ...state,
        reports: {
          ...state.reports,
          filteredReports: action.payload,
        },
      };
    }
    case 'REPORTS_GEO/SET_REPORTS_PAGINATION': {
      return {
        ...state,
        reports: {
          ...state.reports,
          pagination: {
            ...state.reports.pagination,
            ...action.payload,
          },
        },
      };
    }
    case 'REPORTS_GEO/SET_REPORTS_FILTER': {
      return {
        ...state,
        reports: {
          ...state.reports,
          filter: {
            ...state.reports.filter,
            ...action.payload,
          },
          pagination: defaultReportsPagination,
        },
      };
    }
    case 'REPORTS_GEO/SET_REPORTS_FILTERED_SCENES': {
      return {
        ...state,
        scenes: {
          ...state.scenes,
          filteredScenes: action.payload,
        },
      };
    }
    case 'REPORTS_GEO/SET_REPORTS_SCENES_PAGINATION': {
      return {
        ...state,
        scenes: {
          ...state.scenes,
          pagination: {
            ...state.scenes.pagination,
            ...action.payload,
          },
        },
      };
    }
    case 'REPORTS_GEO/SET_REPORTS_SCENES_FILTER': {
      return {
        ...state,
        scenes: {
          ...state.scenes,
          filter: {
            ...state.scenes.filter,
            ...action.payload,
          },
          pagination: defaultScenesPagination,
        },
      };
    }
    default:
      return state;
  }
}
