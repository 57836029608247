import React from 'react';
import isNumber from 'lodash/isNumber';
import DateTime from 'dateTime';

import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';
import JumpToTimeDialog from './lib/JumpToTimeDialog';
import DateOverlay from './lib/DateOverlay';

const overlayStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 4,
  zIndex: 20,
  display: 'flex',
  alignItems: 'center',
};

class VideoPlayerDateInfo extends React.Component {
  state = { showJumpToTime: false };

  setTime(seconds) {
    if (this.overlay) {
      this.overlay.setTime(seconds);
    }
  }

  getOverlayDate = () => (this.overlay ? this.overlay.getDate() : 0);

  _openJumpToTime = () => this.setState({ showJumpToTime: true });

  _closeJumpToTime = () => this.setState({ showJumpToTime: false });

  _onJumpToTime = absoluteTime => {
    const time = this.props.date ? absoluteTime - this.props.date : absoluteTime;

    /* Return time in seconds */
    this.props.onJumpToTime(time / 1000);
  };

  render() {
    const { displayTimezoneName, date } = this.props;
    const value = this.getOverlayDate();
    const duration = this.props.getVideoDuration();
    const hasDate = !!date;
    const convertedStart = hasDate
      ? DateTime.fromJSDateWithTZ(date, { timeZone: displayTimezoneName })
      : 0;
    const convertedEnd = hasDate
      ? DateTime.fromJSDateWithTZ(date + duration * 1000, {
          timeZone: displayTimezoneName,
        })
      : duration * 1000;
    return (
      <div style={overlayStyles}>
        <IconButton
          onClick={this._openJumpToTime}
          disabled={!isNumber(value)}
          data-testid="CalendarIcon"
        >
          <Icon size="small" iconName="date_range" color="white" style={{ cursor: 'pointer' }} />
        </IconButton>
        <DateOverlay
          ref={node => (this.overlay = node)}
          date={date}
          displayTimezoneName={displayTimezoneName}
        />
        <JumpToTimeDialog
          value={value}
          start={convertedStart}
          end={convertedEnd}
          hasDate={hasDate}
          open={this.state.showJumpToTime}
          onClose={this._closeJumpToTime}
          onConfirm={this._onJumpToTime}
          displayTimezoneName={displayTimezoneName}
        />
      </div>
    );
  }
}

export default VideoPlayerDateInfo;
