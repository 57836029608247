import React from 'react';
import { styled } from 'react-jss';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as AuthSelectors from 'auth/redux/selectors';

import { useLogin } from './LoginContext';
import Signin from './Signin';
import OpenIDButton from './common/OpenIDButton';

const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
}));

const SignInForm = ({ onSubmit: passedOnSubmit, ...rest }) => {
  const { onSubmit } = useLogin();
  const location = useLocation();
  const OIDC_ENABLED = useSelector(AuthSelectors.selectOIDCEnabled);
  const showAdminSignin = location.pathname.includes('admin');
  const renderDefaultSignin = !OIDC_ENABLED || showAdminSignin;

  return (
    <Form
      name="signin-form"
      onSubmit={e => {
        e.preventDefault();
        onSubmit(e);
        if (passedOnSubmit) passedOnSubmit(e);
      }}
      {...rest}
    >
      {renderDefaultSignin ? <Signin /> : <OpenIDButton />}
    </Form>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default SignInForm;
