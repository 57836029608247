export function parseContentRange(headers) {
  let contentRange = headers && headers['content-range'];
  if (!contentRange) {
    return {
      start: null,
      end: null,
      total: null,
    };
  }

  contentRange = contentRange.replace('count ', '');

  const [range, total] = contentRange.split('/');
  const [start, end] = range.split('-');

  return {
    start: Number(start),
    end: Number(end),
    total: Number(total),
  };
}
