import { useEffect } from 'react';
import { PREFIX } from 'common/constants/pageTitles';

const usePageTitle = pageTitle => {
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);
};

export const useMiragePageTitle = pageTitle => usePageTitle(`${PREFIX}${pageTitle}`);

export default usePageTitle;
