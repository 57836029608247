import React from 'react';
import { useSelector } from 'react-redux';
import { styled, createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { selectIsNavExpanded } from 'app/redux/selectors';

import Tooltip from 'common/components/base/Tooltip';
import Typography from 'components/Typography';
import MenuItem from 'common/components/base/MenuItem';

import { SIDEBAR_WIDTH, SIDEBAR_EXPANDED_WIDTH } from 'common/constants/app';

import { SECONDARY } from 'common/constants/colors';

const MenuItemWrapper = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  width: SIDEBAR_EXPANDED_WIDTH,
  paddingLeft: 0,
});

const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: SIDEBAR_WIDTH,
});

const useStyles = createUseStyles({
  selected: {
    backgroundColor: `${SECONDARY} !important`,
  },
  label: {
    color: 'white',
  },
});

function isSelectedPath(path) {
  const [hashWithoutParams] = window.location.hash.split('?');
  const splitHash = hashWithoutParams.split('/');
  const splitPath = path.split('/');

  return splitHash[1] === splitPath[1];
}

const SidebarNavItem = ({ path, Icon, id, label, ...rest }) => {
  const classes = useStyles();
  const isNavExpanded = useSelector(selectIsNavExpanded);

  return (
    <Link
      id={id}
      key={path}
      to={path}
      data-testid={`sidebar-${label.split(' ').join('-')}`}
      {...rest}
    >
      <MenuItemWrapper
        selected={isSelectedPath(path)}
        customClasses={{ selected: classes.selected }}
      >
        <Tooltip disableHoverListener={isNavExpanded} placement="right" title={label}>
          <div data-testid={`sidebar-icon-wrapper-${label.split(' ').join('-')}`}>
            <IconContainer>
              <Icon style={{ color: 'white', fontSize: 20 }} />
            </IconContainer>
          </div>
        </Tooltip>
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      </MenuItemWrapper>
    </Link>
  );
};

export default SidebarNavItem;
