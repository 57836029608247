import React from 'react';

import { REGENT_GREY } from 'common/constants/colors';

export default ({ style: { color = REGENT_GREY, width = 20, height = width } = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        d="M12.02 21.09H5.199v-1.354l3.22-3.431c.442-.483.769-.905.98-1.265.213-.36.319-.702.319-1.025 0-.442-.112-.79-.335-1.043-.223-.253-.542-.38-.957-.38-.447 0-.799.155-1.056.462-.258.308-.387.712-.387 1.214H5c0-.607.145-1.16.434-1.662a3.06 3.06 0 0 1 1.227-1.179C7.19 11.142 7.79 11 8.46 11c1.025 0 1.822.246 2.39.738.567.492.85 1.187.85 2.085 0 .492-.127.994-.383 1.504-.255.51-.692 1.105-1.312 1.784L7.74 19.497h4.28v1.593zM16.13 15.9l1.244-2.208h2.112l-2.105 3.623 2.194 3.774h-2.119l-1.32-2.324-1.312 2.324h-2.126l2.195-3.774-2.099-3.623h2.12l1.216 2.208zM12.5 5.888l-5 3.182v-7l5 3.182V2.07l5.5 3.5-5.5 3.5V5.888z"
      />
    </g>
  </svg>
);
