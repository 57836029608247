import React from 'react';
import injectSheet from 'react-jss';
import Icon from 'common/components/base/Icon';
import Fab from 'common/components/base/Fab';
import NOOP from 'lodash/noop';
import classnames from 'classnames';

import { DARK_BORDER, SECONDARY } from 'common/constants/colors';

const TRANSITION_DURATION = 500;
/* convert into seconds */
const TRANSITION_DURATION_STYLE = `all ${TRANSITION_DURATION / 1000}s`;

const iconStyles = {
  position: 'absolute',
  top: 12,
  right: -12,
  zIndex: 20,
};

const styles = {
  main: {
    position: 'relative',
    width: 'min-content',
    borderRight: `1px solid ${DARK_BORDER}`,
  },
  contentWrapper: {
    position: 'relative',
    height: '100%',
    transition: TRANSITION_DURATION_STYLE,
    width: props => props.openWidth,
    maxWidth: props => props.openWidth,
    overflow: 'hidden',
    opacity: 1,
  },
  content: {
    width: props => props.openWidth,
    minWidth: props => props.openWidth,
    height: '100%',
  },
  iconWrapper: iconStyles,
  icon: {
    transition: TRANSITION_DURATION_STYLE,
  },
};

/**
 * [CollapsibleDrawer] - DEPRECATED
 *
 * If you need a collapsible drawer/sidebar please look at common/components/Sidebar.jsx
 */

class CollapsableDrawer extends React.Component {
  static defaultProps = {
    openWidth: 240,
    closedWidth: 16,
    onFinishClose: NOOP,
    onFinishOpen: NOOP,
  };

  componentDidUpdate(prevProps) {
    /* 500ms is the duration of the transition */
    if (!this.props.open && prevProps.open) {
      setTimeout(this.props.onFinishClose, TRANSITION_DURATION);
    } else if (this.props.open && !prevProps.open) {
      setTimeout(this.props.onFinishOpen, TRANSITION_DURATION);
    }
  }

  renderIcon = () => {
    const style = this.props.open ? { transform: 'rotate(-180deg)' } : {};
    return (
      <Fab
        variant="fab"
        size="small"
        onClick={this.props.onToggle}
        className={this.props.classes.iconWrapper}
      >
        <Icon
          iconName="chevron_right"
          color={SECONDARY}
          style={style}
          className={this.props.classes.icon}
        />
      </Fab>
    );
  };

  render() {
    const { classes, children, closedWidth, className, style } = this.props;
    const contentStyle = this.props.open
      ? {}
      : { width: closedWidth, maxWidth: closedWidth, opacity: 0 };

    const icon = this.props.renderIcon
      ? this.props.renderIcon({ style: iconStyles })
      : this.renderIcon();

    return (
      <div
        className={classnames(classes.main, className)}
        style={style}
        data-testid="CollapsableDrawer"
      >
        {icon}
        <div className={classes.contentWrapper} style={contentStyle}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(CollapsableDrawer);
