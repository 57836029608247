import axios from 'axios.js';
import { ANALYTICS_BACKEND } from 'common/constants/urls';
import { parseContentRange } from 'common/api/utils';

export const getFolders = params =>
  axios.get(`${ANALYTICS_BACKEND}/dsfolders/`, { params }).then(({ data }) => data);

export const getSingleFolder = id =>
  axios.get(`${ANALYTICS_BACKEND}/dsfolders/${id}/`).then(({ data }) => data);

export const getItemsForSingleFolder = (id, params) =>
  axios
    .get(`${ANALYTICS_BACKEND}/dsfolders/${id}/items/`, { params })
    .then(({ data, headers }) => ({ data, range: parseContentRange(headers) }));

export const deleteFolder = id =>
  axios.delete(`${ANALYTICS_BACKEND}/dsfolders/${id}/`).then(() => id);

export const patchFolder = (id, body) =>
  axios.patch(`${ANALYTICS_BACKEND}/dsfolders/${id}/`, body).then(({ data }) => data);

export const postFolder = folder =>
  axios.post(`${ANALYTICS_BACKEND}/dsfolders/`, folder).then(({ data }) => data);
