import axios from 'axios.js';

import { ANALYTICS_BACKEND } from 'common/constants/urls';

export function getImageDetails(params) {
  return axios.get(`${ANALYTICS_BACKEND}/images/`, { params }).then(({ data }) => data);
}

export function getInspectModeImageDetections(imageId) {
  return axios.get(`${ANALYTICS_BACKEND}/images/${imageId}/detections/`).then(({ data }) => data);
}

export function getRelatedImages({ id, sortBy, offset, limit, backward, rid }) {
  const params = {
    rid,
    offset,
    limit,
    backward,
    sort_by: sortBy,
  };
  return axios
    .get(`${ANALYTICS_BACKEND}/images/${id}/related/`, { params })
    .then(({ data }) => data);
}

export function getRelatedDetectionImages({ id, sortBy, rid }) {
  const params = {
    all_with_detections: true,
    rid,
    sort_by: sortBy,
  };

  return axios
    .get(`${ANALYTICS_BACKEND}/images/${id}/related/`, { params })
    .then(({ data }) => data);
}
