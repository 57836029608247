import { makeStyles } from 'tss-react-local';
import { PRIMARY } from 'common/constants/colors';

import ToolbarButton from './ToolbarButton';

export const DATE = 'date';
export const YEAR = 'year';
export const TIME = 'time';

const useStyles = makeStyles()({
  toolbar: {
    background: PRIMARY,
    display: 'flex',
    alignItems: 'flex-end',
    padding: 12,
    justifyContent: 'space-between',
    minWidth: 256,
  },
  separator: {
    margin: '0 4px 0 2px',
    cursor: 'default',
  },
});

const PickerToolbar = ({ date, openView, onViewChange }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.toolbar}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ToolbarButton
          variant="body2"
          onClick={() => onViewChange(YEAR)}
          selected={openView === YEAR}
          label={date.year}
          dataTestId="PickerToolbar-year"
        />
        <ToolbarButton
          variant="h2"
          onClick={() => onViewChange(DATE)}
          selected={openView === DATE}
          label={date.toFormat('LLL dd')}
          dataTestId="PickerToolbar-date"
        />
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ToolbarButton
          variant="h2"
          onClick={() => onViewChange(TIME)}
          selected={openView === TIME}
          label={date.toFormat('HH:mm:ss')}
          dataTestId="PickerToolbar-time"
        />
      </div>
    </div>
  );
};

export default PickerToolbar;
