export const REPORT_NAME_MAX_LENGTH = 64;
export const SCENE_TITLE_MAX_LENGTH = 128;
export const SCENE_NOTES_MAX_LENGTH = 1024;

export const REPORT_NAME_LABEL = 'Report Name';
export const REPORT_NAME_ERROR_LABEL = 'Report name';
export const SCENE_TITLE_LABEL = 'Scene Title';
export const SCENE_TITLE_ERROR_LABEL = 'Scene title';
export const SCENE_NOTES_LABEL = 'Scene Notes';
export const SCENE_NOTES_ERROR_LABEL = 'Scene notes';
