import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AvatarIcon = props => (
  <SvgIcon viewBox="0 0 27 27" {...props}>
    <g fillRule="nonzero">
      <path d="M13.5 26.89c7.37 0 13.39-5.983 13.39-13.39C26.89 6.093 20.87.11 13.5.11 6.13.11.11 6.13.11 13.5c0 7.37 6.02 13.39 13.39 13.39zm0-25.321c6.568 0 11.93 5.363 11.93 11.93a11.84 11.84 0 0 1-2.808 7.663c-2.482-2.992-5.693-4.634-9.122-4.634-3.43 0-6.64 1.642-9.122 4.634A11.84 11.84 0 0 1 1.57 13.5c0-6.568 5.363-11.931 11.93-11.931h.001z" />
      <path d="M13.5 14.704c2.846 0 5.18-2.334 5.18-5.18 0-2.847-2.334-5.181-5.18-5.181-2.846 0-5.18 2.335-5.18 5.18 0 2.846 2.334 5.181 5.18 5.181z" />
    </g>
  </SvgIcon>
);

export default AvatarIcon;
