import React from 'react';
import classnames from 'classnames';

import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';

import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';

import useStyles from './styles';

const TableRow = ({
  addPaddingLeft,
  customClasses,
  enableExpanded,
  hideRowBorders,
  prepareRow,
  renderExpandableContent,
  row,
  size,
}) => {
  const classes = useStyles();
  // Required for react-table to internally prepare the props and rendering for the row itself
  prepareRow(row);
  return (
    <React.Fragment>
      <MuiTableRow
        className={classnames(customClasses.row, {
          [classes.extraPadding]: addPaddingLeft,
        })}
        size={size}
        {...row.getRowProps()}
      >
        {enableExpanded && (
          <MuiTableCell
            size={size}
            className={classnames(classes.expandCell, {
              [classes.noBorder]: !!renderExpandableContent || hideRowBorders,
            })}
          >
            <IconButton size={size} onClick={() => row.toggleRowExpanded(!row.isExpanded)}>
              <Icon iconName={row.isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
            </IconButton>
          </MuiTableCell>
        )}
        {row.cells.map(cell => {
          const cellProps = cell.getCellProps();

          return (
            <MuiTableCell
              className={classnames(customClasses.cell, classes.cell, {
                [classes.noBorder]: !!renderExpandableContent || hideRowBorders,
              })}
              size={size}
              {...cellProps}
            >
              {cell.render('Cell')}
            </MuiTableCell>
          );
        })}
      </MuiTableRow>
      <MuiTableRow size={size}>
        {renderExpandableContent ? (
          <MuiTableCell
            colSpan={row.cells.length}
            style={{ padding: 0, width: '100%' }}
            size={size}
          >
            <Collapse className={classes.fullWidth} in={row.isExpanded}>
              {renderExpandableContent({ row, size, prepareRow })}
            </Collapse>
          </MuiTableCell>
        ) : null}
      </MuiTableRow>
    </React.Fragment>
  );
};

export default TableRow;
