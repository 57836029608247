import React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { TEST_VIDEO_SIDE_BAR_TOGGLE_INSPECT_MODE } from '__testSetup__/constants';

import { selectIsManualIdentityDrawingEnabled } from 'settings/redux/selectors';

import EyeIcon from 'common/icons/eye';
import EyePencilIcon from 'common/icons/eye-pencil';
import Icon from 'common/components/base/Icon';
import Slider from 'common/components/base/Slider';
import { HEADER_HEIGHT } from 'common/components/videoPlayer/VideoPlayerHeader';
import { SIDEBAR_WIDTH } from 'common/constants/app';
import { DARK_BORDER } from 'common/constants/colors';

import SidebarButton, { SIDEBAR_BUTTON_HEIGHT } from './SidebarButton';

const OPTIONS_BUTTON_HEIGHT = 38;

const styles = {
  sidebarWrapper: {
    zIndex: 10,
    display: props => (props.hideSidebar ? 'none' : 'flex'),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    minWidth: SIDEBAR_WIDTH,
    height: '100%',
    background: 'white',
    borderBottom: `1px solid ${DARK_BORDER}`,
    borderTop: `1px solid ${DARK_BORDER}`,
  },
  sliderWrapper: {
    display: props =>
      props.inspecting && props.showConfidenceSlider && !props.inspectFrames ? 'flex' : 'none',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    marginBottom: 12,
    height: 160,
    fontSize: 13,
  },
  optionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  optionsIcon: {
    height: OPTIONS_BUTTON_HEIGHT,
    transition: 'transform 0.7s',
    margin: 4,
  },
  optionsDrawer: {
    width: '100%',
    transition: 'height 0.7s',
    borderRadius: 4,
    overflow: 'hidden',
    bottom: OPTIONS_BUTTON_HEIGHT,
  },
  optionsList: {
    minHeight: 224,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inspectButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: HEADER_HEIGHT,
    width: '100%',
  },
};

class SidebarBase extends React.Component {
  static defaultProps = {
    enableSeek: true,
    inspectFrames: false,
    isFrameInspection: false,
    options: [],
  };

  state = {
    isPanelExpanded: false,
  };

  handleSliderMove = (e, number) => {
    /*
      by request, hiding all detections (e.g. 100% confidence threshold) is strange
      and unintuitive if you are trying to inspect, so we are limiting the threshold to
      99% instead
    */
    const value = number > 99 ? 99 : number;

    this.props.onConfidenceThresholdChange(value);
  };

  onToggleExpand = () => this.setState(state => ({ isPanelExpanded: !state.isPanelExpanded }));

  renderInspect = () => {
    const { classes, inspecting, isManualIdentityDrawingEnabled, showAllDetections } = this.props;

    const tooltip = `${inspecting ? 'Hide' : 'Reveal'} additional${
      showAllDetections ? '' : ' face'
    } detections`;

    const enableInspectButton = () => (inspecting ? !this.props.inspectFrames : false);

    const isSelected = this.props.isFrameInspection ? enableInspectButton() : inspecting;

    return (
      <SidebarButton
        className={classes.inspectButton}
        isSelected={isSelected}
        onClick={this.props.toggleInspectMode}
        tooltip={tooltip}
        {...TEST_VIDEO_SIDE_BAR_TOGGLE_INSPECT_MODE}
      >
        {({ style }) =>
          isManualIdentityDrawingEnabled ? (
            <EyePencilIcon style={style} />
          ) : (
            <EyeIcon style={style} />
          )
        }
      </SidebarButton>
    );
  };

  renderSlider = () => (
    <div className={this.props.classes.sliderWrapper}>
      <Slider
        disabled={!this.props.inspecting}
        min={0}
        max={100}
        onChange={this.handleSliderMove}
        orientation="vertical"
        step={1}
        style={{ marginBottom: 4 }}
        value={this.props.confidenceThreshold}
      />
      <span>{`${this.props.confidenceThreshold}%`}</span>
    </div>
  );

  renderOptionsPanel = () => {
    const { classes, options } = this.props;

    if (!options.length) return null;

    const expandedPanelHeight = options.length * SIDEBAR_BUTTON_HEIGHT;

    // These calculations and inline styles are used to animate the transition
    // from collapsed to expanded panel
    const containerStyle = {
      height: this.state.isPanelExpanded ? expandedPanelHeight : 0,
    };

    const iconStyle = {
      transform: this.state.isPanelExpanded ? 'rotate(180deg)' : '',
    };

    return (
      <div className={classes.optionsWrapper}>
        <div className={classes.optionsDrawer} style={containerStyle}>
          <div className={classes.optionsList}>{options.map(this.renderOptionButton)}</div>
        </div>
        <SidebarButton
          iconClassName={classes.optionsIcon}
          isSelected={this.state.isPanelExpanded}
          onClick={this.onToggleExpand}
          tooltip="Additional Video Playback Options"
          style={iconStyle}
        >
          {({ style }) => <Icon iconName="settings" color={style.color} />}
        </SidebarButton>
      </div>
    );
  };

  renderOptionButton = (option, i) => (
    <SidebarButton
      key={i}
      disabled={option.disabled}
      isSelected={option.isSelected}
      onClick={option.onClick}
      tooltip={option.tooltip}
    >
      {({ style }) => <option.Icon style={style} />}
    </SidebarButton>
  );

  render() {
    const { classes, disabled, showConfidenceSlider } = this.props;

    if (disabled) {
      return <div className={classes.sidebarWrapper} />;
    }

    return (
      <div className={classes.sidebarWrapper}>
        {this.renderInspect()}
        <div style={{ width: '100%' }}>
          {showConfidenceSlider && this.renderSlider()}
          {this.renderOptionsPanel()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isManualIdentityDrawingEnabled: selectIsManualIdentityDrawingEnabled(state),
});

export default compose(connect(mapStateToProps), injectSheet(styles))(SidebarBase);
