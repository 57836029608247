/* eslint-disable import/no-cycle */

import { isEqual } from 'lodash';

import {
  patchCamera,
  postNetworkScan,
  postCameraZone,
  patchCameraZone,
} from 'common/api/cameraApi';
import { defaultFetchCameras, defaultEditCamera } from 'common/redux/models/actions';
import { selectAllCameras } from 'library/redux/camera/selectors';

const setAllCameras = data => ({
  type: 'LIBRARY/CAMERA/SET_ALL_DATA',
  payload: data,
});

export const toggleEditMode = (data = {}) => ({
  type: 'LIBRARY/CAMERA/TOGGLE_EDIT_MODE',
  payload: data,
});

/* ========================= THUNK ACTION CREATORS ========================== */

export const fetchCamerasLibrary = () => (dispatch, getState) =>
  dispatch(defaultFetchCameras()).then(data => {
    const currIds = selectAllCameras(getState());
    const cameraIds = data.map(({ id }) => id);

    if (!isEqual(currIds, cameraIds)) {
      dispatch(setAllCameras(cameraIds));
    }

    return data;
  });

export const editCamera = camera => async dispatch => {
  const editedCamera = await dispatch(defaultEditCamera(camera));

  return editedCamera;
};

export const updateCameraZones = ({ cameraId, zones }) => dispatch => {
  const updatedCamera = {
    id: cameraId,
    zones: zones
      ? zones.map(zone => ({
          zone: zone.map(([x, y]) => ({ point: { x, y } })),
        }))
      : null,
  };

  return dispatch(editCamera(updatedCamera));
};

export const createCameraZone = ({ cameraId, zone, name }) => async () => {
  const polygon = { zone };
  const payload = { camera: cameraId, name, polygon };
  const newCamera = await postCameraZone(payload);
  return newCamera;
};

export const editCameraZone = ({ currentlyEditingZone, name, zone }) => async () => {
  const polygon = { zone };
  const payload = { ...currentlyEditingZone, polygon, name };

  const editedCamera = await patchCameraZone(payload.id, payload);
  return editedCamera;
};

export const setRecordingState = ({ cameraId, action }) => dispatch =>
  patchCamera(cameraId, { action }).then(() => dispatch(fetchCamerasLibrary()));

export function createNetworkScan({ networkNumber, logins, ports }) {
  if (!networkNumber.endsWith('.')) {
    networkNumber = networkNumber.concat('.');
  }

  const body = { networkNumber, logins, ports };

  return () => postNetworkScan(body);
}
