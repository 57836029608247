import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { debounce, noop } from 'lodash';

import { makeStyles } from 'tss-react-local';
import { frontendZones } from 'analysis/redux/utils';
import { DEBOUNCE_WAIT, ZONE_STYLES } from 'common/components/videoPlayer/constants';
import { selectIsEditModeEnabled, selectZoneBeingEdited } from 'library/redux/camera/selectors';
import { useCameraZones } from 'library/libraryBody/cameraView/CameraZonesContext';

import { getTooltipText } from './utils';

import Polygon from './Polygon';
import Rectangle from './Rectangle';
import DrawingConfirmationDialog from './DrawingConfirmationDialog';
import DrawingTooltip from './DrawingTooltip';
import { useZones } from './ZonesContext';

const { shadowOffsetX, shadowOffsetY, shadowBlur, shadowColor } = ZONE_STYLES;

const useStyles = makeStyles()({
  root: {
    alignItems: 'center',
    display: 'flex',
    left: 0,
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 30,
  },

  region: {
    position: 'relative',

    '&.drawing': {
      cursor: 'crosshair',
    },
  },
});

const DrawingOverlay = ({
  aspectRatio,
  className,
  // TODO: Add support for these config props
  // multiple = true,
  mode = DrawingOverlay.MODES.POLYGON,
  // fidelity = 4
  onComplete = noop,
  tooltipStrings,
  liveZones,
  zoom = 100,
}) => {
  const { classes, cx } = useStyles();
  const { zones, areZonesComplete, hovering: hoveringPoint, add: addPoint } = useZones();
  const {
    normalizedZones,
    hoverMenuZone,
    isZonesSidebarExpanded,
    zonesCameraId,
  } = useCameraZones();
  const editModeEnabled = zonesCameraId == null || useSelector(selectIsEditModeEnabled);
  const zoneBeingEdited = useSelector(selectZoneBeingEdited);

  const rootRef = useRef(null);
  const regionRef = useRef(null);

  const [isRegionHovering, setIsRegionHovering] = useState(false);
  const [mouseOffset, setMouseOffset] = useState([0, 0]);
  const [mousePosition, setMousePosition] = useState([0, 0]);
  const [originalRegionDimension, setOriginalRegionDimension] = useState({
    width: 0,
    height: 0,
  });

  const updateOriginalRegionDimension = useCallback(() => {
    if (
      !rootRef.current ||
      !regionRef.current ||
      editModeEnabled === undefined ||
      isZonesSidebarExpanded === undefined
    ) {
      return;
    }

    const rootRect = rootRef.current.getBoundingClientRect();
    const originalRootDimension = {
      width: (rootRect.width * 100) / zoom,
      height: (rootRect.height * 100) / zoom,
    };
    const rootAspectRatio = rootRect.width / rootRect.height;

    if (aspectRatio >= rootAspectRatio) {
      setOriginalRegionDimension({
        width: originalRootDimension.width,
        height: originalRootDimension.width / aspectRatio,
      });
    } else if (aspectRatio < rootAspectRatio) {
      setOriginalRegionDimension({
        width: originalRootDimension.height * aspectRatio,
        height: originalRootDimension.height,
      });
    }
  }, [aspectRatio, zoom, editModeEnabled, isZonesSidebarExpanded]);

  const handleRegionMouseEnter = () => {
    setIsRegionHovering(true);
  };

  const handleRegionMouseLeave = () => {
    setIsRegionHovering(false);
  };

  const nonZeroDimensions =
    originalRegionDimension?.width !== 0 || originalRegionDimension?.height !== 0;
  const percentToPixelScaleX = d3
    .scaleLinear()
    .domain([0, 1])
    .range([0, originalRegionDimension.width]);
  const percentToPixelScaleY = d3
    .scaleLinear()
    .domain([0, 1])
    .range([0, originalRegionDimension.height]);

  // Get zoomed offset in region of mouse pointer
  const getOriginalOffsetOfPointer = ([clientX, clientY]) => {
    const { left, top } = regionRef?.current?.getBoundingClientRect();
    const offsetX = ((clientX - left) * 100) / zoom;
    const offsetY = ((clientY - top) * 100) / zoom;

    return [offsetX, offsetY];
  };

  // Handle adding point
  const handleRegionClick = e => {
    e.preventDefault();

    // Return if clicking vertex point to avoid add + complete actions firing simultaneously
    if (!editModeEnabled || e.target.tagName === 'circle') {
      return;
    }

    const { clientX, clientY } = e;
    const [offsetX, offsetY] = getOriginalOffsetOfPointer([clientX, clientY]);
    // Converts pixel coordinates to percent coordinates relative to
    // drawing canvas.
    addPoint({
      point: [percentToPixelScaleX.invert(offsetX), percentToPixelScaleY.invert(offsetY)],
    });
  };

  const handleRegionMouseMove = e => {
    const { clientX, clientY } = e;
    if (!isRegionHovering) {
      setIsRegionHovering(true);
    }

    setMousePosition([clientX, clientY]);
    setMouseOffset(getOriginalOffsetOfPointer([clientX, clientY]));
  };

  const cameraZonesInPixel = nonZeroDimensions
    ? normalizedZones.map(zone =>
        zone.map(([x, y]) => [percentToPixelScaleX(x), percentToPixelScaleY(y)])
      )
    : [];

  const zonesInPixel = nonZeroDimensions
    ? zones.map(zone => zone.map(([x, y]) => [percentToPixelScaleX(x), percentToPixelScaleY(y)]))
    : [];

  const highlightedZone =
    !editModeEnabled && hoverMenuZone?.id
      ? frontendZones([hoverMenuZone]).map(zone =>
          zone.map(([x, y]) => [percentToPixelScaleX(x), percentToPixelScaleY(y)])
        )
      : [];

  const zoneBeingEditedCoordinates =
    zoneBeingEdited?.id && zones?.length === 0
      ? frontendZones([zoneBeingEdited]).map(zone =>
          zone.map(([x, y]) => [percentToPixelScaleX(x), percentToPixelScaleY(y)])
        )
      : [];

  const tooltipText = getTooltipText({
    zones: zonesInPixel,
    areZonesComplete,
    hoveringPoint,
    tooltipStrings,
  });

  // Update dimension if window is resized
  useEffect(() => {
    const debouncedUpdateOriginalRegionDimension = debounce(() => {
      updateOriginalRegionDimension();
    }, DEBOUNCE_WAIT);

    debouncedUpdateOriginalRegionDimension();

    window.addEventListener('resize', debouncedUpdateOriginalRegionDimension);

    return () => {
      window.removeEventListener('resize', debouncedUpdateOriginalRegionDimension);
    };
  }, [updateOriginalRegionDimension]);

  return (
    <>
      <DrawingConfirmationDialog zonesCameraId={zonesCameraId} />
      <div ref={rootRef} className={cx(classes.root, className)} data-testid="DrawingOverlay-root">
        <div
          ref={regionRef}
          role="region"
          className={cx(classes.region, { drawing: editModeEnabled })}
          style={{
            width: originalRegionDimension.width,
            height: originalRegionDimension.height,
          }}
          onClick={handleRegionClick}
          onMouseEnter={handleRegionMouseEnter}
          onMouseLeave={handleRegionMouseLeave}
          onMouseMove={handleRegionMouseMove}
        >
          <svg
            fill="none"
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              zIndex: 9,
            }}
          >
            <filter id="boxShadow">
              <feDropShadow
                dx={shadowOffsetX}
                dy={shadowOffsetY}
                stdDeviation={shadowBlur}
                floodColor={shadowColor}
              />
            </filter>

            {mode === DrawingOverlay.MODES.POLYGON && (
              <Polygon
                data={zonesInPixel}
                cameraData={cameraZonesInPixel}
                mouseOffset={mouseOffset}
                editModeEnabled={editModeEnabled}
                highlightedZone={highlightedZone}
                zoneBeingEditedCoordinates={zoneBeingEditedCoordinates}
                liveZones={liveZones}
              />
            )}
            {mode === DrawingOverlay.MODES.RECTANGLE && (
              <Rectangle
                data={zonesInPixel}
                dimension={originalRegionDimension}
                mouseOffset={mouseOffset}
                onComplete={onComplete}
                zoom={zoom}
              />
            )}
          </svg>

          {editModeEnabled && isRegionHovering && tooltipText && (
            <DrawingTooltip
              title={tooltipText}
              style={{
                left: mousePosition[0],
                top: mousePosition[1],
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

DrawingOverlay.MODES = {
  POLYGON: 'polygon',
  RECTANGLE: 'rectangle',
};

export default DrawingOverlay;
