import store from 'redux/store';
import { compact } from 'lodash';
import { TEXT_OPERATORS } from 'common/constants/operators';
import { identityRowsHasMultipleRows } from 'common/helpers/searchQueryUtils';
import { selectObjectModels, selectPersonModels } from 'common/redux/models/selectors';

function areIdentityRowsEmpty(identityRows) {
  return identityRows.every(({ identities }) => !identities || !identities.length);
}

export default function getQueryStringFromIdentityRows(
  identityRows,
  personModels,
  objectModels,
  skipColor = false
) {
  const state = store.getState();
  personModels = personModels || selectPersonModels(state);
  objectModels = objectModels || selectObjectModels(state);

  let queryString = '';
  if (areIdentityRowsEmpty(identityRows)) return queryString;

  const hasMultipleRows = identityRowsHasMultipleRows(identityRows);

  identityRows.forEach(({ identities, groupOperator }, idx) => {
    if (!identities || !identities.length) return;

    const rowHasMultipleItems = identities.length > 1;
    if (rowHasMultipleItems && hasMultipleRows) queryString += '(';

    compact(identities).forEach(({ id, color, operator, object: isObject }, i) => {
      // Since query relies on person name, get name from current person object
      const name = isObject ? objectModels[id]?.name : personModels[id]?.name;

      if (isObject) {
        if (!skipColor && color) {
          queryString += `:${name.toLowerCase()}.${color}`;
        } else {
          queryString += `:${name.toLowerCase()}`;
        }
      } else {
        queryString += name;
      }

      if (i !== identities.length - 1) {
        queryString += TEXT_OPERATORS[operator];
      }
    });

    if (rowHasMultipleItems && hasMultipleRows) queryString += ')';
    if (idx !== identityRows.length - 1) {
      queryString += TEXT_OPERATORS[groupOperator];
    }
  });

  return queryString;
}
