import React from 'react';
import { createUseStyles } from 'react-jss';

import { ACCENT, PRIMARY_GREY, LIGHT_ACCENT } from 'common/constants/colors';

const useStyles = createUseStyles({
  header: {
    fontSize: 13,
    display: 'flex',
    paddingLeft: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderTop: `1px solid ${ACCENT}`,
    borderBottom: `1px solid ${ACCENT}`,
    background: LIGHT_ACCENT,
    minHeight: 38,
  },
  span: {
    display: 'flex',
  },
  dark: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
  },
  icon: {
    color: PRIMARY_GREY,
    fontWeight: 500,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    minHeight: 32,
    cursor: 'pointer',
  },
});

const ProcessingHeader = ({ onClick, buttonText, children }) => {
  const classes = useStyles();
  return (
    <header className={classes.header} data-testid="GlobalProcessingPanel-header">
      <span className={classes.span}>
        <div className={classes.dark}>{children}</div>
      </span>
      <div
        role="button"
        tabIndex="0"
        onClick={onClick}
        className={classes.icon}
        data-testid="GlobalProcessingPanel-close"
      >
        {buttonText}
      </div>
    </header>
  );
};

export default ProcessingHeader;
