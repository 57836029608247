/**
 * modeled the api after https://www.npmjs.com/package/query-string
 * but they handle the sharp/hash/octothorp (#) differently than we need
 */

const parseQuery = str => {
  if (str == null) return {};
  const queries = str.split('&');
  return queries.reduce((acc, q) => {
    const [key, value] = q.split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
};

export const parseUrl = str => {
  if (str == null) return {};
  const [url, queryPart] = str.split('?');

  return {
    url,
    query: parseQuery(queryPart),
  };
};

export const parse = str => parseUrl(str).query ?? {};

export const stringify = queryObj =>
  Object.entries(queryObj)
    .flatMap(q => (q[1] != null ? q.join('=') : []))
    .join('&');

export const stringifySearch = queryObj => `?${stringify(queryObj)}`;

export const stringifyUrl = ({ url, query }) => [url, stringify(query)].join('?');
