import React from 'react';
import { styled } from 'react-jss';
import Typography from 'components/Typography';
import { useMission } from './MissionContext';

const Root = styled('div')({ display: 'flex' });
const Box = styled('div')({ alignSelf: 'center' });

const Sighting = ({ data }) => {
  const { id, thumbUrl, detectionSource, depth } = data;
  const { titleByDatasourceId } = useMission();

  return (
    <Root>
      <img alt="network-sighting" src={thumbUrl} key={id} />
      <Box>
        <Typography>{`Datasource: ${titleByDatasourceId(detectionSource)}`}</Typography>
        <Typography>{`Depth: ${depth}`}</Typography>
      </Box>
    </Root>
  );
};
export default Sighting;
