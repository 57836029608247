import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" {...props}>
    <path
      fill="#6599DE"
      fillRule="evenodd"
      d="M89.342 65.095l4.967 14.892-6.405 6.405-26.739-40.667c-5.285 4.96-16.33 15.211-26.583 23.054l7.206 20.979L35.545 96 23.217 76.786s-.162 0-.162.162c-.163.163-.32.163-.482.32-2.564 1.438-5.285-1.283-3.84-3.841.162-.163.162-.32.319-.482 0 0 0-.162.162-.162L0 60.455l6.242-6.243 20.816 7.043c8.006-10.245 18.095-21.298 23.054-26.583L9.445 7.933l6.404-6.404 14.893 4.966 4.64-4.641c.482-.482 1.283-.482 1.602 0l2.883 2.877c.482.482.482 1.282 0 1.601l-2.401 2.402 28.658 9.77C77.651 5.694 85.976-5.032 93.508 2.492c7.524 7.524-3.203 15.85-16.012 27.384l9.77 28.658 2.239-2.564c.481-.482 1.282-.482 1.601 0l2.884 2.883c.481.482.481 1.282 0 1.601l-4.648 4.64z"
    />
  </SvgIcon>
);
