import React from 'react';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Theme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';

import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import { getTheme } from './themes';

export const LABEL_FONT_SIZE = 12;

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    fontWeight: 400,
  },
  disabled: {
    filter: 'opacity(30%)',
  },
  linkVariant: {
    textTransform: 'none',
    textDecoration: 'underline',
    padding: 0,
    fontSize: 'inherit',
    lineHeight: 'normal',
    letterSpacing: 'normal',

    '&:hover': {
      textDecoration: 'underline',
      background: 'none',
    },
  },
  label: {
    fontSize: LABEL_FONT_SIZE,
  },
});

interface CustomButtonProps extends ButtonProps {
  customClasses?: {
    label?: string;
    root?: string;
  };
  isLoading?: boolean;
  link?: boolean;
  theme?: Theme;
}

const Button = React.forwardRef<HTMLButtonElement, CustomButtonProps>((props, ref) => {
  const {
    children,
    color = 'primary',
    customClasses = {},
    disabled,
    isLoading = false,
    link,
    size = 'small',
    theme,
    type = 'button',
    variant = 'contained',
    disableRipple,
    disableFocusRipple,
    ...rest
  } = props;

  const classes = useStyles();

  const { label, root, ...restClasses } = customClasses;
  const customTheme = getTheme(theme);

  const Component = (
    <MuiButton
      ref={ref}
      classes={{
        root: classnames(classes.root, root, link && classes.linkVariant),
        label: classnames(classes.label, label),
        disabled: classes.disabled,
        ...restClasses,
      }}
      color={color}
      disabled={disabled || isLoading}
      size={size}
      type={type}
      variant={link ? 'text' : variant}
      disableRipple={disableRipple !== undefined ? disableRipple : link}
      disableFocusRipple={disableFocusRipple !== undefined ? disableFocusRipple : link}
      {...rest}
    >
      {isLoading && <LoadingOverlay size={22} />}
      {children}
    </MuiButton>
  );

  if (customTheme) {
    return <ThemeProvider theme={customTheme}>{Component}</ThemeProvider>;
  }

  return Component;
});

export default Button;
