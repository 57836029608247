import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import injectSheet from 'react-jss';
import { isEmpty } from 'lodash';

import ModelTrainingIcon from 'common/icons/model-training';

import Icon from 'common/components/base/Icon';
import ColumnIcon from 'common/icons/column-layout';

import Home from 'common/icons/home';
import Public from 'common/icons/public';
import LibraryActive from 'common/icons/ic-library-active';
import LiveSearch from 'common/icons/live-monitor-inactive';
import Report from 'common/icons/report';

import { selectIsGeoActive, selectIsGeoAlertingEnabled } from 'settings/redux/selectors';
import { selectIsAuthenticated, selectHasMintGroup } from 'auth/redux/selectors';
import { selectIsNavExpanded } from 'app/redux/selectors';
import { selectSearchRequestId, selectMapConfig } from 'analysis/redux/selectors';
import { appendMapParamsToUrl } from 'analysis/analysisGeo/redux/utils';
import { toggleHamburgerMenu } from 'app/redux/actions';

import { PRIMARY } from 'common/constants/colors';
import {
  FOLDER,
  SIDEBAR_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
  ROOT_DATASOURCE_KEY,
} from 'common/constants/app';
import {
  ANALYSIS_GEO_ROUTE,
  REPORTS_ROUTE,
  PLAN_GEOSPATIAL_ROUTE,
  PLAN_ANALYSIS_ROUTE,
  ANALYSIS_ROUTE,
  LIBRARY_FMV_ROUTE,
  DEFAULT_GEO_DATASOURCE_ROUTE,
  LIVE_MONITOR_ROUTE,
  GEO_ROUTE,
  GEO_ALERTING_ROUTE,
  INCREMENTAL_TRAINING_ROUTE,
  MINT_ROUTE,
} from 'common/constants/urls';

import { TRANSITION_DURATION } from './HideableMirageLogo';

import TopSidebar from './TopSidebar';
import BottomSidebar from './BottomSidebar';

const styles = {
  main: {
    background: PRIMARY,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: SIDEBAR_WIDTH,
    overflow: 'hidden',
    transition: 'width',
    transitionDuration: TRANSITION_DURATION,
    color: 'white',
  },
  '@media print': {
    main: {
      display: 'none',
    },
  },
};

class SideBar extends React.Component {
  getViews = () => (this.props.isGeoActive ? this.getGeoViews() : this.getFmvViews());

  getFmvViews = () =>
    [
      {
        groupTitle: 'ANALYSIS',
        navItems: [
          {
            path: PLAN_ANALYSIS_ROUTE,
            Icon: Home,
            id: 'plan-view-nav-item',
            label: 'Plan Your Mission',
          },
          {
            path: this.getAnalysisPath(ANALYSIS_ROUTE),
            Icon: ColumnIcon,
            label: 'Analysis Results',
          },
          {
            path: LIVE_MONITOR_ROUTE,
            Icon: LiveSearch,
            label: 'Live Monitoring Alerts',
          },
        ],
      },
      {
        groupTitle: 'COLLECTIONS',
        navItems: [
          {
            path: `${LIBRARY_FMV_ROUTE}/${FOLDER}/${ROOT_DATASOURCE_KEY}`,
            Icon: LibraryActive,
            label: 'Datasource & Identity Library',
          },
          {
            path: REPORTS_ROUTE,
            Icon: props => <Report style={{ height: 18, ...props.style }} />,
            label: 'Saved Scenes & Reports',
          },
        ].filter(Boolean),
      },
      this.props.showMint && {
        groupTitle: `MIRAGE\u2122 MODELS`,
        navItems: [
          {
            path: MINT_ROUTE,
            Icon: ModelTrainingIcon,
            label: 'Mint',
          },
        ],
      },
    ].filter(Boolean);

  getGeoViews = () => {
    const views = [
      {
        groupTitle: 'ANALYSIS',
        navItems: [
          {
            path: PLAN_GEOSPATIAL_ROUTE,
            Icon: Public,
            id: 'plan-view-nav-item',
            label: 'Plan Your Mission',
          },
          {
            path: this.getAnalysisPath(ANALYSIS_GEO_ROUTE),
            Icon: props => <ColumnIcon style={{ height: 16, ...props.style }} />,
            label: 'Analysis Results',
          },
        ],
      },
      {
        groupTitle: 'COLLECTIONS',
        navItems: [
          {
            path: DEFAULT_GEO_DATASOURCE_ROUTE,
            Icon: LibraryActive,
            label: 'Mirage Library',
          },
          {
            path: `${REPORTS_ROUTE}${GEO_ROUTE}/all`,
            Icon: props => <Report style={{ height: 17, ...props.style }} />,
            label: 'Saved Scenes & Reports',
          },
        ],
      },
      {
        groupTitle: 'Mirage™ PRIME',
        navItems: [
          {
            path: INCREMENTAL_TRAINING_ROUTE,
            Icon: props => <Icon iconName="trending_up" theme="grey" size="large" {...props} />,
            label: 'Model Library',
          },
        ],
      },
    ];

    if (this.props.isGeoAlertingEnabled) {
      views[0].navItems.splice(1, 0, {
        path: GEO_ALERTING_ROUTE,
        Icon: props => <Icon iconName="folder_special" theme="grey" {...props} />,
        label: 'Mission Dashboard',
      });
    }

    return views;
  };

  getAnalysisPath = analysisRoute => {
    const { searchRequestId, mapConfig } = this.props;

    const analysisPath = searchRequestId
      ? `${analysisRoute}/${searchRequestId}`
      : `${analysisRoute}`;

    return isEmpty(mapConfig) ? analysisPath : appendMapParamsToUrl(analysisPath, mapConfig);
  };

  render() {
    const { classes, isAuthenticated, isNavExpanded, location } = this.props;
    const style = {};
    const showSidebar = !location.pathname.includes('/print-scene') && isAuthenticated;

    if (isNavExpanded) style.width = `${SIDEBAR_EXPANDED_WIDTH}px`;
    if (!showSidebar) return null;

    return (
      <div id="sidebar" className={classes.main} style={style}>
        <TopSidebar views={this.getViews()} />
        <BottomSidebar />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showMint: selectHasMintGroup(state),
  isGeoAlertingEnabled: selectIsGeoAlertingEnabled(state),
  isGeoActive: selectIsGeoActive(state),
  isNavExpanded: selectIsNavExpanded(state),
  isAuthenticated: selectIsAuthenticated(state),
  searchRequestId: selectSearchRequestId(state),
  mapConfig: selectMapConfig(state),
});

const mapDispatchToProps = {
  dispatchToggleHamburgerMenu: toggleHamburgerMenu,
};

export default compose(
  withRouter,
  injectSheet(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SideBar);
