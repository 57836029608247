const defaults = {
  estimate: null,
  identityRows: [{ identities: [], groupOperator: 'and' }],
  datasources: {},
  cameras: {},
  folders: {},
  filters: {
    startDate: null,
    endDate: null,
  },
  isLoadingSearchRequest: true,
  isLoadingDatasources: false,
};

export default function searchBarReducer(state = defaults, action) {
  switch (action.type) {
    case 'ANALYSIS/CLEAR_IDENTITY_ROWS': {
      return {
        ...state,
        identityRows: defaults.identityRows,
      };
    }

    case 'ANALYSIS/RESET_SEARCH_QUERY': {
      return defaults;
    }

    case 'ANALYSIS/SET_SEARCH_QUERY': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'ANALYSIS/SET_SEARCH_QUERY_ESTIMATE': {
      return {
        ...state,
        estimate: action.payload,
      };
    }

    case 'ANALYSIS/SET_IDENTITY_ROWS': {
      return {
        ...state,
        identityRows: action.payload,
      };
    }

    case 'ANALYSIS/SET_LOADING_REQUEST': {
      return {
        ...state,
        isLoadingSearchRequest: action.payload,
      };
    }

    case 'ANALYSIS/SET_LOADING_DATASOURCES': {
      return {
        ...state,
        isLoadingDatasources: action.payload,
      };
    }

    default:
      return state;
  }
}
