import { useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

import Radio from '@material-ui/core/Radio';
import Dialog from '@material-ui/core/Dialog';
import { LIGHT_GREY, WHITE, ICON_DEFAULT } from 'common/constants/colors';

import Typography from 'components/Typography';
import Button from 'common/components/base/Button';
import { serveObjectIcon } from 'common/constants/objects';
import useSharedStyles from './useSharedStyles';
import FormHeader from './FormHeader';

const useStyles = createUseStyles(theme => ({
  body: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    minHeight: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(2),
  },
  imageToProcess: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    maxWidth: theme.spacing(40),
    objectFit: 'contain',
  },
  detections: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    overflowY: 'auto',
  },
  detection: {
    position: 'relative',
    width: theme.spacing(16),
    height: theme.spacing(16),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    pointerEvents: 'none',
    opacity: 0.5,
    '&:focus': {
      outline: 'none',
    },
  },
  isSelectable: {
    cursor: 'pointer',
    pointerEvents: 'unset',
    opacity: 1,
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    overflow: 'hidden',
  },
  radio: {
    position: 'absolute',
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    borderRadius: '50%',
    display: 'flex',
    background: WHITE,
  },
  iconContainer: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    display: 'flex',
    color: WHITE,
    background: ICON_DEFAULT,
    borderRadius: '10%',
    padding: 1,
    fontSize: 12,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,
    height: theme.spacing(6),
    borderTop: `solid 1px ${LIGHT_GREY}`,
    padding: theme.spacing(1.5),
  },
}));

const iconProps = {
  fontSize: 'inherit',
};

const DetectionSelection = ({
  identity: { objectType, name, processing: { preview } = {}, detections = [] },
  open = false,
  onClose,
  onDetectionSelection,
}) => {
  const classes = { ...useSharedStyles(), ...useStyles() };

  const [selection, setSelection] = useState();

  const onSelection = useCallback(() => {
    const detection = detections.find(({ key }) => key === selection);
    return onDetectionSelection({ detections: [detection] });
  }, [detections, onDetectionSelection, selection]);

  const renderRadio = key => (
    <div className={classes.radio}>
      <Radio
        disableRipple
        checked={selection === key}
        size="small"
        style={{ padding: 0, margin: 0 }}
      />
    </div>
  );

  const renderDetection = ({ key, thumbnailUrl, k: detectionObjectType }) => {
    const isSelectable = !Number.isInteger(objectType) || detectionObjectType === objectType;
    const Icon = serveObjectIcon(detectionObjectType);

    return (
      <div
        key={key}
        className={classnames(classes.detection, { [classes.isSelectable]: isSelectable })}
        onClick={() => isSelectable && setSelection(key)}
        role="button"
        tabIndex="0"
      >
        {isSelectable && renderRadio(key)}
        <div className={classes.iconContainer}>
          <Icon {...iconProps} />
        </div>
        <img className={classes.thumbnail} alt="Select Identity" src={thumbnailUrl} />
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClick={e => e.stopPropagation()}
      classes={{ paper: classes.main }}
      fullScreen
      data-testid="UploadIdentity"
    >
      <FormHeader title="Multiple Detections" onClose={onClose} />
      <div className={classes.body}>
        <div className={classes.container}>
          <div>
            <Typography variant="body2">{name}</Typography>
            <Typography variant="subtitle2">Processing...</Typography>
          </div>
          {preview && <img className={classes.imageToProcess} alt="Preview" src={preview} />}
        </div>
        <div className={classes.container}>
          <Typography variant="subtitle2">Please select the correct detection to add</Typography>
          <div className={classes.detections}>{detections.map(renderDetection)}</div>
        </div>
      </div>
      <footer className={classes.footer}>
        <Button disabled={!selection} onClick={onSelection}>
          Select
        </Button>
      </footer>
    </Dialog>
  );
};

export default DetectionSelection;
