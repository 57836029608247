import React from 'react';
import { withStyles } from 'tss-react-local';

import { parseToShorthandDate, parseToHHMMSS } from 'common/helpers/dateUtils';

import Timeline from 'common/components/timeline/Timeline';

const styles = {
  slider: {
    marginLeft: 150,
  },
};

const timelineData = [
  1563181000000,
  1563181100000,
  1563181200000,
  1563181300000,
  1563181400000,
  1563181500000,
  1563181600000,
  1563181700000,
  1563181800000,
];

const Rows = props => {
  const { timelineStart, timelineEnd, onMouseWheel } = props;
  const timeRange = timelineEnd - timelineStart;
  const lines = timelineData.map(time => {
    if (time < timelineStart || time > timelineEnd) {
      return null;
    }

    const leftPercent = ((time - timelineStart) / timeRange) * 100;

    return (
      <span
        key={time}
        style={{
          overflow: 'hidden',
          position: 'absolute',
          width: 4,
          borderRadius: 2,
          background: 'blue',
          height: 36,
          top: 2,
          left: `${leftPercent}%`,
        }}
      />
    );
  });

  return (
    <div
      onWheel={onMouseWheel}
      style={{
        width: '100%',
        height: 40,
        background: 'pink',
        position: 'relative',
      }}
    >
      {lines}
    </div>
  );
};

const TimelineExample = () => (
  <Timeline
    formatTimeDivision={parseToShorthandDate}
    formatTimePoint={parseToHHMMSS}
    startTime={1563181000000}
    endTime={1563181800000}
  >
    {timelineProps => <Rows {...timelineProps} />}
  </Timeline>
);

export default withStyles(TimelineExample, styles);
