import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

import DraggableDialog from 'components/Dialog/DraggableDialog';
import { createThumbnailURL } from 'library/redux/utils';
import { extractMirageFormError } from 'common/helpers/apiErrorUtils';
import { useDatasourceLibrary, useDetectionPerson } from 'common/hooks/useDetections';

import { renderNotification } from 'app/redux/actions';
import PersonEmbeddingWarning from 'common/components/popups/warnings/PersonEmbeddingWarning';
import { extractAndPostPerson } from 'analysis/redux/actions';
import { MAX_EMBEDDINGS_PER_PERSON } from 'common/constants/app';
import { STATUS } from 'common/constants/api';
import * as UploadIdentity from 'common/components/popups/uploadIdentity/UploadIdentity';
import { areEqualProps } from 'common/helpers/areEqualProps';
import * as ModelSelectors from 'common/redux/models/selectors';

import DetectionPersonFilterListContent from './DetectionPersonFilterListContent';

const useStyles = createUseStyles({
  dialog: {
    height: 550,
    width: 550,
  },
});

const DetectionPersonFilterList = ({
  detection,
  dialogTitle,
  dialogSubtitle,
  embedding,
  imageId,
  onCancel = () => null,
  onClose,
  open,
  thumbnailBounds,
  timestamp,
  videoId,
  isLive,
}) => {
  const [error, setError] = useState(null);
  const [postPersonStatus, setPostPersonStatus] = useState(STATUS.IDLE);
  const [enteredName, setEnteredName] = useState('');
  const [personToWarnAbout, setPersonToWarnAbout] = useState(undefined);
  const { isPersonLoading, libraries, people } = useDetectionPerson(
    detection,
    imageId,
    open,
    videoId
  );
  const personFolders = useSelector(ModelSelectors.selectSharedPersonFolders);

  const { isDatasourceLoading, datasourceLibraryId } = useDatasourceLibrary(open, videoId, imageId);

  const personModels = useSelector(ModelSelectors.selectPersonModels);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (!open) {
      setError(null);
      setEnteredName('');
    }
  }, [open]);

  const title = dialogTitle || (detection?.isIdentityType() ? 'Identify' : 'Reassign');
  const subtitle = dialogSubtitle || 'Select an existing identity or enter a new name';

  const thumbnailUrl = createThumbnailURL({
    vid: videoId,
    imageId,
    t: timestamp,
    ...thumbnailBounds,
  });

  const onChangeName = e => {
    if (error) setError(null);
    setEnteredName(e.target.value);
  };

  const getFolderId = libraryId =>
    libraryId && personFolders.find(f => f.library === libraryId)?.id;

  const areEmbeddingsWithinLimit = p => {
    const personModel = personModels[p.id];
    if (personModel?.images?.length >= MAX_EMBEDDINGS_PER_PERSON) {
      setPersonToWarnAbout(personModel);
      return false;
    }

    return true;
  };

  const postPersonEmbedding = async ({ name, id, folderId }) => {
    const embeddingInfo = {
      iid: imageId,
      t: timestamp,
      vid: videoId,
      detection: {
        ...(thumbnailBounds && { ...thumbnailBounds }),
        ...(embedding && { e: embedding }),
        objectType: detection?.getType(),
      },
      ...(name && { name }),
      ...(id && { id }),
      ...(folderId && { folder: folderId }),
    };

    await dispatch(extractAndPostPerson(embeddingInfo));

    onClose();
  };

  const onClickCreate = async (name, libraryId) => {
    if (!areEmbeddingsWithinLimit(name)) return;

    setPostPersonStatus(STATUS.PENDING);
    try {
      await postPersonEmbedding({ name, folderId: getFolderId(libraryId) });
      setPostPersonStatus(STATUS.SUCCESS);
      renderNotification({ message: 'Detection added' });
    } catch (err) {
      /* We do not get a properly formatted error, so we will render the most likely error to occur */
      const message =
        err.response.data.message === UploadIdentity.IS_OBJ_STR_ERROR
          ? `'${name}' is reserved as an object name. Please enter a valid name.`
          : err.response.data.message.indexOf(UploadIdentity.DOES_MATCH_REGEX_ERROR) !== -1
          ? `Invalid Name: Only letters, numbers, and the following special characters _ - . are allowed.`
          : err.response.data.message.includes('value too long for type character varying(255)')
          ? `Invalid Name: name must be less than 255 characters.`
          : `Cannot add detection: ${name} already exists`;

      setPostPersonStatus(STATUS.FAILURE);
      setError(message);
    }
  };

  const onClickRow = async person => {
    if (!areEmbeddingsWithinLimit(person)) return;

    try {
      await postPersonEmbedding(person);
      renderNotification({ message: `New detection added to ${person.name}` });
    } catch (err) {
      const mirageError = extractMirageFormError(err);
      const message =
        mirageError?.message &&
        !mirageError.message.startsWith('duplicate key value violates unique constraint')
          ? mirageError.message
          : `This detection already exists in ${person.name}`;

      setError(message);
    }
  };

  return (
    <DraggableDialog
      classes={{ paper: classes.dialog }}
      dragHandleId="dragHandleTarget"
      onClose={onClose}
      open={open}
      onBackdropClick={onCancel}
      onEscapeKeyDown={onCancel}
    >
      <DetectionPersonFilterListContent
        data={people}
        datasourceLibraryId={datasourceLibraryId}
        enteredName={enteredName}
        error={error}
        isLive={isLive}
        isLoading={isPersonLoading || isDatasourceLoading}
        isPostPersonPending={postPersonStatus === STATUS.PENDING}
        libraries={libraries}
        onClickCreate={onClickCreate}
        onClickRow={onClickRow}
        onChangeName={onChangeName}
        thumbnailUrl={thumbnailUrl}
        title={title}
        subtitle={subtitle}
      />
      <PersonEmbeddingWarning
        person={personToWarnAbout}
        open={!!personToWarnAbout}
        onClose={() => setPersonToWarnAbout(undefined)}
      />
    </DraggableDialog>
  );
};

DetectionPersonFilterList.propTypes = {
  detection: PropTypes.object,
  imageId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  timestamp: PropTypes.number,
  videoId: PropTypes.string,
};

export default React.memo(DetectionPersonFilterList, areEqualProps());
