import get from 'lodash/get';

export const datasourceSortFunctions = [
  {
    label: 'Name',
    // Handle folder sorting
    action: ({ title: titleA, name: nameA }, { title: titleB, name: nameB }) =>
      titleA ? titleA.localeCompare(titleB) : nameA.localeCompare(nameB),
  },
  {
    label: 'Recently Added',
    action: ({ created: createdA = 0 }, { created: createdB = 0 }) =>
      new Date(createdB).getTime() - new Date(createdA).getTime(),
  },
  {
    label: 'Chronological',
    // Handle folder sorting
    action: ({ date: dateA = 0, name: nameA }, { date: dateB = 0, name: nameB }) =>
      nameA && nameB
        ? nameA.localeCompare(nameB)
        : new Date(dateB).getTime() - new Date(dateA).getTime(),
  },
];

export const personSortFunctions = [
  {
    label: 'Name',
    action: ({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB),
  },
  {
    label: 'Recently Added',
    action: ({ created: createdA = 0 }, { created: createdB = 0 }) =>
      new Date(createdB).getTime() - new Date(createdA).getTime(),
  },
];

export const objectSortFunctions = [
  {
    label: 'Name',
    action: (nameA, nameB) => nameA.localeCompare(nameB),
  },
];

export function geoObjectSortFunction(a, b) {
  if (!a.name) return 1;
  if (!b.name) return 0;
  return a.name.localeCompare(b.name);
}

export const desc = (a, b) => (a < b ? 1 : a === b ? 0 : -1);
export const asc = (a, b) => (a > b ? 1 : a === b ? 0 : -1);

export const by = (fieldOrGetter, directionFunction = asc) => (one, two) => {
  const getter =
    typeof fieldOrGetter === 'string'
      ? o => get(o, fieldOrGetter)
      : typeof fieldOrGetter === 'function'
      ? fieldOrGetter
      : o => o;

  const [a, b] = [one, two].map(getter);
  return directionFunction(a, b);
};

export function sortByFromTime(a, b) {
  return a.fromTime - b.fromTime;
}
