import { combineReducers } from 'redux';

import { GRID_RESULTS, PANEL_RESULTS } from './constants';
import createSearchResultReducer from './searchResult/reducer';
import geoAnalysisPanelReducer from './panel/reducer';

const defaults = {
  isLoading: false,
  searchRequest: {},
  selectedGeofence: {},
  searchRequestImages: [],
  timeslices: [],
};

function geoAnalysisReducer(state = defaults, action) {
  switch (action.type) {
    case `ANALYSIS_GEOSPATIAL/RESET`: {
      return defaults;
    }

    case 'ANALYSIS_GEOSPATIAL/SET_TIMESLICES': {
      return {
        ...state,
        timeslices: action.payload,
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_SEARCH_REQUEST`: {
      return {
        ...state,
        searchRequest: action.payload,
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_SELECTED_GEOFENCE`: {
      return {
        ...state,
        selectedGeofence: action.payload,
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_SEARCH_REQUEST_IMAGES`: {
      return {
        ...state,
        searchRequestImages: action.payload,
      };
    }

    case 'ANALYSIS_GEOSPATIAL/SET_LOADING_STATE': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case 'ANALYSIS_GEOSPATIAL/SET_IS_ALERT_DETAIL': {
      return {
        ...state,
        isAlertDetail: action.payload,
      };
    }

    default:
      return state;
  }
}

export default combineReducers({
  main: geoAnalysisReducer,
  [GRID_RESULTS]: createSearchResultReducer(GRID_RESULTS),
  [PANEL_RESULTS]: createSearchResultReducer(PANEL_RESULTS),
  panelConfig: geoAnalysisPanelReducer,
});
