import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import throttle from 'lodash/throttle';

import { inRange } from 'common/helpers/mathUtils';
import { LIGHT_GREY, DARK_GREY } from 'common/constants/colors';
import AuthorizedImage from 'common/components/base/AuthorizedImage';
import CircularLoader from 'common/components/base/CircularLoader';
import Delay from 'common/components/base/Delay';
import Button from 'common/components/base/Button';

import ZoomControls from './ZoomControls';
import MarkerControl from './MarkerControl';
import VideoCameraMarker from './VideoCameraMarker';

const styles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    userSelect: 'none',
    background: DARK_GREY,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    cursor: 'not-allowed',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  imageWrapper: {
    maxHeight: '100%',
    maxWidth: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  controls: {
    zIndex: 10,
    position: 'absolute',
    top: 12,
    right: 12,
  },
  markerControl: {
    zIndex: 10,
    position: 'absolute',
    top: 76,
    right: 12,
  },
  recenter: {
    position: 'absolute',
    bottom: 12,
    right: 12,
    zIndex: 10,
  },
};

const MARKER_SIZE = 20;
const MAX_SCALE = 32;
const MIN_SCALE = 0.25;
const ZOOM_IN = 2;
const ZOOM_OUT = 0.5;
const DEFAULT_STATE = {
  isDragging: false,
  isAddingMarker: false,
  isLoading: true,
  isZooming: false,
  scale: 1,
  scaleWidth: 0,
  scaleHeight: 0,
  translate: { x: 0, y: 0 },
};

function getCursorStyle(isDragging, isAddingMarker) {
  if (isDragging) {
    return 'grabbing';
  }

  return isAddingMarker ? 'crosshair' : 'default';
}

function isDefaultPosition({ scale, translate }) {
  return scale === 1 && translate.x === 0 && translate.y === 0;
}

class InteractiveImage extends React.Component {
  static defaultProps = {
    showZoomControl: true,
    showMarkerControl: true,
    isAddingMarker: false,
    markers: [],
  };

  constructor(props) {
    super(props);
    this.onMouseMove = throttle(this.onMouseMove, 40);
    this.onWheel = throttle(this.onWheel, 40);
  }

  /* allow for force edit mode for adding markers from parent component in addition to toggling if needed */
  state = { ...DEFAULT_STATE, isAddingMarker: this.props.isAddingMarker };

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src && this.props.src) {
      this.onSrcUpdated();
    }
  }

  bindRef = node => {
    this.wrapper = node;
  };

  enableAddMarker = () => this.setState({ isAddingMarker: true });

  disableAddMarker = () => this.setState({ isAddingMarker: false });

  enableTransition = (time = 0.3) => {
    this.imageWrapper.style.transition = `all ${time}s`;
  };

  disableTransition = () => {
    this.imageWrapper.style.transition = '';
  };

  getMarkerStyles = () => {
    const newScale = 1 / this.state.scale;

    return `scale(${newScale})`;
  };

  getTransformStyle = () => {
    const { x, y } = this.state.translate;
    const scaledX = x / this.state.scale;
    const scaledY = y / this.state.scale;

    return `scale(${this.state.scale}) translate(${scaledX}px, ${scaledY}px)`;
  };

  getTranslateFromZoom = scaleFactor => {
    /* this is technically not accurrrate, it zooms into the center of the screen, but it's good enough to use for now */
    const translate = {
      x: this.state.translate.x * scaleFactor,
      y: this.state.translate.y * scaleFactor,
    };

    return translate;
  };

  setStyle = state => {
    if (state.scale && !inRange(state.scale, MIN_SCALE, MAX_SCALE)) return;
    this.setState(state);
  };

  recenter = e => {
    e.stopPropagation();
    this.enableTransition(0.8);
    this.setState({ scale: 1, translate: { x: 0, y: 0 } });
  };

  onLoad = (...args) => {
    const { width, height } = this.image;
    const { clientWidth, clientHeight } = this.wrapper;

    const ratio = width / height;
    const wrapperRatio = clientWidth / clientHeight;

    const scale = ratio < wrapperRatio ? clientHeight / height : clientWidth / width;

    const scaleWidth = width * scale;
    const scaleHeight = height * scale;

    this.setState({ isLoading: false, scaleWidth, scaleHeight });

    if (this.props.onLoad) {
      this.props.onLoad(...args);
    }
  };

  onLoadingSrc = () => {
    this.setState({ isLoading: true });
  };

  onWheel = e => {
    if (this._isMouseDown || !e.dispatchConfig || !e.nativeEvent) return;

    const scaleFactor = e.nativeEvent.deltaY > 0 ? ZOOM_OUT : ZOOM_IN;
    const scale = this.state.scale * scaleFactor;

    const translate = this.getTranslateFromZoom(scaleFactor);

    this.enableTransition();
    this.setStyle({ scale, translate, isZooming: true });
  };

  onMouseDown = e => {
    if (this._isMouseDown) return;

    this._isMouseDown = true;
    this._initialPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    this._initialTranslate = this.state.translate;

    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp, { once: true });
  };

  onMouseMove = ({ pageX, pageY }) => {
    if (!this._isMouseDown) return;

    /* used to prevent onClick after dragging */
    this._hasMoved = true;

    const translate = {
      x: this._initialTranslate.x + (pageX - this._initialPosition.x),
      y: this._initialTranslate.y + (pageY - this._initialPosition.y),
    };

    this.disableTransition();
    this.setStyle({ translate, isZooming: false });
  };

  onMouseUp = () => {
    this._isMouseDown = false;
    document.removeEventListener('mousemove', this.onMouseMove);

    /* used to prevent onClick after dragging */
    setTimeout(() => (this._hasMoved = false), 0);
  };

  onSrcUpdated = () =>
    this.setState({
      ...DEFAULT_STATE,
      isAddingMarker: this.props.isAddingMarker,
    });

  onZoomIn = () => {
    const translate = this.getTranslateFromZoom(ZOOM_IN);
    const scale = this.state.scale * ZOOM_IN;

    this.enableTransition();
    this.setStyle({
      scale,
      translate,
      isZooming: true,
    });
  };

  onZoomOut = () => {
    const translate = this.getTranslateFromZoom(ZOOM_OUT);
    const scale = this.state.scale * ZOOM_OUT;

    this.enableTransition();
    this.setStyle({
      scale,
      translate,
      isZooming: true,
    });
  };

  onImageClick = e => {
    if (this._hasMoved || !this.state.isAddingMarker || !this.props.onCreateMarker) {
      return;
    }

    const { pageX: x, pageY: y } = e.nativeEvent;
    const {
      x: wrapperX,
      y: wrapperY,
      width: wrapperWidth,
      height: wrapperHeight,
    } = this.wrapper.getBoundingClientRect();

    const { markerX, markerY } = this.getMarkerCoords({
      x,
      y,
      wrapperX,
      wrapperY,
      wrapperWidth,
      wrapperHeight,
    });

    if (inRange(markerX, 0, 1) && inRange(markerY, 0, 1)) {
      this.props.onCreateMarker({ x: markerX, y: markerY });
    }
  };

  onDragMarker = (index, { x, y }) => {
    if (this.props.onDragMarker) {
      const marker = this.getDraggedMarker({ index, x, y });
      const { x: markerX, y: markerY } = marker;

      if (inRange(markerX, 0, 1) && inRange(markerY, 0, 1)) {
        this.props.onDragMarker(marker, index);
      }
    }

    if (!this.state.isDragging) {
      this.setState({ isDragging: true });
    }
  };

  onDragFinish = (index, { x, y }) => {
    if (this.props.onDragFinish) {
      const marker = this.getDraggedMarker({ index, x, y });
      const { x: markerX, y: markerY } = marker;

      if (inRange(markerX, 0, 1) && inRange(markerY, 0, 1)) {
        this.props.onDragFinish(marker, index);
      }
    }

    this.setState({ isDragging: false });
  };

  getDraggedMarker = ({ index, x, y }) => {
    const {
      x: wrapperX,
      y: wrapperY,
      width: wrapperWidth,
      height: wrapperHeight,
    } = this.wrapper.getBoundingClientRect();

    const { markerX, markerY } = this.getMarkerCoords({
      x,
      y,
      wrapperX,
      wrapperY,
      wrapperWidth,
      wrapperHeight,
    });

    return {
      ...this.props.markers[index],
      x: markerX,
      y: markerY,
    };
  };

  getMarkerCoords = ({ x, y, wrapperX, wrapperY, wrapperWidth, wrapperHeight }) => {
    const { leftPercent, rightPercent, topPercent, bottomPercent } = this.getWindowPercentages();

    const percentX = (x - wrapperX) / wrapperWidth;
    const percentY = (y - wrapperY) / wrapperHeight;

    const markerX = (rightPercent - leftPercent) * percentX + leftPercent;
    const markerY = (bottomPercent - topPercent) * percentY + topPercent;

    return { markerX, markerY };
  };

  getWindowPercentages = () => {
    if (!this.wrapper || !this.image) {
      return {
        leftPercent: null,
        rightPercent: null,
        topPercent: null,
        bottomPercent: null,
      };
    }

    const {
      width: wrapperWidth,
      height: wrapperHeight,
      x: wrapperX,
      y: wrapperY,
    } = this.wrapper.getBoundingClientRect();
    const { x, y, width, height } = this.image.getBoundingClientRect();

    const leftPercent = -(x - wrapperX) / width;
    const rightPercent = (wrapperWidth - x + wrapperX) / width;
    const topPercent = -(y - wrapperY) / height;
    const bottomPercent = (wrapperHeight - y + wrapperY) / height;

    return {
      leftPercent,
      rightPercent,
      topPercent,
      bottomPercent,
    };
  };

  getImageStyle = () => {
    const { scaleWidth, scaleHeight, isLoading, isAddingMarker, isDragging } = this.state;

    const cursor = getCursorStyle(isDragging, isAddingMarker);

    return {
      cursor,
      width: scaleWidth || 'unset',
      height: scaleHeight || 'unset',

      /* Hide the image until it's finished loading to prevent the image flashing at the wrong dimensions */
      opacity: isLoading ? 0 : 1,
    };
  };

  renderMarker = (marker, index) => {
    const { x, y, color, useIcon, camera, style: markerStyles, ...rest } = marker;

    const style = {
      /* prevent transition when translating since this looks and feels bad */
      transition: this.state.isZooming ? 'all 0.3s' : '',
      left: `${x * 100}%`,
      top: `${y * 100}%`,
      transform: this.getMarkerStyles(),
      cursor: 'grab',
      ...markerStyles,
    };

    if (this.props.renderMarker) {
      this.props.renderMarker({ style, marker });
    }

    /*
      For now, always render the markers so that we can animate the markers moving off-screen
      when zooming. There shouldn't be too many markers in this view anyways
    */

    return (
      <VideoCameraMarker
        camera={camera}
        key={`${x},${y},${index}`}
        onDrag={coords => this.onDragMarker(index, coords)}
        onDragFinish={coords => this.onDragFinish(index, coords)}
        size={MARKER_SIZE}
        style={style}
        useIcon={useIcon}
        {...rest}
      />
    );
  };

  render() {
    const { classes, className, src, children, markers } = this.props;

    return (
      <div
        role="none"
        className={classnames(classes.main, className)}
        ref={this.bindRef}
        onClick={this.onImageClick}
        onDragStart={e => e.preventDefault()}
        onWheel={this.onWheel}
        onMouseDown={this.onMouseDown}
      >
        {this.props.showZoomControl && (
          <ZoomControls
            className={classes.controls}
            onZoomIn={this.onZoomIn}
            onZoomOut={this.onZoomOut}
            disableZoomIn={this.state.scale >= MAX_SCALE}
            disableZoomOut={this.state.scale <= MIN_SCALE}
          />
        )}
        {this.props.showMarkerControl && (
          <MarkerControl className={classes.markerControl} onClick={this.enableAddMarker} />
        )}
        {!isDefaultPosition(this.state) && (
          <Button
            onClick={this.recenter}
            theme="white"
            className={classes.recenter}
            data-testid="InteractiveImage-RecenterButton"
          >
            RE-CENTER
          </Button>
        )}
        <div
          ref={node => (this.imageWrapper = node)}
          style={{
            border: `2px solid ${DARK_GREY}`,
            background: this.state.isLoading ? DARK_GREY : LIGHT_GREY,
            display: 'flex',
            position: 'relative',
            transform: this.getTransformStyle({}),
          }}
          data-testid="InteractiveImage"
        >
          {this.state.isLoading && (
            <Delay delay={250}>
              <CircularLoader variant="indeterminate" />
            </Delay>
          )}
          <AuthorizedImage
            useDefaultSrc={this.props.useDefaultSrc}
            bindRef={node => (this.image = node)}
            className={classes.image}
            onLoadingSrc={this.onLoadingSrc}
            onLoad={this.onLoad}
            alt=""
            src={src}
            style={this.getImageStyle()}
          />
          {!this.state.isLoading && markers.map(this.renderMarker)}
        </div>
        {children}
      </div>
    );
  }
}

export default injectSheet(styles)(InteractiveImage);
