import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import Typography from 'components/Typography';
import IconButton from 'common/components/base/IconButton';
import Icon from 'common/components/base/Icon';

import { DARK_BORDER } from 'common/constants/colors';

const useStyles = createUseStyles(theme => ({
  main: {
    '&$mainExpanded': {
      margin: props => (props.margin ? undefined : 0),
    },
  },
  mainExpanded: {},
  root: {
    padding: props => (props.padding ? `0 ${theme.spacing(1.5)}px` : 0),
    minHeight: props => (props.dense ? 28 : 38),
    height: props => (props.dense ? 28 : 38),
    borderBottom: props => (props.divider ? `solid 1px ${DARK_BORDER}` : ''),

    '&$expanded': {
      minHeight: props => (props.dense ? 28 : 38),
      height: props => (props.dense ? 28 : 38),
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: 0,

    '&$expanded': {
      minHeight: 38,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  expanded: {},
  details: {
    flexDirection: 'column',
    paddingLeft: props => (props.padding ? undefined : 0),
    paddingRight: props => (props.padding ? undefined : 0),
    paddingBottom: theme.spacing(0.75),
  },
  expandIcon: {
    transition: 'all 0.2s',
  },
}));

const Accordion = ({
  label,
  children,
  customClasses,
  defaultExpanded,
  dense,
  divider,
  margin,
  padding,
  variant,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  /* We don't want to show the divider until the expansion panel is expanded */
  const classes = useStyles({
    dense,
    divider: expanded && divider,
    margin,
    padding,
  });

  const {
    expansionPanelClasses = {},
    expansionPanelSummaryClasses = {},
    expansionPanelDetailsClassses = {},
  } = customClasses;

  return (
    <MuiAccordion
      expanded={expanded}
      classes={{
        ...expansionPanelClasses,
        root: classnames(classes.main, expansionPanelClasses.root),
        expanded: classnames(classes.mainExpanded, expansionPanelClasses.expanded),
      }}
      onChange={() => setExpanded(!expanded)}
      {...rest}
    >
      <MuiAccordionSummary
        classes={{
          ...expansionPanelSummaryClasses,
          root: classnames(classes.root, expansionPanelSummaryClasses.root),
          content: classnames(classes.content, expansionPanelSummaryClasses.content),
          expanded: classnames(classes.expanded, expansionPanelSummaryClasses.expanded),
        }}
      >
        <IconButton size="small">
          <Icon
            size="large"
            iconName="arrow_drop_down"
            className={classes.expandIcon}
            style={{ transform: expanded ? '' : 'rotate(-90deg)' }}
          />
        </IconButton>
        <Typography variant={variant}>{label}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails classes={expansionPanelDetailsClassses} className={classes.details}>
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

Accordion.defaultProps = {
  customClasses: {},
  defaultExpanded: false,
  dense: false,
  margin: true,
  padding: true,
  variant: 'h6',
};

export default Accordion;
