import React, { useState, useMemo } from 'react';
import { createUseStyles } from 'react-jss';

import CancelJob from 'common/components/popups/dialogs/CancelJob';
import Table from 'components/Table';
import { DateCell, StatusCell, DatasetOptionsCell } from './GeospatialJobTableCells';

const useStyles = createUseStyles(theme => ({
  row: {
    paddingLeft: theme.spacing(5),
  },
}));

const PAGE_SIZE = 25;
const columns = [
  {
    Header: 'Job Id',
    accessor: 'id',
    width: 1,
  },
  {
    Header: 'Name',
    accessor: 'dataset',
    width: 5,
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    width: 1,
  },
  {
    Header: 'Status',
    accessor: 'status',
    width: 5,
    Cell: StatusCell,
  },
  {
    Header: '# of GeoTIFFs',
    accessor: 'imageCount',
    canSort: false,
    width: 1,
  },
  {
    Header: 'Processed On',
    accessor: 'created',
    Cell: DateCell,
    width: 1,
  },
];

const subColumns = [
  {
    accessor: 'type',
    width: 5,
  },
  {
    accessor: '_priority',
    width: 1,
  },
  {
    accessor: 'status',
    width: 5,
    Cell: StatusCell,
  },
  {
    accessor: '_imageCount',
    width: 1,
  },
  {
    accessor: '_created',
    width: 1,
  },
];

const GeospatialJobTable = ({
  data,
  isLoading,
  onSortChange,
  onPageSizeChange,
  onPageChange,
  totalJobs,
}) => {
  const classes = useStyles();

  const [dataSource, setDataSource] = useState(null);

  const closeCancelJob = () => setDataSource(null);

  const optionColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'options',
        width: 1,
        canSort: false,
        Cell: ({ row }) => <DatasetOptionsCell row={row} cancelJobHandler={setDataSource} />,
      },
    ],
    []
  );

  const renderExpandableContent = ({ row }) => (
    <Table
      customClasses={{ row: classes.row }}
      columns={subColumns}
      data={row.original?.jobs}
      hideHeaders
      hideRowBorders
    />
  );

  return (
    <React.Fragment>
      <CancelJob datasource={dataSource} open={Boolean(dataSource)} handleClose={closeCancelJob} />
      <Table
        enableExpanded
        enablePagination
        enableSorting
        manualPagination={false}
        isLoading={isLoading}
        noDataText="There are no jobs running"
        onSortChange={onSortChange}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        pageSizeOptions={[PAGE_SIZE]}
        pagination={{ totalCount: totalJobs, pageSize: PAGE_SIZE }}
        renderExpandableContent={renderExpandableContent}
        data={data}
        columns={[...columns, ...optionColumns]}
      />
    </React.Fragment>
  );
};

export default GeospatialJobTable;
