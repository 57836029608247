export const LOCAL_AUTH_TYPE = 'LOCAL';

export const GROUPS = {
  ANALYST: 'Analyst',
  ALL_USERS: 'All Users',
  CAMERA_MANAGEMENT: 'Camera Management',
  IDP: 'identity-package',
  VR: 'vehicle-recognition',
  MINT: 'mint',
  PRIME: 'prime',
};
