import Button from 'common/components/base/Button';

const LabelingModeButton = ({ isLabeling, onToggle }) => (
  <Button
    style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 40 }}
    onClick={onToggle}
  >
    {isLabeling ? 'Exit Labeling Mode' : 'Label'}
  </Button>
);

export default LabelingModeButton;
