import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

const useStyles = createUseStyles(theme => ({
  main: {
    display: 'flex',
    alignItems: props => props.alignItems,
    justifyContent: props => props.justifyContent,
    flexDirection: props => props.flexDirection,
    padding: props => props.padding || theme.spacing(2.5),
    margin: 0,
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },
}));

const MirageContent = ({
  className,
  component: Component,
  flexDirection,
  justifyContent,
  alignItems,
  margin,
  padding,
  ...rest
}) => {
  const classes = useStyles({ flexDirection, justifyContent, alignItems, margin, padding });

  return (
    <Component
      className={classnames(classes.main, className)}
      data-testid="MirageContent"
      {...rest}
    />
  );
};

MirageContent.defaultProps = {
  alignItems: 'center',
  component: 'div',
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

export default MirageContent;
