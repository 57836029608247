import L from 'leaflet';

export const getDefaultVideoIcon = () =>
  new L.Icon({
    iconUrl: '/pin-video.svg',
    iconSize: [25],
    iconAnchor: [12.5, 36],
  });

export const getSelectedVideoIcon = () =>
  new L.Icon({
    iconUrl: '/pin-video-selected.svg',
    iconSize: [30],
    iconAnchor: [15, 43.2],
  });

const getVideoIcon = ({ isSelected }) =>
  (isSelected ? getSelectedVideoIcon : getDefaultVideoIcon)();

export default getVideoIcon;
