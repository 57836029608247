/**
 * FrameManager maintains a circular buffer of contiguous frames.
 */

export const ERR_PROTECTED = -4;
export const ERR_UNKNOWN_FRAMERATE = -3;
export const ERR_OUTSIDE_BUFFER = -2;
export const ERR_ALREADY_IN_BUFFER = -1;
export const OK_WANT_NEXT = 0;
export const OK_BUT_NO_MORE = 1;

const MAX_SEEK_SCAN = 5;

class FrameManager {
  constructor(bufSize) {
    console.assert(bufSize > MAX_SEEK_SCAN);
    this._bufferSize = bufSize;

    // Circular buffer size
    this._bufferSize = bufSize;
    // A fixed size, circular buffer of frames
    this._frames = [];
    // A fixed size, circular buffer of frame times (same size as _Frames)
    this._frameTimes = [];

    // This frame is the one that will be displayed, but also the frame
    // that defines the "center" of the circular buffer. It dictates which
    // frames can be accepted into the buffer and the index where they
    // will be filled.
    this._anchorFrame = -1;

    this._expectedNextTime = undefined;
    this._expectedNextFill = 0;

    this.frameDelta = undefined;
    this._acceptThreshold = undefined;

    // When false, we are simply collecting frames as the video plays,
    // anticipating a potential switch to kinetic display mode. When true,
    // we are filling the buffer deliberately and offerFrame needs to
    // communicate what frames should be offered.
    this._frameProtect = false;
    this._displayFrame = -1;
  }

  getBufferSize() {
    return this._bufferSize;
  }

  getDisplayTime() {
    return this._displayFrame >= 0 ? this._frameTimes[this._displayFrame] : -1;
  }

  getDisplayFrame() {
    return this._frames[this._displayFrame];
  }

  updateFramerate(newRate) {
    const newDelta = 1.0 / newRate;

    if (this.frameDelta !== newDelta) {
      this.frameDelta = newDelta;
      this._acceptThreshold = this.frameDelta / 2;
    }
  }

  /**
   * Checks if the frame at time 'time' is suitable to be positioned
   * after current frame.
   *
   * @param time Frame time stamp
   * @returns {boolean} True if frame can be placed right after current frame
   */
  _isExpectedFrame(time) {
    return (
      !this._expectedNextTime ||
      (this._acceptThreshold && Math.abs(this._expectedNextTime - time) < this._acceptThreshold)
    );
  }

  /**
   * Find the storage index for frame at time 'time'
   * @param time Timestamp of the frame
   * @returns {number} Index in the buffer where the frame can be stored
   */
  _findIndex(time) {
    if (this._isExpectedFrame(time)) {
      return this._expectedNextFill;
    }

    if (this._anchorFrame >= 0 && this.frameDelta) {
      const refTime = this._frameTimes[this._anchorFrame];
      let refIndex =
        (this._anchorFrame + Math.round((time - refTime) / this.frameDelta)) % this._bufferSize;

      if (refIndex < 0) refIndex += this._bufferSize;

      return refIndex;
    }

    return 0;
  }

  _currentFrameIsProtected() {
    if (!this._frameProtect) return false;

    return this.displayToCurrentStep() === 1;
  }

  _canAcceptFrame(frameidx, time) {
    // If not protecting frames, accept anything anywhere
    if (!this._frameProtect) {
      return OK_WANT_NEXT;
    }

    // Cannot overwrite displayframe
    if (frameidx === this._displayFrame) {
      return ERR_PROTECTED;
    }

    // If slot is empty, accept anything in there.
    if (!this._frames[frameidx]) {
      return OK_WANT_NEXT;
    }

    // If frame is already there, do not accept
    if (Math.abs(this._frameTimes[frameidx] - time) < this._acceptThreshold) {
      return ERR_ALREADY_IN_BUFFER;
    }

    // Too far ahead in buffer
    if (
      this._displayFrame >= 0 &&
      time > this._frameTimes[this._displayFrame] + (this._bufferSize * this.frameDelta) / 2
    ) {
      return ERR_OUTSIDE_BUFFER;
    }

    return OK_WANT_NEXT;
  }

  offerFrame(frame, time) {
    if (!this.frameDelta) {
      // Must know framerate for buffer insert to function correctly
      // console.log('Err: unknown framerate');
      return ERR_UNKNOWN_FRAMERATE;
    }

    const nextFrame = this._findIndex(time);

    if (!this._frameProtect || this._anchorFrame < 0) {
      this._anchorFrame = nextFrame;
    }

    this._expectedNextFill = (nextFrame + 1) % this._bufferSize;
    this._expectedNextTime = time + this.frameDelta;

    const ret = this._canAcceptFrame(nextFrame, time);
    if (ret >= 0) {
      // Make sure the frame canvas is fully set up..
      if (!this._frames[nextFrame]) {
        this._frames[nextFrame] = document.createElement('canvas');
      }
      this._frames[nextFrame].width = frame.videoWidth;
      this._frames[nextFrame].height = frame.videoHeight;

      const ctx = this._frames[nextFrame].getContext('2d');

      ctx.drawImage(frame, 0, 0);
      this._frameTimes[nextFrame] = time;

      return ret;
    }

    return ret;
  }

  _skipMatches(step) {
    let targetFrame = (this._displayFrame + step) % this._bufferSize;
    if (targetFrame < 0) {
      targetFrame += this._bufferSize;
    }
    return (
      this._frames[targetFrame] &&
      Math.abs(
        this._frameTimes[this._displayFrame] +
          this.frameDelta * step -
          this._frameTimes[targetFrame]
      ) < this._acceptThreshold
    );
  }

  nextFrame(direction) {
    for (let step = 1; step < 5; ++step) {
      if (this._skipMatches(step * direction)) {
        this._displayFrame = (this._displayFrame + step * direction) % this._bufferSize;

        if (this._displayFrame < 0) {
          this._displayFrame += this._bufferSize;
        }

        return true;
      }
    }

    return false;
  }

  reverseBufCapacity() {
    // Backtrack in buffer until we hit a limit
    let step = 0;
    let unmatched = 1;

    while (unmatched < 3) {
      if (this._skipMatches(-(step + unmatched))) {
        step += unmatched;
        unmatched = 1;
      } else {
        unmatched++;
      }
    }

    const time = this._frameTimes[this._displayFrame - step];

    return [time, step];
  }

  seekBy(amount) {
    // TODO(timo): When we hit the boundary of the buffer, request
    // caller to seek video and provide frames on one end of the buffer.
    if (amount > 0) {
      for (let i = 0; i < amount; ++i) {
        if (!this.nextFrame(1)) {
          return false;
        }
      }
    } else {
      for (let i = 0; i < -amount; ++i) {
        if (!this.nextFrame(-1)) {
          return false;
        }
      }
    }

    if (this._frameProtect) {
      this._anchorFrame = this._displayFrame;
    }

    return true;
  }

  /**
   * Notify manager that frames will be displayed from the buffer, and
   * consequently, we should attempt to maintain a reasonable window of
   * frames around current displayframe. Specifically, we should not
   * accept frames which would overwrite the displayframe, or nearby frames.
   *
   * @param desiredTime Desired time to display
   */
  startDisplay(desiredTime, acceptClosest = false) {
    this._displayFrame = this._anchorFrame;
    let bestDist = Math.abs(this._frameTimes[this._displayFrame] - desiredTime);

    for (let i = 0; i < this._frameTimes.length; ++i) {
      const dist = Math.abs(this._frameTimes[i] - desiredTime);
      if (dist < bestDist) {
        bestDist = dist;
        this._displayFrame = i;
      }
    }

    this._frameProtect = true;

    if (
      acceptClosest ||
      Math.abs(this._frameTimes[this._displayFrame] - desiredTime) < this._acceptThreshold
    ) {
      return true;
    }

    this._displayFrame = -1;

    return false;
  }

  stopDisplay() {
    this._frameProtect = false;
  }
}

export default FrameManager;
