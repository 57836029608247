import React from 'react';
import { withStyles } from 'tss-react-local';
import { getDatasources } from 'common/api/datasourceApi';

const styles = {
  thumb: {
    cursor: 'pointer',
    border: '1px solid transparent',
    marginRight: 10,
    '& img': {
      height: '100px',
    },
  },
  videoPanel: {
    display: 'flex',
    flexWrap: 'wrap',
    background: 'white',
    padding: '15px 0',
  },
};
const FILTER_IDS = {
  'https://dev.mirage.percipient.ai': {
    '200a76a50d': true,
  },
};

class VideoThumbnails extends React.Component {
  state = {
    datasources: [],
  };

  componentDidMount() {
    this.fetchDatasources();
  }

  fetchDatasources = () => {
    getDatasources().then(response => {
      const datasources = FILTER_IDS[process.env.BACKEND_HOSTNAME]
        ? response.filter(datasource => FILTER_IDS[process.env.BACKEND_HOSTNAME][datasource.id])
        : response;
      this.setState({ datasources });
    });
  };

  renderDatasources = () =>
    this.state.datasources.map(datasource => (
      <div key={datasource.id} className={this.props.classes.thumb}>
        <img
          alt="ds"
          src={datasource.thumbUrl}
          onClick={() => this.props.onClickDatasource(datasource)}
        />
      </div>
    ));

  render() {
    return <div className={this.props.classes.videoPanel}>{this.renderDatasources()}</div>;
  }
}

export default withStyles(VideoThumbnails, styles);
