import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import isFinite from 'lodash/isFinite';

import SplitPanel from './SplitPanel';

const HANDLE_WIDTH = 5;

const styles = {
  wrapperMain: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  panelMain: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  draggableHandle: {
    position: 'absolute',
    background: 'rgb(233,233,233)',
    boxShadow: '0px 1px 1px 1px rgba(100,100,100, .2)',
    zIndex: 101,
  },
};

class SplitPanelGroup extends React.Component {
  onHandleMouseDown = handleIndex => {
    this._draggingHandleIndex = handleIndex;
    document.addEventListener('mousemove', this.onMoveHandle);
    document.addEventListener('mouseup', this.onDoneDragging, { once: true });
  };

  onDoneDragging = () => {
    document.removeEventListener('mousemove', this.onMoveHandle);
  };

  onMoveHandle = event => {
    event.preventDefault();
    const { config, split } = this.props;
    const { minPosition = 0, maxPosition = 100 } = config[this._draggingHandleIndex];
    const wrapperBounds = this.wrapper.getBoundingClientRect();

    let barPosition;
    let barWidthPercent;

    if (split === 'rows') {
      const distanceFromTrailingEdge = event.pageY - wrapperBounds.y;
      barPosition = (100 * distanceFromTrailingEdge) / wrapperBounds.height;
      barWidthPercent = (100 * HANDLE_WIDTH) / wrapperBounds.height;
    } else {
      const distanceFromTrailingEdge = event.pageX - wrapperBounds.x;
      barPosition = (100 * distanceFromTrailingEdge) / wrapperBounds.width;
      barWidthPercent = (100 * HANDLE_WIDTH) / wrapperBounds.width;
    }

    // Dont drag past mininimum
    if (barPosition < minPosition) {
      barPosition = minPosition;
    }

    // Dont drag past maximum
    else if (barPosition > maxPosition) {
      barPosition = maxPosition - barWidthPercent;
    }

    // Dont drag past handle before
    if (
      this._draggingHandleIndex > 0 &&
      barPosition - barWidthPercent < config[this._draggingHandleIndex - 1].position
    ) {
      barPosition = config[this._draggingHandleIndex - 1].position + barWidthPercent;
    }

    // Dont drag past handle after
    else if (
      this._draggingHandleIndex < config.length - 1 &&
      barPosition + barWidthPercent > config[this._draggingHandleIndex + 1].position
    ) {
      barPosition = config[this._draggingHandleIndex + 1].position - barWidthPercent;
    }

    const nextConfig = cloneDeep(config);
    nextConfig[this._draggingHandleIndex].position = barPosition;

    this.props.onUpdateConfig(nextConfig);
  };

  renderHandles = () => {
    const { classes, split, config } = this.props;
    return config.map((configItem, index) => {
      const { position } = configItem;

      const style =
        split === 'rows'
          ? {
              height: `${HANDLE_WIDTH}px`,
              top: `${position}%`,
              left: 0,
              right: 0,
              cursor: 'ns-resize',
            }
          : {
              width: `${HANDLE_WIDTH}px`,
              left: `${position}%`,
              top: 0,
              bottom: 0,
              cursor: 'ew-resize',
            };

      return (
        <div
          role="img"
          key={position}
          style={style}
          className={classes.draggableHandle}
          onMouseDown={() => this.onHandleMouseDown(index)}
        />
      );
    });
  };

  renderPanels = () => {
    const { classes, children, split } = this.props;
    const { config } = this.props;

    return children.map((child, index) => {
      if (child.type !== SplitPanel) {
        throw new Error('Only include <SplitPanel /> as child');
      }

      const trailingPosition = config[index - 1] && config[index - 1].position;
      const forwardPosition = config[index] && config[index].position;

      const trailingKey = split === 'rows' ? 'top' : 'left';
      const forwardKey = split === 'rows' ? 'bottom' : 'right';

      const panelStyle = {
        [trailingKey]: isFinite(trailingPosition)
          ? `calc(${trailingPosition}% + ${HANDLE_WIDTH}px)`
          : 0,
        [forwardKey]: isFinite(forwardPosition) ? `${100 - forwardPosition}%` : 0,
      };

      return (
        <div
          key={index} // eslint-disable-line react/no-array-index-key
          className={classnames(classes.panelMain, classes.panel)}
          style={panelStyle}
        >
          {child}
        </div>
      );
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classnames(classes.wrapperMain, classes.wrapper)}
        ref={node => (this.wrapper = node)}
      >
        {this.renderHandles()}
        {this.renderPanels()}
      </div>
    );
  }
}

export default injectSheet(styles)(SplitPanelGroup);
