import { areImagesDuplicates, getLatLongFromGeoJSON } from 'common/helpers/geoUtils';
import { round } from 'common/helpers/mathUtils';
import { get } from 'lodash';

import { BACKGROUND_OBJECT_ID } from 'common/constants/app';
import { parse, stringifyUrl } from 'common/helpers/queryString';
import {
  CONFIRMED_STATUS,
  DUPLICATE_STATUS,
  IGNORED_STATUS,
  REJECTED_STATUS,
  UNCONFIRMED_STATUS,
} from 'analysis/redux/constants';
import { ZOOM_LEVEL_RENDER_DETECTIONS } from 'common/components/geomap/constants';

import { MANUALLY_CONFIRMED_CONFIDENCE_VALUE, CONFIRMED_CONFIDENCE_VALUE } from './constants';

export function buildTifUrl(searchResult) {
  const { southWestLng, southWestLat, northEastLng, northEastLat } = getLatLongFromGeoJSON(
    searchResult.polygon
  );

  return `/geothumbnail/geoimage?tifName=${searchResult.path}&southWestLng=${southWestLng}&southWestLat=${southWestLat}&northEastLng=${northEastLng}&northEastLat=${northEastLat}`;
}

export function getResultsByStatus(results = []) {
  const resultsByStatus = {
    [UNCONFIRMED_STATUS]: [],
    [CONFIRMED_STATUS]: [],
    [REJECTED_STATUS]: [],
    [DUPLICATE_STATUS]: [],
    [IGNORED_STATUS]: [],
  };

  results.forEach(result => {
    const array = resultsByStatus[result.status];
    if (array) array.push(result);
  });

  return resultsByStatus;
}

export function getCountsByStatus(results = [], { showNegativeResults }) {
  const countsByStatus = {};
  const resultsByStatus = getResultsByStatus(results);

  Object.keys(resultsByStatus).forEach(status => {
    status = Number(status);
    if (
      !showNegativeResults &&
      [REJECTED_STATUS, DUPLICATE_STATUS, IGNORED_STATUS].includes(status)
    ) {
      return;
    }

    countsByStatus[status] = get(resultsByStatus, status, []).length;
  });

  return countsByStatus;
}

/* TODO: Should probably change this into a geoUtil that uses turf */
export function isWithinMapBounds(mapBounds = [], coordinates) {
  /**
    @param mapBounds {Object}: Lat/Lng boundries of the map with named
    properties, e.g.:
      {
        northEastLat: 48.461037717049734
        northEastLng: 38.844821155071266
        southWestLat: 48.4603057968379
        southWestLng: 38.84337946772576
      }

    @param coordinates {Array}: Array of Lng/Lat values for each corner of a
    polygon (detection or geofence). If length === 1, represents the [lng, lat]
    center point of the detection. E.g.:
      [
        [38.824951, 48.481857]
        [38.825204, 48.408158]
        [38.935899, 48.408273]
        [38.935806, 48.481972]
        [38.824951, 48.481857]
      ]

      or

      [[ 38.824951, 48.481857 ]]

    @returns {boolean} If true, the mapBounds intersect with one or many of the coordinates
  */

  if (!mapBounds.length) return;

  /* We don't use northWest or southWest, but variables exist for clarity */
  const [
    _northWest, // eslint-disable-line no-unused-vars
    [northEastLng, northEastLat],
    _southWest, // eslint-disable-line no-unused-vars
    [southWestLng, southWestLat],
  ] = mapBounds;

  // Initialize max and min values to first set of coordinates
  let maxLng = coordinates[0][0];
  let minLng = coordinates[0][0];
  let maxLat = coordinates[0][1];
  let minLat = coordinates[0][1];

  // Find max/min lat/lng for the image or detection
  for (let i = 0; i < coordinates.length; i++) {
    const lng = coordinates[i][0];
    const lat = coordinates[i][1];

    if (lng > maxLng) maxLng = lng;
    if (lng < minLng) minLng = lng;
    if (lat > maxLat) maxLat = lat;
    if (lat < minLat) minLat = lat;
  }

  const isImageWithinMapLat =
    (maxLat < northEastLat && maxLat > southWestLat) ||
    (minLat < northEastLat && minLat > southWestLat);

  const isImageWithinMapLng =
    (maxLng < northEastLng && maxLng > southWestLng) ||
    (minLng < northEastLng && minLng > southWestLng);

  const isMapWithinImageLat =
    (northEastLat < maxLat && northEastLat > minLat) ||
    (southWestLat < maxLat && southWestLat > minLat);

  const isMapWithinImageLng =
    (northEastLng < maxLng && northEastLng > minLng) ||
    (southWestLng < maxLng && southWestLng > minLng);

  if (
    (isImageWithinMapLat || isMapWithinImageLat) &&
    (isImageWithinMapLng || isMapWithinImageLng)
  ) {
    return true;
  }

  return false;
}

export function buildConfirmedCounts(lightResults = [], objectModels = {}) {
  return Object.values(
    lightResults.reduce((acc, result) => {
      const objectId = result.objectClass || result.o;
      const status = result.status || getStatus(result.c);
      let object = objectModels[objectId];

      if (objectId === BACKGROUND_OBJECT_ID) {
        object = { id: objectId };
      }

      if (!object) return acc;

      acc[objectId] = acc[objectId] || {
        ...object,
        counts: { unconfirmed: 0, confirmed: 0 },
      };

      if (status === REJECTED_STATUS || status === DUPLICATE_STATUS) {
        return acc;
      }

      if (status === CONFIRMED_STATUS) {
        acc[objectId].counts.confirmed += 1;
      } else {
        acc[objectId].counts.unconfirmed += 1;
      }

      return acc;
    }, {})
  );
}

function getStatus(confidence) {
  if (
    confidence === CONFIRMED_CONFIDENCE_VALUE ||
    confidence === MANUALLY_CONFIRMED_CONFIDENCE_VALUE
  ) {
    return CONFIRMED_STATUS;
  }

  return UNCONFIRMED_STATUS;
}

/* Timelisce creation and utility functions for analysis results page */
const TWO_MINUTES_IN_MS = 60 * 1000 * 2;

function isWithinTimeBounds(image, compImage) {
  const imageTime = new Date(compImage.datetime).getTime();
  const targetTime = new Date(image.datetime).getTime();

  return imageTime >= targetTime - TWO_MINUTES_IN_MS && imageTime <= targetTime + TWO_MINUTES_IN_MS;
}

function createTimeslice(startImage, endImage, properties) {
  return {
    start: new Date(startImage.datetime).getTime(),
    end: new Date(endImage.datetime).getTime(),
    ...properties,
  };
}

export function removeDuplicateImages(images) {
  return images.reduce((acc, image) => {
    const compareFn = compImage => !areImagesDuplicates(image, compImage);
    const isNotDuplicate = acc.every(compareFn);
    if (isNotDuplicate) acc.push({ ...image });

    return acc;
  }, []);
}

export function buildTimeslices(searchRequestImages) {
  if (!searchRequestImages || !searchRequestImages.length) return [];

  const timeslices = [];
  let images = [];
  let currImage = searchRequestImages[0];

  searchRequestImages.forEach((image, idx) => {
    images.push(image);

    const id = timeslices.length;
    const nextImage = searchRequestImages[idx + 1];

    if (currImage && nextImage && !isWithinTimeBounds(currImage, nextImage)) {
      timeslices.push(
        createTimeslice(currImage, image, {
          images,
          id,
        })
      );

      /* reset variables when timeslice is made */
      currImage = nextImage;
      images = [];
    } else if (currImage && !nextImage) {
      timeslices.push(
        createTimeslice(currImage, searchRequestImages[idx], {
          images,
          id,
        })
      );
    }
  });

  return timeslices;
}

export function getAnalysisRoundedMapCenter({ lat, lng } = {}) {
  if (!lat || !lng) return {};

  return {
    lat: round(lat, 5),
    lng: round(lng, 5),
  };
}

export function appendMapParamsToUrl(url, mapParams = {}) {
  const { mapCenter = {}, zoomLevel } = mapParams;
  const { lat, lng } = mapCenter;
  return stringifyUrl({ url, query: { lat, lng, zoomLevel } });
}

export function getMapCoordsFromQueryParams(queryParams = '') {
  const { lat, lng, zoomLevel } = parse(queryParams);

  if (!lat || !lng || !zoomLevel) return { mapCenter: {}, zoomLevel: null };

  return {
    mapCenter: {
      lat: Number(lat),
      lng: Number(lng),
    },
    zoomLevel: parseInt(zoomLevel, 10),
  };
}

export function shouldRenderDetections(zoomLevel) {
  return zoomLevel >= ZOOM_LEVEL_RENDER_DETECTIONS;
}
