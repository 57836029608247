import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useInterval from 'common/hooks/useInterval';
import { setWsConnectionHealth } from 'app/redux/actions';
import { selectIsAuthenticated } from 'auth/redux/selectors';
import { selectIsGeoActive } from 'settings/redux/selectors';

import socketV1 from 'common/websocket/socketV1';
import socketV2 from 'common/websocket/socketV2';

import { MIRAGE_HEALTH_GOOD, MIRAGE_HEALTH_ERROR } from 'common/constants/app';

const CLOSED = 3;

const SocketConnection = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isGeoActive = useSelector(selectIsGeoActive);

  const onClose = useCallback(() => {
    if (!window.isLoggingOut && isAuthenticated && !isGeoActive) {
      dispatch(setWsConnectionHealth(MIRAGE_HEALTH_ERROR));
    }
  }, [dispatch, isAuthenticated, isGeoActive]);

  const openWsConnection = useCallback(() => {
    if (isGeoActive) return;

    const onOpen = () => dispatch(setWsConnectionHealth(MIRAGE_HEALTH_GOOD));

    const instanceV1 = socketV1.get();
    const instanceV2 = socketV2.get();

    if (isAuthenticated && (!instanceV1 || instanceV1.readyState === CLOSED)) {
      socketV1.connect({ onopen: onOpen, onclose: onClose });
    }

    if (isAuthenticated && (!instanceV2 || instanceV2.readyState === CLOSED)) {
      socketV2.connect({ onopen: onOpen, onclose: onClose });
    }
  }, [dispatch, isAuthenticated, isGeoActive, onClose]);

  const closeWsConnection = useCallback(() => {
    const instanceV1 = socketV1.get();
    const instanceV2 = socketV2.get();

    if (!isAuthenticated && instanceV1) {
      instanceV1.onclose = onClose;
      instanceV1.close();
    }

    if (!isAuthenticated && instanceV2) {
      instanceV2.onclose = onClose;
      instanceV2.close();
    }
  }, [isAuthenticated, onClose]);

  useEffect(() => {
    openWsConnection();
    closeWsConnection();
  }, [openWsConnection, closeWsConnection]);

  useInterval(openWsConnection, 30000);

  return null;
};

export default SocketConnection;
