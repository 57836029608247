// shortcut constants for matching the js key codes
export const SKIP_BACKWARD_1 = 37; // left arrow <-
export const SKIP_FORWARD_1 = 39; // right arrow ->
export const SKIP_BACKWARD_2 = 186; // semi-colon ;
export const SKIP_FORWARD_2 = 222; // single quote '
export const STEP_BACKWARD = 188; // period .
export const STEP_FORWARD = 190; // comma ,
export const PREV_DETECTION = 219; // open brack [
export const NEXT_DETECTION = 221; // close bracket ]
export const PLAY_PAUSE = 32; // space bar
export const SHIFT = 16; // shift key
export const PLAY_SPEED_1X = 'play_speed_1x'; // currently, no keyboard shortcut
export const PLAY_SPEED_2X = 'play_speed_2x'; // currently, no keyboard shortcut
export const PLAY_SPEED_4X = 'play_speed_4x'; // currently, no keyboard shortcut

export const LEFT_ARROW = SKIP_BACKWARD_1;
export const RIGHT_ARROW = SKIP_FORWARD_1;
