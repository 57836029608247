import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Drawer, Grid, IconButton, ListItemText } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Typography from 'components/Typography';
import { BRIGHT_RED, LIGHT_BLUE } from 'common/constants/colors';
import { useIdentityPackage } from './IdentityPackageContext';

const useStyles = createUseStyles(theme => ({
  drawer: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  description: {
    padding: theme.spacing(2),
  },
  closeButton: {
    color: BRIGHT_RED,
  },
  personGridItem: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    transition: 'background-color 500ms',

    '&:hover': {
      backgroundColor: LIGHT_BLUE,
      cursor: 'pointer',
    },
  },
}));

const IdentityPackageNewPersonsDrawer = () => {
  const classes = useStyles();
  const { name, closeDrawer, drawerOpen, drawerContents, createLink } = useIdentityPackage();

  const history = useHistory();
  const { url } = useRouteMatch();

  const add = async personId => {
    const response = await createLink({ to: personId });
    closeDrawer();
    const linkId = response?.id;
    if (linkId) history.replace(`${url}/link/${linkId}`);
  };

  return (
    <Drawer anchor="bottom" open={drawerOpen} onClose={closeDrawer}>
      <div role="presentation" className={classes.drawer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IconButton onClick={closeDrawer} component="span" className={classes.closeButton}>
              <Close />
            </IconButton>
            <Typography variant="h3" className={classes.description}>
              Click a person below to add them to {name}&apos;s network of relationships
            </Typography>
          </Grid>
          {drawerContents.map(person => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={person.id}
              className={classes.personGridItem}
              onClick={() => {
                add(person.id);
              }}
            >
              <img src={person.images[0]} alt={person.name} />
              <Typography variant="overline">
                <ListItemText primary={person.name} disableTypography />
              </Typography>
            </Grid>
          ))}
        </Grid>
      </div>
    </Drawer>
  );
};

export default IdentityPackageNewPersonsDrawer;
