import invert from 'lodash/invert';

/* Fetching the search results from a completed search request */
export const SEARCH_PENDING = 'pending';
export const SEARCH_FAILED = 'failed';
export const SEARCH_FINISHED = 'finished';

export const GROUP_BY_VIDEO = 'video';
export const GROUP_BY_NONE = 'none';
export const GROUP_BY_DAY = 'day';
export const GROUP_BY_FOLDER = 'folder';
export const GROUP_BY_DATASOURCE = 'datasource';

export const DEFAULT_LIMIT = 50;

export const DEFAULT_CELL_DATA = '— —';

export const UNCONFIRMED_STATUS = 0;
export const CONFIRMED_STATUS = 1;
export const REJECTED_STATUS = 2;
export const DUPLICATE_STATUS = 3;
export const IGNORED_STATUS = 4;

export const SEARCH_REQUEST_PENDING = 0;
export const SEARCH_REQUEST_PROCESSING = 1;
export const SEARCH_REQUEST_COMPLETE = 2;
export const SEARCH_REQUEST_FAILED = 3;
export const SEARCH_REQUEST_DROPPED = 4;
export const SEARCH_REQUEST_CANCELED = 5;
export const SEARCH_REQUEST_EXPIRED = 6;
export const SEARCH_REQUEST_CANCELING = 7;
export const SEARCH_REQUEST_NO_RESULTS = 8;

export const SEARCH_STATUS_TO_TERM = {
  '0': 'pending',
  '1': 'processing',
  '2': 'complete',
  '3': 'failed',
  '4': 'dropped',
  '5': 'canceled',
  '6': 'expired',
  '7': 'canceling',
};

export const SEARCH_TERM_TO_STATUS = invert(SEARCH_STATUS_TO_TERM);

export const MAP_VIEW = 'map';
export const ANALYSIS_VIEW = 'analysis';

export const MAX_SUPPORTED_DATASOURCES = 100;
