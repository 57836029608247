import axios from 'axios.js';
import { ANALYTICS_BACKEND, VENUE_MAP, CAMERAS } from 'common/constants/urls';

/*
  Processing this for clarity. Right now the backend uses "camera" as the key for cameraId,
  but we also need to sometimes inject the entire "camera" object into the marker to render on the
  venue maps. Instead of variables like "camera" and "cameraObject", we're just mapping cameraId and
  venueMapId for consistency accross our variables here.
*/
function _processVenueMarker(marker) {
  if (!marker) return {};

  return {
    ...marker,
    cameraId: marker.camera,
    venueMapId: marker.venueMap,
  };
}

function _processVenueMarkers(markers) {
  return markers.map(_processVenueMarker);
}

export function getVenueMaps() {
  return axios.get(`${ANALYTICS_BACKEND}${VENUE_MAP}/`).then(({ data }) => data);
}

export function postVenueMap(body) {
  return axios.post(`${ANALYTICS_BACKEND}${VENUE_MAP}/`, body).then(({ data }) => data);
}

export function patchVenueMap(id, venue) {
  return axios.patch(`${ANALYTICS_BACKEND}${VENUE_MAP}/${id}/`, venue).then(({ data }) => data);
}

export function deleteVenueMap(id) {
  return axios.delete(`${ANALYTICS_BACKEND}${VENUE_MAP}/${id}/`).then(({ data }) => data);
}

export function postVenueCamera({ venueMapId, cameraId, x, y }) {
  return axios
    .post(`${ANALYTICS_BACKEND}${VENUE_MAP}/${venueMapId}/cameras/`, {
      cameraId,
      x,
      y,
    })
    .then(({ data }) => _processVenueMarker(data));
}

export function getVenueCameras(venueMapId) {
  return axios
    .get(`${ANALYTICS_BACKEND}${VENUE_MAP}/${venueMapId}/cameras/`)
    .then(({ data }) => _processVenueMarkers(data));
}

export function deleteVenueCamera({ venueMapId, cameraId }) {
  return axios
    .delete(`${ANALYTICS_BACKEND}${VENUE_MAP}/${venueMapId}/cameras/`, {
      data: { cameraId },
    })
    .then(({ data }) => _processVenueMarker(data));
}

export function patchVenueCamera({ venueMapId, cameraId, x, y }) {
  return axios
    .patch(`${ANALYTICS_BACKEND}${VENUE_MAP}/${venueMapId}/cameras/`, {
      cameraId,
      x,
      y,
    })
    .then(({ data }) => _processVenueMarker(data));
}

export function getVenuesForCamera(cameraId) {
  return axios
    .get(`${ANALYTICS_BACKEND}${CAMERAS}/${cameraId}/venue_maps/`)
    .then(({ data }) => _processVenueMarkers(data));
}
