import React from 'react';

export const Example = ({ label, children, style, className }) => (
  <div style={{ marginBottom: '20px', ...style }}>
    <span style={{ marginBottom: 8 }}>{label}</span>
    <div style={{ lineHeight: 2.5 }} className={className}>
      {children}
    </div>
  </div>
);
