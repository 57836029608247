import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';

import Button from 'common/components/base/Button';
import Chip from 'common/components/base/Chip';
import Icon from 'common/components/base/Icon';
import Select from 'common/components/base/Select';
import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import * as Colors from 'common/constants/colors';
import { sortByName } from 'common/constants/sortFunctions';

import DetectionPersonFilterListTitle from './DetectionPersonFilterListTitle';

const ICON_SIZE = 50;

const useStyles = createUseStyles(theme => ({
  chip: {
    border: `1px solid ${Colors.BLUE_GREY}`,
    cursor: 'pointer',
    maxWidth: '100%',
  },
  empty: {
    alignItems: 'center',
    background: Colors.LIGHT_GREY,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
  },
  emptySpan: {
    color: Colors.PRIMARY_GREY,
    fontSize: 14,
    fontWeight: 300,
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: 'center',
    wordBreak: 'break-all',
  },
  emptyTitle: {
    color: Colors.TEXT,
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(0.75)}px 0`,
    width: '100%',

    '& > *': {
      flexGrow: 1,
      margin: `0 ${theme.spacing(1.5)}px`,
      width: '100%',
    },
  },
  footerButton: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    width: '100%',
  },
  footerButtonIcon: {
    marginRight: theme.spacing(0.75),
  },
  footerButtonText: {
    color: Colors.SECONDARY,
    fontSize: 16,
  },
  list: {
    borderBottom: `1px solid ${Colors.DARK_BORDER}`,
    borderTop: `1px solid ${Colors.DARK_BORDER}`,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    overflowY: 'auto',
    paddingTop: theme.spacing(1.5),
    position: 'relative',
    width: '100%',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  row: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: ICON_SIZE + 6, // need the +6 to keep the icons from bumping into each other
    padding: `${theme.spacing(0.25)}px ${theme.spacing(2.5)}px`,

    '&:hover': {
      background: Colors.ACCENT,
    },
  },
  rowIcon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    marginRight: theme.spacing(1.5),
  },
  rowLeft: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: '100%',
    minWidth: '30%',
    textAlign: 'start',
    whiteSpace: 'nowrap',

    '& > div': {
      overflow: 'hidden',

      '& > div.name': {
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      '& > div.description': {
        color: Colors.REGENT_GREY,
        fontSize: 12,
        marginTop: theme.spacing(0.25),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  rowRight: {
    maxWidth: '100%',
    minWidth: '30%',
    textAlign: 'end',
  },
}));

const DEFAULT_LIB = -1;

const DetectionPersonFilterListContent = ({
  data,
  datasourceLibraryId,
  enteredName,
  title,
  subtitle,
  error,
  isLoading,
  isPostPersonPending,
  libraries,
  onClickCreate,
  onClickRow,
  onChangeName,
  thumbnailUrl,
}) => {
  const [selectedLibrary, setSelectedLibrary] = useState(datasourceLibraryId || DEFAULT_LIB);
  const classes = useStyles();

  // The associated datasourceLibraryId is fetched on open of this component, so the state value
  // of selectedLibrary is initialized to null. Once it loads, if it exists set it to state
  useEffect(() => {
    if (datasourceLibraryId) {
      setSelectedLibrary(datasourceLibraryId);
    }
  }, [datasourceLibraryId]);

  const filterData = rowData =>
    rowData.name.toLowerCase().includes(enteredName.trim().toLowerCase());

  const filteredData = enteredName ? data.filter(filterData) : data;

  const nameConflicts = filteredData[0]?.name?.toLowerCase() === enteredName.trim().toLowerCase();

  const disabled = !enteredName.trim() || nameConflicts;

  const libraryOptions = [{ label: 'My Library', value: DEFAULT_LIB }].concat(
    Object.values(libraries)
      .sort(sortByName)
      .map(lib => ({ label: lib.name, value: lib.id }))
  );

  const onCreate = () => onClickCreate(enteredName.trim(), selectedLibrary);

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onCreate();
    }
  };

  const RenderRowIcon = ({ rowData: { images } }) => (
    <LazyLoad height={40} overflow>
      <img className={classes.rowIcon} alt="thumb" src={images && images[0]} />
    </LazyLoad>
  );

  const RenderLibrary = ({ rowData: { library } }) => {
    const name = libraries[library]?.name || 'My Library';
    return (
      <div className={classes.rowRight}>
        <Chip
          color="default"
          customClasses={{ root: classes.chip }}
          label={name}
          title={`In Library: ${name}`}
          variant="outlined"
        />
      </div>
    );
  };

  const RenderRow = ({ rowData, idx }) => {
    const { name, tags } = rowData;
    return (
      <ButtonBase
        className={classes.row}
        key={idx}
        onClick={() => onClickRow(rowData)}
        data-testid="DetectionIdentityFilterList-Identity"
      >
        <div className={classes.rowLeft}>
          <RenderRowIcon rowData={rowData} />
          <div>
            <div title={name} className="name">
              {name}
            </div>
            {tags && (
              <div className="description" title={tags}>
                {tags}
              </div>
            )}
          </div>
        </div>
        <RenderLibrary rowData={rowData} />
      </ButtonBase>
    );
  };

  const RenderEmptyView = () => (
    <div className={classes.empty}>
      <span className={classes.emptyTitle}>No Match</span>
      {enteredName.trim() !== '' && (
        <span className={classes.emptySpan}>Press Enter to create: {enteredName}</span>
      )}
    </div>
  );

  const RenderList = () =>
    filteredData.length ? (
      filteredData.map((rowData, idx) => <RenderRow idx={idx} key={rowData.id} rowData={rowData} />)
    ) : (
      <RenderEmptyView />
    );

  const RenderFooter = () => (
    <div className={classes.footer}>
      <Select
        disabled={disabled}
        label="Place in Library"
        data-testid="DetectionPersonFilterList-selectLibrary"
        onChange={e => setSelectedLibrary(e.target.value)}
        options={libraryOptions}
        value={selectedLibrary}
        shrinkOnEmptyLabel={false}
      />
      <Button
        disabled={disabled}
        isLoading={isPostPersonPending}
        theme="transparent"
        className={classes.footerButton}
        onClick={onCreate}
        data-testid="DetectionPersonFilterList-createNewPerson"
      >
        <Icon iconName="add" size="small" className={classes.footerButtonIcon} />
        <span className={classes.footerButtonText}>CREATE NEW IDENTITY</span>
      </Button>
    </div>
  );

  return (
    <Paper classes={{ root: classnames(classes.main) }}>
      <DetectionPersonFilterListTitle
        error={error}
        onChangeName={onChangeName}
        onKeyPress={onKeyPress}
        value={enteredName}
        subtitle={subtitle}
        title={title}
        thumbnailUrl={thumbnailUrl}
      />
      <div className={classes.list}>{isLoading ? <LoadingOverlay /> : <RenderList />}</div>
      {!isLoading && <RenderFooter />}
    </Paper>
  );
};

DetectionPersonFilterListContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  datasourceLibraryId: PropTypes.number,
  isLoading: PropTypes.bool,
  libraries: PropTypes.object.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
};

export default DetectionPersonFilterListContent;
