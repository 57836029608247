import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Dialog from '@material-ui/core/Dialog';

import { TEXT, PRIMARY_GREY, WARNING_COLOR } from 'common/constants/colors';
import { CONFIRM_DELETE, CANCEL } from 'app/redux/constants';

import Icon from 'common/components/base/Icon';
import Button from 'common/components/base/Button';
import LoadingOverlay from 'common/components/generalComponents/LoadingOverlay';
import { createCancelablePromise } from 'common/helpers/helperFunctions';

const styles = {
  main: {
    overflowY: 'hidden',

    '& > *': {
      display: 'flex',
      padding: 20,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 13,
    alignItems: 'center',
  },
  span: {
    maxWidth: 240,
    wordWrap: 'break-word',
  },
  icon: {
    color: WARNING_COLOR,
    marginRight: 8,
  },
  content: {
    flexDirection: 'column',
    fontWeight: 300,
    fontSize: 11,
    color: TEXT,
    lineHeight: 1.5,
    padding: '0 20px 20px 20px',
    whiteSpace: 'pre-wrap',
  },
  details: {
    composes: '$content',
    whiteSpace: 'pre-wrap',
    overflowY: 'auto',
  },
  actions: {
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > a': {
      textDecoration: 'underline',
      color: PRIMARY_GREY,
      fontSize: 11,
    },
  },
  buttons: {
    display: 'flex',

    '& button': {
      marginLeft: 12,
      minWidth: 80,
    },
  },
};

class ConfirmDialog extends Component {
  state = {
    isProcessing: false,
  };

  componentWillUnmount() {
    if (this.cancelableAcceptPromise) {
      this.cancelableAcceptPromise.cancel();
    }
  }

  toggleDetails = () => this.setState(state => ({ showDetails: !state.showDetails }));

  handleAcceptClick = e => {
    e.stopPropagation();

    const { closeImmediately, dialogAcceptFunc, handleClose, onExited } = this.props;

    if (closeImmediately) {
      handleClose();
      dialogAcceptFunc();
    } else {
      const onComplete = () => {
        this.setState({ isProcessing: false });
        handleClose();
        onExited();
      };

      this.setState({ isProcessing: true });

      this.cancelableAcceptPromise = createCancelablePromise(dialogAcceptFunc);

      this.cancelableAcceptPromise()
        .then(onComplete)
        .catch(({ isCanceled }) => {
          if (!isCanceled) onComplete();
        });
    }
  };

  render() {
    const {
      classes,
      dialogDetails,
      dialogTitle,
      dialogContent,
      dialogWidth = 'xs',
      fullWidth,
      dialogDeclineFunc = () => {},
      IconComponent,
      acceptText = CONFIRM_DELETE,
      declineText = CANCEL,
      open,
      handleClose,
      disableBackdropClick = false,
      'data-testid': dataTestId = 'ConfirmDialog',
    } = this.props;

    if (!dialogTitle || !dialogContent) return null;

    return (
      <Dialog
        classes={{ paper: classes.main }}
        disableBackdropClick={disableBackdropClick && this.state.isProcessing}
        maxWidth={dialogWidth}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        open={open}
        data-testid={dataTestId}
        fullWidth={fullWidth}
      >
        <header className={classes.title}>
          {IconComponent || <Icon className={classes.icon} iconName="warning" />}
          <span className={classes.span}>{dialogTitle}</span>
        </header>
        <div className={classes.content}>{dialogContent}</div>
        {dialogDetails && this.state.showDetails && (
          <div className={classes.details}>{dialogDetails}</div>
        )}
        <div className={classes.actions}>
          {dialogDetails && (
            <Button variant={null} onClick={this.toggleDetails}>
              {this.state.showDetails ? 'Hide Details' : 'Show Details'}
            </Button>
          )}
          <div className={classes.buttons}>
            <Button
              data-testid="confirmDialogDecline"
              disabled={this.state.isProcessing}
              onClick={e => {
                e.stopPropagation();
                handleClose();
                dialogDeclineFunc();
              }}
              variant="outlined"
            >
              {declineText}
            </Button>
            <Button
              autoFocus
              data-testid="confirmDialogAccept"
              disabled={this.props.disabled || this.state.isProcessing}
              onClick={this.handleAcceptClick}
            >
              {acceptText}
            </Button>
          </div>
        </div>
        {this.state.isProcessing && <LoadingOverlay />}
      </Dialog>
    );
  }
}

ConfirmDialog.propTypes = {
  dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogAcceptFunc: PropTypes.func,
  dialogDeclineFunc: PropTypes.func,
  dialogWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  handleClose: PropTypes.func.isRequired,
  acceptText: PropTypes.string,
  declineText: PropTypes.string,
  open: PropTypes.bool,
  onExited: PropTypes.func,
};

ConfirmDialog.defaultProps = {
  open: false,
  onExited: noop,
  closeImmediately: true,
  dialogAcceptFunc: Function.prototype,
};

export default injectSheet(styles)(ConfirmDialog);
