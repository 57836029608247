import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { MapContainer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

import { selectIsTileServerFormFailure } from 'settings/redux/selectors';

const useStyles = createUseStyles({
  mapContainer: {
    background: '#eee',
    borderRadius: '2px',
    bottom: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    height: '100px',
    left: '10px',
    position: 'absolute',
    overflow: 'hidden',
    transition: 'box-shadow 0.2s linear',
    width: '100px',
    zIndex: 500,

    '&:hover': {
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.25)',
    },
  },
  map: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
});

const DEFAULT_OBJECT = {};
const MiniMap = ({ children, onClick = noop, omitFailure = false }) => {
  const classes = useStyles();
  const isFailure = useSelector(selectIsTileServerFormFailure);

  if (!omitFailure && isFailure) return null;

  const wrapperProps = onClick
    ? {
        onClick,
        role: 'button',
        tabIndex: 0,
      }
    : DEFAULT_OBJECT;

  return (
    <div className={classes.mapContainer} {...wrapperProps}>
      <MapContainer
        attributionControl={false}
        center={['37.3708853', '-122.0025719']}
        className={classes.map}
        zoom={8}
        zoomControl={false}
      >
        {children}
      </MapContainer>
    </div>
  );
};

MiniMap.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default MiniMap;
