import { GCS_ROTATIONS } from './constants';

export function getIpAddressFromRTSP(rtsp) {
  if (!rtsp) return null;

  let url = rtsp.split('@')[1];
  url = url ? url.split('/')[0] : null; // Isolate IP
  url = url ? url.split(':')[0] : null; // Remove port

  return url;
}

export function getNextGCS(gcs) {
  const index = GCS_ROTATIONS.findIndex(type => type === gcs);

  if (index > -1) {
    const newIndex = (index + 1) % GCS_ROTATIONS.length;
    return GCS_ROTATIONS[newIndex];
  }

  return GCS_ROTATIONS[0];
}

export function getDefaultGCS() {
  const gcs = localStorage.getItem('gcs');
  if (GCS_ROTATIONS.includes(gcs)) {
    return gcs;
  }

  return GCS_ROTATIONS[0];
}

export const pyBoolToJs = unsafeValue => {
  const value = unsafeValue?.toLowerCase();
  return value === 'true' ? true : value === 'false' ? false : value;
};

export const normalizeSettingsResponse = data =>
  data.reduce((acc, el) => {
    const { key, value } = el;
    return {
      ...acc,
      [key]: {
        ...el,
        value: pyBoolToJs(value),
      },
    };
  }, {});
