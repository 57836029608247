import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 100" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-22-14h128v128H-22z" />
      <g fill="#6599DE" fillRule="nonzero">
        <path d="M15.09 18.879c2.352 1.148 4.5 2.422 6.8 3.3a23.494 23.494 0 0 0 15.239.34c6.59-1.921 13.05-4.308 19.578-6.46 4.89-1.602 9.87-2.73 15.078-2.281l.004.003a22.527 22.527 0 0 1 11.64 4.48c.387.407.59.958.571 1.52v41.563c-1.07-.66-2-1.238-2.89-1.77v-.003a24.197 24.197 0 0 0-16.419-2.64c-6.449 1.058-12.41 3.6-18.512 5.75-5.609 2-11.25 3.82-17.3 3.66a23.011 23.011 0 0 1-13.142-4.56 1.579 1.579 0 0 1-.59-1.081c-.05-13.934-.07-27.871-.058-41.82l.001-.001z" />
        <path d="M11.719 100h-7V17.078a2.313 2.313 0 0 0-1.148-2.297 7.91 7.91 0 0 1-3.149-9.09A8.117 8.117 0 0 1 8.22.017 8.121 8.121 0 0 1 15.942 5.8a7.853 7.853 0 0 1-3.23 9 1.995 1.995 0 0 0-1 2V100h.007z" />
      </g>
    </g>
  </SvgIcon>
);
