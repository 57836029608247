import axios from 'axios.js';
import {
  GEO_ALERT_QUERY,
  GEO_ALERT_CONDITIONS,
  GEO_ALERT_RESULT,
  GEO_MISSION,
  GEO_MISSION_BOOKMARK,
  GEO_MISSION_BOOKMARK_REMOVE,
  GEO_MISSION_SHARE,
} from 'common/constants/urls';

export function getGeoAlertQueries(params) {
  return axios.get(`${GEO_ALERT_QUERY}/`, { params }).then(({ data }) => data);
}

export function getSingleGeoAlertQuery(id) {
  return axios.get(`${GEO_ALERT_QUERY}/${id}/`).then(({ data }) => data);
}

/**
  @param query query that you are creating
  @param query.geofenceId associated geofence ID
  @param query.query space de-limited list of object ids, e.g. "5 6 7"
  @param query.refImage image id of reference image
  @param query.name name of query
  @param query.mission mission id for which mission it belongs to
*/
export function postGeoAlertQuery(query) {
  return axios.post(`${GEO_ALERT_QUERY}/`, query).then(({ data }) => data);
}

export function patchGeoAlertQuery(query) {
  return axios
    .patch(`${GEO_ALERT_QUERY}/${query.id}/`, query)
    .then(({ data }) => ({ ...query, ...data }));
}

export function deleteGeoAlertQuery(id) {
  return axios.delete(`${GEO_ALERT_QUERY}/${id}/`).then(({ data }) => data);
}

/**
  @param params.filter_read boolean to send back only unseen results
*/
export function getGeoAlertQueryResults(id, params = { filter_seen: true }) {
  return axios.get(`${GEO_ALERT_QUERY}/${id}/results/`, { params }).then(({ data }) => data);
}

/**
  This is mainly to mark a result as "seen". In the future, we may allow other patchable attributes
  @param result.seen boolean true or false
*/
export function patchGeoAlertResult(id, result) {
  return axios.patch(`${GEO_ALERT_RESULT}/${id}/`, result).then(({ data }) => data);
}

export function getSingleMission(id) {
  return axios.get(`${GEO_MISSION}/${id}/`).then(({ data }) => data);
}

export function getMissions(params) {
  return axios.get(`${GEO_MISSION}/`, { params }).then(({ data }) => data);
}

/**
  @param mission mission that you are creating
  @param mission.name name of mission
*/
export function postMission(mission) {
  return axios.post(`${GEO_MISSION}/`, mission).then(({ data }) => data);
}

export function postMissionBookmarks(missions) {
  return axios.post(`${GEO_MISSION}${GEO_MISSION_BOOKMARK}`, { missions }).then(({ data }) => data);
}

export function postMissionRemoveBookmark(mission) {
  return axios
    .post(`${GEO_MISSION}/${mission}${GEO_MISSION_BOOKMARK_REMOVE}`)
    .then(({ data }) => data);
}

export function updateMissionSharing(mission, groups, users) {
  return axios
    .post(`${GEO_MISSION}/${mission.id}${GEO_MISSION_SHARE}`, { groups, users })
    .then(({ data }) => data);
}

export function patchMission(mission) {
  return axios.patch(`${GEO_MISSION}/${mission.id}/`, mission).then(({ data }) => data);
}

export function deleteMission(id) {
  return axios.delete(`${GEO_MISSION}/${id}/`).then(({ data }) => data);
}

export function getAlertConditions(queryId, params) {
  return axios
    .get(`${GEO_ALERT_QUERY}/${queryId}${GEO_ALERT_CONDITIONS}/`, { params })
    .then(({ data }) => data);
}

export function postAlertCondition(queryId, condition) {
  return axios
    .post(`${GEO_ALERT_QUERY}/${queryId}${GEO_ALERT_CONDITIONS}/`, condition)
    .then(({ data }) => data);
}

export function patchAlertCondition(queryId, condition) {
  return axios
    .patch(`${GEO_ALERT_QUERY}/${queryId}${GEO_ALERT_CONDITIONS}/?id=${condition.id}`, condition)
    .then(({ data }) => data);
}

export function deleteAlertCondition(queryId, id) {
  return axios
    .delete(`${GEO_ALERT_QUERY}/${queryId}${GEO_ALERT_CONDITIONS}/`, {
      params: { id },
    })
    .then(({ data }) => data);
}
