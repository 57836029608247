export const LARGE_HEIGHT = 32;
export const MED_HEIGHT = 30;
export const SMALL_HEIGHT = 26;

export const SMALL_ICON_FONT = 16;
export const MED_ICON_FONT = 20;
export const LARGE_ICON_FONT = 24;

export const SMALL_FONT = 11;
export const MED_FONT = 13;
export const LARGE_FONT = 14;

export const DISABLED_OPACITY = 0.3;
