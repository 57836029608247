export const UPLOAD_GROUP = 'Upload Group';
export const PRIORITY = 'Priority';
export const DATASOURCE = 'Data Source';
export const STATUS = 'Status';
export const DESTINATION = 'Destination';
export const UPLOADED_BY = 'Uploaded By';
export const UPLOADED_DATE = 'Started';
export const FILE_SIZE = 'File Size';
export const ACTION = '';
export const FILE_SIZE_FIELD = 'displayInfo.size';

export const JOB_GRP_LEVEL = 'job_group_level';
export const JOB_GRP_DETAIL_LEVEL = 'job_group_detail_level';
export const JOB_LEVEL = 'job_level';
export const STRIPED_HIGHLIGHT = '-striped -highlight';

export const JOB_LEVEL_MINIMUM_ROWS = 5;
export const MAX_PAGINATED_JOB_COUNT = 100;

export const COLUMNS = {
  [UPLOAD_GROUP]: { header: UPLOAD_GROUP, accessor: UPLOAD_GROUP, width: 180 },
  [PRIORITY]: { header: PRIORITY, accessor: PRIORITY, width: 60 },
  [DATASOURCE]: { header: DATASOURCE, accessor: DATASOURCE, width: 200 },
  [STATUS]: { header: STATUS, accessor: STATUS, width: 288 },
  [DESTINATION]: { header: DESTINATION, accessor: DESTINATION, width: 100 },
  [UPLOADED_BY]: { header: UPLOADED_BY, accessor: UPLOADED_BY, width: 100 },
  [UPLOADED_DATE]: {
    header: UPLOADED_DATE,
    accessor: UPLOADED_DATE,
    width: 100,
  },
  [FILE_SIZE]: { header: FILE_SIZE, accessor: FILE_SIZE, width: 72 },
  [ACTION]: { header: '', accessor: '', width: 56 },
};

export const COLUMNS_VALES = {
  [UPLOAD_GROUP]: {
    [JOB_GRP_LEVEL]: true,
    [JOB_GRP_DETAIL_LEVEL]: false,
    [JOB_LEVEL]: true,
  },
  [PRIORITY]: {
    [JOB_GRP_LEVEL]: false,
    [JOB_GRP_DETAIL_LEVEL]: true,
    [JOB_LEVEL]: true,
  },
  [DATASOURCE]: {
    [JOB_GRP_LEVEL]: false,
    [JOB_GRP_DETAIL_LEVEL]: true,
    [JOB_LEVEL]: true,
  },
  [STATUS]: {
    [JOB_GRP_LEVEL]: true,
    [JOB_GRP_DETAIL_LEVEL]: true,
    [JOB_LEVEL]: true,
  },
  [DESTINATION]: {
    [JOB_GRP_LEVEL]: true,
    [JOB_GRP_DETAIL_LEVEL]: false,
    [JOB_LEVEL]: false,
  },
  [UPLOADED_BY]: {
    [JOB_GRP_LEVEL]: true,
    [JOB_GRP_DETAIL_LEVEL]: false,
    [JOB_LEVEL]: false,
  },
  [UPLOADED_DATE]: {
    [JOB_GRP_LEVEL]: true,
    [JOB_GRP_DETAIL_LEVEL]: false,
    [JOB_LEVEL]: false,
  },
  [FILE_SIZE]: {
    [JOB_GRP_LEVEL]: false,
    [JOB_GRP_DETAIL_LEVEL]: true,
    [JOB_LEVEL]: true,
  },
  [ACTION]: {
    [JOB_GRP_LEVEL]: true,
    [JOB_GRP_DETAIL_LEVEL]: true,
    [JOB_LEVEL]: true,
  },
};

export const DB_DATA_SOURCE_NAME = 'datasource_name';
export const DB_FILE_SIZE = 'file_size';
export const DB_PRIORITY = 'priority';

export const JOBS_SORT_COLUMNS = {
  dataSource: DB_DATA_SOURCE_NAME,
  'displayInfo.size': DB_FILE_SIZE,
  Priority: DB_PRIORITY,
};

export const JOB_FILTER_PARAM = 'job_status[notin]';
export const JOB_FILTER_STATUS = 'completed,canceled';
