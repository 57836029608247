import React from 'react';

import { REGENT_GREY } from 'common/constants/colors';

export default ({ style: { color = REGENT_GREY, width = 20, height = width } = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        d="M11.97 2c2.65 0 5.05 1 6.9 2.6l2.6-2.6v7h-7l2.62-2.62C15.7 5.22 13.93 4.5 11.97 4.5c-3.54 0-6.55 2.31-7.6 5.5L2 9.22C3.39 5.03 7.32 2 11.97 2zM5.44 17.105l.575-5.058h5.578v1.647H7.635l-.246 2.14c.47-.25.968-.376 1.497-.376.948 0 1.69.294 2.229.882.537.588.806 1.41.806 2.468 0 .642-.135 1.218-.407 1.726a2.87 2.87 0 0 1-1.165 1.182c-.506.28-1.103.42-1.791.42-.602 0-1.16-.121-1.675-.365a2.97 2.97 0 0 1-1.22-1.029 2.804 2.804 0 0 1-.475-1.51h1.955c.04.414.186.737.434.967.248.23.573.345.974.345.447 0 .79-.16 1.032-.482.242-.321.363-.776.363-1.364 0-.565-.14-.998-.417-1.299-.278-.3-.673-.45-1.183-.45-.47 0-.85.122-1.142.368l-.191.178-1.572-.39zm11.827 2.851c0-.241-.12-.432-.359-.57-.24-.14-.623-.264-1.152-.373-1.759-.37-2.639-1.117-2.639-2.242 0-.657.273-1.205.817-1.645.545-.44 1.257-.66 2.137-.66.938 0 1.69.222 2.252.664.563.442.844 1.016.844 1.723h-1.975a.956.956 0 0 0-.274-.701c-.182-.185-.467-.277-.854-.277-.333 0-.59.075-.773.226a.712.712 0 0 0-.273.574c0 .219.104.395.31.53.208.134.558.25 1.05.348a8.57 8.57 0 0 1 1.244.332c1.044.383 1.566 1.046 1.566 1.989 0 .675-.29 1.22-.868 1.637-.58.417-1.327.626-2.243.626-.62 0-1.17-.11-1.65-.332-.481-.22-.858-.524-1.132-.909-.273-.385-.41-.8-.41-1.248h1.873c.018.351.148.62.39.807.241.187.565.28.97.28.379 0 .665-.071.858-.215a.67.67 0 0 0 .29-.564z"
      />
    </g>
  </svg>
);
