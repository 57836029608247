import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import { createUseStyles } from 'react-jss';

import Typography from 'components/Typography';
import Button from 'common/components/base/Button';
import TextInput from 'common/components/base/TextInput';
import LoadingIcon from 'common/components/generalComponents/LoadingIcon';

import { WHITE } from 'common/constants/colors';
import { selectCameraModels } from 'common/redux/models/selectors';
import { createCameraZone, editCameraZone, toggleEditMode } from 'library/redux/camera/actions';
import { selectZoneBeingEdited } from 'library/redux/camera/selectors';
import { updateCameraModels } from 'common/redux/models/actions';
import { useZones } from 'common/components/drawingOverlay/ZonesContext';
import { useCameraZones } from 'library/libraryBody/cameraView/CameraZonesContext';

import { CAMERA_ZONE } from 'common/constants/app';

import { extractErrorMessage } from 'common/helpers/apiErrorUtils';

const useStyles = createUseStyles(theme => ({
  dialog: {
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',
    minWidth: '350px',
  },
  actionsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  spacedButton: {
    marginRight: theme.spacing(0.5),
  },
  input: {
    flex: 1,
  },
  errorMessage: {
    marginBottom: theme.spacing(0.5),
  },
}));

const DrawingConfirmationDialog = () => {
  const zoneBeingEdited = useSelector(selectZoneBeingEdited);
  const isZoneBeingEdited = zoneBeingEdited.id !== undefined;
  const cameraModels = useSelector(selectCameraModels);
  const [inputtedName, setInputtedName] = useState(
    zoneBeingEdited.name ? zoneBeingEdited.name : ''
  );
  const [isCreatingZone, setIsCreatingZone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { zones, cancel, active } = useZones();
  const {
    isConfirmModalOpen,
    updateConfirmModal,
    fetchCamera,
    planningMode,
    zonesCameraId,
    onAddToPlan,
  } = useCameraZones();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentCamera = cameraModels[zonesCameraId] || {};
  const cameraName = currentCamera.displayName || 'Unknown';

  const zoneName = zoneBeingEdited?.name || inputtedName;
  const cameraLocation = currentCamera?.location?.formattedAddress || 'Unknown';
  const updateInputtedName = e => setInputtedName(e.target.value);
  const nameInputHasCharacters = zoneName?.trim().length > 0;
  const closeModal = (exitDrawMode = false) => {
    updateConfirmModal(false);
    if (exitDrawMode) {
      if (active < zones.length) cancel({ retainIndex: true });
      setInputtedName('');
      dispatch(toggleEditMode());
    }
  };

  const confirmCreateZone = async () => {
    setIsCreatingZone(true);
    setErrorMessage('');
    const currentZone = zones[zones.length - 1];
    const formattedZone = currentZone?.map(([x, y]) => ({ point: { x, y } }));
    try {
      let newZone;
      if (isZoneBeingEdited) {
        newZone = await dispatch(
          editCameraZone({
            currentlyEditingZone: zoneBeingEdited,
            name: zoneBeingEdited.name,
            zone: formattedZone,
          })
        );
      } else {
        newZone = await dispatch(
          createCameraZone({ cameraId: zonesCameraId, name: inputtedName, zone: formattedZone })
        );
      }
      const cameraData = await fetchCamera(zonesCameraId);
      if (cameraData) dispatch(updateCameraModels([cameraData]));
      if (planningMode && onAddToPlan) {
        onAddToPlan({ queryType: CAMERA_ZONE, query: [newZone.id] });
      }

      setIsCreatingZone(false);
      setInputtedName('');
      cancel({ retainIndex: true });
      dispatch(toggleEditMode());
      closeModal(false);
    } catch (err) {
      setErrorMessage(extractErrorMessage(err));
      setIsCreatingZone(false);
    }
  };

  const onKeyPress = e => {
    if (e.key === 'Enter' && nameInputHasCharacters) {
      confirmCreateZone();
    }
  };

  const renderNewZoneFlow = () => (
    <>
      <Typography variant="h3">New Zone</Typography>
      <Typography variant="subtitle1">{`Camera Name: ${cameraName}`}</Typography>
      <Typography variant="subtitle1">{`Camera Location: ${cameraLocation}`}</Typography>
      <TextInput
        value={inputtedName}
        onChange={updateInputtedName}
        label="Name"
        className={classes.input}
        data-testid="DrawingConfirmDialog-nameInput"
      />
      {errorMessage?.length > 0 && (
        <Typography variant="subtitle1" color="error" className={classes.errorMessage}>
          {errorMessage}
        </Typography>
      )}
    </>
  );

  const renderEditZoneFlow = () => <Typography variant="h3">Save Shape to {zoneName}</Typography>;

  return (
    <Dialog
      open={isConfirmModalOpen}
      onClose={closeModal}
      onKeyPress={onKeyPress}
      data-testid="ConfirmZone"
    >
      <div className={classes.dialog}>
        {isZoneBeingEdited ? renderEditZoneFlow() : renderNewZoneFlow()}
        <div className={classes.actionsRow}>
          <Button
            variant="outlined"
            className={classes.spacedButton}
            onClick={() => closeModal(true)}
            disabled={isCreatingZone}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={confirmCreateZone}
            disabled={!nameInputHasCharacters || isCreatingZone}
            data-testid="DrawingConfirmDialog-saveButton"
          >
            {isCreatingZone ? <LoadingIcon size={20} color={WHITE} /> : 'SAVE'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DrawingConfirmationDialog;
