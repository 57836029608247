import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Icon from 'common/components/base/Icon';
import { PRIMARY_GREY } from 'common/constants/colors';

const styles = {
  main: {
    position: 'relative',
  },
  icon: {
    transform: 'rotate(90deg)',
    transition: 'all 0.5s',
    color: PRIMARY_GREY,
  },
  iconWrapper: {
    background: 'white',
    borderRadius: 4,
    cursor: 'pointer',
    zIndex: 10,
    position: 'absolute',
    transition: 'all 0.5s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transitionDelay: '0.3s',
  },
  contentWrapper: {
    transition: 'all 0.5s',
    width: 'min-content',
    height: 'min-content',
    position: 'relative',
    zIndex: 1,
  },
  disabled: {
    pointerEvents: 'none',
  },
  enabled: {
    pointerEvents: 'all',
  },
};

function getIconLocation(origin) {
  switch (origin) {
    case 'top left':
      return {};
    case 'top right':
      return { right: 0 };
    case 'bottom left':
      return { bottom: 0 };
    case 'bottom right':
      return { bottom: 0, right: 0 };
    default:
      return {};
  }
}

class Minimize extends React.Component {
  static defaultProps = {
    transformOrigin: 'top left',
    transformScale: 'scale(0, 0)',
  };

  renderIcon = () => {
    const transformStyle = getIconLocation(this.props.transformOrigin);
    return (
      <div
        className={this.props.classes.iconWrapper}
        style={
          this.props.open
            ? { transitionDelay: '0s', ...transformStyle }
            : { boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.2)', ...transformStyle }
        }
      >
        <Icon
          iconName={this.props.open ? 'fullscreen_exit' : 'fullscreen'}
          onClick={this.props.onToggle}
          className={this.props.classes.icon}
          style={{
            color: PRIMARY_GREY,
            transform: this.props.open ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        />
      </div>
    );
  };

  render() {
    const {
      classes,
      className,
      children,
      open,
      renderIcon,
      style,
      transformScale,
      transformOrigin,
      wrapperStyles,
    } = this.props;

    const _wrapperStyles = {
      transform: open ? '' : transformScale,
      transformOrigin,
      ...wrapperStyles,
    };

    return (
      <div
        className={classnames(classes.main, className, {
          [classes.disabled]: !open,
        })}
        style={style}
      >
        <div className={classnames({ [classes.enabled]: !open })}>
          {renderIcon ? renderIcon() : this.renderIcon()}
        </div>
        <div className={classes.contentWrapper} style={_wrapperStyles}>
          {children}
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Minimize);
