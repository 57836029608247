import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import { AutoSizer, Table, Column, defaultTableRowRenderer } from 'react-virtualized';
import { DARK_GREY, PRIMARY_GREY, LIGHT_VIOLET, LIGHTEST_BLUE } from 'common/constants/colors';
import 'react-virtualized/styles.css';
import './styles.css';

import defaultHeaderRenderer from './DefaultHeaderRenderer';

const styles = theme => ({
  table: {
    '& > div:first-child': {
      borderBottom: `1px solid ${DARK_GREY}`,
      borderTop: `1px solid ${DARK_GREY}`,
    },
  },
  grid: {
    '&:focus': {
      outline: 'none',
    },
  },
  headers: {
    color: PRIMARY_GREY,
    fontWeight: 400,
    display: 'flex',
    textTransform: 'initial',
    alignItems: 'center',
    fontSize: 13,
  },
  row: {
    fontSize: 13,

    'div[draggable]:nth-of-type(odd) > &': {
      backgroundColor: LIGHT_VIOLET,
    },
    'div[draggable] > &[class *= "selected-"]': {
      backgroundColor: LIGHTEST_BLUE,
    },
    'div[draggable] > &:hover': {
      boxShadow: theme.shadows[3],
      borderTop: 'none',
      zIndex: 1,
    },
    'div[draggable] > &': {
      outline: 'none',
    },
  },
  column: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    minWidth: 16,
    marginRight: 8,

    '& > div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
});

class VirtualizedList extends React.Component {
  _onSort = data => {
    if (this.props.onSort) this.props.onSort(data);
  };

  _tableRef = node => {
    this._table = node;
    if (this.props.bindRef) {
      this.props.bindRef(node);
    }
  };

  _rowRenderer = options =>
    this.props.rowRenderer
      ? this.props.rowRenderer({ ...options, ...this.props })
      : defaultTableRowRenderer({ ...options });

  _headerRenderer = options =>
    this.props.headerRenderer
      ? this.props.headerRenderer({ ...options, ...this.props.headerProps })
      : defaultHeaderRenderer({ ...options });

  _renderColumn = (
    { label, dataKey, disable, cellRenderer, cellDataGetter, flex = 1, minWidth, maxWidth },
    idx
  ) => {
    const injectProps = {};

    /* We don't want to inject these props unless they exist */
    if (minWidth) injectProps.minWidth = minWidth;
    if (maxWidth) injectProps.maxWidth = maxWidth;

    const keyIndex = typeof dataKey === 'string' ? dataKey : dataKey();

    return (
      <Column
        {...injectProps}
        key={dataKey || idx}
        width={0}
        label={label}
        flexGrow={flex}
        dataKey={keyIndex}
        disableSort={disable}
        cellRenderer={cellRenderer}
        cellDataGetter={cellDataGetter}
        headerRenderer={this._headerRenderer}
        className={classnames(this.props.classes.column, this.props.columnClass)}
      />
    );
  };

  render() {
    const { data, classes, config, onRowClick, getRowHeight } = this.props;
    const rowGetter = ({ index }) => data[index];

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            ref={this._tableRef}
            height={height}
            width={width}
            rowHeight={getRowHeight || 40}
            listMargin={24}
            headerHeight={32}
            sort={this._onSort}
            sortBy={this.props.sortBy}
            sortDirection={this.props.sortDirection}
            rowCount={data.length}
            rowGetter={rowGetter}
            rowRenderer={this._rowRenderer}
            onRowClick={onRowClick}
            className={classes.table}
            gridClassName={classes.grid}
            headerClassName={classes.headers}
            rowClassName={classes.row}
          >
            {config.map(this._renderColumn)}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

export default injectSheet(styles)(VirtualizedList);
