import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Popover from '@material-ui/core/Popover';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateTime from 'dateTime';

import Icon from 'common/components/base/Icon';
import Tooltip from 'common/components/base/Tooltip';

import { parseToShorthandDate } from 'common/helpers/dateUtils';
import TextInput from 'common/components/base/TextInput';
import DateTimePicker from 'common/components/pickers/DateTimePicker';

const styles = {
  input: {
    width: '100%',
  },
  disabled: {
    pointerEvents: 'none',
  },
};

function renderDate(start) {
  return start ? parseToShorthandDate(start, { showSeconds: true }) : '';
}

class DateTimePickerInput extends React.Component {
  static defaultProps = {
    tooltipProps: {
      title: '',
    },
    renderDate,
  };

  state = {
    open: false,
    anchorEl: null,
  };

  open = e => this.setState({ open: true, anchorEl: e.currentTarget });

  close = () => this.setState({ open: false, anchorEl: null });

  onChange = date => {
    const { returnLuxonDate, onChange } = this.props;
    const returnDate = date && returnLuxonDate ? DateTime.convertToLuxonDate(date) : date;
    onChange(returnDate);
  };

  renderEndAdornement = () =>
    this.props.calendarIcon && <Icon component={DateRangeIcon} size="small" theme="grey" />;

  render() {
    const {
      classes,
      className,
      disabled,
      label,
      InputProps,
      tooltipProps,
      dataTestId,
      returnLuxonDate,
    } = this.props;

    return (
      <React.Fragment>
        <Tooltip {...tooltipProps}>
          <span className={classes.input}>
            <TextInput
              shrinkOnEmptyLabel
              label={label}
              endAdornment={this.renderEndAdornement()}
              value={this.props.renderDate(this.props.date)}
              onClick={this.open}
              disabled={disabled}
              className={classnames(classes.input, className, {
                [classes.disabled]: disabled,
              })}
              {...InputProps}
              data-testid={dataTestId}
            />
          </span>
        </Tooltip>
        <Popover
          open={this.state.open}
          onClose={this.close}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <DateTimePicker
            date={this.props.date}
            onChange={this.onChange}
            onClose={this.close}
            returnLuxonDate={returnLuxonDate}
          />
        </Popover>
      </React.Fragment>
    );
  }
}

export default injectSheet(styles)(DateTimePickerInput);
