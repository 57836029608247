import { DATASOURCE, IMAGE, PERSON, VENUE_MAP, DOCUMENT } from 'common/constants/app';

const defaults = {
  [DATASOURCE]: {
    extensions: ['.mp4'],
    mimeTypes: ['video/mp4'],
  },

  /* currently there is no endpoint for this so we will create defaults for now */
  [PERSON]: {
    extensions: ['.jpg', '.png'],
    mimeTypes: ['image/jpeg', 'image/png'],
  },

  [DOCUMENT]: {
    extensions: ['.zip', '.pdf', '.docx', '.pptx'],
    mimeTypes: [
      'application/zip',
      'application/x-zip',
      'application/x-zip-compressed',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  },

  /* geospatial shapefiles */
  shapefiles: {
    extensions: ['.shx', '.shp', '.dbf'],
  },

  [IMAGE]: {
    extensions: ['.jpeg', '.jpg', '.png'],
    mimeTypes: ['image/jpeg', 'image/jpg', 'image/png'],
  },

  [VENUE_MAP]: {
    extensions: ['.svg', '.jpg', '.jpeg', '.png'],
  },
};

export default function formatsReducer(state = defaults, action) {
  switch (action.type) {
    case 'COMMON/SET_DATASOURCE_FORMATS': {
      return {
        ...state,
        datasource: action.payload,
      };
    }
    default:
      return state;
  }
}
