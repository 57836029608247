import React from 'react';
import Menu from 'common/components/base/Menu';
import Icon from 'common/components/base/Icon';
import Button from 'common/components/base/Button';
import { Example } from '../helpers';

const alertClick = () => alert('Clicked'); // eslint-disable-line

const options = [
  {
    label: 'Profile',
    onClick: alertClick,
    icon: <Icon iconName="person" theme="grey" style={{ marginRight: 8 }} />,
  },
  {
    label: 'Settings',
    onClick: alertClick,
    icon: <Icon iconName="settings" theme="grey" style={{ marginRight: 8 }} />,
  },
  {
    label: 'Logout',
    onClick: alertClick,
    icon: <Icon iconName="exit_to_app" theme="grey" style={{ marginRight: 8 }} />,
  },
];

const options2 = [
  {
    label: 'Save',
    icon: <Icon iconName="save" theme="grey" style={{ marginRight: 8 }} />,
    clickable: false,
  },
  {
    label: 'Edit',
    icon: <Icon iconName="edit" theme="grey" style={{ marginRight: 8 }} />,
    clickable: false,
  },
  {
    label: 'Delete',
    icon: <Icon iconName="delete" theme="grey" style={{ marginRight: 8 }} />,
    clickable: false,
  },
];

const options3 = [
  {
    label: 'Profile',
    onClick: alertClick,
    icon: <Icon iconName="person" theme="grey" style={{ marginRight: 8 }} size="small" />,
  },
  {
    label: 'Settings',
    icon: <Icon iconName="settings" theme="grey" style={{ marginRight: 8 }} size="small" />,
    onClick: alertClick,
  },
  {
    label: 'Logout',
    onClick: alertClick,
    icon: <Icon iconName="exit_to_app" theme="grey" size="small" style={{ marginRight: 8 }} />,
  },
];

const options4 = [
  {
    label: 'Profile',
    onClick: alertClick,
    disabled: true,
  },
  {
    label: 'Settings',
    onClick: alertClick,
    disabled: true,
    tooltip: 'This is a tooltip',
  },
  {
    label: 'Logout',
    onClick: alertClick,
    tooltip: 'You can logout',
  },
];

class MenuExample extends React.Component {
  state = {
    menu1: false,
    menu2: false,
    menu3: false,
    menu1El: null,
    menu2El: null,
    menu3El: null,

    positionedMenu: false,
    x: null,
    y: null,
  };

  open = prop => e => this.setState({ [prop]: true, [`${prop}El`]: e.target });

  close = prop => () => this.setState({ [prop]: false, [`${prop}El`]: null });

  openPositionedMenu = e =>
    this.setState({
      positionedMenu: true,
      x: e.nativeEvent.pageX,
      y: e.nativeEvent.pageY,
    });

  closePositionedMenu = () => this.setState({ positionedMenu: false, x: null, y: null });

  render() {
    return (
      <div>
        <Example label="Basic Menu">
          <Icon
            iconName="more_horiz"
            color="grey"
            onClick={this.open('menu1')}
            style={{ cursor: 'pointer' }}
          />
          <Menu
            open={this.state.menu1}
            onClose={this.close('menu1')}
            options={options}
            anchorEl={this.state.menu1El}
          />
        </Example>

        <Example label="Non-dense Menu and Non-clickable items">
          <Button onClick={this.open('menu2')}>OPEN MENU</Button>
          <Menu
            dense={false}
            open={this.state.menu2}
            onClose={this.close('menu2')}
            options={options2}
            anchorEl={this.state.menu2El}
          />
        </Example>

        <Example label="Smaller Font Size">
          <Button onClick={this.open('menu3')} variant="outlined" style={{ marginRight: 8 }}>
            SMALL
          </Button>
          <Menu
            size="small"
            open={this.state.menu3}
            onClose={this.close('menu3')}
            options={options3}
            anchorEl={this.state.menu3El}
          />
        </Example>

        <Example label="Positioned Menu and Disabled State with Tooltips">
          <div
            role="none"
            onClick={this.openPositionedMenu}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              minWidth: 400,
              minHeight: 100,
              background: 'black',
              color: 'white',
            }}
          >
            CLICK ANYWHERE
          </div>
          <Menu
            anchorReference="anchorPosition"
            anchorPosition={{ left: this.state.x, top: this.state.y }}
            open={this.state.positionedMenu}
            onClose={this.closePositionedMenu}
            options={options4}
            anchorEl={this.state.positionedMenu}
          />
        </Example>
      </div>
    );
  }
}

export default MenuExample;
