import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react-local';
import Link from '@material-ui/core/Link';

import Avatar from 'common/components/base/Avatar';
import Button from 'common/components/base/Button';
import Checkbox from 'common/components/base/Checkbox';
import Chip from 'common/components/base/Chip';
import CircularLoader from 'common/components/base/CircularLoader';
import ExpansionPanel from 'common/components/base/ExpansionPanel';
import FullScreenDialog from 'components/Dialog/FullScreenDialog';
import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';
import LinearProgress from 'common/components/base/LinearProgress';
import Menu from 'common/components/base/Menu';
import MirageHeading from 'common/components/base/MirageHeading';
import Notifications from 'app/notifications/Notifications';
import OverflowText from 'common/components/base/OverflowText';
import Select from 'common/components/base/Select';
import Tabs from 'common/components/base/TabsOld';
import TextField from 'common/components/base/TextInput';
import Tooltip from 'common/components/base/Tooltip';
import Typography from 'components/Typography';
import UserSelect from 'common/components/generalComponents/inputs/UserSelect';

import * as Actions from 'app/redux/actions';
import { LIBRARY } from 'app/redux/constants';
import NewPicker from './pickers/NewPicker';

import { Example } from './helpers';

const alertClick = () => alert('Clicked'); // eslint-disable-line

const selectOptions = [
  { label: 'Grid', value: '1' },
  { label: 'Map', value: '2' },
  { label: 'List', value: '3' },
];

const menuOptions = [
  {
    label: 'Profile',
    onClick: alertClick,
    icon: <Icon iconName="person" theme="grey" style={{ marginRight: 8 }} />,
  },
  {
    label: 'Settings',
    onClick: alertClick,
    icon: <Icon iconName="settings" theme="grey" style={{ marginRight: 8 }} />,
  },
  {
    label: 'Logout',
    onClick: alertClick,
    icon: <Icon iconName="exit_to_app" theme="grey" style={{ marginRight: 8 }} />,
  },
];

const useStyles = makeStyles()({
  wrapper: {
    '& > div': {
      marginRight: 8,
    },
  },
  flexWrapper: {
    display: 'flex',
    '& > div': {
      marginRight: 8,
    },
  },
  maxWidthLabel: {
    maxWidth: 250,
  },
  loader: {
    marginBottom: 16,
    marginRight: 16,
  },
  custom: {
    marginBottom: 16,
  },
  form: {
    minWidth: 120,
    marginRight: 8,
  },
});

const tabs = [
  {
    label: 'FIRST TAB',
    value: 1,
  },
  {
    label: 'SECOND TAB',
    value: 2,
  },
];

const ExamplesSummary = () => {
  const { classes } = useStyles();

  const [openDialogNumber, setOpenDialogNumber] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const [id, setId] = useState(0);

  const [selectValue, setSelectValue] = useState('');

  const [selectedTab, setSelectedTab] = useState(1);

  const [fieldValue, setFieldValue] = useState('');

  return (
    <div>
      <MirageHeading
        top={<Typography variant="h3">Summary of Common Components</Typography>}
        bottom={
          <Typography>
            Click the link on an example to see more variants and code snippets
          </Typography>
        }
        style={{ marginBottom: 24 }}
      />
      <Example label={<Link href="/#/examples/button">Button</Link>}>
        <Button style={{ marginRight: 8 }}>Primary Call-to-action</Button>
        <Button variant="outlined">Secondary call-to-action</Button>
      </Example>
      <Example label={<Link href="/#/examples/checkbox">Checkbox</Link>}>
        <Checkbox color="secondary" />
        <Checkbox indeterminate />
      </Example>
      <Example label={<Link href="/#/examples/chip">Chip</Link>} className={classes.wrapper}>
        <Chip label="Default Chip" color="default" />
        <Chip label="Primary Color" />
        <Chip label="Avatar Chip" avatar={<Avatar>MR</Avatar>} />
        <Chip
          label="Clickable Avatar Chip"
          avatar={
            <Avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
          }
          onClick={alertClick} // eslint-disable-line
          onDelete={() => alert('Deleted')} // eslint-disable-line
        />
      </Example>
      <Example label={<Link href="/#/examples/dialog">Full-Screen Dialog</Link>}>
        <Button onClick={() => setOpenDialogNumber(2)}>Open</Button>
        <FullScreenDialog open={openDialogNumber === 2} onClose={() => setOpenDialogNumber(null)}>
          Fill-Screen Dialog Content
        </FullScreenDialog>
      </Example>
      <Example label={<Link href="/#/examples/expansion-panel">Expansion Panel</Link>}>
        <ExpansionPanel label="Category Title">
          The content within this panel is now visible.
        </ExpansionPanel>
      </Example>
      <Example label={<Link href="/#/examples/icon">Icon Button and Icon</Link>}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="primary">
            <Icon iconName="add" />
          </IconButton>
          <IconButton color="secondary">
            <Icon iconName="home" />
          </IconButton>
          <Icon
            iconName="person"
            style={{
              height: 44,
              width: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'orange',
            }}
          />
          <Icon
            iconName="language"
            style={{
              color: 'red',
              height: 44,
              width: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </div>
      </Example>
      <Example label={<Link href="/#/examples/loaders">Loaders</Link>} style={{ maxWidth: 600 }}>
        <LinearProgress className={classes.loader} value={75} />
        <LinearProgress className={classes.loader} value={75} variant="query" />
        <LinearProgress
          className={classes.loader}
          value={75}
          variant="indeterminate"
          theme="green"
        />
        <CircularLoader className={classes.loader} color="navy" variant="indeterminate" />
        <CircularLoader className={classes.loader} color="red" variant="indeterminate" />
        <CircularLoader className={classes.loader} color="orange" value={50} />
      </Example>

      <Example label={<Link href="/#/examples/menu">Menu</Link>}>
        <Icon
          iconName="more_horiz"
          color="grey"
          onClick={e => setOpenMenu(e.target)}
          style={{ cursor: 'pointer' }}
        />
        <Menu
          open={!!openMenu}
          onClose={() => setOpenMenu(false)}
          options={menuOptions}
          anchorEl={openMenu}
        />
      </Example>

      <Example
        label={<Link href="/#/examples/notifications">Notification</Link>}
        className={classes.flexWrapper}
      >
        <div>
          <Button
            onClick={() => {
              const note = { id, message: `Notification ${id}` };
              dispatch(Actions.renderNotification(note));
              setId(prev => prev + 1);
            }}
          >
            Notify Me
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              dispatch(Actions.renderErrorMessage(`Error ${id}`));
              setId(prev => prev + 1);
            }}
          >
            Error Me
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              const note = { notification: { id, message: `Library ${id}` }, type: LIBRARY };
              dispatch(Actions.renderGenericNotification(note));
              setId(prev => prev + 1);
            }}
          >
            Library Me
          </Button>
        </div>
        <Notifications />
      </Example>
      <Example label={<Link href="/#/examples/overflowText">Overflow Text</Link>}>
        <OverflowText width={100}>
          1. Some text that will not fit within the given width
        </OverflowText>
        <br />
        <OverflowText width={300}>2. Some text that DOES fit in the given width</OverflowText>
      </Example>
      <Example label={<Link href="/#/examples/pickers">Date Picker</Link>}>
        <div style={{ marginTop: 10 }}>
          <NewPicker />
        </div>
      </Example>
      <Example label={<Link href="/#/examples/select">Select</Link>}>
        <Select
          label="Display"
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
          options={selectOptions}
          className={classes.form}
          helperText="Select display type"
        />
        <Select
          label="Display"
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
          options={selectOptions}
          required
          placholder="Placeholder"
          helperText="Select display type"
          className={classes.form}
        />
        <Select
          label="Age"
          helperText="Disabled"
          className={classes.form}
          value={selectValue}
          onChange={e => setSelectValue(e.target.value)}
          options={selectOptions}
          disabled
        />
      </Example>
      <Example label={<Link href="/#/examples/tabs">Tabs</Link>}>
        <div>
          <Tabs onChange={(e, i) => setSelectedTab(i)} value={selectedTab} tabs={tabs} />
        </div>
      </Example>
      <Example label={<Link href="/#/examples/text-input">Text Field</Link>}>
        <TextField
          value={fieldValue}
          onChange={e => setFieldValue(e.target.value)}
          label="Report Name"
          helperText="Please enter name"
        />
      </Example>
      <Example
        label={<Link href="/#/examples/tooltip">Tooltip</Link>}
        className={classes.flexWrapper}
      >
        <div>
          <Tooltip title="Add" placement="top">
            <Button>TOP</Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Add" placement="bottom">
            <Button>BOTTOM</Button>
          </Tooltip>
        </div>
      </Example>
      <Example label={<Link href="/#/examples/typography">Typography</Link>}>
        <Typography variant="h5" style={{ marginTop: 10 }}>
          h5. Header 5
        </Typography>
        <Typography variant="body1">body1. Body 1</Typography>
        <MirageHeading
          top="PLAN YOUR MISSION"
          bottom="RECOGNIZE • CHARACTERIZE • CONTEXTUALIZE"
          style={{ marginBottom: 24 }}
        />
        <MirageHeading top="Viewport Center" bottom="Santa Clara, California" switchHeaders />
      </Example>
      <Example label={<Link href="/#/examples/userSelect">User Select</Link>}>
        <UserSelect onChange={() => {}} disabledUserIds={[1, 2, 3, 4]} />
      </Example>
    </div>
  );
};

export default ExamplesSummary;
