import { useSelector } from 'react-redux';

import Icon from 'common/components/base/Icon';
import { BRIGHT_GREEN, ERROR, TRANSPARENT_WHITE, WARNING_COLOR } from 'common/constants/colors';
import {
  MIRAGE_HEALTH_UNKNOWN,
  MIRAGE_HEALTH_GOOD,
  MIRAGE_HEALTH_WARNING,
  MIRAGE_HEALTH_ERROR,
} from 'common/constants/app';
import { selectWsConnectionHealth } from 'app/redux/selectors';

import * as S from './redux/selectors';
import { getMirageConnectivity, getHealth } from './utils';
import { getSearchHealth, getServicesHealth } from './computeHealthInfoStatus';

const iconStyle = { fontSize: 26, cursor: 'pointer' };

const GoodStatus = <Icon style={iconStyle} iconName="check_circle" color={BRIGHT_GREEN} />;
const WarningStatus = <Icon style={iconStyle} iconName="error" color={WARNING_COLOR} />;
const ErrorStatus = <Icon style={iconStyle} iconName="error" color={ERROR} />;
const UnknownStatus = <Icon style={iconStyle} iconName="check_circle" color={TRANSPARENT_WHITE} />;

function getStatusIcon(status) {
  switch (status) {
    case MIRAGE_HEALTH_GOOD:
      return GoodStatus;
    case MIRAGE_HEALTH_WARNING:
      return WarningStatus;
    case MIRAGE_HEALTH_ERROR:
      return ErrorStatus;
    case MIRAGE_HEALTH_UNKNOWN:
      return UnknownStatus;

    default:
      return UnknownStatus;
  }
}

const MirageHealthIcon = () => {
  const wsHealth = useSelector(selectWsConnectionHealth);
  const health = useSelector(S.selectMirageHealth);
  const responseTime = useSelector(S.selectHealthResponseTime);

  const healthFlag = health.summary?.flag;
  const mirageStatus = getMirageConnectivity(responseTime);
  const searchHealth = getSearchHealth(health.searchInfo);
  const serviceHealth = getServicesHealth(health.servicesInfo);

  const status = getHealth({
    health: healthFlag,
    mirageStatus: mirageStatus.flag,
    wsHealth,
    searchHealth,
    serviceHealth,
  });
  return getStatusIcon(status);
};

export default MirageHealthIcon;
