import React from 'react';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import RcPagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

import './pagination.css';

export default class Pagination extends React.Component {
  onChange = currentPage => {
    const { limit } = this.props;
    if (this.props.onPageChange) {
      const offset = (currentPage - 1) * limit;
      this.props.onPageChange({ page: currentPage, offset });
    }
  };

  render() {
    const { style, limit, offset, total, disabled, showTotals, ...rest } = this.props;

    const currentPage = offset / limit + 1;

    return (
      <RcPagination
        style={style}
        disabled={disabled}
        onChange={this.onChange}
        current={currentPage}
        pageSize={limit}
        total={total}
        locale={localeInfo}
        showTotal={
          showTotals ? () => `${offset + 1} - ${Math.min(offset + limit, total)} of ${total}` : null
        }
        data-testid="Pagination"
        {...rest}
      />
    );
  }
}
