export const RELATIONSHIP_STATUS = {
  CONFIRMED: 'CONFIRMED',
};
export const TIME_STAMP_FORMAT = 'dd LLL yyyy HH:mm:ss';

export const SORT_BY_TIME = 'Sort by Time';
export const SORT_BY_TIME_VALUE = 1;
export const SORT_BY_CONFIDENCE = 'Sort by Similarity';
export const SORT_BY_CONFIDENCE_VALUE = 2;

export const MIN_CONFIDENCE = 0;
export const MAX_CONFIDENCE = 100;
export const DEFAULT_CONFIDENCE_INTERVAL = [MIN_CONFIDENCE, MAX_CONFIDENCE];

// 5 cols prevents the user from having to do a lot of horizontal scrolling
export const NUM_RELATED_SIGHTINGS_COLS = 5;

export const FACES = 'Faces';
export const TIMELINE = 'Timeline';

export const OBJECT_TYPES = {
  PEOPLE: 0,
  VEHICLES: 2,
};
