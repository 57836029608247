import { useCallback, useState, useMemo, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { compact, sortBy } from 'lodash';

import useInterval from 'common/hooks/useInterval';
import Button from 'common/components/base/Button';
import FullScreenDialog from 'components/Dialog/FullScreenDialog';

import { PRIMARY, SECONDARY_TEXT, DARK_BORDER } from 'common/constants/colors';
import { fetchProcessingData } from 'library/redux/actions';
import { selectProcessingJobs } from 'library/redux/selectors';
import {
  closeProcessingPanel,
  setProcessingPanelResultsMeta,
} from 'library/common/globalProcessingPanel/redux/actions';
import { selectGlobalProcessingPanelOpen } from 'library/common/globalProcessingPanel/redux/selectors';

import GeospatialJobTable from './GeospatialJobTable';

const useStyles = createUseStyles({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: PRIMARY,
    minHeight: 48,
    color: SECONDARY_TEXT,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 48,
    borderBottom: `1px solid ${DARK_BORDER}`,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 20,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  button: {
    opacity: 0.5,
    cursor: 'default',
  },
  list: {
    flexGrow: 1,
  },
});

const ProcessingListView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const processingJobs = useSelector(selectProcessingJobs);
  const open = useSelector(selectGlobalProcessingPanelOpen);

  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [pagination, setPagination] = useState({
    sortBy: 'created',
    sortDir: 'DESC',
    page: 0,
  });

  const fetchJobs = useCallback(() => {
    setIsFetchingData(true);
    dispatch(fetchProcessingData()).finally(() => {
      setIsLoading(false);
      setIsFetchingData(false);
    });
  }, [dispatch]);

  const handleConfigChange = (config = {}) =>
    compact(Object.values(config)).length && setPagination({ ...pagination, ...config });

  const onClose = useCallback(() => dispatch(closeProcessingPanel(false)), [dispatch]);

  // backend is not returning sorted results, performing sort in UI
  const sortedData = useMemo(() => {
    const { batches = [] } = processingJobs;
    const sorted = pagination.sortBy ? sortBy(batches, pagination.sortBy) : batches;
    return pagination.sortDir === 'DESC' ? sorted.reverse() : sorted;
  }, [pagination.sortBy, pagination.sortDir, processingJobs]);

  useInterval(() => !isFetchingData && fetchJobs(), 30000);

  // fetch jobs on dialog open
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      dispatch(setProcessingPanelResultsMeta({ limit: 999999 }, true));
      fetchJobs();
    }
  }, [dispatch, fetchJobs, open]);

  return (
    <FullScreenDialog open={open} onClose={onClose}>
      <article className={classes.content}>
        <header className={classes.header} data-testid="ProcessingListView">
          <div className={classes.left}>Geospatial Image Processing Details</div>
          <div className={classes.right}>
            <Button
              style={{ minWidth: 0, color: 'white' }}
              size="large"
              theme="transparent"
              color="secondary"
              onClick={onClose}
              data-testid="GlobalProcessingPanel-close"
            >
              HIDE
            </Button>
          </div>
        </header>
        <div className={classes.actions}>
          <div className={classes.left}>{processingJobs.totalBatches} jobs</div>
        </div>
        <div className={classes.list}>
          <GeospatialJobTable
            isLoading={isLoading}
            onSortChange={sortConfig => handleConfigChange({ ...sortConfig })}
            onPageSizeChange={limit => handleConfigChange({ limit })}
            onPageChange={page => handleConfigChange({ page: page + 1 })}
            data={sortedData}
            totalJobs={processingJobs.totalBatches || 0}
          />
        </div>
      </article>
    </FullScreenDialog>
  );
};

export default ProcessingListView;
