import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108" {...props}>
    <path
      fill="#6599DE"
      fillRule="evenodd"
      d="M.368 24.915L45.37 45.37 24.915.365c5.666-1.886 13.22 3.78 16.995 11.332l18.393 40.457 2.378 1.083c15.105 5.666 13.22 13.219 22.662 3.776 2.36-2.36 5.666-3.776 9.442-3.776 7.548 0 13.215 5.662 13.215 13.219 0 7.551-5.667 13.218-13.22 13.218-3.775 0-7.081-1.416-9.442-3.776-14.79-14.79-17.133-13.307-22.71-13.267-.036 5.573-1.522 7.92 13.268 22.709 2.36 2.36 3.776 5.666 3.776 9.442 0 7.551-5.667 13.218-13.22 13.218-7.552 0-13.219-5.666-13.219-13.218 0-3.776 1.416-7.082 3.777-9.442 9.442-9.443 1.886-7.552-3.777-22.66L52.151 60.3 11.69 41.91C4.143 38.13-1.524 30.578.367 24.916zm94.558 48.98c3.695 0 7.39-3.695 7.39-7.39 0-3.694-3.695-7.39-7.39-7.39-3.694 0-7.39 3.696-7.39 7.39 0 3.695 3.696 7.39 7.39 7.39zm-28.42 28.42c3.694 0 7.389-3.694 7.389-7.389 0-3.694-3.695-7.39-7.39-7.39-3.694 0-7.39 3.696-7.39 7.39 0 3.695 3.696 7.39 7.39 7.39z"
    />
  </SvgIcon>
);
