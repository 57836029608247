import axios from 'axios.js';

import { ANALYTICS_BACKEND } from 'common/constants/urls';

export function getSearchRequests(params) {
  return axios.get(`${ANALYTICS_BACKEND}/search-request/`, { params }).then(({ data }) => data);
}

export function getSearchRequest(requestId) {
  return axios.get(`${ANALYTICS_BACKEND}/search-request/${requestId}/`).then(({ data }) => data);
}

export function postSearchRequest(body, params) {
  return axios
    .post(`${ANALYTICS_BACKEND}/search-request/`, body, { params })
    .then(({ data }) => data);
}

export function postSearchRequestEstimate(body) {
  return postSearchRequest(body, { estimate: true });
}

export function postSearchRequestRecommendation(body) {
  return postSearchRequest(body, { recommendation: true });
}

export function getSearchRequestResults(requestId, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search-request/${requestId}/results/`, {
      params,
    })
    .then(({ data }) => data);
}

export function getSearchRequestResultGroups(requestId, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search-request/${requestId}/results/group/`, { params })
    .then(({ data }) => data);
}

export function getSearchRequestResultFrames(requestId, videoId, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search-request/${requestId}/result/${videoId}/nframes/`, {
      params,
    })
    .then(({ data }) => data);
}

export function getSearchRequestResultTimelineData(requestId, videoId, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search-request/${requestId}/result/${videoId}/timeline/`, {
      params,
    })
    .then(({ data }) => data);
}

export function postSearchRequestCancel(requestId) {
  return axios
    .post(`${ANALYTICS_BACKEND}/search-request/${requestId}/cancel/`)
    .then(({ data }) => data);
}
