import CryptoJS from 'crypto-js';

export function getUrl(image = {}) {
  return image.preview || image.thumbnailUrl || image;
}

export function getDefaultName(file) {
  return file ? file.name.split('.')[0] : null;
}

/*
  NOTE: We might need to think about somehow dealing with all of
  this duplication logic and multiple identity logic in the backend
*/
export function removeDuplicateFiles(files) {
  const hashes = {};
  const result = [];

  files.forEach(file => {
    const hash = CryptoJS.MD5(file) + String(file.size);
    if (!hashes[hash]) {
      result.push(file);
      hashes[hash] = true;
    }
  });

  return result;
}

export function isNamingError(error = {}) {
  return Boolean(error?.details?.existing_name);
}

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
