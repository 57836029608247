const defaults = {
  viewportAddress: {},
  isLoadingSearchResults: false,
  isLoadingLightResults: false,
  isGeoInspectModeEnabled: false,
  timeline: {
    selectedDatasetId: null,
    selectedResubmitDatasetId: null,
    timesliceDatasets: [],
    selectedTimesliceIndex: null,
  },
  mapData: {
    lightResultsByImage: {}, // A dictionary of results by geoimage ID
    lightResultsInView: [],
    imagesInViewport: [],
    imagesInTimeslice: [],
    imagesInDataset: [],
    inspectResultsForViewport: [],
    resultsByImageId: [],
    searchResultsInView: [],
    uniqueImages: [],
    imagesInViewportWithResults: [],
    searchResultsForViewport: [],
  },
};

export default function geoAnalysisPanelReducer(state = defaults, action) {
  switch (action.type) {
    case `ANALYSIS_GEOSPATIAL/RESET_MAP_PANEL`: {
      return defaults;
    }

    case `ANALYSIS_GEOSPATIAL/SET_IS_GEO_INSPECT_MODE_ENABLED`: {
      return {
        ...state,
        isGeoInspectModeEnabled: action.payload,
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_LOADING_SEARCH_RESULTS`: {
      return {
        ...state,
        isLoadingSearchResults: action.payload,
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_LOADING_LIGHT_RESULTS`: {
      return {
        ...state,
        isLoadingLightResults: action.payload,
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_VIEWPORT_ADDRESS`: {
      return {
        ...state,
        viewportAddress: action.payload,
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_SELECTED_DATASET_ID`: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          selectedDatasetId: action.payload,
        },
      };
    }
    case `ANALYSIS_GEOSPATIAL/SET_SELECTED_RESUBMIT_DATASET_ID`: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          selectedResubmitDatasetId: action.payload,
        },
      };
    }
    case `ANALYSIS_GEOSPATIAL/SET_SELECTED_TIMESLICE_INDEX`: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          selectedTimesliceIndex: action.payload,
        },
      };
    }
    case `ANALYSIS_GEOSPATIAL/SET_TIMESLICE_INFO`: {
      const { timesliceInfo } = action.payload;
      const { timeline } = state;
      if (
        timesliceInfo &&
        (timesliceInfo.index !== timeline.selectedTimesliceIndex ||
          timesliceInfo.datasetId !== timeline.selectedDatasetId)
      ) {
        return {
          ...state,
          timeline: {
            ...timeline,
            selectedDatasetId: timesliceInfo.datasetId,
            selectedTimesliceIndex: timesliceInfo.index,
            selectedResubmitDatasetId: timesliceInfo.datasetId,
          },
        };
      }
      return state;
    }

    case `ANALYSIS_GEOSPATIAL/SET_TIMESLICE_DATASETS`: {
      return {
        ...state,
        timeline: {
          ...state.timeline,
          timesliceDatasets: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_LIGHT_RESULTS_BY_IMAGE`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          lightResultsByImage: {
            ...state.mapData.lightResultsByImage,
            ...action.payload,
          },
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_LIGHT_RESULTS_IN_VIEW`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          lightResultsInView: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_IMAGES_IN_VIEWPORT`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          imagesInViewport: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_IMAGES_IN_TIMESLICE`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          imagesInTimeslice: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_IMAGES_IN_DATASET`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          imagesInDataset: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_RESULTS_BY_IMAGE_ID`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          resultsByImageId: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_SEARCH_RESULTS_IN_VIEW`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          searchResultsInView: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_UNIQUE_IMAGES`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          uniqueImages: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_IMAGES_IN_VIEWPORT_WITH_RESULTS`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          imagesInViewportWithResults: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_SEARCH_RESULTS_FOR_VIEWPORT`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          searchResultsForViewport: action.payload,
        },
      };
    }

    case `ANALYSIS_GEOSPATIAL/SET_INSPECT_RESULTS_FOR_VIEWPORT`: {
      return {
        ...state,
        mapData: {
          ...state.mapData,
          inspectResultsForViewport: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
