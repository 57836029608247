import { useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { noop } from 'lodash';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

import getDefaultIcon from './mapIcons/default';

const MapMarkers = ({
  autoCenter = true,
  markers = [],
  onMarkerClick = noop,
  showMarkers = true,
  limitAutoCenter = false,
}) => {
  const map = useMap();
  const hasCentered = useRef(false);
  const prevMarkerIds = useRef(null);

  const areMarkersValid = useMemo(() => {
    if (!markers || !markers.length) return false;

    try {
      const positions = markers.map(({ position }) => position);
      // Side-effect-free method for validating marker positions
      map.getBoundsZoom(positions);
    } catch (err) {
      // If the markers are invalid, don't crash Mirage
      console.error(err);
      return false;
    }
    return true;
  }, [map, markers]);

  const centerMap = useCallback(() => {
    if (areMarkersValid) {
      const positions = markers.map(({ position }) => position);
      map.fitBounds(positions);
    }
  }, [areMarkersValid, map, markers]);

  useEffect(() => {
    // Mixing react-leaflet and normal leaflet causes a race condition where maxZoom isnt set,
    // and marker cluster group's implode without it.
    if (map && !map._layersMaxZoom) map._layersMaxZoom = 18;
  }, [map]);

  // Auto center map once on initial map and marker load
  useEffect(() => {
    if (!areMarkersValid || !autoCenter || markers.length <= 0 || hasCentered.current) {
      return;
    }

    hasCentered.current = true;

    centerMap();
  }, [autoCenter, areMarkersValid, centerMap, markers]);

  // Auto center map if marker IDs change across updates
  useEffect(() => {
    if (!areMarkersValid || !showMarkers || !autoCenter || markers.length <= 0) {
      return;
    }

    const markerIds = JSON.stringify(markers.map(({ id }) => id));
    if (prevMarkerIds.current === markerIds) return;

    prevMarkerIds.current = markerIds;
    centerMap();
  }, [areMarkersValid, autoCenter, centerMap, markers, showMarkers]);

  useEffect(() => {
    if (!areMarkersValid || !showMarkers) return;
    const markerGroup = L.markerClusterGroup();

    markers.map(({ icon, id, mediaId, position, resultType, options, tooltip }) => {
      const marker = L.marker(position, {
        icon: icon ?? getDefaultIcon(),
      }).on('click', () => {
        onMarkerClick({ id, mediaId, resultType, options });
      });

      if (tooltip) {
        marker.bindTooltip(tooltip, { className: 'tooltip', direction: 'bottom' });
      }

      return markerGroup.addLayer(marker);
    });

    map.addLayer(markerGroup);
    if (!limitAutoCenter) centerMap();

    return () => {
      markerGroup.clearLayers();
      map.removeLayer(markerGroup);
    };
  }, [areMarkersValid, centerMap, limitAutoCenter, map, markers, onMarkerClick, showMarkers]);

  return null;
};

MapMarkers.propTypes = {
  autoCenter: PropTypes.bool,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape(),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      mediaId: PropTypes.string,
      position: PropTypes.arrayOf(PropTypes.number),
      resultType: PropTypes.string,
      options: PropTypes.shape(),
      tooltip: PropTypes.string,
    })
  ),
  onMarkerClick: PropTypes.func,
  showMarkers: PropTypes.bool,
};

export default MapMarkers;
