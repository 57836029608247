import { MAX_PAGINATED_JOB_COUNT } from '../constants';

const defaults = {
  limit: MAX_PAGINATED_JOB_COUNT,
  offset: 0,
  total: 0,
  sortBy: 'priority',
  batchId: '',
  isPaginated: false,
  open: false,
  showCancelJobGroup: false,
  jobGroup: null,
};

export default function processingPanelReducer(state = defaults, action) {
  switch (action.type) {
    case 'ANALYSIS/SET_PROCESSING_PANEL_RESULTS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'ANALYSIS/SET_PROCESSING_PANEL_OPEN': {
      return {
        ...state,
        open: action.payload.open,
      };
    }
    case 'ANALYSIS/SHOW_CANCEL_JOB_GROUP': {
      return {
        ...state,
        showCancelJobGroup: action.payload.showCancelJobGroup,
      };
    }
    case 'ANALYSIS/SET_JOB_GROUP': {
      return {
        ...state,
        jobGroup: action.payload.jobGroup,
      };
    }
    default:
      return state;
  }
}
