export function getPriorQueryFromSearchRequest(searchRequest) {
  const query = { ...searchRequest.filterArgs };
  query.q = searchRequest.query;

  return JSON.stringify(query);
}

/* If search results hits this limit, it means that some data might be missing from the analysis results */
export const MAX_SEARCH_RESULTS_LIMIT = 2000;

export function extractErrorsFromSearchRequest(searchRequest) {
  const result = {
    missingDatasources: [],
    invalidDatasources: [],
    areResultsOverflowed: false,
  };

  if (!searchRequest) return result;

  if (searchRequest.status) {
    result.missingDatasources = searchRequest.status.missingDatasources || [];
    result.invalidDatasources = searchRequest.status.invalidDatasources || [];
    result.areResultsOverflowed =
      searchRequest.status.matchedSegmentsCount >= MAX_SEARCH_RESULTS_LIMIT;
  }

  return result;
}

/* Will need to replace all this logic with a proper parser */
export function replaceVerbalOperators(text) {
  let query = text.replace(/\s+/g, ' ').trim();

  query = query.replace(/ and not /gi, ' &! ');
  query = query.replace(/ or not /gi, ' |! ');
  query = query.replace(/ and /gi, ' & ');
  query = query.replace(/ or /gi, ' | ');

  return query;
}

export function getIdStringFromObject(idObject) {
  return Object.keys(idObject).join(',');
}

function capitalizeObjectMatch(match) {
  return match[1].toUpperCase();
}

/* removes colons from object names and capitalizes the object name */
export function getLexiconFromQueryString(query) {
  return query.replace(/:.{1}/g, capitalizeObjectMatch);
}
