import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react-local';
import { BANNER_HEIGHT } from 'app/ClassificationBanner';
import { selectBannerHeaderText } from 'settings/redux/selectors';
import MirageLogo, { MIRAGE_LOGO_HEIGHT } from 'common/icons/mirage-logo';

export const TRANSITION_DURATION = '0.3s';

const useStyles = makeStyles()((_theme, { hasBanner }) => ({
  main: {
    transitionProperty: 'width minWidth marginRight',
    transitionDuration: TRANSITION_DURATION,
    position: 'relative',
    height: MIRAGE_LOGO_HEIGHT,
  },
  logo: {
    position: 'fixed',
    left: 70,
    top: hasBanner ? 11 + BANNER_HEIGHT : 11,
  },
}));

const HideableMirageLogo = ({ className, ...rest }) => {
  const hasBanner = !!useSelector(selectBannerHeaderText);
  const { classes, cx } = useStyles({ hasBanner });

  return (
    <div className={cx(classes.main, className)} {...rest}>
      <MirageLogo className={classes.logo} />
    </div>
  );
};

export default HideableMirageLogo;
