import React from 'react';
import { withStyles } from 'tss-react-local';
import TextInput from 'common/components/base/TextInput';
import Button from 'common/components/base/Button';
import SplitPanelGroup from 'common/components/splitPanel/SplitPanelGroup';
import SplitPanel from 'common/components/splitPanel/SplitPanel';
import DetectionInterpolator from 'common/components/videoPlayer/utils/DetectionInterpolator';
import { SECONDARY } from 'common/constants/colors';
import VideoPlayer from 'common/components/videoPlayer/VideoPlayer';
import VideoThumbnails from './VideoThumbnails';

const styles = {
  wrapper: {
    position: 'relative',
    height: '100%',
    '& video': {
      height: '100%',
    },
  },
  generatedThumb: {
    cursor: 'pointer',
    marginRight: 10,
    textAlign: 'center',
    height: '100px',
    width: '130px',
    border: '1px solid rgb(230,230,230)',
  },
  selected: {
    border: `3px solid ${SECONDARY}`,
  },
};

class VideoPlayerExample extends React.Component {
  state = {
    panelConfig: [{ position: 80 }],
  };

  componentDidMount() {
    this.interpolator = new DetectionInterpolator();
  }

  onUpdatePanelConfig = config => this.setState({ panelConfig: config });

  updateTime = () => {
    this.videoPlayer.seekTo(parseFloat(this.state.textValue));
  };

  fetchDatasourceVideoData = datasource => {
    this.setState({ datasource });
  };

  render() {
    const { classes } = this.props;

    return (
      <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        <SplitPanelGroup
          split="rows"
          config={this.state.panelConfig}
          onUpdateConfig={this.onUpdatePanelConfig}
        >
          <SplitPanel>
            <div className={classes.wrapper}>
              {this.state.datasource && (
                <VideoPlayer
                  kinetic
                  bindRef={node => (this.videoPlayer = node)}
                  datasource={this.state.datasource}
                />
              )}
            </div>
          </SplitPanel>

          <SplitPanel>
            <div style={{ padding: '15px', height: '100%' }}>
              <section>
                <TextInput
                  value={this.state.textValue}
                  onValueChange={val => this.setState({ textValue: val })}
                />
                <Button onClick={this.updateTime}>Set Video Time</Button>
              </section>
              <VideoThumbnails onClickDatasource={this.fetchDatasourceVideoData} />
            </div>
          </SplitPanel>
        </SplitPanelGroup>
      </div>
    );
  }
}

export default withStyles(VideoPlayerExample, styles);
