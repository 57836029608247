import { createMuiTheme } from '@material-ui/core/styles';
import {
  ACCENT,
  ERROR,
  GREEN,
  OFF_WHITE,
  OFF_BLACK,
  ORANGE,
  PRIMARY,
  PRIMARY_GREY,
  PURPLE,
  SECONDARY,
  SECONDARY_GREY,
  TEXT,
  WHITE,
  YELLOW,
} from 'common/constants/colors';

const NON_COLOR_STRING = '__nope__';
const DEFAULT_COLOR = 'default';
const INEHRIT_COLOR = 'inherit';
const PRIMARY_COLOR = 'primary';
const SECONDARY_COLOR = 'secondary';

const COLORS = {
  [DEFAULT_COLOR]: DEFAULT_COLOR,
  [INEHRIT_COLOR]: INEHRIT_COLOR,
  [PRIMARY_COLOR]: PRIMARY_COLOR,
  [SECONDARY_COLOR]: SECONDARY_COLOR,
};

/* Mui theme requires 25 elevations, but sometimes we want to completely nullify elevations */
const MUI_NO_SHADOWS = [];
for (let i = 0; i < 25; i++) {
  MUI_NO_SHADOWS.push('none');
}

/* Default Mui Shadows for each elevation (0 - 24) from Mui website
  const DEFAULT_SHADOWS = [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ];
*/

const SHADOWS = [
  'none',
  '0px 1px 2px 0px rgba(0,0,0,0.15)',
  '0px 2px 1px -2px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  '0 2px 4px 0 rgba(120, 144, 156, 0.5)',
  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
  '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
];

/* exported for custom typography styles */
export const typographyDefaults = {
  letterSpacing: 0,
  lineHeight: 1.4,
};

const defaultTheme = {
  fontFamily: 'Roboto, sans-serif',
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1080,
      lg: 1280,
      xl: 1920,
    },
  },
  shadows: SHADOWS,
  palette: {
    primary: {
      main: SECONDARY,
      contrastText: OFF_WHITE,
    },
    secondary: {
      main: PRIMARY,
    },
    error: {
      main: ERROR,
    },
    // These theme properties, action.disabled and action.disabledBackground, are what give our
    // buttons the appropriate styling in the disabled state
    action: {
      disabled: NON_COLOR_STRING, // DO NOT REMOVE
      disabledBackground: NON_COLOR_STRING, // DO NOT REMOVE
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
    purple: PURPLE,
  },

  overrides: {
    MuiTabs: {
      root: {
        color: OFF_BLACK,
      },
      indicator: {
        backgroundColor: YELLOW,
        height: 3,
      },
    },
    MuiTab: {
      root: {
        fontWeight: 400,
        fontSize: '0.8125rem',
      },
      wrapper: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flex: 1,
      },
      labelIcon: {
        minHeight: 50,
      },
      textColorInherit: {
        opacity: 0.4,
      },
    },
    MuiTypography: {
      h6: {
        fontSize: 11,
        fontWeight: 500,
        ...typographyDefaults,
      },
      h5: {
        fontSize: 13,
        fontWeight: 500,
        ...typographyDefaults,
      },
      h4: {
        fontSize: 14,
        fontWeight: 500,
        ...typographyDefaults,
      },
      h3: {
        fontSize: 18,
        fontWeight: 500,
        ...typographyDefaults,
      },
      h2: {
        fontSize: 22,
        fontWeight: 500,
        ...typographyDefaults,
      },
      h1: {
        fontSize: 28,
        fontWeight: 500,
        ...typographyDefaults,
      },
      subtitle1: {
        color: PRIMARY_GREY,
        fontSize: 13,
        fontWeight: 400,
        ...typographyDefaults,
      },
      subtitle2: {
        color: PRIMARY_GREY,
        fontSize: 11,
        fontWeight: 400,
        ...typographyDefaults,
      },
      body1: {
        fontSize: 13,
        fontWeight: 400,
        ...typographyDefaults,
      },
      body2: {
        fontSize: 11,
        fontWeight: 400,
        ...typographyDefaults,
      },
    },
  },
};

const redTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: ERROR,
      contrastText: OFF_WHITE,
    },
  },
};

const greenTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: GREEN,
      contrastText: OFF_WHITE,
    },
  },
};

const greyTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: PRIMARY_GREY,
    },
    secondary: {
      main: SECONDARY_GREY,
    },
  },
};

const yellowTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: YELLOW,
    },
  },
};

const orangeTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: ORANGE,
      contrastText: OFF_WHITE,
    },
  },
};

const blueTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: SECONDARY,
      contrastText: OFF_WHITE,
    },
  },
};

const lightblueTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: ACCENT,
      contrastText: TEXT,
    },
  },
};

const darkblueTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: PRIMARY,
    },
  },
};

const blackTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: OFF_BLACK,
      contrastText: OFF_WHITE,
    },
  },
};

const whiteTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: WHITE,
      dark: OFF_WHITE,
      contrastText: SECONDARY,
    },
  },
};

const transparentTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      dark: 'rgba(0, 0, 0, 0.08)',
      main: 'rgba(255, 255, 255, 0)',
      contrastText: PRIMARY,
    },
    secondary: {
      dark: 'rgba(0, 0, 0, 0.08)',
      main: 'rgba(255, 255, 255, 0)',
      contrastText: SECONDARY,
    },
    border: 0,
  },

  /* Will make the button theme clear/transparent */
  shadows: MUI_NO_SHADOWS,
};

const themes = {
  defaultTheme: createMuiTheme(defaultTheme),
  darkblueTheme: createMuiTheme(darkblueTheme),
  redTheme: createMuiTheme(redTheme),
  blueTheme: createMuiTheme(blueTheme),
  blackTheme: createMuiTheme(blackTheme),
  greyTheme: createMuiTheme(greyTheme),
  greenTheme: createMuiTheme(greenTheme),
  lightblueTheme: createMuiTheme(lightblueTheme),
  orangeTheme: createMuiTheme(orangeTheme),
  transparentTheme: createMuiTheme(transparentTheme),
  whiteTheme: createMuiTheme(whiteTheme),
  yellowTheme: createMuiTheme(yellowTheme),
};

export const getTheme = theme => themes[`${theme}Theme`] || null;
export const getColor = color => COLORS[color] || undefined;

export default themes;

/*
  MUI Docs:

  https://material-ui.com/customization/default-theme/

  ** palette.__.main is required

  palette: {
    primary: {
      light: palette.primary[300],
      main: palette.primary[500],
      dark: palette.primary[700],
      contrastText: getContrastText(palette.primary[500]),
    },
    secondary: {
      light: palette.secondary.A200,
      main: palette.secondary.A400,
      dark: palette.secondary.A700,
      contrastText: getContrastText(palette.secondary.A400),
    },
    error: {
      light: palette.error[300],
      main: palette.error[500],
      dark: palette.error[700],
      contrastText: getContrastText(palette.error[500]),
    },
  }
*/
