export const AND_OPERATOR = '&';
export const OR_OPERATOR = '|';
export const AND_NOT_OPERATOR = '&!';
export const OR_NOT_OPERATOR = '|!';
export const NOT_OPERATOR = '!';

export const OPERATORS = {
  [AND_OPERATOR]: 'and',
  [AND_NOT_OPERATOR]: 'and-not',
  [OR_OPERATOR]: 'or',
  [OR_NOT_OPERATOR]: 'or-not',

  // For syntax checks
  '(': true,
  ')': true,
};

export const TEXT_OPERATORS = {
  and: AND_OPERATOR,
  'and-not': AND_NOT_OPERATOR,
  'and not': AND_NOT_OPERATOR,
  or: OR_OPERATOR,
  'or-not': OR_NOT_OPERATOR,
  'or not': OR_NOT_OPERATOR,
  not: NOT_OPERATOR,
};

export const QUERY_OPERATORS = [
  'and',
  'and-not',
  'and not',
  'or',
  'or-not',
  'or not',
  'not',
  AND_OPERATOR,
  OR_OPERATOR,
  AND_NOT_OPERATOR,
  OR_NOT_OPERATOR,
  NOT_OPERATOR,
];

export const DEFAULT_OPERATOR = 'or';
