import React from 'react';
import injectSheet from 'react-jss';
import Icon from 'common/components/base/Icon';
import classnames from 'classnames';
import { inRange, isFinite } from 'lodash';

import Typography from 'components/Typography';
import IconButton from 'common/components/base/IconButton';
import TextInput from 'common/components/base/TextInput';
import { zeroPad } from 'common/helpers/helperFunctions';
import { PRIMARY_GREY } from 'common/constants/colors';

const TIME_WIDTH = 28;
const HOURS = 'hours';
const MINUTES = 'minutes';
const SECONDS = 'seconds';

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    '& > *': {
      minWidth: TIME_WIDTH,
      maxWidth: TIME_WIDTH,
      margin: '0 16px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  colon: {
    minWidth: 0,
    maxWidth: 0,
    position: 'relative',
    margin: 0,
    right: theme.spacing(0.125),
    bottom: theme.spacing(0.375),
  },
  unitLabel: {
    color: PRIMARY_GREY,
  },
  input: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
});

class CustomTimePickerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getTimes() };
  }

  componentDidMount() {
    if (this.props.selectByDefault && this.hours) this.hours.select();
  }

  componentDidUpdate(prevProps) {
    const hasTimeChanged = prevProps.time !== this.props.time && this.props.time;

    if (hasTimeChanged) {
      this.updateTimes();
    }
  }

  inputRefHours = node => {
    this.hours = node;
    if (this.props.inputRefHours) this.props.inputRefHours(node);
  };

  inputRefMinutes = node => {
    this.minutes = node;
    if (this.props.inputRefMinutes) this.props.inputRefMinutes(node);
  };

  inputRefSeconds = node => {
    this.seconds = node;
    if (this.props.inputRefSeconds) this.props.inputRefSeconds(node);
  };

  updateTimes = () => {
    this.setState({ ...this.getTimes() });
  };

  getTimes = () => {
    const paddedHours = zeroPad(this.props.time.hour, 2);
    const paddedMinutes = zeroPad(this.props.time.minute, 2);
    const paddedSeconds = zeroPad(this.props.time.second, 2);

    return {
      hours: paddedHours,
      minutes: paddedMinutes,
      seconds: paddedSeconds,
    };
  };

  onTimeChange = (unit, direction) => () => {
    const newTime = this.props.time.plus({ [unit]: 1 * direction });
    this.props.onChange(newTime);
  };

  onWheel = (unit, disableIncrease, disableDecrease) => e => {
    if (e.nativeEvent.deltaY < 0 && !disableIncrease) {
      this.props.onChange(this.props.time.plus({ [unit]: 1 }));
    } else if (e.nativeEvent.deltaY > 0 && !disableDecrease) {
      this.props.onChange(this.props.time.minus({ [unit]: 1 }));
    }
  };

  onChangeTime = unit => e => {
    const { value } = e.target;
    if (isFinite(Number(value)) && value.length < 3) {
      this.setState({ [unit]: e.target.value }, () => {
        if (value.length === 2) {
          if (unit === HOURS && this.minutes) {
            this.minutes.select();
          } else if (unit === MINUTES && this.seconds) {
            this.seconds.select();
          }
        }
      });
    }
  };

  onProcessTime = unit => e => {
    const value = Number(e.target.value);
    let { time } = this.props;
    if (unit === HOURS) {
      const hours = inRange(value, 0, 24) && isFinite(value) ? zeroPad(value, 2) : 0;
      time = time.set({ hour: hours });
      this.props.onChange(time);
    } else if (unit === MINUTES) {
      const minutes = inRange(value, 0, 60) && isFinite(value) ? zeroPad(value, 2) : 0;
      time = time.set({ minute: minutes });
      this.props.onChange(time);
    } else if (unit === SECONDS) {
      const seconds = inRange(value, 0, 60) && isFinite(value) ? zeroPad(value, 2) : 0;
      time = time.set({ second: seconds });
      this.props.onChange(time);
    }
  };

  onKeyPress = type => e => {
    if (e.key === 'Enter') {
      if (type === HOURS && this.minutes) {
        this.minutes.select();
      } else if (type === MINUTES && this.seconds) {
        this.seconds.select();
      }
    }
  };

  onKeyDown = type => e => {
    if (e.key === 'ArrowUp') {
      if (type === HOURS && !this.props.disableHourIncrease) {
        this.onTimeChange('hour', 1)();
      } else if (type === MINUTES && !this.props.disableMinuteIncrease) {
        this.onTimeChange('minute', 1)();
      } else if (type === SECONDS && !this.props.disableSecondIncrease) {
        this.onTimeChange('second', 1)();
      }
    } else if (e.key === 'ArrowDown') {
      if (type === HOURS && !this.props.disableHourDecrease) {
        this.onTimeChange('hour', -1)();
      } else if (type === MINUTES && !this.props.disableMinuteDecrease) {
        this.onTimeChange('minute', -1)();
      } else if (type === SECONDS && !this.props.disableSecondDecrease) {
        this.onTimeChange('second', -1)();
      }
    }
  };

  render() {
    const {
      classes,
      style,
      className,
      disableHourIncrease,
      disableHourDecrease,
      disableMinuteIncrease,
      disableMinuteDecrease,
      disableSecondIncrease,
      disableSecondDecrease,
      dataTestId,
    } = this.props;

    return (
      <div className={classnames(classes.main, className)} style={style} data-testid={dataTestId}>
        <div className={classes.row}>
          <Typography component="div" className={classnames(classes.unitLabel)} variant="h3">
            H
          </Typography>
          <Typography component="div" className={classnames(classes.unitLabel)} variant="h3">
            M
          </Typography>
          <Typography component="div" className={classnames(classes.unitLabel)} variant="h3">
            S
          </Typography>
        </div>
        <div className={classes.row}>
          <div>
            <IconButton
              disabled={disableHourIncrease}
              customClasses={{ root: classes.button }}
              onClick={!disableHourIncrease ? this.onTimeChange('hour', 1) : undefined}
              data-testid="CustomTimePickerView-hour-increase"
            >
              <Icon iconName="keyboard_arrow_up" theme="grey" />
            </IconButton>
          </div>
          <div>
            <IconButton
              disabled={disableMinuteIncrease}
              customClasses={{ root: classes.button }}
              onClick={!disableMinuteIncrease ? this.onTimeChange('minute', 1) : undefined}
              data-testid="CustomTimePickerView-minute-increase"
            >
              <Icon iconName="keyboard_arrow_up" theme="grey" />
            </IconButton>
          </div>
          <div>
            <IconButton
              disabled={disableSecondIncrease}
              customClasses={{ root: classes.button }}
              onClick={!disableSecondIncrease ? this.onTimeChange('second', 1) : undefined}
              data-testid="CustomTimePickerView-second-increase"
            >
              <Icon iconName="keyboard_arrow_up" theme="grey" />
            </IconButton>
          </div>
        </div>
        <div className={classes.row}>
          <TextInput
            InputProps={{
              inputProps: { className: classes.input },
              disableUnderline: true,
              inputRef: this.inputRefHours,
            }}
            onBlur={this.onProcessTime(HOURS)}
            onChange={this.onChangeTime(HOURS)}
            onKeyDown={this.onKeyDown(HOURS)}
            onKeyPress={this.onKeyPress(HOURS)}
            onWheel={this.onWheel('hour', disableHourIncrease, disableHourDecrease)}
            onFocus={e => e.target.select()}
            shrinkOnEmptyLabel
            value={this.state.hours}
            data-testid="CustomTimePickerView-hour"
          />
          <Typography className={classnames(classes.colon, classes.display)} variant="h3">
            :
          </Typography>
          <TextInput
            InputProps={{
              inputProps: { className: classes.input },
              disableUnderline: true,
              inputRef: this.inputRefMinutes,
            }}
            onBlur={this.onProcessTime(MINUTES)}
            onChange={this.onChangeTime(MINUTES)}
            onKeyDown={this.onKeyDown(MINUTES)}
            onKeyPress={this.onKeyPress(MINUTES)}
            onWheel={this.onWheel('minute', disableMinuteIncrease, disableMinuteDecrease)}
            onFocus={e => e.target.select()}
            shrinkOnEmptyLabel
            value={this.state.minutes}
            data-testid="CustomTimePickerView-minute"
          />
          <Typography className={classnames(classes.colon, classes.display)} variant="h3">
            :
          </Typography>
          <TextInput
            InputProps={{
              inputProps: { className: classes.input },
              disableUnderline: true,
              inputRef: this.inputRefSeconds,
            }}
            onBlur={this.onProcessTime(SECONDS)}
            onChange={this.onChangeTime(SECONDS)}
            onKeyDown={this.onKeyDown(SECONDS)}
            onKeyPress={this.onKeyPress(SECONDS)}
            onWheel={this.onWheel('second', disableSecondIncrease, disableSecondDecrease)}
            onFocus={e => e.target.select()}
            shrinkOnEmptyLabel
            value={this.state.seconds}
            data-testid="CustomTimePickerView-second"
          />
        </div>
        <div className={classes.row}>
          <div>
            <IconButton
              disabled={disableHourDecrease}
              customClasses={{ root: classes.button }}
              onClick={!disableHourDecrease ? this.onTimeChange('hour', -1) : undefined}
              data-testid="CustomTimePickerView-hour-decrease"
            >
              <Icon iconName="keyboard_arrow_down" theme="grey" />
            </IconButton>
          </div>
          <div>
            <IconButton
              disabled={disableMinuteDecrease}
              customClasses={{ root: classes.button }}
              onClick={!disableMinuteDecrease ? this.onTimeChange('minute', -1) : undefined}
              data-testid="CustomTimePickerView-minute-decrease"
            >
              <Icon iconName="keyboard_arrow_down" theme="grey" />
            </IconButton>
          </div>
          <div>
            <IconButton
              disabled={disableSecondDecrease}
              customClasses={{ root: classes.button }}
              onClick={!disableSecondDecrease ? this.onTimeChange('second', -1) : undefined}
              data-testid="CustomTimePickerView-second-decrease"
            >
              <Icon iconName="keyboard_arrow_down" theme="grey" />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(CustomTimePickerView);
