import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import useIdentityPackagePageTitle from './useIdentityPackagePageTitle';
import EditIdentityLink from './EditIdentityLink';

const IdentityLinkDialog = ({ closeUrl }) => {
  const { linkId } = useParams();
  useIdentityPackagePageTitle(`Identity Link ${linkId}`);
  const history = useHistory();
  const handleClose = () => history.replace(closeUrl);

  return (
    <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
      <EditIdentityLink id={linkId} onClose={handleClose} />
    </Dialog>
  );
};

export default IdentityLinkDialog;
