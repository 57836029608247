import React from 'react';
import Typography from 'components/Typography';
import { useMission } from './MissionContext';

const Person = ({ id }) => {
  const { nameByPersonId } = useMission();
  return (
    <div>
      <Typography>{nameByPersonId(id)}</Typography>
    </div>
  );
};

export default Person;
