import React from 'react';
import data from './detections.json';

const COL_NUM = 150;

function buildConfig(detectionData) {
  // 300 / 20 = 15;
  const bucketSize = Math.ceil(detectionData.frames.length / COL_NUM);

  const result = { count: 0 };

  detectionData.frames.forEach((frame, frameIndex) => {
    frame.d.forEach(detection => {
      const category = detection.k;
      if (!result[category]) {
        result[category] = new Array(COL_NUM).fill(0);
      }

      const fillIndex = Math.floor(frameIndex / bucketSize);
      result[category][fillIndex] += 1;

      // Hack but fast
      result.count += 1;
    });
  });

  return result;
}

const lookup = {
  0: 'Face',
  4: 'Person',
  11: 'Backpack',
};

class VideoSummaryGridExample extends React.Component {
  renderTable = rows => {
    let keyIndex = 0;
    const rowViews = Object.keys(rows).map(key => {
      if (key === 'count') {
        return;
      }
      const row = rows[key];

      const cols = row.map(num => {
        let color = 'rgb(230,230,230)';

        if (num > 8) {
          // color = 'green';
          color = '#599532';
        } else if (num > 4) {
          // color = 'orange'
          color = '#B3E095';
        } else if (num >= 1) {
          // color = 'red'
          color = '#E9F7E0';
        }

        return (
          <td key={keyIndex++} style={{ margin: 0, padding: 0, border: 0, background: color }}>
            <div style={{ width: '5px', fontSize: '9px', textAlign: 'center' }}>{null && num}</div>
          </td>
        );
      });

      return (
        <tr key={key}>
          <td>
            <div style={{ width: '100px' }}>{lookup[key]}</div>
          </td>
          {cols}
        </tr>
      );
    });

    return (
      <table style={{ width: '900px', display: 'block', borderSpacing: 0 }}>
        <tbody>{rowViews}</tbody>
      </table>
    );
  };

  render() {
    const result = buildConfig(data);

    return (
      <div>
        <h3>Total Detections: {result.count}</h3>
        <div>Groups: {COL_NUM}</div>
        {this.renderTable(result)}
      </div>
    );
  }
}

export default VideoSummaryGridExample;
