import { clamp, has, round } from 'lodash';

import findObjectByName from 'common/helpers/findObjectByName';
import findPersonByName from 'common/helpers/findPersonByName';

const START_BUFFER_PERCENT = 0.02;
const MIN_START_BUFFER_MS = 30000;
const END_BUFFER_PERCENT = 0.04;
const MIN_END_BUFFER_MS = 60000;

const THIRTY_MIN_IN_MS = 1800000;

export function buildInitialTimelineBounds(time, duration, isLive) {
  if (time == null || duration == null) return;
  const startBuffer =
    duration * START_BUFFER_PERCENT < MIN_START_BUFFER_MS
      ? MIN_START_BUFFER_MS
      : duration * START_BUFFER_PERCENT;

  const endBuffer =
    duration * END_BUFFER_PERCENT < MIN_END_BUFFER_MS
      ? MIN_END_BUFFER_MS
      : duration * END_BUFFER_PERCENT;

  const startTime = time - startBuffer;
  const endTime = time + endBuffer;

  // Roughly the start of the last 30 minute block
  const liveMarkerStartRatio = isLive && (duration - THIRTY_MIN_IN_MS) / duration;
  const startRatio = liveMarkerStartRatio || startTime / duration;
  const endRatio = isLive ? 1 : endTime / duration;

  const start = clamp(round(startRatio, 2), 0, 0.99);
  const end = endRatio > 1 ? 1 : round(endRatio, 2);

  return {
    start: Number.isNaN(start) ? 0 : start,
    end: Number.isNaN(end) ? 1 : end,
  };
}

export function buildTimelineData(initTimelineData = [], startEpochMs, startTime = 0) {
  return initTimelineData.map(group => {
    const segments = [];
    (group.segments || []).forEach(seg => {
      const segment = { ...seg };

      /*
      Do not add the date if there is a startEpochMs because the backend will already process
      these videos at the correct timestamp
    */
      if (!startEpochMs) {
        if (has(segment, 'toTime')) segment.toTime += startTime;
        if (has(segment, 'fromTime')) segment.fromTime += startTime;
      }

      segments.push(segment);
    });

    const person = findPersonByName(group.groupId);
    const object = findObjectByName(group.groupId);

    const thumbURL = person?.images[0];
    const objectKey = object?.id;

    return { ...group, segments, thumbURL, objectKey };
  });
}
