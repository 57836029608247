import ReactDOM from 'react-dom';

import { makeStyles } from 'tss-react-local';
import Typography from 'components/Typography';

const useStyles = makeStyles()(theme => ({
  tooltip: {
    background: 'rgba(44, 48, 50, 0.7)', // OFF_BLACK at 70% opacity
    borderRadius: '2px',
    color: '#ffffff',
    padding: theme.spacing(0.5),
    position: 'fixed',
    whiteSpace: 'nowrap',
    zIndex: 1301,
    transform: 'translate(10px, -25px)',
  },
}));

const DrawingTooltip = ({ title, style }) => {
  const { classes } = useStyles();

  return ReactDOM.createPortal(
    <div className={classes.tooltip} style={style}>
      <Typography variant="body2">{title}</Typography>
    </div>,
    document.getElementById('root')
  );
};

export default DrawingTooltip;
