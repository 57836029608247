import { useMemo, useState, useCallback, useEffect } from 'react';

import Tooltip from 'common/components/base/Tooltip';
import MultiSelect from 'common/components/base/MultiSelect';

const InspectTypesSelect = ({ onSelection, inspectModeTypes, ...rest }) => {
  const options = useMemo(
    () => inspectModeTypes.map(({ displayName: label, id: value }) => ({ label, value })),
    [inspectModeTypes]
  );
  const [selections, setSelections] = useState(options.map(({ value }) => value));
  const [value, setValue] = useState([]);

  const renderValue = useCallback(() => {
    if (value?.length > 5) {
      return `${value.slice(0, 5).join(', ')}...`;
    }
    return value.join(', ');
  }, [value]);

  useEffect(() => {
    const _value = inspectModeTypes.flatMap(({ id, displayName }) =>
      selections.includes(id) ? displayName : []
    );
    setValue(_value);
    if (onSelection) onSelection(selections);
  }, [selections, onSelection, inspectModeTypes]);

  return (
    <Tooltip title={value.join(', ')}>
      <>
        <MultiSelect
          hideClearIcon
          renderValue={renderValue}
          options={options}
          showCheckboxes
          shrinkOnEmptyLabel
          inputLabelProps={{ shrink: true }}
          value={selections}
          onItemsSelected={setSelections}
          {...rest}
        />
      </>
    </Tooltip>
  );
};

export default InspectTypesSelect;
