export const NAME = 'name';
export const CREATED = 'created';
export const UPDATED = 'updated';
export const DATE = 'date';

export function sortByName({ name: nameA }, { name: nameB }) {
  return nameA.localeCompare(nameB);
}

export function sortByUsername({ username: nameA }, { username: nameB }) {
  return nameA.localeCompare(nameB);
}

export function sortByCreated({ created: dateA = 0 }, { created: dateB = 0 }) {
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

export function sortByUpdated({ updated: dateA = 0 }, { updated: dateB = 0 }) {
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

export function sortByDate({ date: dateA = 0 }, { date: dateB = 0 }) {
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}
