import {
  TEST_ANALYSIS_PLAN_SORT_NAME,
  TEST_ANALYSIS_PLAN_SORT_CHRONOLOGICAL,
} from '__testSetup__/constants';

export const planSortConfig = [
  {
    value: 0,
    label: 'Name',
    ...TEST_ANALYSIS_PLAN_SORT_NAME,
  },
  {
    value: 1,
    label: 'Chronological',
    ...TEST_ANALYSIS_PLAN_SORT_CHRONOLOGICAL,
  },
];

export const planDatasourceSortFunctions = [sortByName, sortByChronology];

function sortByName({ title: titleA, name: nameA }, { title: titleB, name: nameB }) {
  return titleA ? titleA.localeCompare(titleB) : nameA.localeCompare(nameB);
}

function sortByChronology({ date: dateA = 0 }, { date: dateB = 0 }) {
  return new Date(dateB || 0).getTime() - new Date(dateA || 0).getTime();
}

export const INCOMPLETE_ANALYSIS_QUERY = 'Select a datasource and person/object to analyze';

export const DATASOURCE_VIEW = 'list';
export const MAP_VIEW = 'map';
