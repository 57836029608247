import store from 'redux/store';
import { pick, sum, sumBy, isEmpty } from 'lodash';

import { renderAlert } from 'app/redux/actions';
import * as CommonModelSelectors from 'common/redux/models/selectors';
import getVideoIcon from 'common/components/map/mapIcons/video';
import getImageIcon from 'common/components/map/mapIcons/image';
import { TEXT_OPERATORS } from 'common/constants/operators';
import { MAX_LIVE_QUERIES, MAX_PERSONS_IN_LIVE_QUERY, IMAGE } from 'common/constants/app';

import { queryToVerbalSyntax } from 'common/helpers/searchQueryUtils';
import { WARNING } from 'app/redux/constants';

export function checkColoredObject(identityRows) {
  for (let i = 0; i < identityRows.length; i++) {
    for (let j = 0; j < identityRows[i].identities.length; j++) {
      if (identityRows[i].identities[j].color) {
        return true;
      }
    }
  }

  return false;
}

export function findMatchingIdentityInRow(row, id, isObject) {
  const identities = [...row.identities];
  for (let i = 0; i < identities.length; i++) {
    if (identities[i].id === id && Boolean(identities[i].object) === Boolean(isObject)) {
      return i;
    }
  }

  return null;
}

export function getExcludedVideos(datasources, filters) {
  const excludedVideos = {};
  let excludedVideosCount = 0;

  if (!filters) return { excludedVideos, excludedVideosCount };

  const { startDate, endDate } = filters;

  if (!datasources.length || !endDate) return { excludedVideos, excludedVideosCount };

  if (filters) {
    datasources.forEach(datasource => {
      const dsStart = datasource ? new Date(datasource.date).getTime() : null;
      const dsEnd = datasource ? dsStart + datasource.duration : null;

      const hasDate = startDate && endDate;
      const dsStartInFilterBounds = dsStart >= startDate && dsStart <= endDate;
      const filterStartInDsBounds = startDate >= dsStart && startDate <= dsEnd;

      if (hasDate && !dsStartInFilterBounds && !filterStartInDsBounds) {
        excludedVideosCount += 1;
        excludedVideos[datasource.id] = true;
      }
    });
  }

  return { excludedVideos, excludedVideosCount };
}

export function getExcludedVideosWithGeoFilter(datasources, geofilter) {
  const excludedVideosWithGeoFilter = {};
  let excludedVideosWithGeoFilterCount = 0;

  if (isEmpty(geofilter)) return { excludedVideosWithGeoFilter, excludedVideosWithGeoFilterCount };

  if (!isEmpty(geofilter)) {
    datasources.forEach(ds => {
      const hasExcludedVideos =
        !geofilter.datasources.includes(ds.id) && !excludedVideosWithGeoFilter[ds.id];

      if (hasExcludedVideos) {
        excludedVideosWithGeoFilterCount += 1;
        excludedVideosWithGeoFilter[ds.id] = true;
      }
    });
  }

  return { excludedVideosWithGeoFilter, excludedVideosWithGeoFilterCount };
}

export function getExcludedFolders(folders, geofilter = {}) {
  const excludedFolders = {};
  let excludedFoldersCount = 0;

  if (isEmpty(geofilter)) return { excludedFolders, excludedFoldersCount };

  if (!folders.length) return { excludedFolders, excludedFoldersCount };

  const filteredFoldersWithNoMatch = geofilter?.folders
    ?.filter(filteredFolder => filteredFolder.countMatching === 0)
    .map(filteredFolder => filteredFolder.id);

  if (!isEmpty(geofilter) && filteredFoldersWithNoMatch.length) {
    folders.forEach(folder => {
      if (filteredFoldersWithNoMatch.includes(folder.id.toString())) {
        excludedFoldersCount += 1;
        excludedFolders[folder.id] = true;
      }
    });
  }

  return { excludedFolders, excludedFoldersCount };
}

export function getExcludedCameras(cameras, geofilter = {}) {
  const excludedCameras = {};
  let excludedCamerasCount = 0;

  if (isEmpty(geofilter)) return { excludedCameras, excludedCamerasCount };

  if (!cameras.length) return { excludedCameras, excludedCamerasCount };

  if (!isEmpty(geofilter)) {
    cameras.forEach(camera => {
      if (!geofilter.cameras.includes(camera.id.toString())) {
        excludedCamerasCount += 1;
        excludedCameras[camera.id] = true;
      }
    });
  }

  return { excludedCameras, excludedCamerasCount };
}

export function makeLiveQuery({ groupId = null, queryName, geofence }) {
  const state = store.getState();
  const { planMonitor } = state;

  const cameraModels = CommonModelSelectors.selectCameraModels(state);
  const dsModels = CommonModelSelectors.selectDatasourceModels(state);
  const objectModels = CommonModelSelectors.selectObjectModels(state);

  const { identityRows, datasources, cameras, cameraZones } = planMonitor.searchQuery;

  const allDatasources = { ...datasources };
  Object.keys(cameras).forEach(id =>
    cameraModels[id].datasources.forEach(dsid => (allDatasources[dsid] = true))
  );

  const includedVideos = processIncludedVideos(allDatasources, dsModels, {
    live: true,
  });

  const params = {};
  let queryString = '';

  identityRows.forEach(({ identities, groupOperator }, idx) => {
    queryString += '(';
    identities.forEach(({ id, operator, color, object: isObject }, i) => {
      const identifier = isObject ? objectModels[id].reservedName : id;

      if (isObject && color) {
        queryString += `${identifier}.${color}`;
      } else {
        queryString += identifier;
      }
      if (i !== identities.length - 1) {
        queryString += TEXT_OPERATORS[operator];
      }
    });
    queryString += ')';
    if (idx !== identityRows.length - 1) {
      queryString += TEXT_OPERATORS[groupOperator];
    }
  });

  // only allow one group for now
  params.groupId = groupId;
  params.cameras = Object.keys(cameras);
  params.datasourceids = includedVideos;
  params.query = queryString;
  params.queryName = queryName;
  params.userid = 1;
  params.camera_zones = Object.keys(cameraZones);

  if (!isEmpty(geofence)) {
    params.geofence = geofence;
  }

  // Add the object IDs and people IDs to the parameters.
  // TODO(rajkinra) This is part of a bigger refactor to robustify
  // live query creation in the back-end, as the current versions dependency on
  // string comparisons results in lots of nightmares debugging.
  const detectionObjects = [];
  const people = [];

  identityRows.forEach(row => {
    row.identities.forEach(identity => {
      const id = String(identity.id);
      if (identity.object) {
        detectionObjects.push(id);
      } else {
        people.push(id);
      }
    });
  });

  params.detectionObjects = detectionObjects;
  params.people = people;

  return params;
}

export function removeExtraneousIdentityRows(identityRows) {
  // For Analysis only - used by submitSearch which is an Analysis action

  const rowsToDelete = [];
  let copiedIdentityRows = [...identityRows];
  copiedIdentityRows.forEach((row, i) => {
    if (!copiedIdentityRows[i].identities.length) rowsToDelete.unshift(i);
  });

  for (let row = 0; row < rowsToDelete.length; row++) {
    if (copiedIdentityRows.length === 1) {
      copiedIdentityRows = [
        {
          identities: [],
          groupOperator: 'and',
        },
      ];
    } else if (rowsToDelete[row] !== null) {
      copiedIdentityRows.splice(rowsToDelete[row], 1);
    } else {
      copiedIdentityRows.splice(-1);
    }
  }
  return copiedIdentityRows;
}

export function processIncludedVideos(selectedDatasources, dsModels, params = {}) {
  dsModels = dsModels || store.getState().common.models.datasource;
  const { live = false } = params;
  return Object.keys(selectedDatasources).reduce((acc, id) => {
    if (dsModels[id]) {
      // if query is for live monitoring, do not allow non-live feeds
      if (live && dsModels[id].isLive) {
        acc.push(id);

        // if query is not for live monitoring, allow the data source
      } else if (!live) {
        acc.push(id);
      } else {
        console.log('Non-live videos have been removed from the live monitoring query', id);
      }
    }
    return acc;
  }, []);
}

export const countFolderItems = (folderModels, filters) =>
  sumBy(folderModels, ({ itemCounts }) =>
    sum(Object.values(filters.length ? pick(itemCounts, filters) : itemCounts))
  );

export function getLiveQueryLimitError(liveQueries) {
  const errorMessage = `Mirage supports up to ${MAX_LIVE_QUERIES} live queries for this hardware configuration. Please delete an existing query before adding a new query.`;
  const blackListQueries = [];
  const whiteListQueries = [];

  liveQueries.forEach(liveQuery => {
    if (liveQuery.whitelist) {
      whiteListQueries.push(liveQuery.queryName);
    } else {
      blackListQueries.push(liveQuery.queryName);
    }
  });

  const blackListDetails = blackListQueries.length
    ? `\n\nPersons of Interest Queries:\n${blackListQueries.join('\n')}`
    : '';
  const whiteListDetails = whiteListQueries.length
    ? `\n\nAuthorized Persons Queries:\n${whiteListQueries.join('\n')}`
    : '';

  const details = blackListDetails + whiteListDetails;
  return `${errorMessage} ${details}`;
}

function getPersonQueryLimitError(editLiveQuery) {
  let queryDetails = '';
  const queryName = editLiveQuery && editLiveQuery.queryName ? editLiveQuery.queryName : '';

  const currentQueries = queryName && queryToVerbalSyntax(editLiveQuery.query, true).split(',');
  const personsOrObjects = 'persons or objects';
  const personsAndObjects = 'persons and objects';

  const errorMessage = `Mirage supports up to ${MAX_PERSONS_IN_LIVE_QUERY} ${personsOrObjects} in a single query for this hardware configuration. Please remove ${personsOrObjects} so that this query has ${MAX_PERSONS_IN_LIVE_QUERY} or fewer in total.\n`;

  if (currentQueries) {
    queryDetails = `\nCurrent ${personsAndObjects} in ${queryName} :\n`;
    currentQueries.forEach(query => {
      queryDetails += `${query.trim()}\n`;
    });
  }

  return `${errorMessage} ${queryDetails}`;
}

export function hasLimitErrors({
  liveQueries,
  inCreateMode = false,
  selectedPersonsCount,
  selectedObjectsCount,
  editLiveQuery,
}) {
  const liveQueryCount = liveQueries.length;
  const personsOrObjectsCapitalized = 'Persons or Objects';

  if (inCreateMode && liveQueryCount >= MAX_LIVE_QUERIES) {
    renderAlert(getLiveQueryLimitError(liveQueries), {
      type: WARNING,
      title: `System Limit Reached: Live Monitoring Queries`,
    });
    return true;
  }

  if (selectedPersonsCount + selectedObjectsCount > MAX_PERSONS_IN_LIVE_QUERY) {
    renderAlert(getPersonQueryLimitError(editLiveQuery), {
      type: WARNING,
      title: `System Limit Reached: Number of ${personsOrObjectsCapitalized} in Live Monitoring Query`,
    });
    return true;
  }

  return false;
}

export const getMapMarkers = datasources =>
  datasources?.reduce((accum, ds) => {
    const mediaId = ds?.id.toString();
    const location = ds?.location?.coords;

    if (location?.lat && location?.lng) {
      accum.push({
        icon:
          ds.itemType === IMAGE
            ? getImageIcon({ isSelected: true })
            : getVideoIcon({ isSelected: true }),
        isSelected: true,
        mediaId,
        position: [location.lat, location.lng],
        resultType: 'segments',
      });
    }
    return accum;
  }, []);

export const getDatasourcesDatetimeRange = datasources =>
  (datasources || [])
    .filter(datasource => !!datasource.date)
    .reduce(
      ([start, end], datasource) => {
        const dsStart = new Date(datasource.date).getTime();
        const dsEnd = dsStart + datasource.duration;
        const newStart = start ? Math.min(start, dsStart) : dsStart;
        const newEnd = end ? Math.max(end, dsEnd) : dsEnd;

        return [newStart, newEnd];
      },
      [null, null]
    );
