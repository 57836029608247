import { FOLDER_KEY } from 'library/geospatial/redux/constants';

import { filter } from '../sharedDefaults';

const defaults = {
  selectedIds: {},
  openedFolderIds: {},
  filter,
};

export default function reducer(state = defaults, action) {
  switch (action.type) {
    case `LIBRARY_GEOSPATIAL/${FOLDER_KEY}/SET_SELECTED_IDS`: {
      return {
        ...state,
        selectedIds: {
          ...action.payload,
        },
      };
    }

    case `LIBRARY_GEOSPATIAL/${FOLDER_KEY}/SET_SORT_FILTER`: {
      return {
        ...state,
        filter: {
          ...state.filter,
          sort: action.payload.sort,
          sortDirection: action.payload.sortDirection,
        },
      };
    }

    case `LIBRARY_GEOSPATIAL/${FOLDER_KEY}/SET_TEXT_FILTER`: {
      return {
        ...state,
        filter: {
          ...state.filter,
          text: action.payload,
        },
      };
    }

    case `LIBRARY_GEOSPATIAL/${FOLDER_KEY}/TOGGLE_OPENED_FOLDER_ID`: {
      return {
        ...state,
        openedFolderIds: {
          ...state.openedFolderIds,
          [action.payload]: !state.openedFolderIds[action.payload],
        },
      };
    }

    default:
      return state;
  }
}
