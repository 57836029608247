import axios from 'axios';

const { CancelToken } = axios;

const PENDING_AXIOS_TRANSACTIONS = {};

// If we get any bugs related to cancelling requests its likely due to dupe
// requests being pending at the same time and overwriting each others cancelKey.
// Normally you'd use a stringified/hashed `config.data` but as we need to include
// GET requests this becomes trickier. One option is to pass in a timestamp at
// request initialization so it stays the same throughout its handling by axios.
const getCancelTransactionKey = config => {
  if (config.cancelKey) return config.cancelKey;
  return `${config.method}:${config.url}`;
};

export const handleCancelableRequest = config => {
  // Dont queue up logout to be cancelable
  if (config.url === '/api/logout/') return;
  const transactionKey = getCancelTransactionKey(config);

  // Save cancel token into memory
  config.cancelToken = new CancelToken(cancel => {
    PENDING_AXIOS_TRANSACTIONS[transactionKey] = cancel;
  });
};

export const handleCancelableRequestCleanup = config => {
  // Config gets wiped if a request is canceled
  if (!config) return;

  if (config.cancelToken) {
    const transactionKey = getCancelTransactionKey(config);
    delete PENDING_AXIOS_TRANSACTIONS[transactionKey];
  }
};

export const cancelAllRequests = () => {
  Object.values(PENDING_AXIOS_TRANSACTIONS).map(cancel => cancel());
};
