function parseOnOperator(group, operator) {
  return group.split(operator).map((item, i, array) => {
    if (i !== array.length - 1) {
      return item.concat(operator);
    }
    return item;
  });
}

function parseText(item) {
  return item
    .split(/(&!|\|!|&|\||\))/g)
    .map(term => term.trim())
    .filter(term => term && term !== ')');
}

function splitGroup(groupText) {
  let splitIndex = null;
  let operatorIndex = null;

  for (let i = 0; i < groupText.length; i += 1) {
    if (groupText[i] === ')') {
      splitIndex = i;
    }
    if (splitIndex) {
      if ((groupText[i] === '&') | (groupText[i] === '|')) {
        if (groupText[i + 1] && groupText[i + 1] === '!') {
          // Break on '&!' and '|!' as rows were already created for these OPERATORS
          break;
        }

        operatorIndex = i;
      }
    }
    if (splitIndex && operatorIndex) break;
  }

  if (splitIndex && operatorIndex) {
    const first = groupText.slice(0, splitIndex);
    const last = groupText.slice(operatorIndex + 1);
    const groupOperator = ' '.concat(groupText[operatorIndex]);

    return [[first.concat(groupOperator)], [last]];
  }

  return [groupText];
}

export default function getParsedQuery(text) {
  const parsedQuery = [];
  const groups = text
    .split('(')
    .map(group => parseOnOperator(group, '&!').map(subgroup => parseOnOperator(subgroup, '|!')));

  let newRow = false;

  groups.forEach(group => {
    // A new row should be created, split the current group on the closing ')'
    if (newRow) {
      group = splitGroup(group[0][0]);
      newRow = false;
    }

    // If group is empty, the string started with '(' and a new row should be created
    if (group[0].length === 1 && group[0][0] === '') {
      newRow = true;
    }

    group.forEach(recursiveFlatten);

    function recursiveFlatten(subgroup) {
      if (Array.isArray(subgroup)) {
        return subgroup.forEach(recursiveFlatten);
      }

      const parsedText = parseText(subgroup);

      if (parsedText.length) {
        return parsedQuery.push(parsedText);
      }
    }
  });

  return parsedQuery;
}
