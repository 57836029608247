import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import MUIIcon from '@material-ui/core/Icon';
import Tooltip from 'common/components/base/Tooltip';

const useStyles = createUseStyles({
  main: {
    display: 'flex',
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: 8,
    position: 'relative',
  },
  icon: {
    margin: 6,
    cursor: 'pointer',
    color: 'black',
    '& path': {
      fill: 'black',
    },
    '&:active': {
      opacity: 0.8,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  playIcon: {
    fontSize: 27,
  },
});

const BaseVideoControls = ({ onClick, isPlaying }) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Tooltip title={isPlaying ? 'Pause (space bar)' : 'Play (space bar)'}>
        <MUIIcon
          role="button"
          tabIndex="0"
          className={classnames(classes.icon, classes.playIcon)}
          onClick={() => {
            if (onClick) onClick();
          }}
        >
          {isPlaying ? 'pause_circle_outline' : 'play_circle_outline'}
        </MUIIcon>
      </Tooltip>
    </div>
  );
};

export default BaseVideoControls;
