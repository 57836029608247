import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { capitalize, noop } from 'lodash';

import CancelIcon from '@material-ui/icons/Cancel';
import { ERROR as ERROR_COLOR, WHITE, BRIGHT_GREEN } from 'common/constants/colors';
import Tooltip from 'common/components/base/Tooltip';
import LinearProgress from 'common/components/base/LinearProgress';
import Icon from 'common/components/base/Icon';
import OverflowText from 'common/components/base/OverflowText';
import Typography from 'components/Typography';

import { DEFAULT_CELL_DATA } from 'common/constants/app';
import { parseToSameDayFormat, parseToShorthandDate } from 'common/helpers/dateUtils';
import { shouldRenderCancelIcon, hasFailed, hasPaused } from 'library/redux/datasource/utils';
import { restartProcessingJob } from 'library/redux/actions';

const useStyles = createUseStyles(theme => ({
  bar: {
    width: theme.spacing(30),
    margin: theme.spacing(0, 2),
  },
  icon: {
    cursor: 'pointer',
    background: WHITE,
    borderRadius: '50%',
    fontSize: 20,
  },
  cancel: {
    composes: '$icon',
    color: ERROR_COLOR,
    background: WHITE,
  },
  resume: {
    composes: '$icon',
    color: BRIGHT_GREEN,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  status: {
    width: 'unset',
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(10),
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

export const DateCell = ({ value }) => {
  const date = value ? parseToSameDayFormat(value, { addTimezone: true }) : DEFAULT_CELL_DATA;
  const tooltip = parseToShorthandDate(value, {
    showSeconds: true,
    showTimeZone: true,
    showDayOfWeek: true,
  });

  return (
    <Tooltip disableHoverListener={!tooltip} enterDelay={500} title={tooltip}>
      <div>{date}</div>
    </Tooltip>
  );
};

export const StatusCell = ({ value, row }) => {
  const classes = useStyles();
  const { original: { percentProgress, errorMessage, status } = {} } = row;

  let statusText = capitalize(value);
  if (hasFailed(status) && errorMessage) {
    statusText += ` - ${errorMessage}`;
  }

  return (
    <div className={classes.cell}>
      <OverflowText className={classes.status} variant="body1">
        {statusText}
      </OverflowText>
      {percentProgress !== undefined && !hasFailed(status) && (
        <div className={classes.progress}>
          <Typography variant="body1">{percentProgress}%</Typography>
          <LinearProgress value={percentProgress} className={classes.bar} />
        </div>
      )}
    </div>
  );
};

const renderCancelIcon = ({ status }, onClick) => {
  const classes = useStyles();

  return (
    shouldRenderCancelIcon(status) && (
      <Tooltip title="Cancel Processing">
        <CancelIcon size="small" theme="red" className={classes.cancel} onClick={onClick} />
      </Tooltip>
    )
  );
};

const renderResumeIcon = (job = {}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { status } = job;

  if (hasPaused(status) || !hasFailed(status)) {
    return null;
  }

  return (
    <Tooltip title="Resume Processing">
      <Icon
        iconName="refresh"
        className={classes.resume}
        onClick={() => dispatch(restartProcessingJob(job))}
      />
    </Tooltip>
  );
};

export const DatasetOptionsCell = ({ row: { original: batch }, cancelJobHandler = noop }) => {
  const classes = useStyles();
  const failedJob = batch.jobs?.find(({ status }) => status === 'failed');
  const _cancelJobHandler = useCallback(() => cancelJobHandler(batch), [batch, cancelJobHandler]);

  return (
    <div className={classes.cell}>
      {renderCancelIcon(batch, _cancelJobHandler)}
      {failedJob && renderResumeIcon(failedJob)}
    </div>
  );
};
