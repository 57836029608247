import { useEffect, useState } from 'react';

import * as FolderApi from 'common/api/folder/v2';
import * as DatasourceApi from 'common/api/datasourceApi';
import { getLibraries } from 'common/api/libraryApi';
import { getPersons } from 'common/api/personApi';
import { sortByName } from 'common/constants/sortFunctions';
import { normalizeList } from 'common/helpers/helperFunctions';
import { by, desc } from 'common/helpers/sortFunctions';

export const useDatasourceLibrary = (open, videoId, imageId) => {
  const [results, setResults] = useState({ datasourceLibraryId: null, isDatasourceLoading: true });

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          setResults({ datasourceLibraryId: null, isDatasourceLoading: true });
          const dsResult = await (videoId
            ? DatasourceApi.getSingleDatasource(videoId)
            : DatasourceApi.getImageDatasources({ ids: imageId }).then(res => res[0]));
          const folderResult = await FolderApi.getSingleFolder(dsResult.hierarchy[0].id);
          setResults({ datasourceLibraryId: folderResult.library, isDatasourceLoading: false });
        } catch (e) {
          /* canceled */
        }
      };

      fetchData();
    }
  }, [imageId, open, videoId]);

  return results;
};

export const useDetectionPerson = (detection, imageId, open, videoId) => {
  const [fetchResults, setFetchResults] = useState({
    isPersonLoading: true,
    libraries: {},
    people: [],
  });
  const embedding = detection?.getEmbedding()?.join(',');
  const objectType = detection?.getType();

  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        try {
          setFetchResults({ isPersonLoading: true, libraries: {}, people: [] });
          const params = {
            ...(embedding ? { e: embedding } : {}),
            ...(embedding && imageId ? { image: imageId } : {}),
            ...(embedding && videoId ? { video: videoId } : {}),
            ...(objectType !== undefined ? { objectType } : {}),
          };
          const responses = await Promise.all([getPersons(params), getLibraries()]);
          const peopleSorter = embedding ? by('confidence', desc) : sortByName;

          setFetchResults({
            isPersonLoading: false,
            libraries: normalizeList(responses[1].results),
            people: responses[0].sort(peopleSorter),
          });
        } catch (e) {
          /* canceled */
        }
      };

      fetchData();
    }
  }, [embedding, imageId, open, videoId]);

  return fetchResults;
};

export const useLibraries = ({ limit = 50 } = {}) => {
  const [fetchResults, setFetchResults] = useState({ isLoading: true, libraries: {} });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { results: libraries } = await getLibraries({ limit });
        setFetchResults({ isLoading: false, libraries: normalizeList(libraries) });
      } catch (e) {
        /* canceled */
      }
    };

    fetchData();
  }, []);

  return fetchResults;
};
