import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Tooltip from 'common/components/base/Tooltip';
import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';

import StepBack from 'common/icons/step-back';
import StepForward from 'common/icons/step-forward';
import emitter, {
  DRAW_DETECTION,
  SHORTCUT_ANIMATION,
  DISABLE_VIDEO_SHORTCUTS,
  ENABLE_VIDEO_SHORTCUTS,
} from 'common/constants/emitter';
import {
  SKIP_BACKWARD_1,
  SKIP_FORWARD_1,
  SKIP_BACKWARD_2,
  SKIP_FORWARD_2,
  STEP_BACKWARD,
  STEP_FORWARD,
  PREV_DETECTION,
  NEXT_DETECTION,
  PLAY_PAUSE,
  SHIFT,
} from 'common/constants/shortcuts';
import { SECONDARY } from 'common/constants/colors';
import { VIDEO_HAS_NOTHING, VIDEO_CONTROL_WIDTH } from '../constants';

const styles = {
  main: {
    display: 'flex',
    height: '100%',
    minWidth: VIDEO_CONTROL_WIDTH,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    position: 'relative',
    zIndex: 10,
  },
  icon: {
    height: 12,
    width: 12,
  },
  iconButton: {
    height: 28,
    width: 28,
  },
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  disabled: {
    cursor: 'not-allowed',
    '&:active': {
      opacity: 1,
    },
  },
};

function findSegment(time, segments, direction) {
  // The input time for findSegment must be rounded because the video player
  // will seek to the nearest frame for the seekTo time, which can be a
  // fraction of a second off the selected segment time
  time = Math.round(time);

  const start = direction < 0 ? segments.length - 1 : 0;
  const test = ({ fromTime }) => (direction < 0 ? fromTime < time : fromTime > time);

  for (let i = start; i >= 0 && i < segments.length; i += direction) {
    if (test(segments[i])) {
      return segments[i];
    }
  }

  return null;
}

class VideoControls extends React.Component {
  state = {
    isNextDetectionLoading: false,
    isPrevDetectionLoading: false,
  };

  componentDidMount() {
    document.addEventListener('keyup', this.handleVideoShortCuts);
    document.addEventListener('keydown', this.handleKeyDown);

    this.unbindOnDrawDetection = emitter.on(DRAW_DETECTION, this.onDrawDetections);

    this.unbindDisableVideoShortCuts = emitter.on(
      DISABLE_VIDEO_SHORTCUTS,
      this.disableVideoShortCuts
    );

    this.unbindEnableVideoShortCuts = emitter.on(ENABLE_VIDEO_SHORTCUTS, this.enableVideoShortCuts);
  }

  shouldComponentUpdate() {
    // Prevent the timeline from updating and jumping around while a new
    // source is loading
    return this.props.hasInitialized;
  }

  componentWillUnmount() {
    this.unbindOnDrawDetection();
    this.unbindDisableVideoShortCuts();
    this.unbindEnableVideoShortCuts();
    this.disableVideoShortCuts();
  }

  shiftDown = false;

  disableVideoShortCuts = () => {
    document.removeEventListener('keyup', this.handleVideoShortCuts);
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  enableVideoShortCuts = () => {
    document.addEventListener('keyup', this.handleVideoShortCuts);
  };

  handleKeyDown = e => {
    if (e.keyCode === SHIFT) this.shiftDown = true;
  };

  handleVideoShortCuts = e => {
    if (
      e.target.nodeName === 'TEXTAREA' ||
      (e.target.nodeName === 'INPUT' && e.target.name !== 'video-progress-bar')
    ) {
      return;
    }

    const value = e.keyCode;

    if (value === SHIFT) this.shiftDown = false;

    if (this.shiftDown) {
      switch (value) {
        case SKIP_BACKWARD_1:
          return this.skipBack(90, SKIP_BACKWARD_2);
        case SKIP_FORWARD_1:
          return this.skipForward(90, SKIP_FORWARD_2);
        default:
          return;
      }
    }

    switch (value) {
      case SKIP_BACKWARD_1:
        return this.skipBack();
      case SKIP_FORWARD_1:
        return this.skipForward();
      case SKIP_BACKWARD_2:
        return this.skipBack();
      case SKIP_FORWARD_2:
        return this.skipForward();
      case STEP_BACKWARD:
        return this.seekToPreviousFrame();
      case STEP_FORWARD:
        return this.seekToNextFrame();
      case PREV_DETECTION:
        return this.getPrevSegment();
      case NEXT_DETECTION:
        return this.getNextSegment();
      case PLAY_PAUSE:
        return this.togglePlay();
      default:
    }
  };

  getPrevSegment = () => {
    if (this.isPrevSegmentDisabled()) return;

    const time = this.getVideoTimeInMs();
    const prevSegment = findSegment(time, this.props.segments, -1);

    if (prevSegment) {
      const timestamp = prevSegment.fromTime - this.props.startEpochMs;

      this.setState({ isPrevDetectionLoading: true });
      this.props.seekTo(timestamp / 1000);
      emitter.emit(SHORTCUT_ANIMATION, PREV_DETECTION);

      if (this.props.isLive) {
        this.props.toggleIsLive(false);
      }
    }
  };

  getNextSegment = () => {
    if (this.isNextSegmentDisabled()) return;

    const time = this.getVideoTimeInMs();
    const nextSegment = findSegment(time, this.props.segments, 1);

    if (nextSegment) {
      const timestamp = nextSegment.fromTime - this.props.startEpochMs;

      this.setState({ isNextDetectionLoading: true });
      this.props.seekTo(timestamp / 1000);
      emitter.emit(SHORTCUT_ANIMATION, NEXT_DETECTION);
    }
  };

  /**
    The default value for startEpochMs is 0. If this value exists, it is the
    time since epoch stamp for the video. Add startEpochMs to the video's
    time because the video's time is relative to the length of the video,
    and timestamp in segments is relative to epoch if startEpochMs exists.

    e.g. video.getTime() returns 43100, this is 43.1 seconds into the video.
    startEpochMs is '1581548277182', this is the point in time that the video
    starts, which will be included in the segment.fromTime value. So to get
    the proper time, we have to first add startEpochMs to video.getTime() to
    find the correct segment, then subtract it from segment.fromTime to
    advance the video to the correct frame
  */
  getVideoTimeInMs = () =>
    this.props.video ? 1000 * this.props.video.getTime() + this.props.startEpochMs - 1 : 0;

  isPrevSegmentDisabled = () => {
    if (!this.props.segments.length) return true;

    const time = this.getVideoTimeInMs();
    const firstSegment = this.props.segments[0];

    return time <= firstSegment.fromTime;
  };

  isNextSegmentDisabled = () => {
    if (this.props.isNearLiveEdge || !this.props.segments.length) {
      return true;
    }

    const time = this.getVideoTimeInMs();
    const lastSegment = this.props.segments[this.props.segments.length - 1];

    return time >= lastSegment.fromTime;
  };

  isSeekToPrevFrameDisabled = () => this.props.video && this.props.video.getTime() <= 0.001;

  isSeekToNextFrameDisabled = () => this.props.isNearLiveEdge || this.props.isPlayDisabled;

  onDrawDetections = () => {
    if (this.state.isNextDetectionLoading || this.state.isPrevDetectionLoading) {
      this.setState({
        isNextDetectionLoading: false,
        isPrevDetectionLoading: false,
      });
    }
  };

  togglePlay = () => {
    if (!this.props.video || this.props.isPlayDisabled) return;

    this.props.togglePlay();
    emitter.emit(SHORTCUT_ANIMATION, PLAY_PAUSE);
  };

  replay = () => {
    this.props.replay();
    emitter.emit(SHORTCUT_ANIMATION, PLAY_PAUSE);
  };

  /*
    We will disable animations for prev/next frame for now. It might be very annoying
    to see the animation display everytime you seek frames according to Alison,
    and the complexity of the code is simpler by not having to introduce the step
    forward/backward svg components. TBD
  */
  seekToNextFrame = () => {
    if (this.isSeekToNextFrameDisabled()) return;

    const time = this.props.video.getTime();
    const frameTime = 1.0 / this.props.frameRate;
    const frameNumber = Math.floor(time / frameTime);
    const nextTime = (frameNumber + 1) * frameTime;
    this.props.seekTo(nextTime);
  };

  seekToPreviousFrame = () => {
    if (this.isSeekToPrevFrameDisabled()) return;

    const time = this.props.video.getTime();
    const frameTime = 1.0 / this.props.frameRate;
    const frameNumber = Math.floor(time / frameTime);
    const nextTime = (frameNumber - 1) * frameTime;
    this.props.seekTo(nextTime);

    if (this.props.isLive) {
      this.props.toggleIsLive(false);
    }
  };

  skipBack = (jumpBy = 5, animation = SKIP_BACKWARD_1) => {
    const { isLive, seekTo, toggleIsLive, video } = this.props;

    const time = video.getTime() - jumpBy;

    if (time < 0) return;

    seekTo(time);
    emitter.emit(SHORTCUT_ANIMATION, animation);

    if (isLive) {
      toggleIsLive(false);
    }
  };

  skipForward = (jumpBy = 5, animation = SKIP_FORWARD_1) => {
    const { isCameraRecording, isLive, isNearLiveEdge, seekTo, toggleIsLive, video } = this.props;

    const time = video.getTime() + jumpBy;
    const duration = video.getDuration();

    if ((isLive && isNearLiveEdge) || time > duration) return;

    if (isCameraRecording && !isLive && time > duration) {
      toggleIsLive(true);
    } else {
      seekTo(time);
      emitter.emit(SHORTCUT_ANIMATION, animation);
    }
  };

  renderDetectionControl = ({
    className,
    icon,
    iconStyle,
    isDisabled,
    isLoading,
    onClick,
    tooltip,
    dataTestId,
  }) => {
    const { classes, disabled } = this.props;
    const shouldDisableButton = isDisabled || disabled || isLoading;
    return (
      <Tooltip title={tooltip}>
        <div>
          <IconButton
            loadingIconProps={{ size: 24 }}
            isLoading={isLoading}
            className={classnames(className, classes.iconButton)}
            disabled={shouldDisableButton}
            onClick={onClick}
            size="small"
            data-testid={dataTestId}
          >
            <Icon iconName={icon} style={iconStyle} />
          </IconButton>
        </div>
      </Tooltip>
    );
  };

  renderFrameStep = step => (
    <Tooltip title={step.tooltip}>
      <div>
        <IconButton
          className={this.props.classes.iconButton}
          disabled={step.isDiabled}
          onClick={step.onClick}
          size="small"
          data-testid={step.dataTestId}
        >
          <step.Icon className={step.className} style={{ color: SECONDARY }} />
        </IconButton>
      </div>
    </Tooltip>
  );

  renderPlayIcon = () => {
    const { classes, isLive, isPlayDisabled, video } = this.props;

    const isReplayable = !isLive && video?.getReadyState() !== VIDEO_HAS_NOTHING && isPlayDisabled;

    if (isReplayable) {
      return this.renderDetectionControl({
        className: classes.playIcon,
        iconStyle: { height: 26, width: 26, fontSize: 26 },
        onClick: this.replay,
        icon: 'replay',
        tooltip: 'Replay',
        dataTestId: 'VideoControl-replay',
      });
    }

    return this.renderDetectionControl({
      className: classes.playIcon,
      iconStyle: { height: 26, width: 26, fontSize: 26 },
      onClick: this.togglePlay,
      icon: this.props.isPlaying ? 'pause_circle_outline' : 'play_circle_outline',
      isDisabled: !isLive && isPlayDisabled,
      tooltip: this.props.isPlaying ? 'Pause (space bar)' : 'Play (space bar)',
      dataTestId: this.props.isPlaying ? 'VideoControl-pause' : 'VideoControl-play',
    });
  };

  render() {
    const {
      classes,
      disabled,
      removeDetectionControls,
      removeFrameControls,
      inspecting,
    } = this.props;

    const iconClassName = classnames({
      [classes.icon]: true,
      [classes.disabled]: disabled || this.prevDisabled,
    });

    const detectionNextControlText = inspecting
      ? 'Next Detection ( ] )'
      : 'Next Analysis Result ( ] )';

    const detectionPrevControlText = inspecting
      ? 'Prev Detection ( [ )'
      : 'Prev Analysis Result ( [ )';

    return (
      <div className={classes.main} data-testid="VideoControls">
        {disabled && <div className={classes.disabledOverlay} />}
        {!removeDetectionControls &&
          this.renderDetectionControl({
            onClick: this.getPrevSegment,
            icon: 'skip_previous',
            isDisabled: this.isPrevSegmentDisabled(),
            isLoading: this.state.isPrevDetectionLoading,
            tooltip: detectionPrevControlText,
            dataTestId: 'VideoControl-prev-detection',
          })}
        {!removeFrameControls &&
          this.renderFrameStep({
            onClick: this.seekToPreviousFrame,
            Icon: StepBack,
            isDisabled: this.isSeekToPrevFrameDisabled(),
            className: iconClassName,
            tooltip: 'Step Back 1 Frame ( , )',
            dataTestId: 'VideoControl-prev-frame',
          })}
        {this.renderPlayIcon()}
        {!removeFrameControls &&
          this.renderFrameStep({
            onClick: this.seekToNextFrame,
            Icon: StepForward,
            isDisabled: this.isSeekToNextFrameDisabled(),
            className: iconClassName,
            tooltip: 'Step Forward 1 Frame ( . )',
            dataTestId: 'VideoControl-next-frame',
          })}
        {!removeDetectionControls &&
          this.renderDetectionControl({
            onClick: this.getNextSegment,
            icon: 'skip_next',
            isDisabled: this.isNextSegmentDisabled(),
            isLoading: this.state.isNextDetectionLoading,
            tooltip: detectionNextControlText,
            dataTestId: 'VideoControl-next-detection',
          })}
      </div>
    );
  }
}

export default injectSheet(styles)(VideoControls);
