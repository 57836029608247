import { makeStyles } from 'tss-react-local';

import { YELLOW, DARKEST_GREY, TEXT } from 'common/constants/colors';

const useStyles = makeStyles()({
  container: {
    width: 32,
    height: 13,
    background: YELLOW,
    borderRadius: 3,
    fontSize: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${DARKEST_GREY}`,
    color: TEXT,
  },
});

export default ({ className, style }) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)} style={{ ...style }}>
      BETA
    </div>
  );
};
