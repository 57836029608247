import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LiveMonitorInactive = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm3-12.9V9a.6.6 0 0 0-.6-.6H7.2a.6.6 0 0 0-.6.6v6a.6.6 0 0 0 .6.6h7.2a.6.6 0 0 0 .6-.6v-2.1l2.4 2.4V8.7L15 11.1z"
    />
  </SvgIcon>
);

export default LiveMonitorInactive;
