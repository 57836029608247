import { selectActiveModules } from 'settings/redux/selectors';
import { getDetectionObjects, postDetectionObject } from 'common/api/detectionObjectApi';
import { convertNameToConventionalFormat, normalizeObjectModels } from 'common/helpers/objectUtils';
import { setObjectModels } from './actions';

export const fetchDetectionObjects = () => (dispatch, getState) => {
  const state = getState();
  const activeModules = selectActiveModules(state);

  return getDetectionObjects({ show_custom: true }).then(data => {
    const result = normalizeObjectModels(data, activeModules);
    dispatch(setObjectModels(result.activeObjects));
    return result;
  });
};

export function createCustomObject({ name, labelColor }) {
  return async function createCustomObjectThunk(_dispatch, getState) {
    const activeModules = selectActiveModules(getState());

    return postDetectionObject({
      name: convertNameToConventionalFormat(name),
      displayName: name,
      labelColor,
      visible: true,
      enabledModules: Object.keys(activeModules),
    });
  };
}
