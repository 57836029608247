import { isEmpty } from 'lodash';
import { getObjectAttributesFromName, isNameObject } from 'common/helpers/objectUtils';

export function normalizeVideoResolution(width, height) {
  const NORMAL_HEIGHT = 720;
  const heightScale = height / NORMAL_HEIGHT;
  const scaledWidth = width / heightScale;

  return { height: NORMAL_HEIGHT, width: scaledWidth };
}

export const getAttributesFromDetection = detection => {
  const name = detection.getName();
  const isObj = isNameObject(name);

  if (isObj) {
    const { name: _name, color } = getObjectAttributesFromName(name);

    return {
      name: _name,
      color,
      isObj,
    };
  }
  return {
    name,
    isObj,
  };
};

export function getCanvasMargins(canvasRect, scaleAdjustedRect) {
  const { top: topOfCanvas, left: leftOfCanvas } = canvasRect;
  const { top: topOfContent, left: leftOfContent } = scaleAdjustedRect;

  const marginLeft = leftOfContent - leftOfCanvas;
  const marginTop = topOfContent - topOfCanvas;

  return {
    marginLeft,
    marginTop,
  };
}

export const doesPointIntersectRect = (pointX, pointY, { x, y, width, height }) =>
  pointX >= x && pointX <= x + width && pointY >= y && pointY <= y + height;

export const getScale = (overlayRef = {}) => {
  if (isEmpty(overlayRef)) return {};

  const {
    clientHeight,
    clientWidth,
    height,
    width,
    offsetHeight, // offsetHeight/Width is the original value without transform scale applied
    offsetWidth,
  } = overlayRef;

  let { top, left } = overlayRef.getBoundingClientRect();

  /*
    Required to get the context bounding rect instead of the canvas DOM element because
    'object-fit: contain' positions the canvas context in the middle, but the mouse event
    X and Y are based on the canvas DOM element, which can be positioned differently
  */
  const origScaleX = width / offsetWidth;
  const origScaleY = height / offsetHeight;

  let scaleX = origScaleX;
  let scaleY = origScaleY;

  const ratio = width / height;
  const clientRatio = clientWidth / clientHeight;

  if (ratio > clientRatio) {
    const contextHeight = clientWidth / ratio;
    const diff = (clientHeight - contextHeight) / 2;
    top += diff;
    scaleY *= clientHeight / contextHeight;
  } else {
    const contextWidth = clientHeight * ratio;
    const diff = (clientWidth - contextWidth) / 2;
    left += diff;
    scaleX *= clientWidth / contextWidth;
  }

  const rect = {
    top,
    left,
  };

  return { rect, scaleX, scaleY };
};
