import React from 'react';

export default ({ style, ...rest }) => (
  <svg style={{ width: 20, height: 20, ...style }} viewBox="0 0 24 24" {...rest}>
    <g id="ic_eye-pencil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <path
          d="M9.85669318,19 L12,21.1433068 L9,22 L9.85669318,19 Z M19.4999127,9.25 L21.75,11.5000873 L12.7500873,20.5 L10.5,18.2499127 L19.4999127,9.25 Z M9.42857143,2 C13.7142857,2 17.3742857,4.54586047 18.8571429,8.13953489 L18.831,8.078 L13.539718,13.4475362 C12.3711333,13.9447357 11.0858326,14.2365036 9.7336088,14.2747603 L9.42857143,14.2790698 C5.14285714,14.2790698 1.48285714,11.7332093 -1.94566141e-10,8.13953489 C1.48285714,4.54586047 5.14285714,2 9.42857143,2 Z M9.42857143,4.04651163 C7.06163679,4.04651163 5.14285714,5.87902056 5.14285714,8.13953489 C5.14285714,10.4000492 7.06163679,12.2325581 9.42857143,12.2325581 C11.7955061,12.2325581 13.7142857,10.4000492 13.7142857,8.13953489 C13.7142857,5.87902056 11.7955061,4.04651163 9.42857143,4.04651163 Z M21.7561666,7 L24,9.24383338 L22.4938334,10.75 L20.25,8.50616663 L21.7561666,7 Z M9.42857143,5.68372093 C10.8487322,5.68372093 12,6.78322629 12,8.13953489 C12,9.49584348 10.8487322,10.5953488 9.42857143,10.5953488 C8.00841064,10.5953488 6.85714286,9.49584348 6.85714286,8.13953489 C6.85714286,6.78322629 8.00841064,5.68372093 9.42857143,5.68372093 Z"
          id="Combined-Shape"
          fill="#78909C"
        />
      </g>
    </g>
  </svg>
);
