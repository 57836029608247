export const TEST_ATTRIBUTE = 'data-testid';

export const TEST_ADVANCE_SETTINGS_ENABLE_FLAGS = {
  [TEST_ATTRIBUTE]: `AdvancedSettings-enableFlags`,
};

export const TEST_ADVANCE_SETTINGS_TOGGLE_OLD_PLAN_VIEW = {
  [TEST_ATTRIBUTE]: `AdvancedSettings-toggleOldPlanView`,
};

export const TEST_SCAN_CAMERA_CLOSE_ICON = {
  [TEST_ATTRIBUTE]: `CameraDiscovery-closeIcon`,
};

export const TEST_SCAN_CAMERA_SCAN_PORTS = {
  [TEST_ATTRIBUTE]: `CameraDiscovery-scanPorts`,
};

export const TEST_SCAN_CAMERA_SHOW_PASSWORDS = {
  [TEST_ATTRIBUTE]: `CameraDiscovery-showPasswords`,
};

export const TEST_SCAN_CAMERA_CANCEL = {
  [TEST_ATTRIBUTE]: `CameraDiscovery-cancelScans`,
};

export const TEST_SCAN_CAMERA_SCAN_NETWORK = {
  [TEST_ATTRIBUTE]: `CameraDiscovery-scanSelectedNetwork`,
};

export const TEST_SCAN_CAMERA_SCAN_PROGRESS = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryLogins-scanProgress`,
};

export const TEST_SCAN_CAMERA_ADMIN_NEW_USERNAME = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryLogins-newUsername`,
};

export const TEST_SCAN_CAMERA_ADMIN_NEW_PASSWORD = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryLogins-newPassword`,
};

export const TEST_SCAN_CAMERA_ADMIN_ADD_CRED = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryLogins-addCredentials`,
};

export const TEST_SCAN_CAMERA_ENTERED_USERNAME_CRED = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryLogins-enteredUsername`,
};

export const TEST_SCAN_CAMERA_ENTERED_PASSWORD_CRED = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryLogins-enteredPassword`,
};

export const TEST_SCAN_CAMERA_SELECTED_IP_NETWORK = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryNetworks-selectIPNetwork`,
};

export const TEST_SCAN_CAMERA_ENTER_NEW_IP_NETWORK = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryNetworks-enterIPNetwork`,
};

export const TEST_SCAN_CAMERA_ADD_IP_NETWORK = {
  [TEST_ATTRIBUTE]: `CameraDiscoveryNetworks-addIPNetwork`,
};

export const TEST_SCAN_RESULTS_CLOSE_ICON = {
  [TEST_ATTRIBUTE]: `ScanResults-closeIcon`,
};

export const TEST_SCAN_RESULTS_SHOW_PASSWORD = {
  [TEST_ATTRIBUTE]: `ScanResults-showPassword`,
};

export const TEST_SCAN_RESULTS_CONNECT_TO_CAMERA = {
  [TEST_ATTRIBUTE]: `ScanResults-connectCamera`,
};

export const TEST_SCAN_RESULTS_CANCEL = {
  [TEST_ATTRIBUTE]: `ScanResults-cancel`,
};

export const TEST_SCAN_RESULTS_FINISH = {
  [TEST_ATTRIBUTE]: `ScanResults-finish`,
};

export const TEST_OBJECT_LIBRARY_BUTTON = {
  [TEST_ATTRIBUTE]: `IdentitiesQueryEmpty-object-library-button`,
};

export const TEST_LIBRARY_OBJECTS_TAB = {
  [TEST_ATTRIBUTE]: `LibraryHeader-objectTab`,
};

export const TEST_LIBRARY_HEADER_CHECKBOX = {
  [TEST_ATTRIBUTE]: `LibraryBreadCrumbs-checkbox`,
};

export const TEST_LIBRARY_HEADER_UPLOAD = {
  [TEST_ATTRIBUTE]: `LibraryBodyButtons-upload-button`,
};

export const TEST_LIBRARY_HEADER_NEW_FOLDER = {
  [TEST_ATTRIBUTE]: `LibraryBodyButtons-newFolder`,
};

export const TEST_MENU_RENAME_CAMERA = {
  [TEST_ATTRIBUTE]: `LibraryBodyDataWrapper-renameCamera`,
};

export const TEST_MENU_CHANGE_CAMERA_PROCESSING = {
  [TEST_ATTRIBUTE]: `LibraryBodyDataWrapper-changeCamProcessingStatus`,
};

export const TEST_SELECT_CAMERA = {
  [TEST_ATTRIBUTE]: `CameraLibraryTile-camera`,
};

export const TEST_CLOSE_ICON = {
  [TEST_ATTRIBUTE]: `FormHeader-closeIcon`,
};

export const TEST_SELECT_DATE_RANGE = {
  [TEST_ATTRIBUTE]: `DateTimeRangePickerChip-selectDateRange`,
};

export const TEST_SELECT_TIME_RANGE = {
  [TEST_ATTRIBUTE]: `TimeRangePickerChip-selectTimeRange`,
};

export const TEST_ANALYSIS_SEARCH_BAR_QUEUE_LINK = {
  [TEST_ATTRIBUTE]: `Queuelink-analysisHistoryLink`,
};

export const TEST_ANALYSIS_CLICK_ON_SEARCH_QUERY_CHIP = {
  [TEST_ATTRIBUTE]: `QueryChips-clickOnChip`,
};

export const TEST_ANALYSIS_CLICK_ON_SEARCH_QUERY_BAR = {
  [TEST_ATTRIBUTE]: `SearchQuery-searchBar`,
};

export const TEST_SELECT_ANALYSIS_VIEW = {
  [TEST_ATTRIBUTE]: `AnalysisMain-selectAnalysisView`,
};

export const TEST_SELECT_MAP_VIEW = {
  [TEST_ATTRIBUTE]: `AnalysisMain-selectMapView`,
};

export const TEST_PERSON_FORM_SAVE_PERSON_INFO = {
  [TEST_ATTRIBUTE]: `Editperson-savePersonInfo`,
};

export const TEST_ICON_MENU_ITEM = {
  [TEST_ATTRIBUTE]: `IconMenu-menuItem`,
};

export const TEST_CAMERA_MANAGEMENT_EMPTY_ONVIF = {
  [TEST_ATTRIBUTE]: `CameraManagementEmpty-onvif`,
};

export const TEST_CAMERA_MANAGEMENT_EMPTY_MANUAL_SETUP = {
  [TEST_ATTRIBUTE]: `CameraManagementEmpty-openManualSetup`,
};

export const TEST_SETUP_PREVIEW_CAMERA_CLOSE = {
  [TEST_ATTRIBUTE]: `SetupCamera-closeicon`,
};

export const TEST_RTSP_PREVIEW_IMAGE = {
  [TEST_ATTRIBUTE]: `SetupCamera-rtspPreview`,
};

export const TEST_BACK_TO_SETUP_CAMERA = {
  [TEST_ATTRIBUTE]: `PreviewCamera-backSetupCamera`,
};

export const TEST_ADVANCED_SETTING_PASSWORD_ENTER_PASSWORD = {
  [TEST_ATTRIBUTE]: `PasswordPrompt-enterPassword`,
};

export const TEST_ADVANCED_SETTING_PASSWORD_SUBMIT_PASSWORD = {
  [TEST_ATTRIBUTE]: `PasswordPrompt-submitPassword`,
};

export const TEST_SAVE_CAMERA_FINISH = {
  [TEST_ATTRIBUTE]: `PreviewCamera-SaveCameraFinish`,
};

export const TEST_SAVE_CAMERA_CONNECT_ANOTHER = {
  [TEST_ATTRIBUTE]: `PreviewCamera-SaveCameraConnectAnother`,
};

export const TEST_ANALYSIS_PLAN_SORT_DATASOURCE_OPTION = {
  [TEST_ATTRIBUTE]: `DatasourcesSection-sortDatasourceOption`,
};

export const TEST_ANALYSIS_PLAN_SORT_NAME = {
  [TEST_ATTRIBUTE]: `DatasourcesSection-sortName-constant`,
};

export const TEST_ANALYSIS_PLAN_SORT_CHRONOLOGICAL = {
  [TEST_ATTRIBUTE]: `DatasourcesSection-sortChronological-constant`,
};

export const TEST_ADD_OBJECT_TO_PLAN = {
  [TEST_ATTRIBUTE]: `ObjectTile-objectLibraryTile`,
};

export const TEST_ANALYSIS_HISTORY_ACTIVE_TAB = {
  [TEST_ATTRIBUTE]: `AnalysisHistory-activeTab`,
};

export const TEST_ANALYSIS_HISTORY_EXPIRE_TAB = {
  [TEST_ATTRIBUTE]: `AnalysisHistory-expireTab`,
};

export const TEST_PROFILE_MENU_USERNAME = {
  [TEST_ATTRIBUTE]: `ProfileMenu-username`,
};

export const TEST_LIVE_MONITORING_LEFT_CHEVRON = {
  [TEST_ATTRIBUTE]: `LeftSideBar-chevronIcon`,
};

export const TEST_LIVE_MONITORING_QUERY_GROUP_MENU = {
  [TEST_ATTRIBUTE]: `LeftSideBar-queryGroupMenu`,
};

export const TEST_LIVE_MONITORING_QUERY_MENU_ICON = {
  [TEST_ATTRIBUTE]: `QueryMenu-queryMenuIcon`,
};

export const TEST_LIVE_MONITORING_QUERY_GROUP_MENU_ICON = {
  [TEST_ATTRIBUTE]: `QueryGroupMenuItem-queryGroupMenuIcon`,
};

export const TEST_LIVE_MONITORING_QUERY_COLUMN_MENU_ICON = {
  [TEST_ATTRIBUTE]: `QueryColumn-queryColumnMenuIcon`,
};

export const TEST_LIVE_MONITORING_COLUMN_HEADER = {
  [TEST_ATTRIBUTE]: `QueryColumn-columnHeader`,
};

export const TEST_LIVE_MONITORING_SORT_RESULTS_MENU = {
  [TEST_ATTRIBUTE]: `QueryColumn-sortResultsMenu`,
};

export const TEST_VIDEO_SIDE_BAR_TOGGLE_INSPECT_MODE = {
  [TEST_ATTRIBUTE]: `AnalysisSidebar-toggleInspectMode`,
};

export const TEST_VIDEO_PLAYER_LIVE_IMAGE = {
  [TEST_ATTRIBUTE]: `DetectionVideoPlayerView-liveImage`,
};

export const TEST_ADD_TO_PLAN = {
  [TEST_ATTRIBUTE]: `LibraryHeader-addtoplan-button`,
};

export const TEST_UPLOADING_DATASOURCE = {
  [TEST_ATTRIBUTE]: `DatasourceProcessingTile-uploadingVideo`,
};

export const TEST_CAMERA_MANAGEMENT_ONVIF_DISCOVERY = {
  [TEST_ATTRIBUTE]: `CameraManagementHeader-onvifCameraDiscovery`,
};

export const TEST_CAMERA_MANAGEMENT_MANUAL_SETUP = {
  [TEST_ATTRIBUTE]: `CameraManagementHeader-manualSetup`,
};

export const TEST_CAMERA_MANAGEMENT_SELECT_SETTINGS = {
  [TEST_ATTRIBUTE]: `SettingsRouter-selectSettingsTab`,
};

export const TEST_CAMERA_MANAGEMENT_SELECT_ADVANCED_SETTINGS = {
  [TEST_ATTRIBUTE]: `SettingsRouter-selectAdvancedSettingsTab`,
};

export const TEST_CAMERA_MANAGEMENT_SELECT_CAMERA_MANAGEMENT = {
  [TEST_ATTRIBUTE]: `SettingsRouter-selectCameraManagement`,
};

export const TEST_CAMERA_MANAGEMENT_SELECT_CHECKBOX = {
  [TEST_ATTRIBUTE]: `CameraManagementList-selectCheckbox`,
};

export const TEST_CAMERA_MANAGEMENT_SELECT_ROW = {
  [TEST_ATTRIBUTE]: `CameraManagementList-selectRow`,
};

export const TEST_CAMERA_MANAGEMENT_EDIT_CAMERA = {
  [TEST_ATTRIBUTE]: `CameraManagementList-editCamera`,
};

export const TEST_CAMERA_MANAGEMENT_CHANGE_LIVE_PROCESSING = {
  [TEST_ATTRIBUTE]: `CameraManagementList-changeLiveProcessing`,
};

export const TEST_CAMERA_MANAGEMENT_DELETE_CAMERA = {
  [TEST_ATTRIBUTE]: `CameraManagementList-deleteCamera`,
};

export const TEST_CAMERA_MANAGEMENT_ADD_VENUE_LOCATION = {
  [TEST_ATTRIBUTE]: `CameraManagementList-addVenueLocation`,
};

export const TEXT_SETTINGS_VENUE_MAP_MANAGEMENT = {
  [TEST_ATTRIBUTE]: `Settings-VenueMapManagement`,
};

export const TEXT_SETTINGS_SYSTEM_SETTINGS = {
  [TEST_ATTRIBUTE]: `Settings-SystemSettings`,
};

export const TEST_SCENE_PLAYER_SAVE_AS_VIDEO_CHECKBOX = {
  [TEST_ATTRIBUTE]: 'ScenePlayer-saveAsVideoCheckbox',
};

export const TEST_GEO_DASHBOARD_SETTINGS = {
  [TEST_ATTRIBUTE]: 'Geo-Dashboard-Settings',
};
