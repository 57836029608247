import { forwardRef, useCallback, useEffect, useRef } from 'react';

import Video from './Video';

const dashjs = require('dashjsLatest');

const dashJsSettings = {
  debug: {
    logLevel: dashjs.Debug.LOG_LEVEL_ERROR,
  },
  streaming: {
    buffer: {
      bufferToKeep: 30,
      bufferTimeAtTopQuality: 20,
      bufferTimeAtTopQualityLongForm: 20,
    },
    fragmentRequestTimeout: 30000,
    gaps: {
      jumpGaps: false,
    },
  },
};

const DashVideoLatest = props => {
  const dashPlayer = useRef(null);
  const video = useRef(null);

  const { bindRef, onDashError, src } = props;

  const setSource = useCallback(
    currentSource => {
      dashPlayer.current?.reset();
      if (!currentSource) return;

      dashPlayer.current = dashjs.MediaPlayer().create();
      dashPlayer.current.initialize(video.current, currentSource, false);
      dashPlayer.current.updateSettings(dashJsSettings);
      dashPlayer.current.on('error', error => onDashError?.(error));
    },
    [onDashError]
  );

  useEffect(() => {
    bindRef?.({ getDashPlayer: () => dashPlayer.current });
  }, [bindRef]);
  useEffect(() => {
    setSource(src);
  }, [setSource, src]);
  useEffect(
    () => () => {
      dashPlayer.current?.reset();
    },
    []
  );

  const { forwardedRef, ...rest } = props;
  return <Video {...rest} ref={forwardedRef} bindRef={node => (video.current = node)} />;
};

export default forwardRef((props, ref) => <DashVideoLatest {...props} forwardedRef={ref} />);
