import React from 'react';
import SplitPanelGroup from 'common/components/splitPanel/SplitPanelGroup';
import SplitPanel from 'common/components/splitPanel/SplitPanel';

const Container = props => (
  <div style={{ width: '100%', height: '100%', background: props.color }}>{props.text}</div>
);

export default class SplitPanelExample extends React.Component {
  state = {
    first: [{ position: 40 }, { position: 70 }],
    second: [{ position: 40 }, { position: 70 }],
    third: [{ position: 30 }, { position: 60 }],
    fourth: [{ position: 80 }],
    fifth: [{ position: 40 }, { position: 65 }, { position: 85 }],
    sixth: [
      { position: 20, minPosition: 10, maxPosition: 30 },
      { position: 50 },
      { position: 80, minPosition: 70, maxPosition: 90 },
    ],
  };

  onChange = key => config => this.setState({ [key]: config });

  render() {
    return (
      <div>
        <div
          style={{
            border: '3px solid yellow',
            background: 'rgb(250,250,250)',
            width: '100%',
            height: '300px',
          }}
        >
          <SplitPanelGroup
            split="rows"
            config={this.state.first}
            onUpdateConfig={this.onChange('first')}
          >
            <SplitPanel>
              <Container text="Row 1" color="rgb(210,210,210)" />
            </SplitPanel>
            <SplitPanel>
              <Container text="Row 2" color="rgb(180,180,180)" />
            </SplitPanel>
            <SplitPanel>
              <Container text="Row 3" color="rgb(140,140,140)" />
            </SplitPanel>
          </SplitPanelGroup>
        </div>

        <div
          style={{
            border: '3px solid yellow',
            marginTop: '50px',
            background: 'rgb(250,250,250)',
            width: '100%',
            height: '300px',
          }}
        >
          <SplitPanelGroup
            split="columns"
            config={this.state.second}
            onUpdateConfig={this.onChange('second')}
          >
            <SplitPanel>
              <Container text="Column 1" color="rgb(210,210,210)" />
            </SplitPanel>
            <SplitPanel>
              <Container text="Column 2" color="rgb(180,180,180)" />
            </SplitPanel>
            <SplitPanel>
              <Container text="Column 3" color="rgb(140,140,140)" />
            </SplitPanel>
          </SplitPanelGroup>
        </div>

        <div
          style={{
            border: '3px solid yellow',
            marginTop: '50px',
            background: 'rgb(250,250,250)',
            width: '100%',
            height: '300px',
          }}
        >
          <SplitPanelGroup
            split="columns"
            config={this.state.third}
            onUpdateConfig={this.onChange('third')}
          >
            <SplitPanel>
              <SplitPanelGroup
                split="rows"
                config={this.state.fourth}
                onUpdateConfig={this.onChange('fourth')}
              >
                <SplitPanel>
                  <Container text="1" color="rgb(210,210,210)" />
                </SplitPanel>
                <SplitPanel>
                  <Container text="2" color="rgb(180,180,180)" />
                </SplitPanel>
              </SplitPanelGroup>
            </SplitPanel>

            <SplitPanel>
              <Container text="3" color="rgb(210,210,210)" />
            </SplitPanel>

            <SplitPanel>
              <SplitPanelGroup
                split="rows"
                config={this.state.fifth}
                onUpdateConfig={this.onChange('fifth')}
              >
                <SplitPanel>
                  <Container text="4" color="rgb(210,210,210)" />
                </SplitPanel>
                <SplitPanel>
                  <Container text="5" color="rgb(180,180,180)" />
                </SplitPanel>
                <SplitPanel>
                  <Container text="6" color="rgb(140,140,140)" />
                </SplitPanel>
                <SplitPanel>
                  <Container text="7" color="rgb(110,110,110)" />
                </SplitPanel>
              </SplitPanelGroup>
            </SplitPanel>
          </SplitPanelGroup>
        </div>

        <div
          style={{
            border: '3px solid yellow',
            marginTop: '50px',
            background: 'rgb(250,250,250)',
            width: '100%',
            height: '300px',
          }}
        >
          <SplitPanelGroup
            split="columns"
            config={this.state.sixth}
            onUpdateConfig={this.onChange('sixth')}
          >
            <SplitPanel>
              <Container text="Column 1" color="rgb(210,210,210)" />
            </SplitPanel>
            <SplitPanel>
              <Container text="Column 2" color="rgb(180,180,180)" />
            </SplitPanel>
            <SplitPanel>
              <Container text="Column 3" color="rgb(140,140,140)" />
            </SplitPanel>
            <SplitPanel>
              <Container text="Column 4" color="rgb(110,110,110)" />
            </SplitPanel>
          </SplitPanelGroup>
        </div>
      </div>
    );
  }
}
