import React from 'react';
import Button from 'common/components/base/Button';
import Ripple from 'common/components/base/Ripple';
import { Example } from '../helpers';

const RippleExamples = () => {
  const ripple = React.useRef(null);
  return (
    <div>
      <Example>
        <Button onClick={() => ripple.current.start()}>ripple</Button>
        <Button onClick={() => ripple.current.stop()}>Stop</Button>
        <div style={{ width: 300, height: 300, position: 'relative' }}>
          <Ripple ref={ripple} />
        </div>
      </Example>
    </div>
  );
};

export default RippleExamples;
