import React from 'react';

import { REGENT_GREY } from 'common/constants/colors';

export default ({ style: { color = REGENT_GREY, width = 20, height = width } = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        d="M12.5 2C7.85 2 3.92 5.03 2.53 9.22L4.9 10c1.05-3.19 4.06-5.5 7.6-5.5 1.96 0 3.73.72 5.12 1.88L15 9h7V2l-2.6 2.6C17.55 3 15.15 2 12.5 2zM9.24 17.111c-.515.506-1.117.76-1.805.76-.88 0-1.583-.303-2.112-.907-.529-.603-.793-1.418-.793-2.443 0-.652.142-1.25.427-1.795a3.134 3.134 0 0 1 1.193-1.271A3.31 3.31 0 0 1 7.873 11c.656 0 1.24.164 1.75.492.51.328.907.8 1.19 1.415.282.615.425 1.32.43 2.113v.731c0 1.659-.413 2.962-1.237 3.91-.825.948-1.994 1.454-3.507 1.518l-.486.007v-1.648l.438-.007c1.718-.077 2.648-.884 2.789-2.42zm-1.32-.752c.32 0 .594-.082.824-.246.23-.164.405-.362.523-.594v-.814c0-.67-.127-1.19-.383-1.559-.255-.369-.597-.553-1.025-.553-.396 0-.722.18-.977.543-.256.363-.383.817-.383 1.364 0 .542.124.988.372 1.336.249.35.598.523 1.05.523zm11.43.616c0 1.376-.285 2.429-.854 3.158-.57.729-1.404 1.094-2.502 1.094-1.085 0-1.914-.358-2.488-1.074-.575-.715-.869-1.74-.882-3.076v-1.832c0-1.39.288-2.445.864-3.165.577-.72 1.408-1.08 2.492-1.08 1.085 0 1.914.357 2.488 1.07.575.713.869 1.737.882 3.073v1.832zm-1.975-2.01c0-.825-.113-1.425-.339-1.801-.225-.376-.577-.564-1.056-.564-.465 0-.808.178-1.029.536-.22.358-.338.917-.352 1.678v2.42c0 .812.11 1.414.332 1.808.22.395.575.592 1.063.592.483 0 .832-.19 1.046-.568.214-.378.326-.957.335-1.736v-2.365z"
      />
    </g>
  </svg>
);
