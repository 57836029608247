import React from 'react';
import CollapsableDrawer from 'common/components/base/CollapsableDrawer';
import Icon from 'common/components/base/Icon';
import Fab from 'common/components/base/Fab';

import { Example } from '../helpers';

export default class ButtonExample extends React.Component {
  renderList = count => {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(this.renderListItem(i));
    }

    return <ul style={{ display: 'flex', flexDirection: 'column' }}>{result}</ul>;
  };

  renderListItem = i => <li style={{ display: 'flex', alignItems: 'center' }}>Result {i}</li>;

  renderIcon = props => {
    const icon = props.open ? (
      <Icon iconName="chevron_left" color="blue" />
    ) : (
      <Icon iconName="menu" color="blue" />
    );

    return (
      <Fab variant="fab" size="small" {...props}>
        {icon}
      </Fab>
    );
  };

  render() {
    return (
      <div>
        <Example label="Drawer">
          <div style={{ width: '100%', height: 500, border: '3px solid black' }}>
            <CollapsableDrawer style={{ height: '100%' }}>{this.renderList(10)}</CollapsableDrawer>
          </div>
        </Example>

        <Example label="Drawer with Custom Icon">
          <div style={{ width: '100%', height: 500, border: '3px solid black' }}>
            <CollapsableDrawer style={{ height: '100%' }} renderIcon={this.renderIcon}>
              {this.renderList(10)}
            </CollapsableDrawer>
          </div>
        </Example>
      </div>
    );
  }
}
