import React from 'react';
import Map from 'common/components/map/Map';
import { Example } from '../helpers';

export default class LiveVideoPlayerExample extends React.Component {
  state = {
    markerConfig: [],
  };

  componentDidMount = () => {
    this.setState({
      markerConfig: [
        { position: { lat: 37.7749, lng: -122.4194 } },
        { position: { lat: 37.7739, lng: -122.4191 } },
        { position: { lat: 37.7719, lng: -122.4183 } },
        { position: { lat: 37.7709, lng: -122.4199 } },
      ],
    });

    setTimeout(() => {
      this.setState({
        markerConfig: [
          { position: { lat: 37.7749, lng: -122.4194 } },
          { position: { lat: 37.7739, lng: -122.4191 } },
          { position: { lat: 37.7719, lng: -122.4183 } },
          { position: { lat: 37.7709, lng: -122.4199 } },
          { position: { lat: 37.7759, lng: -122.4199 } },
        ],
      });
    }, 5000);
  };

  render() {
    return (
      <div>
        <Example label="Map With Lines">
          <Map markerConfig={this.state.markerConfig} drawLine />
        </Example>
      </div>
    );
  }
}
