const defaults = {
  exportJobs: [],
  importJobs: [],
  repositories: [],
  isExportJobsLoading: false,
  isImportJobsLoading: false,
};

export default function dataShiftViewReducer(state = defaults, action) {
  switch (action.type) {
    case 'DATA_SHIFT/SET_EXPORT_JOBS': {
      return {
        ...state,
        exportJobs: action.payload,
      };
    }

    case 'DATA_SHIFT/SET_IMPORT_JOBS': {
      return {
        ...state,
        importJobs: action.payload,
      };
    }

    case 'DATA_SHIFT/SET_REPOSITORIES': {
      return {
        ...state,
        repositories: action.payload,
      };
    }

    case 'DATA_SHIFT/EXPORT_JOBS_LOADING': {
      return {
        ...state,
        isExportJobsLoading: action.payload,
      };
    }

    case 'DATA_SHIFT/IMPORT_JOBS_LOADING': {
      return {
        ...state,
        isImportJobsLoading: action.payload,
      };
    }

    default:
      return state;
  }
}
