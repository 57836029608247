import { styled, createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Icon from 'common/components/base/Icon';
import Button from 'common/components/base/Button';
import IconButton from 'common/components/base/IconButton';

import Tooltip from 'common/components/base/Tooltip';
import Typography from 'components/Typography';

const useStyles = createUseStyles(theme => ({
  controls: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: '#80808075',
    zIndex: 20,
  },
  shiftedControlsBottomSpacing: {
    bottom: theme.spacing(4),
  },
  centerLabel: {
    color: 'white',
    padding: '6px 5px',
    fontSize: '12px',
    minWidth: 'auto',
    cursor: 'default',
  },
}));

const StyledButton = styled(Button)(() => ({
  color: 'white',
  padding: '4px',
  fontSize: '10px',
  minWidth: 'auto',
}));

const PanAndZoomControls = ({
  additionalControlsPadding,
  isPristine,
  onReset,
  onZoomIn,
  onZoomOut,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.controls, {
        [classes.shiftedControlsBottomSpacing]: additionalControlsPadding,
      })}
      data-testid="PanAndZoomControls"
    >
      <Tooltip title={<Typography variant="body1">Zoom Out</Typography>}>
        <IconButton onClick={onZoomOut} size="small">
          <Icon iconName="remove" color="white" size="small" /> {/* disable when @ 100% */}
        </IconButton>
      </Tooltip>

      {isPristine ? (
        <Typography variant="body1" className={classes.centerLabel}>
          ZOOM
        </Typography>
      ) : (
        <Tooltip title={<Typography variant="body1">Reset Pan and Zoom</Typography>}>
          <div>
            <StyledButton size="small" variant={null} onClick={onReset}>
              RESET
            </StyledButton>
          </div>
        </Tooltip>
      )}

      <Tooltip title={<Typography variant="body1">Zoom In</Typography>}>
        <IconButton onClick={onZoomIn} size="small">
          <Icon iconName="add" color="white" size="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default PanAndZoomControls;
