import axios from 'axios.js';
import { parseContentRange } from 'common/api/utils';

import {
  downloadBlob,
  extractFileNameFromDisposition,
  COMMON_MIME_TYPES,
} from 'common/helpers/fileUtils';
import { ANALYTICS_BACKEND } from 'common/constants/urls';

/* ================================ PERSON ================================== */

export function getSinglePerson(id) {
  return axios.get(`${ANALYTICS_BACKEND}/ppl/${id}/`).then(({ data }) => data);
}

export function getPersons(params) {
  return axios.get(`${ANALYTICS_BACKEND}/ppl/`, { params }).then(({ data }) => data);
}

function getExportedIdentity(id) {
  const options = {
    responseType: 'arraybuffer',
  };
  return axios.get(`${ANALYTICS_BACKEND}/ppl/${id}/zip/`, options).then(({ data }) => data);
}

function getExportedPersons(ids) {
  const options = {
    responseType: 'arraybuffer',
  };
  return axios.get(`${ANALYTICS_BACKEND}/ppl/zip/?ids=${ids}`, options).then(data => data);
}

export function exportIdentity({ id, name }) {
  return getExportedIdentity(id).then(response => {
    const blob = new Blob([response], {
      type: COMMON_MIME_TYPES.zip,
    });
    downloadBlob(blob, `${name}.zip`);
  });
}

export function exportSelectedPersons(personIds) {
  return getExportedPersons(personIds).then(response => {
    const blob = new Blob([response.data], {
      type: COMMON_MIME_TYPES.zip,
    });
    const disposition = response.headers['content-disposition'];
    const filename = extractFileNameFromDisposition(disposition) || `bulk_export.zip`;
    downloadBlob(blob, filename);
  });
}

export function deletePerson(id) {
  return axios.delete(`${ANALYTICS_BACKEND}/ppl/${id}/`).then(() => id);
}

export function deleteImagesFromPerson({ id, images }) {
  return Promise.all(
    images.map(url => {
      const urls = url.split('/');
      const l = urls.length;

      // image url contains folder and file extension which need to be processed
      const hash = (urls[l - 2] + urls[l - 1]).match(/(.+?)(\.[^.]*$|$)/)[1];
      return axios.delete(`${ANALYTICS_BACKEND}/ppl/${id}/image/`, {
        params: { hash },
      });
    })
  );
}

export function patchPerson(person) {
  return axios.patch(`${ANALYTICS_BACKEND}/ppl/${person.id}/`, person).then(({ data }) => data);
}

export function postOrderedTracklets(body) {
  return axios.post(`${ANALYTICS_BACKEND}/ppl/search/`, body).then(({ data }) => data);
}

export function postConfirmIdentity(identity) {
  return axios.post(`${ANALYTICS_BACKEND}/ppl/confirm/`, identity).then(({ data }) => data);
}

export function deleteConfirmIdentity({ id, fh }) {
  return axios.delete(`${ANALYTICS_BACKEND}/ppl/${id}/image/`, {
    params: { hash: fh },
  });
}

export const getConnectCandidates = (id, { page = 1, page_size: pageSize = 50, ...rest }) =>
  axios
    .get(`${ANALYTICS_BACKEND}/ppl/${id}/connect_candidates/`, {
      params: { page, page_size: pageSize, ...rest },
    })
    .then(({ data, headers }) => ({ data, range: parseContentRange(headers) }));
