import { get, isEmpty, orderBy } from 'lodash';
import { createSelector } from 'reselect';

import { isPercipientEmail } from 'common/helpers/userUtils';

import { GROUPS, LOCAL_AUTH_TYPE } from './constants';

export function selectCurrentUser(state) {
  return get(state, 'auth.user');
}

function selectUserAuthType(state) {
  return get(state, 'auth.userAuthType');
}

export function selectCurrentUsername(state) {
  return get(state, 'auth.user.username');
}

export const selectOpenIdConfig = state => get(state, 'auth.openIdConfig');
export const selectOIDCEndpoint = state => get(state, 'auth.openIdConfig.OIDC_ENDPOINT');
export const selectOIDCEnabled = state => get(state, 'auth.openIdConfig.OIDC_ENABLED');
export const selectOIDCClientId = state => get(state, 'auth.openIdConfig.OIDC_CLIENT_ID');

export const selectCurrentUserGroups = createSelector(selectCurrentUser, currentUser =>
  get(currentUser, 'groups', [])
);

export const selectCustomerDefinedUserGroups = createSelector(
  selectCurrentUserGroups,
  userGroups => {
    const percipientDefinedGroups = Object.values(GROUPS);

    return orderBy(
      userGroups.filter(group => !percipientDefinedGroups.includes(group)),
      [group => group.toLowerCase()]
    );
  }
);

export const selectIsLocalAuthType = createSelector(
  selectUserAuthType,
  authType => authType === LOCAL_AUTH_TYPE
);

export const selectIsSuperuser = createSelector(
  selectCurrentUser,
  currentUser => currentUser.isSuperuser
);

export const selectIsStaffUser = createSelector(
  selectCurrentUser,
  currentUser => currentUser.isStaff
);

export const selectCurrentUserId = createSelector(selectCurrentUser, currentUser => currentUser.id);

export const selectIsAdmin = createSelector(
  selectIsSuperuser,
  selectIsStaffUser,
  (isSuper, isStaff) => isSuper || isStaff
);

const makeSelectUserHasGroupPermission = group =>
  createSelector(selectCurrentUserGroups, groups => groups.includes(group));

export const selectHasMintGroup = makeSelectUserHasGroupPermission(GROUPS.MINT);

export const selectCanAccessCameraManagement = createSelector(
  selectIsSuperuser,
  makeSelectUserHasGroupPermission(GROUPS.CAMERA_MANAGEMENT),
  (isSuperuser, hasCameraManagementGroup) => isSuperuser || hasCameraManagementGroup
);

export const selectCanUpdatePrime = createSelector(
  selectIsSuperuser,
  makeSelectUserHasGroupPermission(GROUPS.PRIME),
  (isSuperuser, canUpdatePrime) => isSuperuser || canUpdatePrime
);

export const selectHasPercipientEmail = createSelector(selectCurrentUser, user =>
  isPercipientEmail(user.email)
);

export const selectIsLoggingOut = state => state.auth.selectIsLoggingOut;
export const selectIsAuthenticated = createSelector(selectCurrentUser, user => !isEmpty(user));
export const selectIsOIDCSession = state => state.auth.isOIDCSession;
