import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MoreLikeThisVehicle = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path d="M19.6666667,7 C20.1066667,7 20.48,7.2625 20.6133333,7.63125 L20.6133333,7.63125 L22,11.375 L22,16.375 C22,16.71875 21.7,17 21.3333333,17 L21.3333333,17 L20.6666667,17 C20.3,17 20,16.71875 20,16.375 L20,16.375 L20,15.75 L12,15.75 L12,16.375 C12,16.71875 11.7,17 11.3333333,17 L11.3333333,17 L10.6666667,17 C10.3,17 10,16.71875 10,16.375 L10,16.375 L10,11.375 L11.3866667,7.63125 C11.5266667,7.2625 11.8933333,7 12.3333333,7 L12.3333333,7 Z M5,8 L9,12 L5,16 L5,12.8 L1,12.8 L1,11.2 L5,11.2 L5,8 Z M12.3333333,12 C11.78,12 11.3333333,12.41875 11.3333333,12.9375 C11.3333333,13.45625 11.78,13.875 12.3333333,13.875 C12.8866667,13.875 13.3333333,13.45625 13.3333333,12.9375 C13.3333333,12.41875 12.8866667,12 12.3333333,12 Z M19.6666667,12 C19.1133333,12 18.6666667,12.41875 18.6666667,12.9375 C18.6666667,13.45625 19.1133333,13.875 19.6666667,13.875 C20.22,13.875 20.6666667,13.45625 20.6666667,12.9375 C20.6666667,12.41875 20.22,12 19.6666667,12 Z M19.6666667,7.9375 L12.3333333,7.9375 L11.3333333,10.75 L20.6666667,10.75 L19.6666667,7.9375 Z" />
  </SvgIcon>
);

export default MoreLikeThisVehicle;
