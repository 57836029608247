import DateTime from 'dateTime';
import { inRange, isFinite, minBy, maxBy } from 'lodash';

import { zeroPad } from 'common/helpers/helperFunctions';
import pluralize from 'pluralize';
import { round } from './mathUtils';
import getUTCOffsetString from './getUTCOffsetString';

export const UNKNOWN_TIMEZONE = 'Unknown TZ';

/**
 MIR-8565: Due to limitations in reading data from the EXIF tag, the time
 zone for most images uploaded to Mirage cannot be determined.
 For Ohm.0, we show the user that the TZ is "Unknown". For Ohm.1, we will give
 the option to set the timezone manually.
 */
export function formatImageDate(date, utcOffsetSec = null, options = {}) {
  const { parser = parseToShorthandDate, displayTimezoneName = null, ...otherOptions } = options;
  let { showTimeZone = true } = options;
  let suffix = '';
  let keepLocalTime = false;
  // If UTC offset does not exist it is null. Zero is a valid value so we
  // account for that
  const doesUtcOffsetExist = utcOffsetSec || utcOffsetSec === 0;

  /**
   We subtract the UTC offset from the date to adjust the date to UTC so that
   the correct timezone is displayed.

   For instance, if the date is: "2020-04-06T12:00:00.000Z" and the UTC offset
   is -25,200 (UTC-7), the date gets adjusted to "2020-04-06T19:00:00.000Z"
   and will display as 12:00 PM MDT

   If UTC offset does not exist the value is null, null * 1000 = 0 so there
   is no adjustment
   */
  if (showTimeZone && !doesUtcOffsetExist) {
    if (!displayTimezoneName) {
      showTimeZone = false;
      suffix = ` ${UNKNOWN_TIMEZONE}`;
    }
    keepLocalTime = true;
  }
  let adjustedDate = DateTime.fromJSDateWithTZ(date, {
    keepLocalTime,
    timeZone: displayTimezoneName,
  });
  if (doesUtcOffsetExist) adjustedDate = adjustedDate.minus(utcOffsetSec * 1000);

  const formattedDate = parser(adjustedDate, {
    ...otherOptions,
    showTimeZone,
    displayTimezoneName,
  });

  return `${formattedDate}${suffix}`;
}

export function getCurrentTimeZoneText() {
  return `(${getCurrentTimeZoneAbbr(new Date())}) ${DateTime.local().zoneName}`;
}

export function getCurrentTimeZoneAbbr(date) {
  /**
   @param date Date object to extract timezone from. "Unknown" is returned if
   this value is undefined to highlight that this function has not been used
   properly
   */

  if (!date) return UNKNOWN_TIMEZONE;

  const timeZone = date.isLuxonDateTime ? date.zoneName : null;
  const abbreviatedTimeZone = DateTime.fromJSDateWithTZ(date, { timeZone }).offsetNameShort;
  return abbreviatedTimeZone;
}

export function parseToDatasourcePopupDate(date, timeZone = null) {
  const parsedDate = DateTime.fromJSDateWithTZ(date, { timeZone });
  const formattedDateTime = parsedDate.toFormat('ccc dd LLL yyyy, HH:mm:ss');
  return `${formattedDateTime} ${getCurrentTimeZoneAbbr(parsedDate)}`;
}

export function parseToMonthDayYear(time) {
  const localizedDate = DateTime.fromJSDateWithTZ(time).toFormat('DDD');
  return localizedDate;
}

export function parseToSameDayFormat(time, options = {}) {
  const { disableTime, addTimezone, displayTimezoneName } = options;
  const date = DateTime.fromJSDateWithTZ(time, { timeZone: displayTimezoneName });
  const today = DateTime.fromJSDateWithTZ(null, { timeZone: displayTimezoneName });

  if (
    !disableTime &&
    date.day === today.day &&
    date.month === today.month &&
    date.year === today.year
  ) {
    return addTimezone
      ? `${date.toFormat('HH:mm')} ${getCurrentTimeZoneAbbr(date)}`
      : date.toFormat('HH:mm');
  }

  return date.toFormat('dd LLL yyyy');
}

export function parseToShorthandDate(time, options = {}) {
  const {
    showSeconds = false,
    showMilliseconds = false,
    showTime = true,
    showTimeZone = false,
    showDayOfWeek = false,
    displayTimezoneName = null,
  } = options;
  let format = 'dd LLL yyyy';

  if (showDayOfWeek) format = `ccc, ${format}`;

  if (showMilliseconds) format += ', HH:mm:ss.SSSS';
  else if (showSeconds) format += ', HH:mm:ss';
  else if (showTime) format += ', HH:mm';

  const dateTime = DateTime.fromJSDateWithTZ(time, { timeZone: displayTimezoneName });

  const timeZone = showTimeZone ? ` ${getCurrentTimeZoneAbbr(dateTime)}` : '';
  return `${dateTime.toFormat(format)}${timeZone}`;
}

export function parseToVerboseDate(time, { showTimeZone = true, displayTimezoneName = null } = {}) {
  const parsedTime = DateTime.fromJSDateWithTZ(time, { timeZone: displayTimezoneName });
  const formattedTime = parsedTime.toFormat('cccc, d LLLL yyyy, HH:mm:ss');

  return showTimeZone ? `${formattedTime} ${getCurrentTimeZoneAbbr(parsedTime)}` : formattedTime;
}

export function parseToFullDate(time) {
  return `${DateTime.fromJSDateWithTZ(time).toFormat(
    'ccc, d LLL yyyy, HH:mm'
  )} ${getCurrentTimeZoneAbbr(time)}`;
}

export function parseToTime(
  time,
  { format = 'HH:mm:ss', withTimeZone = false, displayTimezoneName = null } = {}
) {
  const parsedDateTime = DateTime.fromJSDateWithTZ(time, {
    timeZone: displayTimezoneName,
  });
  const formatted = parsedDateTime.toFormat(`${format}`);
  return withTimeZone ? `${formatted} ${getCurrentTimeZoneAbbr(parsedDateTime)}` : formatted;
}

export function parseToHHMMSS(time, { withTimeZone = false, displayTimezoneName = null } = {}) {
  return parseToTime(time, { format: 'HH:mm:ss', withTimeZone, displayTimezoneName });
}

export function parseToHHMM(time, { withTimeZone = false, displayTimezoneName = null } = {}) {
  return parseToTime(time, { format: 'HH:mm', withTimeZone, displayTimezoneName });
}

export function parseDurationToHHMMSS(time, { showMilliseconds = false, showHours = false } = {}) {
  const timeInSeconds = Math.round(time) / 1000;
  const seconds = Math.floor(timeInSeconds % 60);
  const minutes = (Math.floor(timeInSeconds - seconds) % 3600) / 60;
  const hours = Math.floor((timeInSeconds - seconds) / 3600);

  const withMs = showMilliseconds ? round(timeInSeconds % 1000, 3) : seconds;

  const paddedHours = zeroPad(hours, 2);
  const paddedMins = zeroPad(minutes, 2);
  const paddedSecs = zeroPad(withMs, 2);

  if (!showHours && paddedHours === '00') {
    return `${paddedMins}:${paddedSecs}`;
  }

  return `${paddedHours}:${paddedMins}:${paddedSecs}`;
}

export function parseDurationToText(duration, { alwaysShowSeconds = false } = {}) {
  const timeInSeconds = Math.round(Math.abs(duration / 1000));
  const seconds = Math.floor(timeInSeconds % 60);

  //  If the duration is negative, we'll want to prefix everything with a negative sign
  const prefix = duration < 0 ? '-' : '';

  //  If the result is less than a minute, we'll always show it in seconds
  if (timeInSeconds < 60) {
    return `${prefix}${pluralize('second', seconds, true)}`;
  }

  const minutes = (Math.floor(timeInSeconds - seconds) % 3600) / 60;
  //  If the result is less than an hour, we'll indicate the number of minutes, optionally with seconds
  if (timeInSeconds < 3600) {
    let result = `${prefix}${pluralize('minute', minutes, true)}`;
    if (alwaysShowSeconds) {
      result += `, ${pluralize('second', seconds, true)}`;
    }
    return result;
  }

  //  We'll show hours, minutes, and optionally seconds
  const hours = Math.floor((timeInSeconds - seconds) / 3600);
  let result = `${prefix}${pluralize('hour', hours, true)}, ${pluralize('minutes', minutes, true)}`;
  if (alwaysShowSeconds) {
    result += `, ${pluralize('second', seconds, true)}`;
  }
  return result;
}

export function getMomentDiff(start, end, unit = 'days') {
  const startDateTime = DateTime.fromJSDateWithTZ(start);
  const endDateTime = DateTime.fromJSDateWithTZ(end);
  const diff = endDateTime.diff(startDateTime, unit).toObject()?.[unit];
  return Math.round(diff);
}

export function isSameDate(a, b, unit = 'day') {
  const aDateTime = DateTime.fromJSDateWithTZ(a);
  const bDateTime = DateTime.fromJSDateWithTZ(b);
  let result = false;
  switch (unit) {
    case 'month':
      result = aDateTime.year === bDateTime.year && aDateTime.month === bDateTime.month;
      break;
    case 'year':
      result = aDateTime.year === bDateTime.year;
      break;
    default:
      result =
        aDateTime.year === bDateTime.year &&
        aDateTime.month === bDateTime.month &&
        aDateTime.day === bDateTime.day;
      break;
  }
  return result;
}

export function getTimeFromHHMMSS(str) {
  const parts = str.trim().split(':').reverse();

  if (!inRange(parts.length, 2, 4)) return undefined;

  const [seconds = 0, minutes = 0, hours = 0] = parts;

  /* Return time in milliseconds */
  return seconds * 1000 + minutes * 60 * 1000 + hours * 60 * 60 * 1000;
}

/*
  This essentially translates to this format:

  DD MMM (YYYY)? (HH:MM:(SS)? (AM/PM)?)?

  - Day and Month are required
  - Year will default to the current year is not specified, it must be 4 numbers
  - If time is present, Hours and Minutes must present, Seconds and Meridiem are optional
  - Hours does not have to be padded (3:05, 03:05 are both valid)
  - Minutes and Seconds must be padded (3:5 is not valid, but 3:05 is valid)
*/
const SHORTHAND_DATE_REGEX = /^(\d{1,2})\s([A-Za-z]{3,9})(\s\d\d\d\d)?,?(\s\d\d?:\d\d:?\d?\d?)?(\s[aA][mM]|\s[pP][mM])?$/;

const MONTHS = {
  jan: 1,
  january: 1,
  feb: 2,
  february: 2,
  mar: 3,
  march: 3,
  apr: 4,
  april: 4,
  may: 5,
  jun: 6,
  june: 6,
  jul: 7,
  july: 7,
  aug: 8,
  august: 8,
  sep: 9,
  september: 9,
  oct: 10,
  october: 10,
  nov: 11,
  november: 11,
  dec: 12,
  december: 12,
};

export function getMonthFromName(str = '') {
  return MONTHS[str.toLowerCase()];
}

export function getHMSInfo(time = '') {
  const split = time.split(':');
  if (!inRange(split.length, 2, 4)) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const hours = Number(split[0]);
  const minutes = Number(split[1]);
  const seconds = Number(split[2]);

  if (isFinite(hours) && isFinite(minutes) && inRange(hours, 0, 24) && inRange(minutes, 0, 60)) {
    if (isFinite(seconds) && inRange(seconds, 0, 60)) {
      return { hours, minutes, seconds };
    }

    return { hours, minutes, seconds: 0 };
  }

  return {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
}

function formatTime(time = '', meridiem = '') {
  const trimmed = time.trim();
  const { hours, minutes, seconds } = getHMSInfo(trimmed);
  const result = `:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
  if (meridiem && meridiem.toLowerCase().trim() === 'pm' && hours < 12) {
    return `${hours + 12}${result}`;
  }

  if (meridiem && meridiem.toLowerCase().trim() === 'am' && hours === 12) {
    return `00${result}`;
  }

  return `${zeroPad(hours, 2)}${result}`;
}

export function getAbsoluteTimeFromDateString(string, hideOffset = false) {
  const match = string.match(SHORTHAND_DATE_REGEX);

  if (!match) return null;

  const currYear = String(DateTime.fromJSDateWithTZ().year);
  const { 1: day, 2: monthString, 3: year = currYear, 4: time, 5: meridiem } = match;
  const month = getMonthFromName(monthString);

  if (!day || !monthString || !month) return null;

  const paddedMonths = zeroPad(month, 2);
  const paddedDays = zeroPad(day, 2);
  const isoTime = `T${formatTime(time, meridiem)}${
    hideOffset ? '' : getUTCOffsetString(string, { year })
  }`;
  const isoDate = `${year.trim()}-${paddedMonths}-${paddedDays}${isoTime}`;

  const unixTime = DateTime.fromISO(isoDate).valueOf();
  return unixTime;
}

export const startOfDay = date => new Date(new Date(date).setHours(0, 0, 0));
export const endOfDay = date => new Date(new Date(date).setHours(23, 59, 59));

/**
 * getFirstLastDate
 * Pick the first and last date in an array of timestamps.
 *
 * @param {array} dates – Unordered array of ISO compatible timestamps
 * @return {array} Contains first and last date in array
 */

export const getFirstLastDate = (dates, options = {}) => {
  if (!dates?.length) return;

  const firstDate = minBy(dates, date => new Date(date));
  const lastDate = maxBy(dates, date => new Date(date));

  if (options.dateBoundaries) return [startOfDay(firstDate), endOfDay(lastDate)];

  return [firstDate, lastDate];
};

export const convertOffsetToGMTFormat = (offsetInMinutes = 0) => {
  const modifier = offsetInMinutes < 0 ? '-' : '+';
  const offsetInHours = offsetInMinutes / 60;
  const paddedZero = Math.abs(offsetInHours) < 10 ? '0' : '';
  return `GMT${modifier}${paddedZero}${Math.abs(offsetInHours)}:00`;
};

export const formatTimeZoneName = ({ name, continentName, mainCities, alternativeName }, date) => {
  const myLuxon = DateTime.fromJSDateWithTZ(date).setZone(name);
  const shortOffset = myLuxon.toFormat('ZZ');
  const abbreviatedNamedOffset = myLuxon.toFormat('ZZZZ');
  const cityList = mainCities.join(', ');

  return {
    name,
    continentName,
    label: `(UTC ${shortOffset}) ${alternativeName}/${abbreviatedNamedOffset} - ${cityList}`,
  };
};
