import { buildLocationFromGooglePlace } from './utils';

class GeoCoder {
  constructor() {
    this._geoCoder = new window.google.maps.Geocoder();
  }

  getLocationByLatLng(coords) {
    const latlng = new window.google.maps.LatLng(coords.lat, coords.lng);

    return new Promise((resolve, reject) => {
      this._geoCoder.geocode(
        {
          location: latlng,
        },
        (results, status) => {
          if (status === 'OK' && results[0]) {
            return resolve(buildLocationFromGooglePlace(results[0]));
          }

          return reject(new Error('Location cannot be found'));
        }
      );
    });
  }
}

export default GeoCoder;
