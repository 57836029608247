import React from 'react';
import { styled } from 'react-jss';
import { useSelector } from 'react-redux';
import Typography from 'components/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Checkbox from 'common/components/base/Checkbox';
import * as SettingsSelectors from 'settings/redux/selectors';
import { useLogin } from './LoginContext';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.spacing(-1),
  marginBottom: theme.spacing(3),
}));

const StyledCheckbox = styled(Checkbox)({
  height: 26,
  marginTop: -6,
});

const CheckboxLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 10,
  display: 'block',
}));

const ConsentCheckbox = props => {
  const requireConsent = useSelector(SettingsSelectors.selectConsentBannerContent);
  const { hasConsent, setHasConsent, setIsConsentBannerOpen } = useLogin();

  return requireConsent ? (
    <Root data-testid="signin-consentCheckbox" {...props}>
      <StyledCheckbox
        size="small"
        checked={hasConsent}
        onChange={e => setHasConsent(e.target.checked)}
      />
      <CheckboxLabel variant="body3">
        I agree and consent to the conditions set forth in{' '}
        <ButtonBase onClick={() => setIsConsentBannerOpen(true)}>
          <Typography variant="link3">USG Warning & Consent Banner</Typography>
        </ButtonBase>
      </CheckboxLabel>
    </Root>
  ) : null;
};

export default ConsentCheckbox;
