import React, { useEffect } from 'react';
import injectSheet, { styled } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';

import Icon from 'common/components/base/Icon';
import Typography from 'components/Typography';

import { getData } from 'common/errors';
import { getCurrentUser } from 'common/api/userApi';
import { setLoggingError } from 'app/redux/actions';
import { selectLoggingError } from 'app/redux/selectors';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    height: 'fit-content',
    width: 'fit-content',
    maxWidth: '33%',
    padding: `${theme.spacing(6)}px ${theme.spacing(8)}px`,
  },
  icon: {
    fontSize: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
});

const SytledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

const LoggingError = ({ classes }) => {
  const dispatch = useDispatch();
  const loggingError = useSelector(selectLoggingError);
  const fullMessage = getData(loggingError);
  const hasLoggingError = Boolean(loggingError);

  useEffect(() => {
    let intervalId;

    if (hasLoggingError) {
      const checkAndClearError = () =>
        getCurrentUser().then(currentUser => {
          // If the currentUser exists then the request was successful which means the
          // logging error has been cured
          if (currentUser) {
            dispatch(setLoggingError(null));
            clearInterval(intervalId);
          }
        });

      if (!intervalId) intervalId = setInterval(checkAndClearError, 10000);
    } else if (intervalId) {
      clearInterval(intervalId);
    }
  }, [hasLoggingError, dispatch]);

  return (
    <Dialog classes={{ paper: classes.root }} open={hasLoggingError}>
      <Icon className={classes.icon} iconName="error" theme="red" />
      <div>
        <SytledTypography variant="h4">Audit Logging Error</SytledTypography>
        <SytledTypography variant="body1">{fullMessage}</SytledTypography>
      </div>
    </Dialog>
  );
};

export default injectSheet(styles)(LoggingError);
