import isEqual from 'lodash/isEqual';

const defaults = {
  allData: [],
  filter: {
    identityStatus: null,
    objectType: null,
  },
};

export default function personsReducer(state = defaults, action) {
  switch (action.type) {
    case 'LIBRARY/PERSON/SET_ALL_DATA': {
      //  Prevent unnecessary component update when data has not changed
      if (!isEqual(state.allData, action.payload)) {
        return {
          ...state,
          allData: action.payload,
        };
      }
      return state;
    }
    case 'LIBRARY/PERSON/SET_FILTER_IDENTITY_STATUS': {
      return {
        ...state,
        filter: {
          ...state.filter,
          identityStatus: action.payload,
        },
      };
    }
    case 'LIBRARY/PERSON/SET_FILTER_OBJECT_TYPE': {
      return {
        ...state,
        filter: {
          ...state.filter,
          objectType: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
