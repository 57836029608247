import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';

const ENTER_EXIT_DURATION = 500;

const useStyles = createUseStyles({
  main: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
    borderRadius: '50%',
    transform: 'scale(0)',
    backgroundColor: props => props.color || 'black',
    transition: 'all 0.5s',
    opacity: 0,
    pointerEvents: 'none',
  },
  entering: {
    animation: `$enter ${ENTER_EXIT_DURATION}ms ease-in-out`,
  },
  pulsating: {
    animation: `$pulsate ${ENTER_EXIT_DURATION * 2}ms ease-in-out infinite`,
  },
  exiting: {
    animation: `$exit ${ENTER_EXIT_DURATION}ms ease-in-out`,
  },
  '@keyframes enter': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.7,
    },
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1)',
      opacity: 0.7,
    },
    '50%': {
      transform: 'scale(0.92)',
      opacity: 0.7,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0.7,
    },
  },
  '@keyframes exit': {
    '0%': {
      transform: 'scale(0.96)',
      opacity: 0.7,
    },
    '100%': {
      transform: 'scale(0)',
      opacity: 0,
    },
  },
});

const TouchRipple = forwardRef(({ color, ...rest }, ref) => {
  const classes = useStyles({ color });
  const savedTimeouts = useRef({});
  const rippleRef = useRef(null);
  const [isEntering, setIsEntering] = useState(false);
  const [isPulsating, setIsPulsating] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const start = React.useCallback(() => {
    if (isEntering || isPulsating) return;

    setIsEntering(true);
    const startTimeout = setTimeout(() => setIsPulsating(true), ENTER_EXIT_DURATION);

    savedTimeouts.current.start = startTimeout;
  }, [isEntering, isPulsating]);

  const stop = React.useCallback(() => {
    clearTimeout(savedTimeouts.current.start);

    if (isExiting) return;

    setIsExiting(true);
    setIsEntering(false);
    setIsPulsating(false);

    const stopTimeout = setTimeout(() => {
      setIsExiting(false);
    }, ENTER_EXIT_DURATION);

    savedTimeouts.current.stop = stopTimeout;
  }, [isExiting]);

  useEffect(() => {
    const { start: startTimeout, stop: stopTimeout } = savedTimeouts.current;
    return function cleanup() {
      clearTimeout(startTimeout);
      clearTimeout(stopTimeout);
    };
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      start,
      stop,
    }),
    [start, stop]
  );

  return (
    <div
      ref={rippleRef}
      className={classnames(classes.main, {
        [classes.entering]: isEntering,
        [classes.pulsating]: isPulsating,
        [classes.exiting]: isExiting,
      })}
      {...rest}
    />
  );
});

export default TouchRipple;
