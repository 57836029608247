import { ANALYSIS_ROUTE, MONITOR_ROUTE, LIBRARY_FMV_ROUTE } from 'common/constants/urls';

const getViewPropsFromURL = url => {
  const isHistorical = url?.includes(ANALYSIS_ROUTE);
  const isMonitor = url?.includes(MONITOR_ROUTE);
  const isLibraryOpen = url?.includes(LIBRARY_FMV_ROUTE);
  return {
    isLibraryPopup: isHistorical || isMonitor,
    isHistorical,
    isMonitor,
    isLibraryOpen,
  };
};

export default getViewPropsFromURL;
