import store from 'redux/store';
import { selectCameraModels } from 'common/redux/models/selectors';
import buildIdentityRows from './buildIdentityRows';

const buildObjectFromIdString = idString => {
  if (!idString) return {};
  return idString.split(',').reduce((acc, vid) => {
    acc[vid] = true;
    return acc;
  }, {});
};

const buildSearchQuery = ({
  q = '',
  c = '',
  v = '',
  folders = '',
  et,
  st,
  zones,
  cameraZones,
  minLingerMillis,
  geofence,
}) => {
  /* if query does not exist or BOTH cameras and videos do not exist, searchQuery is invalid */
  const noVideoSource = !c && !v && !folders;
  const invalidQuery = !q || noVideoSource;
  if (invalidQuery) {
    return null;
  }

  const identityRows = buildIdentityRows(q);
  const cameras = buildObjectFromIdString(c);
  const datasources = buildObjectFromIdString(v);
  const folderDatas = buildObjectFromIdString(folders);
  let queryZones = zones;
  const cameraZoneIds = cameraZones ? Object.keys(cameraZones) : [];

  if (cameraZoneIds.length > 0) {
    const camerasInfo = selectCameraModels(store.getState());
    const zonesDict = {};
    // build a hash map of available zones
    // then use those to get the points of the selected zones in the plan
    Object.keys(camerasInfo).forEach(cameraId => {
      const { zones: zonesInfo } = camerasInfo[cameraId];
      zonesInfo.forEach(zone => {
        zonesDict[zone.id] = zone;
      });
    });
    const tempZonesArray = [];
    cameraZoneIds.forEach(cameraZoneId => {
      const currentZone = zonesDict[cameraZoneId];
      tempZonesArray.push(currentZone);
    });
    queryZones = tempZonesArray.map(tempZone =>
      tempZone.polygon.zone.map(({ point: { x, y } }) => [x, y])
    );
  }

  const searchQuery = {
    cameras,
    datasources,
    folders: folderDatas,
    identityRows,
    query: q,
    cameraIds: c,
    datasourceIds: v,
    folderIds: folders,
    filters: {
      startDate: st ? Number(st) : null,
      endDate: et ? Number(et) : null,
    },
    zones: queryZones,
    minLingerMillis,
    geofence,
  };

  return searchQuery;
};

export default buildSearchQuery;
