import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Tooltip from 'common/components/base/Tooltip';
import IconButton from 'common/components/base/IconButton';

import { REGENT_GREY, SECONDARY, ACCENT } from 'common/constants/colors';

export const SIDEBAR_BUTTON_HEIGHT = 38;

const styles = {
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: SIDEBAR_BUTTON_HEIGHT,
    width: SIDEBAR_BUTTON_HEIGHT,
    cursor: 'pointer',
    padding: 0,
  },
  disabled: {
    cursor: 'not-allowed',
  },
  selected: {
    background: ACCENT,

    '&:hover': {
      background: ACCENT,
    },
  },
};

const SidebarButton = ({
  children,
  classes,
  className,
  iconClassName,
  disabled,
  isSelected,
  onClick,
  tooltip,
  ...rest
}) => {
  const color = isSelected ? SECONDARY : REGENT_GREY;
  return (
    <Tooltip showTooltip={Boolean(tooltip)} title={tooltip} placement="left">
      <div className={className}>
        <IconButton
          className={classnames(iconClassName, {
            [classes.icon]: true,
            [classes.selected]: isSelected,
            [classes.disabled]: disabled,
          })}
          onClick={onClick}
          role="button"
          tabIndex="0"
          disabled={disabled}
          {...rest}
        >
          {children({ style: { color } })}
        </IconButton>
      </div>
    </Tooltip>
  );
};

SidebarButton.defaultProps = {
  tooltip: '',
};

export default injectSheet(styles)(SidebarButton);
