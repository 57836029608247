import { createUseStyles } from 'react-jss';
import { DARK_BORDER, REGENT_GREY, LIGHT_VIOLET } from 'common/constants/colors';

const useStyles = createUseStyles(theme => ({
  table: {
    borderCollapse: 'separate',
  },
  body: {
    position: 'relative',
    height: theme.spacing(8),
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1,
    background: 'white',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    minHeight: theme.spacing(5),
    padding: theme.spacing(0, 2), // Needed to properly align headers with cell data when using useFlexLayout
  },
  stripedTable: {
    /* We need to stripe the odds on every 4th row because we have a second row rendered
       on the DOM for row expansion */
    '& > tr:nth-child(4n+1)': {
      backgroundColor: LIGHT_VIOLET,
    },
  },
  noBorder: {
    borderBottom: 'unset',
  },
  expandCell: {
    width: theme.spacing(5),
    minWidth: theme.spacing(5),
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerCell: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(4),
    color: REGENT_GREY,
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  sortArrow: {
    marginLeft: theme.spacing(1),
  },
  extraPadding: {
    '& > *:first-child': {
      paddingLeft: theme.spacing(4),
    },
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    zIndex: 1,
    background: 'white',

    // Borders don't work normally when using sticky positioning
    '&::before': {
      content: '""',
      dispaly: 'block',
      width: '100%',
      position: 'absolute',
      top: -1,
      height: 1,
      background: DARK_BORDER,
    },
  },
  emptyRow: {
    height: theme.spacing(8),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export default useStyles;
