import axios from 'axios.js';

import { ANALYTICS_BACKEND, PROC_JOBS } from 'common/constants/urls';

export function getProcessingData(params) {
  return axios.get(`${ANALYTICS_BACKEND}${PROC_JOBS}/`, { params }).then(({ data }) => data);
}

export function cancelJob(id) {
  /**
   * @param {object} body e.g. { jobGroupId: <string> }
   */
  return axios.post(`${ANALYTICS_BACKEND}${PROC_JOBS}/${id}/cancel/`).then(({ data }) => data);
}

export function cancelJobGroup(body) {
  /**
   * @param {object} body e.g. { jobGroupId: <string> }
   */
  return axios
    .post(`${ANALYTICS_BACKEND}${PROC_JOBS}/cancellation-request/`, body)
    .then(({ data }) => data);
}

export function reprioritizeJob(body) {
  /**
   * @param {object} body e.g. { job_id: <string>, HIGHEST_PRIORITY or LOWEST_PRIORITY : <string> }
   */
  return axios
    .post(`${ANALYTICS_BACKEND}${PROC_JOBS}/reprioritize/`, body)
    .then(({ data }) => data);
}

export function resumeJob(id) {
  /**
   * @param {object} body e.g. { job_id: <string>, HIGHEST_PRIORITY or LOWEST_PRIORITY : <string> }
   */
  return axios.post(`${ANALYTICS_BACKEND}${PROC_JOBS}/${id}/resume/`, '').then(({ data }) => data);
}
