export function sortByUpdated(a, b) {
  return new Date(b.updated).getTime() - new Date(a.updated).getTime();
}

export function exceedsLimitErrorMessage(type, length) {
  return `${type} exceeds ${length} character limit`;
}

export function isLengthValid(typeMaxLength, length) {
  return length <= typeMaxLength;
}
