import { makeStyles } from 'tss-react-local';

const useStyles = makeStyles()((theme, { level }) => ({
  container: {
    boxShadow: theme.shadows[level],
    padding: theme.spacing(0.75),
    marginTop: theme.spacing(3),
  },
}));

const range = Array(24)
  .fill()
  .map((_, i) => i);

const ShadowInstance = ({ level }) => {
  const { classes } = useStyles({ level });

  return <div className={classes.container}>{`theme.shadows[${level}]`}</div>;
};

const ShadowsExample = () => range.map(level => <ShadowInstance key={level} level={level} />);

export default ShadowsExample;
