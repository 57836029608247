import React from 'react';
import Tabs from 'common/components/base/TabsOld';
import Icon from 'common/components/base/Icon';
import { withStyles } from 'tss-react-local';
import { PRIMARY } from 'common/constants/colors';
import { Example } from '../helpers';

const styles = {
  main: {
    '& button': {
      marginRight: 8,
    },
  },
  flexContainer: {
    zIndex: 5,
    position: 'relative',
    color: PRIMARY,
  },
  tabs: {
    height: '100%',
    minHeight: 'unset',
  },
};

const tabs1 = [
  {
    label: 'FIRST TAB',
    value: 1,
  },
  {
    label: 'SECOND TAB',
    value: 2,
  },
];

const tabs2 = [
  {
    icon: <Icon iconName="map" />,
    value: 1,
  },
  {
    icon: <Icon iconName="camera" />,
    value: 2,
  },
];

const tabs3 = [
  {
    label: 'FIRST TAB FIRST TAB FIRST TAB',
    value: 1,
  },
  {
    label: 'SECOND TAB SECOND TAB SECOND TAB',
    value: 2,
  },
];

const tabs4 = [
  {
    icon: <Icon iconName="map" />,
    value: 1,
  },
  {
    icon: <Icon iconName="camera" />,
    value: 2,
  },
];

class TabsExample extends React.Component {
  state = { tabs1: 1, tabs2: 1, tabs3: 1, tabs4: 1 };

  onChange = prop => (e, value) => this.setState({ [prop]: value });

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <Example label="Text Tabs">
          <div>
            <Tabs onChange={this.onChange('tabs1')} value={this.state.tabs1} tabs={tabs1} />
          </div>
          <div>
            <Tabs onChange={this.onChange('tabs3')} value={this.state.tabs3} tabs={tabs3} />
          </div>
        </Example>
        <Example label="Icon Tabs">
          <Tabs
            useBackgroundSelector
            onChange={this.onChange('tabs2')}
            value={this.state.tabs2}
            tabs={tabs2}
          />
        </Example>
        <Example label="Vertical Tabs">
          <div style={{ width: 320 }}>
            <Tabs
              orientation="vertical"
              useBackgroundSelector
              onChange={this.onChange('tabs4')}
              value={this.state.tabs4}
              tabs={tabs4}
            />
          </div>
          <div style={{ width: 64 }}>
            <Tabs
              orientation="vertical"
              onChange={this.onChange('tabs4')}
              value={this.state.tabs4}
              tabs={tabs4}
            />
          </div>
        </Example>
      </div>
    );
  }
}

export default withStyles(TabsExample, styles);
