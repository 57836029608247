import { capitalize, compact } from 'lodash';

import store from 'redux/store';
import { OPERATORS } from 'common/constants/operators';
import { UNKNOWN_PERSON } from 'common/constants/app';
import { isNameObject } from 'common/helpers/objectUtils';

import getParsedQuery from './getParsedQuery';

function getNames(idString, models) {
  const ids = idString ? idString.split(',') : [];
  return compact(ids.map(id => models[id] && (models[id].name || models[id].title)));
}

export function formatQueryDatasources(
  query,
  { dsModels = {}, folderModels = {}, cameraModels = {} }
) {
  const dsNames = getNames(query.v, dsModels);
  const cameraNames = getNames(query.c, cameraModels);
  const folderNames = getNames(query.folders, folderModels);

  return cameraNames.concat(dsNames).concat(folderNames).join(', ');
}

export function identityRowsHasMultipleRows(identityRows) {
  return (
    identityRows.length &&
    identityRows.reduce((acc, { identities }) => (identities.length ? acc + 1 : acc), 0) > 1
  );
}

function itemExistsInPersonModels(item, personModels) {
  return Object.keys(personModels).find(key => personModels[key].name === item);
}

function buildDisplayQuery(groups) {
  let displayQuery = '';

  groups.forEach((group, i) => {
    if (groups.length > 1) {
      let groupOperator = null;

      if (i !== groups.length - 1) {
        // The last item in the group is the group operator
        // if it is not the last group
        groupOperator = group.pop();
      }

      displayQuery += '(';
      displayQuery += group.join(' ');
      displayQuery += ')';

      if (groupOperator) displayQuery += ` ${groupOperator} `;
    } else {
      displayQuery += group.join(' ');
    }
  });

  return displayQuery;
}

export function queryToVerbalSyntax(query, hideOrOperator, outputArray = false) {
  const { person: personModels } = store.getState().common.models;
  const parsedQuery = getParsedQuery(query);
  let verbalSyntaxGroups = parsedQuery.map(group =>
    group.map(item => {
      if (personModels[item]) return personModels[item].name;
      if (itemExistsInPersonModels(item, personModels)) return item;
      if (OPERATORS[item]) return OPERATORS[item].toUpperCase();
      if (!isNameObject(item)) return UNKNOWN_PERSON;

      item = item.replace(/:/g, '');
      return capitalize(item);
    })
  );
  if (outputArray) {
    if (hideOrOperator) {
      verbalSyntaxGroups = verbalSyntaxGroups.map(obj => (obj === 'OR' ? ', ' : obj));
    }

    return verbalSyntaxGroups;
  }
  let displayQuery = buildDisplayQuery(verbalSyntaxGroups);

  if (hideOrOperator) {
    displayQuery = displayQuery.split(' OR ').join(', ');
  }

  return displayQuery;
}
