import * as turf from '@turf/turf';
import DateTime from 'dateTime';
import { get, isEmpty } from 'lodash';

import { parseToSameDayFormat } from 'common/helpers/dateUtils';

import { BBOX } from 'common/components/geomap/constants';

/*
  MIR-8681 --

  These two functions are used to prevent turf from crashing the application. Turf.js will throw
  when unexpected data comes in, or if it crashes due to an internal bug. Instead of having Mirage
  crash, we will return at least something that won't crash the application, at the cost of potentially
  showing incorrect information.

  Note that if turf crashes and we are forced to catch here, we should look at these errors as
  critical and we should figure out why it's happening as it could lead to very misleading information
  on the UI. Often times, the cause is bad data, but there seem to be a few internal turf bugs that
  may cause these issues as well.
*/
function _getIntersection(...args) {
  try {
    return turf.intersect(...args);
  } catch (err) {
    console.error(err);
    console.warn('Turf Intersection failed. Returning null.');
    return null;
  }
}

function _getUnion(...args) {
  try {
    return turf.union(...args);
  } catch (err) {
    console.error(err);
    console.warn('Turf Union failed. Returning first argument polygon.');
    return args[0];
  }
}

export function getBoundaryIntersection(boundA, boundB) {
  const intersection = _getIntersection(boundA, boundB);
  return intersection && intersection.geometry;
}

export function convertPointStringToCoords(point) {
  const splitOnParen = point.split('(')[1];

  if (!splitOnParen) return {};

  const stringCoords = splitOnParen.split(')')[0];

  if (!stringCoords) return {};

  const [lng, lat] = stringCoords.split(' ');

  return { lat: Number(lat), lng: Number(lng) };
}

export function GeoImageParams(id = null, start, end) {
  this.geofence = id;
  this.start = start ? DateTime.fromJSDateWithTZ(start).toISO() : null;
  this.end = end ? DateTime.fromJSDateWithTZ(end).toISO() : null;
}

export function getGeoTiffPolygon(tiffs) {
  const points = tiffs.reduce((acc, tiff) => {
    if (tiff.geometry && tiff.geometry.coordinates) {
      const data = tiff.geometry.coordinates[0].map(point => turf.point(point));
      return acc.concat(data);
    }

    return acc;
  }, []);

  const features = turf.featureCollection(points);
  return turf.envelope(features);
}

export function getBoundsAroundGeoTiffs(tiffs) {
  return getBoundsFromPolygon(getGeoTiffPolygon(tiffs));
}

/* Checks whether a geofence area is covered by the geotiffs */
export function getGeofenceCoverageInfo(polygon, geotiffs) {
  if (!polygon || !geotiffs) return false;

  /* Just in case we get badly formed polygons -- badly formed polygons crash turf.js */
  if (doesPolygonIntersectItself(polygon)) return false;
  const geometries = geotiffs.map(t => t.geometry);

  const coveredArea = geometries.reduce((acc, geometry) => {
    const intersection = _getIntersection(polygon.geometry, geometry);

    if (!acc) {
      acc = intersection;
    } else if (intersection) {
      /* Take the union of the intersections inside the geofence */
      acc = _getUnion(acc, intersection);
    }

    return acc;
  }, false);

  const coverage = coveredArea ? turf.area(coveredArea) : 0;
  const geofenceArea = turf.area(polygon);
  /*
    Check if the area of the intersection is the same as the geofence, allowing
    for a 0.5% buffer. I.e. if there is at least 99.5% coverage the geofence is
    considered to be fully covered
  */
  const hasFullCoverage = coverage && coverage >= geofenceArea * 0.995;

  return {
    hasFullCoverage,
    coverage,
  };
}

export function doesPolygonIntersectItself(polygon) {
  if (polygon && polygon.geometry && polygon.geometry.coordinates) {
    const kinks = turf.kinks(polygon);
    return !!kinks.features.length;
  }
  return false;
}

/*
  When rendering a lot of geo tiffs, we should not try to render multiple tiffs that are overlapping,
  instead we will render the first tile in that 'stack' of geo tiffs
*/
export function getGeoTiffStacks(tiffs) {
  return tiffs.reduce((acc, tiff) => {
    const overlaps = acc.some(t => {
      const intersection = _getIntersection(t.geometry, tiff.geometry);

      /*
        In general, the analysts will probably select tiles that don't overlap much at all,
        but to be safe, we will assume anything with more than 50% of the same bounds is a stack
      */
      return intersection && turf.area(intersection) > turf.area(tiff.geometry) * 0.5;
    });

    if (!overlaps || !acc.length) acc.push(tiff);
    return acc;
  }, []);
}

/* We will need to update this to work properly later down the road. This is greedy and not very accurate */
export function getGeoTiffAreaBounds(tiffs) {
  const geometries = tiffs.map(t => t.geometry);
  const polygons = geometries.reduce((acc, geometry) => {
    try {
      const idx = acc.findIndex(t => _getIntersection(t, geometry));
      const overlappingPolygon = acc[idx];

      if (overlappingPolygon) {
        const poly1 = turf.polygon(overlappingPolygon.coordinates);
        const poly2 = turf.polygon(geometry.coordinates);
        const polygon = _getUnion(poly1, poly2);

        // It is possible for _getUnion to return a "MultiPolygon", where
        // poly1 and poly2 have two non-contigous intersections
        if (polygon.geometry && polygon.geometry.type === 'Polygon') {
          acc.splice(idx, 1, polygon.geometry);
        }
      } else if (!overlappingPolygon || !acc.length) {
        acc.push(geometry);
      }
    } catch (err) {
      // NOOP
    }

    return acc;
  }, []);

  /* return bounds and swap lat lngs */
  return polygons.map(p => p.coordinates[0].map(coords => coords.slice().reverse()));
}

/* Right now our tiffs act as a polygon, but we're making this function for clarity */
export function getGeoTiffCenter(tiff) {
  return getPolygonCenter(tiff);
}

export function getPolygonCenterLatLng(bounds) {
  const { lat, lng } = getPolygonCenter(bounds);
  return [lat, lng];
}

export function getGeoTiffBounds(tiff) {
  // Currently, the bounds for the geoImages are represented as [lng, lat],
  // the bounds for Leaflet are required in a [lat, lng] format, so this code
  // swaps the two
  return tiff.geometry.coordinates[0].map(point => point.slice().reverse());
}

export function getPolygonBounds(polygon) {
  if (polygon && polygon.geometry && polygon.geometry.coordinates) {
    return polygon.geometry.coordinates[0];
  }
  return [];
}

export function getPolygonCenter(polygon) {
  const point = turf.center(polygon.geometry);
  if (point) {
    const [lat, lng] = point.geometry.coordinates.slice().reverse();
    return { lat, lng };
  }
  return {};
}

export function getLabeledGeoJSONFromResult(result, properties, { visualization = BBOX } = {}) {
  const geometry = visualization === BBOX ? result.polygon : new GeoJSON(result.centroid);
  return getGeoJSON(geometry, {
    imageId: result.image,
    objectType: result.objectClass,
    ...properties,
  });
}

export function getGeoJSON(polygon, properties) {
  return {
    ...polygon,
    properties,
  };
}

export function getLatLongFromGeoJSON(geoJSON) {
  const box = turf.bbox(geoJSON);
  return {
    southWestLng: box[0],
    southWestLat: box[1],
    northEastLng: box[2],
    northEastLat: box[3],
  };
}

export function isValidLatLngCoordinates(coords) {
  return coords.northEastLat && coords.northEastLng && coords.southWestLat && coords.southWestLng;
}

export function buildGeoJSONFromLatLong(coords) {
  const { northEastLat, northEastLng, southWestLat, southWestLng } = coords;

  const geoJSON = new GeoJSON();

  geoJSON.geometry.coordinates.push([
    [southWestLng, northEastLat], // Northwest corner
    [southWestLng, southWestLat], // Southwest corner
    [northEastLng, southWestLat], // Southeast corner
    [northEastLng, northEastLat], // Northeast corner
    [southWestLng, northEastLat], // Northwest corner repeated
  ]);

  return geoJSON;
}

export function buildGeoJSONFromBounds(bounds) {
  const geoJSON = new GeoJSON();

  geoJSON.geometry.coordinates.push(bounds);

  return geoJSON;
}

export function buildLatLngForLeaflet(coords) {
  const { northEastLat, northEastLng, southWestLat, southWestLng } = coords;

  if (!northEastLat || !northEastLng || !southWestLat || !southWestLng) {
    return null;
  }

  return [
    [northEastLat, southWestLng], // Northwest corner
    [southWestLat, southWestLng], // Southwest corner
    [southWestLat, northEastLng], // Southeast corner
    [northEastLat, northEastLng], // Northeast corner
    [northEastLat, southWestLng], // Northwest corner repeated
  ];
}

export function GeoJSON(geometry) {
  this.geometry = geometry || {
    coordinates: [],
    type: 'Polygon',
  };

  this.properties = {};
  this.type = 'Feature';
}

export function getBoundsFromMultiPolygon(multiPolygon) {
  return multiPolygon.geometry?.coordinates?.map(x =>
    x[0]?.map(coords => coords.slice().reverse())
  );
}

export function getBoundsFromPolygon(polygon) {
  /*
    We have to flip from lng/lat to lat/lng because Leaflet uses the more
    widely-accepted lat/lng format, but the GeoJSON standard is lng/lat
  */
  return get(polygon, 'geometry.coordinates', [[]])[0].map(coords => coords.slice().reverse());
}

export function isBoxWithinImage(box, image, options) {
  const bounds = get(image, 'geometry.coordinates');
  return isBoxWithinBounds(box, bounds, options);
}

export function isBoxWithinGeofence(box, geofence, options) {
  const bounds = get(geofence, 'polygon.geometry.coordinates');
  return isBoxWithinBounds(box, bounds, options);
}

export function isBoxWithinBounds(box, bounds, { strictBoundaries = false } = {}) {
  try {
    const poly = turf.polygon(bounds);
    const points = box.geometry.coordinates[0].map(coords => turf.point(coords));
    return strictBoundaries
      ? points.every(point => turf.booleanContains(poly, point))
      : points.some(point => turf.booleanContains(poly, point));
  } catch (err) {
    console.error(err);
    return false;
  }
}

export function isPointWithinBounds(latLng, bounds) {
  const poly = turf.polygon([bounds]);
  const point = turf.point(latLng);
  return turf.booleanContains(poly, point);
}

/* returns true if geofence is fully or any portion of it is inside the viewport, also true */
/* if entire viewport is inside the geofence */
export function isGeofenceVisibleInViewport(geofence = {}, viewportBounds = []) {
  if (isEmpty(geofence) || !viewportBounds.length) return false;
  const poly1 = get(geofence, 'polygon', {});
  const poly2 = turf.polygon([viewportBounds]);
  return (
    turf.booleanContains(poly1, poly2) ||
    turf.booleanWithin(poly1, poly2) ||
    turf.booleanOverlap(poly1, poly2)
  );
}

/* returns true only if the given polygon is inside the viewport, if any portion of geofence is outside then returns false */
export function isPolygonWithinBounds(polygon = {}, bounds = []) {
  const polygonBounds = getBoundsFromPolygon(polygon);
  if (isEmpty(polygonBounds) || isEmpty(bounds)) return false;
  const poly1 = turf.polygon([polygonBounds]);
  const poly2 = turf.polygon([bounds]);
  return turf.booleanContains(poly2, poly1);
}

/* returns true if the entire viewport is covered by geofence */
export function isViewportWithinGeofence(geofence = {}, viewportBounds = []) {
  if (isEmpty(geofence) || !viewportBounds.length) return false;

  const poly1 = get(geofence, 'polygon', {});
  const poly2 = turf.polygon([viewportBounds]);

  return turf.booleanContains(poly1, poly2);
}

/* returns true if a portion of geofence is inside the viewport */
export function doesGeofenceOverlapsViewport(geofence = {}, viewportBounds = []) {
  if (isEmpty(geofence) || !viewportBounds.length) return false;

  const poly1 = get(geofence, 'polygon', {});
  const poly2 = turf.polygon([viewportBounds]);

  return turf.booleanOverlap(poly1, poly2);
}

export function isPointWithinImage(coords, image) {
  /**
    @param coords = Object { lat: num, lng: num } or Array [lng, lat]
  */
  if (!Array.isArray(coords)) {
    coords = [coords.lng, coords.lat];
  }

  const imagePoly = turf.polygon(image.geometry.coordinates);
  const point = turf.point(coords);

  return turf.booleanContains(imagePoly, point);
}

export function hasResultsToRenderUpdated(prevResults, currResults) {
  if (prevResults.length !== currResults.length) {
    return true;
  }

  return Object.keys(currResults).some(key => !prevResults[key]);
}

export function getGeoDisplayName(pathOrDataRoot) {
  /**
    @param pathOrDataRoot <String> Either image.path or dataset.dataRoot
  */
  return pathOrDataRoot?.split('/').pop();
}

export function parseGeoImageLocation(location) {
  if (typeof location === 'string') {
    try {
      location = JSON.parse(location);
    } catch (e) {
      // NOOP
    }
  }

  if (location) {
    const result = [];
    if (location.city) result.push(location.city);
    if (location.state) result.push(location.state);
    if (location.country) result.push(location.country);
    return result.join(', ');
  }

  return '';
}

export function getGeoSearchDateRange(filterDateStart, filterDateEnd) {
  if (!filterDateStart || !filterDateEnd) return null;

  return ` From ${parseToSameDayFormat(filterDateStart)} to ${parseToSameDayFormat(
    filterDateEnd
  )} `;
}

export function areImagesDuplicates(image, compImage) {
  if (image.datetime === compImage.datetime && image.datasetId === compImage.datasetId) {
    // If the datetime and dataset of each image are identical, compare the coordinates
    // of the images. If every coordinate is identical, only one of the
    // images should be rendered.
    return image.geometry.coordinates[0].every((coord, index) => {
      const compareLng = compImage.geometry.coordinates[0][index][0];
      const compareLat = compImage.geometry.coordinates[0][index][1];

      return coord[0] === compareLng && coord[1] === compareLat;
    });
  }

  return false;
}

export function buildTifUrl(result) {
  const { southWestLng, southWestLat, northEastLng, northEastLat } = getLatLongFromGeoJSON(
    result.polygon
  );

  return `/geothumbnail/geoimage?tifName=${result.path}&southWestLng=${southWestLng}&southWestLat=${southWestLat}&northEastLng=${northEastLng}&northEastLat=${northEastLat}`;
}
