import store from 'redux/store';
import isNumber from 'lodash/isNumber';

import buildSearchQuery from 'common/helpers/buildSearchQuery';
import {
  SEARCH_REQUEST_PENDING,
  SEARCH_REQUEST_PROCESSING,
  SEARCH_REQUEST_COMPLETE,
} from 'analysis/redux/constants';

export function getSearchRequestStatus(request) {
  return request && request.status && request.status.status;
}

export function getSearchRequestDetails(request) {
  return request && request.status ? request.status : {};
}

export function doesSearchRequestExist(request) {
  return request && request.status && isNumber(request.status.status);
}

export function hasSearchRequestLoaded(request) {
  return doesSearchRequestExist(request) && !isSearchRequestInProgress(request);
}

const PENDING_REQUESTS = [SEARCH_REQUEST_PENDING, SEARCH_REQUEST_PROCESSING];
export function isSearchRequestInProgress(request) {
  return PENDING_REQUESTS.includes(getSearchRequestStatus(request));
}

export function isSearchRequestInQueue(request) {
  return getSearchRequestStatus(request) === SEARCH_REQUEST_PENDING;
}

export function isSearchRequestComplete(request) {
  return getSearchRequestStatus(request) === SEARCH_REQUEST_COMPLETE;
}

export function getDatasourceNames(datasourceIds) {
  const dsModels = store.getState().common.models.datasource;

  return datasourceIds.map(vid => (dsModels[vid] ? dsModels[vid].title : '')).join(', ');
}

export function buildAnalysisSearchQuery(searchRequest) {
  const searchQuery = buildSearchQuery({
    q: searchRequest.query,
    ...searchRequest.filterArgs,
  });

  searchQuery.searchRequest = searchRequest;
  return searchQuery;
}

export function extractDatasourceIds(searchRequests) {
  return searchRequests.reduce((acc, searchRequest) => {
    const { filterArgs: { v = '' } = {} } = searchRequest;

    acc = acc.concat(v.split(',').filter(String));

    return acc;
  }, []);
}

export const frontendZones = backendZones =>
  (backendZones ?? []).map(zone => {
    const zoneCoordinates = zone.polygon ? zone.polygon : zone;
    return zoneCoordinates.zone.map(({ point: { x, y } }) => [x, y]);
  });

export const createLiveQueryZonesHash = liveQuery => {
  const liveQueryZones = {};
  liveQuery?.cameraZones?.forEach(id => (liveQueryZones[id] = true));
  return liveQueryZones;
};
