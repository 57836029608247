import axios from 'axios.js';

import { ANALYTICS_BACKEND, DATASOURCES, TRACKLET_STATS, PEOPLE } from 'common/constants/urls';

export function getTrackletGrid(requestId, id, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search-request/${requestId}/searchlight/${id}/`, {
      params,
    })
    .then(({ data }) => data);
}

export function postSIFTAction(requestId, body) {
  return axios
    .post(`${ANALYTICS_BACKEND}/search-request/${requestId}/actions/`, body)
    .then(({ data }) => data);
}

export function fetchConfirmedFaces(id, params) {
  return axios
    .get(`${ANALYTICS_BACKEND}${DATASOURCES}/${id}${PEOPLE}/`, { params })
    .then(({ data }) => data);
}

export function fetchTrackletStats(params) {
  return axios.get(`${ANALYTICS_BACKEND}${TRACKLET_STATS}/`, { params }).then(({ data }) => data);
}

export function fetchGroupedTracklets(params) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search-request/tracklet/`, { params })
    .then(({ data }) => data);
}

export function fetchSearchQueryDataSources(requestId) {
  return axios
    .get(`${ANALYTICS_BACKEND}/search-request/${requestId}/datasources/`)
    .then(({ data }) => data);
}
