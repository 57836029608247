import { SORT_BY_TIME, DEFAULT_CONFIDENCE_INTERVAL, FACES } from 'analysis/siftView/constants';

const defaults = {
  tracklets: [],
  pagination: {},
  isRelatedSightingsView: false,
  orderedTracklets: [],
  matchConfidence: DEFAULT_CONFIDENCE_INTERVAL,
  groupedMatchConfidence: DEFAULT_CONFIDENCE_INTERVAL,
  isSortedByTime: SORT_BY_TIME,
  selectedConfirmedPerson: null,
  selectedSiftDataSource: {},
  selectedTracklet: null,
  shouldClearTracklets: false,
  rightPanelOption: FACES,
  selectedTracklets: {},
  confirmedFaces: [],
  loadingConfirmedFaces: false,
  loadingAllSightings: false,
  loadingRelatedSightings: false,
  seekTime: null,
  shouldRefreshFrameDetections: false,
  selectedSiftDataSources: [],
  loadingDataSources: false,
  hoveredTrackletId: null,
  videoDuration: 0,
  isGroupedSightingsView: false,
  isGroupedSightingsLoadAll: false,
  unconfirmedChecked: true,
  confirmedChecked: true,
  groupedTracklets: {},
  filters: {
    startDate: null,
    endDate: null,
  },
  timelineBounds: {
    start: null,
    end: null,
  },
  groupedIdentityCreated: false,
  dataSourceDoesNotExist: false,
  searchRequest: {},
};

export default function siftViewReducer(state = defaults, action) {
  switch (action.type) {
    case 'SIFT_VIEW/SET_SEARCH_REQUEST':
      return {
        ...state,
        searchRequest: action.payload.searchRequest,
      };
    case 'SIFT_VIEW/RESET_TRACKLET_DATA': {
      return {
        ...state,
        tracklets: defaults.tracklets,
        pagination: defaults.pagination,
      };
    }
    case 'SIFT_VIEW/SET_TRACKLET_DATA': {
      return {
        ...state,
        tracklets: action.payload.tracklets,
        pagination: action.payload.pagination,
      };
    }
    case 'SIFT_VIEW/ADD_TRACKLETS': {
      const tracklets = action.payload.addToEnd
        ? [...state.tracklets, ...action.payload.tracklets]
        : [...action.payload.tracklets, ...state.tracklets];

      return { ...state, tracklets };
    }
    case 'SIFT_VIEW/UPDATE_TRACKLETS': {
      const updatesMap = action.payload.reduce((m, tracklet) => {
        m[tracklet.id] = tracklet;
        return m;
      }, {});

      const updatedTracklets = state.tracklets.map(row =>
        row.map(t => ({
          ...t,
          ...updatesMap[t.id],
        }))
      );
      const updatedOrderedTracklets = state.orderedTracklets.map(t => ({
        ...t,
        ...updatesMap[t.id],
      }));
      return {
        ...state,
        tracklets: updatedTracklets,
        orderedTracklets: updatedOrderedTracklets,
      };
    }

    case 'SIFT_VIEW/SET_IS_RELATED_SIGHTINGS_VIEW': {
      return {
        ...state,
        isRelatedSightingsView: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_ORDERED_TRACKLETS': {
      return {
        ...state,
        orderedTracklets: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_MATCH_CONFIDENCE': {
      return {
        ...state,
        matchConfidence: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_GROUPED_SIGHTINGS_MATCH_CONFIDENCE': {
      return {
        ...state,
        groupedMatchConfidence: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_IS_SORTED_BY_TIME': {
      return {
        ...state,
        isSortedByTime: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_SELECTED_CONFIRMED_PERSON': {
      return {
        ...state,
        selectedConfirmedPerson: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_SELECTED_HOVERED_PERSON': {
      return {
        ...state,
        selectedHoveredPerson: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_SELECTED_DATASOURCE': {
      return {
        ...state,
        selectedSiftDataSource: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_SELECTED_TRACKLET': {
      return {
        ...state,
        selectedTracklet: action.payload,
      };
    }

    case 'SIFT_VIEW/CLEAR_TRACKLET': {
      return {
        ...state,
        shouldClearTracklets: action.payload,
      };
    }

    case 'SIFT_VIEW/RIGHT_PANEL_OPTION': {
      return {
        ...state,
        rightPanelOption: action.payload,
      };
    }

    case 'SIFT_VIEW/ADD_GROUPED_TRACKLETS': {
      const next = action.payload.reduce(
        (acc, id) => ({
          ...acc,
          [id]: true,
        }),
        {}
      );

      return {
        ...state,
        groupedTracklets: { ...state.groupedTracklets, ...next },
      };
    }

    case 'SIFT_VIEW/ADD_SELECTED_TRACKLETS': {
      const next = action.payload.reduce(
        (acc, id) => ({
          ...acc,
          [id]: true,
        }),
        {}
      );

      return {
        ...state,
        selectedTracklets: { ...state.selectedTracklets, ...next },
      };
    }

    case 'SIFT_VIEW/REMOVE_SELECTED_TRACKLET': {
      return {
        ...state,
        selectedTracklets: Object.keys(state.selectedTracklets).reduce((acc, key) => {
          if (key !== action.payload.toString()) {
            acc[key] = state.selectedTracklets[key];
          }
          return acc;
        }, {}),
      };
    }

    case 'SIFT_VIEW/REMOVE_GROUPED_TRACKLET': {
      return {
        ...state,
        groupedTracklets: Object.keys(state.groupedTracklets).reduce((acc, key) => {
          if (key !== action.payload.toString()) {
            acc[key] = state.groupedTracklets[key];
          }
          return acc;
        }, {}),
      };
    }

    case 'SIFT_VIEW/REMOVE_ORDERED_TRACKLET': {
      return {
        ...state,
        orderedTracklets: state.orderedTracklets.filter(x => x.detectionId !== action.payload),
      };
    }

    case 'SIFT_VIEW/REMOVE_REJECTED_TRACKLETS': {
      const orderedTracklets = state.orderedTracklets.filter(
        x => !action.payload.tracklets.includes(x.detectionId)
      );
      return {
        ...state,
        orderedTracklets,
      };
    }

    case 'SIFT_VIEW/CLEAR_SELECTED_TRACKLETS': {
      return {
        ...state,
        selectedTracklets: defaults.selectedTracklets,
      };
    }

    case 'SIFT_VIEW/CONFIRMED_FACES': {
      return {
        ...state,
        confirmedFaces: action.payload,
      };
    }

    case 'SIFT_VIEW/LOADING_CONFIRMED_FACES': {
      return {
        ...state,
        loadingConfirmedFaces: action.payload,
      };
    }

    case 'SIFT_VIEW/LOADING_ALL_SIGHTINGS': {
      return {
        ...state,
        loadingAllSightings: action.payload,
      };
    }

    case 'SIFT_VIEW/LOADING_RELATED_SIGHTINGS': {
      return {
        ...state,
        loadingRelatedSightings: action.payload,
      };
    }

    case 'SIFT_VIEW/SEEK_TIME': {
      return {
        ...state,
        seekTime: action.payload,
      };
    }

    case 'SIFT_VIEW/RESET_SIFT': {
      return defaults;
    }

    case 'SIFT_VIEW/SET_REFRESH_FRAME_DETECTIONS': {
      return {
        ...state,
        shouldRefreshFrameDetections: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_SELECTED_DATASOURCES': {
      return {
        ...state,
        selectedSiftDataSources: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_LOADING_DATASOURCES': {
      return {
        ...state,
        loadingDataSources: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_SELECTED_HOVERED_TRACKLET_ID': {
      return {
        ...state,
        hoveredTrackletId: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_VIDEO_DURATION': {
      return {
        ...state,
        videoDuration: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_IS_GROUPED_SIGHTINGS_VIEW': {
      return {
        ...state,
        isGroupedSightingsView: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_UNCONFIRMED_CHECKED': {
      return {
        ...state,
        unconfirmedChecked: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_CONFIRMED_CHECKED': {
      return {
        ...state,
        confirmedChecked: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_IS_GROUPED_SIGHTINGS_LOAD_ALL_VIEW': {
      return {
        ...state,
        isGroupedSightingsLoadAll: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_FILTER_DATES': {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_TIMELINE_BOUNDS': {
      return {
        ...state,
        timelineBounds: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_GROUPED_IDENTITY_CREATED': {
      return {
        ...state,
        groupedIdentityCreated: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_DATA_SOURCE_DOES_NOT_EXIST': {
      return {
        ...state,
        dataSourceDoesNotExist: action.payload,
      };
    }

    case 'SIFT_VIEW/SET_EXIT_RELATED_SIGHTINGS': {
      return {
        ...state,
        selectedTracklets: defaults.selectedTracklets,
        selectedConfirmedPerson: defaults.selectedConfirmedPerson,
        isRelatedSightingsView: defaults.isRelatedSightingsView,
        isGroupedSightingsView: defaults.isGroupedSightingsView,
        isGroupedSightingsLoadAll: defaults.isGroupedSightingsLoadAll,
        groupedMatchConfidence: defaults.groupedMatchConfidence,
        matchConfidence: defaults.matchConfidence,
        groupedIdentityCreated: defaults.groupedIdentityCreated,
      };
    }

    default:
      return state;
  }
}
