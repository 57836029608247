import React from 'react';
import injectSheet from 'react-jss';

import Snackbar from '@material-ui/core/Snackbar';
import Zoom from '@material-ui/core/Zoom';

import { SECONDARY } from 'common/constants/colors';

const styles = {
  main: {
    right: 16,
    transition: 'bottom 0.5s',

    '& > div': {
      minWidth: 224,
    },
  },
  text: {
    boxShadow: 'none',
    fontSize: 11,
    color: SECONDARY,
    marginRight: 8,
    cursor: 'pointer',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      fontSize: 11,
      fontWeight: 300,
    },
  },
};

function zoomTransition(props) {
  return <Zoom {...props} />;
}

class ViewPlan extends React.Component {
  anchorOrigin = {
    horizontal: 'right',
    vertical: 'bottom',
  };

  _handleClose = (e, reason) => {
    if (reason !== 'clickaway') this.props.onClose(this.props.data);
  };

  _renderAction = notif => (
    <span
      tabIndex="0"
      role="button"
      className={this.props.classes.text}
      onClick={() => {
        this.props.onClose(notif);
        this.props.history.push('/plan');
      }}
    >
      VIEW PLAN
    </span>
  );

  _renderMessage = notif => (
    <div className={this.props.classes.message}>
      <span>{notif.message}</span>
    </div>
  );

  render() {
    const { style, data, open } = this.props;
    const action = this._renderAction(data);
    const message = this._renderMessage(data);

    return (
      <Snackbar
        style={style}
        action={action}
        anchorOrigin={this.anchorOrigin}
        autoHideDuration={5000}
        classes={{ root: this.props.classes.main }}
        message={message}
        onClose={this._handleClose}
        open={open}
        TransitionComponent={zoomTransition}
        transitionDuration={200}
      />
    );
  }
}

export default injectSheet(styles)(ViewPlan);
