import { getObjectAttributesFromName, isNameObject } from 'common/helpers/objectUtils';

export function normalizeVideoResolution(width, height) {
  const NORMAL_HEIGHT = 720;
  const heightScale = height / NORMAL_HEIGHT;
  const scaledWidth = width / heightScale;

  return { height: NORMAL_HEIGHT, width: scaledWidth };
}

export const getAttributesFromDetection = detection => {
  const name = detection.getName();
  const isObj = isNameObject(name);

  if (isObj) {
    const { name: _name, color } = getObjectAttributesFromName(name);

    return {
      name: _name,
      color,
      isObj,
    };
  }
  return {
    name,
    isObj,
  };
};

export function getCanvasMargins(canvasRect, scaleAdjustedRect) {
  const { top: topOfCanvas, left: leftOfCanvas } = canvasRect;
  const { top: topOfContent, left: leftOfContent } = scaleAdjustedRect;

  const marginLeft = leftOfContent - leftOfCanvas;
  const marginTop = topOfContent - topOfCanvas;

  return {
    marginLeft,
    marginTop,
  };
}

export const doesPointIntersectRect = (pointX, pointY, rectangle) =>
  pointX >= rectangle.x && pointX <= rectangle.x + rectangle.width && pointY >= rectangle.y;
