import { useState } from 'react';
import DateTime from 'dateTime';
import { Grid, Popover } from '@material-ui/core';

import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';
import DateInput from 'components/DateInput';
import DateTimeRangePicker from 'common/components/pickers/DateTimeRangePicker';

function NewPicker() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [anchorEl, setAnchorEl] = useState();

  const updateDate = newDate => {
    setStartDate(DateTime.fromJSDateWithTZ(newDate.startDate));
    setEndDate(DateTime.fromJSDateWithTZ(newDate.endDate));
  };

  const attemptSwitch = () => {
    if (!startDate || !endDate) return;
    if (startDate.diff(endDate).milliseconds > 0) {
      setStartDate(endDate);
      setEndDate(startDate);
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <IconButton
            onClick={event => {
              if (!startDate) setStartDate(DateTime.fromJSDateWithTZ());
              if (!endDate) setEndDate(DateTime.fromJSDateWithTZ());
              setAnchorEl(event.currentTarget);
            }}
          >
            <Icon iconName="date_range" />
          </IconButton>
        </Grid>
        <Grid item>
          <DateInput label="Start" onBlur={attemptSwitch} date={startDate} setDate={setStartDate} />
        </Grid>
        <Grid item style={{ padding: '0px 10px' }}>
          -
        </Grid>
        <Grid item>
          <DateInput label="End" onBlur={attemptSwitch} date={endDate} setDate={setEndDate} />
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(undefined)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <DateTimeRangePicker
          startDate={startDate?.isValid ? startDate : undefined}
          endDate={endDate?.isValid ? endDate : undefined}
          onChange={updateDate}
          onClose={() => setAnchorEl(undefined)}
        />
      </Popover>
    </>
  );
}

export default NewPicker;
