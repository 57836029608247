import React from 'react';

import SendError from 'app/notifications/SendError';
import LoggingError from 'app/notifications/LoggingError';
import Notifications from 'app/notifications/Notifications';
import MirageAlert from 'common/components/popups/dialogs/MirageAlert';

const AppPopups = () => (
  <>
    <MirageAlert />
    <SendError />
    <LoggingError />
    <Notifications />
  </>
);

export default AppPopups;
