import React from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';

import { NAVY_BLUE } from 'common/constants/colors';
import { getHexadecimalOpacity } from 'common/helpers/colorHelpers';
import Typography from 'components/Typography';
import Icon from 'common/components/base/Icon';
import MirageContent from 'common/components/base/MirageContent';
import MirageHeader from 'common/components/base/MirageHeader';
import * as SettingsSelectors from 'settings/redux/selectors';
import { useLogin } from './LoginContext';

const animationDuration = '0.3s';
const useStyles = createUseStyles(theme => ({
  wrapper: {
    bottom: 0,
    height: 'auto',
    left: 0,
    padding: 0,
    position: 'fixed',
    transform: ({ isOpen }) =>
      isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(0, calc(100% - 50px), 0)',
    transition: `transform ${animationDuration} ease-in-out`,
  },
  innerWrapper: {
    background: getHexadecimalOpacity(NAVY_BLUE, 0.7),
    color: 'white',
    margin: '0 auto',
    position: 'relative',
    width: '100%',
    padding: `0 ${theme.spacing(2.5)}px`,
  },
  content: {
    margin: '0 auto',
    maxWidth: theme.spacing(128),
    position: 'relative',
    width: '100%',
    maxHeight: theme.spacing(27),
    overflow: ({ isOpen }) => (isOpen ? 'auto' : 'hidden'),
  },
  details: {
    display: 'block',
    paddingTop: 0,
    fontSize: 11,
  },
  icon: {
    transition: `transform ${animationDuration}`,
    transform: ({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'),
  },
}));

const USGWarningAndConsent = ({ className }) => {
  const { isConsentBannerOpen: isOpen, toggleConsentBannerOpen } = useLogin();
  const content = useSelector(SettingsSelectors.selectConsentBannerContent);
  const classes = useStyles({ isOpen });
  return content ? (
    <MirageContent className={classnames(classes.wrapper, className)}>
      <div className={classes.innerWrapper}>
        <div className={classes.content}>
          <MirageHeader>
            <Typography variant="h6">USG Warning & Consent Banner</Typography>
            <ButtonBase onClick={toggleConsentBannerOpen} data-testid="WarningAndConsent-toggle">
              <Typography variant="link2">{isOpen ? 'Collapse' : 'Expand'}</Typography>
              <Icon iconName="chevron_right" className={classes.icon} />
            </ButtonBase>
          </MirageHeader>
          <MirageContent
            className={classes.details}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </MirageContent>
  ) : null;
};

export default USGWarningAndConsent;
