const USER_COLORS = [
  'blueviolet',
  'burlywood',
  'coral',
  'crimson',
  'darkblue',
  'darkcyan',
  'darkgreen',
  'darkmagenta',
  'darkorange',
  'deeppink',
  'dodgerblue',
  'firebrick',
  'goldenrod',
  'orangered',
  'plum',
  'rebeccapurple',
];

export const getConsistentUserColor = user =>
  USER_COLORS[parseInt(user.id, 10) % USER_COLORS.length];
