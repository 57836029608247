import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider, JssProvider } from 'react-jss';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import store from 'redux/store';
import history from 'history.js';
import localJss, { createGenerateClassName } from 'jss.js';

import themes from 'common/components/base/themes';

const muiCache = createCache({
  'key': 'mui',
  'prepend': true,
});

const AppProviders = ({ children }) => (
  <Provider store={store}>
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={themes.defaultTheme}>
        <MuiThemeProvider theme={themes.defaultTheme}>
          <JssProvider jss={localJss} generateClassName={createGenerateClassName()}>
            <Router history={history}>
              <DndProvider backend={HTML5Backend}>{children}</DndProvider>
            </Router>
          </JssProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </CacheProvider>
  </Provider>
);

export default AppProviders;
