import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { cancelProcessingJob } from 'library/redux/actions';

import ConfirmDialog from './ConfirmDialog';

/**
 API:
 @prop datasource (required) {Object}
 @prop open (required) {Boolean}
 */
class CancelJob extends PureComponent {
  cancelJob = () => {
    this.props.dispatchCancelProcessingJob({
      id: this.props.datasource.id,
    });
  };

  render() {
    const { datasource } = this.props;
    if (!datasource) return null;
    const title =
      datasource.dataset ||
      datasource.displayInfo?.videoFilename ||
      datasource.displayInfo?.uploadFolderNames;

    return (
      <ConfirmDialog
        dialogTitle={`Are you sure you want to cancel processing ${title}?`}
        dialogContent="Canceled datasources will not be added to Mirage Library and any progress will be lost."
        dialogAcceptFunc={this.cancelJob}
        acceptText="CANCEL PROCESSING"
        declineText="DO NOT CANCEL"
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = {
  dispatchCancelProcessingJob: cancelProcessingJob,
};

export default connect(null, mapDispatchToProps)(CancelJob);
