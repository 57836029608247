import { set, isEqual, cloneDeep } from 'lodash';

const fnSet = (obj, path, value) => set(cloneDeep(obj), path, value);

export const complete = (prevZones, zoneIndex) => {
  const currentZone = prevZones[zoneIndex];
  const firstPoint = currentZone[0];
  return fnSet(prevZones, [zoneIndex, currentZone.length], firstPoint);
};

export const areZonesComplete = zones =>
  zones.length > 0 &&
  zones.every(zone => {
    const first = zone[0];
    const last = zone[zone.length - 1];

    return zone.length > 1 && isEqual(first, last);
  });

export const update = (prevZones, [zoneIndex, pointIndex], point) =>
  fnSet(prevZones, [zoneIndex, pointIndex], point);

export const insert = (prevZones, [zoneIndex, pointIndex], point) => {
  const precedingZones = prevZones.slice(0, zoneIndex);
  const currentZone = prevZones[zoneIndex];
  const followingZones = prevZones.slice(zoneIndex + 1);

  const effectivePointIndex = pointIndex;
  const precedingPoints = currentZone.slice(0, effectivePointIndex);
  const followingPoints = currentZone.slice(effectivePointIndex);
  const newZone = precedingPoints.concat([point]).concat(followingPoints);

  return precedingZones.concat([newZone]).concat(followingZones);
};

export const remove = (prevZones, [zoneIndex, pointIndex], skipCompleteCheck = false) => {
  const precedingZones = prevZones.slice(0, zoneIndex);
  const currentZone = prevZones[zoneIndex];
  const followingZones = prevZones.slice(zoneIndex + 1);
  const isComplete = !skipCompleteCheck && areZonesComplete([currentZone]);

  let newZone;
  if (isComplete && currentZone.length < 4) {
    newZone = [];
  } else if (isComplete && (pointIndex === 0 || pointIndex === currentZone.length - 1)) {
    newZone = [[...currentZone.slice(1, currentZone.length - 1), currentZone[1]]];
  } else {
    const precedingPoints = currentZone.slice(0, pointIndex);
    const followingPoints = currentZone.slice(pointIndex + 1);

    newZone = [precedingPoints.concat(followingPoints)];
  }

  return precedingZones.concat(newZone).concat(followingZones);
};

export const add = (prevZones, zoneIndex, point) => {
  const precedingZones = prevZones.slice(0, zoneIndex);
  const currentZone = prevZones[zoneIndex] || [];
  const nextZone = currentZone.concat([point]);
  return precedingZones.concat([nextZone]);
};
