import { batchActions } from 'redux-batched-actions';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';

import { extractErrorMessage, extractMirageFormError } from 'common/helpers/apiErrorUtils';
import { renderError } from 'app/redux/actions';

import {
  DATASOURCE,
  IDENTITY,
  CAMERA,
  FOLDER,
  VIDEO,
  IMAGE,
  OBJECT,
  CAMERA_ZONE,
} from 'common/constants/app';
import { DEFAULT_OPERATOR } from 'common/constants/operators';
import { PLAN_ANALYSIS_KEY, PLAN_MONITOR_KEY } from 'plan/redux/reducer';

import { postGeofenceFilter } from 'common/api/libraryApi';
import * as PlanSelectors from 'plan/redux/selectors';
import * as CommonModelActions from 'common/redux/models/actions';
import { setConfidenceThreshold } from 'settings/redux/actions';
import buildIdentityRows from 'common/helpers/buildIdentityRows';

import { findMatchingIdentityInRow } from '../utils';

const ANALYSIS_SUFFIX = '_ANALYSIS';
export const MONITOR_SUFFIX = '_MONITOR';

const addCamerasToQuery = createAddCamerasToQuery(ANALYSIS_SUFFIX);
const addFoldersToQuery = createAddFoldersToQuery(ANALYSIS_SUFFIX);
const addCamerasToQueryMonitor = createAddCamerasToQuery(MONITOR_SUFFIX);
const addCameraZonesToQuery = createAddCameraZonesToQuery(ANALYSIS_SUFFIX);
const addCameraZonesToQueryMonitor = createAddCameraZonesToQuery(MONITOR_SUFFIX);
const addDatasourcesToQuery = createAddDatasourcesToQuery(ANALYSIS_SUFFIX);
const addDatasourcesToQueryMonitor = createAddDatasourcesToQuery(MONITOR_SUFFIX);
const addIdentitiesToQuery = createAddIdentitiesToQuery(ANALYSIS_SUFFIX);
const addIdentitiesToQueryMonitor = createAddIdentitiesToQuery(MONITOR_SUFFIX);

export const addIdentityRow = createAddIdentityRow(ANALYSIS_SUFFIX);
export const addIdentityRowMonitor = createAddIdentityRow(MONITOR_SUFFIX);
export const changeGroupRowOperator = createChangeGroupRowOperator(ANALYSIS_SUFFIX);
export const changeGroupRowOperatorMonitor = createChangeGroupRowOperator(MONITOR_SUFFIX);
export const clearCameras = createClearCameras(ANALYSIS_SUFFIX);
export const clearCamerasMonitor = createClearCameras(MONITOR_SUFFIX);
export const clearCameraZones = createClearCameraZones(ANALYSIS_SUFFIX);
export const clearCameraZonesMonitor = createClearCameraZones(MONITOR_SUFFIX);
export const clearDatasources = createClearDatasources(ANALYSIS_SUFFIX);
export const clearDatasourcesMonitor = createClearDatasources(MONITOR_SUFFIX);
export const clearIdentityRows = createClearIdentityRows(ANALYSIS_SUFFIX);
export const clearIdentityRowsMonitor = createClearIdentityRows(MONITOR_SUFFIX);
export const removeIdentityFromQuery = createRemoveIdentityFromQuery(ANALYSIS_SUFFIX);
export const removeIdentityFromQueryMonitor = createRemoveIdentityFromQuery(MONITOR_SUFFIX);
export const removeFromQuery = createRemoveFromQuery(ANALYSIS_SUFFIX);
export const removeFromQueryMonitor = createRemoveFromQuery(MONITOR_SUFFIX);
export const removeIdentityRow = createRemoveIdentityRow(ANALYSIS_SUFFIX);
export const removeIdentityRowMonitor = createRemoveIdentityRow(MONITOR_SUFFIX);
export const setDatasourcesSortIndex = createSetDatasourcesSortIndex(ANALYSIS_SUFFIX);
export const setDatasourcesSortIndexMonitor = createSetDatasourcesSortIndex(MONITOR_SUFFIX);
export const setIdentityRows = createSetIdentityRows(ANALYSIS_SUFFIX);
export const setIdentityRowsMonitor = createSetIdentityRows(MONITOR_SUFFIX);
export const setPlanFilterDates = createSetFilterDates(ANALYSIS_SUFFIX);
export const setPlanFilterDatesMonitor = createSetFilterDates(MONITOR_SUFFIX);
export const toggleIdentitySearchOperator = createToggleIdentitySearchOperator(ANALYSIS_SUFFIX);
export const toggleIdentitySearchOperatorMonitor = createToggleIdentitySearchOperator(
  MONITOR_SUFFIX
);
export const toggleQueryNameDialogMonitor = createToggleQueryNameDialog(MONITOR_SUFFIX);
export const updateObjectQueryColor = createUpdateObjectQueryColor(ANALYSIS_SUFFIX);
export const updateObjectQueryColorMonitor = createUpdateObjectQueryColor(MONITOR_SUFFIX);

export function addToSearchQuery({ queryType, query }) {
  return (dispatch, getState) => {
    const { confidenceThreshold } = getState().settings;

    // Reset confidence threshold to 5 on new search inputs
    if (confidenceThreshold < 5) dispatch(setConfidenceThreshold(5));

    switch (queryType) {
      case IDENTITY:
        return dispatch(addIdentitiesToQuery(query));
      case OBJECT:
        return dispatch(addIdentitiesToQuery(query));
      case DATASOURCE:
        return dispatch(addDatasourcesToQuery(query));
      case FOLDER:
        return dispatch(addFoldersToQuery(query));
      case CAMERA:
        return dispatch(addCamerasToQuery(query));
      case CAMERA_ZONE:
        return dispatch(addCameraZonesToQuery(query));
      default:
        return '';
    }
  };
}

export function addToSearchQueryMonitor({ queryType, query }) {
  return (dispatch, getState) => {
    const { confidenceThreshold } = getState().settings;

    // Reset confidence threshold to 5 on new search inputs
    if (confidenceThreshold < 5) dispatch(setConfidenceThreshold(5));

    switch (queryType) {
      case IDENTITY:
        return dispatch(addIdentitiesToQueryMonitor(query));
      case OBJECT:
        return dispatch(addIdentitiesToQueryMonitor(query));
      case DATASOURCE:
        return dispatch(addDatasourcesToQueryMonitor(query));
      case CAMERA:
        return dispatch(addCamerasToQueryMonitor(query));
      case CAMERA_ZONE:
        return dispatch(addCameraZonesToQueryMonitor(query));
      default:
        return '';
    }
  };
}

/* ========================= Shared Actions ========================== */
function createAddCamerasToQuery(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;
  const setCameraQuery = createSetCameraQuery(suffix);

  return query => (dispatch, getState) => {
    const { cameras } = getState()[planKey].searchQuery;
    const payload = { ...cameras };

    query.forEach(id => (payload[id] = true));

    return dispatch(setCameraQuery(payload));
  };
}

function createAddCameraZonesToQuery(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;
  const setCameraZonesQuery = createSetCameraZonesQuery(suffix);

  return query => (dispatch, getState) => {
    const { cameraZones } = getState()[planKey].searchQuery;
    const payload = { ...cameraZones };

    query.forEach(id => (payload[id] = true));
    return dispatch(setCameraZonesQuery(payload));
  };
}

function createAddDatasourcesToQuery(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;
  const setDatasourceQuery = createSetDatasourceQueryAndSyncFilterDates(suffix);

  return query => (dispatch, getState) => {
    const { datasources } = getState()[planKey].searchQuery;
    const payload = { ...datasources };

    query.forEach(id => (payload[id] = true));

    return dispatch(setDatasourceQuery(payload));
  };
}

function createAddFoldersToQuery(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;
  const setFolderQuery = createSetFolderQueryAndSyncFilterDates(suffix);

  return query => (dispatch, getState) => {
    const { folders } = getState()[planKey].searchQuery;
    const payload = { ...folders };

    query.forEach(id => (payload[id] = true));

    return dispatch(setFolderQuery(payload));
  };
}

function createAddIdentitiesToQuery(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;
  const setIdentityQuery = createSetIdentityRows(suffix);

  return ({ identities, row }) => (dispatch, getState) => {
    const { identityRows } = getState()[planKey].searchQuery;

    if (row === undefined) {
      row = identityRows.length > 0 ? identityRows.length - 1 : 0;
    }

    // Identities inside of the row have to be copied because they
    // are mutated - identities.push(...) - and the identityRows in both
    // plan and planMonitor hold the same reference to this array initially
    const payload = [
      ...identityRows.slice(0, row),
      {
        ...identityRows[row],
        identities: [...identityRows[row].identities],
      },
      ...identityRows.slice(row + 1),
    ];
    identities.forEach(identity => {
      let isDuplicate = false;

      identityRows.forEach(({ identities: rowIdentities }) => {
        isDuplicate = rowIdentities.some(
          ({ id, object: isObject }) =>
            id === identity.id && Boolean(isObject) === Boolean(identity.object)
        );
      });

      if (isDuplicate) return;

      payload[row].identities.push(identity);
    });

    return dispatch(setIdentityQuery(payload));
  };
}

function getPayload(queryType, searchQuery) {
  switch (queryType) {
    case DATASOURCE:
      return { ...searchQuery.datasources };
    case CAMERA:
      return { ...searchQuery.cameras };
    case CAMERA_ZONE:
      return { ...searchQuery.cameraZones };
    default:
      return { ...searchQuery.folders };
  }
}

function getAction(suffix, queryType, payload) {
  let action;
  const setDatasourceQuery = createSetDatasourceQueryAndSyncFilterDates(suffix);
  const setCameraQuery = createSetCameraQuery(suffix);
  const setFolderQuery = createSetFolderQueryAndSyncFilterDates(suffix);
  const setCameraZonesQuery = createSetCameraZonesQuery(suffix);

  switch (queryType) {
    case DATASOURCE:
      action = setDatasourceQuery(payload);
      break;
    case CAMERA:
      action = setCameraQuery(payload);
      break;
    case CAMERA_ZONE:
      action = setCameraZonesQuery(payload);
      break;
    default:
      action = setFolderQuery(payload);
      break;
  }

  return action;
}

function createRemoveFromQuery(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;
  const setIdentityQuery = createSetIdentityRows(suffix);

  return ({ queryType, query }) => (dispatch, getState) => {
    const { confidenceThreshold } = getState().settings;
    const { searchQuery } = getState()[planKey];

    // Reset confidence threshold to 5 on new search inputs
    if (confidenceThreshold < 5) dispatch(setConfidenceThreshold(5));

    if (queryType === IDENTITY) {
      const payload = cloneDeep(searchQuery.identityRows);
      for (let i = 0; i < query.length; i++) {
        for (let j = 0; j < payload.length; j++) {
          const idx = findMatchingIdentityInRow(payload[j], query[i]);
          if (isNumber(idx)) {
            payload[j].identities = [
              ...payload[j].identities.slice(0, idx),
              ...payload[j].identities.slice(idx + 1),
            ];
            break;
          }
        }
      }

      return dispatch(setIdentityQuery(payload));
    }

    const payload = getPayload(queryType, searchQuery);

    query.forEach(id => delete payload[id]);
    const action = getAction(suffix, queryType, payload);

    return dispatch(action);
  };
}

function createRemoveIdentityFromQuery(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;

  return ({ id, rowIndex, isObject }) => (dispatch, getState) => {
    const { searchQuery } = getState()[planKey];

    const identityRows = cloneDeep(searchQuery.identityRows);
    const identityIndex = findMatchingIdentityInRow(identityRows[rowIndex], id, isObject);

    // If only one identity remains in the row, remove entire row
    if (identityRows[rowIndex].identities.length === 1) {
      dispatch(createRemoveIdentityRow(suffix)(rowIndex));
      return;
    }

    if (isNumber(identityIndex)) {
      identityRows[rowIndex].identities = [
        ...identityRows[rowIndex].identities.slice(0, identityIndex),
        ...identityRows[rowIndex].identities.slice(identityIndex + 1),
      ];
    }

    return dispatch(createSetIdentityRows(suffix)(identityRows));
  };
}

export function createUpdateObjectQueryColor(suffix) {
  const planKey = suffix === ANALYSIS_SUFFIX ? PLAN_ANALYSIS_KEY : PLAN_MONITOR_KEY;

  return ({ color, itemIndex, rowIndex }) => (dispatch, getState) => {
    const { identityRows } = getState()[planKey].searchQuery;
    const dispatchUpdateIdentityRows =
      suffix === ANALYSIS_SUFFIX
        ? (...args) => dispatch(setIdentityRows(...args))
        : (...args) => dispatch(setIdentityRowsMonitor(...args));

    const newIdentityRows = [...identityRows];

    newIdentityRows[rowIndex] = {
      ...identityRows[rowIndex],
      identities: [...identityRows[rowIndex].identities],
    };

    newIdentityRows[rowIndex].identities[itemIndex] = {
      ...identityRows[rowIndex].identities[itemIndex],
      color,
    };

    dispatchUpdateIdentityRows(newIdentityRows);
  };
}

export const setZones = polygons => ({
  type: 'PLAN_ANALYSIS/SET_ZONES',
  payload: polygons,
});

export const clearZones = () => ({
  type: 'PLAN_ANALYSIS/CLEAR_ZONES',
});

export function setPlanView(type) {
  return {
    type: 'PLAN_ANALYSIS/SET_PLAN_VIEW',
    payload: type,
  };
}

export function setGeoFilterCoordinates(geoFilterCoordinates) {
  return {
    type: 'PLAN_ANALYSIS/SET_GEO_FILTER_COORDINATES',
    payload: geoFilterCoordinates,
  };
}

export function clearGeoFilterCoordinates() {
  return {
    type: 'PLAN_ANALYSIS/CLEAR_GEO_FILTER_COORDINATES',
  };
}

export function setDatasourcesInGeofence(datasourcesInGeofence) {
  return {
    type: 'PLAN_ANALYSIS/SET_DATASOURCES_IN_GEOFENCE',
    payload: datasourcesInGeofence,
  };
}

export function clearDatasourcesInGeofence() {
  return setDatasourcesInGeofence({});
}

export function createSetFilterDates(suffix) {
  return ({ startDate, endDate }) => ({
    type: `PLAN${suffix}/SET_PLAN_FILTER_DATES`,
    payload: { startDate, endDate },
  });
}

function createClearCameras(suffix) {
  return () => ({
    type: `PLAN${suffix}/CLEAR_CAMERAS`,
  });
}

function createClearCameraZones(suffix) {
  return () => ({
    type: `PLAN${suffix}/CLEAR_CAMERA_ZONES`,
  });
}

function createSetDatasourceQuery(suffix) {
  return payload => ({
    type: `PLAN${suffix}/SET_DATASOURCES_SEARCH_QUERY`,
    payload,
  });
}

function createSetCameraQuery(suffix) {
  return payload => ({
    type: `PLAN${suffix}/SET_CAMERAS_SEARCH_QUERY`,
    payload,
  });
}

function createSetCameraZonesQuery(suffix) {
  return payload => ({
    type: `PLAN${suffix}/SET_CAMERA_ZONES_SEARCH_QUERY`,
    payload,
  });
}

function createSetFolderQuery(suffix) {
  return payload => ({
    type: `PLAN${suffix}/SET_FOLDERS_SEARCH_QUERY`,
    payload,
  });
}

function createClearIdentityRows(suffix) {
  return () => ({
    type: `PLAN${suffix}/CLEAR_IDENTITY_ROWS`,
  });
}

function createSetIdentityRows(suffix) {
  return identityRows => ({
    type: `PLAN${suffix}/SET_IDENTITY_ROWS`,
    payload: identityRows,
  });
}

function createClearDatasources(suffix) {
  return () => ({
    type: `PLAN${suffix}/CLEAR_DATASOURCES`,
  });
}

function createAddIdentityRow(suffix) {
  return () => ({
    type: `PLAN${suffix}/ADD_IDENTITY_ROW`,
  });
}

function createRemoveIdentityRow(suffix) {
  return (row = null) => ({
    type: `PLAN${suffix}/REMOVE_IDENTITY_ROW`,
    row,
  });
}

function createChangeGroupRowOperator(suffix) {
  return ({ operator, row }) => ({
    type: `PLAN${suffix}/CHANGE_ROW_OPERATOR`,
    payload: { operator, row },
  });
}

function createToggleIdentitySearchOperator(suffix) {
  return (id, row) => ({
    type: `PLAN${suffix}/TOGGLE_IDENTITY_SEARCH_OPERATOR`,
    id,
    row,
  });
}

function createSetDatasourcesSortIndex(suffix) {
  return index => ({
    type: `PLAN${suffix}/SET_DATASOURCES_SORT_INDEX`,
    payload: index,
  });
}

function createToggleQueryNameDialog(suffix) {
  return isOpen => ({
    type: `PLAN${suffix}/TOGGLE_QUERY_NAME_DIALOG`,
    payload: { isOpen },
  });
}

const setIsLoadingPlanData = isLoading => ({
  type: 'PLAN_ANALYSIS/SET_IS_LOADING',
  payload: isLoading,
});

const setFolderLoading = payload => ({ type: 'PLAN_ANALYSIS/SET_FOLDER_LOADING', payload });

// TODO: move some of this work into the reducer itself
export function resetAnalysisPlanPage() {
  return batchActions([clearDatasources(), clearIdentityRows(), clearZones()]);
}

export function resetMonitorPlanPage() {
  return batchActions([
    clearDatasourcesMonitor(),
    clearIdentityRowsMonitor(),
    clearCamerasMonitor(),
    clearCameraZonesMonitor(),
  ]);
}

export function resetEntirePlanPage() {
  return batchActions([
    resetAnalysisPlanPage(),
    resetMonitorPlanPage(),
    clearDatasourcesInGeofence(),
  ]);
}

/* ========================= THUNK ACTION CREATORS ========================== */

export const editPlan = query => dispatch => {
  const { zones, minLingerMillis } = query;

  dispatch(resetAnalysisPlanPage());
  dispatch(setSearchQuery(query));
  dispatch(setZones({ zones, minLingerMillis }));
};

/**
 * This creates a thunk that sets datasources and syncs date filters
 *
 * @param {string} suffix
 */
export function createSetDatasourceQueryAndSyncFilterDates(suffix) {
  const setDatasourceQuery = createSetDatasourceQuery(suffix);

  return function setDatasourceQueryAndSyncFilterDates(payload) {
    return function setDatasourceQueryAndSyncFilterDatesThunk(dispatch) {
      dispatch(setDatasourceQuery(payload));
      dispatch(syncFilterDates(suffix));
    };
  };
}

/**
 * This creates a thunk sets folders and syncs date filters
 *
 * @param {string} suffix
 */
export function createSetFolderQueryAndSyncFilterDates(suffix) {
  const setFolderQuery = createSetFolderQuery(suffix);

  return function setFolderQueryAndSyncFilterDates(payload) {
    return function setFolderQueryAndSyncFilterDatesThunk(dispatch) {
      dispatch(setFolderQuery(payload));
      dispatch(syncFilterDates(suffix));
    };
  };
}

/**
 * This thunk syncs filter dates with datasources and folders selected
 *
 * @param {string} suffix
 */
export function syncFilterDates(suffix) {
  const setFilterDates = createSetFilterDates(suffix);

  return function syncFilterDatesThunk(dispatch, getState) {
    const dsModels = PlanSelectors.selectDatasourceModels(getState());
    const doAllDatasourcesHaveDate = dsModels.every(dsModel => !!dsModel.date);
    let [startDate, endDate] = [null, null];

    if (doAllDatasourcesHaveDate) {
      [startDate, endDate] = PlanSelectors.selectDefaultFilterDatetimeRange(getState());
    }

    dispatch(setFilterDates({ startDate, endDate }));
  };
}

// NOTE: setSearchQuery is setup to be used for Analysis Plan only
export function setSearchQuery(query) {
  return async (dispatch, getState) => {
    const { c = '', q = '', v = '', folders, t, st, et } = query;

    //  Fetch the various datasources so we can ensure they exist
    const dataRequests = [];
    if (v) {
      dataRequests.push(dispatch(CommonModelActions.fetchDatasourcesById(v.split(','))));
    }
    if (c) {
      dataRequests.push(dispatch(CommonModelActions.fetchCamerasById(c.split(','))));
    }
    if (folders) {
      dataRequests.push(dispatch(CommonModelActions.fetchFoldersV2(folders)));
    }

    if (dataRequests.length) {
      dispatch(setIsLoadingPlanData(true));

      try {
        await Promise.all(dataRequests);
      } catch (err) {
        renderError(extractErrorMessage(err));
      } finally {
        dispatch(setIsLoadingPlanData(false));
      }
    }

    //  We can now safely extract the sources that exist from the state
    const {
      camera: cameraModels,
      datasource: dsModels,
      folder_v2: folderModels,
    } = getState().common.models;

    // Remove cameras/datasources that have been deleted from Mirage
    const cameraKeys = c ? c.split(',').filter(key => !!cameraModels[key]) : [];
    const datasourceKeys = v ? v.split(',').filter(key => !!dsModels[key]) : [];
    const folderKeys = folders ? folders.split(',').filter(key => !!folderModels[key]) : [];

    if (cameraKeys.length) {
      dispatch(addCamerasToQuery(cameraKeys));
    }

    if (folderKeys.length) {
      dispatch(addFoldersToQuery(folderKeys));
    }

    if (datasourceKeys.length) {
      dispatch(
        addToSearchQuery({
          queryType: DATASOURCE,
          query: datasourceKeys,
        })
      );
    }

    const actions = [setIdentityRows(buildIdentityRows(q))];

    if (!Number.isNaN(Number(t))) {
      actions.push(setConfidenceThreshold(Number(t)));
    }

    if (st && et) {
      actions.push(
        setPlanFilterDates({
          startDate: Number(st),
          endDate: Number(et),
        })
      );
    }

    dispatch(batchActions(actions));
  };
}

export function removePersonsFromPlan(ids) {
  return dispatch => {
    // removeFromQuery is a thunk so cannot be batched
    dispatch(
      removeFromQuery({
        queryType: IDENTITY,
        query: ids,
      })
    );
    dispatch(
      removeFromQueryMonitor({
        queryType: IDENTITY,
        query: ids,
      })
    );
  };
}

export const fetchZoneableDatasourceForFolder = id => (dispatch, getState) => {
  const state = getState();
  const params = PlanSelectors.selectDateRangeParams(state);
  return dispatch(CommonModelActions.fetchZoneableDatasourceForFolder({ id, ...params }));
};

export const fetchPlanFolders = () => (dispatch, getState) => {
  const state = getState();
  const folderIds = PlanSelectors.selectFolderIds(state);
  const hasFoldersInPlan = PlanSelectors.selectFoldersCount(state);

  if (!hasFoldersInPlan) return;

  dispatch(setFolderLoading(true));
  return Promise.all(
    folderIds.map(id => dispatch(CommonModelActions.fetchSingleFolder(id)))
  ).finally(() => dispatch(setFolderLoading(false)));
};

function handlePlanPageError(err) {
  const { message } = extractMirageFormError(err);

  return renderError({ code: message ? undefined : 'Unknown Error', message, severity: 'error' });
}

export function doGeofenceFilter(geoJsonCoordinates) {
  return function doGeofenceFilterThunk(dispatch, getState) {
    const state = getState();
    const datasourceQuery = PlanSelectors.selectDatasourceQuery(state);
    const monitorQuery = PlanSelectors.selectMonitorQuery(state);

    const datasourceIds =
      datasourceQuery.datasourceIds.length > 0 ? datasourceQuery.datasourceIds.split(',') : [];
    const folderIds =
      datasourceQuery.folderIds.length > 0 ? datasourceQuery.folderIds.split(',') : [];
    const cameraIds =
      datasourceQuery.cameraIds.length > 0 ? datasourceQuery.cameraIds.split(',') : [];
    const cameraIdsFromMonitor =
      monitorQuery.cameraIds.length > 0 ? monitorQuery.cameraIds.split(',') : [];

    const body = {
      geofence: geoJsonCoordinates,
      datasources: datasourceIds,
      folders: folderIds,
      cameras: [...cameraIds, ...cameraIdsFromMonitor],
    };

    return postGeofenceFilter(body)
      .then(data => {
        dispatch(setDatasourcesInGeofence(data));
        return data;
      })
      .catch(handlePlanPageError);
  };
}

function getType(itemType) {
  const ITEM_TYPE_MAP = {
    [VIDEO]: DATASOURCE,
    [IMAGE]: DATASOURCE,
  };

  return ITEM_TYPE_MAP[itemType] || itemType;
}

function buildIdentityQuery(identityIds, identityRows) {
  return {
    identities: identityIds.map(id => ({ id, operator: DEFAULT_OPERATOR })),
    row: identityRows.length - 1,
  };
}

function buildObjectQuery(objectIds, identityRows) {
  return {
    identities: objectIds.map(id => ({ id, operator: DEFAULT_OPERATOR, object: true })),
    row: identityRows.length - 1,
  };
}

export function addToPlan({ isMonitor = false, selectedIds }) {
  return async function addToPlanThunk(dispatch, getState) {
    const state = getState();
    const addTo = isMonitor ? addToSearchQueryMonitor : addToSearchQuery;
    const identityRows = isMonitor
      ? PlanSelectors.selectMonitorIdentityRows(state)
      : PlanSelectors.selectIdentityRows(state);

    // The Plan page relies on common models to render its items. If we update the Plan page to
    // rely on Folder items, then we can remove these calls
    const dataRequests = [];

    if (selectedIds[VIDEO]) {
      dataRequests.push(dispatch(CommonModelActions.fetchDatasourcesById(selectedIds.video)));
    }

    if (selectedIds[CAMERA]) {
      dataRequests.push(dispatch(CommonModelActions.fetchCamerasById(selectedIds.camera)));
    }

    await Promise.all(dataRequests);

    Object.keys(selectedIds).forEach(itemType => {
      const ids = selectedIds[itemType];
      const type = getType(itemType);

      let query = ids;

      if (type === IDENTITY) query = buildIdentityQuery(ids, identityRows);
      if (type === OBJECT) query = buildObjectQuery(ids, identityRows);
      dispatch(addTo({ query, queryType: type }));
    });
  };
}
