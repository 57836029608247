/**
 * Pads an integer with zeros and returns a string
 *
 * @param value Integer value
 * @param length Target length of the string
 * @returns A string that is at least "length" long, padded with zeros if necessary
 */
export function zeroPad(value, length) {
  const n = Math.abs(value);
  const zeros = Math.max(0, length - Math.floor(n).toString().length);
  const power = 10 ** zeros;
  let zeroString = power.toString().substr(1);
  if (value < 0) {
    zeroString = `-${zeroString}`;
  }

  return zeroString + n;
}

/* These polyfills really should be somewhere else, we should move them into their own file soon */
Element.prototype.remove = function removeElement() {
  this.parentElement.removeChild(this);
};

NodeList.prototype.remove = HTMLCollection.prototype.remove;

HTMLCollection.prototype.remove = function removeNode() {
  for (let i = this.length - 1; i >= 0; i -= 1) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i]);
    }
  }
};

export function rotate(array) {
  if (array.length === 0) return [];
  array.push(array.shift());
  return array;
}

export function replaceAfter(idx, str, exp, replacement) {
  const subString = str.substring(idx);
  return str.substring(0, idx) + subString.replace(exp, replacement);
}

export function generateRandomKey() {
  const now = new Date().getTime();
  const randomNum = Math.round(Math.random() * 1000000);

  return String(now).concat(String(randomNum));
}

export function convertTimeStringToSeconds(inputString) {
  // Assumes 00:00:00.00 format
  const splitTime = inputString.split(':');
  const hours = Number(splitTime[0]);
  const minutes = Number(splitTime[1]);
  const seconds = Number(splitTime[2]);

  return hours * 3600 + minutes * 60 + seconds;
}

// converts array to dictionary where the model ids map to the model objects themselves
export function normalizeList(array, reducer) {
  if (!array) return;

  if (typeof reducer === 'function') {
    return array.reduce(reducer, {});
  }

  return array.reduce((acc, el) => {
    acc[el.id] = el;
    return acc;
  }, {});
}

// normalizes a single model
export function noramlizeSingleModel(data, { convertToArray } = {}) {
  // if id exists it is a single model
  if (data.id) {
    data = { [data.id]: data };
  }

  return convertToArray ? Object.keys(data) : data;
}

export function newIntegerArray(start, end) {
  const result = [];
  let i = start;

  while (i < end) {
    result.push(i);
    i += 1;
  }

  return result;
}

export function createCancelablePromise(func) {
  let _hasCanceled = false;

  function wrappedFunc(...args) {
    return new Promise((resolve, reject) =>
      func(...args)
        .then(val =>
          _hasCanceled ? reject(Object.assign(new Error(), { isCanceled: true })) : resolve(val)
        )
        .catch(error =>
          _hasCanceled ? reject(Object.assign(new Error(), { isCanceled: true })) : reject(error)
        )
    );
  }

  wrappedFunc.cancel = () => (_hasCanceled = true);

  return wrappedFunc;
}

export function isValidLat(value) {
  return value >= -90 && value <= 90;
}

export function isValidLng(value) {
  return value >= -180 && value <= 180;
}

export function createDurationString(timeString) {
  const splitTime = timeString.split(':');
  const hours = Number(splitTime[0]);
  const minutes = Number(splitTime[1]);
  const seconds = Number(splitTime[2]);

  if (hours > 0) {
    return `${hours} hrs`;
  }

  if (minutes > 0) {
    return `${minutes} min`;
  }

  if (seconds > 0) {
    return `${seconds} sec`;
  }

  return '';
}
