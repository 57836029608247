import React from 'react';

class Delay extends React.Component {
  static defaultProps = {
    delay: 1000,
  };

  state = {
    hidden: true,
  };

  componentDidMount() {
    this.timeout = setTimeout(this.showChildren, this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  showChildren = () => this.setState({ hidden: false });

  render() {
    return this.state.hidden ? null : this.props.children;
  }
}

export default Delay;
