import React from 'react';
import { useSelector } from 'react-redux';

import { selectPersonMimeTypes } from 'common/redux/formats/selectors';

import { createUseStyles } from 'react-jss';
import Dropzone from 'react-dropzone';
import { noop } from 'lodash';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Typography from 'components/Typography';
import Button from 'common/components/base/Button';
import { LIGHT_GREY } from 'common/constants/colors';

import Add from 'common/icons/ic-plus-white';

const useStyles = createUseStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    borderTop: `solid 0.5px ${LIGHT_GREY}`,
    padding: theme.spacing(3),
    boxSizing: 'border-box',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    height: theme.spacing(22),
  },
  addMore: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: 0,
    marginRight: theme.spacing(1),
  },
  addMoreButton: {
    height: '100%',
    borderRadius: 0,
    minWidth: theme.spacing(4),
    padding: 0,
  },
  images: {
    width: '100%',
    height: '100%',
    background: LIGHT_GREY,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
}));

const FormBody = React.forwardRef(
  (
    { addImages, children, isProcessing, toggleWebcam, imagesToAdd = [], isLoading = false },
    ref
  ) => {
    const acceptedTypes = useSelector(selectPersonMimeTypes);
    const canAddImages = !!addImages && !isProcessing && !imagesToAdd.length;
    const classes = useStyles({ canAddImages, isLoading });
    const renderMessage = () => {
      let message = '';
      if (isProcessing) message = 'Processing...';
      if (canAddImages) message = 'Add more images, or select an image to remove.';
      return <Typography variant="subtitle2">{message}</Typography>;
    };

    return (
      <div className={classes.main}>
        {renderMessage()}
        <div className={classes.body}>
          <div className={classes.addMore}>
            <Dropzone accept={acceptedTypes} onDrop={addImages || noop}>
              {({ getRootProps, getInputProps }) => (
                <Button
                  className={classes.addMoreButton}
                  disabled={isProcessing}
                  {...getRootProps()}
                  data-testid="UploadIdentity-dropzone"
                >
                  <input {...getInputProps()} disabled={isLoading} />
                  <Add selected />
                </Button>
              )}
            </Dropzone>
            <Button
              className={classes.addMoreButton}
              disabled={isProcessing}
              onClick={toggleWebcam}
              variant="outlined"
            >
              <PhotoCameraIcon />
            </Button>
          </div>
          <div ref={ref} className={classes.images}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export default FormBody;
