import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PROC_SVC, UPLOAD_FAILED, UPLOADING } from 'library/redux/constants';
import { cancelProcessingJobGroup, cancelUploadingJobGroup } from 'library/redux/actions';
import {
  selectCanceljobGroup,
  selectShowCancelJobGroup,
} from 'library/common/globalProcessingPanel/redux/selectors';
import { closeCancelJobGroup } from 'library/common/globalProcessingPanel/redux/actions';

import ConfirmDialog from './ConfirmDialog';

class CancelJobGroup extends PureComponent {
  isUploading = () => {
    const { dataSource } = this.props;
    return (
      dataSource.type === PROC_SVC &&
      (dataSource.status === UPLOADING || dataSource.status === UPLOAD_FAILED)
    );
  };

  getButtonText = () => {
    const buttonText = this.isUploading() ? 'UPLOADING' : 'PROCESSING';
    return `CANCEL ${buttonText}`;
  };

  getDialogContentText = () =>
    this.isUploading()
      ? 'Data sources will not be added to Mirage Library and any progress will be lost.'
      : 'Data sources that are in progress or in queue will not be added to Mirage Library and any progress will be lost.';

  getTitleText = () => {
    const { dataSource } = this.props;

    if (this.isUploading()) {
      return dataSource.title;
    }

    return dataSource.displayInfo.batchName || dataSource.displayInfo.uploadFolderNames;
  };

  getCancelConfirmTitle = () =>
    `Are you sure you want to ${this.getButtonText().toLowerCase()} ${this.getTitleText()} ?`;

  cancelJobGroup = () => {
    const { dataSource } = this.props;
    if (this.isUploading()) {
      return this.props.dispatchCancelUploadingJobGroup({
        id: dataSource.txID,
      });
    }

    return this.props.dispatchCancelProcessingJobGroup({
      batchId: dataSource.batchId,
    });
  };

  handleClose = () => {
    this.props.dispatchCloseCancelJobGroup();
  };

  render() {
    const { dataSource } = this.props;
    if (!dataSource) return null;

    return (
      <ConfirmDialog
        dialogTitle={this.getCancelConfirmTitle()}
        dialogContent={this.getDialogContentText()}
        dialogAcceptFunc={this.cancelJobGroup}
        acceptText={this.getButtonText()}
        handleClose={this.handleClose}
        declineText="DO NOT CANCEL"
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  dataSource: selectCanceljobGroup(state),
  open: selectShowCancelJobGroup(state),
});

const mapDispatchToProps = {
  dispatchCancelProcessingJobGroup: cancelProcessingJobGroup,
  dispatchCancelUploadingJobGroup: cancelUploadingJobGroup,
  dispatchCloseCancelJobGroup: closeCancelJobGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelJobGroup);
