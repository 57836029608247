const CONFIDENCE_THRESHOLD_KEY = 'confidenceThreshold';

const defaults = {
  editLiveQuery: null,
  editPerson: null,
  isInspectingDetection: false,
  isPlayerLive: true,
  isSubmittingLiveSearch: false,
  liveQueryGroups: [],
  liveQueries: [],
  liveQueryResults: {},
  confidenceThreshold: localStorage.getItem(CONFIDENCE_THRESHOLD_KEY) || 20,
};

export default function liveSearchReducer(state = defaults, action) {
  switch (action.type) {
    case 'LIVE_SEARCH/SET_EDIT_LIVE_QUERY': {
      return {
        ...state,
        editLiveQuery: action.payload,
      };
    }

    case 'LIVE_SEARCH/SET_EDIT_PERSON': {
      return {
        ...state,
        editPerson: action.payload,
      };
    }

    case 'LIVE_SEARCH/SET_LIVE_QUERY_GROUPS': {
      return {
        ...state,
        liveQueryGroups: action.payload,
      };
    }

    case 'LIVE_SEARCH/SET_LIVE_QUERIES': {
      return {
        ...state,
        liveQueries: action.payload,
      };
    }

    case 'LIVE_SEARCH/SET_LIVE_QUERY_RESULTS': {
      return {
        ...state,
        liveQueryResults: action.payload,
      };
    }

    case 'LIVE_SEARCH/MERGE_LIVE_QUERY_RESULTS': {
      return {
        ...state,
        liveQueryResults: { ...state.liveQueryResults, ...action.payload },
      };
    }

    case 'LIVE_SEARCH/SET_IS_PLAYER_LIVE': {
      return {
        ...state,
        isPlayerLive: action.payload,
      };
    }

    case 'LIVE_SEARCH/SET_IS_SUBMITTING': {
      return {
        ...state,
        isSubmittingLiveSearch: action.payload,
      };
    }

    case 'LIVE_SEARCH/SET_CONFIDENCE_THRESHOLD': {
      const confidenceThreshold = action.payload;

      try {
        localStorage.setItem(CONFIDENCE_THRESHOLD_KEY, confidenceThreshold);
      } catch (error) {
        console.error({ error, confidenceThreshold });
      }

      return {
        ...state,
        confidenceThreshold,
      };
    }

    case 'LIVE_SEARCH/TOGGLE_IS_INSPECTING': {
      return {
        ...state,
        isInspectingDetection: action.payload,
      };
    }

    default:
      return state;
  }
}
