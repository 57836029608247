import React from 'react';
import { useDispatch } from 'react-redux';
import ChevronIcon from '@material-ui/icons/ChevronRight';
import { closeProcessingPanel } from 'library/common/globalProcessingPanel/redux/actions';

import UploadCountHeader from './UploadCountHeader';
import ProcessingHeader from './ProcessingHeader';
import { getDataSourcesCountText } from './utils';

const ProcessingPanelHeader = ({ switchToGroupView, groupName, showJobLevel, processingData }) => {
  const { uploadGroupCount = 0, dataSourcesCount = 0 } = processingData;
  const dispatch = useDispatch();

  const dataSourcesCountText = getDataSourcesCountText(dataSourcesCount);

  const renderUploadHeader = () => (
    <UploadCountHeader
      uploadCount={uploadGroupCount}
      groupName={groupName}
      switchToGroupView={switchToGroupView}
    />
  );

  const renderGroupName = () => {
    if (showJobLevel && groupName) {
      return (
        <span>
          <ChevronIcon style={{ verticalAlign: 'middle' }} /> {groupName}{' '}
        </span>
      );
    }

    return null;
  };

  const renderDataSourceCount = () => {
    if (dataSourcesCount !== 0 && !groupName) {
      return <span style={{ marginLeft: 12 }}>{`(${dataSourcesCountText})`}</span>;
    }

    return null;
  };

  return (
    <ProcessingHeader onClick={() => dispatch(closeProcessingPanel())} buttonText="CLOSE">
      {renderUploadHeader()}
      {renderDataSourceCount()}
      {renderGroupName()}
    </ProcessingHeader>
  );
};

export default ProcessingPanelHeader;
