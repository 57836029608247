import { isEqual, cloneDeep, set, unset, isUndefined } from 'lodash';

import { batchActions } from 'redux-batched-actions';
import { Util } from 'leaflet';

import { getCacheBreakURL } from 'common/helpers/urlUtils';
import {
  setVenueMapModels,
  mergeVenueMapModels,
  defaultFetchCameras,
} from 'common/redux/models/actions';
import { getUserProfile, postUserProfile } from 'common/api/userApi';
import { getLiveQueries } from 'common/api/liveQueryApi';
import { extractMirageFormError } from 'common/helpers/apiErrorUtils';
import { normalizeList } from 'common/helpers/helperFunctions';
import { patchCamera, deleteCamera } from 'common/api/cameraApi';
import {
  deleteVenueMap,
  getVenueMaps,
  patchVenueMap,
  postVenueMap,
  getVenueCameras,
} from 'common/api/venueMapApi';
import { renderAlert } from 'app/redux/actions';
import { VENUE_MAP } from 'common/constants/app';
import {
  getGlobalSettings,
  patchBulkGlobalSettings,
  patchGeoSettings,
  postGeoSettings,
  getActiveModules,
  getCustomerSettings,
  getConfig,
  getGeoSettings,
} from 'common/api/settingsApi';
import {
  selectActiveFeatures,
  selectGCS,
  selectGeoSettings,
  selectTileServerUrl,
  selectTileServerSettingId,
  selectGlobalSettings,
  selectUserProfile,
  selectTileServerSettingChoices,
} from './selectors';
import { getNextGCS } from './utils';
import transformTemplateError from './transformTemplateError';

export const setUserProfile = payload => ({
  type: 'SETTINGS/SET_USER_PROFILE',
  payload,
});

export const setGeoSettings = settings => ({
  type: 'SETTINGS/SET_GEO_SETTINGS',
  payload: settings,
});

export const setCustomTileServer = url => ({
  type: 'SETTINGS/SET_CUSTOM_TILE_SERVER',
  payload: url,
});

export const setOsmGeocoderServer = url => ({
  type: 'SETTINGS/SET_OSM_GEOCODER_SERVER',
  payload: url,
});

export const setActiveModules = modules => ({
  type: 'SETTINGS/SET_ACTIVE_MODULES',
  payload: modules,
});

export const setCustomerSettings = settings => ({
  type: 'SETTINGS/SET_CUSTOMER_SETTINGS',
  payload: settings,
});

export const setConfigSettings = config => ({
  type: 'SETTINGS/SET_CONFIG_SETTINGS',
  payload: config,
});

export const setActiveFeatures = features => ({
  type: 'SETTINGS/SET_ACTIVE_FEATURES',
  payload: features,
});

export function setConfidenceThreshold(value) {
  return {
    type: 'SETTINGS/SET_CONFIDENCE_THRESHOLD',
    value,
  };
}

export function toggleShowObjectConfidence() {
  return {
    type: 'SETTINGS/TOGGLE_SHOW_OBJECT_CONFIDENCE',
  };
}

export function toggleShowIdentityConfidence() {
  return {
    type: 'SETTINGS/TOGGLE_SHOW_IDENTITY_CONFIDENCE',
  };
}

export const setGCS = gcs => ({
  type: 'SETTINGS/SET_GCS',
  payload: gcs,
});

/* Camera Management in settings page */
const setCameras = cameras => ({
  type: 'SETTINGS/SET_CAMERAS',
  payload: cameras,
});

const setSelectedCameras = cameras => ({
  type: 'SETTINGS/SET_SELECTED_CAMERAS',
  payload: cameras,
});

export function toggleGCS() {
  return function toggleGCSThunk(dispatch, getState) {
    const gcs = selectGCS(getState());
    dispatch(setGCS(getNextGCS(gcs)));
  };
}

export function setActiveFeature(feature, bool) {
  return function setActiveFeatureThunk(dispatch, getState) {
    const activeFeatures = selectActiveFeatures(getState());
    const features = { ...activeFeatures, [feature]: bool };

    dispatch(setActiveFeatures(features));
  };
}

export function fetchActiveModules() {
  return async function fetchActiveModulesThunk(dispatch) {
    const modules = (await getActiveModules()) || [];
    const activeModules = modules.reduce(
      (acc, module) => ({
        ...acc,
        [module]: true,
      }),
      {}
    );

    dispatch(setActiveModules(activeModules));
    return modules;
  };
}

export function fetchCustomerSettings() {
  return async function fetchCustomerSettingsThunk(dispatch) {
    const settings = await getCustomerSettings();
    dispatch(setCustomerSettings(settings));

    return settings;
  };
}

export function fetchConfig() {
  return async function fetchConfigThunk(dispatch) {
    const config = await getConfig();
    dispatch(setConfigSettings(config));

    return config;
  };
}

export function fetchGlobalSettings() {
  return function fetchGlobalSettingsThunk(dispatch) {
    dispatch({ type: 'SETTINGS/FETCH_GLOBAL_SETTINGS' });
    return getGlobalSettings()
      .then(data => {
        dispatch({
          type: 'SETTINGS/FETCH_GLOBAL_SETTINGS_SUCCESS',
          payload: data,
        });
      })
      .catch(e => {
        dispatch({ type: 'SETTINGS/FETCH_GLOBAL_SETTINGS_FAILURE', payload: e.message });
      });
  };
}

export function fetchGeoSettings() {
  return async function fetchGeoSettingsThunk(dispatch) {
    const data = await getGeoSettings();
    const geoSettings = normalizeList(data, (acc, el) => {
      acc[el.key] = el;
      return acc;
    });

    dispatch(setGeoSettings(geoSettings));
    return data;
  };
}

export function verifyTileServerURL() {
  return function verifyTileServerURLThunk(dispatch, getState) {
    const state = getState();
    const templatedUrl = selectTileServerUrl(state);
    dispatch({ type: 'SETTINGS/VERIFY_TILE_SERVER' });

    let fetchableUrl;
    try {
      fetchableUrl = Util.template(templatedUrl, { s: 'a', z: 8, x: 44, y: 102 });
    } catch (e) {
      dispatch({
        type: 'SETTINGS/VERIFY_TILE_SERVER_FAILURE',
        payload: transformTemplateError(e.message),
      });
      return Promise.reject(e.message);
    }
    return fetch(getCacheBreakURL(fetchableUrl)).then(response =>
      response.ok
        ? dispatch({ type: 'SETTINGS/VERIFY_TILE_SERVER_SUCCESS' })
        : dispatch({
            type: 'SETTINGS/VERIFY_TILE_SERVER_FAILURE',
            payload: 'Cannot connect to tile server.',
          })
    );
  };
}

export function toggleGlobalSetting(setting) {
  return function toggleGlobalSettingThunk(dispatch, getState) {
    const prevSetting = selectGlobalSettings(getState())?.[setting];
    const id = prevSetting?.id;
    const value = !prevSetting?.value;
    return dispatch(updateBulkGlobalSettings([{ id, value }]));
  };
}

/* bulk updates takes an array of settings that will be updated */
export function updateBulkGlobalSettings(settings) {
  return function updateBulkGlobalSettingsThunk(dispatch) {
    dispatch({ type: 'SETTINGS/UPDATE_GLOBAL_SETTINGS' });
    return patchBulkGlobalSettings(settings)
      .then(() => dispatch(fetchGlobalSettings()))
      .then(() => dispatch({ type: 'SETTINGS/UPDATE_GLOBAL_SETTINGS_SUCCESS' }))
      .catch(() =>
        dispatch({
          type: 'SETTINGS/UPDATE_GLOBAL_SETTINGS_FAILURE',
          payload: 'Unable to update settings right now, please try again later.',
        })
      );
  };
}

export function updateTileServerUrl(value) {
  return function updateTileServerUrlThunk(dispatch, getState) {
    const state = getState();
    const id = selectTileServerSettingId(state);
    return dispatch(updateBulkGlobalSettings([{ id, value }])).then(() =>
      dispatch(verifyTileServerURL())
    );
  };
}

export function verifyTileServerUrlChoices() {
  return function verifyTileServerUrlChoicesThunk(dispatch, getState) {
    const state = getState();
    const options = selectTileServerSettingChoices(state);
    const urlStatuses = {};

    if (!options) return urlStatuses;
    const splitOptions = options.split(',');

    const promises = [];

    const verifyUrl = url => {
      let fetchableUrl;
      try {
        // this files in the values into the template so that it can check the url and see if it returns true or not
        fetchableUrl = Util.template(url, { s: 'a', z: 8, x: 44, y: 102 });
      } catch (e) {
        urlStatuses[url] = false;
        return Promise.reject(e.message);
      }
      return fetch(getCacheBreakURL(fetchableUrl))
        .then(response => {
          urlStatuses[url] = response.ok;
          return url;
        })
        .catch(() => {
          urlStatuses[url] = false;
          return url;
        });
    };

    for (let i = 0; i < splitOptions.length; i++) {
      promises.push(verifyUrl(splitOptions[i]));
    }

    return Promise.allSettled(promises).then(() =>
      dispatch({
        type: 'SETTINGS/VERIFY_SERVER_STATUSES',
        payload: urlStatuses,
      })
    );
  };
}

export function createGeoSetting({ key, value }) {
  return async function createGeoSettingThunk(dispatch, getState) {
    const setting = (await postGeoSettings({ key, value })) || {};
    const geoSettings = { ...selectGeoSettings(getState()) };
    geoSettings[key] = setting;

    dispatch(setGeoSettings(geoSettings));

    return setting;
  };
}

export function editGeoSetting({ id, key, value }) {
  return async function editGeoSettingThunk(dispatch, getState) {
    const setting = (await patchGeoSettings({ id, value })) || {};
    const geoSettings = { ...selectGeoSettings(getState()) };
    geoSettings[key] = setting;

    dispatch(setGeoSettings(geoSettings));

    return setting;
  };
}

export function fetchVenueMaps() {
  return dispatch =>
    getVenueMaps().then(data => {
      const venues = normalizeList(data);
      dispatch(setVenueMapModels(venues));
      return data;
    });
}

export function createVenueMap({ name, file }) {
  return dispatch => {
    const body = new FormData();
    body.append('mapName', name);
    body.append(file.name, file);

    return postVenueMap(body).then(venue => {
      dispatch(mergeVenueMapModels({ [venue.id]: venue }));
      return venue;
    });
  };
}

export function removeVenueMap(id) {
  return (dispatch, getState) =>
    deleteVenueMap(id).then(data => {
      const venueMapModels = { ...getState().common.models[VENUE_MAP] };
      delete venueMapModels[id];
      dispatch(setVenueMapModels(venueMapModels));
      return data;
    });
}

export function editVenueMap(id, venue) {
  return dispatch =>
    patchVenueMap(id, venue).then(data => {
      dispatch(mergeVenueMapModels({ [data.id]: data }));
      return data;
    });
}

export function fetchVenueMarkers(ids = []) {
  return (dispatch, getState) => {
    const requests = [];
    ids.forEach(id => requests.push(getVenueCameras(id)));

    return Promise.all(requests).then(data => {
      const venueMapModels = { ...getState().common.models[VENUE_MAP] };
      data.forEach(({ venueMapId, cameraId }) => {
        const venueMap = venueMapModels[venueMapId] ? { ...venueMapModels[venueMapId] } : null;

        if (venueMap) {
          const cameras = venueMap.cameras ? [...venueMap.cameras] : [];
          cameras.push(cameraId);

          venueMapModels[venueMapId] = venueMap;
        }
      });

      dispatch(setVenueMapModels(venueMapModels));

      return data;
    });
  };
}

export function fetchCamerasSettings() {
  return dispatch =>
    dispatch(defaultFetchCameras()).then(data => {
      const cameraIds = data.map(({ id }) => id);
      dispatch(setCameras(cameraIds));
      return data;
    });
}

const removeCameras = keys => (dispatch, getState) => {
  const requests = [];
  const normalizedList = {};
  const selectedCameras = { ...getState().settings.selectedCameras };
  const { cameras: allCameras } = getState().settings;

  allCameras.forEach(id => (normalizedList[id] = true));

  keys.forEach(id => {
    requests.push(deleteCamera(id));
    delete selectedCameras[id];
    delete normalizedList[id];
  });

  /* since we don't support multi-item delete from UI, we won't show error dialog with aggregated backend error messages */
  return Promise.all(requests)
    .then(() => {
      const cameras = Object.keys(normalizedList);
      const actions = [setCameras(cameras), setSelectedCameras(selectedCameras)];
      dispatch(batchActions(actions));
    })
    .catch(backendError => {
      const { error } = extractMirageFormError(backendError);

      if (error) {
        renderAlert(error, { title: 'Camera could not be deleted.' });
      }

      return Promise.reject(backendError);
    });
};

export const removeCamera = key => dispatch => dispatch(removeCameras([key]));

export const setRecordingState = ({ cameraId, action }) => dispatch =>
  patchCamera(cameraId, { action }).then(() => dispatch(fetchCamerasSettings()));

export function setSelectedCameraStates({ ids, selected }) {
  return (dispatch, getState) => {
    const selectedCameras = { ...getState().settings.selectedCameras };
    /* set cameras as selected */
    if (selected) {
      ids.forEach(id => (selectedCameras[id] = true));
    } else {
      ids.forEach(id => delete selectedCameras[id]);
    }

    dispatch(setSelectedCameras(selectedCameras));
  };
}
export function toggleSelectedCamera(id) {
  return (dispatch, getState) => {
    const selected = !getState().settings.selectedCameras[id];
    dispatch(setSelectedCameraStates({ ids: [id], selected }));
  };
}

const setLiveQueries = liveQueries => ({
  type: 'SETTINGS/SET_LIVE_QUERIES',
  payload: liveQueries,
});

export function fetchLiveQueries() {
  return dispatch =>
    getLiveQueries().then(liveQueries => {
      const normalizedModels = normalizeList(liveQueries);
      const liveQueryKeys = Object.keys(normalizedModels);
      dispatch(setLiveQueries(liveQueryKeys));

      return normalizedModels;
    });
}

export const fetchUserProfile = () => dispatch =>
  getUserProfile().then(profile => {
    dispatch(setUserProfile(profile));
    return profile;
  });

export function updateUserProfile(key, value) {
  return async function updateUserProfileThunk(dispatch, getState) {
    const currentProfile = selectUserProfile(getState());
    let profile = cloneDeep(currentProfile);
    if (isUndefined(value)) {
      unset(profile, key);
    } else {
      set(profile, key, value);
    }

    if (!isEqual(profile, currentProfile)) {
      profile = await postUserProfile({ profile });
      dispatch(setUserProfile(profile));
    }
  };
}

export function initSettings() {
  return function initSettingsThunk(dispatch) {
    return Promise.all([
      dispatch(fetchActiveModules()),
      dispatch(fetchGlobalSettings()),
      dispatch(fetchGeoSettings()),
      dispatch(fetchConfig()),
    ]);
  };
}
