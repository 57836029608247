import { MIRAGE_HEALTH_UNKNOWN, UNKNOWN } from 'common/constants/app';
import { isInteger } from 'lodash';

const fourMinutes = 240000;

export const getSearchHealth = searchInfo => {
  if (!searchInfo) return MIRAGE_HEALTH_UNKNOWN;

  const { sparkNumberOfExecutors, sparkNumberOfCores, sparkTotalMemory } = searchInfo;

  const currTime = new Date().getTime();
  const executorTimeDifference = currTime - new Date(sparkNumberOfExecutors.time).getTime();
  const coreTimeDifference = currTime - new Date(sparkNumberOfCores.time).getTime();
  const memoryTimeDifference = currTime - new Date(sparkTotalMemory.time).getTime();

  if (
    executorTimeDifference < fourMinutes &&
    coreTimeDifference < fourMinutes &&
    memoryTimeDifference < fourMinutes
  ) {
    return searchInfo.flag;
  }
  return MIRAGE_HEALTH_UNKNOWN;
};

export const getServicesHealth = servicesInfo => {
  const { maculaHeartbeat = {}, processorHeartbeat = {}, camcoderHeartbeat = {} } =
    servicesInfo || {};
  return Math.max(
    maculaHeartbeat.flag || 0,
    processorHeartbeat.flag || 0,
    camcoderHeartbeat.flag || 0
  );
};

export const getVideoAndImageInfo = (dsInfoVideo, dsInfoImage) => {
  const { videoCount, videoCap } = dsInfoVideo;
  const { imageCount, imageCap } = dsInfoImage;

  const videoPercent =
    isInteger(videoCap) && videoCap !== 0 ? Math.round((videoCount / videoCap) * 100) : 0;
  const imagePercent =
    isInteger(imageCap) && imageCap !== 0 ? Math.round((imageCount / imageCap) * 100) : 0;
  const videoInfo = isInteger(videoCount)
    ? `${videoCount} / ${videoCap} (${
        videoCap !== 0 ? Math.round((videoCount / videoCap) * 100) : 100
      }%)`
    : UNKNOWN;
  const imageInfo = isInteger(imageCount)
    ? `${imageCount} / ${imageCap} (${
        imageCap !== 0 ? Math.round((imageCount / imageCap) * 100) : 100
      }%)`
    : UNKNOWN;
  return { videoPercent, imagePercent, videoInfo, imageInfo };
};
