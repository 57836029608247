import { useEffect, useRef, useState } from 'react';

import Tooltip from 'common/components/base/Tooltip';
import { doesContentOverflow } from 'common/helpers/styleFunctions';
import Typography from 'components/Typography';

const defaultStyle = {
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
};

/**
 @prop {String} tooltip -- manual override for the tooltip if needed

 rest of props are from MUI Tooltip Component and Mirage Base Tooltip Component
 */

const OverflowText = ({ children, showTooltip, style, tooltip, tooltipProps, width, ...rest }) => {
  const ref = useRef(null);
  const [shouldShowTooltip, setShouldShowTooltip] = useState(
    showTooltip !== undefined ? showTooltip : false
  );
  const tooltipTitle = tooltip ?? children;

  useEffect(() => {
    setShouldShowTooltip(
      showTooltip !== undefined ? showTooltip : doesContentOverflow(ref.current)
    );
  }, [ref, showTooltip]);

  return (
    <Tooltip
      title={shouldShowTooltip ? <Typography>{tooltipTitle}</Typography> : ''}
      {...tooltipProps}
    >
      <Typography ref={ref} style={{ maxWidth: width, ...defaultStyle, ...style }} {...rest}>
        {children}
      </Typography>
    </Tooltip>
  );
};

OverflowText.defaultProps = {
  tooltipProps: {},
  width: 80,
};

export default OverflowText;
