import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react-local';

import * as SettingsSelectors from 'settings/redux/selectors';
import { getColorLuminance } from 'common/helpers/colorHelpers';

export const BANNER_HEIGHT = 14;
const DEFAULT_COLOR = '#3f7847';

const useStyles = makeStyles()((theme, { backgroundColor, color }) => ({
  message: {
    color,
    fontSize: 10,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    backgroundColor,
    border: `1px solid ${backgroundColor}`,
    color: 'white',
    height: BANNER_HEIGHT,
    lineHeight: `${BANNER_HEIGHT}px`,
    padding: theme.spacing(0, 1.5),
    textAlign: 'center',
    textTransform: 'uppercase',
    zIndex: 1001,
  },
}));

function getDefaultTextColor(backgroundColor) {
  return getColorLuminance(backgroundColor) < 150 ? 'white' : 'black';
}

const ClassificationBanner = ({ children }) => {
  const backgroundColor = useSelector(SettingsSelectors.selectBannerColor) || DEFAULT_COLOR;
  const bannerTextColor = useSelector(SettingsSelectors.selectBannerTextColor);
  const color = bannerTextColor || getDefaultTextColor(backgroundColor);
  const { classes } = useStyles({ backgroundColor, color });

  return children ? (
    <div className={classes.root}>
      <p className={classes.message}>{children}</p>
    </div>
  ) : null;
};

const ClassificationHeader = () => {
  const headerText = useSelector(SettingsSelectors.selectBannerHeaderText);
  return <ClassificationBanner>{headerText}</ClassificationBanner>;
};

const ClassificationFooter = () => {
  const footerText = useSelector(SettingsSelectors.selectBannerFooterText);
  return <ClassificationBanner>{footerText}</ClassificationBanner>;
};

export { ClassificationHeader, ClassificationFooter };
