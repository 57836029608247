import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 100" {...props}>
    <path
      fill="#6599DE"
      fillRule="evenodd"
      d="M47.55 76.767l.043.043.024.02h.02c4.123-.058 7.956-1.382 9.877-2.169l.1-.038.106-.024c2.46-.6 8.19-3.679 11.546-5.583L.516 0 .491.1C.444.279-3.915 18.298 14.245 40.255c16.579 20.028 30.653 33.933 33.306 36.512zm47.778 17.938L71.464 70.732c-2.101 1.207-7.155 4.045-10.705 5.486l18.2 20.763.005.005c3.123 2.443 10.051 2.952 11.04 3.014h.043c2.574 0 4.349-.47 5.272-1.393 1.381-1.388.279-3.451.009-3.903zm-9.583-1.338a1.82 1.82 0 0 1-2.578 0 1.835 1.835 0 0 1 0-2.588 1.82 1.82 0 0 1 2.578 0 1.835 1.835 0 0 1 0 2.588z"
    />
  </SvgIcon>
);
