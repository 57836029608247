import MaterialFolderIcon from '@material-ui/icons/Folder';
import { makeStyles } from 'tss-react-local';

import Button from 'common/components/base/Button';
import IconButton from 'common/components/base/IconButton';
import Icon from 'common/components/base/Icon';
import Backpack from 'common/icons/ic-backpack';
import Bus from 'common/icons/ic-bus';
import Car from 'common/icons/ic-car';
import Face from 'common/icons/ic-face';
import Handbag from 'common/icons/ic-handbag';
import LibraryActive from 'common/icons/ic-library-active';
import Motorcycle from 'common/icons/ic-motorcycle';
import Person from 'common/icons/ic-person';
import PersonCrossed from 'common/icons/ic-person-crossed';
import Suitcase from 'common/icons/ic-suitcase';
import Train from 'common/icons/ic-train';
import Truck from 'common/icons/ic-truck';
import MapIcon from 'common/icons/map';
import MirageFolderIcon from 'common/icons/folder';
import MirageFolderIcon2 from 'common/icons/folder2';
import MoreLikeThisPerson from 'common/icons/more-like-this-person';
import MoreLikeThisVehicle from 'common/icons/more-like-this-vehicle';
import { MEDIUM_GREY, SECONDARY, PRIMARY_GREY } from 'common/constants/colors';

// Geo Objects
import BM21 from 'common/icons/ic-bm-21';
import Tank from 'common/icons/ic-battle-tank';
import TowedArtillery from 'common/icons/ic-towed-artillery';

import { Example } from '../helpers';

const useStyles = makeStyles()(theme => ({
  box: {
    display: 'flex',
  },
  grey: {
    fill: '#80868B',
  },
  item: {
    flex: 1,
    margin: theme.spacing(1),
  },
  mapIcon: {
    height: 17,
    width: 18,
  },
}));

const list = [
  Face,
  Backpack,
  Bus,
  Car,
  Handbag,
  LibraryActive,
  Motorcycle,
  Person,
  PersonCrossed,
  Suitcase,
  Train,
  Truck,
  MoreLikeThisPerson,
  MoreLikeThisVehicle,
  Tank,
  BM21,
  TowedArtillery,
];

const DisplayOptionsMapIcon = ({ disabled, selected, ...rest }) => {
  const { classes } = useStyles();

  return (
    <MapIcon
      className={classes.mapIcon}
      htmlColor={disabled ? MEDIUM_GREY : selected ? SECONDARY : PRIMARY_GREY}
      {...rest}
    />
  );
};
DisplayOptionsMapIcon.displayName = 'DisplayOptionsMapIcon';

const RedLine = ({ style, ...props }) => (
  <span
    style={{
      background: 'red',
      height: 24,
      width: 24,
      margin: 10,
      display: 'inline-block',
      ...style,
    }}
    {...props}
  />
);

const IconExample = () => {
  const { classes } = useStyles();

  return (
    <div>
      <Example label="Folder Icons">
        <RedLine>
          <MirageFolderIcon />
        </RedLine>
        <RedLine>
          <MirageFolderIcon2 />
        </RedLine>
        <RedLine>
          <MaterialFolderIcon />
        </RedLine>
        <Button startIcon={<MirageFolderIcon />}>Old</Button>
        <Button startIcon={<MirageFolderIcon2 />}>New</Button>
        <Button startIcon={<MaterialFolderIcon />}>Material</Button>
      </Example>

      <Example label="New MapIcon">
        <DisplayOptionsMapIcon />
        <DisplayOptionsMapIcon disabled />
        <DisplayOptionsMapIcon selected />
      </Example>

      <Example label="Default">
        <Icon iconName="close" />
      </Example>

      <Example label="Colored Icons">
        <Icon iconName="delete" color="pink" />
        <Icon iconName="map" color="#fa8d0e" />
        <Icon iconName="edit" color="red" />
        <Icon iconName="android" color="brown" />
        <Icon iconName="videocam" disabled />
        <Icon iconName="refresh" color="green" disabled />
      </Example>

      <Example label="Themed Icons">
        <Icon iconName="delete" theme="grey" />
        <Icon iconName="map" theme="green" />
        <Icon iconName="edit" theme="red" />
        <Icon iconName="android" theme="blue" />
        <Icon iconName="videocam" disabled />
        <Icon iconName="refresh" theme="green" disabled />
      </Example>

      <Example label="Sizes">
        <Icon size="small" iconName="https" theme="black" />
        <Icon size="default" iconName="done_outline" theme="red" />
        <Icon size="default" iconName="favorite" theme="grey" />
        <Icon size="large" iconName="bookmark" theme="green" />
      </Example>

      <Example label="SVG Icons">
        <div className={classes.box}>
          {list.map(MyIcon => (
            <div className={classes.item} key={MyIcon}>
              <MyIcon />
              <MyIcon color="secondary" />
              <MyIcon className={classes.grey} />
            </div>
          ))}
        </div>
      </Example>

      <Example label="Buttons">
        <div className={classes.box}>
          {list.map(MyIcon => (
            <div className={classes.item} key={MyIcon}>
              <IconButton color="secondary">
                <MyIcon />
              </IconButton>
              <IconButton color="primary">
                <MyIcon />
              </IconButton>
              <Button startIcon={<MyIcon color="white" />}>Text</Button>
            </div>
          ))}
        </div>
      </Example>
    </div>
  );
};

export default IconExample;
