import { forwardRef } from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import MuiMenuItem from '@material-ui/core/MenuItem';

import { ACCENT } from 'common/constants/colors';

const useStyles = createUseStyles(theme => ({
  root: {
    minHeight: 36,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 300,

    '& .MuiTouchRipple-child': {
      backgroundColor: `${ACCENT}`,
    },
  },
  dense: {
    minHeight: 30,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  selected: {
    backgroundColor: `${ACCENT} !important`,
  },
  'label-default': {
    fontSize: 12,
  },
  'label-small': {
    fontSize: 11,
    fontWeight: 300,
  },
}));

const MenuItem = forwardRef(
  ({ className, children, customClasses, dense, size, clickable, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <MuiMenuItem
        ref={ref}
        className={classnames(classes.root, classes[`label-${size}`], className, {
          [classnames(classes.dense, customClasses.dense)]: dense,
        })}
        classes={{
          ...customClasses,
          selected: classnames(classes.selected, customClasses.selected),
        }}
        dense={dense}
        button={clickable}
        /* remove outline caused by tabIndex when its not clickable */
        tabIndex={clickable ? undefined : null}
        data-testid="MenuItem"
        {...rest}
      >
        {children}
      </MuiMenuItem>
    );
  }
);

MenuItem.defaultProps = {
  clickable: true,
  customClasses: {},
  size: 'default',
};

export default MenuItem;
