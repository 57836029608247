import React from 'react';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from 'tss-react-local';

export const useStyles = makeStyles()(theme => ({
  root: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    '&:focus': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  yearSelected: {
    margin: '8px 0',
    fontWeight: theme.typography.fontWeightMedium,
  },
  yearDisabled: {
    pointerEvents: 'none',
    color: theme.palette.text.hint,
  },
}));

const Year = ({ onSelect, forwardedRef, value, selected, disabled, children, ...rest }) => {
  const { classes, cx } = useStyles();
  const handleClick = React.useCallback(() => onSelect(value), [onSelect, value]);

  return (
    <Typography
      role="button"
      component="div"
      tabIndex={disabled ? -1 : 0}
      onClick={handleClick}
      onKeyPress={handleClick}
      color={selected ? 'primary' : undefined}
      variant={selected ? 'h5' : 'subtitle1'}
      ref={forwardedRef}
      className={cx(classes.root, {
        [classes.yearSelected]: selected,
        [classes.yearDisabled]: disabled,
      })}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default React.forwardRef((props, ref) => <Year {...props} forwardedRef={ref} />);
