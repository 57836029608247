import React from 'react';
import { styled } from 'react-jss';

import Typography from 'components/Typography';
import Button from 'common/components/base/Button';
import TextInput from 'common/components/base/TextInput';

import { STATUS } from 'common/constants/api';
import { useIdentityPackage } from 'identityPackage/IdentityPackageContext';
import StrengthSlider from 'identityPackage/StrengthSlider';

const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(6, 8),
}));

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Relationship = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const Images = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
});

const CancelButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginRight: theme.spacing(3),
}));

const SaveButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}));

const Img = styled('img')(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
}));

const FIELDS = { RELATIONSHIP: 'identityLinkRelationship', STRENGTH: 'identityLinkStrength' };

const EditIdentityLink = ({ id, onClose }) => {
  const {
    fetchStatus,
    getLinkToIdentityThumb,
    getLinkToIdentityName,
    getLinkRelationship,
    getLinkStrength,
    name,
    updateLink,
  } = useIdentityPackage();

  return fetchStatus === STATUS.SUCCESS ? (
    <Form
      onSubmit={e => {
        e.preventDefault();
        updateLink({
          id,
          relationship: e.target.elements[FIELDS.RELATIONSHIP].value,
          strength: Number(e.target.elements[FIELDS.STRENGTH].value),
        });
        onClose();
      }}
    >
      <Typography variant="h3" paragraph>
        Edit Relationship Details
      </Typography>

      <Images>
        <Img
          src={getLinkToIdentityThumb(id)}
          alt={getLinkToIdentityName(id) ?? 'linked identity'}
        />
      </Images>

      <TextInput
        label={`Relationship to ${name}`}
        name={FIELDS.RELATIONSHIP}
        defaultValue={getLinkRelationship(id)}
        fullWidth
      />

      <Relationship>
        <Typography variant="body1">Expected Degree of Proximity</Typography>
        <StrengthSlider defaultValue={getLinkStrength(id)} name={FIELDS.STRENGTH} />
      </Relationship>

      <Actions>
        <CancelButton
          variant="outlined"
          data-testid="cancelIdentityLinkUpdateButton"
          onClick={onClose}
        >
          Cancel
        </CancelButton>
        <SaveButton type="submit" data-testid="updateIdentityLinkButton">
          Save
        </SaveButton>
      </Actions>
    </Form>
  ) : null;
};

export default EditIdentityLink;
