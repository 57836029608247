import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 99" color={color} {...rest}>
    <g fillRule="nonzero">
      <path d="M42.781 77.062h6.555v-9.836H42.78v4.918z" />
      <path d="M8.445 49.484c.637-7.86 5.402-46.191 37.613-46.191 14.887 0 25.742 7.906 32.266 23.508 3.984 9.52 5.074 19.164 5.352 22.684-.618-.172-1.25-.29-1.915-.29H10.355c-.66 0-1.293.118-1.91.29v-.001zm31.059 16.105c0-.906.734-1.64 1.637-1.64h9.836c.906 0 1.64.734 1.64 1.64v4.918h32.785c1.953 0 3.461-.785 4.547-1.965l-.894-12.559c-.13-1.797-.926-3.394-2.114-4.594.067-.191.11-.398.102-.609-.02-.508-2.145-50.766-40.984-50.766C7.215.014 5.094 50.272 5.075 50.78c-.004.211.035.418.101.61-1.187 1.199-1.984 2.796-2.113 4.593L2.028 70.506h37.477l-.001-4.917zM90.234 72.496a9.509 9.509 0 0 1-4.832 1.29H52.617v4.917a1.64 1.64 0 0 1-1.64 1.64H41.14a1.637 1.637 0 0 1-1.637-1.64v-4.918H1.793L.598 90.543a7.341 7.341 0 0 0 1.937 5.504 7.334 7.334 0 0 0 5.352 2.328H84.23a7.334 7.334 0 0 0 5.351-2.328 7.33 7.33 0 0 0 1.942-5.504l-1.29-18.047z" />
    </g>
  </SvgIcon>
);
