import axios from 'axios.js';

import { ANALYTICS_BACKEND, REPORTS } from 'common/constants/urls';

import { parseContentRange } from './utils';

export function getReports(params) {
  return axios.get(`${ANALYTICS_BACKEND}${REPORTS}/`, { params }).then(({ data, headers }) => {
    const range = parseContentRange(headers);

    return { data, range };
  });
}

export function getSingleReport(id) {
  return axios.get(`${ANALYTICS_BACKEND}${REPORTS}/${id}/`).then(({ data }) => data);
}

export function postReport(report) {
  return axios.post(`${ANALYTICS_BACKEND}${REPORTS}/`, report).then(({ data }) => data);
}

export function patchReport(report) {
  return axios
    .patch(`${ANALYTICS_BACKEND}${REPORTS}/${report.id}/`, report)
    .then(({ data }) => data);
}

export function deleteReport(id) {
  return axios.delete(`${ANALYTICS_BACKEND}/case/report/${id}/`).then(({ data }) => data);
}

export function getExportedReportPPTX(id, params) {
  return axios.get(`${ANALYTICS_BACKEND}/case/report/${id}/pptx/`, params).then(({ data }) => data);
}

export function postExportedFMVReportPPTX(id, body, config) {
  return axios
    .post(`${ANALYTICS_BACKEND}/case/report/${id}/pptx/`, body, config)
    .then(({ data }) => data);
}

export function postExportedFMVReportPDF(id, body, config) {
  return axios
    .post(`${ANALYTICS_BACKEND}/case/report/${id}/pdf/`, body, config)
    .then(({ data }) => data);
}
