import { useEffect, useState } from 'react';
import { styled } from 'react-jss';
import { useDispatch } from 'react-redux';

import Button from 'common/components/base/Button';
import Typography from 'components/Typography';

import useLibraries from 'common/hooks/useLibraries';
import { estimateAndSubmitSearch } from 'analysis/redux/searchBar/actions';
import { ROOT_DATASOURCE_KEY } from 'common/constants/app';

import { useIdentityPackage } from './IdentityPackageContext';

const Form = styled('form')({ display: 'flex', alignItems: 'baseline' });

const StyledButton = styled(Button)(({ theme }) => ({ marginLeft: theme.spacing(1) }));

const ProxySearchForm = () => {
  const {
    name,
    library,
    query,
    isPrincipalLoaded,
    areLinksLoaded,
    identityLinkIds,
  } = useIdentityPackage();
  const {
    fetchLibraries,
    getTopLevelFolderFromLibraryId,
    isLoaded: areLibrariesLoaded,
  } = useLibraries();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchLibraries();
  }, [fetchLibraries]);

  const isDisabled = !(areLibrariesLoaded || isPrincipalLoaded || areLinksLoaded);

  return (
    identityLinkIds.length > 0 && (
      <Form
        onSubmit={e => {
          e.preventDefault();
          setIsLoading(true);
          dispatch(
            estimateAndSubmitSearch({
              searchQuery: {
                folderIds: String(getTopLevelFolderFromLibraryId(library) ?? ROOT_DATASOURCE_KEY),
                query,
              },
            })
          );
        }}
      >
        <Typography>Search for {name} and their known associates</Typography>
        <StyledButton
          color="primary"
          variant="contained"
          type="submit"
          disabled={isDisabled}
          isLoading={isLoading}
        >
          Search
        </StyledButton>
      </Form>
    )
  );
};

export default ProxySearchForm;
