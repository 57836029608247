import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import Button from 'common/components/base/Button';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    color: 'black',
    fontWeight: 500,
    fontSize: 16,
    width: 26,
    height: 26,
    minWidth: 'unset',
    padding: 0,
  },
  buttonTop: {
    composes: '$button',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttonBottom: {
    composes: '$button',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  label: {
    height: 26,
  },
};

class ZoomControls extends React.Component {
  onZoomIn = e => {
    e.stopPropagation();
    this.props.onZoomIn(e);
  };

  onZoomOut = e => {
    e.stopPropagation();
    this.props.onZoomOut(e);
  };

  render() {
    const { className, classes, disableZoomIn, disableZoomOut } = this.props;
    return (
      <div className={classnames(classes.main, className)}>
        <Button
          theme="white"
          className={classes.buttonTop}
          disabled={disableZoomIn}
          customClasses={{ label: classes.label }}
          onClick={this.onZoomIn}
          data-testid="ZoomInButton"
        >
          +
        </Button>
        <Button
          theme="white"
          className={classes.buttonBottom}
          disabled={disableZoomOut}
          customClasses={{ label: classes.label }}
          onClick={this.onZoomOut}
          style={{ fontSize: 20 }}
          data-testid="ZoomOutButton"
        >
          -
        </Button>
      </div>
    );
  }
}
export default injectSheet(styles)(ZoomControls);
