const defaults = {
  allData: [],
  /*
    In order to get the video rotation after you reprocess a video, we need to know
    which datasource thumbnails have changed, without affecting the default cache behavior
    of the browser (which saves a lot of bandwidth and resources)
  */
  reprocessedVideos: {},
};

export default function datasourcesReducer(state = defaults, action) {
  switch (action.type) {
    case 'LIBRARY/DATASOURCE/SET_REPROCESSED_DATASOURCES': {
      return {
        ...state,
        reprocessedVideos: {
          ...state.reprocessedVideos,
          [action.payload.id]: action.payload.cacheBreaker,
        },
      };
    }
    case 'LIBRARY/DATASOURCE/SET_ALL_DATA': {
      return {
        ...state,
        allData: action.payload,
      };
    }
    default:
      return state;
  }
}
