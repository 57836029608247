import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react-local';
import HideableMirageLogo, { TRANSITION_DURATION } from 'app/nav/HideableMirageLogo';
import { selectIsNavExpanded } from 'app/redux/selectors';
import { DARK_BORDER } from 'common/constants/colors';

const HEADER_SIZES = {
  default: 50,
  small: 36,
};

const useStyles = makeStyles()((theme, props) => ({
  main: {
    alignItems: props.alignItems,
    borderBottom: props.showBottomBorder ? `1px solid ${DARK_BORDER}` : undefined,
    display: 'flex',
    flexDirection: props.flexDirection,
    height: HEADER_SIZES[props.size],
    justifyContent: props.justifyContent,
    margin: 0,
    minHeight: HEADER_SIZES[props.size],
    paddingRight: theme.spacing(2.5),
    paddingLeft: props.withLogo && !props.isNavExpanded ? 110 : theme.spacing(2.5),
    position: 'relative',
    transitionDuration: TRANSITION_DURATION,
    width: '100%',
  },
  logo: {
    position: 'absolute',
  },
}));

const MirageHeader = ({
  alignItems,
  children,
  className,
  component: Component,
  flexDirection,
  justifyContent,
  margin,
  padding,
  showBottomBorder,
  size,
  withLogo,
  ...rest
}) => {
  const isNavExpanded = useSelector(selectIsNavExpanded);
  const { classes, cx } = useStyles({
    alignItems,
    showBottomBorder,
    flexDirection,
    justifyContent,
    size,
    isNavExpanded,
    withLogo,
  });

  return (
    <Component className={cx(classes.main, className)} data-testid="MirageHeader" {...rest}>
      {/* withLogo will ONLY work for headers in the top of the screen directly next to the sidebar */}
      {withLogo && <HideableMirageLogo className={classes.logo} />}
      {children}
    </Component>
  );
};

MirageHeader.defaultProps = {
  alignItems: 'center',
  component: 'div',
  flexDirection: 'row',
  justifyContent: 'space-between',
  size: 'default',
};

MirageHeader.propTypes = {
  withLogo: PropTypes.bool,
  showBottomBorder: PropTypes.bool,
};

export default MirageHeader;
