import React from 'react';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Container, Grid } from '@material-ui/core';
import PrincipalProfile from './PrincipalProfile';
import IdentityPackageMainContent from './IdentityPackageMainContent';
import { IdentityPackageProvider } from './IdentityPackageContext';

const useStyles = createUseStyles(theme => ({
  identityPackage: {
    marginTop: theme.spacing(3),
  },
}));

const IdentityPackage = () => {
  const classes = useStyles();
  const { personId } = useParams();

  return (
    <IdentityPackageProvider principalId={personId}>
      <Container className={classes.identityPackage} maxWidth="xl">
        <Grid container className={classes.identityPackage}>
          <Grid item xs={2}>
            <PrincipalProfile />
          </Grid>
          <Grid item xs={10}>
            <IdentityPackageMainContent />
          </Grid>
        </Grid>
      </Container>
    </IdentityPackageProvider>
  );
};

export default IdentityPackage;
