import DateTime from 'dateTime';
import { renderAlert } from 'app/redux/actions';

import {
  ENQUEUED,
  ACCEPTED,
  FAILED,
  UPLOADING,
  COMPLETED,
  VID_ENC,
  PROC_SVC,
  CREATED,
  RUNNING,
  CANCELED,
  PROC_OFFLINE,
  CANCELING,
  COMPLETING,
  UPLOAD_FAILED,
  ENCODING_COMPLETED,
} from 'library/redux/constants';

import { parseToShorthandDate } from 'common/helpers/dateUtils';

/* Possible return values from vid proc service */
const VIDEO_ALREADY_EXISTS = 'VIDEO_ALREADY_EXISTS';
const UNSUPPORTED_EXTENSION = 'UNSUPPORTED_EXTENSION';
const ZERO_SIZE = 'ZERO_SIZE';
const UNSUPPORTED_CODEC = 'UNSUPPORTED_CODEC';
const UNSUPPORTED_FORMAT = 'UNSUPPORTED_FORMAT';
const INVALID_START_TIME = 'INVALID_START_TIME';
const IMAGE_ALREADY_EXISTS = 'IMAGE_ALREADY_EXISTS';
const VIDEO_PROCESSING_IN_PROGRESS = 'VIDEO_PROCESSING_IN_PROGRESS';
const VIDEO_DELETION_IN_PROGRESS = 'VIDEO_DELETION_IN_PROGRESS';

/* TODO: Make a datasourceUtils.js file and move non-library related functions there */
export function isDatasourceALiveRecording(datasource) {
  return datasource && datasource.startEpochMs !== 0;
}

export function getVideoTimestampBoundaries(datasource) {
  return {
    start: datasource.startEpochMs,
    end: datasource.startEpochMs + datasource.duration,
  };
}

export function isValidLocation(location) {
  return location && location.coords && location.coords.lat && location.coords.lng;
}

function getUploadImageStatus(hasError, isProcOnline) {
  if (hasError) return UPLOAD_FAILED;

  return isProcOnline ? UPLOADING : PROC_OFFLINE;
}

export function normalizeUploadImageData({
  txID,
  folderName,
  targetFolderId,
  isProcOnline,
  hasError = false,
}) {
  return {
    percentProgress: 0,
    status: getUploadImageStatus(hasError, isProcOnline),
    title: folderName,
    tileTitle: [folderName, ''],
    thumbUrl: '',
    txID,
    type: PROC_SVC,
    parentFolderKey: targetFolderId,
    priority: 0,
  };
}

export function getUploadFolderName(files) {
  const fileCount = files.length;
  const filePath = files[0].path.split('/');
  const hasParentFolder = filePath.length > 1;
  let folderName = '';
  if (fileCount === 1 && !hasParentFolder) {
    folderName = filePath[0];
  } else if (fileCount > 1 && !hasParentFolder) {
    folderName = parseToShorthandDate(DateTime.fromJSDateWithTZ(), {
      showSeconds: true,
      showTimeZone: true,
    });
  } else if (filePath.length > 1 && hasParentFolder) {
    folderName = filePath[0];
  }

  return folderName;
}

function getErrors(errors) {
  const duplicates = [];
  const incorrectCodecs = [];
  const unsupportedExtensions = [];
  const unknownErrors = [];
  const zeroSize = [];
  const invalidStartTime = [];
  const imageAlreadyExists = [];
  const unsupportedFormats = [];
  const videoProcessingInProgress = [];
  const videoDeletionInProgress = [];

  errors.forEach(error => {
    switch (error.errorCode) {
      case UNSUPPORTED_CODEC:
        return incorrectCodecs.push(error);
      case UNSUPPORTED_EXTENSION:
        return unsupportedExtensions.push(error);
      case VIDEO_ALREADY_EXISTS:
        return duplicates.push(error);
      case ZERO_SIZE:
        return zeroSize.push(error);
      case INVALID_START_TIME:
        return invalidStartTime.push(error);
      case IMAGE_ALREADY_EXISTS:
        return imageAlreadyExists.push(error);
      case UNSUPPORTED_FORMAT:
        return unsupportedFormats.push(error);
      case VIDEO_PROCESSING_IN_PROGRESS:
        return videoProcessingInProgress.push(error);
      case VIDEO_DELETION_IN_PROGRESS:
        return videoDeletionInProgress.push(error);
      default:
        return unknownErrors.push(error);
    }
  });

  return {
    duplicates,
    incorrectCodecs,
    unsupportedExtensions,
    unknownErrors,
    zeroSize,
    invalidStartTime,
    imageAlreadyExists,
    unsupportedFormats,
    videoProcessingInProgress,
    videoDeletionInProgress,
  };
}

function getDuplicateVideoError(videos) {
  if (!videos.length) return '';
  return `Duplicate videos detected for: ${joinFiles(videos, true)}`;
}

function getIncorrectCodecError(videos, format) {
  if (!videos.length) return '';

  return `The required codec is not yet supported for the following: ${joinFiles(
    videos
  )}Mirage currently supports the following codecs:\n${format}`;
}

function getUnsupportedExtensionError(videos) {
  if (!videos.length) return '';

  return `The following files can not yet be extracted from *.zip files: ${joinFiles(
    videos
  )}Mirage currently supports *.zip extraction for the following file types:\n*.jpg, *.png, *.docx, *.pptx, *.pdf`;
}

function getUnknownError(videos) {
  if (!videos.length) return '';
  return `Unknown errors occurred while processing: ${joinFiles(videos)}`;
}

function getZeroSizeError(videos) {
  if (!videos.length) return '';
  return `Video files contain no data: ${joinFiles(videos)}`;
}

function getInvalidStartTimeError(videos) {
  if (!videos.length) return '';
  return `Video file has incorrect timing information, and cannot be processed. : ${joinFiles(
    videos
  )}\nPlease contact system administrator for more help.`;
}

function getImageAlreadyExists(files) {
  if (!files.length) return '';
  return `Image file already exists: ${joinFiles(files)}`;
}

function getUnsupportedFormat(files) {
  if (!files.length) return '';
  return `The following files have unsupported formats: ${joinFiles(files)}`;
}

function getVideoProcessingInProgress(files) {
  if (!files.length) return '';
  return `Video file is already getting processed: ${joinFiles(files)}`;
}

function getVideoDeletionInProgress(files) {
  if (!files.length) return '';
  return `The following files have been marked for deletion and cannot be processed until that action completes: ${joinFiles(
    files
  )}`;
}

function joinFiles(files, withLocation) {
  let result = '\n\n';
  if (withLocation) {
    result += files
      .map(file => `${file.filepath} -- file location: ${file.existingLocation}`)
      .join('\n');
  } else {
    result += files.map(file => file.filepath).join(', ');
  }
  return `${result}\n\n`;
}

function getEnqueuedStatus(type, retryCounter) {
  const status = type === VID_ENC ? 'Encoding' : 'Processing';
  if (retryCounter > 0) {
    return `Retrying ${status} (${retryCounter})`;
  }

  return `In Queue (${status})`;
}

export function checkForUploadErrors(response, format = null) {
  const {
    duplicates,
    incorrectCodecs,
    unsupportedExtensions,
    unknownErrors,
    zeroSize,
    invalidStartTime,
    imageAlreadyExists,
    unsupportedFormats,
    videoProcessingInProgress,
    videoDeletionInProgress,
  } = getErrors(response[0].errors);

  let error = '';

  error += getDuplicateVideoError(duplicates);
  error += getIncorrectCodecError(incorrectCodecs, format);
  error += getUnsupportedFormat(unsupportedFormats);
  error += getUnsupportedExtensionError(unsupportedExtensions);
  error += getUnknownError(unknownErrors);
  error += getZeroSizeError(zeroSize);
  error += getInvalidStartTimeError(invalidStartTime);
  error += getImageAlreadyExists(imageAlreadyExists);
  error += getVideoProcessingInProgress(videoProcessingInProgress);
  error += getVideoDeletionInProgress(videoDeletionInProgress);

  if (error) renderAlert(error, { title: 'Some Files Cannot be Processed' });

  /* Remove upload preview from failed datasource */
  const videosToRemove = duplicates
    .concat(incorrectCodecs)
    .concat(unsupportedExtensions)
    .concat(unknownErrors)
    .concat(zeroSize);

  return { rejectedVideos: videosToRemove };
}

export function getStatus({ status, type, progress = 0, retryCounter = 0, isBatch }) {
  if (type === VID_ENC) {
    switch (status) {
      case ACCEPTED:
      case ENQUEUED:
        return getEnqueuedStatus(type, retryCounter);
      case FAILED:
        return 'Encoding Failed';
      case RUNNING:
        return 'Encoding Video';
      case COMPLETING:
        return 'Encoding Completing';
      case COMPLETED:
        return ENCODING_COMPLETED;
      case CANCELING:
        return 'Canceling Encoding';
      case CANCELED:
        return 'Canceled';
      default:
        return '';
    }
  } else if (isBatch) {
    switch (status) {
      case CREATED:
        return 'Preparing to Process';
      case UPLOADING:
        if (progress === 100) return 'Preparing to Process';
        return 'Uploading';
      case FAILED:
      case UPLOAD_FAILED:
        return 'Failed';
      case RUNNING:
      case PROC_SVC:
        return 'Processing';
      case CANCELED:
        return 'Canceled';
      case ENQUEUED:
      case ACCEPTED:
        return getEnqueuedStatus(type, retryCounter);
      case COMPLETED:
        return 'Completed';
      case CANCELING:
        return 'Canceling';
      case COMPLETING:
        return 'Completing';
      case PROC_OFFLINE:
        return 'Paused - DataProc Offline';
      default:
        return '';
    }
  } else {
    switch (status) {
      case CREATED:
        return 'Preparing to Process';
      case UPLOADING:
        if (progress === 100) return 'Preparing to Process';
        return 'Uploading';
      case FAILED:
      case UPLOAD_FAILED:
        return 'Processing Failed';
      case RUNNING:
      case PROC_SVC:
        return 'Processing';
      case CANCELED:
        return 'Processing Canceled';
      case ENQUEUED:
      case ACCEPTED:
        return getEnqueuedStatus(type, retryCounter);
      case COMPLETED:
        return 'Processing Completed';
      case CANCELING:
        return 'Canceling Processing';
      case COMPLETING:
        return 'Completing Processing';
      case PROC_OFFLINE:
        return 'Paused - DataProc Offline';
      default:
        return '';
    }
  }
}
export function shouldRenderCancelIcon(status) {
  return ![PROC_OFFLINE, COMPLETED, COMPLETING, CANCELED, CANCELING, CREATED].includes(status);
}

export function isProcessing(status) {
  return status === RUNNING || status === PROC_SVC;
}

export function isEnqueuedOrRunning(status) {
  return status === ENQUEUED || status === RUNNING || status === ACCEPTED;
}

export function isEnqueued(status) {
  return status === ENQUEUED || status === ACCEPTED;
}

export function isCreated(status) {
  return status === CREATED;
}

export function hasFailed(status) {
  return status === FAILED || status === UPLOAD_FAILED;
}

export function hasPaused(status) {
  return status === PROC_OFFLINE;
}

export function isUploading(status) {
  return status === UPLOADING;
}

export function shouldRenderReprioritizationIcon(status) {
  return [ENQUEUED, ACCEPTED, RUNNING, CREATED, PROC_OFFLINE].includes(status);
}

export function shouldRenderProgress(status, progress = 0) {
  if ((status === UPLOADING && progress === 100) || hasPaused(status) || progress === 0) {
    return false;
  }

  return ![CREATED, ENQUEUED, CANCELED, CANCELING, COMPLETED, COMPLETING].includes(status);
}
