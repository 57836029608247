import React from 'react';
import injectSheet from 'react-jss';
import Paper from '@material-ui/core/Paper';
import DateTime from 'dateTime';
import isEmpty from 'lodash/isEmpty';

import { TEST_SELECT_DATE_RANGE } from '__testSetup__/constants';

import Button from 'common/components/base/Button';
import { SECONDARY, ERROR } from 'common/constants/colors';

import { isSameDate } from 'common/helpers/dateUtils';
import { mergeDateWithTime, setTime } from './lib/utils';
import CustomDateTimePicker from './lib/CustomDateTimePicker';
import { DATE, YEAR, TIME } from './lib/PickerToolbar';
import CustomTimePickerView from './lib/CustomTimePickerView';
import RangeCalendar from './lib/RangeCalendar';

const styles = {
  main: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  dateRange: {
    display: 'flex',
  },
  pickerWrapper: {
    overflow: 'hidden',
    margin: '0 2px',
    minHeight: 342,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
  },
  button: {
    textDecoration: 'none',
    color: SECONDARY,
    fontWeight: 500,
  },
  timePicker: {
    minHeight: 254,
  },
  error: {
    marginRight: 12,
    fontWeight: 300,
    fontSize: 10,
    color: ERROR,
  },
};

function getState(start, end) {
  const startDate = start
    ? DateTime.fromJSDateWithTZ(start)
    : DateTime.fromJSDateWithTZ().startOf('day');
  const endDate = end ? DateTime.fromJSDateWithTZ(end) : startDate.endOf('day');

  const initialEndMonth = endDate;
  let initialStartMonth = startDate;
  if (isSameDate(startDate, endDate, 'month')) {
    initialStartMonth = startDate.set({ month: startDate.month - 1 });
  }

  const minDate = initialStartMonth.set({ month: initialStartMonth.month + 1 }).startOf('month');

  const maxDate = initialEndMonth.set({ month: initialEndMonth.month }).endOf('month');

  return {
    internalStartDate: startDate,
    internalEndDate: endDate,
    initialStartMonth,
    initialEndMonth,
    maxDate,
    minDate,
  };
}

class DateTimeRangePicker extends React.Component {
  static defaultProps = {
    startDate: DateTime.fromJSDateWithTZ(),
    endDate: DateTime.fromJSDateWithTZ(),
    dateRange: [],
  };

  state = {
    showTime: false,
    isFirstClick: true,
    ...getState(
      this.props.startDate || this.props.dateRange[0],
      this.props.endDate || this.props.dateRange[1]
    ),
  };

  componentDidUpdate(prevProps) {
    const hasDateChanged =
      prevProps.startDate !== this.props.startDate || this.props.endDate !== prevProps.endDate;

    if (this.props.startDate && this.props.endDate && hasDateChanged) {
      this.onDateUpdated();
    }

    if (this.state.internalEndDate.valueOf() < this.state.internalStartDate.valueOf()) {
      this.reverseStartAndEnd();
    }
  }

  reverseStartAndEnd = () => {
    const start = setTime(this.state.internalEndDate, this.state.internalStartDate);

    const end = setTime(this.state.internalStartDate, this.state.internalEndDate);

    let { initialStartMonth } = this.state;
    let { initialEndMonth } = this.state;

    if (start.month !== end.month) {
      initialStartMonth = start;
      initialEndMonth = end;
    }

    const maxDate = end.set({ month: end.month }).endOf('month');

    const minDate = start.set({ month: start.month + 1 }).startOf('month');

    /* Catch all to make sure we don't break and cause an infinite loop */
    if (end.valueOf() > start.valueOf()) {
      this.setState({
        internalStartDate: start,
        internalEndDate: end,
        initialStartMonth,
        initialEndMonth,
        maxDate,
        minDate,
      });
    }
  };

  handleCalendarDateChange = selectedDate => {
    if (this.state.isFirstClick) {
      this.setState(state => ({
        internalStartDate: mergeDateWithTime(selectedDate, state.internalStartDate),
        internalEndDate: mergeDateWithTime(selectedDate, state.internalEndDate),
        isFirstClick: false,
      }));
    } else {
      this.setState(state => ({
        internalEndDate: mergeDateWithTime(selectedDate, state.internalEndDate),
        isFirstClick: true,
      }));
    }
  };

  handleStartTimeChange = time =>
    this.setState(state => ({
      internalStartDate: mergeDateWithTime(state.internalStartDate, time),
    }));

  handleEndTimeChange = time =>
    this.setState(state => ({
      internalEndDate: mergeDateWithTime(state.internalEndDate, time),
    }));

  handleStartYearChange = date =>
    this.setState(state => ({
      initialStartMonth: date,
      internalStartDate: mergeDateWithTime(date, state.internalStartDate),
      minDate: date.endOf('month'),
    }));

  handleEndYearChange = date =>
    this.setState(state => ({
      initialEndMonth: date,
      internalEndDate: mergeDateWithTime(date, state.internalEndDate),
      maxDate: date.startOf('month'),
    }));

  handleStartMonthChange = date => {
    const minDate = date.set({ month: date.month + 1 }).startOf('month');

    this.setState({ minDate, initialStartMonth: date });
  };

  handleEndMonthChange = date => {
    const maxDate = date.set({ month: date.month }).endOf('month');

    this.setState({ maxDate, initialEndMonth: date });
  };

  onChange = () => {
    this.props.onChange({
      startDate: this.state.internalStartDate.valueOf(),
      endDate: this.state.internalEndDate.valueOf(),
    });

    this.props.onClose();
  };

  onDateUpdated = () => this.setState({ ...getState(this.props.startDate, this.props.endDate) });

  showTimeSelect = () => {
    this.startPicker.changeView(TIME);
    this.endPicker.changeView(TIME);
    this.setState({ showTime: true });
  };

  showDateSelect = () => {
    this.startPicker.changeView(DATE);
    this.endPicker.changeView(DATE);
    this.setState({ showTime: false });
  };

  handleViewChange = view => {
    if (view === YEAR) return;
    this.setState({ showTime: view !== YEAR && view !== DATE });
    this.startPicker.changeView(view);
    this.endPicker.changeView(view);
  };

  renderTimePicker = ({ time, onChange }) => (
    <CustomTimePickerView
      time={time}
      className={this.props.classes.timePicker}
      onChange={onChange}
    />
  );

  getCalendarDateRange = () => (isEmpty(this.props.dateRange) ? undefined : this.props.dateRange);

  render() {
    const { classes, dateRange } = this.props;
    const error =
      this.state.internalStartDate.valueOf() > this.state.internalEndDate.valueOf()
        ? 'Start Date must be before End Date'
        : '';

    return (
      <Paper className={classes.main}>
        <div className={classes.dateRange}>
          <div className={classes.pickerWrapper}>
            <CustomDateTimePicker
              showTabs={false}
              bindRef={node => (this.startPicker = node)}
              date={this.state.internalStartDate}
              minDate={dateRange[0] || undefined}
              maxDate={this.state.maxDate}
              disableFuture
              onTimeChange={this.handleStartTimeChange}
              onYearChange={this.handleStartYearChange}
              onDateChange={this.handleCalendarDateChange}
              onViewChange={this.handleViewChange}
              reverseYearOrder
              selectByDefault
              renderCalendar={props => (
                <RangeCalendar
                  {...props}
                  startDate={this.state.internalStartDate}
                  endDate={this.state.internalEndDate}
                  dateRange={this.getCalendarDateRange()}
                  initialMonth={this.state.initialStartMonth}
                  onMonthChange={this.handleStartMonthChange}
                />
              )}
            />
          </div>
          <div className={classes.pickerWrapper}>
            <CustomDateTimePicker
              showTabs={false}
              bindRef={node => (this.endPicker = node)}
              date={this.state.internalEndDate}
              minDate={this.state.minDate}
              maxDate={dateRange[1] || undefined}
              disableFuture
              onTimeChange={this.handleEndTimeChange}
              onYearChange={this.handleEndYearChange}
              onDateChange={this.handleCalendarDateChange}
              onViewChange={this.handleViewChange}
              renderTimePicker={this.renderTimePicker}
              reverseYearOrder
              renderCalendar={props => (
                <RangeCalendar
                  {...props}
                  startDate={this.state.internalStartDate}
                  endDate={this.state.internalEndDate}
                  dateRange={this.getCalendarDateRange()}
                  initialMonth={this.state.initialEndMonth}
                  onMonthChange={this.handleEndMonthChange}
                />
              )}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            theme="transparent"
            className={classes.button}
            onClick={this.state.showTime ? this.showDateSelect : this.showTimeSelect}
            {...TEST_SELECT_DATE_RANGE}
          >
            {this.state.showTime ? 'SELECT DATE' : 'SELECT TIME'}
          </Button>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={classes.error}>{error}</span>
            <Button theme="transparent" className={classes.button} onClick={this.props.onClose}>
              CANCEL
            </Button>
            <Button
              theme="transparent"
              className={classes.button}
              onClick={this.onChange}
              disabled={!!error}
            >
              OK
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}

export default injectSheet(styles)(DateTimeRangePicker);
