import pluralize from 'pluralize';
import { isEmpty, uniq } from 'lodash';

import {
  ALERT_QUERY,
  CAMERA,
  LIVE_QUERY,
  LIVE_QUERY_GROUP,
  DATASOURCE,
  IMAGE,
  PERSON,
  USER,
  FOLDER,
  FOLDER_V2,
  SCENE,
  REPORT,
  OBJECT,
  GEOFENCE,
  GEOIMAGE,
  GEODATASET,
  GEO_CLASSIFIER,
  GEO_DETECTOR,
  GEO_REPORT,
  GEO_SCENE,
  GEO_SEARCH_REQUEST,
  MISSION,
  ADHOC_S3_FILE,
  MACULA,
  VENUE_MAP,
} from 'common/constants/app';
import { normalizeList } from 'common/helpers/helperFunctions';
import { getCamera, getCameras, patchCamera, postCamera } from 'common/api/cameraApi';
import { getDatasourcesById } from 'common/api/datasourceApi';
import { getReports, postReport } from 'common/api/reportApi';
import { getUsers, getUserInfo } from 'common/api/userApi';
import { getSceneImageURL, postScene } from 'common/api/sceneApi';
import * as DeprecatedFolderApi from 'common/api/folder/v1';
import * as FolderApiV2 from 'common/api/folder/v2';
import { getLiveQueryGroups } from 'common/api/liveQueryGroupApi';

import {
  deleteGeoAlertQuery,
  deleteMission,
  getSingleMission,
  postGeoAlertQuery,
  postMission,
  getMissions,
  postMissionBookmarks,
  postMissionRemoveBookmark,
  patchGeoAlertQuery,
  patchMission,
  updateMissionSharing,
} from 'common/api/geoAlertingApi';

import { normalizeDatasources, normalizeUser, normalizeFolders } from './utils';

const mergeModels = (modelName, models) => ({
  type: 'COMMON/MODELS/MERGE_MODELS',
  payload: { modelName, models },
});

const setModels = (modelName, models) => ({
  type: 'COMMON/MODELS/SET_MODELS',
  payload: { modelName, models },
});

export const purgeGeoDatasets = () => ({
  type: 'COMMON/MODELS/PURGE_GEODATASETS',
});

/*
TODO:
1) Remove pluralize - Complexity should not be used for internal structures
2) RemoveSingle functions should all be removed -- done
*/

export function mergeVenueMapModels(data) {
  return mergeModels(VENUE_MAP, data);
}

export function setVenueMapModels(data) {
  return setModels(VENUE_MAP, data);
}

export function setLiveQueryModels(data) {
  return setModels(pluralize(LIVE_QUERY), data);
}

export function setLiveQueryGroupModels(data) {
  return setModels(pluralize(LIVE_QUERY_GROUP), data);
}

export function mergeDatasourceModels(data) {
  return mergeModels(DATASOURCE, data);
}

export function setDatasourceModels(data) {
  return setModels(DATASOURCE, data);
}

export function mergeImageModels(data) {
  return mergeModels(IMAGE, data);
}

export function setImageModels(data) {
  return setModels(IMAGE, data);
}

export function mergeCameraModels(data) {
  return mergeModels(CAMERA, data);
}

function setCameraModels(data) {
  return setModels(CAMERA, data);
}

export function mergePersonModels(data) {
  return mergeModels(PERSON, data);
}

export function setPersonModels(data) {
  return setModels(PERSON, data);
}

export function setUserModels(data) {
  return setModels(USER, data);
}

export function mergeUserModels(data) {
  return mergeModels(USER, data);
}

export function setObjectModels(data) {
  return setModels(OBJECT, data);
}

export function mergeDeprecatedFolderModels(data) {
  return mergeModels(FOLDER, data);
}

export function setDeprecatedFolderModels(data) {
  return setModels(FOLDER, data);
}

export function mergeFolderV2Models(data) {
  return mergeModels(FOLDER_V2, data);
}

export function setFolderV2Models(data) {
  return setModels(FOLDER_V2, data);
}

export function mergeReportModels(data) {
  return mergeModels(pluralize(REPORT), data);
}

export function setReportModels(data) {
  return setModels(pluralize(REPORT), data);
}

export function mergeSceneModels(data) {
  return mergeModels(pluralize(SCENE), data);
}

export function setSceneModels(data) {
  return setModels(pluralize(SCENE), data);
}

export function setGeofenceModels(data) {
  return setModels(GEOFENCE, data);
}

export function mergeGeofenceModels(data) {
  return mergeModels(GEOFENCE, data);
}

export function mergeGeoimageModels(data) {
  return mergeModels(GEOIMAGE, data);
}

export function mergeGeoDatasets(data) {
  return mergeModels(GEODATASET, data);
}

export function setGeoDatasets(data) {
  return setModels(GEODATASET, data);
}

export function setGeoSearchRequests(data) {
  return setModels(GEO_SEARCH_REQUEST, data);
}

export function mergeGeoSearchRequests(data) {
  return mergeModels(GEO_SEARCH_REQUEST, data);
}

export function setGeoReportModels(data) {
  return setModels(GEO_REPORT, data);
}

export function mergeGeoReportModels(data) {
  return mergeModels(GEO_REPORT, data);
}

export function mergeGeoSceneModels(data) {
  return mergeModels(GEO_SCENE, data);
}

export function setGeoClassifierModels(data) {
  return setModels(GEO_CLASSIFIER, data);
}

export function mergeGeoClassifierModels(data) {
  return mergeModels(GEO_CLASSIFIER, data);
}

export function setGeoDetectorModels(data) {
  return setModels(GEO_DETECTOR, data);
}

export function mergeGeoDetectorModels(data) {
  return mergeModels(GEO_DETECTOR, data);
}

export function setAdhocS3FileModels(data) {
  return setModels(ADHOC_S3_FILE, data);
}

export function mergeAdhocS3FileModels(data) {
  return mergeModels(ADHOC_S3_FILE, data);
}

export function setMaculaModels(data) {
  return setModels(pluralize(MACULA), data);
}

function mergeAlertQueryModels(data) {
  return mergeModels(ALERT_QUERY, data);
}

export function setMissionModels(data) {
  return setModels(MISSION, data);
}

export function mergeMissionModels(data) {
  return mergeModels(MISSION, data);
}

export function updateCameraModels(cameras) {
  const normalizedCameras = normalizeList(cameras);
  return mergeCameraModels(normalizedCameras);
}

/* ====================================== THUNK ACTIONS ========================================= */

export function defaultFetchCameras() {
  return dispatch =>
    getCameras().then(data => {
      const cameras = normalizeList(data);
      dispatch(setCameraModels(cameras));
      return data;
    });
}

export function fetchCamerasById(ids) {
  return function fetchCamerasByIdThunk(dispatch) {
    return getCameras({ ids: ids.join(',') }).then(data => {
      const cameras = normalizeList(data);
      dispatch(mergeCameraModels(cameras));
      return data;
    });
  };
}

export function fetchCameraById(id) {
  return function fetchCamerasByIdThunk(dispatch) {
    return getCamera(id).then(data => {
      const cameras = normalizeList([data]);
      dispatch(mergeCameraModels(cameras));
      return data;
    });
  };
}

export const defaultCreateCamera = camera =>
  async function dispatchCreateCamera(dispatch) {
    const newCamera = await postCamera(camera);
    dispatch(mergeCameraModels({ [newCamera.id]: newCamera }));
    return newCamera;
  };

export const defaultEditCamera = camera =>
  async function dispatchEditCamera(dispatch) {
    const editedCamera = await patchCamera(camera.id, camera);
    dispatch(mergeCameraModels({ [editedCamera.id]: editedCamera }));
    return editedCamera;
  };

export function defaultFetchLiveQueryGroups() {
  return function defaultFetchLiveQueryGroupsThunk(dispatch) {
    return getLiveQueryGroups().then(data => {
      const liveQueryGroups = normalizeList(data);

      dispatch(setLiveQueryGroupModels(liveQueryGroups));

      return data;
    });
  };
}

export function defaultFetchReports() {
  return dispatch =>
    getReports().then(({ data }) => {
      const reports = normalizeList(data);
      dispatch(setReportModels(reports));
      return data;
    });
}

export function defaultCreateReport(report) {
  return dispatch =>
    postReport(report).then(data => {
      dispatch(mergeReportModels({ [data.id]: data }));
      return data;
    });
}

export function defaultCreateScene(scene) {
  return dispatch =>
    postScene(scene).then(data => {
      dispatch(mergeSceneModels({ [data.id]: data }));
      return data;
    });
}

export function createSceneFromVideo({ scene, reportId, timestamp }) {
  return function createSceneFromVideoThunk(dispatch) {
    const params = {
      source: scene.source,
      timestamp,
    };

    return getSceneImageURL(params).then(thumbUrl =>
      dispatch(
        defaultCreateScene({
          ...scene,
          report: reportId,
          imageUrl: thumbUrl,
        })
      )
    );
  };
}

export function fetchDatasourcesById(ids) {
  return function fetchDatasourcesByIdThunk(dispatch) {
    return getDatasourcesById({ ids: uniq(ids) }).then(data => {
      const datasources = normalizeList(data, normalizeDatasources);

      if (!isEmpty(datasources)) {
        dispatch(mergeDatasourceModels(datasources));
      }

      return data;
    });
  };
}

export function createGeoAlertQuery(query) {
  return dispatch =>
    postGeoAlertQuery(query).then(data => {
      dispatch(mergeAlertQueryModels({ [data.id]: data }));
      return data;
    });
}

export function editGeoAlertQuery(query) {
  return dispatch =>
    patchGeoAlertQuery(query).then(data => {
      dispatch(mergeAlertQueryModels({ [data.id]: data }));
      return data;
    });
}

export function removeGeoAlertQuery(query) {
  return dispatch =>
    deleteGeoAlertQuery(query).then(data => {
      dispatch(mergeAlertQueryModels({ [query.id]: undefined }));
      return data;
    });
}

export function fetchMissions(params) {
  return dispatch =>
    getMissions(params).then(data => {
      const missions = normalizeList(data);
      dispatch(setMissionModels(missions));
      return data;
    });
}

export function fetchSingleMission(id) {
  return dispatch =>
    getSingleMission(id).then(data => {
      dispatch(mergeMissionModels({ [data.id]: data }));
      return data;
    });
}

export function createMission(mission) {
  return dispatch =>
    postMission(mission).then(async data => {
      const modifiedMissions = await dispatch(addMissionBookmarks([data]));
      return modifiedMissions[0];
    });
}

export function editMissionSharing(mission, groups, users) {
  return function editMissionSharingThunk(dispatch) {
    const groupIds = groups.map(g => g.id);
    const userIds = users.map(u => u.id);
    updateMissionSharing(mission, groupIds, userIds).then(() => {
      const newMission = { ...mission, acl: { groups, users } };
      return dispatch(mergeMissionModels({ [mission.id]: newMission }));
    });
  };
}

export function editMission(mission) {
  return dispatch =>
    patchMission(mission).then(data => {
      dispatch(mergeMissionModels({ [data.id]: data }));
      return data;
    });
}

export function removeMission(missionId) {
  return dispatch =>
    deleteMission(missionId).then(data => {
      dispatch(mergeMissionModels({ [missionId]: undefined }));
      return data;
    });
}

export function addMissionBookmarks(missions) {
  return function addMissionBookmarksThunk(dispatch) {
    const missionIds = missions.map(m => m.id);
    return postMissionBookmarks(missionIds).then(() => {
      const modifiedMissions = missions.reduce((acc, m) => {
        acc[m.id] = { ...m, bookmarked: true };
        return acc;
      }, {});
      dispatch(mergeMissionModels(modifiedMissions));
      return Object.values(modifiedMissions);
    });
  };
}

export function removeMissionBookmark(mission) {
  return function removeMissionBookmarkThunk(dispatch) {
    return postMissionRemoveBookmark(mission.id).then(() => {
      const removedMission = { ...mission, bookmarked: false };
      dispatch(mergeMissionModels({ [mission.id]: removedMission }));
      return removedMission;
    });
  };
}

export const fetchUserInfo = userId => dispatch =>
  getUserInfo(userId).then(data => {
    const userInfo = normalizeUser([data]);

    dispatch(mergeUserModels(userInfo));
    return data;
  });

export const fetchUsers = () => dispatch =>
  getUsers().then(data => {
    const users = normalizeUser(data);

    dispatch(setUserModels(users));
    return data;
  });

export const fetchZoneableDatasourceForFolder = params => dispatch =>
  DeprecatedFolderApi.getZoneableDsFromFolder(params).then(data => {
    dispatch(mergeDatasourceModels({ [data.id]: data }));
    return data;
  });

export const fetchFoldersV2 = params => dispatch =>
  FolderApiV2.getFolders(params).then(data => {
    const folders = normalizeFolders(data);

    if (params) {
      dispatch(mergeFolderV2Models(folders));
    } else {
      dispatch(setFolderV2Models(folders));
    }
    return data;
  });

export const fetchSingleFolder = id => dispatch =>
  FolderApiV2.getSingleFolder(id).then(folder => {
    const normalizedFolder = normalizeFolders([folder]);
    dispatch(mergeFolderV2Models(normalizedFolder));
    return folder;
  });

export const createFolder = folder =>
  function createFolderThunk(dispatch) {
    return FolderApiV2.postFolder(folder).then(newFolder => {
      const normalizedFolder = normalizeFolders([newFolder]);
      dispatch(mergeFolderV2Models(normalizedFolder));
      return newFolder;
    });
  };

export const editFolder = folder =>
  function editFolderThunk(dispatch) {
    return FolderApiV2.patchFolder(folder.id, folder).then(editedFolder => {
      const normalizedFolder = normalizeFolders([editedFolder]);
      dispatch(mergeFolderV2Models(normalizedFolder));

      return editedFolder;
    });
  };
