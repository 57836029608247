import { POLYGON, RECTANGLE } from './constants';

const initialLoading = {
  isGeofenceLoading: false,
  isFolderLoading: false,
};

const selectionDefaults = {
  dialogGeofenceId: null,
  filterDateStart: null,
  filterDateEnd: null,
  searchObjects: {},
  filteredGeoImageIds: {}, // These are geoimages for the map on the plan page
  geoDatasetIdObject: {},
};

const uiState = {
  datasetDialogIsOpen: false,
  geofencePagination: {
    ids: [],
    page: 1,
    limit: 50,
    offset: 0,
  },
  geofenceError: null,
  geofenceFilterText: '',
  isEditingGeofence: false,
  drawingGeofenceShape: null,
  isSidebarClosed: false,
  imageShadingPolygon: {},
  imagesInViewport: [],
};

const nonLoadingDefaults = {
  ...selectionDefaults,
  ...uiState,
};

const defaults = {
  ...initialLoading,
  ...nonLoadingDefaults,
};

export default function geoPlanReducer(state = defaults, action) {
  switch (action.type) {
    case 'PLAN_GEOSPATIAL/SET_GEOFENCE_PAGINATION': {
      return {
        ...state,
        geofencePagination: {
          ...state.geofencePagination,
          ...action.payload,
        },
      };
    }

    case 'PLAN_GEOSPATIAL/CLOSE_SIDEBAR': {
      return {
        ...state,
        isSidebarClosed: true,
      };
    }
    case 'PLAN_GEOSPATIAL/TOGGLE_SIDEBAR': {
      return {
        ...state,
        isSidebarClosed: !state.isSidebarClosed,
      };
    }

    case 'PLAN_GEOSPATIAL/START_EDITING_GEOFENCE': {
      return {
        ...state,
        isEditingGeofence: true,
        isSidebarClosed: true,
      };
    }

    case 'PLAN_GEOSPATIAL/STOP_EDITING_GEOFENCE': {
      return {
        ...state,
        isEditingGeofence: defaults.isEditingGeofence,
      };
    }

    case 'PLAN_GEOSPATIAL/START_DRAWING_POLYGON': {
      return {
        ...state,
        ...selectionDefaults,
        drawingGeofenceShape: POLYGON,
        isSidebarClosed: true,
        isEditingGeofence: defaults.isEditingGeofence,
      };
    }

    case 'PLAN_GEOSPATIAL/START_DRAWING_RECTANGLE': {
      return {
        ...state,
        ...selectionDefaults,
        drawingGeofenceShape: RECTANGLE,
        isSidebarClosed: true,
        isEditingGeofence: defaults.isEditingGeofence,
      };
    }

    case 'PLAN_GEOSPATIAL/STOP_DRAWING': {
      return {
        ...state,
        drawingGeofenceShape: null,
      };
    }

    case 'PLAN_GEOSPATIAL/CLEAR_GEOFENCE': {
      return {
        ...state,
        ...selectionDefaults,
        isSidebarClosed: defaults.isSidebarClosed,
      };
    }

    case 'PLAN_GEOSPATIAL/SET_GEOFENCE_ERROR': {
      return {
        ...state,
        geofenceError: action.payload,
      };
    }

    case 'PLAN_GEOSPATIAL/SET_GEOFENCE_FILTER_TEXT': {
      return {
        ...state,
        geofenceFilterText: action.payload,
      };
    }

    case 'PLAN_GEOSPATIAL/START_LOADING_FOLDERS': {
      return {
        ...state,
        isFolderLoading: true,
      };
    }

    case 'PLAN_GEOSPATIAL/STOP_LOADING_FOLDERS': {
      return {
        ...state,
        isFolderLoading: false,
      };
    }

    case 'PLAN_GEOSPATIAL/SET_GEOFENCE_LOADING': {
      return {
        ...state,
        isGeofenceLoading: action.payload,
      };
    }

    case `PLAN_GEOSPATIAL/OPEN_DATASET_DIALOG`: {
      return {
        ...state,
        datasetDialogIsOpen: true,
      };
    }

    case `PLAN_GEOSPATIAL/CLOSE_DATASET_DIALOG`: {
      return {
        ...state,
        datasetDialogIsOpen: false,
      };
    }

    case `PLAN_GEOSPATIAL/SET_DIALOG_CURRENT_GEOFENCE_ID`: {
      return {
        ...state,
        dialogGeofenceId: action.payload,
        geoDatasetIdObject: defaults.geoDatasetIdObject,
        filterDateStart: defaults.filterDateStart,
        filterDateEnd: defaults.filterDateEnd,
      };
    }

    case `PLAN_GEOSPATIAL/SET_FILTER_DATES`: {
      return {
        ...state,
        filterDateStart: action.payload.start,
        filterDateEnd: action.payload.end,
      };
    }

    case 'PLAN_GEOSPATIAL/SET_FILTERED_GEOIMAGES': {
      return {
        ...state,
        filteredGeoImageIds: action.payload,
      };
    }

    case 'PLAN_GEOSPATIAL/SET_FILTER_OBJECTS': {
      return {
        ...state,
        searchObjects: action.payload,
      };
    }

    case 'PLAN_GEOSPATIAL/SET_GEODATASET_IDS': {
      return {
        ...state,
        geoDatasetIdObject: action.payload,
        dialogGeofenceId: defaults.dialogGeofenceId,
      };
    }

    case 'PLAN_GEOSPATIAL/RESET_GEO_PLAN': {
      return {
        ...state,
        ...nonLoadingDefaults,
      };
    }

    case `PLAN_GEOSPATIAL/SET_DIALOG_CURRENT_MAP_BOUNDS`: {
      return {
        ...state,
        dialogCurrentMapBounds: action.payload,
      };
    }

    case `PLAN_GEOSPATIAL/SET_DIALOG_CURRENT_MAP_CENTER`: {
      return {
        ...state,
        dialogCurrentMapCenter: action.payload,
      };
    }

    case `PLAN_GEOSPATIAL/SET_IMAGE_SHADING_POLYGON`: {
      return {
        ...state,
        imageShadingPolygon: action.payload,
      };
    }

    case `PLAN_GEOSPATIAL/SET_IMAGES_IN_VIEWPORT`: {
      return {
        ...state,
        imagesInViewport: action.payload,
      };
    }

    default:
      return state;
  }
}
