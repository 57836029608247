import React, { useState, useEffect, useCallback } from 'react';
import { isEqual, without, noop } from 'lodash';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from 'common/components/base/Tooltip';

import Button from 'common/components/base/Button';
import Checkbox from 'common/components/base/Checkbox';
import ListSubheader from 'common/components/base/ListSubheader';
import Select from 'common/components/base/Select';

import { ACCENT, LIGHT_ACCENT } from 'common/constants/colors';
import Icon from 'common/components/base/Icon';

const useStyles = createUseStyles(theme => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: 32,
    padding: '0 13px',
    borderRadius: 16,
    border: `1px solid ${ACCENT}`,
    transition: 'all 0.3s',

    '&:hover': {
      background: `${LIGHT_ACCENT}`,
    },
  },
  select: {
    maxWidth: theme.spacing(30),
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  footer: {
    outline: 'none',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1.25),
  },
  cancel: {
    marginRight: theme.spacing(0.75),
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
  clearIcon: {
    cursor: 'pointer',
  },
}));

/* NOTE: Right now, there is a ObjectMultiSelect component that should exhibit the same behavior
as this MultiSelect component, but it has its own unique styling. When we need to update functionality
here, we should ensure that the UX remains the same in that component as well */
const MultiSelect = ({
  className,
  customClasses = {},
  defaultValue,
  onClose,
  onChange,
  onItemsSelected,
  value,
  options,
  renderMenuItem,
  requireSelection,
  showCheckboxes,
  tooltip,
  disabled,
  hideClearIcon,
  allowExternalControl,
  ...rest
}) => {
  const classes = useStyles();

  const [internalValue, setValue] = useState(defaultValue || value || []);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultValue?.length) {
      onItemsSelected(defaultValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Allows the selected values to, optionally, be controlled externally when the defaultValue changes
  useEffect(() => {
    if (allowExternalControl && defaultValue && !isEqual(defaultValue, internalValue)) {
      setValue(defaultValue);
    }
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const openSelect = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeSelect = useCallback(
    (e, { shouldApplyChanges = false } = {}) => {
      e.stopPropagation();
      setIsOpen(false);
      onClose(e);
      if (shouldApplyChanges) {
        onItemsSelected(internalValue);
      } else {
        setValue(value);
      }
    },
    [onClose, onItemsSelected, internalValue, value]
  );

  const onApply = useCallback(e => closeSelect(e, { shouldApplyChanges: true }), [closeSelect]);

  const handleOnChange = e => {
    const selections = e.target.value || [];
    /*
      For some reason ListSubheaders have undefined values and can be clicked, despite not showing
      any selection indicator. I'm not sure if this a bug or a limitation of how MUI does things, but
      for now we need to remove undefined values otherwise it will cause weird UX behaviors.
    */
    setValue(without(selections, undefined, null, ''));
    onChange(e);
  };

  const disableApply = requireSelection ? internalValue.length === 0 : false;

  const _renderMenuItem = data => (
    <>
      <Checkbox
        size="small"
        customClasses={{
          checkboxClasses: {
            root: classes.checkbox,
          },
        }}
        checked={internalValue.includes(data.value)}
      />
      {renderMenuItem ? renderMenuItem(data) : data.label}
    </>
  );

  return (
    <Tooltip title={tooltip}>
      <Select
        open={isOpen}
        variant="outlined"
        className={classnames(className, classes.main)}
        classes={{
          select: classnames(customClasses.select, classes.select),
        }}
        IconComponent={() =>
          internalValue.length && !disabled && !hideClearIcon ? (
            <Icon
              iconName="cancel"
              className={classes.clearIcon}
              theme="grey"
              size="small"
              onClick={() => {
                setValue([]);
                onItemsSelected([]);
              }}
            />
          ) : (
            <Icon iconName="arrow_drop_down" />
          )
        }
        displayEmpty
        multiple
        input={<InputBase />}
        value={internalValue}
        options={options}
        renderMenuItem={_renderMenuItem}
        onChange={handleOnChange}
        onOpen={openSelect}
        onClose={closeSelect}
        disabled={disabled}
        shrinkOnEmptyLabel
        /*
          This prevents the menu from auto-adjusting when selecting multiple items
          MUI ticket: https://github.com/mui-org/material-ui/issues/19245
        */
        MenuProps={{ variant: 'menu' }}
        {...rest}
      >
        <ListSubheader className={classes.footer}>
          <Button onClick={closeSelect} variant="outlined" className={classes.cancel}>
            CANCEL
          </Button>
          <Button disabled={disableApply} onClick={onApply}>
            APPLY
          </Button>
        </ListSubheader>
      </Select>
    </Tooltip>
  );
};

MultiSelect.defaultProps = {
  value: [],
  onClose: noop,
  onChange: noop,
  onItemsSelected: noop,
  showCheckboxes: false,
  hideClearIcon: false,
  allowExternalControl: false,
};

export default MultiSelect;
