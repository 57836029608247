import { isEmpty, startCase } from 'lodash';
import { PROC_OFFLINE, FAILED } from 'library/redux/constants';

import {
  computeStatusCount,
  computeCompletedJobsCount,
  computeDataSourceCount,
  getJobs,
  hasFailedJobs,
} from './utils';

const DEFAULT_JOB_INFO = {
  uploadGroupCount: 0,
  dataSourcesCount: 0,
  uploadGroups: {},
  uploadingJobs: [],
  topLevelJobs: [],
  inProgressJobs: [],
  haveAllJobsFailed: false,
};

function getJobStatus(status, isProcOnline) {
  return isProcOnline ? status : PROC_OFFLINE;
}

function getPreProcessedJobs(uploadGroups) {
  const processingJobs = [];
  Object.values(uploadGroups).forEach(jobGrp => {
    jobGrp.children.forEach(job => processingJobs.push(job));
  });

  return processingJobs;
}

function preProcessUploadingJobs(uploadingJobs, userName, isProcOnline) {
  return uploadingJobs.map(job => ({
    ...job,
    displayInfo: job.displayInfo || {},
    status: getJobStatus(job.status, isProcOnline),
    createdBy: startCase(userName),
    children: [],
  }));
}

function computeJobsCount(statusCounts) {
  return computeStatusCount(statusCounts);
}

function normalizeBatches(batchesObj, isProcOnline) {
  return (batchesObj.batches || []).map(batch => ({
    batchId: batch.batchId,
    displayInfo: batch.displayInfo,
    status: isProcOnline ? batch.batchStatus : PROC_OFFLINE,
    destinationFolder: batch.destinationFolder,
    createdBy: batch.createdBy,
    clientUploadStartTs: batch.uploadTs,
    size: batch.totalSize,
    title: batch.title,
    hasFailedJobs: hasFailedJobs(batch.statusCounts),
    completedJobsCount: computeCompletedJobsCount(batch),
    jobsCount: computeJobsCount(batch),
    children: getJobs(batch.workflows, isProcOnline),
  }));
}

function getProcessingJobsInfo(processUploadingJobs = [], batches = {}, userName, isProcOnline) {
  if (isEmpty(batches) && processUploadingJobs.length === 0) {
    return DEFAULT_JOB_INFO;
  }

  const topLevelJobs = normalizeBatches(batches, isProcOnline);
  const uploadGroups = topLevelJobs.reduce((acc, batch) => {
    acc[batch.batchId] = batch;
    return acc;
  }, {});

  const uploadingJobs = preProcessUploadingJobs(processUploadingJobs, userName, isProcOnline);

  const uploadGroupCount = uploadingJobs.length + Object.keys(uploadGroups).length;
  const dataSourcesCount = computeDataSourceCount(batches);
  const inProgressJobs = getPreProcessedJobs(uploadGroups);
  const haveAllJobsFailed = Object.values(uploadGroups).every(group => group.status === FAILED);

  return {
    uploadGroupCount,
    dataSourcesCount,
    uploadGroups,
    uploadingJobs,
    topLevelJobs,
    inProgressJobs,
    haveAllJobsFailed,
  };
}

export default getProcessingJobsInfo;
