import React from 'react';
import injectSheet from 'react-jss';

import LinearProgress from 'common/components/base/LinearProgress';
import { NAVY_BLUE } from 'common/constants/colors';

import { getStatus, hasFailed } from 'library/redux/datasource/utils';
import { UPLOADING, COMPLETED } from 'library/redux/constants';
import { getTextColor, isJobRetrying } from './utils';

const styles = {
  outer: {
    width: 176,
    display: 'inline-block',
    zoom: 1,
    position: 'relative',
    clip: 'auto',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    alignItems: 'baseline',
    color: NAVY_BLUE,
    fontSize: 13,
  },
  special: {
    flex: 1,
    borderBottom: '1px dotted #333',
  },
  statusColumn: {
    float: 'right',
    width: '30%',
    marginLeft: 4,
    marginTop: 5,
    fontSize: 11,
    color: NAVY_BLUE,
  },
  progressBar: {
    color: NAVY_BLUE,
    textAlign: 'left',
    fontSize: 'medium',
  },
};

const JobStatus = ({ statusInfo, classes }) => {
  const { percentProgress, retryCounter, status, type, batchId } = statusInfo;

  const isWithinRange = percentProgress > 0 && percentProgress < 100;
  const shouldRenderProgressBar = (status === UPLOADING || status !== COMPLETED) && isWithinRange;

  const statusText = getStatus({ status, type, percentProgress, retryCounter, isBatch: !!batchId });

  return (
    <div
      className={classes.progressBar}
      style={{ color: getTextColor(statusInfo.status) }}
      data-testid="JobStatus-icon"
    >
      <span style={{ width: '70%' }}>
        {statusText && (
          <div className={classes.outer}>
            <div className={classes.container}>
              {statusText}
              {!isJobRetrying(statusInfo) && shouldRenderProgressBar && (
                <>
                  <span className={classes.special} />
                  {percentProgress}%
                </>
              )}
            </div>
          </div>
        )}
      </span>

      {shouldRenderProgressBar && (
        <span className={classes.statusColumn}>
          <LinearProgress
            color={hasFailed(statusInfo.status) ? 'secondary' : 'primary'}
            value={percentProgress}
          />
        </span>
      )}
    </div>
  );
};

export default injectSheet(styles)(JobStatus);
