import { isEmpty } from 'lodash';
import { isMirageException } from 'common/errors';
import { humanize, removePeriod } from 'common/helpers/stringUtils';

const DJANGO_FORM_EXCEPTION = 'DjangoFormExcpetion';
const MIRAGE_FORM_EXCEPTION = 'MirageFormExcpetion';
export const UNKNOWN_ERROR_MSG = 'Unknown Error';

/* Based on how the django form error is structured */
export function createApiFormError(key, error) {
  return {
    /* default message */
    message: 'An error has occurred',
    response: {
      data: {
        [key]: error,
      },
      status: 400,
    },
  };
}

export function extractDjangoFormError(err) {
  /*
    This error is an axios error. Axios will always format the error such that there is a
    message and response key, where message is a broad description of the failed HTTP request
    and response contains the status and data of the reponse itself
  */
  const { message, response = {} } = err;
  const { data, status } = response;

  let error;
  const errors = [];

  /*
    Django Form errors return objects that hold the keys of invalid form requests. These
    keys usually point to an array of error messages that describe the bad request

    e.g. err = {
      message: ...,
      response: {
        data: {
          externalId: ["camera with that externalId already exists", "camera with that name already exists"]
        },
        status: 400
      }
    }
  */
  if (data && typeof data === 'object' && !isEmpty(data)) {
    const errorKeys = Object.keys(data);

    errorKeys.forEach(key =>
      errors.push({
        key,
        message: data[key],
      })
    );

    const firstError = data[errorKeys[0]];

    /*
      Return the first error message by default, generally we don't want to show more than
      one error at a time anyway
    */
    error = firstError[0] || firstError.message || firstError;
  } else {
    error = message;
  }

  return {
    data,
    error,
    errors,
    status,
    type: DJANGO_FORM_EXCEPTION,
  };
}

/* This is based on the Django Form Error structure */
function getErrorMessage(data) {
  let error = '';
  if (data && typeof data === 'object') {
    const key = Object.keys(data)[0];
    const firstError = data[key];

    /*
      Return the first error message by default, generally we don't want to show more than
      one error at a time anyway
    */
    error =
      firstError instanceof Array
        ? `${removePeriod(firstError[0])}: ${humanize(key)}`
        : firstError.message || firstError;
  }

  return error;
}

export function extractMirageFormError(err) {
  /* err will not exist sometimes when things like internet connection is turned off */
  if (!err || !err.response || !err.response.data) {
    return {
      message: UNKNOWN_ERROR_MSG,
      error: UNKNOWN_ERROR_MSG,
    };
  }

  const { data, status } = err.response;
  const { details, message, resolution } = data;
  if (!isMirageException(err)) {
    console.warn('Error returned is not of type MirageException');

    /*
      Stop-gap solution to render some sort of error since our backend will sometimes render
      MirageExcpetions, and other times directly sends the Django error back
    */
    const djangoData = extractDjangoFormError(err);
    if (djangoData.error) {
      return djangoData;
    }

    return { data };
  }

  let error;
  if (details) {
    error = getErrorMessage(details);
  }

  return {
    type: MIRAGE_FORM_EXCEPTION,
    status,
    data,
    error,
    message,
    resolution,
    details,
  };
}

//  This is meant to be called to extract an error message from an unknown source. The error
//  may come from Mirage, Django, the network, or the javascript itself. It will only return the
//  error message string. If you need more info, then you probably know the type of error
//  you have and you should call extractMirageFormError or extractDjangoFormError directly.
export const extractErrorMessage = err => {
  const mirageParsedResponse = extractMirageFormError(err);
  if (mirageParsedResponse?.message === UNKNOWN_ERROR_MSG) {
    //  Likely some sort of JS error, return the message if it exists, otherwise the default
    return err?.message || UNKNOWN_ERROR_MSG;
  }

  if (mirageParsedResponse?.message && mirageParsedResponse?.resolution) {
    //  They get a nicely detailed error message
    return `${mirageParsedResponse.message} ${mirageParsedResponse.resolution}`;
  }

  //  It's either a Django or Mirage error, extract the message details if they exist
  return (
    mirageParsedResponse?.error ||
    mirageParsedResponse?.message?.detail ||
    mirageParsedResponse?.message ||
    UNKNOWN_ERROR_MSG
  );
};

export function intentionallyDropError(error) {
  console.warn('Error has been intentionally dropped:', error);
}
