import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { render } from '@testing-library/react';
import { Provider } from 'react-redux';
import { JssProvider, ThemeProvider } from 'react-jss';
import { MuiThemeProvider } from '@material-ui/core/styles';

import themes from 'common/components/base/themes';
import store from 'redux/store';
import { set } from 'lodash';
import localJss, { createGenerateClassName } from '../jss';
import * as TEST_IDS from './constants';

(function checkUnique(testIds) {
  const unique = {};

  for (let i = 0; i < testIds.length; i++) {
    const testId = testIds[i][TEST_IDS.TEST_ATTRIBUTE];

    if (unique[testId]) {
      console.warn(`Test ID Generator: The ID '${testId}' is already in use`);
    } else {
      unique[testId] = testId;
    }
  }
})(Object.values(TEST_IDS));

export function getElementByTestID(testId) {
  return document.querySelector(`[data-testid="${testId}"]`);
}

export function getElementsByTestID(testId) {
  return document.querySelectorAll(`[data-testid="${testId}"]`);
}

export const renderThemedComponent = (children, { store: providedStore } = {}) =>
  render(
    <Provider store={providedStore || store}>
      <ThemeProvider theme={themes.defaultTheme}>
        <MuiThemeProvider theme={themes.defaultTheme}>
          <JssProvider jss={localJss} generateClassName={createGenerateClassName()}>
            {children}
          </JssProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  );

export const parameterizedTest = ({ subject, cases, description = subject.name }) =>
  describe(description, () => {
    cases.forEach(([...args]) => {
      const testArgs = typeof args[0] === 'string' ? args[1] : args[0];
      const expected = args[args.length - 1];
      const itDesc =
        typeof args[0] === 'string'
          ? args[0]
          : `given ${JSON.stringify(testArgs)}, returns ${JSON.stringify(expected)}`;

      it(itDesc, () => {
        expect(subject(...testArgs)).toEqual(expected);
      });
    });
  });

export const simpleSelectorTest = cases =>
  cases.forEach(([subject, path]) =>
    describe(subject.name, () => {
      it(`returns data when it can be found at ${path}`, () => {
        expect(subject(set({}, path, 1))).toEqual(1);
      });
      it('returns undefined when no data exists', () => {
        expect(subject()).toEqual(undefined);
      });
    })
  );
