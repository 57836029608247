import React from 'react';

import { withStyles } from 'tss-react-local';
import TextField from 'common/components/base/TextInput';
import LocationInput from 'common/components/base/LocationInput';
import Icon from 'common/components/base/Icon';
import { Example } from '../helpers';

const styles = {
  section: {
    marginTop: 28,
    marginBottom: 20,
  },
  input: {
    marginRight: 8,
  },
};

class TextInputExample extends React.Component {
  state = {
    default: '',
    field1: '',
    field2: '',
    field3: '',
    location: { formattedAddress: '' },
  };

  onTextChange = input => e => this.setState({ [input]: e.target.value });

  render() {
    const { classes } = this.props;
    const inputClass = { textfieldClasses: { root: classes.input } };

    return (
      <div>
        <Example className={classes.section} label="Default Text Input">
          <TextField
            customClasses={inputClass}
            value={this.state.default}
            onChange={this.onTextChange('default')}
          />
          <TextField
            required
            label="Required"
            value={this.state.default}
            onChange={this.onTextChange('default')}
          />
        </Example>

        <Example className={classes.section} label="Adornments">
          <TextField
            startAdornment={<Icon iconName="edit" theme="blue" size="small" />}
            customClasses={inputClass}
            value={this.state.field1}
            onChange={this.onTextChange('field1')}
          />
          <TextField
            endAdornment={<Icon iconName="delete" color="pink" size="small" />}
            value={this.state.field1}
            onChange={this.onTextChange('field1')}
          />
        </Example>

        <Example className={classes.section} label="Label and Helper Text">
          <TextField
            customClasses={inputClass}
            value={this.state.field2}
            onChange={this.onTextChange('field2')}
            label="Report Name"
            helperText="Report with this name already exists"
            hasError
          />
          <TextField
            customClasses={inputClass}
            value={this.state.field2}
            onChange={this.onTextChange('field2')}
            label="Report Name"
            helperText="Please enter name"
          />
          <TextField
            customClasses={inputClass}
            value={this.state.field2}
            onChange={this.onTextChange('field2')}
            label="Report Name"
            helperText={!this.state.field2 ? 'Dynamic Helper Text' : ''}
            placeholder="Enter name"
          />
          <TextField
            customClasses={inputClass}
            value={this.state.field2}
            onChange={this.onTextChange('field2')}
            hasError={!this.state.field2}
            label={!this.state.field2 ? 'Dynamic Label' : ''}
            helperText={!this.state.field2 ? 'Dynamic Helper Text' : ''}
          />
          <TextField
            customClasses={inputClass}
            value={this.state.field2}
            onChange={this.onTextChange('field2')}
            label={!this.state.field2 ? 'Dynamic Label' : ''}
            helperText={!this.state.field2 ? 'Dynamic Helper Text' : ''}
            shrinkOnEmptyLabel
          />
        </Example>

        <Example className={classes.section} label="Themes">
          <TextField
            customClasses={inputClass}
            value={this.state.field3}
            onChange={this.onTextChange('field3')}
            label=""
            placeholder="fgizkjs"
            variant="outlined"
          />
          <TextField
            customClasses={inputClass}
            value={this.state.field3}
            onChange={this.onTextChange('field3')}
            label="Location"
            helperText="Enter valid coordinates"
            variant="outlined"
            hasError
          />
          <TextField
            customClasses={inputClass}
            value={this.state.field3}
            onChange={this.onTextChange('field3')}
            // label="This is a label"
            placeholder="asdfasdfaf"
            variant="filled"
          />
          <TextField
            customClasses={inputClass}
            value={this.state.field3}
            onChange={this.onTextChange('field3')}
            helperText="Enter Input"
            variant="filled"
            hasError
          />
        </Example>

        <Example className={classes.section} label="Multiline">
          <TextField
            label="Multi-line"
            multiline
            rows={8}
            customClasses={inputClass}
            placeholder="Some placeholder"
          />
          <TextField
            label="Multi-line"
            multiline
            rows={8}
            customClasses={inputClass}
            variant="outlined"
          />
          <TextField
            label="Multi-line"
            multiline
            rows={8}
            customClasses={inputClass}
            variant="filled"
          />
        </Example>

        <Example className={classes.section} label="Location Input">
          <LocationInput
            value={this.state.location.formattedAddress}
            className={this.props.classes.input}
            onPlacesChanged={location => {
              this.setState({ location });
            }}
            onChange={location => {
              this.setState({ location });
            }}
          />
        </Example>
      </div>
    );
  }
}

export default withStyles(TextInputExample, styles);
