import React from 'react';

import Button from 'common/components/base/Button';

const CaptureSceneButton = ({ onClick, disabled, ...rest }) => (
  <Button
    theme="blue"
    size="small"
    onClick={onClick}
    disabled={disabled}
    data-testid="CaptureSceneButton"
    style={{ whiteSpace: 'nowrap' }}
    {...rest}
  >
    CAPTURE SCENE
  </Button>
);

export default CaptureSceneButton;
