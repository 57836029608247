import React, { Component } from 'react';
import injectSheet, { createUseStyles, styled } from 'react-jss';
import { connect } from 'react-redux';
import { startCase } from 'lodash';
import classnames from 'classnames';

import Button from 'common/components/base/Button';
import Typography from 'components/Typography';

import { selectCurrentUser } from 'auth/redux/selectors';
import {
  selectActiveModules,
  selectIsBrandingEnabled,
  selectIsGeoActive,
  selectIsLiveNGEnabled,
} from 'settings/redux/selectors';
import { selectIsLiveProcOnline } from 'app/header/mirageHealth/redux/selectors';
import { toggleAboutView } from 'app/redux/actions';
import { getVersion, getFrontendBuild } from 'common/api/appApi';
import { fetchUserInfo } from 'common/redux/models/actions';
import { parseToShorthandDate } from 'common/helpers/dateUtils';
import { FMV, GEO } from 'common/constants/app';
import { PRIMARY, PRIMARY_GREY, TEXT, YELLOW } from 'common/constants/colors';
import { CURRENT_USER_PARAM } from 'common/constants/parameters';
import MirageLogo from 'common/icons/mirage-logo';
import NeuralNetwork from 'common/icons/neural-network-icon-static';
import Tooltip from 'common/components/base/Tooltip';

const styles = theme => ({
  main: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.3)',
    position: 'fixed',
    zIndex: 1100,
    backgroundColor: 'rgba(44, 48, 50, 0.8)',
    overflowY: 'auto',
  },
  container: {
    maxWidth: 'fit-content',
    margin: theme.spacing(5, 'auto'),
    borderRadius: 3,
    backgroundColor: 'white',
    color: TEXT,

    '& header': {
      padding: theme.spacing(4, 6, 0, 6),
    },
  },
  body: {
    fontSize: 13,
    position: 'relative',
    padding: theme.spacing(4, 6, 3, 6),

    '& section': {
      marginBottom: theme.spacing(4),
    },
  },
  neuralNetwork: {
    '& > svg': {
      position: 'absolute',
      maxWidth: '100%',
      bottom: theme.spacing(-2),
      left: 0,
      pointerEvents: 'none',
    },
  },
  heading: {
    fontSize: 28,
    fontWeight: 400,
    color: '#78909c',
    marginBottom: 4,

    '& span': {
      fontFamily: '"Orbitron", sans-serif',
      color: PRIMARY,
    },
  },
  subheading1: {
    fontWeight: 500,
    color: PRIMARY_GREY,
    textTransform: 'uppercase',
  },
  subtext: {
    fontSize: 13,
    color: PRIMARY_GREY,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(0, 3, 3, 3),
  },
  warning: {
    background: YELLOW,
    textAlign: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  logo: {
    padding: theme.spacing(1, 0),
    maxWidth: theme.spacing(16),
  },
  genericLogo: {
    width: theme.spacing(16),
    height: theme.spacing(6),
  },
});

const INVALID_DATETIME = '— —';
const NOT_RUNNING = 'Not Running';

export function sortByRepository({ repository: repositoryA, repository: repositoryB }) {
  return repositoryA.localeCompare(repositoryB);
}

function formatTime(time) {
  if (!time) return INVALID_DATETIME;
  const parsedDateTime = parseToShorthandDate(time, { showDayOfWeek: true, showTimeZone: true });

  return parsedDateTime === 'Invalid date' ? INVALID_DATETIME : parsedDateTime;
}

const useCellStyles = createUseStyles(theme => ({
  root: {
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(1.5),
  },
}));

const TD = React.forwardRef((props, ref) => {
  const { className, ...rest } = props;
  const classes = useCellStyles();
  return <td ref={ref} className={classnames(classes.root, className)} {...rest} />;
});
const HeaderCell = styled(TD)({ paddingTop: props => props.theme.spacing(5) });
const TH = styled('th')({
  fontWeight: 400,
  paddingBottom: props => props.theme.spacing(1),
  color: PRIMARY_GREY,
  textAlign: 'left',
});

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      version: {
        backend: {},
        faceDet: {},
        faceRec: {},
        frontend: {},
        geosearch: {},
        geothumbnail: {},
        search: {},
        videnc: {},
        vidproc: {},
        macula: {},
        maculaintake: {},
        fileImporter: {},

        /*
          this is the image processing service, we will try to combine vidproc and imgproc together
          in the future, so the backend team came up with a "vague" name for now
        */
        processor: {},
      },
    };
  }

  componentDidMount() {
    this.setup();
  }

  setup = async () => {
    this.props.dispatchFetchUserInfo(CURRENT_USER_PARAM);
    const versionInfo = await getVersion();

    const { frontend, host, time } = await getFrontendBuild();

    const { frontendExpected } = versionInfo;
    if (frontendExpected?.commit === frontend.commit) {
      frontend.release = frontendExpected.release;
    }
    const version = {
      ...versionInfo,
      frontend: {
        ...frontend,
        host,
        time,
      },
    };

    this.setState({ version });
  };

  getBuildServices = version => {
    const { isLiveNGEnabled } = this.props;
    const liveProcessor = isLiveNGEnabled ? 'camcoder' : 'macula';

    const {
      backend: {
        release: backendRelease = '',
        branch: backendBranch = '',
        buildJobNumber: backendBuildNo = '',
        commit: backendCommit = '',
      } = {},
      faceDet: {
        release: faceDetRelease = '',
        branch: faceDetBranch = '',
        buildJobNumber: faceDetBuildNo = '',
        commit: faceDetCommit = '',
        host: faceDetHost = '',
        time: faceDetTime = '',
      } = {},
      faceRec: {
        release: faceRecRelease = '',
        branch: faceRecBranch = '',
        buildJobNumber: faceRecBuildNo = '',
        commit: faceRecCommit = '',
        host: faceRecHost = '',
        time: faceRecTime = '',
      } = {},
      frontend: {
        release: frontendRelease = '',
        branch: frontendBranch = '',
        buildJobNumber: frontendBuildNo = '',
        commit: frontendCommit = '',
        host: frontendHost,
        time: frontendTime,
      } = {},
      geosearch: {
        release: geosearchRelease = '',
        branch: geosearchBranch = '',
        buildJobNumber: geosearchBuildNo = '',
        commit: geosearchCommit = '',
        user: geosearchUser = '',
        time: geosearchTime = '',
      } = {},
      geothumbnail: {
        release: geothumbnailRelease = '',
        branch: geothumbnailBranch = '',
        buildJobNumber: geothumbnailBuildNo = '',
        commit: geothumbnailCommit = '',
        host: geothumbnailUser = '',
        time: geothumbnailTime = '',
      } = {},
      processor: {
        release: processorRelease = '',
        branch: processorBranch = '',
        buildJobNumber: processorBuildNo = '',
        commit: processorCommit = '',
        time: processorTime = '',
        user: processorUser = '',
      } = {},
      search: {
        release: searchRelease = '',
        branch: searchBranch = '',
        buildJobNumber: searchBuildNo = '',
        commit: searchCommit = '',
        time: searchTime = '',
        user: searchUser = '',
      } = {},
      videnc: {
        release: videncRelease = '',
        branch: videncBranch = '',
        buildJobNumber: videncBuildNo = '',
        commit: videncCommit = '',
        time: videncTime = '',
        user: videncUser = '',
      } = {},
      [liveProcessor]: {
        release: liveRelease = '',
        branch: liveBranch = '',
        buildJobNumber: liveBuildNo = '',
        user: liveUser = '',
        time: liveTime = '',
        commit: liveCommit = '',
      } = {},
      maculaintake: {
        release: maculaintakeRelease = '',
        branch: maculaintakeBranch = '',
        buildJobNumber: maculaintakeBuildNo = '',
        user: maculaintakeUser = '',
        time: maculaintakeTime = '',
        commit: maculaintakeCommit = '',
      } = {},
      fileImporter: {
        release: fileImporterRelease = '',
        branch: fileImporterBranch = '',
        buildJobNumber: fileImporterBuildNo = '',
        user: fileImporterUser = '',
        time: fileImporterTime = '',
        commit: fileImporterCommit = '',
      } = {},
      mint: {
        release: mintRelease = '',
        branch: mintBranch = '',
        buildJobNumber: mintBuildNo = '',
        user: mintUser = '',
        time: mintTime = '',
        commit: mintCommit = '',
      } = {},
      host,
      time,
    } = version;

    let services = [
      {
        modules: [FMV, GEO],
        repository: 'Backend',
        release: backendRelease,
        branch: backendBranch || NOT_RUNNING,
        commit: backendCommit,
        host,
        buildNo: backendBuildNo,
        buildDate: formatTime(time),
      },
      {
        modules: [FMV, GEO],
        repository: 'Frontend',
        release: frontendRelease,
        branch: frontendBranch || NOT_RUNNING,
        commit: frontendCommit,
        host: frontendHost,
        buildNo: frontendBuildNo,
        buildDate: formatTime(frontendTime),
      },
      {
        modules: [FMV],
        repository: 'Face Detection',
        release: faceDetRelease,
        branch: faceDetBranch || NOT_RUNNING,
        commit: faceDetCommit,
        host: faceDetHost,
        buildNo: faceDetBuildNo,
        buildDate: formatTime(faceDetTime),
      },
      {
        modules: [FMV],
        repository: 'Face Recognition',
        release: faceRecRelease,
        branch: faceRecBranch || NOT_RUNNING,
        commit: faceRecCommit,
        host: faceRecHost,
        buildNo: faceRecBuildNo,
        buildDate: formatTime(faceRecTime),
      },
      {
        modules: [FMV],
        repository: 'Search',
        release: searchRelease,
        branch: searchBranch || NOT_RUNNING,
        commit: searchCommit,
        host: searchUser,
        buildNo: searchBuildNo,
        buildDate: formatTime(searchTime),
      },
      {
        modules: [FMV],
        repository: 'Video Encoding',
        release: videncRelease,
        branch: videncBranch || NOT_RUNNING,
        commit: videncCommit,
        host: videncUser,
        buildNo: videncBuildNo,
        buildDate: formatTime(videncTime),
      },
      {
        modules: [FMV],
        repository: 'Data Processing',
        release: processorRelease,
        branch: processorBranch || NOT_RUNNING,
        commit: processorCommit,
        host: processorUser,
        buildNo: processorBuildNo,
        buildDate: formatTime(processorTime),
      },
      {
        modules: [FMV],
        repository: 'Live Processing',
        release: liveRelease,
        branch: liveBranch || NOT_RUNNING,
        commit: liveCommit,
        host: liveUser,
        buildNo: liveBuildNo,
        buildDate: formatTime(liveTime),
      },
      {
        modules: [FMV],
        repository: 'Live Processing Intake',
        release: maculaintakeRelease,
        branch: maculaintakeBranch || NOT_RUNNING,
        commit: maculaintakeCommit,
        host: maculaintakeUser,
        buildNo: maculaintakeBuildNo,
        buildDate: formatTime(maculaintakeTime),
      },
      {
        modules: [FMV],
        repository: 'File Importer',
        release: fileImporterRelease,
        branch: fileImporterBranch || NOT_RUNNING,
        commit: fileImporterCommit,
        host: fileImporterUser,
        buildNo: fileImporterBuildNo,
        buildDate: formatTime(fileImporterTime),
      },
      {
        modules: [FMV],
        repository: 'Mint',
        release: mintRelease,
        branch: mintBranch || NOT_RUNNING,
        commit: mintCommit,
        host: mintUser,
        buildNo: mintBuildNo,
        buildDate: formatTime(mintTime),
      },
    ];

    if (this.props.isGeoActive) {
      services.push(
        {
          modules: [GEO],
          repository: 'Geosearch',
          release: geosearchRelease,
          branch: geosearchBranch || NOT_RUNNING,
          commit: geosearchCommit,
          host: geosearchUser,
          buildNo: geosearchBuildNo,
          buildDate: formatTime(geosearchTime),
        },
        {
          modules: [GEO],
          repository: 'Geothumbnail',
          release: geothumbnailRelease,
          branch: geothumbnailBranch || NOT_RUNNING,
          commit: geothumbnailCommit,
          host: geothumbnailUser,
          buildNo: geothumbnailBuildNo,
          buildDate: formatTime(geothumbnailTime),
        }
      );
    }

    if (isLiveNGEnabled) {
      services = services.filter(service => service.repository !== 'Live Processing Intake');
    }

    return services.sort(sortByRepository);
  };

  getModelVersions = version => {
    const { isLiveProcOnline = false, isLiveNGEnabled = false } = this.props;
    const versionSource = isLiveProcOnline && !isLiveNGEnabled ? version.macula : version.processor;
    const {
      models: {
        facenetModel: {
          date: processorFacenetDate = '',
          name: processorFacenetName = '',
          id: processorFacenetId = '',
          hash: processorFacenetHash = '',
          version: processorFacenetVersion = '',
        } = {},
        faceDetectionModel: {
          date: processorFaceDetectionDate = '',
          name: processorFaceDetectionName = '',
          id: processorFaceDetectionId = '',
          hash: processorFaceDetectionHash = '',
          version: processorFaceDetectionVersion = '',
        } = {},
        objectDetectionModel: {
          date: processorObjectDetectionDate = '',
          name: processorObjectDetectionName = '',
          id: processorObjectDetectionId = '',
          hash: processorObjectDetectionHash = '',
          version: processorObjectDetectionVersion = '',
        } = {},
        vehicleRecognitionModel: {
          date: processorVehicleRecognitionDate = '',
          name: processorVehicleRecognitionName = '',
          id: processorVehicleRecognitionId = '',
          hash: processorVehicleRecognitionHash = '',
          version: processorVehicleRecognitionVersion = '',
        } = {},
        vehicleDetectionModel: {
          date: processorVehicleDetectionDate = '',
          name: processorVehicleDetectionName = '',
          id: processorVehicleDetectionId = '',
          hash: processorVehicleDetectionHash = '',
          version: processorVehicleDetectionVersion = '',
        } = {},
      } = {},
    } = versionSource;

    const models = [
      {
        modules: [FMV],
        type: 'Face Recognition',
        versionName: processorFacenetName,
        id: processorFacenetId || NOT_RUNNING,
        hash: processorFacenetHash || NOT_RUNNING,
        version: processorFacenetVersion || NOT_RUNNING,
        buildDate: processorFacenetDate || NOT_RUNNING,
      },
      {
        modules: [FMV],
        type: 'Face Detection',
        versionName: processorFaceDetectionName,
        id: processorFaceDetectionId || NOT_RUNNING,
        hash: processorFaceDetectionHash || NOT_RUNNING,
        version: processorFaceDetectionVersion || NOT_RUNNING,
        buildDate: processorFaceDetectionDate || NOT_RUNNING,
      },
      {
        modules: [FMV],
        type: 'Object Detection',
        versionName: processorObjectDetectionName,
        id: processorObjectDetectionId || NOT_RUNNING,
        hash: processorObjectDetectionHash || NOT_RUNNING,
        version: processorObjectDetectionVersion || NOT_RUNNING,
        buildDate: processorObjectDetectionDate || NOT_RUNNING,
      },
      {
        modules: [FMV],
        type: 'Vehicle Recognition',
        versionName: processorVehicleRecognitionName,
        id: processorVehicleRecognitionId || NOT_RUNNING,
        hash: processorVehicleRecognitionHash || NOT_RUNNING,
        version: processorVehicleRecognitionVersion || NOT_RUNNING,
        buildDate: processorVehicleRecognitionDate || NOT_RUNNING,
      },
      {
        modules: [FMV],
        type: 'Vehicle Detection',
        versionName: processorVehicleDetectionName,
        id: processorVehicleDetectionId || NOT_RUNNING,
        hash: processorVehicleDetectionHash || NOT_RUNNING,
        version: processorVehicleDetectionVersion || NOT_RUNNING,
        buildDate: processorVehicleDetectionDate || NOT_RUNNING,
      },
    ];

    return models;
  };

  isComponentsVersionsSame = services =>
    services &&
    services
      .filter(x => x.branch !== NOT_RUNNING)
      .every(x => this.serviceVersion(x) === this.serviceVersion(services[0]));

  toggleClosed = () => this.props.dispatchToggleAboutView(false);

  serviceVersion = service => {
    if (service.branch === NOT_RUNNING) {
      return NOT_RUNNING;
    }
    if (service?.release !== '') {
      return service.release;
    }
    return `SNAPSHOT (${service.branch})`;
  };

  mirageVersion = componentsVersion => {
    const namedRelease = this.props.isGeoActive
      ? this.state.version.geoRelease
      : this.state.version.release;

    if (namedRelease !== undefined && componentsVersion === namedRelease) {
      return namedRelease;
    }

    return 'SNAPSHOT';
  };

  renderServiceRow = service => (
    <tr key={service.repository}>
      <TD>{service.repository}</TD>
      <TD>{this.serviceVersion(service)}</TD>
      <TD>{service.commit}</TD>
      <TD>{service.buildDate}</TD>
    </tr>
  );

  renderModelRow = model => {
    const StyledRow = styled('tr')({ '&>td:not(:last-of-type)': { paddingRight: 77 } });
    const modelDate =
      model.buildDate !== NOT_RUNNING ? formatTime(model.buildDate) : model.buildDate;
    return (
      <StyledRow key={model.type}>
        <TD>{model.type}</TD>
        <Tooltip title={model.versionName}>
          <TD>{model.version}</TD>
        </Tooltip>
        <TD data-testid={`about-modelHash-${model.type}`}>{model.hash}</TD>
        <TD>{modelDate}</TD>
      </StyledRow>
    );
  };

  renderModelInfo = () => {
    const { activeModules, isLiveProcOnline, isLiveNGEnabled } = this.props;

    const models = this.getModelVersions(this.state.version);
    const buildModels = models.filter(
      model => model.modules.some(module => activeModules[module]) && model.id !== NOT_RUNNING
    );

    if (buildModels.length === 0) return null;

    const modelInfoHeaderText =
      isLiveProcOnline && !isLiveNGEnabled
        ? 'Live Processing Model Info'
        : 'Data Processing Model Info';

    return (
      <tbody>
        <tr>
          <HeaderCell colSpan="4" className={this.props.classes.subheading1}>
            {modelInfoHeaderText}
          </HeaderCell>
        </tr>
        <tr>
          <TH>Model</TH>
          <TH>Version</TH>
          <TH>Id</TH>
          <TH>Training Date</TH>
        </tr>
        {buildModels.map(this.renderModelRow)}
      </tbody>
    );
  };

  renderWarning = () => (
    <div className={this.props.classes.warning}>
      <Typography variant="body1">
        Version mismatch: Software components are not all at the same patch level. Please contact
        system administrator.
      </Typography>
    </div>
  );

  render() {
    const { activeModules, classes, isBrandingEnabled, isGeoActive, userModel } = this.props;
    const services = this.getBuildServices(this.state.version);
    const buildServices = services.filter(service =>
      service.modules.some(module => activeModules[module])
    );

    const isVersionMatch = this.isComponentsVersionsSame(buildServices);
    const componentsVersion = isVersionMatch ? this.serviceVersion(services[0]) : undefined;
    const mirageVersion = this.mirageVersion(componentsVersion);

    const licensedUser =
      userModel.firstName && userModel.lastName
        ? startCase(`${userModel.firstName} ${userModel.lastName}`)
        : userModel.username;

    return (
      <div className={classes.main} onClick={this.toggleClosed} role="none">
        <div className={classes.container} onClick={e => e.stopPropagation()} role="none">
          <header>
            <h3 className={classes.heading}>
              <span>Mirage™</span> Analyst Edition
            </h3>
            <p className={classes.subtext} data-testid="about-version">
              Version {mirageVersion}
            </p>
            {!isVersionMatch && this.renderWarning()}
          </header>
          <div className={classes.body}>
            <span className={classes.neuralNetwork}>
              <NeuralNetwork />
            </span>
            <section>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <TD colSpan="4" className={classes.subheading1}>
                      Build
                    </TD>
                  </tr>
                  <tr>
                    <TH>Component</TH>
                    <TH>Version</TH>
                    <TH>Id</TH>
                    <TH>Build Date</TH>
                  </tr>
                  {buildServices.map(this.renderServiceRow)}
                </tbody>
                {!isGeoActive && this.renderModelInfo()}
              </table>
            </section>
            <section>
              <h4 className={classes.subheading1}>Terms</h4>
              <p>
                Use of this product is subject to the terms of the percipient.ai End User Agreement,
                unless otherwise specified.
              </p>
            </section>
            <section>
              <h4 className={classes.subheading1}>Registered To</h4>
              <p>{licensedUser}</p>
            </section>

            <div>
              {isBrandingEnabled ? (
                <img className={classes.logo} src="/logo.png" alt="percipient.ai logo" />
              ) : (
                <MirageLogo className={classes.genericLogo} />
              )}
              <p className={classes.subtext}>
                &copy; {new Date().getFullYear()} percipient.ai &nbsp;&nbsp;&nbsp;
              </p>
            </div>
          </div>
          <footer className={classes.footer}>
            <Button onClick={this.toggleClosed} data-testid="About-close-button">
              CLOSE
            </Button>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeModules: selectActiveModules(state),
  isBrandingEnabled: selectIsBrandingEnabled(state),
  isGeoActive: selectIsGeoActive(state),
  userModel: selectCurrentUser(state),
  isLiveProcOnline: selectIsLiveProcOnline(state),
  isLiveNGEnabled: selectIsLiveNGEnabled(state),
});

const mapDispatchToProps = {
  dispatchFetchUserInfo: fetchUserInfo,
  dispatchToggleAboutView: toggleAboutView,
};

const WithProps = connect(mapStateToProps, mapDispatchToProps)(About);

export default injectSheet(styles)(WithProps);
