import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="88" height="45.5" viewBox="0 0 110 57">
    <g fill="none" fillRule="evenodd">
      <path d="M-9-36h128V92H-9z" />
      <path
        fill="#659ADE"
        d="M51.79 0L39.822 13.043h39.712L77.534 0H51.789zM5.812 1.734c-.821.07-1.524.693-1.624 1.546-.118.99.562 1.88 1.548 1.998l33.785 3.958 2.944-3.204-36.275-4.26c-.236-.028-.378-.038-.378-.038zm21.705 14.062L13.928 31.29h80.106l-.944-15.494H27.518zM9.578 34.079C4.255 34.08 0 39.16 0 45.518 0 51.875 4.26 57 9.578 57h90.807c5.319 0 9.615-5.13 9.615-11.482 0-6.352-4.292-11.439-9.615-11.439H9.578zm1.952 2.12c5.096 0 9.23 4.128 9.23 9.218a9.217 9.217 0 0 1-2.704 6.522 9.259 9.259 0 0 1-6.526 2.7 9.261 9.261 0 0 1-6.53-2.7 9.217 9.217 0 0 1-2.704-6.522c0-5.09 4.134-9.218 9.234-9.218zm43.651 0c5.097 0 9.23 4.128 9.23 9.218 0 5.089-4.134 9.217-9.23 9.222a9.261 9.261 0 0 1-6.53-2.7 9.213 9.213 0 0 1-2.699-6.522c0-5.09 4.133-9.218 9.23-9.218zm-21.743 0c5.096 0 9.23 4.128 9.23 9.218 0 5.089-4.134 9.217-9.23 9.222a9.261 9.261 0 0 1-6.53-2.7 9.213 9.213 0 0 1-2.699-6.522c0-5.09 4.133-9.218 9.23-9.218zm43.491 0c5.096 0 9.225 4.128 9.23 9.218 0 5.09-4.134 9.217-9.23 9.222a9.261 9.261 0 0 1-6.53-2.7 9.217 9.217 0 0 1-2.704-6.522c0-5.09 4.133-9.218 9.234-9.218zm22 0c5.096 0 9.225 4.128 9.23 9.218 0 5.09-4.134 9.217-9.23 9.222a9.261 9.261 0 0 1-6.53-2.7 9.217 9.217 0 0 1-2.704-6.522c0-5.09 4.133-9.218 9.234-9.218z"
      />
    </g>
  </svg>
);
