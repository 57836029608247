import get from 'lodash/get';
import { createSelector } from 'reselect';

import { DATASOURCE } from 'common/constants/app';
import { PLAN_ANALYSIS_ROUTE, PLAN_GEOSPATIAL_ROUTE } from 'common/constants/urls';

import { selectJobs } from 'library/redux/selectors';
import { selectActiveModules } from 'settings/redux/selectors';

import { selectIsAuthenticated, selectHasPercipientEmail } from 'auth/redux/selectors';

export const selectWsConnectionHealth = state => get(state, 'app.wsConnectionHealth');

export const selectLoggingError = state => get(state, 'app.loggingError');

export const selectIsNavExpanded = state => get(state, 'app.isNavExpanded');

export const selectShowAbout = state => get(state, 'app.showAbout');

export const selectShowProcessingList = state => get(state, 'app.showProcessingList');

export const selectNotifications = state => get(state, 'app.notifications');

export const selectGlobalJobs = state =>
  selectJobs(state, {
    match: {
      params: { type: DATASOURCE },
    },
  });

export const selectCanAccessExamples = createSelector(
  [selectIsAuthenticated, selectHasPercipientEmail],
  (isAuthenticated, isAdmin) =>
    (isAuthenticated && process.env.NODE_ENV !== 'production') || isAdmin
);

export const selectDefaultRoute = createSelector([selectActiveModules], activeModules => {
  if (activeModules.fmv) return PLAN_ANALYSIS_ROUTE;
  if (activeModules.geo) return PLAN_GEOSPATIAL_ROUTE;

  // The /api/modules response only comes back after authentication. If we dont know what
  // modules are active just return null and dont route them to the default page.
  return null;
});
