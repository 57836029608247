import { useCallback, useEffect, useMemo, useState } from 'react';
import { getLibraries } from 'common/api/libraryApi';
import { STATUS } from 'common/constants/api';
import { DATASOURCE, ROOT_DATASOURCE_KEY, ROOT_PERSON_KEY } from 'common/constants/app';
import { MY_LIBRARY_ID } from 'common/constants/urls';

export const LIMIT = 50;

const useLibraries = shouldFetch => {
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState(STATUS.IDLE);

  const normalized = useMemo(
    () =>
      response?.results?.reduce(
        (acc, v) => {
          acc.data[v.id] = v;
          acc.ids.push(v.id);
          return acc;
        },
        { data: {}, ids: [] }
      ) ?? { data: {}, ids: [] },
    [response]
  );

  const fetchLibraries = useCallback(
    async (params = { page: 1, limit: LIMIT }, omitLoading = false) => {
      try {
        if (!omitLoading) {
          setStatus(STATUS.PENDING);
        }
        setResponse(await getLibraries(params));
        setStatus(STATUS.SUCCESS);
      } catch (e) {
        setStatus(STATUS.FAILURE);
        setError(e.message);
      }
    },
    []
  );

  const { data, ids } = normalized;

  useEffect(() => {
    if (shouldFetch) {
      fetchLibraries();
    }
  }, [fetchLibraries, shouldFetch]);

  const getTopLevelFolderFromLibraryId = useCallback(
    (id, type = DATASOURCE) => {
      if (type === DATASOURCE) {
        return id === MY_LIBRARY_ID ? ROOT_DATASOURCE_KEY : data?.[id]?.topLevelDatasourceFolder;
      }

      return id === MY_LIBRARY_ID ? ROOT_PERSON_KEY : data?.[id]?.topLevelIdentityFolder;
    },
    [data]
  );

  return {
    error,
    status,
    data,
    total: response?.total,
    ids,
    fetchLibraries,
    isLoaded: status === STATUS.SUCCESS,
    isLoading: status === STATUS.PENDING,
    getTopLevelFolderFromLibraryId,
  };
};

export default useLibraries;
