import { capitalize, get } from 'lodash';
import { BACKGROUND_OBJECT_ID, FMV, GEO } from 'common/constants/app';
import { normalizeList } from './helperFunctions';

export function isObjectExperimental(object, module) {
  return object.experimental && object.experimental.includes(module);
}

export function isNameObject(name) {
  return name && name.startsWith(':');
}

export function getObjectAttributesFromName(name, sliceIndex = 1) {
  const split = name ? name.split('.') : [];
  return {
    /* Remove colon from object */
    name: capitalize(split[0].slice(sliceIndex)),
    color: split[1],
  };
}

export function prepareObjectModel(model) {
  return {
    ...model,
    reservedName: `:${model.name.split(' ').join('_').toLowerCase()}`,
  };
}

export function normalizeObjectModels(objectData, activeModules) {
  const fmvObjects = [];
  const geoObjects = [];

  const activeObjects = normalizeList(objectData, (acc, model) => {
    const { id, modules } = model;

    for (let i = 0; i < modules.length; i++) {
      const module = modules[i];
      if (module === FMV) fmvObjects.push(model);
      if (module === GEO) geoObjects.push(model);

      if (activeModules[module]) {
        acc[id] = prepareObjectModel(model);
      }
    }

    return acc;
  });

  return {
    fmvObjects,
    geoObjects,
    activeObjects,
  };
}

export function getObjectColor(object, objectId) {
  if (objectId === BACKGROUND_OBJECT_ID) return 'whitesmoke';

  return get(object, 'labelColor') || '';
}

export function getObjectName(object = {}, objectId) {
  if (objectId === BACKGROUND_OBJECT_ID) return 'Not a Vehicle';

  return get(object, 'displayName', '');
}

export function convertNameToConventionalFormat(name) {
  return name.trim().replace(/\s/g, '_').toLowerCase();
}
