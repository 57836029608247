import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-jss';
import noop from 'lodash/noop';

import { HEADER_HEIGHT } from 'common/constants/app';
import { LIBRARY_POPUP_HEADER, LIBRARY_POPUP_TEXT } from 'common/constants/colors';
import Typography from 'components/Typography';
import Tooltip from 'common/components/base/Tooltip';
import Button from 'common/components/base/Button';
import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';

const HeaderContainer = styled('div')({
  height: HEADER_HEIGHT,
  background: LIBRARY_POPUP_HEADER,
  color: LIBRARY_POPUP_TEXT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 16px',
  position: 'sticky',
  top: 0,
  zIndex: 100,
});

const HeaderText = styled('span')({
  alignItems: 'center',
  display: 'flex',
  height: HEADER_HEIGHT,
  justifyContent: 'center',
});

const DialogHeader = ({ button, children = null, headerText, onClose, showCloseIcon }) => {
  const getCloseIcon = () =>
    showCloseIcon && (
      <IconButton data-testid="DialogHeader-CloseIcon" onClick={onClose} theme="white">
        <Icon iconName="close" />
      </IconButton>
    );

  const getButton = () =>
    button && (
      <Tooltip disableHoverListener={!button.disabled} title={button.tooltip}>
        <div>
          <Button
            data-testid="DialogHeader-CommandButton"
            disabled={!!button.disabled}
            onClick={button.onClick}
          >
            {button.text}
          </Button>
        </div>
      </Tooltip>
    );

  return (
    <HeaderContainer>
      <HeaderText>
        {getCloseIcon()}
        <Typography data-testid="DialogHeader-HeaderText" variant="h4">
          {headerText}
        </Typography>
      </HeaderText>
      {getButton()}
      {children}
    </HeaderContainer>
  );
};

DialogHeader.propTypes = {
  button: PropTypes.shape({
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }),
  headerText: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

DialogHeader.defaultProps = {
  onClose: noop,
  showCloseIcon: true,
};

export default DialogHeader;
