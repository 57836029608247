import DriveEtaIcon from '@material-ui/icons/DriveEta';
import TrashContainer from '@material-ui/icons/Delete';

// Default icon served for custom objects.
import DefaultIcon from 'common/icons/ic-default';

// FMV Objects
import Bag from 'common/icons/ic-bag';
import Backpack from 'common/icons/ic-backpack';
import Bicycle from 'common/icons/ic-bicycle';
import Boat from 'common/icons/ic-boat';
import Bus from 'common/icons/ic-bus';
import Face from 'common/icons/ic-face';
import Gun from 'common/icons/ic-gun';
import Hat from 'common/icons/ic-hat';
import Knife from 'common/icons/ic-knife';
import Motorcycle from 'common/icons/ic-motorcycle';
import Person from 'common/icons/ic-person';
import Phone from 'common/icons/ic-phone';
import Plane from 'common/icons/ic-plane';
import Scissors from 'common/icons/ic-scissors';
import Train from 'common/icons/ic-train';
import Flag from 'common/icons/ic-flag';
import Suitcase from 'common/icons/ic-suitcase';
import HandBag from 'common/icons/ic-handbag';
import Truck from 'common/icons/ic-truck';
import Van from 'common/icons/ic-van';
import Tanks from 'common/icons/ic-tanks';
import OtherVehicle from 'common/icons/ic-other-vehicles';
import Hmmwv from 'common/icons/ic-hmmwv';
import Building from 'common/icons/ic-building';
import Suv from 'common/icons/ic-suv';
import MaritimeVehicle from 'common/icons/ic-ship';
import Ladder from 'common/icons/ic-ladder';
import TiffinBox from 'common/icons/ic-tiffin-box';

// Geospatial
import _2S1SP from 'common/icons/ic-2s1-sp';
import APC from 'common/icons/ic-armored-personnel-carrier';
import ArmoredVehicle from 'common/icons/ic-armored-vehicle';
import BM21 from 'common/icons/ic-bm-21';
import BMP from 'common/icons/ic-bmp';
import MilitaryTruck from 'common/icons/ic-military-truck';
import MTLB from 'common/icons/ic-mt-lb';
import Tank from 'common/icons/ic-battle-tank';
import TowedArtillery from 'common/icons/ic-towed-artillery';

export const FACE = 0;
export const PERSON = 4;

export const BUS = 1;
export const CAR = 2;
export const MOTORCYCLE = 3;
export const TRAIN = 5;
export const BAG = 6;
export const KNIFE = 7;
export const TRUCK = 8;
export const SCISSORS = 9;
export const PHONE = 10;
export const BACKPACK = 11;
export const SUITCASE = 12;
export const HANDBAG = 13;
export const FLAG = 14;
export const VAN = 15;
export const BUILDING = 16;
export const HMMWV = 17;
export const OTHER_VEHICLE = 18;
export const SUV = 19;
export const MARITIME_VEHICLE = 20;
export const _2S1_SP = 24;
export const BM_21 = 26;
export const TANK = 28;
export const ARMORED_PERSONNEL_CARRIER = 29;
export const ARMORED_VEHICLE = 30;
export const MILITARY_TRUCK = 31;
export const TOWED_ARTILLERY = 32;
export const TIFFIN_BOX = 41;
export const TRASH_CONTAINER = 42;
export const BICYCLE = 43;
export const LADDER = 44;
export const ALL_OBJECTS = '__all__';
export const ALL_MIRAGE_OBJECTS = '__all_mirage__';
export const ALL_CUSTOM_OBJECTS = '__all_custom__';
export const IDENTITY_TYPES = [FACE, CAR];

const OBJECT_ICONS = {
  [FACE]: {
    icon: Face,
    name: 'Face',
  },
  [BUS]: {
    icon: Bus,
    name: 'Bus',
  },
  [CAR]: {
    icon: DriveEtaIcon,
    name: 'Car',
  },
  [MOTORCYCLE]: {
    icon: Motorcycle,
    name: 'Motorcycle',
  },
  [PERSON]: {
    icon: Person,
    name: 'Person',
  },
  [TRAIN]: {
    icon: Train,
    name: 'Train',
  },
  [BAG]: {
    icon: Bag,
    name: 'Bag',
  },
  [KNIFE]: {
    icon: Knife,
    name: 'Knife',
  },
  [TRUCK]: {
    icon: Truck,
    name: 'Truck',
  },
  [SCISSORS]: {
    icon: Scissors,
    name: 'Scissors',
  },
  [PHONE]: {
    icon: Phone,
    name: 'Phone',
  },
  [BACKPACK]: {
    icon: Backpack,
    name: 'Backpack',
  },
  [SUITCASE]: {
    icon: Suitcase,
    name: 'Suitcase',
  },
  [HANDBAG]: {
    icon: HandBag,
    name: 'HandBag',
  },
  [FLAG]: {
    icon: Flag,
    name: 'Flag',
  },
  [VAN]: {
    icon: Van,
    name: 'Van',
  },
  [BUILDING]: {
    icon: Building,
    name: 'Building',
  },
  [HMMWV]: {
    icon: Hmmwv,
    name: 'Hmmwv',
  },
  [OTHER_VEHICLE]: {
    icon: OtherVehicle,
    name: 'Other Vehicle',
  },
  [SUV]: {
    icon: Suv,
    name: 'SUV',
  },
  [MARITIME_VEHICLE]: {
    icon: MaritimeVehicle,
    name: 'Maritime Vehicle',
  },
  [BICYCLE]: {
    icon: Bicycle,
    name: 'Bicycle',
  },
  [LADDER]: {
    icon: Ladder,
    name: 'Ladder',
  },
  Boat: {
    icon: Boat,
    name: 'Boat',
  },
  Gun: {
    icon: Gun,
    name: 'Gun',
  },
  Hat: {
    icon: Hat,
    name: 'Hat',
  },
  Plane: {
    icon: Plane,
    name: 'Plane',
  },
  Tanks: {
    icon: Tanks,
    name: 'Tanks',
  },
  [TRASH_CONTAINER]: {
    icon: TrashContainer,
    name: 'Trash Container',
  },
  [TIFFIN_BOX]: {
    icon: TiffinBox,
    name: 'Tiffin Box',
  },

  // Geospatial
  [_2S1_SP]: {
    icon: _2S1SP, // eslint-disable-line react/jsx-pascal-case
    name: '',
  },
  25: {
    icon: MTLB,
    name: '',
  },
  [BM_21]: {
    icon: BM21,
    name: '',
  },
  27: {
    icon: BMP,
    name: 'BMP',
  },
  [TANK]: {
    icon: Tank,
    name: 'Tank',
  },
  [ARMORED_PERSONNEL_CARRIER]: {
    icon: APC,
    name: 'APC',
  },
  [ARMORED_VEHICLE]: {
    icon: ArmoredVehicle,
    name: 'Armored Vehicle',
  },
  [MILITARY_TRUCK]: {
    icon: MilitaryTruck,
    name: 'Military Truck',
  },
  [TOWED_ARTILLERY]: {
    icon: TowedArtillery,
    name: 'Towed Artillery',
  },
};

export function serveObjectIcon(objectId) {
  if (OBJECT_ICONS[objectId]) {
    return OBJECT_ICONS[objectId].icon;
  }

  return DefaultIcon;
}

export function getObjectName(objectId) {
  if (OBJECT_ICONS[objectId]) {
    return OBJECT_ICONS[objectId].name;
  }

  return '';
}
