import { renderAlert } from 'app/redux/actions';
import { WARNING } from 'app/redux/constants';

const DEFAULT_ACCESS_TYPE = 'offline';
const DEFAULT_RESPONSE_TYPE = 'code';
const DEFAULT_SCOPE = 'openid%20email%20profile';
const DEFAULT_REDIRECT_URI = window.location.origin;

export function getAuthorizationURL(
  baseURL,
  {
    access_type: accessType = DEFAULT_ACCESS_TYPE,
    client_id: clientId = process.env.CLIENT_ID,
    redirect_uri: redirectUri = DEFAULT_REDIRECT_URI,
    response_type: responseType = DEFAULT_RESPONSE_TYPE,
    scope = DEFAULT_SCOPE,
  } = {}
) {
  let url = `${baseURL}?client_id=${clientId}`;

  if (accessType) url += `&access_type=${accessType}`;
  if (redirectUri) url += `&redirect_uri=${redirectUri}`;
  if (responseType) url += `&response_type=${responseType}`;
  if (scope) url += `&scope=${scope}`;

  return url;
}

export function setAuthError(error) {
  return localStorage.setItem('authError', error);
}

export function checkAuthError() {
  const error = localStorage.getItem('authError');

  if (error) {
    setAuthError('');
    renderAlert(error, { title: 'Unauthorized', type: WARNING });
  }
}
