import { BBOX, POINT } from './lib/leaflet-bounding-boxes/constants';

export { BBOX, POINT };

export const LEAFLET_COMMENT = 'leaflet_comment';
export const LEAFLET_DRAW = 'leaflet_draw';
export const LEAFLET_FILTERS = 'leaflet_filters';
export const LEAFLET_PAN = 'leaflet_pan';
export const LEAFLET_SEARCH = 'leaflet_search';
export const LEAFLET_MEASURE = 'leaflet_measure';
export const LEAFLET_MULTI_SELECT = 'leaflet_multiselect';
export const LEAFLET_MODIFY_BOXES = 'leaflet_modifyboxes';

export const MIN_ZOOM = 4;
export const MAX_ZOOM = 22;
export const MAX_NATIVE_ZOOM = 20;
export const ZOOM_LEVEL_20 = 20;
export const ZOOM_LEVEL_RENDER_GEOIMAGES = 10;
export const ZOOM_LEVEL_RENDER_DETECTIONS = 18;

/* Shortcuts for Geomap */
export const GEOMAP_CANCEL_KEY = 'Escape';
export const GEOMAP_FILTERS_KEY = 'e';
export const GEOMAP_PAN_KEY = 'p';
export const GEOMAP_SEARCH_KEY = 'g';
export const GEOMAP_DRAW_KEY = 'd';
export const GEOMAP_COMMENT_KEY = 'a';
export const GEOMAP_OSMLAYER_KEY = 'o';
export const GEOMAP_GEOLAYER_KEY = 'h';
export const GEOMAP_TILELAYER_KEY = 't';
export const GEOMAP_TOGGLE_LATLNG_UNITS_KEY = 'l';
export const GEOMAP_MEASURE_KEY = 'm';
export const GEOMAP_MULTI_SELECT_KEY = 's';
export const GEOMAP_MODIFY_BOXES = 'v';

export const GEOMAP_DISABLED_TOOL_SHORTCUTS = {
  [GEOMAP_FILTERS_KEY]: true,
  [GEOMAP_PAN_KEY]: true,
  [GEOMAP_SEARCH_KEY]: true,
  [GEOMAP_DRAW_KEY]: true,
  [GEOMAP_COMMENT_KEY]: true,
  [GEOMAP_TOGGLE_LATLNG_UNITS_KEY]: true,
  [GEOMAP_MEASURE_KEY]: true,
  [GEOMAP_MULTI_SELECT_KEY]: true,
  [GEOMAP_MODIFY_BOXES]: true,
};

export const GEOMAP_TOOL_MAPPINGS = {
  [LEAFLET_PAN]: GEOMAP_PAN_KEY,
  [LEAFLET_SEARCH]: GEOMAP_SEARCH_KEY,
  [LEAFLET_DRAW]: GEOMAP_DRAW_KEY,
  [LEAFLET_COMMENT]: GEOMAP_COMMENT_KEY,
  [LEAFLET_MEASURE]: GEOMAP_MEASURE_KEY,
  [LEAFLET_MULTI_SELECT]: GEOMAP_MULTI_SELECT_KEY,
  [LEAFLET_MODIFY_BOXES]: GEOMAP_MODIFY_BOXES,
};

/* Events */
export const GEOJSON_ADDED = 'geoJSON:added';

export const LEAFLET_DRAW_TOOLTIP = 'Click and drag to draw rectanlge.';
export const LEAFLET_DRAW_WARNING_TOOLTIP = 'Select an object prior to drawing.';
