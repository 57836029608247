import { useCallback, useEffect, useRef } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet-draw';

function usePolygonEditControl(props) {
  const { onCreated, onDeleted, onDrawStart, onEdited } = props;
  const { layerContainer, map } = useLeafletContext();
  const drawControlRef = useRef();

  const onDrawCreate = useCallback(
    e => {
      const container = layerContainer || map;
      container.addLayer(e.layer);
      if (onCreated) onCreated(e);
    },
    [onCreated]
  );

  const eventHandlers = {
    [L.Draw.Event.CREATED]: onDrawCreate,
    [L.Draw.Event.EDITED]: e => {
      if (onEdited) onEdited(e);
    },
    [L.Draw.Event.DELETED]: () => {
      if (onDeleted) onDeleted();
    },
    [L.Draw.Event.DRAWSTART]: () => {
      if (onDrawStart) onDrawStart();
    },
  };

  // Add event listeners
  useEffect(() => {
    Object.keys(eventHandlers).forEach(key => {
      map.on(key, eventHandlers[key]);
    });

    return () => {
      Object.keys(eventHandlers).forEach(key => {
        map.off(key, eventHandlers[key]);
      });
    };
  }, [map]);

  // Create controls
  useEffect(() => {
    if (!drawControlRef.current) {
      drawControlRef.current = new L.Control.Draw({
        draw: {
          circle: false,
          circlemarker: false,
          marker: false,
          polyline: false,
        },
        edit: {
          featureGroup: layerContainer,
        },
      });
      map.addControl(drawControlRef.current);
    }
  }, []);
}

export default usePolygonEditControl;
