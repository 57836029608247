export const calculateNewTransformValue = (panAndZoomEl, newContainerEl) => {
  const { width: pazWidth, height: pazHeight } = panAndZoomEl.getBoundingClientRect();
  const {
    width: newContainerWidth,
    height: newContainerHeight,
  } = newContainerEl.getBoundingClientRect();
  const [scaledWidth, scaledHeight] = panAndZoomEl
    .getAttribute('data-scaleddimensions')
    .split(',')
    .map(n => parseFloat(n, 10));
  const [scale, xTranslate, yTranslate] = panAndZoomEl?.style?.transform
    .split(/[ ,]+|[a-z]|\(|\)/)
    .filter(Boolean)
    .map(n => parseFloat(n, 10));

  const scaleFactor = Math.min(newContainerWidth / scaledWidth, newContainerHeight / scaledHeight);

  const pazWidthDiff = pazWidth / scale - scaledWidth;
  const pazHeightDiff = pazHeight / scale - scaledHeight;

  const newContainerWidthDiff = newContainerWidth - scaledWidth * scaleFactor;
  const newContainerHeightDiff = newContainerHeight - scaledHeight * scaleFactor;

  const newX =
    (xTranslate / (pazWidth / scale - pazWidthDiff)) * (newContainerWidth - newContainerWidthDiff);
  const newY =
    (yTranslate / (pazHeight / scale - pazHeightDiff)) *
    (newContainerHeight - newContainerHeightDiff);

  return `scale(${scale}) translate(${newX}px, ${newY}px)`;
};
