import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import DateTime from 'dateTime';

import Popover from '@material-ui/core/Popover';

import { getAbsoluteTimeFromDateString, parseToShorthandDate } from 'common/helpers/dateUtils';
import { ERROR } from 'common/constants/colors';

import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';
import TextInput from 'common/components/base/TextInput';
import Typography from 'components/Typography';
import DateTimeRangePicker from 'common/components/pickers/DateTimeRangePicker';

const styles = theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    transition: 'all 0.5s',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  dash: {
    transition: 'all 0.5s',
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
  },
  root: {
    fontSize: 11,
  },
  rootWarning: {
    fontSize: 11,
    color: ERROR,
  },
  input: {
    transition: 'all 0.5s',
    width: props => (props.showSeconds ? 140 : 120),
    minWidth: props => (props.showSeconds ? 140 : 120),
    fontSize: 11,
  },
  label: {
    transition: 'all 0.5s',
    position: 'absolute',
  },
  hidden: {
    opacity: 0,
  },
});

function isValidDate(date, showSeconds) {
  const luxonFormat = showSeconds ? 'dd LLL yyyy, HH:mm:ss' : 'dd LLL yyyy, HH:mm';
  const parsedDate = DateTime.fromFormat(date, luxonFormat);

  return date === '' || parsedDate.isValid;
}

class DateTimeRangePickerInput extends React.Component {
  static defaultProps = {
    TextInputProps: {},
    expanded: true,
    dateRange: [],
    minTimespan: 1000,
    showSeconds: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      ...this.getDate(this.props.startDate, this.props.endDate),
      invalidStartDate: false,
      invalidEndDate: false,
    };
  }

  componentDidUpdate({ startDate: prevStart, endDate: prevEnd }, prevState) {
    const startHasChanged = Number.isNaN(prevStart)
      ? Number.isNaN(prevStart) !== Number.isNaN(this.props.startDate)
      : prevStart !== this.props.startDate;
    const endHasChanged = Number.isNaN(prevEnd)
      ? Number.isNaN(prevEnd) !== Number.isNaN(this.props.endDate)
      : prevEnd !== this.props.endDate;

    if (startHasChanged || endHasChanged) {
      this.updateDates();
    }

    if (
      this.props.setHasInvalidInput &&
      (prevState.invalidStartDate !== this.state.invalidStartDate ||
        prevState.invalidEndDate !== this.state.invalidEndDate)
    ) {
      this.props.setHasInvalidInput(this.state.invalidStartDate || this.state.invalidEndDate);
    }
  }

  updateDates = () => {
    const dates = { ...this.getDate(this.props.startDate, this.props.endDate) };
    const invalidStartDate = !isValidDate(dates.start, this.props.showSeconds);
    const invalidEndDate = !isValidDate(dates.end, this.props.showSeconds);
    this.setState({
      start: dates.start,
      end: dates.end,
      invalidStartDate,
      invalidEndDate,
    });
  };

  getDate = (start, end) => ({
    start: this.parseDate(start),
    end: this.parseDate(end),
  });

  parseDate = (time, unsetResult = '') =>
    time ? parseToShorthandDate(time, { showSeconds: this.props.showSeconds }) : unsetResult;

  //  Don't allow them to select a time range of less than the minimum
  adjustEndTimestampIfNeeded = (startTimestamp, endTimestamp) => {
    const shouldAdjust =
      startTimestamp &&
      endTimestamp &&
      Math.abs(endTimestamp - startTimestamp) < this.props.minTimespan;

    return shouldAdjust ? startTimestamp + this.props.minTimespan : endTimestamp;
  };

  finishEditing = () =>
    this.setState(({ start, end }) => {
      const startTime = getAbsoluteTimeFromDateString(start);
      const endTime = this.adjustEndTimestampIfNeeded(
        startTime,
        getAbsoluteTimeFromDateString(end)
      );

      //  Flip the dates if they are getting silly
      const needToFlip = endTime && startTime && startTime > endTime;
      const startDate = needToFlip ? endTime : startTime;
      const endDate = needToFlip ? startTime : endTime;
      const dates = { ...this.getDate(startDate, endDate) };
      if (start && !dates.start) dates.start = start;
      if (end && !dates.end) dates.end = end;

      const invalidStartDate = !isValidDate(dates.start, this.props.showSeconds);
      const invalidEndDate = !isValidDate(dates.end, this.props.showSeconds);

      if (startDate && endDate) this.onChange({ startDate, endDate });

      return {
        start: dates.start,
        end: dates.end,
        invalidStartDate,
        invalidEndDate,
      };
    });

  onKeyPressStart = e => {
    if (e.key === 'Enter') {
      this.finishEditing();
    }
  };

  onKeyPressEnd = e => {
    if (e.key === 'Enter') {
      this.finishEditing();
    }
  };

  open = e => this.setState({ open: true, anchorEl: e.currentTarget });

  close = () => this.setState({ open: false, anchorEl: null });

  onChange = async ({ startDate, endDate }) => {
    await this.props.onChange({
      startDate,
      endDate: this.adjustEndTimestampIfNeeded(startDate, endDate),
    });
  };

  onChangeStart = e => this.setState({ start: e.target.value });

  onChangeEnd = e => this.setState({ end: e.target.value });

  getWidth = () => (this.props.expanded ? (this.props.showSeconds ? 300 : 260) : 64);

  render() {
    const { start, end, invalidStartDate, invalidEndDate } = this.state;
    const { classes, className, dateRange, showPresent, TextInputProps } = this.props;

    const { InputProps = {}, ...restTextInputProps } = TextInputProps;
    const hideIfExpanded = { [classes.hidden]: this.props.expanded };
    const showIfExpanded = { [classes.hidden]: !this.props.expanded };
    const startDateInputClass = invalidStartDate ? classes.rootWarning : classes.root;
    const endDateInputClass = invalidEndDate ? classes.rootWarning : classes.root;
    const placeholderDate = DateTime.fromJSDateWithTZ().toFormat('dd LLL yyyy');

    return (
      <div className={classes.main}>
        <IconButton onClick={this.open} data-testid="DateTimeRangePickerInput-DateRangeButton">
          <Icon iconName="date_range" theme="grey" size="small" />
        </IconButton>
        <div className={classes.body} style={{ width: this.getWidth() }}>
          <Typography
            className={classnames(hideIfExpanded, classes.label)}
            data-testid="DateTimeRangePickerInput-DateRange"
            variant="body2"
          >
            Date Range
          </Typography>
          <TextInput
            className={classnames(showIfExpanded, classes.input, className)}
            data-testid="DateTimeRangePickerInput-TextInputStart"
            InputProps={{
              ...InputProps,
              classes: {
                root: startDateInputClass,
                ...InputProps.classes,
              },
            }}
            onBlur={this.finishEditing}
            onChange={this.onChangeStart}
            onKeyPress={this.onKeyPressStart}
            placeholder={this.parseDate(dateRange[0], `Start: ${placeholderDate}`)}
            shrinkOnEmptyLabel
            value={start}
            {...restTextInputProps}
          />
          <Typography className={classnames(showIfExpanded, classes.dash)} variant="body1">
            —
          </Typography>
          <TextInput
            className={classnames(showIfExpanded, classes.input, className)}
            data-testid="DateTimeRangePickerInput-TextInputEnd"
            InputProps={{
              ...InputProps,
              classes: {
                root: endDateInputClass,
                ...InputProps.classes,
              },
              inputProps: {
                ref: node => (this.endDate = node),
                ...InputProps.inputProps,
              },
            }}
            onBlur={this.finishEditing}
            onChange={this.onChangeEnd}
            onKeyPress={this.onKeyPressEnd}
            placeholder={this.parseDate(dateRange[1], `End: ${placeholderDate}`)}
            shrinkOnEmptyLabel
            value={showPresent && !end && !!start ? 'Present' : end}
            {...restTextInputProps}
          />
        </div>
        <Popover
          open={this.state.open}
          onClose={this.close}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <DateTimeRangePicker
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            onChange={this.onChange}
            onClose={this.close}
            dateRange={dateRange}
          />
        </Popover>
      </div>
    );
  }
}

export default injectSheet(styles)(DateTimeRangePickerInput);
