import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 66" {...props}>
    <path
      fill="#6599DE"
      fillRule="evenodd"
      d="M87.007 23.895c-2.999 0-5.699.599-8.1 1.495l-6.903-11.351 2.098-5.08h.9c1.8 0 2.998-1.192 2.998-2.984S76.802 2.99 75.002 2.99h-9c-1.8 0-2.999 1.193-2.999 2.985 0 1.792 1.199 2.985 2.999 2.985h1.502l-1.199 2.984H39V0H23.997v5.975h9v8.36l-5.4 10.752c-2.098-.896-4.196-1.192-6.598-1.192C9.3 23.895 0 33.15 0 44.799s9.299 20.904 21 20.904c11.7 0 20.998-9.256 20.998-20.904 0-7.168-3.6-13.44-9-17.024l3.6-6.87L55.5 48.08h10.8C67.803 58.233 76.5 66 87.002 66 98.7 66 108 56.743 108 45.096c.006-11.945-9.293-21.201-20.993-21.201zM36 45.195c0 8.434-6.65 15.066-15.12 15.066-8.464 0-15.12-6.626-15.12-15.065 0-8.434 6.65-15.066 15.12-15.066 1.208 0 2.721.3 3.929.605l-6.65 13.257c-.3.3-.3.6-.3 1.204 0 1.807 1.207 3.01 3.021 3.01 1.208 0 2.115-.604 2.721-1.807l6.65-13.258C33.58 36.158 36 40.377 36 45.196zm38.88-16.397c-4.109 3.204-6.954 7.57-7.903 12.81H61.92l8.223-20.086 4.737 7.276zm1.302 5.637l4.458 8.608H72c.56-3.509 2.232-6.378 4.182-8.608zm-19.676 2.87L41.76 17.216H64.8l-8.294 20.087zm30.46 22.956c-7.332 0-13.443-5.124-14.966-12.049l14.965-.006c1.833 0 3.053-1.203 3.053-3.01 0-.605-.304-1.204-.613-1.509l-7.634-12.653c1.529-.604 3.362-.904 5.194-.904 8.551 0 15.275 6.626 15.275 15.066-.006 8.433-6.724 15.065-15.275 15.065z"
    />
  </SvgIcon>
);
