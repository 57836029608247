import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsGeoActive } from 'settings/redux/selectors';
import { selectAreJobsInProgress } from 'library/redux/selectors';
import { selectGlobalProcessingPanelOpen } from 'library/common/globalProcessingPanel/redux/selectors';
import CircularLoader from 'common/components/base/CircularLoader';
import Tooltip from 'common/components/base/Tooltip';

const JobProcessingIcon = () => {
  const isGlobalProcessingPanelOpen = useSelector(selectGlobalProcessingPanelOpen);
  const jobsInProgress = useSelector(selectAreJobsInProgress);
  const isGeoActive = useSelector(selectIsGeoActive);

  return (
    <Tooltip placement="right" title="Processing Panel" showTooltip={!isGlobalProcessingPanelOpen}>
      <>
        <CircularLoader
          disableShrink={!isGeoActive && jobsInProgress}
          value={100}
          size={22}
          thickness={4}
          variant={!isGeoActive && jobsInProgress ? 'indeterminate' : 'determinate'}
        />
      </>
    </Tooltip>
  );
};

export default JobProcessingIcon;
