import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PlusIconWhite = ({ fill, ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" fontSize="small" viewBox="0 0 14 14" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path d="M-5-5h24v24H-5z" />
      <path fill="#FFF" fillRule="nonzero" d={fill || 'M14 8H8v6H6V8H0V6h6V0h2v6h6z'} />
    </g>
  </SvgIcon>
);

export default PlusIconWhite;
