import defaultAxios from 'axios';
import axios from 'axios.js';
import { ANALYTICS_BACKEND, LOGOUT, OPEN_ID_CONFIG_URL } from 'common/constants/urls';

export function revokeToken() {
  return axios.get(`${ANALYTICS_BACKEND}${LOGOUT}/`);
}

export function tokenRequest({ username, password }) {
  return axios
    .post(`${ANALYTICS_BACKEND}/token-auth/`, {
      username,
      password,
    })
    .then(({ data, headers }) => {
      if (headers.location) return headers;
      return data;
    });
}

export function getOpenIDConfig(url) {
  /* default axios will not contain our token information, we don't want to send our token when we don't need to */
  return defaultAxios.get(`${url}${OPEN_ID_CONFIG_URL}`).then(({ data }) => data);
}

export function getOpenIDAuth() {
  return defaultAxios.get(`${ANALYTICS_BACKEND}/oidc-auth/`).then(({ data }) => data);
}

export function postOpenIDAuth({ authorizationCode }) {
  return defaultAxios
    .post(`${ANALYTICS_BACKEND}/oidc-auth/`, { authorizationCode })
    .then(({ data, headers }) => {
      // Handle redirect based on location header for non-analyst roles
      if (headers.location) return headers;
      return data;
    });
}

export function refreshOpenIDToken() {
  return axios.post(`${ANALYTICS_BACKEND}/oidc-auth/refresh/`).then(({ data }) => data);
}
