import { batchActions } from 'redux-batched-actions';
import lodashSortBy from 'lodash/sortBy';
import get from 'lodash/get';
import DateTime from 'dateTime';

import { setIsLoadingGroupBy } from 'analysis/redux/searchBar/actions';
import {
  defaultFetchCameras,
  fetchDatasourcesById,
  mergePersonModels,
  setPersonModels,
} from 'common/redux/models/actions';
import { selectDatasourceModels } from 'common/redux/models/selectors';
import {
  getSearchRequests,
  getSearchRequestResults,
  getSearchRequestResultGroups,
  getSearchRequestResultFrames,
  getSearchRequestResultTimelineData,
} from 'common/api/searchRequest';
import { postConfirmIdentity, deleteConfirmIdentity, getPersons } from 'common/api/personApi';
import { getImageDetails, getInspectModeImageDetections } from 'common/api/analysisImageApi';

/* importing from library redux because it requires some hacky code that I don't want to replicate */
import { libraryFetchDetectionObjects } from 'library/redux/object/actions';
import { fetchSinglePerson } from 'library/redux/person/actions';

import { getDetectionTimeRange } from 'common/components/videoPlayer/utils/utils';
import { renderAlert, renderError } from 'app/redux/actions';
import { normalizeList } from 'common/helpers/helperFunctions';
import { DS_TYPE_IMAGE, DS_TYPE_VIDEO } from 'common/constants/app';
import { OBJECT_TYPES } from 'analysis/siftView/constants';

import { extractDatasourceIds } from './utils';
import {
  DEFAULT_LIMIT,
  GROUP_BY_DATASOURCE,
  GROUP_BY_VIDEO,
  GROUP_BY_DAY,
  GROUP_BY_FOLDER,
  SEARCH_FINISHED,
  SEARCH_PENDING,
} from './constants';

export const setShowAllSearches = showAllSearches => ({
  type: 'ANALYSIS/SET_SHOW_ALL_SEARCHED',
  payload: showAllSearches,
});

const setLoadingHistory = isLoading => ({
  type: 'ANALYSIS/SET_LOADING_HISTORY',
  payload: isLoading,
});

const setSearchRequestHistory = data => ({
  type: 'ANALYSIS/SET_SEARCH_REQUEST_HISTORY',
  payload: data,
});

export const setSearchRequest = request => ({
  type: 'ANALYSIS/SET_SEARCH_REQUEST',
  payload: { request },
});

export const setSearchRequestId = requestId => ({
  type: 'ANALYSIS/SET_SEARCH_REQUEST_ID',
  payload: { id: requestId },
});

export const setMapConfig = (mapConfig = {}) => ({
  type: 'ANALYSIS/SET_MAP_CONFIG',
  payload: { mapConfig },
});

const setGroupByKey = groupBy => ({
  type: 'ANALYSIS/SET_GROUP_BY',
  payload: { groupBy },
});

export function setAnalysisIsSubmitting(isSubmitting) {
  return {
    type: `ANALYSIS/SET_ANALYSIS_IS_SUBMITTING`,
    payload: isSubmitting,
  };
}

export const setGroupByFilter = groupByFilter => ({
  type: 'ANALYSIS/SET_GROUP_BY_FILTER',
  payload: { groupByFilter },
});

const setSelectedResultVideoId = videoId => ({
  type: 'ANALYSIS/SET_SELECTED_RESULT_VIDEO_ID',
  payload: { video: videoId },
});

const setSelectedResultImageId = (imageId = null) => ({
  type: 'ANALYSIS/SET_SELECTED_RESULT_IMAGE_ID',
  payload: { image: imageId },
});

export const setSelectedRelatedImageId = (imageId = null) => ({
  type: 'ANALYSIS/SET_SELECTED_RELATED_IMAGE_ID',
  payload: { image: imageId },
});

export const setSelectedResultTimestamp = timestamp => ({
  type: 'ANALYSIS/SET_SELECTED_RESULT_TIMESTAMP',
  payload: { timestamp },
});

export const setHighlightedResults = (highlight = []) => ({
  type: 'ANALYSIS/SET_HIGHLIGHTED_RESULTS',
  payload: { highlight },
});

const setSearchResultsMeta = (results, limit, offset, total) => ({
  type: 'ANALYSIS/SET_SEARCH_RESULTS_META',
  payload: { results, limit, offset, total },
});

const setSearchResultGroupsMeta = (results, limit, offset, total) => ({
  type: 'ANALYSIS/SET_SEARCH_RESULTS_GROUP_META',
  payload: { results, limit, offset, total },
});

export const setSearchStatus = status => ({
  type: 'ANALYSIS/SET_SEARCH_STATUS',
  payload: { searchStatus: status },
});

const setSearchResultsFrames = (results, allRangeStart, allRangeEnd) => ({
  type: 'ANALYSIS/SET_VIDEO_PLAYER_SEARCH_RESULTS',
  payload: {
    results,
    allRangeStart: allRangeStart || null,
    allRangeEnd: allRangeEnd || null,
  },
});

export const setVideoAllDetections = (allDetections, allRangeStart, allRangeEnd) => ({
  type: 'ANALYSIS/SET_ALL_VIDEO_DETECTIONS',
  payload: {
    allDetections: allDetections || [],
    allRangeStart: allRangeStart || null,
    allRangeEnd: allRangeEnd || null,
  },
});

export const setVideoTimelineData = timelineData => ({
  type: 'ANALYSIS/SET_VIDEO_TIMELINE_DATA',
  payload: { timelineData },
});

const setImageDetails = imageDetails => ({
  type: 'ANALYSIS/SET_IMAGE_DETAILS',
  payload: imageDetails,
});

const setInspectModeDetections = detections => ({
  type: 'ANALYSIS/SET_INSPECT_MODE_DETECTIONS',
  payload: detections,
});

/* ========================= THUNK ACTION CREATORS ========================== */

export const fetchPersons = params => dispatch =>
  getPersons(params).then(data => {
    const persons = normalizeList(data);
    dispatch(setPersonModels(persons));
    return data;
  });

export const initAnalysisHistory = params => dispatch =>
  getSearchRequests(params).then(data => {
    const actions = [setLoadingHistory(false), setSearchRequestHistory(data)];
    // TODO: Implement pagination for the Analysis History view so there are
    // fewer datasources to fetch on page load
    const dsIds = extractDatasourceIds(data);

    return Promise.all([
      dispatch(fetchDatasourcesById(dsIds)),
      dispatch(defaultFetchCameras()),
      dispatch(libraryFetchDetectionObjects()),
      dispatch(fetchPersons()),
    ]).then(() => {
      dispatch(batchActions(actions));
    });
  });

export const fetchAnalysisHistory = params => dispatch =>
  getSearchRequests(params).then(data => {
    const actions = [setLoadingHistory(false), setSearchRequestHistory(data)];
    dispatch(batchActions(actions));
  });

export function resetAnalysis() {
  return batchActions([{ type: 'ANALYSIS/RESET' }, setSearchRequest({})]);
}

export const extractAndPostPerson = ({ detection, folder, id, iid, name, t, vid }) => dispatch => {
  const personData = { ...detection, image_id: iid, n: name, t, v: vid };
  if (id) personData.i = id;
  if (folder) personData.f = folder;

  return postConfirmIdentity(personData).then(person => {
    dispatch(mergePersonModels({ [person.id]: person }));
    return person;
  });
};

export const setSelectedResult = (resultType, mediaId, options) => (dispatch, getState) => {
  const currentResultVideoId = getState().analysis.main.selectedResultVideoId;
  const actions = [];
  if (!mediaId) {
    actions.push(setSelectedResultVideoId(null));
    actions.push(setSelectedResultTimestamp(null));
    actions.push(setSelectedResultImageId(null));
    actions.push(setSelectedRelatedImageId(null));
  } else if (resultType === DS_TYPE_IMAGE) {
    actions.push(setSelectedResultVideoId(null));
    actions.push(setSelectedResultTimestamp(null));
    actions.push(setSelectedResultImageId(mediaId));
    actions.push(setSelectedRelatedImageId(null));
  } else if (resultType === DS_TYPE_VIDEO) {
    actions.push(setSelectedResultVideoId(mediaId));
    actions.push(setSelectedResultTimestamp(options.timestamp));
    actions.push(setSelectedResultImageId(null));
    actions.push(setSelectedRelatedImageId(null));
  }
  if (options.clearHighlightedResults) {
    actions.push(setHighlightedResults([]));
  }

  if (actions.length) dispatch(batchActions(actions));

  if (resultType === DS_TYPE_VIDEO && mediaId) {
    const { searchRequestId } = getState().analysis.main;

    dispatch(fetchSearchDetectionsInRange(mediaId, options.timestamp));

    const params = { limit: 1000 };

    if (currentResultVideoId !== mediaId) {
      return getSearchRequestResultTimelineData(searchRequestId, mediaId, params).then(data => {
        data.segmentGroups.forEach(group => {
          group.segments = lodashSortBy(group.segments, 'fromTime');
        });

        dispatch(setVideoTimelineData(data.segmentGroups));
      });
    }
  }
};

export const fetchSearchResults = (offset, groupType, omitLoading = false) =>
  function fetchSearchResultsThunk(dispatch, getState) {
    const state = getState();
    const {
      groupBy,
      groupByFilter,
      searchResultsLimit,
      searchResultsOffset,
      searchRequestId,
      searchStatus,
    } = state.analysis.main;
    const dsModels = selectDatasourceModels(state);

    if (!omitLoading && searchStatus !== SEARCH_PENDING) {
      dispatch(setSearchStatus(SEARCH_PENDING));
    }

    const params = {
      limit: searchResultsLimit || DEFAULT_LIMIT,
      offset: offset === undefined ? searchResultsOffset : offset,
    };

    const groupByType = groupType || groupBy;
    if (
      groupByFilter &&
      // TODO: This dsModels[groupByFilter] logic is similar to the
      // logic used in SearchResultsGroup to determine groupType, which is
      // eventually passed to this function. However, fetchSearchResults is
      // called in more places than just SearchResultsGroup, and we should not
      // have to calculate groupType at each callsite. Clean up to calculate
      // groupType here instead of threading through from SearchResultsGroup
      (dsModels[groupByFilter] || groupByType === GROUP_BY_VIDEO)
    ) {
      params.datasourceId = groupByFilter;
    } else if (groupByFilter && groupByType === GROUP_BY_FOLDER) {
      params.folderId = groupByFilter;
    } else if (groupByFilter && groupByType === GROUP_BY_DAY) {
      if (groupByFilter !== 'Unknown') {
        params.day = `${groupByFilter}(${DateTime.fromJSDateWithTZ().toFormat('ZZZ')})`;
      } else {
        params.day = groupByFilter;
      }
    }

    return dispatch(fetchResults(searchRequestId, params));
  };

function fetchResults(searchRequestId, params) {
  return dispatch =>
    getSearchRequestResults(searchRequestId, params).then(data => {
      const { items, pagination } = data;
      dispatch(
        batchActions([
          setSearchStatus(SEARCH_FINISHED),
          setSearchResultsMeta(items, params.limit, params.offset, pagination.totalCount),
        ])
      );

      return items;
    });
}

function fetchResultGroups(searchRequestId, params) {
  return dispatch =>
    getSearchRequestResultGroups(searchRequestId, params).then(data => {
      const { searchResultGroups, pagination } = data;

      dispatch(
        batchActions([
          setSearchStatus(SEARCH_FINISHED),
          setSearchResultGroupsMeta(
            searchResultGroups,
            params.limit,
            params.offset,
            pagination.totalCount
          ),
        ])
      );

      return data;
    });
}

export const fetchSearchResultGroups = offset => (dispatch, getState) => {
  const {
    searchResultsLimit,
    searchResultsOffset,
    searchRequestId,
    groupBy,
  } = getState().analysis.main;

  let groupByParam = groupBy;
  if (groupBy === GROUP_BY_DAY) {
    groupByParam = `${groupBy}(${DateTime.now().toFormat('ZZZ')})`;
  } else if (groupBy === GROUP_BY_FOLDER) {
    groupByParam = `${GROUP_BY_FOLDER},${GROUP_BY_DATASOURCE}`;
  }

  const params = {
    groupBy: groupByParam,
    limit: searchResultsLimit || DEFAULT_LIMIT,
    offset: offset === undefined ? searchResultsOffset : offset,
  };

  dispatch(setSearchStatus(SEARCH_PENDING));

  return dispatch(fetchResultGroups(searchRequestId, params));
};

export const setGroupBy = groupBy => dispatch => {
  dispatch(batchActions([setIsLoadingGroupBy(true), setGroupByKey(groupBy)]));

  const promise = groupBy ? dispatch(fetchSearchResultGroups(0)) : dispatch(fetchSearchResults(0));

  return promise.then(() => dispatch(setIsLoadingGroupBy(false)));
};

export const setGroupBySelectedFilter = (groupByFilter, groupType) => dispatch => {
  dispatch(setGroupByFilter(groupByFilter));

  return groupByFilter
    ? dispatch(fetchSearchResults(0, groupType))
    : dispatch(fetchSearchResultGroups(0));
};

// Refesh after confirming an identity. Pull page data from reducer
export const refreshSearchResults = omitLoading =>
  function refreshSearchResultsThunk(dispatch, getState) {
    const { groupByFilter, groupBy } = getState().analysis.main;

    if (groupBy && !groupByFilter) {
      return dispatch(fetchSearchResultGroups());
    }

    return dispatch(fetchSearchResults(undefined, undefined, omitLoading));
  };

export const clearSearchResults = () => (dispatch, getState) => {
  const { searchResultsLimit, searchResultsOffset } = getState().analysis.main;

  return dispatch(
    batchActions([
      setSearchStatus(SEARCH_FINISHED),
      setSearchResultsMeta([], searchResultsLimit, 0, 0),
      setSearchResultGroupsMeta([]),
      setSearchResultsFrames([], searchResultsLimit || DEFAULT_LIMIT, searchResultsOffset || 0, 0),
      setVideoAllDetections(null),
      setGroupByKey(null),
      setGroupByFilter(null),
      setSelectedResultVideoId(null),
      setSelectedResultTimestamp(null),
    ])
  );
};

export function createConfirmedIdentity(
  datasourceId,
  imageId,
  matchData,
  objectType = OBJECT_TYPES.PEOPLE
) {
  return function createConfirmIdentityThunk(dispatch) {
    const { id, bbox, embedding, name, thumb, tu } = matchData;
    const data = {
      v: datasourceId,
      e: embedding,
      i: id,
      n: name,
      tu,
      image_id: imageId,
      objectType,
      t: thumb ? thumb.timestamp : null,
      h: thumb ? thumb.box.height : bbox.height,
      w: thumb ? thumb.box.width : bbox.width,
      x: thumb ? thumb.box.x : bbox.x,
      y: thumb ? thumb.box.y : bbox.y,
    };

    return postConfirmIdentity(data)
      .then(() => dispatch(fetchSinglePerson(matchData.id)))
      .then(() => dispatch(refreshSearchResults(true)))
      .catch(e => {
        console.error(get(e, 'response.data.message'));
        renderError({
          code: 'identity confirmation',
          severity: 'error',
          resolution: 'Please contact system andministrator.',
          message: 'There was a problem communicating with the backend',
        });
      });
  };
}

export function removeConfirmedIdentity(matchData) {
  return dispatch => {
    if (!matchData.confirmedThumbHash) {
      renderAlert(`Cannot unconfirm an exact match`);
      return Promise.reject();
    }

    const data = {
      id: matchData.id,
      fh: matchData.confirmedThumbHash,
    };

    return deleteConfirmIdentity(data)
      .then(() => dispatch(fetchSinglePerson(matchData.id)))
      .then(() => dispatch(refreshSearchResults(true)));
  };
}

export function fetchSearchDetectionsInRange(videoId, timestamp) {
  return (dispatch, getState) => {
    const { searchRequestId } = getState().analysis.main;

    const params = {
      timestamp: Math.round(timestamp),
      framesBefore: 50,
      framesAfter: 300,
    };

    return getSearchRequestResultFrames(searchRequestId, videoId, params).then(response => {
      const { frames } = response;
      const { start, end } = getDetectionTimeRange(
        frames,
        params.timestamp,
        params.framesBefore,
        params.framesAfter,
        'timestamp'
      );
      dispatch(setSearchResultsFrames(frames, start, end));
    });
  };
}

export const fetchImageDetails = id => dispatch =>
  getImageDetails({ ids: id, details: true })
    .then(details => {
      if (details[0]) {
        dispatch(setImageDetails({ [details[0].id]: details[0] }));
        return details[0];
      }

      return {};
    })
    .catch(() => {
      dispatch(setImageDetails({ [id]: {} }));
      return {};
    });

export const fetchInspectModeImageDetections = id => dispatch =>
  getInspectModeImageDetections(id)
    .then(details => {
      dispatch(setInspectModeDetections({ [id]: details }));
      return details;
    })
    .catch(() => dispatch(setInspectModeDetections({ [id]: {} })));
