import store from 'redux/store';

import SEVERITY from 'app/notifications/constants';
import { ERROR, NOTIFICATION } from './constants';

export function setProcessingListView(isOpen) {
  return {
    type: 'APP/SET_SHOW_PROCESSING_IMAGES_VIEW',
    payload: isOpen,
  };
}

export function setWsConnectionHealth(status) {
  return {
    type: 'APP/SET_WS_CONNECTION_HEALTH',
    payload: status,
  };
}

export function toggleAboutView(isOpen) {
  return {
    type: 'APP/TOGGLE_ABOUT_VIEW',
    payload: isOpen,
  };
}

export function toggleHamburgerMenu() {
  return {
    type: 'APP/TOGGLE_HAMBURGER_MENU',
  };
}

export function renderErrorReport(errorReport) {
  return {
    type: 'APP/SET_ERROR_REPORT',
    errorReport,
  };
}

function setNotifications(notifications) {
  return {
    type: 'APP/SET_NOTIFICATIONS',
    notifications,
  };
}

export function renderAlert(alert, { type = ERROR, title = '', details = '' } = {}) {
  return store.dispatch({
    type: 'APP/SET_ALERT_DIALOG',
    payload: { alert, type, title, details },
  });
}

export function setLoggingError(loggingError) {
  return {
    type: 'APP/SET_LOGGING_ERROR',
    payload: loggingError,
  };
}

/**
  @param error {object} requires at least:
    @param error.code {string}
    @param error.message {string}
    @param error.resolution {string}
*/
export function renderError(error) {
  error.type = ERROR;
  error.id = error.id ?? error.code;
  return store.dispatch(addNotification(error));
}

export const renderErrorMessage = message => renderError({ message, severity: SEVERITY.ERROR });

export function renderNotification(notification) {
  notification.type = NOTIFICATION;
  notification.id = notification.id ?? notification.code;
  return store.dispatch(addNotification(notification));
}

export function renderGenericNotification({ type, notification }) {
  notification.type = type;
  return store.dispatch(addNotification(notification));
}

export function addNotification(notification) {
  return (dispatch, getState) => {
    const notifications = [...getState().app.notifications];
    const index = notifications.findIndex(({ id }) => id === notification.id);

    if (index >= 0) {
      notifications.splice(index, 1, notification);
    } else {
      notifications.push(notification);
    }

    return dispatch(setNotifications(notifications));
  };
}

export function removeNotification(notification) {
  return (dispatch, getState) => {
    const notifications = [...getState().app.notifications];
    const idx = notifications.findIndex(({ id }) => id === notification.id);

    if (idx === -1) return;
    notifications.splice(idx, 1);

    return dispatch(setNotifications(notifications));
  };
}
