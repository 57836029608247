import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import MiragePopoverMenu from 'common/components/miragePopoverMenu/MiragePopoverMenu';
import { MIRAGE_HEALTH_UNKNOWN, MIRAGE_HEALTH_GOOD } from 'common/constants/app';
import { selectIsLiveNGEnabled } from 'settings/redux/selectors';
import { selectWsConnectionHealth } from 'app/redux/selectors';

import * as S from './redux/selectors';
import { getConfig, getMirageConnectivity } from './utils';

const useStyles = createUseStyles({
  main: {
    height: 'min-content',
    width: 290,
  },
});

const MirageHealthDialog = props => {
  const classes = useStyles();
  const health = useSelector(S.selectMirageHealth);
  const responseTime = useSelector(S.selectHealthResponseTime);
  const searchStatus = useSelector(S.selectHealthSearchStatus);
  const isLiveNGEnabled = useSelector(selectIsLiveNGEnabled);

  const internetStatus = window.PERCIPIENT_IS_ONLINE ? MIRAGE_HEALTH_GOOD : MIRAGE_HEALTH_UNKNOWN;
  const mirageStatus = getMirageConnectivity(responseTime);
  const wsHealth = useSelector(selectWsConnectionHealth);

  return (
    <MiragePopoverMenu
      clickable={false}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      header="Mirage™ Health Check"
      config={getConfig(
        mirageStatus,
        internetStatus,
        searchStatus,
        health,
        wsHealth,
        isLiveNGEnabled
      )}
      classes={{ paper: classes.main }}
      data-testid="MirageHealthIcon-miragePopoverMenu"
      {...props}
    />
  );
};

export default MirageHealthDialog;
