import React from 'react';

import CustomTimePickerView from './CustomTimePickerView';
import PickerToolbar, { DATE, TIME, YEAR } from './PickerToolbar';
import Calendar from './Calendar';
import YearSelection from './YearSelection';

class CustomDateTimePicker extends React.PureComponent {
  static defaultProps = {
    autoSubmit: true,
    ampm: true,
    openTo: DATE,
    onViewChange: () => {},
  };

  state = {
    openView: this.props.openTo,
  };

  componentDidMount() {
    if (this.props.bindRef) this.props.bindRef(this);
  }

  changeView = (nextView, isFinish = true) => {
    if (isFinish && this.props.autoSubmit) {
      this.handleViewChange(nextView);
    }
  };

  onViewChange = (nextView, isFinish = true) => {
    this.props.onViewChange(nextView);
    this.handleViewChange(nextView, isFinish);
  };

  handleViewChange = view => {
    this.setState({ openView: view });
  };

  handleYearChange = date => {
    this.props.onYearChange(date);
    this.changeView(DATE, true);
  };

  handleDayChange = date => {
    this.props.onDateChange(date);
  };

  handleTimeChange = date => {
    this.props.onTimeChange(date);
  };

  render() {
    const { openView } = this.state;
    const {
      animateYearScrolling,
      date,
      disablePast,
      disableFuture,
      maxDate,
      minDate,
      onMonthChange,
      shouldDisableDate,
      reverseYearOrder,
      selectByDefault,
    } = this.props;

    const calendarProps = {
      date,
      minDate,
      maxDate,
      disablePast,
      disableFuture,
      onMonthChange,
      shouldDisableDate,
      onChange: this.handleDayChange,
    };

    const CalendarComponent = this.props.renderCalendar ? (
      this.props.renderCalendar(calendarProps)
    ) : (
      <Calendar {...calendarProps} />
    );

    return (
      <React.Fragment>
        <PickerToolbar date={date} openView={openView} onViewChange={this.onViewChange} />

        {openView === YEAR && (
          <YearSelection
            date={date}
            minDate={minDate}
            maxDate={maxDate}
            onChange={this.handleYearChange}
            disablePast={disablePast}
            disableFuture={disableFuture}
            animateYearScrolling={animateYearScrolling}
            reverseYearOrder={reverseYearOrder}
          />
        )}
        {openView === DATE && CalendarComponent}
        {openView === TIME && (
          <CustomTimePickerView
            time={date}
            style={{ minHeight: 256 }}
            onChange={this.handleTimeChange}
            selectByDefault={selectByDefault}
          />
        )}
      </React.Fragment>
    );
  }
}

export default CustomDateTimePicker;
