import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from 'tss-react-local';
import Typography from 'components/Typography';
import Icon from 'common/components/base/Icon';
import Avatar from 'common/components/base/Avatar';
import LogoutIcon from 'common/icons/logout';

import MiragePopoverMenu from 'common/components/miragePopoverMenu/MiragePopoverMenu';

import { PRIMARY_GREY, DARK_BORDER } from 'common/constants/colors';
import { toggleAboutView } from 'app/redux/actions';
import { getUserAbbreviation } from 'common/helpers/userUtils';
import { signout } from 'auth/redux/actions';
import { selectCurrentUsername } from 'auth/redux/selectors';
import { selectCanAccessExamples } from 'app/redux/selectors';

const useStyles = makeStyles()({
  paper: {
    minWidth: 148,
  },
  menu: {
    backgroundColor: 'white',
    borderRadius: 3,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    backgroundColor: '#e7effa',
    borderRadius: '3px 3px 0 0',
    fontSize: 11,
    fontWeight: 300,
    padding: '0 20px',
  },
  user: {
    height: 50,
    minHeight: 50,
    borderBottom: `1px solid ${DARK_BORDER}`,
  },
  avatar: {
    height: 22,
    width: 22,
    fontSize: 13,
  },
});

const ProfileMenu = ({ children, onClose, open, anchorEl, ...rest }) => {
  const username = useSelector(selectCurrentUsername);
  const canAccessExamples = useSelector(selectCanAccessExamples);

  const dispatch = useDispatch();
  const { classes } = useStyles();
  const history = useHistory();

  const config = [
    {
      key: 'user',
      label: '',
      items: [
        {
          key: 'user',
          icon: (
            <Avatar customClasses={{ root: classes.avatar }}>
              {getUserAbbreviation(username)}
            </Avatar>
          ),
          label: username,
          className: classes.user,
          clickable: false,
        },
        {
          key: 'about',
          label: 'About Mirage',
          icon: <Icon iconName="info_outlined" theme="grey" />,
          onClick: () => {
            dispatch(toggleAboutView(true));
            onClose();
          },
          'data-testid': 'ProfileMenu-aboutMirage',
        },
        ...(canAccessExamples
          ? [
              {
                key: 'examples',
                label: 'Component Library',
                icon: <Icon iconName="info_outlined" theme="grey" />,
                onClick: () => {
                  history.push('/examples');
                  onClose();
                },
              },
            ]
          : []),
        {
          key: 'signout',
          label: 'Signout',
          icon: <LogoutIcon style={{ fill: PRIMARY_GREY }} />,
          onClick: () => dispatch(signout()),
          'data-testid': 'ProfileMenu-logout',
        },
      ],
    },
  ];

  return (
    <MiragePopoverMenu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      classes={{ paper: classes.paper }}
      config={config}
      dense={false}
      header={<Typography variant="body2">Logged in as</Typography>}
      onClose={onClose}
      open={open}
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      {...rest}
    />
  );
};

export default ProfileMenu;
