import { get, isEmpty, sumBy } from 'lodash';

import { IMAGE, VIDEO } from 'common/constants/app';
import {
  CANCELED,
  CANCELING,
  COMPLETED,
  FAILED,
  PROC_SVC,
  UPLOAD_FAILED,
  VID_ENC,
  VIDEO_PROC,
  PROC_OFFLINE,
  ACCEPTED,
  ENQUEUED,
  ASC,
} from 'library/redux/constants';

import { LIGHT_BLACK, ERROR as ERROR_COLOR } from 'common/constants/colors';

import { hasPaused, shouldRenderCancelIcon } from 'library/redux/datasource/utils';
import { getStartDate } from 'library/redux/utils';

import {
  PRIORITY,
  UPLOAD_GROUP,
  DATASOURCE,
  DESTINATION,
  UPLOADED_BY,
  UPLOADED_DATE,
  JOB_GRP_LEVEL,
  JOB_GRP_DETAIL_LEVEL,
  MAX_PAGINATED_JOB_COUNT,
  FILE_SIZE,
  DB_DATA_SOURCE_NAME,
  DB_FILE_SIZE,
  DB_PRIORITY,
} from './constants';

export function getTextColor(status) {
  switch (status) {
    case FAILED:
    case UPLOAD_FAILED:
      return ERROR_COLOR;
    default:
      return LIGHT_BLACK;
  }
}

export function isJobGroupLevel(columnId) {
  return [UPLOAD_GROUP, DESTINATION, UPLOADED_BY, UPLOADED_DATE].includes(columnId);
}

export function shouldRenderActionButtons(tableType) {
  return tableType !== JOB_GRP_LEVEL;
}

export function shouldRenderCancel({ status, config, encodingInfo, processingInfo }) {
  if (hasPaused(status) || !config) {
    return false;
  }

  const isVideoFile = config.mediaType === VIDEO || config.videoFilename;

  if (
    isVideoFile &&
    isEmpty(encodingInfo) &&
    isEmpty(processingInfo) &&
    !shouldRenderCancelIcon(status)
  ) {
    return false;
  }

  if (
    isVideoFile &&
    !shouldRenderCancelIcon(encodingInfo.status) &&
    !shouldRenderCancelIcon(processingInfo.status)
  ) {
    return false;
  }
  return !(config.mediaType === IMAGE && !shouldRenderCancelIcon(status));
}

export function getJobs(jobGroups, isProcOnline) {
  return jobGroups.map(jobGroup => {
    const updatedGroup = sortJobs(jobGroup); // Temporary hack until the BE does sorting jobs inside job groups to avoid re-rendering
    const { jobs } = updatedGroup;
    let job;
    if (jobs.length > 1) {
      // For videos there will be an encoding and processing job
      const encodingJob = prepareChildJob(
        jobs.find(elem => elem.type === VID_ENC),
        isProcOnline
      );
      const processingJob = prepareChildJob(
        jobs.find(elem => elem.type === PROC_SVC),
        isProcOnline
      );
      job = { ...encodingJob, ...processingJob };
    } else {
      job = prepareChildJob(jobs[0], isProcOnline);
    }
    job.ordinalPriority = jobGroup.ordinalPriority;
    job.encodingInfo = job.encodingInfo || {};
    job.processingInfo = job.processingInfo || {};
    job.status = jobGroup.status;
    return job;
  });
}

export function computeDataSourceCount(batchObj) {
  return (batchObj.batches || []).reduce((sum, group) => sum + computeStatusCount(group), 0);
}

export function getUploadGroupName(jobGroup) {
  const uploadStartTime = jobGroup
    ? getStartDate(jobGroup.displayInfo.uploadTs || jobGroup.displayInfo.clientUploadStartTs, true)
    : '';
  return jobGroup
    ? jobGroup.displayInfo.batchName || jobGroup.displayInfo.uploadFolderNames || uploadStartTime
    : '';
}

export function computeCompletedJobsCount(jobGroup) {
  return get(jobGroup, 'statusCounts.completed', 0);
}

export function shouldRenderViewAllJobsText(tableType, rowInfo = {}) {
  return (
    tableType === JOB_GRP_DETAIL_LEVEL &&
    rowInfo.jobsCount &&
    rowInfo.jobsCount > MAX_PAGINATED_JOB_COUNT
  );
}

export function isJobRetrying(statusInfo) {
  const hasRetryCounter = get(statusInfo, 'retryCounter', 0) > 0;
  return (statusInfo.status === ACCEPTED || statusInfo.status === ENQUEUED) && hasRetryCounter;
}

export function getEncodingInfo(job) {
  const encodingInfo = job.type === VID_ENC ? createStatusInfo(job) : null;

  if (encodingInfo) {
    const progress = getEncodingProgress(job);

    if (progress !== null) {
      return {
        ...encodingInfo,
        percentProgress: job.status === COMPLETED ? 100 : progress,
      };
    }
  }

  return encodingInfo;
}

export function getProcessingInfo(job) {
  const processingInfo =
    job.type === PROC_SVC || job.type === VIDEO_PROC // (VIDEO_PROC - for backward compatability ).
      ? createStatusInfo(job)
      : null;

  if (processingInfo) {
    const progress = getProcessingProgress(job);
    if (progress !== null) {
      return {
        ...processingInfo,
        percentProgress: job.status === COMPLETED ? 100 : progress,
      };
    }
  }

  return processingInfo;
}

export function getSortedByField(sortBy) {
  if (sortBy.endsWith(DB_DATA_SOURCE_NAME)) return DATASOURCE;
  if (sortBy.endsWith(DB_FILE_SIZE)) return FILE_SIZE;
  if (sortBy.endsWith(DB_PRIORITY)) return PRIORITY;
  return '';
}

export function sortByOrdinalPriority(jobs, sortDirection) {
  return sortDirection === ASC ? sortByOrdinalPriorityAsc(jobs) : sortByOrdinalPriorityDsc(jobs);
}

function sortByOrdinalPriorityAsc(jobs) {
  return jobs.sort((a, b) => b.ordinalPriority - a.ordinalPriority);
}

function sortByOrdinalPriorityDsc(jobs) {
  return jobs.sort((a, b) => a.ordinalPriority - b.ordinalPriority);
}

// TODO: Temporary hack to avoid re-rendering.
function sortJobs(jobGroup) {
  if (jobGroup && jobGroup.jobs.length <= 1) return jobGroup;
  const sortedJobs = jobGroup.jobs.sort((a, b) => a.type.localeCompare(b.type));
  return {
    ...jobGroup,
    jobs: sortedJobs,
  };
}

export function computeStatusCount(statusCounts) {
  return sumBy(Object.values(get(statusCounts, 'statusCounts', {})));
}

export function hasFailedJobs(statusCounts) {
  const failedJobCount = get(statusCounts, 'failed', 0);
  return failedJobCount > 0;
}

function createStatusInfo(job) {
  return {
    status: job.status,
    type: job.type,
    percentProgress: job.percentProgress,
    retryCounter: job.retryCounter,
  };
}

function getEncodingProgress(job) {
  return job.type === VID_ENC ? job.percentProgress : null;
}
function getProcessingProgress(job) {
  return job.type === PROC_SVC ? job.percentProgress : null;
}

function prepareChildJob(job, isProcOnline) {
  if (job === undefined) {
    return {};
  }

  if (!isProcOnline) {
    job.status = PROC_OFFLINE;
    return job;
  }

  if (job.config && job.config.mediaType === IMAGE) {
    if (job.status === COMPLETED) {
      job.percentProgress = 100;
    }
    return job;
  }

  const encodingInfo = getEncodingInfo(job);
  if (encodingInfo) {
    job.encodingInfo = encodingInfo;
  }

  const processingInfo = getProcessingInfo(job);
  if (processingInfo) {
    job.processingInfo = processingInfo;
  }

  if (job.status === CANCELED || job.status === CANCELING) {
    job.percentProgress = 0;
  }

  return job;
}
