import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from 'common/components/base/Menu';

const IdentityTileMenu = ({ className, onClick, onClose, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton className={className} onClick={e => setAnchorEl(e.target)}>
        <MoreHorizIcon htmlColor="white" />
      </IconButton>
      <Menu
        open={!!anchorEl}
        onClose={e => {
          setAnchorEl(null);
          if (onClose) onClose(e);
        }}
        anchorEl={anchorEl}
        {...rest}
      />
    </>
  );
};

IdentityTileMenu.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default IdentityTileMenu;
