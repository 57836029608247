import React from 'react';

export default ({ style: { width = 16, height = 16, color = '#FD716A' } = {}, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 22 22"
    {...rest}
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="10" cy="10" r="10" fill={color} stroke="#FFF" strokeWidth=".5" />
      <path
        fill="#FFF"
        d="M14.5 6.4l-.9-.9L10 9.1 6.4 5.5l-.9.9L9.1 10l-3.6 3.6.9.9 3.6-3.6 3.6 3.6.9-.9-3.6-3.6z"
      />
    </g>
  </svg>
);
