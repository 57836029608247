import { get, isEmpty } from 'lodash';
/**
 * Returns an Object with proper params
 * @return {Object} Object with following properties
 * @property q {String} Query for the search (required)
 * @property r {String} Refresh
 * @property e {String} Exclused videos delimited by ',' (deprecated)
 * @property v {String} Included videos delimited by ','
 * @property t {String} Confidence Threshold in percentage
 * @property st {String} Start time for search
 * @property et {String} End time for search
 */

const isResubmittingZones = zones => zones[0]?.hasOwnProperty('zone');

const createSearchRequestParams = ({ threshold = 5, searchQuery, useCache }) => {
  const {
    datasourceIds,
    cameraIds,
    query,
    folderIds,
    zones,
    minLingerMillis,
    geofence,
    searchType,
  } = searchQuery;

  /* startDate and endDate are already aboslute timestamps */
  const startDate = get(searchQuery, 'filters.startDate');
  const endDate = get(searchQuery, 'filters.endDate');
  const params = {};

  if (searchType) params.search_type = searchType;
  if (cameraIds) params.c = cameraIds;
  if (startDate) params.st = String(startDate);
  if (endDate) params.et = String(endDate);
  if (datasourceIds) params.v = datasourceIds;
  if (folderIds) params.folders = folderIds;
  if (zones) {
    params.zones = isResubmittingZones(zones)
      ? zones
      : zones.map(zone => ({ zone: zone.map(([x, y]) => ({ point: { x, y } })) }));
  }
  if (!isEmpty(geofence)) {
    params.geofence = geofence;
  }

  if (minLingerMillis) params.linger_ms = minLingerMillis;

  params.q = query;
  params.t = String(threshold);

  if (!useCache) params.r = true;

  return params;
};

export default createSearchRequestParams;
