export const PREFIX = 'Mirage - ';

export const ANALYSIS_HISTORY = `${PREFIX}Analysis History`;
export const ANALYSIS_RESULTS = `${PREFIX}Analysis Results`;
export const DASHBOARD = `${PREFIX}Mission Dashboard`;
export const INCREMENTAL_TRAINING = `${PREFIX}Incremental Training`;
export const LIBRARY = `${PREFIX}Mirage Library`;
export const LIVE_MONITORING = `${PREFIX}Live Monitoring`;
export const LOGIN = `${PREFIX}Login`;
export const PLAN = `${PREFIX}Plan Your Mission`;
export const REPORTS = `${PREFIX}Saved Scenes & Reports`;
export const SETTINGS = `${PREFIX}Settings`;
export const SIFT = `${PREFIX}Searchlight`;
export const MINT = `${PREFIX}Create New Model`;
