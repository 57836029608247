import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react-local';

import Button from 'common/components/base/Button';
import Icon from 'common/components/base/Icon';
import IconButton from 'common/components/base/IconButton';
import { LIBRARY_POPUP_HEADER } from 'common/constants/colors';
import { Example } from '../helpers';

const useStyles = makeStyles()(theme => ({
  button: {
    margin: 8,
  },
  dark: {
    background: LIBRARY_POPUP_HEADER,
    padding: theme.spacing(0.75),
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(0.75),
  },
  main: {
    '& button': {
      marginRight: 8,
    },
  },
  map: {
    padding: theme.spacing(0.75),
    background:
      'url(https://www.umhs-adolescenthealth.org/wp-content/uploads/2016/12/google-map-background.jpg)',
    backgroundSize: 'contain',
  },
  navy: {
    background: 'navy !important',
  },
  rightIcon: {
    marginLeft: 8,
  },
  wide: {
    padding: '20px !important',
    width: 200,
  },
}));

const DisabledExamples = () => {
  const { classes } = useStyles();

  return (
    <>
      <Button theme="red" disabled>
        ERROR
      </Button>
      <Button theme="green" disabled>
        START LIVE PROCESSING
      </Button>
      <Button theme="transparent" disabled>
        TRANSPARENT
      </Button>
      <Button theme="transparent" color="secondary" disabled>
        TRANSPARENT
      </Button>
      <Button theme="white" disabled>
        WHITE
      </Button>
      <Button disabled>DEFAULT</Button>
      <Button variant="outlined" disabled>
        <Icon className={classes.leftIcon} iconName="cloud" />
        WITH ICON
      </Button>
      <Button disabled component={Link} to="#">
        Disabled Link
      </Button>
    </>
  );
};

const ButtonExample = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.main}>
      <Example label="Primary Button">
        <Button>DEFAULT</Button>
      </Example>

      <Example label="Secondary Button">
        <Button variant="outlined">SECONDARY</Button>
        <Button variant={null}>TEXT BUTTON</Button>
        <Button variant="outlined">
          <Icon className={classes.leftIcon} iconName="close" />
          WITH ICON
        </Button>
        <Button variant="outlined">
          WITH ICON
          <Icon iconName="cloud" className={classes.rightIcon} />
        </Button>
      </Example>

      <Example label="Themes">
        <Button theme="red">ERROR</Button>
        <Button theme="green">START LIVE PROCESSING</Button>
        <Button theme="white">CANCEL</Button>
        <Button theme="transparent">TRANSPARENT</Button>
        <Button theme="transparent" color="secondary">
          TRANSPARENT
        </Button>
      </Example>

      <Example label="Loading Buttons">
        <Button theme="red" isLoading disabled>
          ERROR
        </Button>
        <Button theme="green" isLoading disabled>
          START LIVE PROCESSING
        </Button>
        <Button theme="white" isLoading disabled>
          CANCEL
        </Button>
        <Button theme="transparent" isLoading>
          TRANSPARENT
        </Button>
        <Button theme="transparent" isLoading color="secondary">
          TRANSPARENT
        </Button>
      </Example>

      <Example label="Disabled">
        <DisabledExamples />
      </Example>

      <Example className={classes.dark} label="Disabled on Dark">
        <DisabledExamples />
      </Example>

      <Example className={classes.map} label="Disabled on Map">
        <DisabledExamples />
      </Example>

      <Example label="Custom Classes">
        <Button theme="red" customClasses={{ root: classes.navy }}>
          NAVY
        </Button>
        <Button theme="green" customClasses={{ root: classes.wide }}>
          WIDE
        </Button>
      </Example>

      <Example label="Button Sizes">
        <Button size="large">
          <Icon className={classes.leftIcon} iconName="delete" />
          LARGE
        </Button>
        <Button>
          <Icon className={classes.leftIcon} iconName="filter_list" />
          MEDIUM
        </Button>
        <Button size="small" theme="blue">
          <Icon className={classes.leftIcon} iconName="more_horiz" />
          SMALL
        </Button>
      </Example>

      <Example label="Icon Buttons">
        <IconButton>
          <Icon iconName="delete" />
        </IconButton>
        <IconButton theme="red">
          <Icon iconName="edit" />
        </IconButton>
        <IconButton theme="red" disabled>
          <Icon iconName="map" />
        </IconButton>
        <IconButton theme="orange" isLoading>
          <Icon iconName="folder" />
        </IconButton>
      </Example>
    </div>
  );
};

export default ButtonExample;
