import get from 'lodash/get';
import { createSelector } from 'reselect';

import { getSearchHealth } from '../computeHealthInfoStatus';

export const selectMirageHealth = state => get(state, 'health');

export const selectHealthResponseTime = createSelector(
  selectMirageHealth,
  health => health.responseTime
);

export const selectHealthSearchStatus = createSelector(selectMirageHealth, health =>
  getSearchHealth(health.searchInfo)
);

export const selectIsProcOnline = createSelector(
  selectMirageHealth,
  health => health.servicesInfo?.processorHeartbeat?.flag === 1
);

export const selectIsMintServiceOnline = createSelector(
  selectMirageHealth,
  health => health.servicesInfo?.mintHeartbeat?.flag === 1
);

export const selectIsLiveProcOnline = createSelector(
  selectMirageHealth,
  health => health.isLiveProcOnline
);
