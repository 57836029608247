import { string } from 'yup';

import store from 'redux/store';

import { ASSET_BACKEND, LIVE_MONITOR_ROUTE } from 'common/constants/urls';
import getParsedQuery from 'common/helpers/getParsedQuery';
import { isOnlyDigits } from 'common/helpers/stringUtils';

export function createThumbnailURL(frameThumb) {
  const { feedId, x, y, width, height, frameTime } = frameThumb;
  return `${ASSET_BACKEND}/api/ds/${feedId}/snip/?t=${frameTime}&x=${x}&y=${y}&w=${width}&h=${height}&th=${frameThumb.thumbhash}`;
}

export function buildLiveUrl(params = {}) {
  const { liveQueryId = null, liveCameraId = null, liveResultId = null } = params;
  let url = LIVE_MONITOR_ROUTE;

  if (liveResultId) {
    url += `/${liveQueryId}/${liveCameraId}/${liveResultId}`;
  } else if (liveCameraId) {
    url += `/${liveQueryId}/${liveCameraId}`;
  } else if (liveQueryId) {
    url += `/${liveQueryId}`;
  }

  return url;
}

export function isDuplicateEmail(inputEmail, invitations) {
  return invitations.map(email => email.toLowerCase()).indexOf(inputEmail.toLowerCase()) >= 0;
}

export function isValidEmailSyntax(email) {
  return string().email().required().isValidSync(email);
}

export function queryToNameSyntax(query) {
  const { person: personModels } = store.getState().common.models;

  return query
    .split(splitQueryRegex())
    .map(item => {
      if (personModels[item]) {
        return personModels[item].name;
      }

      return item;
    }, [])
    .join('');
}

function splitQueryRegex() {
  // Matches '(', ')', '|', '!', or '&' and keeps the delimiter
  return new RegExp(/(\(|\)|\||!|&)/g);
}

export function getPersonIdsFromQuery(query) {
  const parsedQuery = getParsedQuery(query);
  const ids = [];

  parsedQuery.forEach(group => {
    group.forEach(id => {
      if (isOnlyDigits(id)) ids.push(id);
    });
  });

  return ids;
}

export function sortByFrametime(a, b) {
  return b.resultThumb.frameTime - a.resultThumb.frameTime;
}
