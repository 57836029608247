import React from 'react';
import classnames from 'classnames';
import { noop, chain } from 'lodash';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';

import { withStyles } from 'tss-react-local';

import ListSubheader from './ListSubheader';
import MenuItem from './MenuItem';
import Tooltip from './Tooltip';
import { SMALL_FONT, MED_FONT, LARGE_FONT } from './constants';

const styles = {
  helperText: {
    fontSize: 9,
    fontWeight: 300,
    marginTop: 3,
  },
  select: {
    fontSize: 13,
    paddingTop: 0,
    paddingBottom: 0,
    height: 20,
  },
  menuList: {
    overflow: 'hidden',
  },
  root: {
    top: -3,
  },
  shrink: {
    /* Slightly modified from MUI's default transition */
    transform: 'translate(0, 6px) scale(0.75)',
    whiteSpace: 'nowrap',
  },
  'size-small': {
    fontSize: SMALL_FONT,
    top: -3,
  },
  'size-default': {
    fontSize: MED_FONT,
  },
  groupHeader: {
    color: 'black',
    fontSize: LARGE_FONT,
    fontWeight: 'bold',
    paddingTop: 10,
  },
};

class Select extends React.Component {
  static defaultProps = {
    customClasses: {},
    margin: 'dense',
    dense: true,
    options: [],
    renderMenuHeader: noop,
    size: 'default',
    shrinkOnEmptyLabel: true,
  };

  onChange = e => {
    if (this.props.onChange) this.props.onChange(e);
    if (this.props.onValueChange) this.props.onValueChange(e.target.value);
  };

  renderMenuItem = data => {
    const { value, label, disabled, tooltip, tooltipProps, ...rest } = data;
    const Component = (
      <MenuItem
        key={value}
        value={value}
        disabled={disabled}
        dense={this.props.dense}
        disableGutters={this.props.disableGutters}
        {...this.props.MenuItemProps}
        {...rest}
      >
        {this.props.renderMenuItem ? this.props.renderMenuItem(data) : label}
      </MenuItem>
    );

    if (tooltip) {
      const Content = disabled ? (
        /* Add div to place tooltip on a disabled item, prevent disabled objects from triggering click events */
        <div role="none" tabIndex="" onClick={e => e.stopPropagation()}>
          {Component}
        </div>
      ) : (
        Component
      );

      return (
        <Tooltip key={value} title={tooltip} placement="right" {...tooltipProps}>
          {Content}
        </Tooltip>
      );
    }

    return Component;
  };

  renderGroupheader = (header = '') => {
    const { classes, renderGroupheader } = this.props;
    if (!header.length || /undefined/i.test(header)) return null;

    return (
      <ListSubheader key={header} className={classes.groupHeader}>
        {renderGroupheader ? renderGroupheader(header) : header}
      </ListSubheader>
    );
  };

  renderOptions = () =>
    chain(this.props.options)
      .groupBy('group')
      .map((items, header) => [this.renderGroupheader(header), items.map(this.renderMenuItem)])
      .flattenDeep()
      .compact()
      .value();

  render() {
    const {
      children,
      classes,
      className,
      customClasses,
      dense,
      disabled,
      error,
      formControlProps,
      formHelperTextProps,
      helperText,
      inputLabelProps,
      label,
      margin,
      onChange,
      options,
      renderMenuHeader,
      size,
      required,
      shrinkOnEmptyLabel,
      value,

      // Do not gather in rest
      onValueChange,
      renderMenuItem,

      ...rest
    } = this.props;

    const { formControlClasses = {}, selectClasses = {}, inputLabelClasses = {} } = customClasses;

    return (
      <FormControl
        classes={formControlClasses}
        disabled={disabled}
        required={required}
        error={error}
        className={className}
        {...formControlProps}
      >
        {(!shrinkOnEmptyLabel || label) && (
          <InputLabel
            classes={{
              ...inputLabelClasses,
              root: classnames(classes.root, classes[`size-${size}`], inputLabelClasses.root),
              shrink: classnames(classes.shrink, inputLabelClasses.shrink),
            }}
            {...inputLabelProps}
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          classes={{
            ...selectClasses,
            select: classnames(classes.select, selectClasses.select),
          }}
          margin={margin}
          MenuProps={{ classes: { list: classes.menuList } }}
          onChange={this.onChange}
          value={value}
          {...rest}
        >
          {renderMenuHeader()}
          {this.renderOptions()}
          {children}
        </MuiSelect>
        {(!shrinkOnEmptyLabel || helperText) && (
          <FormHelperText className={classnames(classes.helperText)} {...formHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(Select, styles);
