import React from 'react';
import { styled } from 'react-jss';
import { connect } from 'react-redux';

import Icon from 'common/components/base/Icon';
import { DARKEST_GREY, REGENT_GREY, TEXT, ACCENT } from 'common/constants/colors';
import { setSelectedReport } from 'reports/redux/actions';

import { parseToShorthandDate } from 'common/helpers/dateUtils';
import DatasourceSaveEditScene from 'common/components/popups/saveEditScene/DatasourceSaveEditScene';
import SceneSavedPopover from 'common/components/popups/saveEditScene/SceneSavedPopover';
import CaptureSceneButton from 'common/components/generalComponents/buttons/CaptureSceneButton';
import Typography from 'components/Typography';
import Tooltip from 'common/components/base/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { KINETIC_SEEK_BETA_TOOLTIP } from 'common/constants/strings';

export const HEADER_HEIGHT = 54;

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  width: theme.spacing(3),
  paddingLeft: theme.spacing(0.5),
  overflow: 'visible',
  color: DARKEST_GREY,
}));

const StyledOnDemandIcon = styled(Icon)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  verticalAlign: 'middle',
  color: REGENT_GREY,
}));

const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(1.5)}px`,
  height: HEADER_HEIGHT,
  flexShrink: 0,
  fontSize: 13,
}));

const StyledTitle = styled('div')({
  whiteSpace: 'pre',
});

const StyledKineticBeta = styled('div')(({ theme }) => ({
  padding: '3px',
  margin: `0 ${theme.spacing(0.5)}px`,
  background: ACCENT,
  borderRadius: 3,
  fontSize: 7,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${DARKEST_GREY}`,
  color: TEXT,
}));

const StyledKineticRightHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

class VideoPlayerHeader extends React.PureComponent {
  static defaultProps = {
    datasource: {},
  };

  state = {
    showSaveScene: false,
    showSceneSavedPopup: false,
  };

  openSaveScene = () => {
    this.pauseVideo();
    this.setState({ showSaveScene: true });
  };

  closeSaveScene = () => this.setState({ showSaveScene: false });

  openSceneSaved = () => this.setState({ showSceneSavedPopup: true });

  closeSceneSaved = () => this.setState({ showSceneSavedPopup: false });

  pauseVideo = () => this.props.video && this.props.video.pause();

  onSceneSaved = scene => {
    if (scene?.report && Number.isInteger(scene.report)) {
      this.props.dispatchSetSelectedReport(scene.report);
    }

    this.openSceneSaved();
  };

  render() {
    const {
      cameraDisplayName,
      cameraFps,
      disableCaptureScene,
      disableCaptureSceneTooltip,
      priorQuery,
      datasource,
      kinetic,
    } = this.props;
    const bullet = '\u00B7';
    const fpsLabel = cameraFps ? `${bullet} ${cameraFps} fps\n` : '';

    return (
      <StyledHeader>
        {cameraDisplayName ? (
          <Tooltip
            title={
              <StyledTitle>
                {fpsLabel}
                Processing started on:
                {parseToShorthandDate(datasource.created, {
                  showTimeZone: true,
                  displayTimezoneName: datasource.displayTimezoneName,
                })}
              </StyledTitle>
            }
          >
            <Typography variant="body1" align="center" data-testid="VideoPlayerHeader-cameraName">
              {cameraDisplayName}
              <StyledInfoIcon />
            </Typography>
          </Tooltip>
        ) : (
          <Typography data-testid="VideoPlayerHeader-datasource-title">
            <StyledOnDemandIcon iconName="ondemand_video" />
            {datasource.title}
          </Typography>
        )}
        <StyledKineticRightHeader>
          {kinetic && (
            <Tooltip title={KINETIC_SEEK_BETA_TOOLTIP}>
              <StyledKineticBeta>Kinetic Seek Enabled</StyledKineticBeta>
            </Tooltip>
          )}
          <Tooltip
            showTooltip={disableCaptureScene && disableCaptureSceneTooltip}
            title={disableCaptureSceneTooltip}
          >
            <div>
              <CaptureSceneButton
                onClick={this.openSaveScene}
                disabled={disableCaptureScene}
                data-testid="VideoPlayerHeader-captureScene"
              />
            </div>
          </Tooltip>
        </StyledKineticRightHeader>
        <DatasourceSaveEditScene
          datasourceId={datasource.id}
          onClose={this.closeSaveScene}
          onSceneSaved={this.onSceneSaved}
          open={this.state.showSaveScene}
          priorQuery={priorQuery}
          video={this.props.video}
        />
        <SceneSavedPopover open={this.state.showSceneSavedPopup} onClose={this.closeSceneSaved} />
      </StyledHeader>
    );
  }
}

const mapDispatchToProps = {
  dispatchSetSelectedReport: setSelectedReport,
};

export default connect(null, mapDispatchToProps)(VideoPlayerHeader);
