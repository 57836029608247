import React from 'react';
import injectSheet from 'react-jss';

import Popover from '@material-ui/core/Popover';
import { TEST_SELECT_TIME_RANGE } from '__testSetup__/constants';

import { parseToHHMM } from 'common/helpers/dateUtils';
import Chip from 'common/components/base/Chip';
import Typography from 'components/Typography';
import TimeRangePicker from 'common/components/pickers/TimeRangePicker';

const styles = {
  input: {
    width: '100%',
  },
  chip: {
    cursor: 'pointer',
  },
  label: {
    marginTop: 2,
  },
};

class TimeRangePickerChip extends React.Component {
  state = {
    open: false,
    anchorEl: null,
  };

  open = e => this.setState({ open: true, anchorEl: e.currentTarget });

  close = () => this.setState({ open: false, anchorEl: null });

  onChange = ({ startTime, endTime }) => {
    this.props.onChange({ startTime, endTime });
  };

  onClear = () => this.props.onChange({ startTime: null, endTime: null });

  renderTime = (start, end) => {
    if (!start && !end) return '';

    const startTime = parseToHHMM(start, {});
    if (end) {
      const endTime = parseToHHMM(end, {});
      return `${startTime} — ${endTime}`;
    }

    return startTime;
  };

  render() {
    const { disabled, startTime, endTime, classes } = this.props;
    const hasTime = startTime && endTime;
    return (
      <React.Fragment>
        <Chip
          className={classes.chip}
          disabled={disabled}
          variant="outlined"
          color="default"
          onClick={this.open}
          label={
            <Typography variant="body2" className={classes.label}>
              {hasTime ? this.renderTime(startTime, endTime) : 'Time Range'}
            </Typography>
          }
          onDelete={hasTime && !disabled ? this.onClear : null}
          {...TEST_SELECT_TIME_RANGE}
        />
        <Popover
          disableRestoreFocus
          open={this.state.open}
          onClose={this.close}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <TimeRangePicker
            startTime={startTime}
            endTime={endTime}
            onChange={this.onChange}
            onClose={this.close}
          />
        </Popover>
      </React.Fragment>
    );
  }
}

export default injectSheet(styles)(TimeRangePickerChip);
