import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 100" color={color} {...rest}>
    <path
      fillRule="evenodd"
      d="M6.444 35c-.58.11-1.048.634-1.096 1.215-1.348 14.772-1.343 26.94 0 41.778.053.445-.147.88-.253 1.299A1.386 1.386 0 0 0 4 80.59v4.023c0 .701.643 1.34 1.348 1.34h13.61A9.405 9.405 0 0 0 28.27 94a9.405 9.405 0 0 0 9.311-8.046h54.186A9.405 9.405 0 0 0 101.08 94a9.405 9.405 0 0 0 9.312-8.046h6.783c3.534-.45 6.763-3.436 6.826-6.704-.1-4.3 0-5.463 0-8.046 0-1.733-.78-3.226-1.812-4.4-2.254-2.34-4.85-3.666-7.963-4.693-2.876-8.286-9.228-20.993-9.228-20.993-2.77-3.819-7.59-6.066-12.008-6.118-29.558.016-59.969 0-86.545 0zm6.424 12.068h10.008v14.75H12.868c-1.037-4.64-1.037-10.11 0-14.75zm16.75 0h12.135v14.75H29.618v-14.75zm18.876 0H60.63v14.75H48.494v-14.75zm18.877 0h16.18v14.75H67.37v-14.75zm22.921 0h8.97c2.308 3.478 4.77 8.968 7.21 14.75h-16.18v-14.75zM67.371 64.5h16.18v13.41H67.37V64.5zM28.27 77.91c3.74 0 6.741 2.985 6.741 6.704s-3.002 6.704-6.741 6.704c-3.74 0-6.742-2.985-6.742-6.704 0-3.72 3.002-6.705 6.742-6.705zm72.809 0c3.74 0 6.741 2.985 6.741 6.704s-3.002 6.704-6.741 6.704c-3.74 0-6.742-2.985-6.742-6.704 0-3.72 3.002-6.705 6.742-6.705z"
    />
  </SvgIcon>
);
