import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import 'leaflet-draw/dist/leaflet.draw.css';
import { useSelector } from 'react-redux';
import { FeatureGroup, Polygon } from 'react-leaflet';

import { PLAN_ROUTE, ANALYSIS_ROUTE } from 'common/constants/urls';
import { selectGeoFilterCoordinates, selectLatLngCoordinates } from 'plan/redux/selectors';

import PolygonEditControl from './PolygonEditControl';

const FeatureDrawGeofence = ({ setCoordinates, doGeofenceFilter, clearDatasourcesInGeofence }) => {
  const history = useHistory();
  const editLayerRef = useRef();
  const { coordinates } = useSelector(selectGeoFilterCoordinates);
  const reformatCoordinates = useSelector(selectLatLngCoordinates);

  useEffect(
    () =>
      history.listen(location => {
        if (location.pathname.includes(PLAN_ROUTE) || location.pathname.includes(ANALYSIS_ROUTE)) {
          return;
        }
        setCoordinates({});
        clearDatasourcesInGeofence();
      })[(history, setCoordinates, clearDatasourcesInGeofence)]
  );

  const onDrawStart = () => {
    editLayerRef.current.clearLayers();
  };

  const onCreated = layers => {
    const { geometry } = layers.layer.toGeoJSON();
    setCoordinates(geometry);
    doGeofenceFilter(geometry);
  };

  const onEdited = e => {
    e.layers.eachLayer(layer => {
      const { geometry } = layer.toGeoJSON();
      setCoordinates(geometry);
      doGeofenceFilter(geometry);
    });
  };

  const onDeleted = () => {
    setCoordinates({});
    clearDatasourcesInGeofence();
  };

  return (
    <FeatureGroup ref={editLayerRef}>
      {!isEmpty(coordinates) && <Polygon positions={reformatCoordinates} />}
      <PolygonEditControl
        onCreated={onCreated}
        onDeleted={onDeleted}
        onDrawStart={onDrawStart}
        onEdited={onEdited}
      />
    </FeatureGroup>
  );
};

export default FeatureDrawGeofence;
