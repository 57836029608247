import React, { useCallback } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

const DraggableDialog = ({ dragHandleId, ...rest }) => {
  const DraggablePaper = useCallback(
    props => (
      <Draggable cancel='[class*="MuiDialogContent-root"]' handle={`#${dragHandleId}`}>
        <Paper {...props} />
      </Draggable>
    ),
    [dragHandleId]
  );

  return <MuiDialog PaperComponent={DraggablePaper} {...rest} />;
};

export default DraggableDialog;
