import { UNCONFIRMED_STATUS } from 'common/constants/geoSearchResult';
import { DESC_CONFIDENCE } from '../constants';

/* TODO: de-couple the grid and panel view reducer here */
const defaults = {
  reviewToggle: UNCONFIRMED_STATUS,
  geoSearchResults: [],
  filter: {
    objectIds: [],
    recency: DESC_CONFIDENCE,
    userIds: [],
    timesliceIds: [],
  },
  highlightedResult: {},
  searchResultsLimit: 50,
  searchResultsOffset: 0,
  searchResultsTotal: 0,
  selectedSearchResult: {},
  selectedSearchResultIdObj: {},
  mostRecentSelectedResultId: {},
};

export default function createSearchResultReducer(suffix) {
  return function geoAnalysisReducer(state = defaults, action) {
    switch (action.type) {
      case `ANALYSIS_GEOSPATIAL/${suffix}/RESET`: {
        return defaults;
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_RESULT_REVIEW_TOGGLE`: {
        return {
          ...state,
          reviewToggle: action.payload,
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_FILTER_OBJECTS`: {
        return {
          ...state,
          filter: {
            ...state.filter,
            objectIds: action.payload,
          },
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_FILTER_USERS`: {
        return {
          ...state,
          filter: {
            ...state.filter,
            userIds: action.payload,
          },
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_FILTER_TIMESLICES`: {
        return {
          ...state,
          filter: {
            ...state.filter,
            timesliceIds: action.payload,
          },
        };
      }
      case 'ANALYSIS_GEOSPATIAL/SET_SELECTED_TIMESLICE_INDEX': {
        return {
          ...state,
          filter: {
            ...state.filter,
            timesliceIds: defaults.filter.timesliceIds,
          },
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_FILTER_RECENCY`: {
        return {
          ...state,
          filter: {
            ...state.filter,
            recency: action.payload,
          },
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_HIGHLIGHTED_RESULT`: {
        return {
          ...state,
          highlightedResult: {
            ...state.highlightedResult,
            [action.payload.toggle]: action.payload.result,
          },
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_SEARCH_RESULTS`: {
        return {
          ...state,
          geoSearchResults: action.payload,
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_SELECTED_SEARCH_RESULT`: {
        return {
          ...state,
          selectedSearchResult: action.payload,
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_SELECTED_SEARCH_RESULTS`: {
        return {
          ...state,
          selectedSearchResultIdObj: action.payload,
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_MOST_RECENT_SELECTED_RESULT_ID`: {
        return {
          ...state,
          mostRecentSelectedResultId: action.payload,
        };
      }
      case `ANALYSIS_GEOSPATIAL/${suffix}/SET_SEARCH_RESULTS_META`: {
        return {
          ...state,
          searchResultsLimit: action.payload.limit,
          searchResultsOffset: action.payload.offset,
          searchResultsTotal: action.payload.total,
        };
      }
      default:
        return state;
    }
  };
}
