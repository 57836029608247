import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import SaveEditScene from 'common/components/popups/saveEditScene/SaveEditScene';
import GeoCoder from 'common/components/generalComponents/googleMaps/GeoCoder';
import { isGoogleActive } from 'common/helpers/networkUtils';

import {
  defaultCreateReport,
  createSceneFromVideo,
  fetchDatasourcesById,
} from 'common/redux/models/actions';
import { buildSceneFromDatasource } from 'common/components/popups/saveEditScene/utils';
import { isValidLocation } from 'library/redux/datasource/utils';

const DatasourceSaveEditScene = props => {
  const { datasourceId, open, priorQuery, video, onSceneSaved, ...rest } = props;
  const dispatch = useDispatch();
  const [datasource, setDatasource] = useState({});
  const [location, setLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      const fetchDatasource = async () => {
        setIsLoading(true);

        const [_datasource] = await dispatch(fetchDatasourcesById([datasourceId]));

        setIsLoading(false);
        setDatasource(_datasource);
      };

      fetchDatasource();
    } else {
      setDatasource({});
    }
  }, [dispatch, datasourceId, open]);

  useEffect(() => {
    const hasFormattedAddress = datasource.location?.formattedAddress;

    if (!hasFormattedAddress && isGoogleActive() && isValidLocation(datasource.location)) {
      const _geocoder = new GeoCoder();

      _geocoder.getLocationByLatLng(datasource.location.coords).then(setLocation);
    } else {
      setLocation(datasource.location);
    }
  }, [datasource.location]);

  const onSaveScene = useCallback(
    async ({ report, scene }) => {
      const timestamp = Math.round(datasource.startEpochMs + scene.targetTime);
      let reportId = report.id;

      if (!reportId) {
        ({ id: reportId } = await dispatch(defaultCreateReport(report)));
      }

      await dispatch(createSceneFromVideo({ scene, reportId, timestamp }));

      onSceneSaved(scene);
    },
    [dispatch, datasource.startEpochMs, onSceneSaved]
  );

  const source = useMemo(() => ({ ...datasource, location }), [datasource, location]);

  const defaultScene = useMemo(
    () =>
      buildSceneFromDatasource(source, {
        targetTime: video?.getTime() * 1000,
        priorQuery,
      }),
    [source, priorQuery, video]
  );

  return (
    <SaveEditScene
      isLoading={isLoading}
      open={open}
      onSaveScene={onSaveScene}
      source={source}
      scene={defaultScene}
      {...rest}
    />
  );
};

DatasourceSaveEditScene.defaultProps = {
  onSceneSaved: () => {},
};

export default DatasourceSaveEditScene;
