import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { signout } from 'auth/redux/actions';

const useGlobalSignoutListener = () => {
  const dispatch = useDispatch();

  const handleUpdate = useCallback(
    event => {
      if (event.key === 'isLoggingOut') dispatch(signout());
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('storage', handleUpdate);

    return () => {
      window.removeEventListener('storage', handleUpdate);
    };
  }, [handleUpdate]);
};

export default useGlobalSignoutListener;
