import { createUseStyles } from 'react-jss';
import { WHITE } from 'common/constants/colors';

export default createUseStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(94),
    height: theme.spacing(58),
    borderRadius: 2,
    backgroundColor: WHITE,
    boxShadow: `0 ${theme.spacing(0.5)}px ${theme.spacing(1.5)}px 0 rgba(0, 0, 0, 0.3)`,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));
