import { CAMERA_MANAGEMENT } from 'settings/redux/constants';
import {
  DATASOURCE,
  FOLDER,
  CAMERA,
  OBJECT,
  PERSON,
  GEODATASET,
  GEOSPATIAL,
  GEOFENCE,
  DATASETS,
} from './app';

let BACKEND = '';

if (process.env.BACKEND_HOSTNAME) {
  BACKEND = process.env.BACKEND_HOSTNAME; // Needed for the Jest test suite
}

/* Open ID URLS */
export const GOOGLE_OPEN_ID_DOMAIN = 'https://accounts.google.com';
export const OPEN_ID_CONFIG_URL = '/.well-known/openid-configuration';

/* Django Backend API URLs */
export const ANALYTICS_BACKEND = `${BACKEND}/api`;
export const GEO_BACKEND = `${BACKEND}/geo`;
export const ASSET_BACKEND = BACKEND;

export const MINT_BACKEND = `${BACKEND}/mint`;
export const MODULES = '/modules';
export const CUSTOMER_SETTINGS = '/customer-settings';

export const DATASOURCES = '/ds';
export const TRACKLET_STATS = '/tracklets/statistics';
export const LOCATION = '/location';
export const USERS = '/users';

export const LOGOUT = '/logout';
export const IMAGES = '/images';
export const DATASHIFT = '/datashift';

export const HEALTH = '/health';
export const QUEUE = '/vidproc/status';
export const REPORTS = '/case/report';
export const SCENES = '/case/scene';
export const GEO_SCENES = `${GEO_BACKEND}/report/scene`;
export const GEO_SECTIONS = `${GEO_BACKEND}/report/section`;
export const GEO_REPORT = `${GEO_BACKEND}/report`;
export const SETTINGS = '/settings';
export const TIMESTAMP = '/ssd';
export const THUMB = '/thumb';
export const VERSION = '/version';
export const GRAPH = '/graph';
const COMMENT = '/comments';
export const GEO_COMMENT = `${GEO_BACKEND}${COMMENT}`;
export const GEO_CLASSIFIER = `${GEO_BACKEND}/classifier`;
export const GEO_DETECTOR = `${GEO_BACKEND}/detector`;
export const GEO_ALERT_QUERY = `${GEO_BACKEND}/alert-query`;
export const GEO_MISSION = `${GEO_BACKEND}/mission`;
export const GEO_MISSION_BOOKMARK = '/bookmark/';
export const GEO_MISSION_BOOKMARK_REMOVE = '/removebookmark/';
export const GEO_MISSION_SHARE = '/share/';
export const GEO_ALERT_CONDITIONS = '/conditions';
export const GEO_ALERT_RESULT = `${GEO_BACKEND}/alert-result`;

export const LIVE_QUERY_GROUP = '/live-query-group';
export const CAMERAS = '/camera';
export const CAMERA_MANAGER = '/camera-manager';
export const ZONE = '/zone';
export const VENUE_MAP = '/venue-map';

export const MANUAL_S3_FOLDER_LISTING = '/manual-folder-listing';
export const MANUAL_PROCESSING = '/manual-processing';
export const MACULAS = '/macula';
export const VID_PROC = '/upload';
export const PROC_JOBS = '/jobs';

export const PEOPLE = '/people';

/* React Router Query Params */
export const MY_LIBRARY_ID = 'my';

/* Mirage React Router URLs */
export const LIBRARY_GEO_ROUTE = `/library/geo`;
export const LIBRARY_FMV_ROUTE = `/library`;
export const DATASOURCE_ROUTE = `/${DATASOURCE}`;
export const PERSON_ROUTE = `/${PERSON}`;
export const CAMERA_ROUTE = `/${CAMERA}`;
export const OBJECT_ROUTE = `/${OBJECT}`;
export const FOLDER_ROUTE = `/${FOLDER}`;
export const GEODATASET_ROUTE = `/${GEODATASET}`;
export const GEOSPATIAL_ROUTE = `/${GEOSPATIAL}`;
export const GEOFENCE_ROUTE = `/${GEOFENCE}`;
export const GEO_ROUTE = '/geo';
export const PLAN_ROUTE = '/plan';
export const REPORTS_ROUTE = '/reports';
export const PLAN_GEOSPATIAL_ROUTE = '/plan-geospatial';
export const PLAN_GEO_OBJECT_POPUP_ROUTE = `${PLAN_GEOSPATIAL_ROUTE}/objects`;
export const MONITOR_ROUTE = '/monitor';
export const IDENTIFY_ROUTE = '/identify';
export const ANALYSIS_ROUTE = '/analysis';
export const ANALYSIS_GEO_ROUTE = '/analysis/geo';
export const ANALYSIS_SIFT_ROUTE = '/analysis/searchlight';
export const ANALYSIS_PREVALENCE_ROUTE = '/analysis/prevalence';
export const ZONE_ROUTE = '/zones';
export const PLAN_ANALYSIS_ROUTE = `${PLAN_ROUTE}${ANALYSIS_ROUTE}`;
export const PLAN_IDENTIFY_ROUTE = `${PLAN_ROUTE}${IDENTIFY_ROUTE}`;
export const PLAN_MONITOR_ROUTE = `${PLAN_ROUTE}${MONITOR_ROUTE}`;
export const PLAN_ANALYSIS_ZONE_ROUTE = `${PLAN_ROUTE}${ANALYSIS_ROUTE}${ZONE_ROUTE}`;
export const LIVE_MONITOR_ROUTE = '/live-monitoring';
export const SETTINGS_ROUTE = '/settings';
export const INCREMENTAL_TRAINING_ROUTE = '/incremental-training';
export const LIBRARY_GEODATASET_ROUTE = `${LIBRARY_GEO_ROUTE}/${GEOSPATIAL}/${FOLDER}/${DATASETS}/${GEODATASET}`;

export const INCREMENTAL_TRAINING_IMPORT_CLASSIFIER = `${GEO_CLASSIFIER}/import/`;
export const INCREMENTAL_TRAINING_IMPORT_DETECTOR = `${GEO_DETECTOR}/import/`;
export const INCREMENTAL_TRAINING_IMPORT_ANNOTATIONS = `${GEO_CLASSIFIER}/import-annotations/`;
export const INCREMENTAL_TRAINING_EXPORT_ANNOTATIONS = `${GEO_CLASSIFIER}/export-annotations/`;
export const INCREMENTAL_TRAINING_IMPORT_CONFIRMED_DETECTIONS = `${GEO_DETECTOR}/import-confirmed-detections/`;
export const INCREMENTAL_TRAINING_EXPORT_CONFIRMED_DETECTIONS = `${GEO_DETECTOR}/export-confirmed-detections/`;
export const INCREMENTAL_TRAINING_ANNOTATIONS_INSTANCE_NAMES = `${GEO_CLASSIFIER}/annotations-instance-names/`;
export const INCREMENTAL_TRAINING_DETECTIONS_INSTANCE_NAMES = `${GEO_DETECTOR}/confirmed-detections-instance-names/`;
export const CAMERA_MANAGEMENT_ROUTE = `${SETTINGS_ROUTE}/${CAMERA_MANAGEMENT}`;
export const GEO_ALERTING_ROUTE = '/mission-dashboard';
export const GEO_REPORTS_BASE_URL = `${window.location.origin}/#/reports/geo`;
export const DEFAULT_GEO_DATASOURCE_ROUTE = `${LIBRARY_GEO_ROUTE}${GEOSPATIAL_ROUTE}${FOLDER_ROUTE}/fences`;
export const MINT_ROUTE = '/mint';
