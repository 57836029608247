import { LOCAL_AUTH_TYPE } from './constants';

const defaults = {
  isLoggingOut: false,
  isOIDCSession: false,
  openIdConfig: {},
  user: {},
  userAuthType: LOCAL_AUTH_TYPE,
};

export default function authReducer(state = defaults, action) {
  switch (action.type) {
    case 'AUTH/RESET_AUTH': {
      return {
        ...defaults,
        /* keep openIdConfig */
        openIdConfig: state.openIdConfig,
      };
    }
    case 'AUTH/SET_CURRENT_USER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'AUTH/SET_USER_AUTH_TYPE': {
      return {
        ...state,
        userAuthType: action.payload,
      };
    }
    case 'AUTH/SET_OPEN_ID_CONFIG': {
      return {
        ...state,
        openIdConfig: action.payload,
      };
    }
    case 'AUTH/SET_IS_LOGGING_OUT': {
      return {
        ...state,
        isLoggingOut: action.payload,
      };
    }
    case 'AUTH/SET_IS_OIDC_SESSION': {
      return {
        ...state,
        isOIDCSession: action.payload,
      };
    }
    default:
      return state;
  }
}
