import axios from 'axios.js';
import { ANALYTICS_BACKEND, LIVE_QUERY_GROUP } from 'common/constants/urls';

export function getLiveQueryGroups() {
  return axios.get(`${ANALYTICS_BACKEND}${LIVE_QUERY_GROUP}/`).then(({ data }) => data);
}

export function postLiveQueryGroup(body) {
  return axios.post(`${ANALYTICS_BACKEND}${LIVE_QUERY_GROUP}/`, body).then(({ data }) => data);
}

export function patchLiveQueryGroup(id, body) {
  return axios
    .patch(`${ANALYTICS_BACKEND}${LIVE_QUERY_GROUP}/${id}/`, body)
    .then(({ data }) => data);
}

export function deleteLiveQueryGroup(groupId) {
  return axios
    .delete(`${ANALYTICS_BACKEND}${LIVE_QUERY_GROUP}/${groupId}/`)
    .then(({ data }) => data);
}
