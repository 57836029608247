import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LibraryActive = props => (
  <SvgIcon viewBox="-2.9 -2.9 23.8 23.8" {...props}>
    <path d="M17.916 10.786c0-.077.007-.155-.014-.236L15.219.728a.888.888 0 0 0-.861-.658H3.642a.892.892 0 0 0-.861.658L.102 10.55c-.021.078-.014.159-.014.236H.07v6.25c0 .493.401.894.893.894h16.074c.492 0 .893-.401.893-.893v-6.251h-.014zm-5.903 0c-.186 0-.33.148-.355.334a2.679 2.679 0 0 1-5.316 0c-.025-.186-.165-.334-.355-.334h-4.1l2.437-8.93h9.352l2.436 8.93h-4.1zM5.787 5.428h6.43a.358.358 0 0 0 .358-.359V3.997a.358.358 0 0 0-.358-.358h-6.43a.358.358 0 0 0-.359.358V5.07c0 .2.158.36.359.36zM13.106 7.214H4.894a.358.358 0 0 0-.359.359v1.072c0 .197.158.358.359.358h8.216a.358.358 0 0 0 .358-.358V7.573a.363.363 0 0 0-.362-.359z" />
  </SvgIcon>
);

export default LibraryActive;
