const DEFAULT_STATE = {
  file: null,
  fileUploadProgress: null,
};

function mintReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'MINT/SET_FILE_TO_UPLOAD': {
      return {
        ...state,
        file: action.payload,
      };
    }

    case 'MINT/SET_FILE_UPLOAD_PROGESS': {
      return {
        ...state,
        fileUploadProgress: action.payload,
      };
    }

    default:
      return state;
  }
}

export default mintReducer;
