import { useState, useCallback } from 'react';

import { STATUS } from 'common/constants/api';
import { getCamera } from 'common/api/cameraApi';

const DEFAULT_CAMERA = {};

const useCamera = () => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(DEFAULT_CAMERA);
  const [status, setStatus] = useState(STATUS.IDLE);

  const fetch = useCallback(async id => {
    try {
      setStatus(STATUS.PENDING);
      const cameraData = await getCamera(id);
      setData(cameraData);
      setStatus(STATUS.SUCCESS);
      return cameraData;
    } catch (e) {
      setStatus(STATUS.FAILURE);
      setError(e.message);
    }
  }, []);

  return {
    fetch,
    data,
    isPending: status === STATUS.PENDING,
    isSuccess: status === STATUS.SUCCESS,
    error,
  };
};

export default useCamera;
