import React from 'react';
import Map from 'common/components/map/Map';
import { getCoordsFromDrawing } from 'common/helpers/googleMapFunctions';
import { Example } from '../helpers';

export default class MapDrawingExample extends React.Component {
  state = {
    markerConfig: [],
    drawing: null,
  };

  componentDidMount = () => {
    this.setState({
      markerConfig: [{ position: { lat: 37.7749, lng: -122.4194 } }],
    });
  };

  onOverlayComplete = nextDrawing => {
    if (this.state.drawing) {
      this.state.drawing.overlay.setMap(null);
    }

    const coords = getCoordsFromDrawing(nextDrawing);

    this.setState({
      drawing: nextDrawing,
      markerConfig: coords.map(coord => ({
        position: { lat: coord.latitude, lng: coord.longitude },
      })),
    });
  };

  render() {
    return (
      <div>
        <Example label="Map With Drawing">
          <Map markerConfig={this.state.markerConfig} onOverlayComplete={this.onOverlayComplete} />
        </Example>
      </div>
    );
  }
}
