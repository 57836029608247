import { combineReducers } from 'redux';
import { CAMERA, DATASOURCE, PERSON, OBJECT } from 'common/constants/app';
import { ROOT_DIRECTORIES } from 'library/redux/constants';

import camera from './camera/reducer';
import datasource from './datasource/reducer';
import object from './object/reducer';
import person from './person/reducer';
import processingPanelReducer from '../common/globalProcessingPanel/redux/reducer';

const defaults = {
  folderMoveInfo: {},
  openedFolders: {},
  processingJobs: {},
  selectedCamera: null,
  uploadingImages: {},
  uploadingJobs: {},
  currentLibraryKey: ROOT_DIRECTORIES[DATASOURCE],
};

function main(state = defaults, action) {
  switch (action.type) {
    case 'LIBRARY/SET_FOLDER_MOVE_INFO': {
      return {
        ...state,
        folderMoveInfo: action.moveInfo,
      };
    }
    case 'LIBRARY/TOGGLE_OPEN_FOLDER': {
      return {
        ...state,
        openedFolders: {
          ...state.openedFolders,
          [action.key]: action.isOpened,
        },
      };
    }
    case 'LIBRARY/SET_CURRENT_LIBRARY_KEY': {
      return {
        ...state,
        currentLibraryKey: action.currentLibraryKey,
      };
    }
    case 'LIBRARY/SET_UPLOADING_JOBS': {
      return {
        ...state,
        uploadingJobs: action.payload,
      };
    }
    case 'LIBRARY/SET_UPLOADING_IMAGES': {
      return {
        ...state,
        uploadingImages: action.payload,
      };
    }
    case 'LIBRARY/SET_PROCESSING_JOBS': {
      return {
        ...state,
        processingJobs: action.payload,
      };
    }
    default:
      return state;
  }
}

export default combineReducers({
  main,
  globalProcessingPanel: processingPanelReducer,
  [CAMERA]: camera,
  [DATASOURCE]: datasource,
  [OBJECT]: object,
  [PERSON]: person,
});
