import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Home = props => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <g fill="inherit" fillRule="nonzero">
      <path d="M11 .82l10.5 8.4H.5z" />
      <path d="M3.5 8.111v11h5.07v-5.893h4.86v5.893h5.07v-11z" />
    </g>
  </SvgIcon>
);

export default Home;
