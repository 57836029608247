import { makeStyles } from 'tss-react-local';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
}));

const BaseDialog = ({ classes, ...rest }) => {
  const { classes: innerClasses } = useStyles();
  const { paper, ...restClasses } = classes || {};

  return <Dialog classes={{ paper: `${innerClasses.paper} ${paper}`, ...restClasses }} {...rest} />;
};

export default BaseDialog;
