import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="752" height="288" viewBox="0 0 940 360">
    <g fill="#C7CFDC" fillRule="evenodd" opacity=".65" transform="translate(-40)">
      <path
        fillRule="nonzero"
        d="M947.096 140.714h182.951a.714.714 0 1 0 0-1.428h-182.95a.714.714 0 1 0 0 1.428zM947.096 218.571h182.951a.714.714 0 1 0 0-1.428h-182.95a.714.714 0 1 0 0 1.428z"
        opacity=".3"
      />
      <ellipse cx="924.228" cy="220" opacity=".299" rx="20.01" ry="20" />
      <ellipse cx="924.228" cy="140" opacity=".299" rx="20.01" ry="20" />
      <path
        fillRule="nonzero"
        d="M901.906 220.402l-81.844 120a.715.715 0 0 1-1.181-.804l81.844-120a.715.715 0 0 1 1.181.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M901.63 220.642l-81.845 40a.715.715 0 0 1-.628-1.284l81.845-40a.715.715 0 0 1 .628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M901.002 220.642l-81.845-40a.714.714 0 1 1 .628-1.284l81.845 40a.714.714 0 1 1-.628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M818.88 100.402l81.845 120a.715.715 0 0 0 1.181-.804l-81.844-120a.715.715 0 0 0-1.181.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M818.81 20.27l81.844 200a.715.715 0 0 0 1.323-.54l-81.845-200a.715.715 0 0 0-1.322.54z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M901.977 140.27l-81.845 200a.715.715 0 0 1-1.322-.54l81.844-200a.715.715 0 0 1 1.323.54z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M901.906 140.402l-81.844 120a.715.715 0 0 1-1.181-.804l81.844-120a.715.715 0 0 1 1.181.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M901.63 140.642l-81.845 40a.715.715 0 0 1-.628-1.284l81.845-40a.715.715 0 0 1 .628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M901.002 140.642l-81.845-40a.714.714 0 1 1 .628-1.284l81.845 40a.714.714 0 1 1-.628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M818.88 20.402l81.845 120a.715.715 0 0 0 1.181-.804l-81.844-120a.715.715 0 0 0-1.181.804z"
        opacity=".3"
      />
      <ellipse cx="795.59" cy="340" opacity=".3" rx="20.01" ry="20" />
      <ellipse cx="795.59" cy="260" opacity=".299" rx="20.01" ry="20" />
      <ellipse cx="795.59" cy="180" opacity=".299" rx="20.01" ry="20" />
      <ellipse cx="795.59" cy="100" opacity=".299" rx="20.01" ry="20" />
      <ellipse cx="795.59" cy="20" opacity=".299" rx="20.01" ry="20" />
      <path
        fillRule="nonzero"
        d="M771.354 340.543l-81.845-39.901a.714.714 0 1 1 .626-1.284l81.845 39.9a.714.714 0 1 1-.626 1.285z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M771.981 260.74l-81.844 40.1a.715.715 0 0 1-.63-1.283l81.845-40.1a.715.715 0 0 1 .63 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M772.258 180.501l-81.845 120.1a.715.715 0 0 1-1.182-.805l81.845-120.099a.715.715 0 0 1 1.182.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M772.328 100.37l-81.844 200.099a.715.715 0 0 1-1.324-.54l81.845-200.1a.715.715 0 0 1 1.323.54z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M772.353 20.3l-81.845 280.099a.715.715 0 0 1-1.372-.4l81.845-280.1a.715.715 0 0 1 1.372.4z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.231 220.402l81.845 120a.715.715 0 0 0 1.181-.804l-81.845-120a.715.715 0 0 0-1.18.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.508 220.642l81.845 40a.715.715 0 0 0 .628-1.284l-81.845-40a.715.715 0 0 0-.628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M690.136 220.642l81.845-40a.714.714 0 1 0-.628-1.284l-81.845 40a.714.714 0 1 0 .628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M772.257 100.402l-81.845 120a.715.715 0 0 1-1.18-.804l81.844-120a.715.715 0 0 1 1.181.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M772.328 20.27l-81.845 200a.715.715 0 0 1-1.322-.54l81.844-200a.715.715 0 0 1 1.323.54z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.16 140.27l81.845 200a.715.715 0 0 0 1.323-.54l-81.845-200a.715.715 0 0 0-1.322.54z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.231 140.402l81.845 120a.715.715 0 0 0 1.181-.804l-81.845-120a.715.715 0 0 0-1.18.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.508 140.642l81.845 40a.715.715 0 0 0 .628-1.284l-81.845-40a.715.715 0 0 0-.628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M690.136 140.642l81.845-40a.714.714 0 1 0-.628-1.284l-81.845 40a.714.714 0 1 0 .628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M772.257 20.402l-81.845 120a.715.715 0 0 1-1.18-.804l81.844-120a.715.715 0 0 1 1.181.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M690.136 60.642l81.845-40a.714.714 0 1 0-.628-1.284l-81.845 40a.714.714 0 1 0 .628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.508 60.642l81.845 40a.715.715 0 0 0 .628-1.284l-81.845-40a.715.715 0 0 0-.628 1.284z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.231 60.402l81.845 120a.715.715 0 0 0 1.181-.804l-81.845-120a.715.715 0 0 0-1.18.804z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.16 60.27l81.845 200a.715.715 0 0 0 1.323-.54l-81.845-200a.715.715 0 0 0-1.322.54z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M689.136 60.2l81.845 280a.715.715 0 0 0 1.372-.4l-81.845-280a.715.715 0 0 0-1.372.4z"
        opacity=".3"
      />
      <ellipse cx="666.953" cy="297.143" opacity=".3" rx="20.01" ry="20" />
      <ellipse cx="666.953" cy="220" opacity=".3" rx="20.01" ry="20" />
      <ellipse cx="666.953" cy="140" opacity=".299" rx="20.01" ry="20" />
      <ellipse cx="666.953" cy="60" opacity=".299" rx="20.01" ry="20" />
      <path
        fillRule="nonzero"
        d="M564.043 297.857h81.845a.714.714 0 1 1 0 1.429h-81.845a.714.714 0 1 1 0-1.429z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.553 296.623l81.845-77.143a.715.715 0 0 1 .98 1.04l-81.844 77.143a.715.715 0 0 1-.98-1.04z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.41 296.813l81.844-157.143a.715.715 0 0 1 1.268.66l-81.845 157.143a.715.715 0 0 1-1.268-.66z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.368 296.915l81.845-237.148a.715.715 0 0 1 1.35.466L564.72 297.38a.715.715 0 0 1-1.351-.466z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.553 220.52l81.845 77.143a.715.715 0 0 0 .98-1.04l-81.844-77.143a.715.715 0 0 0-.98 1.04z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M564.043 220.714h81.845a.714.714 0 1 0 0-1.428h-81.845a.714.714 0 1 0 0 1.428z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.544 140.51l81.845 80a.715.715 0 0 0 .999-1.02l-81.845-80a.715.715 0 0 0-1 1.02z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.407 219.675l81.845-160.006a.715.715 0 0 1 1.273.65L564.68 220.326a.715.715 0 0 1-1.273-.65z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.41 140.33l81.844 157.143a.715.715 0 0 0 1.268-.66L564.677 139.67a.715.715 0 0 0-1.268.66z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.544 219.49l81.845-80a.715.715 0 0 1 .999 1.02l-81.845 80a.715.715 0 0 1-1-1.02z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M564.043 140.714h81.845a.714.714 0 1 0 0-1.428h-81.845a.714.714 0 1 0 0 1.428z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.544 60.505l81.845 80.006a.715.715 0 0 0 .999-1.022l-81.845-80.005a.715.715 0 0 0-1 1.021z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.368 60.803l81.845 236.573a.715.715 0 0 0 1.35-.467L564.72 60.336a.715.715 0 0 0-1.351.467z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.408 60.896l81.844 159.43a.715.715 0 0 0 1.272-.652L564.68 60.244a.715.715 0 0 0-1.271.652z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M563.546 139.488l81.844-79.43a.715.715 0 0 1 .996 1.024l-81.845 79.43a.715.715 0 0 1-.995-1.024z"
        opacity=".3"
      />
      <path
        fillRule="nonzero"
        d="M564.043 60.714h81.845a.714.714 0 1 0 0-1.428h-81.845a.714.714 0 1 0 0 1.428z"
        opacity=".3"
      />
      <ellipse cx="541.175" cy="297.143" opacity=".3" rx="20.01" ry="20" />
      <ellipse cx="541.175" cy="220" opacity=".3" rx="20.01" ry="20" />
      <ellipse cx="541.175" cy="140" opacity=".3" rx="20.01" ry="20" />
      <ellipse cx="541.175" cy="60" opacity=".3" rx="20.01" ry="20" />
      <path
        fillRule="nonzero"
        d="M2.101 297.857H517.01c1.11 0 2.011-.32 2.011-.714 0-.395-.9-.714-2.011-.714H2.101c-1.11 0-2.011.32-2.011.714 0 .394.9.714 2.011.714zM2.101 220.714H517.01c1.11 0 2.011-.32 2.011-.714 0-.394-.9-.714-2.011-.714H2.101c-1.11 0-2.011.32-2.011.714 0 .394.9.714 2.011.714zM2.101 140.714H517.01c1.11 0 2.011-.32 2.011-.714 0-.394-.9-.714-2.011-.714H2.101c-1.11 0-2.011.32-2.011.714 0 .394.9.714 2.011.714zM2.101 60.714H517.01c1.11 0 2.011-.32 2.011-.714 0-.394-.9-.714-2.011-.714H2.101c-1.11 0-2.011.32-2.011.714 0 .394.9.714 2.011.714z"
        opacity=".3"
      />
    </g>
  </svg>
);
