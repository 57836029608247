import { batchActions } from 'redux-batched-actions';
import { resetMirage } from 'redux/actions';

import { intentionallyDropError } from 'common/helpers/apiErrorUtils';
import { revokeToken, tokenRequest, getOpenIDAuth } from 'common/api/authApi';
import { getCurrentUser, getUserAuthType } from 'common/api/userApi';
import { initSettings, fetchCustomerSettings, fetchUserProfile } from 'settings/redux/actions';

const resetAuth = () => ({
  type: 'AUTH/RESET_AUTH',
});

const setCurrentUser = user => ({
  type: 'AUTH/SET_CURRENT_USER',
  payload: user,
});

const setUserAuthType = authType => ({
  type: 'AUTH/SET_USER_AUTH_TYPE',
  payload: authType,
});

const setOpenIdConfig = config => ({
  type: 'AUTH/SET_OPEN_ID_CONFIG',
  payload: config,
});

export function fetchOpenIdConfig() {
  return dispatch =>
    getOpenIDAuth().then(data => {
      dispatch(setOpenIdConfig(data));
      return data;
    });
}

export function signIn(credentials) {
  return dispatch =>
    tokenRequest(credentials).then(({ location }) => {
      if (location) {
        window.location.assign(location);
        return; // Bail out before 4xx's get thrown causing error messages to appear
      }

      const actions = [
        dispatch(initSettings()),
        dispatch(fetchCustomerSettings()),
        dispatch(fetchCurrentUser()),
      ];

      return Promise.all(actions);
    });
}

export const setIsLoggingOut = payload => ({
  type: 'AUTH/SET_IS_LOGGING_OUT',
  payload,
});

export const setIsOIDCSession = payload => ({
  type: 'AUTH/SET_IS_OIDC_SESSION',
  payload,
});

export const signout = () => (dispatch, getState) => {
  const { isLoggingOut } = getState().auth;

  // Avoid the logout process from getting into an infinite loop of 401s
  if (window.isLoggingOut || isLoggingOut) return;

  // Setting things directly on the window or localStorage is almost always a code
  // smell, but in this case is necessary because...
  //
  // window = synchronous and instantaneous. Avoids any possibility of a race condition
  // that would miss blocking new outgoing requests.
  //
  // localstorage = fires a browser-wide event that other tabs/instances of the app
  // are listening for to signout in their own instance. Unfortunately localStorage
  // update are async so there's still a possible race. condition
  //
  // redux = what all React components are subscribed to for changes in auth state
  window.isLoggingOut = true;
  localStorage.setItem('isLoggingOut', true);
  dispatch(setIsLoggingOut(true));

  return revokeToken().finally(() => {
    dispatch(batchActions([setIsOIDCSession(false), resetAuth(), resetMirage()]));
    localStorage.setItem('isOIDCSession', false);
    sessionStorage.clear();
    window.isLoggingOut = false;
    localStorage.setItem('isLoggingOut', false);
    dispatch(setIsLoggingOut(false));
  });
};

export function fetchCurrentUser() {
  return async function fetchCurrentUserThunk(dispatch) {
    // NOTE: the user auth type will eventually be merged with the current-user request, so we can
    // go back to one endpoint https://percipientai.atlassian.net/browse/MIR-12293

    const [currentUser, authType] = await Promise.all([
      getCurrentUser(),
      // Intentionally drop user auth-type error and only update if exists to make this change
      // backward compatible
      getUserAuthType().catch(intentionallyDropError),
    ]);

    const actions = [];

    if (currentUser) {
      actions.push(setCurrentUser(currentUser));
      dispatch(fetchUserProfile());
    }

    if (authType?.authMode) actions.push(setUserAuthType(authType.authMode));

    if (actions.length) {
      return dispatch(batchActions(actions));
    }
  };
}
