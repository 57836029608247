import InteractiveImage from 'common/components/interactiveImage/InteractiveImage';
import { Example } from '../helpers';

const InteractiveImageExample = () => (
  <div>
    <Example label="Default" style={{ width: 600, height: 600 }}>
      <div style={{ width: 800, height: 600, border: '1px solid black' }}>
        <InteractiveImage
          useDefaultSrc
          src="https://static.anychart.com/images/docs/seat_map/house.svg"
        />
      </div>
    </Example>
  </div>
);

export default InteractiveImageExample;
