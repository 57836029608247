import React from 'react';
import DateTime from 'dateTime';

import { withStyles } from 'tss-react-local';

import Year from './Year';
import { MIN_DATE } from './constants';

export const styles = {
  container: {
    height: 240,
    overflowY: 'auto',
  },
};

class YearSelection extends React.Component {
  static defaultProps = {
    animateYearScrolling: false,
    minDate: MIN_DATE,
    maxDate: DateTime.fromJSDateWithTZ(),
  };

  componentDidMount() {
    if (this.selectedYearRef && this.selectedYearRef.scrollIntoView) {
      this.selectedYearRef.scrollIntoView({
        behavior: this.props.animateYearScrolling ? 'smooth' : 'auto',
      });
    }
  }

  onYearSelect = year => {
    let newDate = this.props.date.set({ year });
    if (newDate > this.props.maxDate) newDate = this.props.maxDate;
    this.props.onChange(newDate);
  };

  render() {
    const {
      classes,
      date,
      minDate,
      maxDate,
      disablePast,
      disableFuture,
      reverseYearOrder,
    } = this.props;

    const currentYear = DateTime.fromJSDateWithTZ(date).year;
    const maxDateTime = DateTime.fromJSDateWithTZ(maxDate);
    const minDateTime = DateTime.fromJSDateWithTZ(minDate);

    let yearRange = Array(maxDateTime.year - minDateTime.year + 1)
      .fill()
      .map((val, index) => index + minDateTime.year);
    if (reverseYearOrder) yearRange = yearRange.reverse();
    const localDateTime = DateTime.fromJSDateWithTZ();
    return (
      <div className={classes.container} data-testid="YearSelection">
        {yearRange.map(year => {
          const selected = year === currentYear;
          const isDisabled = Boolean(
            (disablePast && year < localDateTime.year) ||
              (disableFuture && year > localDateTime.year)
          );

          return (
            <Year
              key={year}
              selected={selected}
              value={year}
              onSelect={this.onYearSelect}
              ref={selected ? node => (this.selectedYearRef = node) : undefined}
              disabled={isDisabled}
            >
              {year}
            </Year>
          );
        })}
      </div>
    );
  }
}

export default withStyles(YearSelection, styles);
