import React, { Fragment } from 'react';
import { withStyles } from 'tss-react-local';
import { NavLink, Route, Switch } from 'react-router-dom';
import config from './config';
import ExamplesSummary from './ExamplesSummary';

const styles = {
  main: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
  },
  leftSide: {
    width: '300px',
    minWidth: '300px',
    boxShadow: '2px 0 2px 2px rgba(130,130,130,0.3)',
    overflowY: 'auto',
    zIndex: 1000,
    position: 'relative',
  },
  listItem: {
    padding: '5px 15px',
  },
  rightSide: {
    flexGrow: 1,
    padding: '15px',
    overflowY: 'auto',
    position: 'relative',
  },
};

class Examples extends React.Component {
  renderSidebar = configRow => (
    <div key={configRow.url} className={this.props.classes.listItem}>
      <NavLink to={`/examples/${configRow.url}`} activeStyle={{ color: 'black' }}>
        {configRow.label}
      </NavLink>
    </div>
  );

  renderRoute = configRow => {
    const { url, Component, fileText } = configRow;

    const contents = fileText?.default;
    const code = contents && (
      <div>
        <div
          style={{
            marginTop: '30px',
            marginBottom: '5px',
            fontStyle: 'italic',
            fontWeight: 300,
          }}
        >
          Example Code
        </div>
        <pre
          style={{
            background: 'rgb(245,245,245)',
            padding: '10px',
            border: '1px solid rgb(230,230,230)',
            margin: 0,
          }}
        >
          {contents}
        </pre>
      </div>
    );

    return (
      <Route
        key={url}
        path={`/examples/${url}`}
        render={() => (
          <Fragment>
            <Component />
            {code}
          </Fragment>
        )}
      />
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.leftSide}>
          <div key="/examples/" className={this.props.classes.listItem}>
            <NavLink exact to="/examples/" activeStyle={{ color: 'black' }}>
              Summary
            </NavLink>
          </div>
          {config.map(this.renderSidebar)}
        </div>
        <div className={classes.rightSide}>
          <Switch>{config.map(this.renderRoute)}</Switch>
          <Route exact path="/examples" render={() => <ExamplesSummary />} />
        </div>
      </div>
    );
  }
}

export default withStyles(Examples, styles);
