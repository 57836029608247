import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'primary', ...rest }) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 100" color={color} {...rest}>
    <path
      fillRule="evenodd"
      d="M123.235 68.56c-.418-1.474-1.307-3.445-3.129-4.689-8.387-5.017-19.447-6.312-20.831-6.444-1.39-.136-3.61-.46-3.61-.46S84.058 47.481 73.041 43.9c-11.011-3.58-33.678-1.037-35.63-.845-4.628.432-17.226 10.256-17.226 10.256S6.44 55.93 5.377 56.92c-1.058.99-1.408 5.829-1.375 10.325.034 4.501.798 10.678.798 10.678l11.434.723c-.072-.54-.12-1.085-.12-1.648 0-6.65 5.12-12.043 11.435-12.043s11.434 5.571 11.434 12.217c0 1.244-.178 2.418-.51 3.746h51.954c-.337-1.328-.515-2.506-.515-3.746 0-6.65 5.12-12.128 11.44-12.128 6.315 0 11.434 5.346 11.434 11.997 0 1.051-.14 2.041-.38 3.013 2.279.103 10.637.427 11.281-.038.75-.53-.029-9.983-.452-11.457zM37.063 56.652s-.13-7.65 1.75-9.534c1.884-1.883 14.149-1.399 14.736-1.447.593-.05 1.147.66 1.19 1.335l.69 10.805-18.366-1.16zM80.98 57.81l-20.99-.542s-2.02-10.469-2.1-10.892c-.086-.423.293-.728.936-.728.644 0 9.203.8 13.585 2.426 5.967 2.207 11.18 6.322 11.18 6.322l-2.611 3.414zm19.755 9.73c-5.404 0-9.796 4.363-9.796 9.73 0 5.361 4.397 9.729 9.796 9.729 5.404 0 9.796-4.363 9.796-9.73.005-5.366-4.392-9.73-9.796-9.73zm0 14.594c-2.702 0-4.898-2.182-4.898-4.865 0-2.683 2.196-4.865 4.898-4.865 2.701 0 4.898 2.182 4.898 4.865.005 2.683-2.197 4.865-4.898 4.865zM27.263 67.541c-5.403 0-9.794 4.363-9.794 9.73 0 5.361 4.396 9.729 9.794 9.729 5.402 0 9.798-4.363 9.798-9.73 0-5.366-4.396-9.73-9.798-9.73zm.002 14.594c-2.701 0-4.898-2.182-4.898-4.865 0-2.683 2.197-4.865 4.898-4.865 2.702 0 4.898 2.182 4.898 4.865 0 2.683-2.196 4.865-4.898 4.865z"
    />
  </SvgIcon>
);
