import { createUseStyles } from 'react-jss';

import { PRIMARY, LIGHT_BLACK } from 'common/constants/colors';
import MirageLogo from 'common/icons/mirage-logo';
import FirefoxLogo from 'common/icons/firefox-logo';
import chromeLogo from 'common/images/chrome.png';

const useStyles = createUseStyles(theme => ({
  main: {
    display: 'flex',
    height: '100%',
  },
  section: {
    width: 320,
    backgroundColor: PRIMARY,
    color: 'white',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 320,
  },
  light: {
    color: '#95a0b7',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 80,

    '& > h2': {
      marginBottom: 24,
    },
    '& p': {
      fontWeight: 350,
      fontSize: 16,
      maxWidth: 400,
    },
    '& br': {
      lineHeight: 2,
    },
    '& sup': {
      color: LIGHT_BLACK,
      fontSize: 6,
    },
    '& img': {
      height: 40,
      width: 'auto',
    },
  },
  mirageLogo: {
    color: 'unset',
  },
  browsers: {
    display: 'flex',
    margin: theme.spacing(2, 0),

    '& p': {
      fontSize: 14,
    },
  },
}));

const Browser = ({ name, logo, source }) => (
  <div style={{ marginRight: 24 }}>
    <p style={{ marginBottom: 4 }}>
      {name}
      <sup>TM</sup>
    </p>
    <div style={{ width: 40, height: 40 }}>{logo}</div>
    <sup>Source: {source}</sup>
  </div>
);

const BrowserErrorPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <section className={classes.section}>
        <MirageLogo className={classes.mirageLogo} height={60} width={160} />
      </section>
      <section className={classes.content}>
        <h2>Unsupported Browser Detected</h2>
        <p>
          Mirage<sup>TM</sup> is optimized for the following browsers:
        </p>
        <div className={classes.browsers}>
          <Browser name="Google Chrome" logo={<img src={chromeLogo} alt="" />} source="Google" />
          <Browser name="Mozilla Firefox" logo={<FirefoxLogo />} source="Mozilla" />
        </div>
        <p>
          Many features are not yet supported on your current browser. Please switch to a supported
          browser.
        </p>
        <br />
      </section>
    </div>
  );
};

export default BrowserErrorPage;
