import MuiCircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from 'tss-react-local';

import { getColor } from './themes';

const useStyles = makeStyles()((_theme, props) => ({
  root: {
    color: props.color,
  },
}));

const CircularLoader = ({
  color = 'primary',
  className = '',
  size = 32,
  thickness = 3,
  variant = 'determinate',
  ...rest
}) => {
  const { classes, cx } = useStyles({ color });

  return (
    <MuiCircularProgress
      color={getColor(color)}
      className={cx(classes.root, className)}
      size={size}
      thickness={thickness}
      variant={variant}
      {...rest}
    />
  );
};

export default CircularLoader;
