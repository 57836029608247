import React from 'react';
import { Box, Grid } from '@material-ui/core';
import useIdentityPackagePageTitle from './useIdentityPackagePageTitle';
import { useIdentityPackage } from './IdentityPackageContext';

const IdentityPackageImages = () => {
  useIdentityPackagePageTitle('Images');
  const { name, images } = useIdentityPackage();

  return (
    <Box p={4}>
      <Grid container spacing={3}>
        {images.map(image => (
          <Grid item xs={2} key={image}>
            <img src={image} alt={name} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default IdentityPackageImages;
