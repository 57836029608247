import { maxBy, isEmpty } from 'lodash';

import { normalizeList } from 'common/helpers/helperFunctions';
import { IMAGE, ROOT_OBJECT_KEY } from 'common/constants/app';
import { getConsistentUserColor } from './getRandomUserColor';

export function normalizeDatasources(acc, ds) {
  ds.location =
    typeof ds.location === 'string' && !isEmpty(ds.location)
      ? JSON.parse(ds.location)
      : ds.location || {};
  acc[ds.id] = ds;

  return acc;
}

export function normalizeImages(acc, image) {
  image.itemType = IMAGE;
  acc[image.id] = image;
  return acc;
}

export function normalizeUser(users) {
  return users.reduce((acc, user) => {
    acc[user.id] = user;
    acc[user.id].color = getConsistentUserColor(user);

    return acc;
  }, {});
}

const updateNameOfType = (folders, rootKey, name) => {
  const root = folders.find(f => f.id === rootKey);
  if (root) {
    root.name = name;
  }
};

export function normalizeFolders(folders) {
  folders.forEach(f => (f.isShared = !!f.library));
  updateNameOfType(folders, ROOT_OBJECT_KEY, 'All Objects');
  return normalizeList(folders);
}

export function convertToIdTable(modelArray = [], { fullModel = false } = {}) {
  return modelArray.reduce((acc, el) => {
    acc[el.id] = fullModel ? el : true;
    return acc;
  }, {});
}

export function getNewestImage(images = []) {
  return maxBy(images, ({ datetime }) => (datetime ? new Date(datetime).getTime() : 0));
}
