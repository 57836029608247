import { makeStyles } from 'tss-react-local';
import MuiDialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: 0,
    marginTop: theme.spacing(3),
  },
}));

const DialogActions = ({ classes, ...rest }) => {
  const { classes: innerClasses, cx } = useStyles();
  const { root, ...restClasses } = classes || {};

  return (
    <MuiDialogActions classes={{ root: cx(innerClasses.root, root), ...restClasses }} {...rest} />
  );
};

export default DialogActions;
