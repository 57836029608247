import { SIDEBAR_WIDTH } from 'common/constants/app';
import { SKY_BLUE, REGENT_GREY, WHITE } from 'common/constants/colors';
/*
  HTML5 media elements have 5 states:
    HAVE_NOTHING	    0	No information is available about the media resource.
    HAVE_METADATA	    1	Enough of the media resource has been retrieved that the metadata attributes are initialized. Seeking will no longer raise an exception.
    HAVE_CURRENT_DATA	2	Data is available for the current playback position, but not enough to actually play more than one frame.
    HAVE_FUTURE_DATA	3	Data for the current playback position as well as for at least a little bit of time into the future is available (in other words, at least two frames of video, for example).
    HAVE_ENOUGH_DATA	4	Enough data is available—and the download rate is high enough—that the media can be played through to the end without interruption.

  source: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
*/

export const VIDEO_HAS_NOTHING = 0;
export const VIDEO_HAS_METADATA = 1;
export const VIDEO_HAS_CURRENT_DATA = 2;
export const VIDEO_HAS_FUTURE_DATA = 3;
export const VIDEO_HAS_ENOUGH_DATA = 4;

/* Videoplayer constants */
export const VIDEO_PROGRESS_BAR_MARGIN = 13;
export const VIDEO_CONTROL_WIDTH = 200;
export const VIDEO_SIDEBAR_WIDTH = SIDEBAR_WIDTH;

export const ZONE_STYLES = {
  defaultColor: WHITE,
  color: WHITE,
  pointSize: 4,
  shadowOffsetX: 0,
  shadowOffsetY: 1,
  shadowBlur: 2,
  shadowColor: 'rgba(0, 0, 0, 0.5)',
  highlightedColor: SKY_BLUE,
  inactiveColor: REGENT_GREY,
};

export const DEBOUNCE_WAIT = 100; // in ms
