import React, { useEffect } from 'react';
import { TileLayer } from 'react-leaflet';
import { useSelector, useDispatch } from 'react-redux';

import { selectTileServerUrl } from 'settings/redux/selectors';
import { verifyTileServerURL } from 'settings/redux/actions';

const MapTileLayer = () => {
  const tileServerUrl = useSelector(selectTileServerUrl);

  const dispatch = useDispatch();

  useEffect(() => {
    if (tileServerUrl) dispatch(verifyTileServerURL());
  }, [dispatch, tileServerUrl]);

  return <TileLayer url={tileServerUrl} />;
};

export default MapTileLayer;
