import { useState } from 'react';
import { makeStyles } from 'tss-react-local';
import Select from 'common/components/base/Select';
import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import MultiSelect from 'common/components/base/MultiSelect';
import { Example } from '../helpers';

const options1 = [
  { label: 'Grid', value: '1' },
  { label: 'Map', value: '2' },
  { label: 'List', value: '3' },
];

const options2 = [
  { label: 'Grid', value: '1', disabled: true, tooltip: 'This is a tooltip' },
  { label: 'Map', value: '2' },
  { label: 'List', value: '3', tooltip: 'Choose Map to remove Error' },
];

const options3 = [
  { label: 'Truck', value: 8 },
  { label: 'Car', value: 2 },
  { label: 'Bus', value: 1 },
];

const idMapping = {
  1: 'Bus',
  2: 'Car',
  8: 'Truck',
};

const useStyles = makeStyles()(theme => ({
  form: {
    minWidth: 120,
    marginRight: 8,
  },
  icon: {
    marginRight: 8,
  },
  select: {
    minWidth: theme.spacing(20),
  },
}));

const SelectExample = () => {
  const { classes } = useStyles();
  const [state, setState] = useState({ value1: '', value2: '', value3: [] });

  const onChange = prop => e => setState({ [prop]: e.target.value });

  return (
    <div>
      <Example label="Material Select">
        <FormControl>
          <InputLabel htmlFor="foo">Identity Type</InputLabel>
          <MuiSelect variant="outlined" className={classes.select}>
            <MenuItem value={1}>Named</MenuItem>
            <MenuItem value={2}>Provisional</MenuItem>
          </MuiSelect>
        </FormControl>
      </Example>
      <Example label="Select">
        <Select
          label="Display"
          value={state.value1}
          onChange={onChange('value1')}
          options={options1}
          className={classes.form}
          helperText="Select display type"
        />
        <Select
          label="Display"
          value={state.value1}
          onChange={onChange('value1')}
          options={options1}
          required
          placholder="Placeholder"
          helperText="Select display type"
          className={classes.form}
        />
        <Select
          label="Age"
          helperText="Disabled"
          className={classes.form}
          value={state.value1}
          onChange={onChange('value1')}
          options={options1}
          disabled
        />
      </Example>
      <Example label="With Error State">
        <Select
          className={classes.form}
          value={state.value2}
          label="Display"
          helperText={state.value2 !== '2' ? 'Error' : ''}
          onChange={onChange('value2')}
          options={options2}
          error={state.value2 !== '2'}
        />
      </Example>
      <Example label="Multi Select">
        <MultiSelect
          showCheckboxes
          shrinkOnEmptyLabel
          className={classes.form}
          inputLabelProps={{ shrink: true }}
          options={options3}
          value={state.value3}
          renderValue={(value = []) => {
            if (value.length === 0) {
              return 'All Vehicle Types';
            }

            return value.map(id => idMapping[id]).join(', ');
          }}
          onItemsSelected={value3 => {
            setState({ value3 });
          }}
        />
      </Example>
    </div>
  );
};

export default SelectExample;
