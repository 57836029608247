import React from 'react';
import { SketchPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';

import Tooltip from 'common/components/base/Tooltip';
import * as allColors from 'common/constants/colors';
import { getColorLuminance } from 'common/helpers/colorHelpers';

import { Example } from '../helpers';

const colorSampleStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '0.5px solid black',
};

export default class ColorsExample extends React.Component {
  state = {
    selectedColor: null,
    showColorPicker: false,
  };

  onColorChange = color => this.setState({ compareColor: color.hex });

  setSelectedColor = color => this.setState({ selectedColor: color });

  toggleColorPicker = e =>
    this.setState(state => ({
      showColorPicker: !state.showColorPicker,
      anchorEl: e.currentTarget,
    }));

  render() {
    const { anchorEl, compareColor, selectedColor, showColorPicker } = this.state;

    const colorsToRender = Object.keys(allColors)
      .filter(key => typeof allColors[key] === 'string')
      .map(key => ({
        name: key,
        value: allColors[key],
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <Example label="Color Comparison">
        <div style={{ display: 'flex', margin: 20 }}>
          <div
            style={{
              ...colorSampleStyles,
              height: 200,
              width: 200,
              marginRight: 30,
              backgroundColor: selectedColor || 'unset',
            }}
          >
            {selectedColor || 'Select a color...'}
          </div>
          <div
            onClick={this.toggleColorPicker}
            role="none"
            style={{
              ...colorSampleStyles,
              height: 200,
              width: 200,
              backgroundColor: compareColor || 'unset',
              cursor: 'pointer',
            }}
          >
            {compareColor || '...compare to'}
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {colorsToRender.map(({ name, value }) => (
            <div key={name} style={{ marginRight: 20, marginBottom: 20 }}>
              <p
                style={{
                  fontSize: 14,
                  maxWidth: '100px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {name}
              </p>
              <Tooltip title={`${name}`}>
                <div
                  onClick={() => this.setSelectedColor(value)}
                  role="none"
                  style={{
                    ...colorSampleStyles,
                    backgroundColor: value,
                    height: 100,
                    width: 100,
                    cursor: 'pointer',
                    border: selectedColor === value ? `4px solid #5E9ED6` : 'none',
                    color: getColorLuminance(value) < 150 ? 'white' : 'black',
                  }}
                >
                  {value}
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
        <Popover
          open={showColorPicker}
          onClose={this.toggleColorPicker}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SketchPicker color={compareColor} onChange={this.onColorChange} />
        </Popover>
      </Example>
    );
  }
}
