import { makeStyles } from 'tss-react-local';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import IdentityTile, { Name, Relationship } from 'components/IdentityTile';
import { Example } from './helpers';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': { margin: theme.spacing(1) },
  },
}));

const options = [
  { label: 'Edit', icon: <EditIcon />, onClick: () => console.log('edit') },
  { label: 'Export', icon: <SaveAltIcon />, onClick: () => console.log('export') },
  { label: 'Delete', icon: <DeleteIcon />, onClick: () => console.log('delete') },
];

const IdentityTileExample = () => {
  const { classes } = useStyles();

  return (
    <Example className={classes.container} label="basic">
      <IdentityTile imgSrc="http://www.fillmurray.com/300/300">
        <Name value="Steve Stevenson" />
        <Relationship value='wife to "El Chapo"' strength={2} />
      </IdentityTile>

      <IdentityTile imgSrc="http://www.fillmurray.com/500/500">
        <Name value='Domingo "The Sword" Montoya' />
        <Relationship value='first cousin twice removed to "El Chapo"' strength="1" />
      </IdentityTile>

      <IdentityTile isSelected imgSrc="http://www.fillmurray.com/500/500" options={options}>
        <Name value="Mark Ronson" />
        <Relationship value='son to "El Chapo"' />
      </IdentityTile>

      <IdentityTile imgSrc="http://www.fillmurray.com/400/400" />

      <IdentityTile imgSrc="http://www.fillmurray.com/200/200" />
    </Example>
  );
};

export default IdentityTileExample;
