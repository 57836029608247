import { useCallback, useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';

import { noop, isEmpty } from 'lodash';
import {
  selectIsManualIdentityDrawingEnabled,
  selectIsLiveNGEnabled,
} from 'settings/redux/selectors';

import DrawingOverlay from 'common/components/drawingOverlay/DrawingOverlay';
import { ZonesProvider } from 'common/components/drawingOverlay/ZonesContext';
import { CameraZonesProvider } from 'library/libraryBody/cameraView/CameraZonesContext';

import { LIVE as LIVE_COLOR, CYAN_BLUE } from 'common/constants/colors';
import emitter, { DRAW_DETECTION } from 'common/constants/emitter';
import { ANALYSIS_VIDEO } from 'common/constants/app';
import { ZONE_STYLES } from 'common/components/videoPlayer/constants';
import { fetchSinglePerson } from 'library/redux/person/actions';
import BoundingBox from 'components/Detections/BoundingBox';
import DetectionPersonFilterList from 'components/Detections/DetectionPersonFilterList';
import { getScale } from './utils';

const DIALOG_TITLE = 'Add Reference Image';
const DIALOG_SUBTITLE =
  'Clear reference images work best. Blurry reference images may impact accuracy in analysis.';

const useStyles = createUseStyles(theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    border: ({ isLive, borderColor }) => (isLive ? `4px solid ${borderColor}` : 'unset'),
  },
  canvas: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'inline-block',
  },
  labellingButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    zIndex: 40,
  },
}));

const DetectionOverlay = ({
  isLoading,
  imageId,
  contentWidth,
  contentHeight,
  inspecting = false,
  inspectTypes = [],
  analysisType,
  confidenceThreshold,
  zones = [],
  videoId,
  pause = noop,
  startEpochMs,
  video,
  inspectFrames,
  isLive = false,
  isLabeling = false,
  trackletId,
  interpolator,
  frameRate,
  forwardedRef,
  livePlayer,
  liveQuery,
  zoom,
}) => {
  const dispatch = useDispatch();

  const overlayRef = useRef();
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [dialogDetection, setDialogDetection] = useState();
  const [hovering, setHovering] = useState();
  const [identificationCancelHandler, setIdentificationCancelHandler] = useState(noop);
  const [showPersonFilterList, setShowPersonFilterList] = useState(false);
  const [thumbnailBounds, setThumbnailBounds] = useState();
  const isManualIdentityDrawingEnabled = useSelector(selectIsManualIdentityDrawingEnabled);
  const isLiveNGEnabled = useSelector(selectIsLiveNGEnabled);
  const videoTime = video && video.getTime() * 1000;

  const classes = useStyles({
    isLive,
    borderColor: isLiveNGEnabled ? CYAN_BLUE : LIVE_COLOR,
  });

  const overlayDivId = `DetectionOverlay-${isLiveNGEnabled ? 'NG' : 'V1'}`;

  const isValidTypeDetection = useCallback(
    detection => {
      let liveDetection = false;
      if (livePlayer) {
        liveDetection =
          liveQuery?.detectionObjects?.includes(detection.getType()?.toString()) ||
          detection.isIdentity();
      }
      return (
        !detection.isInspectedFrame() || inspectTypes.includes(detection.getType()) || liveDetection
      );
    },
    [inspectTypes, livePlayer, liveQuery]
  );

  const createBoundingBoxes = useCallback(
    (detections = []) => {
      let filteredDetections = inspectFrames
        ? detections.filter(x => !isEmpty(x._person) || trackletId === x._trackletId)
        : detections;

      if (inspecting) {
        filteredDetections = filteredDetections.filter(isValidTypeDetection);
      }

      filteredDetections = filteredDetections.filter(
        detection =>
          !detection.isInspectedFrame() || detection.getConfidence() * 100 > confidenceThreshold
      );

      const scale = getScale(overlayRef.current);
      return filteredDetections.map(
        detection =>
          new BoundingBox({
            detection,
            canvas: overlayRef.current,
            scale,
            trackletId,
            livePlayer,
          })
      );
    },
    [confidenceThreshold, inspectFrames, inspecting, isValidTypeDetection, trackletId, livePlayer]
  );

  const drawZones = () => {
    if (isLoading || !overlayRef.current) return;

    const canvas = overlayRef.current;
    const { width: canvasWidth, height: canvasHeight } = canvas;

    if (zones?.length) {
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = ZONE_STYLES.color;
      ctx.setLineDash([ZONE_STYLES.pointSize]);
      ctx.lineWidth = ZONE_STYLES.pointSize;
      ctx.shadowOffsetX = ZONE_STYLES.shadowOffsetX;
      ctx.shadowOffsetY = ZONE_STYLES.shadowOffsetY;
      ctx.shadowBlur = ZONE_STYLES.shadowBlur;
      ctx.shadowColor = ZONE_STYLES.shadowColor;

      zones.forEach(([[firstX, firstY], ...rest]) => {
        if (firstX && firstY) {
          ctx.beginPath();
          ctx.moveTo(firstX * canvasWidth, firstY * canvasHeight);
          rest.forEach(([x, y]) => {
            ctx.lineTo(x * canvasWidth, y * canvasHeight);
          });
          ctx.stroke();
        }
      });
    }
  };

  const onClickTooltip = useCallback(
    async detection => {
      if (analysisType === ANALYSIS_VIDEO) pause();

      if (!detection.detection.getType() && detection.detection.getId()) {
        const { objectType } = await dispatch(fetchSinglePerson(detection.detection.getId()));
        detection.detection.setType(objectType);
      }

      setDialogDetection(detection);
      setThumbnailBounds(detection.detection.getBounds());
      setShowPersonFilterList(true);
    },
    [analysisType, dispatch, pause]
  );

  const onHandleManualIdentification = useCallback(({ bounds, onCancel }) => {
    setIdentificationCancelHandler(() => onCancel);
    setThumbnailBounds(bounds);
    setShowPersonFilterList(true);
  }, []);

  const renderDrawingOverlay = () => {
    const shouldRenderLabelingOverlay =
      isManualIdentityDrawingEnabled && inspecting && !isLoading && isLabeling;

    if (!shouldRenderLabelingOverlay) return null;

    return (
      <CameraZonesProvider>
        <ZonesProvider>
          <DrawingOverlay
            aspectRatio={contentWidth / contentHeight}
            mode={DrawingOverlay.MODES.RECTANGLE}
            onComplete={onHandleManualIdentification}
            tooltipStrings={{
              start: 'Click to start labeling',
              add: 'Click to complete',
            }}
            zoom={zoom}
          />
        </ZonesProvider>
      </CameraZonesProvider>
    );
  };

  const onCloseDetectionPersonFilterList = useCallback(() => setShowPersonFilterList(false), []);

  const renderDetectionPersonFilterList = () => (
    <DetectionPersonFilterList
      open={showPersonFilterList}
      onClose={onCloseDetectionPersonFilterList}
      timestamp={videoTime + startEpochMs}
      videoId={videoId}
      imageId={imageId}
      detection={dialogDetection?.detection}
      embedding={dialogDetection?.detection?.getEmbedding()}
      thumbnailBounds={thumbnailBounds}
      onCancel={identificationCancelHandler}
      dialogTitle={isLabeling ? DIALOG_TITLE : undefined}
      isLive={isLive}
      dialogSubtitle={isLabeling ? DIALOG_SUBTITLE : undefined}
    />
  );

  // called when tooltip triggers onOpen
  const onMouseOver = useCallback(boundingBox => setHovering(boundingBox), []);

  // called when tooltip triggers onClose
  const onMouseLeave = useCallback(() => setHovering(), []);

  const clearCanvas = useCallback(() => {
    const canvas = overlayRef.current;
    return canvas && canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
  }, []);

  const drawDetections = useCallback(
    (detections = []) => {
      clearCanvas();
      if (!overlayRef.current || !detections.length) return setBoundingBoxes([]);
      const _boundingBoxes = createBoundingBoxes(detections);
      setBoundingBoxes(_boundingBoxes);
      emitter.emit(DRAW_DETECTION);
    },
    [clearCanvas, createBoundingBoxes]
  );

  const setTime = useCallback(
    seconds => {
      if (!interpolator) {
        clearCanvas();
        return;
      }

      const timestamp = seconds * 1000;
      const detections = interpolator.getBoundingBoxesAtTime(timestamp, frameRate);
      drawDetections(detections);
    },
    [interpolator, frameRate, drawDetections, clearCanvas]
  );

  useImperativeHandle(forwardedRef, () => ({ drawDetections, clearCanvas, setTime }), [
    drawDetections,
    clearCanvas,
    setTime,
  ]);

  const renderCanvas = () => (
    <canvas
      className={classes.canvas}
      ref={overlayRef}
      width={contentWidth || '100%'}
      height={contentHeight || '100%'}
      data-testid="VideoPlayer-detectionOverlay"
    />
  );

  // render tooltips only for actionable detections
  const renderTooltips = () => {
    // We do not want actionable tooltips when labeling as they interfer with the drawing
    if (isLabeling) return [];

    const identityDetections = [];
    const identityTypeDetections = [];

    boundingBoxes.forEach(boundingBox => {
      if (boundingBox.detection.isIdentity()) {
        identityDetections.push(boundingBox);
      } else if (boundingBox.detection.isActionable()) {
        identityTypeDetections.push(boundingBox);
      }
    });

    return [...identityDetections, ...identityTypeDetections].map(boundingBox =>
      boundingBox.renderTooltip({ onClickTooltip, onMouseOver, onMouseLeave })
    );
  };

  // render bounding box for all detections
  const renderBoundingBoxes = () => {
    if (isEmpty(boundingBoxes)) return null;
    return boundingBoxes.map(boundingBox =>
      boundingBox.renderBoundingBox(hovering?.detection?.isSame(boundingBox?.detection))
    );
  };

  return (
    <div
      data-testid="DetectionOverlay-div"
      className={classes.main}
      ref={forwardedRef}
      id={overlayDivId}
    >
      {renderCanvas()}
      {renderDrawingOverlay()}
      {renderDetectionPersonFilterList()}
      {drawZones()}
      {renderBoundingBoxes()}
      {renderTooltips()}
    </div>
  );
};

export default forwardRef((props, ref) => <DetectionOverlay {...props} forwardedRef={ref} />);
