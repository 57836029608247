import { createSelector } from 'reselect';
import axios from 'axios';
import DateTime from 'dateTime';

import SEVERITY from 'app/notifications/constants';
import {
  getData,
  getMessage,
  getResolution,
  getErrorCode,
  getStatus,
  getContentType,
  isMirageException,
  isMiragePopup,
} from 'common/errors';
import store from 'redux/store';
import { signout } from 'auth/redux/actions';
import { renderError, renderAlert, setLoggingError } from 'app/redux/actions';

import { getCurrentTimeZoneAbbr } from 'common/helpers/dateUtils';
import { handleCancelableRequest, handleCancelableRequestCleanup } from './axiosCancellation';

const transformMirageExceptionForRender = error => ({
  ...getData(error),
  severity: SEVERITY.ERROR,
});

const handleMirageException = error => {
  if (getStatus(error) >= 500) {
    renderError(transformMirageExceptionForRender(error));
  }

  return Promise.reject(error);
};

const isHTML = error => getContentType(error) === 'text/html';

const handleHTML = error => Promise.reject(error);

const getAlertText = createSelector(getMessage, getResolution, (...args) => args.join(' '));

const handleMiragePopup = error => {
  renderAlert(getAlertText(error));
  return Promise.reject(error);
};

const transformOtherErrorForRender = error => {
  const data = getData(error);
  const status = getStatus(error);

  if (status === 502) {
    return {
      code: status,
      message: 'Bad Gateway',
      severity: SEVERITY.ERROR,
    };
  }

  return {
    ...(typeof data === 'string'
      ? {
          message: data,
          code: 'Unknown Error',
        }
      : {
          ...data,
          message:
            "We're having trouble with that request at the moment, please try again later or contact your system administrator",
          code: getErrorCode(error),
        }),
    severity: SEVERITY.ERROR,
  };
};

const handleOtherErrors = error => {
  if (getStatus(error) >= 500) {
    renderError(transformOtherErrorForRender(error));
  }

  return Promise.reject(error);
};

const isUnauthorized = error => getStatus(error) === 401;

export const handleUnauthorized = error => {
  store.dispatch(signout(error));
  return Promise.reject(error);
};

const isLoggingError = error =>
  // 403s can be returned for other issues, so we need to check the error message
  // to determine whether this 403 is a logging error
  getStatus(error) === 403 && getData(error)?.includes?.('audit logging error');
const handleLoggingError = error => store.dispatch(setLoggingError(error));

export const onReject = error => {
  handleCancelableRequestCleanup(error.config);

  if (isUnauthorized(error)) return handleUnauthorized(error);
  if (isLoggingError(error)) return handleLoggingError(error);
  if (isHTML(error)) return handleHTML(error);
  if (isMirageException(error)) return handleMirageException(error);
  if (isMiragePopup(error)) return handleMiragePopup(error);
  return handleOtherErrors(error);
};

const onResolve = response => {
  handleCancelableRequestCleanup(response.config);

  return response;
};

// Setup local instance of axios for Mirage to use
const localAxios = axios.create({});

localAxios.interceptors.request.use(config => {
  handleCancelableRequest(config);

  const date = DateTime.fromJSDateWithTZ();
  config.headers.userTimezoneOffsetMinutes = date.offset;
  config.headers.userLocalTimezoneName = getCurrentTimeZoneAbbr(date);

  // Drop all new requests when logging out
  if (window.isLoggingOut && config.url !== '/api/logout/') {
    handleCancelableRequestCleanup(config);
  }

  return config;
});

localAxios.interceptors.response.use(onResolve, onReject);

export default localAxios;
