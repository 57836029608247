import React from 'react';
import injectSheet from 'react-jss';

import emitter, { SHORTCUT_ANIMATION } from 'common/constants/emitter';
import Icon from 'common/components/base/Icon';
import JumpBack5sIcon from 'common/icons/jump-back-5-s';
import JumpBack90sIcon from 'common/icons/jump-back-90';
import JumpForward5sIcon from 'common/icons/jump-forward-5-s';
import JumpForward90sIcon from 'common/icons/jump-forward-90';
import PlaySpeed2xIcon from 'common/icons/playspeed-2-x';
import PlaySpeed4xIcon from 'common/icons/playspeed-4-x';

import {
  SKIP_BACKWARD_1,
  SKIP_FORWARD_1,
  SKIP_BACKWARD_2,
  SKIP_FORWARD_2,
  STEP_BACKWARD,
  STEP_FORWARD,
  PREV_DETECTION,
  NEXT_DETECTION,
  PLAY_PAUSE,
  PLAY_SPEED_1X,
  PLAY_SPEED_2X,
  PLAY_SPEED_4X,
} from 'common/constants/shortcuts';

import { TEXT, OFF_WHITE } from 'common/constants/colors';

const styles = {
  main: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 10,
    pointerEvents: 'none',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: OFF_WHITE,
    background: TEXT,
    height: 96,
    width: 96,
    opacity: 0.7,
    borderRadius: '50%',
  },
  icon: {
    fontSize: 64,
  },
};

const ICON_STYLE = {
  color: 'white',
  width: 48,
};

class VideoShortcutAnimations extends React.Component {
  state = { animation: null };

  /*
    We are using an event library as this makes the React Logic much simpler,
    otherwise we would have to pass animation props that force update depending on the what
    kind of props are passed in (e.g. making sure the animations always play, even if the same
    button is pressed, and making sure the animations do not play when unrelated props change).
    By emitting a function, we ensure that the component only animates when it is called
  */
  componentDidMount() {
    this.unbind = emitter.on(SHORTCUT_ANIMATION, this.playAnimation);
  }

  /* Ignore all changes in props that might cause an update */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) return true;
    return false;
  }

  componentWillUnmount() {
    this.unbind();
  }

  shortcuts = {
    [SKIP_BACKWARD_1]: () => <JumpBack5sIcon style={ICON_STYLE} />,
    [SKIP_FORWARD_1]: () => <JumpForward5sIcon style={ICON_STYLE} />,
    [SKIP_BACKWARD_2]: () => <JumpBack90sIcon style={ICON_STYLE} />,
    [SKIP_FORWARD_2]: () => <JumpForward90sIcon style={ICON_STYLE} />,
    [STEP_BACKWARD]: () => this.renderIcon('fast_rewind'),
    [STEP_FORWARD]: () => this.renderIcon('fast_forward'),
    [PREV_DETECTION]: () => this.renderIcon('skip_previous'),
    [NEXT_DETECTION]: () => this.renderIcon('skip_next'),
    [PLAY_PAUSE]: () => this.renderIcon(!this.isPaused() ? 'play_arrow' : 'pause'),
    [PLAY_SPEED_1X]: () => this.renderIcon('play_arrow'),
    [PLAY_SPEED_2X]: () => <PlaySpeed2xIcon style={ICON_STYLE} />,
    [PLAY_SPEED_4X]: () => <PlaySpeed4xIcon style={ICON_STYLE} />,
  };

  /*
    Relying on prop updates for checking whether a video is paused actually lags behind the exact
    current state of the video. Calling ths function directly on the video ensures we are on the
    correct state
  */
  isPaused = () => this.props.video && this.props.video.isPaused();

  stopAnimation = () => this.setState({ animation: null });

  playAnimation = animation => {
    this.setState({ animation }, this.animate);

    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.stopAnimation, 400);
  };

  animate = () => {
    this.icon.animate(
      [
        { transform: 'scale(1)', opacity: '0.8' },
        { transform: 'scale(1.3)', opacity: '0' },
      ],
      {
        duration: 400,
        iterations: 1,
      }
    );
  };

  renderIcon = iconName => <Icon className={this.props.classes.icon} iconName={iconName} />;

  render() {
    const { classes } = this.props;
    const animation = this.shortcuts[this.state.animation];

    return (
      <div className={classes.main}>
        {animation && (
          <div className={classes.iconWrapper} ref={node => (this.icon = node)}>
            {animation()}
          </div>
        )}
      </div>
    );
  }
}

export default injectSheet(styles)(VideoShortcutAnimations);
