import DateTime from 'dateTime';

const { convertToLuxonDate } = DateTime;

export function mergeDateWithTime(date, time) {
  const luxonDate = convertToLuxonDate(date);
  return luxonDate.set({ minute: time.minute, hour: time.hour, second: time.second });
}

export function setTime(date, time) {
  const result = date.set({ minute: time.minute, hour: time.hour, second: time.second });
  return result;
}

export function convertToMeridiem(time, meridiem, ampm) {
  if (ampm) {
    const currentMeridiem = time.hour >= 12 ? 'pm' : 'am';
    if (currentMeridiem !== meridiem) {
      const hours = meridiem === 'am' ? time.hour - 12 : time.hour + 12;

      return time.set({ hour: hours });
    }
  }

  return time;
}

export const isWithinInterval = (date, { start, end } = {}) => {
  const luxonDate = convertToLuxonDate(date);
  const luxonStartDate = convertToLuxonDate(start);
  const luxonEndDate = convertToLuxonDate(end);
  return (
    luxonDate.startOf('day') >= luxonStartDate.startOf('day') &&
    luxonDate.startOf('day') <= luxonEndDate.startOf('day')
  );
};

export const getWeekArray = date => {
  const { days } = date
    .endOf('month')
    .endOf('week')
    .diff(date.startOf('month').startOf('week'), 'days')
    .toObject();

  const weeks = [];
  new Array(Math.round(days))
    .fill(0)
    .map((_, i) => i)
    .map(day => date.startOf('month').startOf('week').plus({ days: day }))
    .forEach((v, i) => {
      if (i === 0 || (i % 7 === 0 && i > 6)) {
        weeks.push([v]);
        return;
      }

      weeks[weeks.length - 1].push(v);
    });

  return weeks;
};
