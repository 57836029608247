import React from 'react';
import injectSheet from 'react-jss';

import DashVideo from './DashVideo';
import FrameManager, { OK_WANT_NEXT } from './lib/FrameManager';

const SEEK_DONE = 0;
const SEEK_WAITING_DISPLAY_FRAME = 1;
const SEEK_PLAYER_SEEKING = 2;

const REVERSE_FILL_FRAMES = 64;

const DASH_STYLES = {
  maxWidth: '100%',
  maxHeight: '100%',
  width: '100%',
  objectFit: 'contain',
  display: 'inline-block',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
};

const styles = {
  canvasWrapper: {
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
    display: 'flex',
  },
  canvas: props => ({
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    objectFit: 'contain',
    display: props.autoPlay ? 'none' : 'inline-block',
    position: 'relative',
  }),
};

class KineticPlayer extends React.Component {
  constructor(props) {
    super(props);

    /**
     * Only suport "kinetic seek" if we have a fairly recent webkit
     * doing the video, and offering requestVideoFrameCallback.
     */
    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      this._frameManager = new FrameManager(props.bufferSize ? props.bufferSize : 256);
      console.log('Kinetic seek enabled');
    } else {
      this._frameManager = null;
      console.log('Kinetic seek not supported');
    }

    // These will be populated by DOM references upon mount.
    this.videoPlayer = null;
    this._canvas = null;
    this._video = null;
    this._lastSyncedTime = -1;
    // 0 = not seek
    this._isSeeking = SEEK_DONE;

    // This is a workaround for a bug in Video.isPaused, which sometimes
    // seems to return false even though the video playback is paused.
    this._pendingFillRequest = true;
  }

  componentDidMount() {
    if (this.props.bindRef) this.props.bindRef(this);

    // If kinetic seek is on, turn on event handers and callbacks
    if (this._frameManager) {
      this.videoPlayer.getVideo().requestVideoFrameCallback(this._captureFrame);
    }

    if (this.props.autoPlay) {
      this.setPlaybackMode();
    } else {
      this.setKineticMode();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.frameRate !== this.props.frameRate) {
      this._frameManager.updateFramerate(this.props.frameRate);
    }
  }

  bindCanvas = c => (this._canvas = c);

  bindDashPlayer = dash => (this._dashVideo = dash);

  bindVideo = node => {
    this.videoPlayer = node;

    if (node != null) {
      this._video = node.getVideo();
    }
  };

  getDashPlayer = () => (this._dashVideo ? this._dashVideo.getDashPlayer() : null);

  // Wrap video control functions. We may have to intercept some
  // of these calls, so instead of forwardRef pattern, we simply
  // wrap every API call.
  getDuration = () => this.videoPlayer.getDuration();

  getReadyState = () => this.videoPlayer.getReadyState();

  getTime = () =>
    this.inKineticMode() ? this._frameManager.getDisplayTime() : this.videoPlayer.getTime();

  hasLoadedCurrentFrame = () => this.videoPlayer.hasLoadedCurrentFrame();

  handleWheelEvent = e => {
    if (!this.inKineticMode()) {
      this.setKineticMode();
      this._frameManager.startDisplay(this.videoPlayer.getTime(), true);
    }

    // Fill backwards.. this is tricky business...
    if (e.deltaY < 0) {
      this._displayPrevFrame();
    } else {
      this._displayNextFrame();
    }
  };

  setTime = time => {
    if (this.inKineticMode()) {
      if (!this._frameManager.startDisplay(time)) {
        this.videoPlayer.setTime(time);
        this.videoPlayer.play();
        this._isSeeking = SEEK_PLAYER_SEEKING;
      } else if (this._frameManager.getDisplayTime() < time) {
        this._displayNextFrame();
      } else {
        this._updateCanvas();
      }
    } else {
      this.videoPlayer.setTime(time);
      this._isSeeking = SEEK_PLAYER_SEEKING;
    }
  };

  inKineticMode = () => !!(this._canvas && this._canvas.style.display === 'inline-block');

  setKineticMode() {
    this._canvas.style.display = 'inline-block';
    this.videoPlayer.getVideo().muted = true;

    if (this.props.onKinetic) {
      this.props.onKinetic();
    }
  }

  setPlaybackMode() {
    this.videoPlayer.getVideo().muted = false;
    this._canvas.style.display = 'none';
  }

  canPlay() {
    if (this.inKineticMode()) {
      return this._frameManager.getDisplayTime() < this.videoPlayer.getDuration();
    }

    return this.videoPlayer.canPlay();
  }

  isPaused = () => this.inKineticMode() || this.videoPlayer.isPaused();

  play() {
    if (this.inKineticMode()) {
      this.videoPlayer.setTime(this._frameManager.getDisplayTime());
    }
    this.setPlaybackMode();
    this._frameManager.stopDisplay();
    this.videoPlayer.play();
  }

  pause() {
    if (!this.inKineticMode()) {
      // Sometimes videoplayer is ahead of the capture such that this might fail, so
      // we ignore matching threshold here.
      this._frameManager.startDisplay(this.videoPlayer.getTime(), true);
      this.setKineticMode();
      this._updateCanvas();
    }
  }

  _captureFrame = (now, metadata) => {
    if (this._isSeeking !== SEEK_PLAYER_SEEKING) {
      const time = metadata.mediaTime;
      const video = this.videoPlayer.getVideo();

      const ret = this._frameManager.offerFrame(video, time);
      this._pendingFillRequest = false;

      if (ret !== OK_WANT_NEXT) {
        // console.log('Buffer full... pausing: ', ret);
        this.videoPlayer.pause();
      }

      if (this._isSeeking === SEEK_WAITING_DISPLAY_FRAME) {
        if (this._frameManager.startDisplay(time)) {
          this._isSeeking = SEEK_DONE;
          if (this.props.onSeekFinish) this.props.onSeekFinish();
          this._updateCanvas();
        }
      }
    }

    // Request the next frame again...
    this.videoPlayer.getVideo().requestVideoFrameCallback(this._captureFrame);
  };

  _updateCanvas() {
    if (
      this._canvas.width !== this._video.videoWidth ||
      this._canvas.height !== this._video.videoHeight
    ) {
      this._canvas.width = this._video.videoWidth;
      this._canvas.height = this._video.videoHeight;
    }

    // TODO(timo): Sometimes if the video player glitches out frame may
    // not actually be available, so we protect here a little bit, although
    // behavior may still end up being slightly glitchy.
    const frame = this._frameManager.getDisplayFrame();
    if (frame) {
      this._canvas.getContext('2d').drawImage(frame, 0, 0);

      const displayTime = this._frameManager.getDisplayTime();

      if (this.props.onProgress) {
        this._lastSyncedTime = displayTime;
        if (this.props.onProgress) this.props.onProgress(displayTime);
      }
    }
  }

  _displayNextFrame() {
    const videoTime = this.videoPlayer.getTime();
    if (
      !this._frameManager.nextFrame(1) &&
      (this.videoPlayer.isPaused() ||
        videoTime < this._frameManager.getDisplayTime() + this._frameManager.frameDelta ||
        this._pendingFillRequest)
    ) {
      this.videoPlayer.setTime(this._frameManager.getDisplayTime() + this._frameManager.frameDelta);
      this._pendingFillRequest = true;
      this.videoPlayer.play();
    }
    this._updateCanvas();
  }

  _displayPrevFrame() {
    const videoTime = this.videoPlayer.getTime();

    const backStep = Math.min(this._frameManager.getBufferSize() / 2, REVERSE_FILL_FRAMES);

    // If previous frame is not even available
    if (!this._frameManager.nextFrame(-1)) {
      // This is the time from which we would like to start filling the buffer

      const targetTime = Math.max(
        this._frameManager.getDisplayTime() - backStep * this._frameManager.frameDelta,
        0
      );

      // Check that we are not already filling..
      if (
        this.videoPlayer.isPaused() || // Not playing or
        videoTime < targetTime || // Filling too far back
        videoTime >= // Filling on the wrong side
          this._frameManager.getDisplayTime() - this._frameManager.frameDelta ||
        this._pendingFillRequest
      ) {
        this.videoPlayer.setTime(targetTime);
        this._pendingFillRequest = true;
        this.videoPlayer.play();
      }
    } else if (this.videoPlayer.isPaused()) {
      const capacity = this._frameManager.reverseBufCapacity();

      // console.log('Buf capacity: ', capacity[1]);
      if (capacity[1] < this._frameManager.getBufferSize() / 2) {
        this.videoPlayer.setTime(capacity[0] - backStep * this._frameManager.frameDelta);
        this.videoPlayer.play();
      }
    }
    this._updateCanvas();
  }

  _returnToPlayBack = () => {
    if (this.inKineticMode()) {
      // TODO(timo): Allow audio...
      this.videoPlayer.getVideo().muted = true;
      this.videoPlayer.setTime(this._frameManager.getDisplayTime());
      this.videoPlayer.play();
      this._frameManager.stopDisplay();
      this.setPlaybackMode();
    }

    this._displayFrame = -1;
    this._allowFill = -1;
  };

  _seekFinished = () => {
    // This event maybe triggered by the underlying video player also
    // when filling buffers in kinetic mode, but in that case it is
    // going to be a no-op.
    if (this._isSeeking === SEEK_DONE) {
      return;
    }
    if (!this.inKineticMode() && this.props.onSeekFinish) {
      this._isSeeking = SEEK_DONE;
      this.props.onSeekFinish();
    } else {
      this._isSeeking = SEEK_WAITING_DISPLAY_FRAME;
    }
  };

  _seekStarted = () => {
    if (this._isSeeking === SEEK_PLAYER_SEEKING && this.props.onSeekStart) {
      this.props.onSeekStart();
    }
  };

  _loadStarted = () => {
    if (this.props.onLoadStart) {
      this.props.onLoadStart();
    }
  };

  _loadFinished = () => {
    if (this.props.onLoadFinish) {
      this.props.onLoadFinish();
    }
  };

  _syncTime = seconds => {
    // If in direct playback mode
    if (!this.inKineticMode()) {
      if (this._lastSyncedTime !== seconds) {
        this._lastSyncedTime = seconds;
        if (this.props.onProgress) this.props.onProgress(seconds);
      }
    }
  };

  render() {
    const { classes, frameRate } = this.props;

    return (
      <>
        <DashVideo
          autoPlay
          frameRate={frameRate}
          onProgress={this._syncTime}
          onSeekFinish={this._seekFinished}
          onSeekStart={this._seekStarted}
          onLoadStart={this._loadStarted}
          onLoadFinish={this._loadFinished}
          bindRef={this.bindDashPlayer}
          ref={this.bindVideo}
          src={this.props.src}
          style={DASH_STYLES}
        />
        <div className={classes.canvasWrapper}>
          <canvas
            className={classes.canvas}
            ref={this.bindCanvas}
            width={0}
            height={0}
            onClick={this._returnToPlayBack}
          />
        </div>
      </>
    );
  }
}

export default injectSheet(styles)(KineticPlayer);
