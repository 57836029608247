import SvgIcon from '@material-ui/core/SvgIcon';
import { SKY_BLUE, WHITE } from 'common/constants/colors';

export default ({
  color = 'primary',
  backgroundColor = WHITE,
  borderColor = SKY_BLUE,
  boxShadow = SKY_BLUE,
  cameraColor = SKY_BLUE,
  ...rest
}) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="5 0 17 22"
    color={color}
    {...rest}
  >
    <g filter="url(#filter0_d_44_13499)">
      <path
        d="M5.33365 2H18.667C19.5874 2 20.3336 2.74619 20.3336 3.66667V17C20.3336 17.9205 19.5874 18.6667 18.667 18.6667H13.3512L12.0003 22L10.4061 18.6667H5.33365C4.41318 18.6667 3.66699 17.9205 3.66699 17V3.66667C3.66699 2.74619 4.41318 2 5.33365 2Z"
        fill={boxShadow}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9682 20.9685L13.0699 18.2501H18.6663C19.3567 18.2501 19.9163 17.6904 19.9163 17.0001V3.66675C19.9163 2.97639 19.3567 2.41675 18.6663 2.41675H5.33301C4.64266 2.41675 4.08301 2.97639 4.08301 3.66675V17.0001C4.08301 17.6904 4.64266 18.2501 5.33301 18.2501H10.668L11.9682 20.9685Z"
      fill={backgroundColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66699 3.66667C3.66699 2.74619 4.41319 2 5.33366 2H18.6669C19.5875 2 20.3336 2.7462 20.3336 3.66667V17C20.3336 17.9204 19.5875 18.6667 18.6669 18.6667H13.3513L12.0004 22L10.4061 18.6667H5.33366C4.41318 18.6667 3.66699 17.9204 3.66699 17V3.66667ZM5.33366 2.83333C4.87343 2.83333 4.50033 3.20643 4.50033 3.66667V17C4.50033 17.4602 4.87344 17.8334 5.33366 17.8334H10.9312L11.9373 19.9369L12.7898 17.8334H18.6669C19.1272 17.8334 19.5003 17.4602 19.5003 17V3.66667C19.5003 3.20642 19.1272 2.83333 18.6669 2.83333H5.33366Z"
      fill={borderColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.74316 7.35712C6.74316 6.84532 7.15807 6.43042 7.66986 6.43042H14.2902C14.802 6.43042 15.2169 6.84533 15.2169 7.35712V8.38273L16.6129 6.98681C16.9121 6.68754 17.4237 6.89956 17.4237 7.32269V12.9085C17.4237 13.3317 16.9123 13.5434 16.6131 13.2447L15.2169 11.8485V12.8741C15.2169 13.3859 14.802 13.8008 14.2902 13.8008H7.66986C7.15807 13.8008 6.74316 13.3859 6.74316 12.8741V7.35712ZM7.66986 7.18042C7.57228 7.18042 7.49316 7.25954 7.49316 7.35712V12.8741C7.49316 12.9717 7.57228 13.0508 7.66986 13.0508H14.2902C14.3878 13.0508 14.4669 12.9717 14.4669 12.8741V11.1846C14.4669 10.7613 14.9786 10.5495 15.2778 10.8487L16.6737 12.2446V7.9866L15.2778 9.38252C14.9785 9.68179 14.4669 9.46977 14.4669 9.04664V7.35712C14.4669 7.25953 14.3878 7.18042 14.2902 7.18042H7.66986Z"
      fill={cameraColor}
    />
    <defs>
      <filter
        id="filter0_d_44_13499"
        x="0.333652"
        y="0.33333"
        width="23.3337"
        height="26.6667"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.66667" />
        <feGaussianBlur stdDeviation="1.66667" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_44_13499" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_44_13499"
          result="shape"
        />
      </filter>
    </defs>
  </SvgIcon>
);
