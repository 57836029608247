import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 61" {...props}>
    <path
      fill="#6599DE"
      fillRule="evenodd"
      d="M7.07 2.498L9.6 0h3.642l2.527 2.498h25.35v2.416h12.827V2.498h23.301l1.481-2.25h2.659l2.029 4.666h4.574v7.018c0 .304 1.572 1.585 1.804 1.945.499.775 1.088 2.349.146 2.943-.63.382-1.927.234-2.65.427-.57.148-1.088.337-1.587.63 0 0-3.394 1.34-3.394 6.11 0 0 0 5.006 3.458 11.237C87.492 38.342 96 49.383 96 57.923c0 3.54-3.949 3.054-3.949 3.054H71.956s-2.723-.86-2.723-4.639l-7.83-21.425s-1.32-4.354-4.754-2.008c-1.935 1.325-11.44.642-14.407.642-3 0-6.011-2.498-6.559-5.126l-.738-6.016s-.5-1.826-6.416-1.826H6.817s-3.423-.126-3.423-4.034V9.527H0V2.502l7.07-.004zm35.707 28h10.009s4.814.232 4.814-4.845c0-4.133-3.236-5.135-5-5.135.787 7.455-5.393 8.432-3.826 4.805 1.57-3.637-2.449-5.307-2.449-5.307l-4.297.04s-3.628.169-3.628 5.175c0 3.443 1.75 5.268 4.377 5.268z"
    />
  </SvgIcon>
);
