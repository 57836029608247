import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import tinycolor from 'tinycolor2';

import { makeStyles } from 'tss-react-local';
import CheckboxIcon from 'common/icons/checkbox';
import CheckboxIndeterminate from 'common/icons/checkbox-indeterminate';

import Icon from 'common/components/base/Icon';
import { PRIMARY_GREY } from 'common/constants/colors';
import { DISABLED_OPACITY, SMALL_ICON_FONT, MED_ICON_FONT } from './constants';

function getColor(color, theme) {
  return theme.palette[color] ? theme.palette[color].main : color;
}

const MUI_COLOR_DEFAULTS = ['primary', 'secondary', 'default'];

const fontSizes = {
  small: SMALL_ICON_FONT,
  medium: MED_ICON_FONT,
};

const useStyles = makeStyles()((theme, props) => ({
  disabled: {
    opacity: DISABLED_OPACITY,
  },
  indeterminate: {
    color: getColor(props.color, theme),
  },
  'label-default': {
    fontSize: 13,
  },
  'label-small': {
    fontSize: 11,
  },
  root: {
    /* MUI has weird margin props on these, going to remove them for styling clarity */
    marginLeft: 0,
    marginRight: 0,
  },
  checkboxRoot: {
    color: props.color,

    /* Makes the checkbox background white, while maintaining the original size of the checkbox */
    '& > span:first-of-type': {
      background: 'white',
      margin: 4,
      maxHeight: fontSizes[props.size] - 8,
      maxWidth: fontSizes[props.size] - 8,
    },
  },
  disableClick: {
    /* this style is to make the checkbox view-only without incurring disabled styles, e.g. a grey color */
    pointerEvents: 'none',
  },
}));

const Checkbox = forwardRef(
  (
    {
      className,
      clickable,
      size,
      theme,
      color,
      label,
      labelPlacement,
      customClasses,
      formControlProps,
      ...rest
    },
    ref
  ) => {
    const { classes, cx } = useStyles({ color, size });
    const muiColor = MUI_COLOR_DEFAULTS.includes(color) ? color : 'default';
    const { checkboxClasses = {}, formControlClasses = {} } = customClasses;

    const _color = tinycolor(color);
    const isBright = _color.isValid() ? _color.getBrightness() > 200 : false;

    return (
      <FormControlLabel
        classes={{
          ...formControlClasses,
          root: cx(classes.root, className, formControlClasses.root, {
            [classes.disableClick]: !clickable,
          }),
          label: cx(classes[`label-${size}`], formControlClasses.label),
        }}
        control={
          <MuiCheckbox
            disableRipple={!clickable}
            color={muiColor}
            indeterminateIcon={
              isBright ? (
                <CheckboxIndeterminate size={size} />
              ) : (
                <Icon iconName="indeterminate_check_box" size={size} />
              )
            }
            icon={
              <Icon
                style={isBright ? { color: PRIMARY_GREY } : {}}
                iconName="check_box_outline_blank"
                size={size}
              />
            }
            checkedIcon={
              isBright ? <CheckboxIcon size={size} /> : <Icon iconName="check_box" size={size} />
            }
            classes={{
              ...checkboxClasses,
              root: cx(classes.checkboxRoot, checkboxClasses.root),
              indeterminate: cx(classes.indeterminate, checkboxClasses.indeterminate),
              disabled: cx(classes.disabled, checkboxClasses.disabled),
            }}
            size={size}
            ref={ref}
            data-testid="Checkbox"
            {...rest}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        {...formControlProps}
      />
    );
  }
);

Checkbox.defaultProps = {
  clickable: true,
  color: 'primary',
  size: 'small',
  customClasses: {},
  label: '',
};

Checkbox.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
};

export default Checkbox;
