import React from 'react';
import classnames from 'classnames';
import DateTime from 'dateTime';
import injectSheet from 'react-jss';

import IconButton from 'common/components/base/IconButton';
import { PRIMARY_TEXT, LIGHT_BLUE, PRIMARY_GREY } from 'common/constants/colors';

import { isSameDate } from 'common/helpers/dateUtils';
import { isWithinInterval } from './utils';
import { MIN_DATE, MAX_DATE } from './constants';
import Calendar from './Calendar';

const styles = {
  dayLabel: {
    fontSize: 10,
    color: PRIMARY_TEXT,
    fontWeight: 400,
  },
  day: {
    width: 28,
    height: 28,
    padding: 0,

    '&:hover': {
      background: 'unset',
    },
  },
  selected: {
    '&:hover': {
      background: 'transparent',
    },
  },
  nonCurrentMonthDay: {
    color: PRIMARY_GREY,
    opacity: 0.4,
    background: 'white !important',
    pointerEvents: 'none',
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: LIGHT_BLUE,
    color: 'white',
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  dayOutOfRange: {
    color: PRIMARY_GREY,
    opacity: 0.6,
    background: 'white !important',
    pointerEvents: 'none',
  },
  dayOutOfRangeWrapper: {
    cursor: 'not-allowed',
  },
};

class RangeCalendar extends React.Component {
  static defaultProps = {
    initialMonth: DateTime.fromJSDateWithTZ(),
    minDate: MIN_DATE,
    maxDate: MAX_DATE,
    disablePast: false,
    disableFuture: false,
    allowKeyboardControl: true,
  };

  state = {
    currentMonth: this.props.initialMonth.startOf('month'),
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.initialMonth &&
      !isSameDate(prevProps.initialMonth, this.props.initialMonth, 'month')
    ) {
      this.onMonthChanged();
    }
  }

  onDateSelect = (day, isFinish = true) => this.props.onChange(day, isFinish);

  onMonthChanged = () =>
    this.setState({
      currentMonth: this.props.initialMonth.startOf('month'),
    });

  renderDay = (date, isDayInCurrentMonth, idx) => {
    const { classes } = this.props;
    const { startDate, endDate, dateRange } = this.props;

    const dayIsBetween = isWithinInterval(date, {
      start: startDate,
      end: endDate,
    });
    const isDayInRange =
      !dateRange ||
      !dateRange[0] ||
      !dateRange[1] ||
      isWithinInterval(date, {
        start: dateRange[0],
        end: dateRange[1],
      });
    const isFirstDay = isSameDate(date, startDate);
    const isLastDay = isSameDate(date, endDate);

    const wrapperClassName = classnames({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
      [classes.nonCurrentMonthDay]: !isDayInCurrentMonth,
      [classes.dayOutOfRangeWrapper]: !isDayInRange,
    });

    const dayClassName = classnames(classes.day, {
      [classes.selected]: dayIsBetween || isFirstDay,
      [classes.nonCurrentMonthDay]: !isDayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !isDayInCurrentMonth && dayIsBetween,
      [classes.dayOutOfRange]: !isDayInRange,
    });

    return (
      <div key={idx} className={wrapperClassName} data-testid="RangeCalendar">
        <IconButton
          customClasses={{ root: dayClassName, label: classes.dayLabel }}
          onClick={isDayInRange ? () => this.onDateSelect(date) : null}
          disabled={!isDayInRange || !isDayInCurrentMonth}
        >
          {date.day}
        </IconButton>
      </div>
    );
  };

  render() {
    return (
      <Calendar
        date={this.state.currentMonth}
        disableFuture={this.props.disableFuture}
        disablePast={this.props.disablePast}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        onChange={this.onDateSelect}
        onMonthChange={this.props.onMonthChange}
        renderDay={this.renderDay}
      />
    );
  }
}

export default injectSheet(styles)(RangeCalendar);
