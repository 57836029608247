export function getColorLuminance(color) {
  if (typeof color !== 'string') return NaN;

  const [r, g, b, a] = color.startsWith('rgb') ? _getRGBArray(color) : _getHexRGBArray(color);

  if (a) {
    return 255 - 255 * a;
  }

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function getHexadecimalOpacity(color, opacity) {
  if (opacity < 0) return '';
  if (opacity > 1) return color + _getHexFromNumber(255);
  return color + _getHexFromNumber(opacity * 255);
}

function _getHexFromNumber(num) {
  const hex = Number(Math.floor(num)).toString(16);
  return hex.length > 1 ? hex : `0${hex}`;
}

function _getHexRGBArray(color) {
  const hex = color.replace('#', '');
  return [
    parseInt(hex.slice(0, 2), 16),
    parseInt(hex.slice(2, 4), 16),
    parseInt(hex.slice(4, 6), 16),
  ];
}

function _getRGBArray(color) {
  const openParenIndex = color.indexOf('(');
  const closeParenIndex = color.indexOf(')');

  return color
    .substring(openParenIndex + 1, closeParenIndex)
    .split(',')
    .map(Number);
}

function _getFactor(ratio) {
  if (ratio > 1) {
    return 1;
  }
  if (ratio < 0) {
    return 0;
  }

  return ratio;
}

export function interpolateColors(ratio = 0, ...restColors) {
  if (!restColors) return '';
  if (restColors.lengh === 1) return restColors[0];

  const factor = _getFactor(ratio);
  const factorInterval = 1 / (restColors.length - 1);

  let index = Math.floor(factor / factorInterval);
  /*
    Index can sometimes be the length of the restColors array, if factor === factorInterval.
    This causes undefined values which we do not want
  */
  index = index === restColors.length - 1 ? index - 1 : index;

  const factorRatio = (factor - factorInterval * index) / factorInterval;

  /* turn hex strings into numbers */
  const [hexA, hexB] = [restColors[index], restColors[index + 1]].map(color =>
    _getHexRGBArray(color)
  );

  const result = [];
  for (let i = 0; i < hexA.length; i++) {
    /* interpolateo between two colors */
    result[i] = Math.round(hexA[i] + factorRatio * (hexB[i] - hexA[i]));
  }

  return `#${result.map(num => _getHexFromNumber(num)).join('')}`;
}
