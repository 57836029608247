const defaults = {
  isLoadingClassifiers: true,
  isLoadingDetectors: true,
  isExportClassifierModelLoading: false,
  isExportDetectorModelLoading: false,
  isImportConfirmationsLoading: false,
  isExportConfirmationsLoading: false,
};

export default function incrementalTrainingReducer(state = defaults, action) {
  switch (action.type) {
    case 'INCREMENTAL_TRAINING/SET_LOADING_CLASSIFIERS': {
      return {
        ...state,
        isLoadingClassifiers: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_DETECTORS': {
      return {
        ...state,
        isLoadingDetectors: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_EXPORT_CLASSIFIER': {
      return {
        ...state,
        isExportClassifierModelLoading: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_EXPORT_DETECTOR': {
      return {
        ...state,
        isExportDetectorModelLoading: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_EXPORT_DETECTIONS': {
      return {
        ...state,
        isExportDetectionsLoading: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_IMPORT_DETECTOR': {
      return {
        ...state,
        isImportDetectorModelLoading: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_IMPORT_DETECTIONS': {
      return {
        ...state,
        isImportDetectionsLoading: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_IMPORT_CONFIRMATIONS': {
      return {
        ...state,
        isImportConfirmationsLoading: action.payload,
      };
    }

    case 'INCREMENTAL_TRAINING/SET_LOADING_EXPORT_CONFIRMATIONS': {
      return {
        ...state,
        isExportConfirmationsLoading: action.payload,
      };
    }

    default:
      return state;
  }
}
