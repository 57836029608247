export function isLatLngInDDSFormat(formattedAddress) {
  const pattern = new RegExp(
    /^\s*[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?\s*)$/g
  );
  return pattern.test(formattedAddress);
}

export function sceneHasLocation(scene) {
  const { location: { coords = {}, formattedAddress = '' } = {} } = scene;

  return (coords.lat && coords.lng) || isLatLngInDDSFormat(formattedAddress);
}

// Merge datasource location if they are same.
export function mergeDataSourceLocation(dataSourceLocations) {
  if (!dataSourceLocations) {
    return [];
  }

  for (let i = 1; i < dataSourceLocations.length; i++) {
    const latlngs = dataSourceLocations
      .slice(0, i)
      .filter(
        x =>
          x.coords &&
          dataSourceLocations[i].coords &&
          x.coords.lat === dataSourceLocations[i].coords.lat &&
          x.coords.lng === dataSourceLocations[i].coords.lng
      );
    if (latlngs.length === 1) {
      if (dataSourceLocations[i].isSelected) {
        latlngs[0].coords = {};
      } else {
        dataSourceLocations[i].coords = {};
      }
    }
  }

  return dataSourceLocations;
}

export function rowIdGenerator(id) {
  return `ReportRow-${id}`;
}

export function parseQuery(query) {
  try {
    return JSON.parse(query);
  } catch (err) {
    // Old queries are formatted as strings, the appropriate formatting
    // is a JSON object - if the query cannot be parsed, the SearchTerms
    // component will not be rendered
    return {};
  }
}

export function getParsedQuery(query) {
  const parsedQuery = parseQuery(query);

  return parsedQuery && parsedQuery.q;
}

export function getCoordsFromFormattedAddress(formattedAddress) {
  const latLngArray = formattedAddress?.split(',');

  return (
    isLatLngInDDSFormat(formattedAddress) && {
      lat: Number(latLngArray[0].trim()),
      lng: Number(latLngArray[1].trim()),
    }
  );
}
