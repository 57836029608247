import { isValidEmailSyntax } from 'liveSearch/utils';

export function getUserName({ firstName, lastName, username } = {}) {
  return firstName && lastName ? `${firstName} ${lastName}` : username;
}

export function getUserAbbreviation(name) {
  return name && name[0] ? name[0].toUpperCase() : '';
}

export function isPercipientEmail(email) {
  return isValidEmailSyntax(email) && email.endsWith('@percipient.ai');
}
