export const POINT = 'point';
export const BBOX = 'bbox';

/* Events for this library */
export const BOUNDING_BOXES_CLEAR = 'boundingBoxes:clear';
export const BOUNDING_BOXES_CLICKED = 'boundingBoxes:clicked';
export const BOUNDING_BOXES_CLONED = 'boundingBoxes:cloned';
export const BOUNDING_BOXES_CLONE_FINISH = 'boundingBoxes:cloneFinish';
export const BOUNDING_BOXES_CLONE_START = 'boundingBoxes:cloneStart';
export const BOUNDING_BOXES_COPY_PASTED = 'boundingBoxes:copyPasted';
export const BOUNDING_BOXES_DELETED = 'boundingBoxes:deleted';
export const BOUNDING_BOXES_SELECTED = 'boundingBoxes:selected';
export const BOUNDING_BOXES_TRANSFORMED = 'boundingBoxes:transformed';

/* other constants */
export const SELECTED_BOX_COLOR = 'yellow';
