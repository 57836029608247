import axios from 'axios.js';

import { ANALYTICS_BACKEND } from 'common/constants/urls';

/**
  @param params.showCustom will request the backend to show custom objects
*/
export function getDetectionObjects(params) {
  return axios.get(`${ANALYTICS_BACKEND}/search/objects/`, { params }).then(({ data }) => data);
}

export function patchDetectionObject(object) {
  return axios
    .patch(`${ANALYTICS_BACKEND}/search/objects/${object.id}/`, object)
    .then(({ data }) => data);
}

export function postDetectionObject(params) {
  return axios.post(`${ANALYTICS_BACKEND}/search/objects/`, params).then(({ data }) => data);
}
