import L from 'leaflet';

const getDefaultIcon = () =>
  new L.Icon.Default({
    iconRetinaUrl: '/marker-icon-2x.png',
    iconUrl: '/marker-icon.png',
    shadowUrl: '/marker-shadow.png',
  });

export default getDefaultIcon;
