import React from 'react';
import classnames from 'classnames';

import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';

import Icon from 'common/components/base/Icon';

import useStyles from './styles';

const TableHeadGroup = ({
  columns,
  customClasses,
  addPaddingLeft,
  enableExpanded,
  enableSorting,
  headers,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <MuiTableRow
      className={classnames(customClasses.tableRow, {
        [classes.extraPadding]: addPaddingLeft,
      })}
      {...rest}
    >
      {enableExpanded && <MuiTableCell className={classes.expandCell} />}
      {headers.map(column => {
        const headerProps = column.getHeaderProps(enableSorting && column.getSortByToggleProps());

        return (
          <MuiTableCell
            className={classnames(customClasses.headerCell, classes.headerCell)}
            {...headerProps}
          >
            {column.render('Header')}
            {column.isSorted && (
              <Icon
                iconName={column.isSortedDesc ? 'arrow_downward' : 'arrow_upward'}
                className={classes.sortArrow}
              />
            )}
          </MuiTableCell>
        );
      })}
    </MuiTableRow>
  );
};

export default TableHeadGroup;
