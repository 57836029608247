import React from 'react';
import injectSheet from 'react-jss';
import isNumber from 'lodash/isNumber';
import Dialog from '@material-ui/core/Dialog';
import DateTime from 'dateTime';

import Button from 'common/components/base/Button';
import TimezoneSelect from 'common/components/generalComponents/inputs/TimezoneSelect';
import TextInput from 'common/components/base/TextInput';
import Tooltip from 'common/components/base/Tooltip';

import { ERROR } from 'common/constants/colors';

import {
  getCurrentTimeZoneAbbr,
  getTimeFromHHMMSS,
  parseDurationToHHMMSS,
  getAbsoluteTimeFromDateString,
  parseToShorthandDate,
} from 'common/helpers/dateUtils';

import { inRange } from 'common/helpers/mathUtils';
import { THRESHOLD_DATE } from 'common/constants/times';

const styles = {
  main: {
    padding: 24,

    '& > *': {
      display: 'flex',
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    justifyContent: 'flex-end',

    '& button': {
      marginLeft: 12,
      minWidth: 64,
    },
  },
  error: {
    color: ERROR,
    fontSize: 10,
    minHeight: 14,
  },
  input: {
    minWidth: 200,
    marginBottom: 12,
    marginRight: 12,
  },
  select: {
    minWidth: 64,
    margin: '6px 0 12px 0',
  },
};

/* There is some weird propogation through the component. We will prevent the propgation for now */
const PaperComponent = props => <div {...props} onClick={e => e.stopPropagation()} role="none" />;

function formatDate(absoluteTime, timeZone = null) {
  const parsedTime = DateTime.fromJSDateWithTZ(absoluteTime, { timeZone });

  if (absoluteTime < THRESHOLD_DATE) {
    return parseDurationToHHMMSS(parsedTime);
  }

  return parseToShorthandDate(parsedTime, {
    showSeconds: true,
    displayTimezoneName: timeZone,
  });
}

const DEFAULT_VALUE = 0;

class JumpToTimeDialog extends React.Component {
  static defaultProps = {
    hasDate: false,
    value: 0,
    start: 0,
    end: 0,
  };

  state = { value: '', error: '', timezone: DEFAULT_VALUE };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.onOpen();
    }
  }

  handleKeyPress = e => {
    if (e.keyCode === 13 || e.which === 13) {
      this.onConfirm(e);
    }
  };

  close = e => {
    e.stopPropagation();
    this.props.onClose(e);
  };

  onChange = e => this.setState({ value: e.target.value, error: '' });

  onConfirm = e => {
    e.stopPropagation();

    let value;

    if (this.props.hasDate) {
      const absoluteTime = getAbsoluteTimeFromDateString(this.state.value, true);

      if (absoluteTime != null) {
        value = DateTime.fromJSDateWithTZ(absoluteTime, {
          timeZone: this.props.displayTimezoneName,
          keepLocalTime: true,
        }).toMillis();
      }
    } else {
      value = getTimeFromHHMMSS(this.state.value);
    }

    if (!isNumber(value)) {
      this.setState({ error: 'Format is malformed or invalid' });
    } else if (!inRange(value, this.props.start, this.props.end)) {
      this.setState({
        error: 'Time is not within video range',
        /*
          When this conditional is run, it means the time is in a valid format, but out of range.
          Since there is leniency in how the user may type his date input, we want to show the date
          that Mirage thinks the user is typing (basically, the fully formatted date), so the user
          knows what went wrong and can easily adjust
        */
        value: formatDate(value, this.props.displayTimezoneName),
      });
    } else {
      this.props.onClose();
      this.props.onConfirm(value);
    }
  };

  onOpen = () => {
    const { hasDate, value, displayTimezoneName } = this.props;
    const defaultValue = hasDate
      ? formatDate(value, displayTimezoneName)
      : parseDurationToHHMMSS(value);
    this.setState({
      value: defaultValue,
      error: '',
    });
  };

  render() {
    const { classes, open } = this.props;

    const requiredFormat = this.props.hasDate
      ? 'Format should be DD MMM (YYYY), HH:MM(:SS) (AM/PM)'
      : 'Format should be HH:MM(:SS)';

    return (
      <Dialog
        disableRestoreFocus
        open={open}
        onClose={this.close}
        classes={{ paper: classes.main }}
        PaperProps={{ component: PaperComponent }}
      >
        <div className={classes.inputWrapper} data-testid="JumpToTimeDialog">
          <Tooltip title={requiredFormat} placement="top-start">
            <TextInput
              className={classes.input}
              label="Jump to:"
              value={this.state.value}
              onChange={this.onChange}
              onKeyPress={this.handleKeyPress}
              error={!!this.state.error}
              helperText={this.state.error}
              autoFocus
            />
          </Tooltip>
          <TimezoneSelect
            value={this.state.timezone}
            className={classes.select}
            overrideText={this.props.hasDate ?? getCurrentTimeZoneAbbr(this.state.value)}
          />
        </div>

        <div className={classes.actions}>
          <Button variant="outlined" onClick={this.close} data-testid="Cancel">
            CANCEL
          </Button>
          <Button onClick={this.onConfirm} disabled={this.state.isLoading} data-testid="Confirm">
            CONFIRM
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default injectSheet(styles)(JumpToTimeDialog);
