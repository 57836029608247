import React from 'react';
import { withStyles } from 'tss-react-local';

import Checkbox from 'common/components/base/Checkbox';
import { Example } from '../helpers';

const styles = {
  backgroundExample: {
    background: 'pink',
  },
  large: {
    height: 80,
    width: 80,
  },
  blue: {
    color: 'red',
  },
};

class CheckboxExample extends React.Component {
  state = { check: false };

  onChange = e => this.setState({ check: e.target.checked });

  render() {
    return (
      <div>
        <Example className={this.props.classes.backgroundExample} label="Checkbox">
          <Checkbox />
          <Checkbox color="secondary" />
          <Checkbox checked />
          <Checkbox checked color="secondary" />
          <Checkbox indeterminate />
          <Checkbox color="secondary" indeterminate />
          <Checkbox size="medium" checked />
          <Checkbox size="medium" indeterminate />
          <Checkbox size="medium" />
        </Example>

        <Example label="Disabled Checkboxes">
          <Checkbox disabled />
          <Checkbox disabled color="secondary" />
          <Checkbox disabled checked />
          <Checkbox disabled checked color="secondary" />
          <Checkbox disabled indeterminate />
          <Checkbox disabled color="secondary" indeterminate />
          <Checkbox disabled size="medium" checked />
          <Checkbox disabled size="medium" indeterminate />
          <Checkbox disabled size="medium" />
        </Example>

        <Example label="Labels">
          <Checkbox label="Default Unchecked" />
          <Checkbox checked size="medium" label="Medium Checked" />
          <Checkbox indeterminate label="Default Indeterminate" />
        </Example>

        <Example label="Custom Classes">
          <Checkbox
            checked={this.state.check}
            onChange={this.onChange}
            label="Large Circle"
            theme="green"
            customClasses={{
              checkboxClasses: { root: this.props.classes.large },
            }}
          />
          <Checkbox
            checked={this.state.check}
            onChange={this.onChange}
            label="Red Label"
            customClasses={{
              formControlClasses: { label: this.props.classes.blue },
            }}
          />
        </Example>
        <Example label="Custom Colors">
          <Checkbox color="yellow" />
          <Checkbox color="blue" />
          <Checkbox color="#78909c" />
          <Checkbox color="black" indeterminate />
          <Checkbox color="pink" indeterminate />
          <Checkbox color="white" indeterminate />
        </Example>
      </div>
    );
  }
}

export default withStyles(CheckboxExample, styles);
