import { combineReducers } from 'redux';

import analysis from 'analysis/redux/reducer';
import analysisGeospatial from 'analysis/analysisGeo/redux/reducer';
import app, { DEFAULTS as appDefaults } from 'app/redux/reducer';
import auth from 'auth/redux/reducer';
import common from 'common/redux/reducer';
import dataShiftView from 'settings/datashift/redux/reducer';
import geoAlerting from 'geoAlerting/redux/reducer';
import health from 'app/header/mirageHealth/redux/reducer';
import incrementalTraining from 'incrementalTraining/redux/reducer';
import library from 'library/redux/reducer';
import libraryGeospatial from 'library/geospatial/redux/reducer';
import liveSearch from 'liveSearch/redux/reducer';
import mint from 'mint/redux/reducer';
import { createPlanReducer, PLAN_ANALYSIS_KEY, PLAN_MONITOR_KEY } from 'plan/redux/reducer';
import planGeospatial from 'plan/geospatial/redux/reducer';
import report from 'reports/redux/reducer';
import reportGeospatial from 'reports/geospatial/redux/reducer';
import settings from 'settings/redux/reducer';
import siftView from 'analysis/siftView/redux/reducer';

const appReducer = combineReducers({
  analysis,
  analysisGeospatial,
  app,
  auth,
  common,
  dataShiftView,
  geoAlerting,
  health,
  incrementalTraining,
  library,
  libraryGeospatial,
  liveSearch,
  mint,
  [PLAN_ANALYSIS_KEY]: createPlanReducer('_ANALYSIS'),
  [PLAN_MONITOR_KEY]: createPlanReducer('_MONITOR'),
  planGeospatial,
  report,
  reportGeospatial,
  settings,
  siftView,
});

/* root reducer */
export default (state, action) => {
  if (action.type === 'RESET_MIRAGE') {
    /* keep authentication after reset/logout */
    state = {
      auth: state.auth,
      settings: state.settings,
      app: {
        ...appDefaults,
        // Retain isNavExpanded value on logout
        isNavExpanded: state.app.isNavExpanded,
      },
    };
  }

  return appReducer(state, action);
};
