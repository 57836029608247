import React from 'react';
import injectSheet from 'react-jss';
import MuiMenu from '@material-ui/core/Menu';
import classnames from 'classnames';
import noop from 'lodash/noop';

import BetaIcon from 'common/components/generalComponents/icons/BetaIcon';
import Tooltip from './Tooltip';
import MenuItem from './MenuItem';

const styles = {
  iconMargin: {
    '& > *:first-child': {
      marginRight: 6,
    },
  },
};

/**
  @prop open **required {Bool} shows componet or not
  @prop options **required {Array <Object>} options to render
  @prop onClose **required {Func} determined what to do when pop closes
  @prop anchorEl {DOM Element} determines where to render menu
  @prop anchorPosition {Object} determines where to render menu
  @prop anchorReference {Bool} deteremines whether to use anchorPosition or anchorEl
*/
class Menu extends React.PureComponent {
  static defaultProps = {
    dense: true,
    options: [],
  };

  onClick = (e, callback = noop) => {
    e.stopPropagation();
    this.props.onClose();
    callback(e);
  };

  renderOption = ({
    beta = false,
    className,
    disabled,
    id,
    icon,
    label,
    onClick,
    selected,
    tooltip,
    tooltipProps,
    ...rest
  }) => {
    const { classes, disableIconMargin } = this.props;
    const hasLabelAndIcon = Boolean(label && icon);
    const key = id || label;
    const Component = (
      <MenuItem
        id={id}
        key={key}
        className={classnames(className, {
          [classes.iconMargin]: !disableIconMargin && hasLabelAndIcon,
        })}
        disabled={disabled}
        onClick={e => this.onClick(e, onClick)}
        dense={this.props.dense}
        size={this.props.size}
        selected={selected}
        {...rest}
      >
        {icon}
        {label}
        {beta && <BetaIcon style={{ display: 'inline-flex', marginLeft: 12 }} />}
      </MenuItem>
    );

    if (tooltip) {
      const Content = disabled ? (
        /* Add div to place tooltip on a disabled item, prevent disabled objects from triggering click events */
        <div role="none" tabIndex="" onClick={e => e.stopPropagation()}>
          {Component}
        </div>
      ) : (
        Component
      );

      return (
        <Tooltip key={key} title={tooltip} placement="right" {...tooltipProps}>
          {Content}
        </Tooltip>
      );
    }

    return Component;
  };

  render() {
    const { classes, options, onClose, open, customClasses, dense, ...rest } = this.props;

    return (
      <MuiMenu open={open} onClose={onClose} classes={customClasses} {...rest}>
        {options.map(this.renderOption)}
      </MuiMenu>
    );
  }
}

export default injectSheet(styles)(Menu);
