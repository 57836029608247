import React from 'react';
import injectSheet from 'react-jss';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import classnames from 'classnames';

import Tooltip from 'common/components/base/Tooltip';
import { ACCENT, TITLE_SELECTED, PRIMARY } from 'common/constants/colors';

const styles = {
  root: {
    height: '100%',
    minHeight: 'unset',
  },
  flexContainer: {
    height: '100%',
    zIndex: 5,
    position: 'relative',
    color: PRIMARY,
  },
  tabRoot: {
    maxWidth: 'unset',
    minWidth: props => (props.orientation === 'vertical' ? 50 : 128),
    height: '100%',
    fontSize: props => props.fontSize || 13,
    fontWeight: 500,
  },
  tabWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
  disabled: {
    opacity: 0.3,
  },
};

const getUnderlineStyles = (indicatorColor, orientation) => {
  const result = {
    background: indicatorColor || TITLE_SELECTED,
  };

  if (orientation === 'vertical') {
    result.width = 3;
  } else {
    result.height = 3;
  }

  return result;
};

const getBackgroundStyles = (indicatorColor, orientation) => {
  const result = {
    background: indicatorColor || ACCENT,
    zIndex: 1,
  };

  if (orientation === 'vertical') {
    result.width = '100%';
  } else {
    result.height = '100%';
  }

  return result;
};

/* MUI will complain if the value of a tab does not correspond to any of the tab options */
const getValue = (tabs, value) => (tabs.find(tab => tab.value === value) ? value : false);

const Tab = ({
  classes,
  customClasses = {},
  disabled,
  icon,
  forwardRef,
  label,
  tooltip,
  tooltipProps,
  value,
  ...rest
}) => {
  const Icon = disabled ? (
    <Tooltip title={tooltip} {...tooltipProps}>
      <div style={{ display: 'flex' }}>{icon}</div>
    </Tooltip>
  ) : (
    icon
  );

  const TabComponent = (
    <MuiTab
      ref={forwardRef}
      classes={{
        ...customClasses,
        root: classnames(classes.tabRoot, customClasses.root, {
          [classes.disabled]: disabled,
        }),
      }}
      disabled={disabled}
      icon={Icon}
      label={label}
      value={value}
      style={disabled ? { pointerEvents: 'auto' } : {}}
      {...rest}
    />
  );

  return disabled ? (
    TabComponent
  ) : (
    <Tooltip title={tooltip} {...tooltipProps}>
      {TabComponent}
    </Tooltip>
  );
};

/*
  MUI internally requires refs to handle a bunch of required logic. Adding a tooltip around
  the outside will break this ref handling logic unless we forward the ref past the tooltip
  component
*/
const RefTab = React.forwardRef((props, ref) => <Tab {...props} forwardRef={ref} />);

class TabsOld extends React.Component {
  static defaultProps = {
    useBackgroundSelector: false,
    orientation: 'horizontal',
    customClasses: {},
  };

  renderTab = props => <RefTab key={props.value} classes={this.props.classes} {...props} />;

  render() {
    const {
      allowNoSelection,
      classes,
      value,
      tabs,
      indicatorColor,
      customClasses,
      TabIndicatorProps,
      onChange,
      orientation,
      useBackgroundSelector,
      ...rest
    } = this.props;

    const selectedValue = allowNoSelection ? getValue(tabs, value) : value;

    return (
      <MuiTabs
        classes={{
          ...customClasses,
          root: classnames(classes.root, customClasses.root),
          flexContainer: classnames(classes.flexContainer, customClasses.flexContainer),
        }}
        onChange={onChange}
        orientation={orientation}
        value={selectedValue}
        TabIndicatorProps={{
          style: useBackgroundSelector
            ? getBackgroundStyles(indicatorColor, orientation)
            : getUnderlineStyles(indicatorColor, orientation),
          ...TabIndicatorProps,
        }}
        {...rest}
      >
        {tabs.map(this.renderTab)}
      </MuiTabs>
    );
  }
}

export default injectSheet(styles)(TabsOld);
