import { getSceneSavedAsVideo } from 'common/helpers/sceneUtils';

export function buildScene(scene, data, transform) {
  const result = {
    ...scene,
    timestamp: scene.targetTime,
    location: data.location,
    description: data.description,
    tags: data.tags,
    summary: data.summary,
    query: data.query,
    transform,
  };

  const { id } = data.report;
  if (id) result.report = id;
  if (data.targetTime) result.targetTime = data.targetTime;
  if (data.startTime) result.startTime = data.startTime;
  if (data.endTime) result.endTime = data.endTime;

  return data.saveAsVideo ? getSceneSavedAsVideo(result) : { ...result, videoUrl: '' };
}

export function buildSceneFromDatasource(datasource, { targetTime = 0, priorQuery = '' }) {
  return {
    id: null,
    targetTime,
    startTime: targetTime - 5000 >= 0 ? targetTime - 5000 : 0,
    endTime: targetTime + 5000 <= datasource.duration ? targetTime + 5000 : datasource.duration,
    summary: '',
    description: '',
    imageUrl: '',
    location: datasource.location || {},
    query: priorQuery || '',
    source: datasource.id || null,
    tags: '',
    report: {
      /* Scene doesn't exist yet, so it does not have a report associated with it */
      id: null,
      name: '',
    },
    transform: '',
  };
}

export function buildSceneFromImage(image, { priorQuery = '' }) {
  return {
    id: null,
    summary: '',
    description: '',
    imageUrl: '',
    location: image.location || {},
    query: priorQuery || '',
    imageSource: image.id || null,
    tags: '',
    report: {
      /* Scene doesn't exist yet, so it does not have a report associated with it */
      id: null,
      name: '',
    },
    transform: '',
  };
}

export function getInputStartValue(clipStartTime, targetTime) {
  const startValue = Math.max(Math.floor(Math.round(targetTime - clipStartTime) / 1000), 0);

  return startValue === 0 ? '' : startValue;
}

export function getInputEndValue(clipEndTime, targetTime, duration) {
  const endValue = Math.max(
    Math.ceil((clipEndTime - targetTime - ((duration * 1000) % 1000)) / 1000),
    0
  );

  return endValue === 0 ? '' : endValue;
}
