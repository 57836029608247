export const LIST_VIEW = 'list';
export const MAP_VIEW = 'map';

export const SORT_DIRECTION_ASC = 'ASC';
export const SORT_DIRECTION_DESC = 'DESC';

export const SORT_DIRECTION_ARROW_ASC = 'arrow_upward';
export const SORT_DIRECTION_ARROW_DESC = 'arrow_downward';

export const SORT_BY_CREATED = 'created';
export const SORT_BY_SCENE_TIME = 'scene_time';
export const SORT_BY_NAME = 'name';
export const SORT_BY_SUMMARY = 'summary';
export const SORT_BY_DESCRIPTION = 'description';
export const SORT_BY_REPORT = 'report__name';
