import { isEqual } from 'lodash';
import { ALERT_IMAGE_DATE } from 'geoAlerting/constants';

const defaults = {
  showAlertsOnly: localStorage.getItem('dashboard-showAlertsOnly') === 'true' || false,
  dashboard: {
    selectedMissions: [],
    showMap: localStorage.getItem('dashboard-showMap') !== 'false',
    queriesInMapView: [],
    filterWithMap: true,
    sortBy: {
      column: ALERT_IMAGE_DATE,
      asc: true, // default sort direction is ascending
    },
  },
};

export default function geoAlertingReducer(state = defaults, action) {
  switch (action.type) {
    case 'GEO_ALERTING/SET_SHOW_ALERTS_ONLY': {
      localStorage.setItem('dashboard-showAlertsOnly', !!action.payload);
      return {
        ...state,
        showAlertsOnly: action.payload,
      };
    }
    case 'GEO_ALERTING/SET_DASHBOARD_MISSION_SELECTION': {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          selectedMissions: action.payload,
        },
      };
    }
    case 'GEO_ALERTING/SET_DASHBOARD_SORT_BY': {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          sortBy: action.payload,
        },
      };
    }
    case 'GEO_ALERTING/SET_SHOW_DASHBOARD_MAP': {
      localStorage.setItem('dashboard-showMap', !!action.payload);
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          showMap: action.payload,
        },
      };
    }
    case 'GEO_ALERTING/SET_QUERIES_IN_MAP_VIEW': {
      if (isEqual(state.dashboard.queriesInMapView, action.payload)) return state;
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          queriesInMapView: action.payload,
        },
      };
    }
    case 'GEO_ALERTING/SET_DASHBOARD_FILTER_WITH_MAP': {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          filterWithMap: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
