import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="95" height="44" viewBox="0 0 119 55">
    <g fill="none" fillRule="evenodd">
      <path d="M-5-37h128V91H-5z" />
      <g fill="#659ADE">
        <path d="M93.068 50.418c-3.82 0-6.93-3.129-6.93-6.977 0-3.844 3.11-6.973 6.93-6.973 3.824 0 6.933 3.129 6.933 6.973 0 3.848-3.11 6.977-6.933 6.977m0-17.935c-6.006 0-10.888 4.914-10.888 10.958 0 6.043 4.882 10.961 10.888 10.961 6.005 0 10.892-4.918 10.892-10.961 0-6.044-4.887-10.958-10.892-10.958M25.744 50.418c-3.824 0-6.934-3.129-6.934-6.977 0-3.844 3.11-6.973 6.934-6.973 3.82 0 6.93 3.129 6.93 6.973 0 3.848-3.11 6.977-6.93 6.977m0-17.935c-6.005 0-10.893 4.914-10.893 10.958 0 6.043 4.888 10.961 10.893 10.961 6.006 0 10.888-4.918 10.888-10.961 0-6.044-4.882-10.958-10.888-10.958" />
        <path d="M28.707 16.538l6.224-10.952c-6.242.542-13.445 1.438-21.725 2.82l-5.388 8.132h20.89zm22.932 0V4.9c-3.462-.051-7.458.038-12.03.332-.037.112-.05.233-.111.34L33.27 16.539h18.37zm3.964 0h27.72L72.302 8.22c-.292-.177-4.548-2.583-16.699-3.195v11.513zM2.137 28.018v-1.513h3.959a1.99 1.99 0 0 0 1.982-1.994 1.99 1.99 0 0 0-1.982-1.994H2.137v-3.984c0-.066.028-.117.033-.178a2.26 2.26 0 0 1 .079-.392 2.13 2.13 0 0 1 .153-.37c.028-.05.032-.116.065-.167l7.922-11.957c.005-.01.014-.01.023-.018.093-.14.223-.243.353-.355.07-.061.12-.14.2-.192.07-.047.157-.06.236-.098.158-.075.311-.154.483-.187.01 0 .014-.005.028-.01 49.599-8.378 62.32-.032 62.836.328.01.01.018.023.028.028.009.01.023.01.037.018l15.38 11.607 25.164 1.947c.089.01.158.052.242.07.139.028.269.056.399.112.116.052.213.122.315.192.107.07.205.145.297.238.098.098.168.21.242.332.046.07.111.121.148.2.075.16.822 1.85 1.388 4.83h-3.18a1.987 1.987 0 0 0-1.98 1.994c0 1.098.886 1.99 1.98 1.99h3.7c.315 4.18.056 9.743-1.792 16.472-.014.052-.046.09-.06.136a1.791 1.791 0 0 1-.12.252c-.07.136-.15.257-.247.369-.06.07-.116.13-.18.192-.121.102-.251.186-.39.266-.07.032-.13.08-.205.107-.222.084-.46.14-.705.14h-5.94a1.986 1.986 0 0 1-1.982-1.99c0-7.692-6.22-13.95-13.863-13.95-7.644 0-13.859 6.258-13.859 13.95 0 1.098-.89 1.99-1.981 1.99H41.74a1.986 1.986 0 0 1-1.982-1.99c0-7.692-6.214-13.95-13.858-13.95s-13.863 6.258-13.863 13.95c0 1.098-.886 1.99-1.982 1.99H4.11a1.99 1.99 0 0 1-.678-.135c-.05-.024-.106-.042-.157-.07a1.917 1.917 0 0 1-.882-.808c-4.344-7.763-1.225-15.399-.255-17.398z" />
      </g>
    </g>
  </svg>
);
