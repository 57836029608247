import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ModelTrainingIcon = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g fillRule="evenodd">
      <path
        d="M23,12 C23,18.08 18.08,23 12,23 C8.28,23 4.99,21.15 3,18.33 L3,21 L1,21 L1,15 L7,15 L7,17 L4.52,17 C6.13,19.41 8.88,21 12,21 C16.97,21 21,16.97 21,12 L23,12 Z M12.7142857,7 L12.7142857,11.2857143 L17,11.2857143 L17,12.7142857 L12.7142857,12.7142857 L12.7142857,17 L11.2857143,17 L11.2857143,12.7142857 L7,12.7142857 L7,11.2857143 L11.2857143,11.2857143 L11.2857143,7 L12.7142857,7 Z M12,1 C15.72,1 19.01,2.85 21,5.67 L21,3 L23,3 L23,9 L17,9 L17,7 L19.48,7 C17.87,4.59 15.12,3 12,3 C7.03,3 3,7.03 3,12 L1,12 C1,5.92 5.92,1 12,1 Z"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
);

export default ModelTrainingIcon;
